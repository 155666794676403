import { navigate } from "gatsby-link"
import { createAction, handleActions } from "redux-actions"
import CommonApis from "../../apis/CommonApis"

const GET_STUDENT_GROUP_LIST = "common/GET_STUDENT_GROUP_LIST"
const GET_REGION_LIST = "common/GET_REGION_LIST"
const GET_ATTITUDE_LIST = "common/GET_ATTITUDE_LIST"
const GET_AI_LIST = "common/GET_AI_LIST"
const GET_BELONGINGS_LIST = "common/GET_BELONGINGS_LIST"
const GET_USER_INFORMATION = "common/GET_USER_INFORMATION"
const POST_UPLOAD_FILES = "common/POST_UPLOAD_FILES"
const GET_CONTENT_CATEGORY = "common/GET_CONTENT_CATEGORY"
const GET_CONTENT_TEACHER = "common/GET_CONTENT_TEACHER"
const GET_NOTIFICATIONS = "common/GET_NOTIFICATIONS"

export const getStudentGroupList = createAction(GET_STUDENT_GROUP_LIST)
export const getRegionList = createAction(GET_REGION_LIST)
export const getAttitudeList = createAction(GET_ATTITUDE_LIST)
export const getAiList = createAction(GET_AI_LIST)
export const getBelongingsList = createAction(GET_BELONGINGS_LIST)
export const getUserInformation = createAction(GET_USER_INFORMATION)
export const postUploadFiles = createAction(POST_UPLOAD_FILES)
export const getContentCategory = createAction(GET_CONTENT_CATEGORY)
export const getContentTeacher = createAction(GET_CONTENT_TEACHER)
export const getNotifications = createAction(GET_NOTIFICATIONS)

const initState = {
  studentGroupList: undefined,
  regionList: undefined,
  attitudeList: undefined,
  aiList: undefined,
  belongingsList: undefined,
  userInformation: undefined,
  contentCategory: undefined,
  contentTeacher: undefined,
  notifications: [],
}

export default handleActions(
  {
    [GET_STUDENT_GROUP_LIST]: (state, action) => ({
      ...state,
      studentGroupList: action.payload,
    }),
    [GET_REGION_LIST]: (state, action) => ({
      ...state,
      regionList: action.payload,
    }),
    [GET_ATTITUDE_LIST]: (state, action) => ({
      ...state,
      attitudeList: action.payload,
    }),
    [GET_AI_LIST]: (state, action) => ({
      ...state,
      aiList: action.payload,
    }),
    [GET_BELONGINGS_LIST]: (state, action) => ({
      ...state,
      belongingsList: action.payload,
    }),
    [GET_USER_INFORMATION]: (state, action) => ({
      ...state,
      userInformation: action.payload,
    }),
    [GET_CONTENT_CATEGORY]: (state, action) => ({
      ...state,
      contentCategory: action.payload,
    }),
    [GET_CONTENT_TEACHER]: (state, action) => ({
      ...state,
      contentTeacher: action.payload,
    }),
    [GET_NOTIFICATIONS]: (state, action) => ({
      ...state,
      notifications: action.payload,
    }),
  },
  initState
)

// 학습자 소속 그룹 조회
export const getStudentGroupListAction = param => async dispatch => {
  const result = await CommonApis.getStudentGroupList(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(getStudentGroupList(result.data))
    return true
  } else {
    return false
  }
}

// 지역 목록 조회
export const getRegionListAction = param => async dispatch => {
  const result = await CommonApis.getRegionList(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(getRegionList(result.data))
    return true
  } else {
    return false
  }
}

// 렛미업 상벌점 목록 조회
export const getAttitudeListAction = param => async dispatch => {
  const result = await CommonApis.getAttitudeList(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(getAttitudeList(result.data))
    return true
  } else {
    return false
  }
}

// AI 카메라 민감도 목록 조회
export const getAiListAction = param => async dispatch => {
  const result = await CommonApis.getAiList(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(getAiList(result.data))
    return true
  } else {
    return false
  }
}

// 소속 목록 조회
export const getBelongingsListAction = param => async dispatch => {
  const result = await CommonApis.getBelongingsList(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(getBelongingsList(result.data))
    return true
  } else {
    return false
  }
}

// 로그인 유저, 분류 및 이름
export const getUserInformationAction = param => async dispatch => {
  const result = await CommonApis.getUserInformation(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(getUserInformation(result.data))
    return true
  } else {
    return false
  }
}

// 파일 업로드 여러개
export const postUploadFilesAction = param => async dispatch => {
  const result = await CommonApis.postUploadFiles(param)
  if (result.code == 0 || result.code == 1) {
    return result.data.files
  } else {
    return false
  }
}

// 컨텐츠 브랜드명 및 카테고리 조회
export const getContentCategoryAction = param => async dispatch => {
  const result = await CommonApis.getContentCategory(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(getContentCategory(result.data))
    return true
  } else {
    return false
  }
}

// 컨텐츠 강사 이름 조회
export const getContentTeacherAction = param => async dispatch => {
  const result = await CommonApis.getContentTeacher(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(getContentTeacher(result.data))
    return true
  } else {
    return false
  }
}
// 공지사항 목록 조회
export const getAlarmNotificationsAction = param => async dispatch => {
  const result = await CommonApis.getAlarmNotifications(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(getNotifications(result.data.notifications))
    return true
  } else {
    return false
  }
}
// 공지사항 읽음 처리
export const postAlarmNotificationsReadAction = param => async dispatch => {
  const result = await CommonApis.postAlarmNotificationsRead(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else {
    return false
  }
}
