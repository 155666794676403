/*
  관리자 메인 페이지
*/
import React, { useState, useEffect, useRef } from "react"

import "swiper/scss"

import SEO from "../components/seo"
import Layout from "../components/Layout"

import checked_on from "../images/checked_on.svg"
import checked_off from "../images/checked_off.svg"
import download from "../images/download.svg"

import moment from "moment"

import TopInfo from "../components/common/TopInfo"
import PrivateRoute from "../components/privateRoute"
import AdminMainContainer from "../containers/AdminMainContainer"

import PieChart from "../components/common/PieChart"
import { dataCheckFn } from "../utils/util"

import { useDownloadExcel, DownloadTableExcel } from "react-export-table-to-excel/src"

const AdminMain = props => {
  const { adminMainCustomer, getStatisticsCustomer, adminMainStudy, getStatisticsStudentGroupStudy, getStatisticsSignInTime, adminMainSignInTime } =
    props
  //테이블용 더미
  const TITLE = ["", "학습자그룸", "총 누적 스터디 스케줄(개)", "소속", "이름"]
  const [dateMM, setDateMM] = useState(moment().format("MM"))
  const [studyDateMM, setStudyDateMM] = useState(moment().format("MM"))
  const [timeDateMM, setTimeDateMM] = useState(moment().format("MM"))
  const [joinDateMM, setJoinDateMM] = useState(moment().format("MM"))
  const [pieLabels, setPieLabels] = useState([])
  const [piedataArr, setPieDataArr] = useState([])

  const studyTable = useRef(null)
  const timeTable = useRef(null)
  const joinTable = useRef(null)

  const getData = async () => {
    await getStatisticsStudentGroupStudy(studyDateMM)
    await getStatisticsSignInTime(timeDateMM)
    await getStatisticsCustomer(joinDateMM)
  }
  const sumFn = (list, type) => {
    if (list.length == 0) return 0
    let sum = 0
    for (let i of list) {
      sum += i[type]
    }
    return sum
  }

  //멤버 sum arr
  const memberSum = () => {
    //비어있으면 빈 배열 리턴
    if (!(adminMainCustomer.length > 0)) return []
    let arr = []
    for (let t of Object.keys(adminMainCustomer[0])) {
      arr = [...arr, 0]
    }
    //0으로 세팅
    for (let i = 0; i < adminMainCustomer.length; i++) {
      for (let j = 0; j < Object.keys(adminMainCustomer[i]).length; j++) {
        //타입 == 첫칸 총합으로
        if (Object.keys(adminMainCustomer[i])[j] == "type") {
          arr[j] = "총합"
        } else if (Object.keys(adminMainCustomer[i])[j] == "rate" && adminMainCustomer.length - 1 == i) {
          //대비 %에 마지막 계산이면 /4해서 평균으로
          arr[j] += adminMainCustomer[i][Object.keys(adminMainCustomer[i])[j]]
          arr[j] = Math.floor(arr[j] / 4)
        } else {
          //더하기
          arr[j] += adminMainCustomer[i][Object.keys(adminMainCustomer[i])[j]]
        }
      }
    }
    return arr
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    memberSum()
  }, [adminMainCustomer])

  useEffect(() => {
    //파이데이터 제작
    if (dataCheckFn(adminMainStudy)) {
      let tempLa = []
      let tempData = []
      for (let i = 0; i < adminMainStudy.length - 1; i++) {
        let sum = 0
        for (let j = 0; j < adminMainStudy[i].dailyList.length; j++) {
          sum += adminMainStudy[i].dailyList[j].count
        }
        tempLa = [...tempLa, adminMainStudy[i].name]
        tempData = [...tempData, sum]
      }

      setPieLabels(tempLa)
      setPieDataArr(tempData)
    }
  }, [adminMainStudy])

  return (
    <PrivateRoute>
      <Layout>
        <SEO title="Main" />
        <div className="admin-main-container">
          <TopInfo rightView={false} title={[`${moment().format("YYYY/MM/DD")} 렛미업 현황`, "", ""]} />
          <div className="admin-main-con-1">
            <p className="sub-title">학습자 그룹 스터디 스케줄 개설 현황</p>
            <div className="admin-main-con-1-info-div">
              <div className="pie-chart">
                <PieChart labels={pieLabels} dataArr={piedataArr} />
              </div>
              <div className="table-div">
                <div className="table-top">
                  <div style={{ width: "120px", margin: "10px 5px" }}>
                    <select
                      value={studyDateMM}
                      className="form-select"
                      onChange={async e => {
                        setStudyDateMM(e.target.value)
                        await getStatisticsStudentGroupStudy(e.target.value)
                      }}
                    >
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i, idx) => (
                        <option key={idx} className="" value={i < 10 ? "0" + i : i}>
                          {i}월
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* <div style={{ display: "none" }}>
                    <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className=""
                      table="studyTable"
                      filename={`학습자 그룹 스터디 스케줄 개설 현황 ${studyDateMM}월`}
                      sheet={`학습자 그룹 스터디 스케줄 개설 현황 ${studyDateMM}월`}
                      buttonText={``}
                    ></ReactHTMLTableToExcel>
                  </div> */}
                  <DownloadTableExcel
                    filename={`학습자 그룹 스터디 스케줄 개설 현황 ${studyDateMM}월`}
                    sheet={`학습자 그룹 스터디 스케줄 개설 현황 ${studyDateMM}월`}
                    currentTableRef={studyTable.current}
                  >
                    {/* <Button className="btn fgb-btn-m fgb-btn-grean"> Export excel </Button> */}
                    <div
                      // onClick={() => document.getElementById("test-table-xls-button").click()}
                      className="btn fgb-btn-m fgb-btn-grean"
                    >
                      <img src={download} />
                      <p>엑셀 다운로드</p>
                    </div>
                  </DownloadTableExcel>
                  {/* <div onClick={() => document.getElementById("test-table-xls-button").click()} className="btn fgb-btn-m fgb-btn-grean">
                    <img src={download} />
                    <p>엑셀 다운로드</p>
                  </div> */}
                </div>
                <div className="fgb-table-div">
                  <table className={`fgb-table`}>
                    <thead>
                      <tr className={`fgb-table-title-tr`}>
                        {adminMainStudy.length > 0 &&
                          [
                            "",
                            "학습자 그룹",
                            "총 누적 스터디 스케줄(개)",
                            ...adminMainStudy[0].dailyList.map(i => moment(i.date).format("MM/DD")),
                            "전월 대비 %",
                          ].map((i, idx) => <td key={idx}>{i}</td>)}
                      </tr>
                    </thead>
                    <tbody>
                      {adminMainStudy.map((i, idx) => (
                        <tr className={`fgb-table-tr`} key={idx}>
                          <td>{idx + 1}</td>
                          <td>{i.name}</td>
                          <td>{sumFn(i.dailyList, "count")}</td>
                          {i.dailyList.map((i, index) => (
                            <td key={index}>{i.count}</td>
                          ))}

                          <td>{(i.rate || 0).toFixed()}%</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <table ref={studyTable} style={{ display: "none" }} id={"studyTable"} className={`fgb-table`}>
                    <tr>
                      <td> </td>
                    </tr>
                    <tr>
                      {adminMainStudy.length > 0 &&
                        [
                          "",
                          "학습자 그룹",
                          "총 누적 스터디 스케줄(개)",
                          ...adminMainStudy[0].dailyList.map(i => moment(i.date).format("MM/DD")),
                          "전월 대비 %",
                        ].map((i, idx) => <td key={idx}>{i}</td>)}
                    </tr>
                    {adminMainStudy.map((i, idx) => (
                      <tr className={`fgb-table-tr`} key={idx}>
                        <td>{idx + 1}</td>
                        <td>{i.name}</td>
                        <td>{sumFn(i.dailyList, "count")}</td>
                        {i.dailyList.map((i, index) => (
                          <td key={index}>{i.count}</td>
                        ))}

                        <td>{(i.rate || 0).toFixed()}%</td>
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="admin-main-con-2">
            <p className="sub-title">시간대별 이용 현황</p>
            <div className="admin-main-con-2-info-div">
              <div className="table-top">
                <div style={{ width: "120px", margin: "10px 5px" }}>
                  <select
                    value={timeDateMM}
                    className="form-select"
                    onChange={async e => {
                      setTimeDateMM(e.target.value)
                      await getStatisticsSignInTime(e.target.value)
                    }}
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i, idx) => (
                      <option key={idx} className="" value={i < 10 ? "0" + i : i}>
                        {i}월
                      </option>
                    ))}
                  </select>
                </div>
                {/* <div style={{ display: "none" }}>
                  <ReactHTMLTableToExcel
                    id="time-table-xls-button"
                    className=""
                    table="timeTable"
                    filename={`시간대별 이용 현황 ${studyDateMM}월`}
                    sheet={`시간대별 이용 현황 ${studyDateMM}월`}
                    buttonText={``}
                  ></ReactHTMLTableToExcel>
                </div>
                <div onClick={() => document.getElementById("time-table-xls-button").click()} className="btn fgb-btn-m fgb-btn-grean">
                  <img src={download} />
                  <p>엑셀 다운로드</p>
                </div> */}
                <DownloadTableExcel
                  filename={`시간대별 이용 현황 ${studyDateMM}월`}
                  sheet={`시간대별 이용 현황 ${studyDateMM}월`}
                  currentTableRef={timeTable.current}
                >
                  {/* <Button className="btn fgb-btn-m fgb-btn-grean"> Export excel </Button> */}
                  <div
                    // onClick={() => document.getElementById("test-table-xls-button").click()}
                    className="btn fgb-btn-m fgb-btn-grean"
                  >
                    <img src={download} />
                    <p>엑셀 다운로드</p>
                  </div>
                </DownloadTableExcel>
              </div>
              <div style={{ overflowY: "auto", maxHeight: "474px" }} className="fgb-table-div">
                <table className={`fgb-table`} id={"timeTable"}>
                  <thead>
                    <tr className={`fgb-table-title-tr`}>
                      {adminMainSignInTime.length > 0 &&
                        [
                          "",
                          ...adminMainSignInTime[0].countInTime.map((i, idx) => {
                            if (idx + 6 < 10) {
                              return `0${idx + 6}:00~`
                            } else if (idx < 18) {
                              return `${idx + 6}:00~`
                            } else {
                              return `0${idx - 18}:00~`
                            }
                          }),
                        ].map((i, idx) => <td key={idx}>{i}</td>)}
                    </tr>
                  </thead>
                  <tbody>
                    {adminMainSignInTime.map((i, idx) => (
                      <tr className={`fgb-table-tr`} key={idx}>
                        {idx == 0 ? (
                          <td>총 누적 접속자수</td>
                        ) : adminMainSignInTime.length - 1 > idx ? (
                          <td>{moment(i.date).format("MM/DD") + " 접속자수"}</td>
                        ) : (
                          <td>{"전월대비"}</td>
                        )}
                        {i.countInTime.map((data, index) => (
                          <td key={index}>{adminMainSignInTime.length - 1 > idx ? data.toFixed() : data.toFixed() + "%"}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table ref={timeTable} style={{ display: "none" }} className={`fgb-table`} id={"timeTable"}>
                  <tbody>
                    <tr>
                      <td></td>
                    </tr>
                    <tr className={`fgb-table-title-tr`}>
                      {adminMainSignInTime.length > 0 &&
                        [
                          "",
                          ...adminMainSignInTime[0].countInTime.map((i, idx) => {
                            if (idx + 6 < 10) {
                              return `0${idx + 6}:00~`
                            } else if (idx < 18) {
                              return `${idx + 6}:00~`
                            } else {
                              return `0${idx - 18}:00~`
                            }
                          }),
                        ].map((i, idx) => <td key={idx}>{i}</td>)}
                    </tr>
                    {adminMainSignInTime.map((i, idx) => (
                      <tr className={`fgb-table-tr`} key={idx}>
                        {idx == 0 ? (
                          <td>총 누적 접속자수</td>
                        ) : adminMainSignInTime.length - 1 > idx ? (
                          <td>{moment(i.date).format("MM/DD") + " 접속자수"}</td>
                        ) : (
                          <td>{"전월대비"}</td>
                        )}
                        {i.countInTime.map((data, index) => (
                          <td key={index}>{adminMainSignInTime.length - 1 > idx ? data.toFixed() : data.toFixed() + "%"}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="admin-main-con-2">
            <p className="sub-title">회원 가입 현황</p>
            <div className="admin-main-con-2-info-div">
              <div className="table-top">
                <div style={{ width: "120px", margin: "10px 5px" }}>
                  <select
                    value={joinDateMM}
                    className="form-select"
                    onChange={async e => {
                      await getStatisticsCustomer(e.target.value)
                      setJoinDateMM(e.target.value)
                    }}
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i, idx) => (
                      <option key={idx} className="" value={i < 10 ? "0" + i : i}>
                        {i}월
                      </option>
                    ))}
                  </select>
                </div>
                {/* <div style={{ display: "none" }}>
                  <ReactHTMLTableToExcel
                    id="member-table-xls-button"
                    className=""
                    table="joinTable"
                    filename={`회원 가입 현황 ${joinDateMM}월`}
                    sheet={`회원 가입 현황 ${joinDateMM}월`}
                    buttonText={``}
                  ></ReactHTMLTableToExcel>
                </div>
                <div onClick={() => document.getElementById("member-table-xls-button").click()} className="btn fgb-btn-m fgb-btn-grean">
                  <img src={download} />
                  <p>엑셀 다운로드</p>
                </div> */}
                <DownloadTableExcel
                  filename={`회원 가입 현황 ${joinDateMM}월`}
                  sheet={`회원 가입 현황 ${joinDateMM}월`}
                  currentTableRef={joinTable.current}
                >
                  {/* <Button className="btn fgb-btn-m fgb-btn-grean"> Export excel </Button> */}
                  <div
                    // onClick={() => document.getElementById("test-table-xls-button").click()}
                    className="btn fgb-btn-m fgb-btn-grean"
                  >
                    <img src={download} />
                    <p>엑셀 다운로드</p>
                  </div>
                </DownloadTableExcel>
              </div>
              <div className="fgb-table-div">
                <table id={"joinTable"} className={`fgb-table`}>
                  <thead>
                    <tr className={`fgb-table-title-tr`}>
                      {adminMainCustomer.length > 0 &&
                        [...Object.keys(adminMainCustomer[0])].map((i, idx) => (
                          <td key={idx}>{i == "accumulatedCustomerCount" ? "총 누적 회원" : i == "type" ? "" : i == "rate" ? "전월 대비 %" : i}</td>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {adminMainCustomer.length > 0 &&
                      adminMainCustomer.map((i, idx) => (
                        <tr className={`fgb-table-tr`} key={idx}>
                          {Object.keys(i).map((data, index) => {
                            if (data == "type") {
                              return (
                                <td key={index}>
                                  {i[data] == "PERSON"
                                    ? "개인"
                                    : i[data] == "STUDY_CAFE"
                                    ? "스터디 카페"
                                    : i[data] == "CRAM_SCHOOL"
                                    ? "학원"
                                    : "컨설턴트"}
                                </td>
                              )
                            } else {
                              return <td key={index}>{Math.floor(i[data])}</td>
                            }
                          })}
                        </tr>
                      ))}
                    <tr className={`fgb-table-tr`}>
                      {memberSum().map((i, idx) => (
                        <td key={idx}>{i}</td>
                      ))}
                    </tr>
                  </tbody>
                </table>
                <table ref={joinTable} style={{ display: "none" }} id={"joinTable"} className={`fgb-table`}>
                  <tbody>
                    <tr>
                      <td></td>
                    </tr>
                    <tr className={`fgb-table-title-tr`}>
                      {adminMainCustomer.length > 0 &&
                        [...Object.keys(adminMainCustomer[0])].map((i, idx) => (
                          <td key={idx}>{i == "accumulatedCustomerCount" ? "총 누적 회원" : i == "type" ? "" : i == "rate" ? "전월 대비 %" : i}</td>
                        ))}
                    </tr>
                    {adminMainCustomer.length > 0 &&
                      adminMainCustomer.map((i, idx) => (
                        <tr className={`fgb-table-tr`} key={idx}>
                          {Object.keys(i).map((data, index) => {
                            if (data == "type") {
                              return (
                                <td key={index}>
                                  {i[data] == "PERSON"
                                    ? "개인"
                                    : i[data] == "STUDY_CAFE"
                                    ? "스터디 카페"
                                    : i[data] == "CRAM_SCHOOL"
                                    ? "학원"
                                    : "컨설턴트"}
                                </td>
                              )
                            } else {
                              return <td key={index}>{Math.floor(i[data])}</td>
                            }
                          })}
                        </tr>
                      ))}
                    <tr className={`fgb-table-tr`}>
                      {memberSum().map((i, idx) => (
                        <td key={idx}>{i}</td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default AdminMainContainer(AdminMain)
