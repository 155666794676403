import api from "../utils/api"

// 운영관리

//컨텐츠-브랜드명 및 카테고리
const getContentCategorys = async data => {
  const result = await api.get(`/content-categorys`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//학습자 그룹 목록
const getStudentGroups = async data => {
  const result = await api.get(`/student-groups`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//학습자 유형 관리 목록
const getCategoryStudentGroups = async data => {
  const result = await api.get(
    `/category/student-groups?dateType=${data.dateType}&dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&studentGroupSeqDepth1=${data.studentGroupSeqDepth1}&studentGroupSeqDepth2=${data.studentGroupSeqDepth2}&page=${data.page}&count=${data.count}`,
    { token: true }
  )
  if (result) {
    return result.data
  } else return false
}
//학습자 유형 관리 정보
const getCategoryStudentGroup = async data => {
  const result = await api.get(`/category/student-groups/${data}`, { token: true })
  if (result) {
    return result.data
  } else return false
}

// 관리자 유형 수정
const putCategoryStudentGroups = async data => {
  const result = await api.put(`/category/student-groups`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
// 관리자 유형 추가
const postCategoryStudentGroups = async data => {
  const result = await api.post(`/category/student-groups`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
// 관리자 유형 삭제
const delCategoryStudentGroups = async data => {
  const result = await api.delete(`/category/student-groups`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
// 제휴사 관리 목록
const getPartnersList = async data => {
  const result = await api.get(
    `/partners?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&status=${data.status}&type=${data.type}&brandName=${data.brandName}&nameType=${data.nameType}&nameWord=${data.nameWord}&page=${data.page}&count=${data.count}`,
    { token: true }
  )
  if (result) {
    return result.data
  } else return false
}
// 제휴사 관리 목록 excel download
const getPartnersListExcel = async data => {
  const result = await api.get(
    `/partners/excel/download?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&status=${data.status}&type=${data.type}&brandName=${data.brandName}&nameType=${data.nameType}&nameWord=${data.nameWord}&page=${data.page}&count=${data.count}`,
    { token: true, options: { responseType: "blob" } }
  )
  if (result) {
    return result.data
  } else return false
}
// 제휴사 관리 브랜드 목록
const getBelongings = async data => {
  const result = await api.get(`/belongings`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//제휴사 정보 get
const getPartners = async data => {
  const result = await api.get(`/partners/${data}`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//제휴사 정보 권한 put
const putPartners = async data => {
  const result = await api.put(`/partners`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//제휴사 정보 선택 삭제
const delPartners = async data => {
  const result = await api.delete(`/partners`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//키워드관리
const getKeywordList = async data => {
  const result = await api.get(
    `/keyword/managements?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&tag=${data.tag}&page=${data.page}&count=${data.count}`,
    {
      token: true,
    }
  )
  if (result) {
    return result.data
  } else return false
}
//키워드 선택 삭제
const delKeyword = async data => {
  const result = await api.delete(`/keyword/managements`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//키워드관리 내역 목록 xl
const getKeywordXlDown = async data => {
  const result = await api.get(`/keyword/managements/excel/download?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&tag&page=0&count=9999999`, {
    body: data,
    token: true,
    options: { responseType: "blob" },
  })
  if (result) {
    return result.data
  } else return false
}
//컨첸트 카테고리 관리
//브랜드명 카테고리
const getBrandCategory = async data => {
  const result = await api.get(`/brand-category`, {
    token: true,
  })
  if (result) {
    return result.data
  } else return false
}
//컨텐츠 카테고리 조회
const getCategoryContents = async data => {
  const result = await api.get(
    `/category/contents?brandName=${data.brandName}&depth1=${data.depth1}&depth2=${data.depth2}&depth3=${data.depth3}&depth4=${data.depth4}`,
    {
      token: true,
    }
  )
  if (result) {
    return result.data
  } else return false
}
//컨텐츠 카테고리 삭제
const delCategoryContents = async data => {
  const result = await api.delete(`/category/contents`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//컨텐츠 카테고리 추가
const postCategoryContents = async data => {
  const result = await api.post(`/category/contents`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//컨텐츠 카테고리 엑셀 업로드
const postCategoryContentsExcelUpload = async data => {
  const result = await api.post(`/category/contents/excel/upload`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//공지사항 관리
//공지사항 목록 GET
const getNotifications = async data => {
  const result = await api.get(
    `/notifications?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&type=${data.type}&page=${data.page}&count=${data.count}`,
    {
      token: true,
    }
  )
  if (result) {
    return result.data
  } else return false
}
//공지사항 목록 Del
const delNotifications = async data => {
  const result = await api.delete(`/notifications`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else return false
}
//공지사항 타겟 GET
const getTargetNotifications = async data => {
  const result = await api.get(`/notifications/${data}`, {
    token: true,
  })
  if (result) {
    return result.data
  } else return false
}
//공지사항 상세 put
const putTargetNotifications = async data => {
  const result = await api.put(`/notifications`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else return false
}
//공지사항 상세 post
const postTargetNotifications = async data => {
  const result = await api.post(`/notifications`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else return false
}

//렛미업 포인트 관리
//렛미업 포인트 관리 목록 조회
const getPointRules = async data => {
  const result = await api.get(
    `/point/rules?dateType=${data.dateType}&dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&isUse=${data.isUse}&isExpiration=${data.isExpiration}&frequencyType=${data.frequencyType}&pointType=${data.pointType}`,
    {
      token: true,
    }
  )
  if (result) {
    return result.data
  } else return false
}
//렛미업 포인트 관리 정보 조회
const getPointRule = async data => {
  const result = await api.get(`/point/rule?pointRuleSeq=${data}`, {
    token: true,
  })
  if (result) {
    return result.data
  } else return false
}
//렛미업 포인트 관리 정보 수정
const putPointRule = async data => {
  const result = await api.put(`/point/rule`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else return false
}

//AI학습규칙 관리
//학습규칙 목록 조회
const getRuleLearnAttitudes = async data => {
  const result = await api.get(
    `/rule/learn-attitudes?dateType=${data.dateType}&dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&pushType=${data.pushType}&isUse=${data.isUse}`,
    {
      token: true,
    }
  )
  if (result) {
    return result.data
  } else return false
}
//학습규칙 정보 조회
const getRuleLearnAttitude = async data => {
  const result = await api.get(`/rule/learn-attitude?learnAttitudeRuleSeq=${data}`, {
    token: true,
  })
  if (result) {
    return result.data
  } else return false
}
//학습규칙 정보 수정
const putRuleLearnAttitude = async data => {
  const result = await api.put(`/rule/learn-attitude`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else return false
}
//ai 목록 조회
const getRuleAISenstives = async data => {
  const result = await api.get(
    `/rule/ai-sensitives?dateType=${data.dateType}&dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&pushType=${data.pushType}&isUse=${data.isUse}`,
    {
      token: true,
    }
  )
  if (result) {
    return result.data
  } else return false
}
//ai 정보 조회
const getRuleAISenstive = async data => {
  const result = await api.get(`/rule/ai-sensitive?aiSensitiveRuleSeq=${data}`, {
    token: true,
  })
  if (result) {
    return result.data
  } else return false
}
//ai 정보 수정
const putRuleAISensitive = async data => {
  const result = await api.put(`/rule/ai-sensitive`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else return false
}

// admin로그인 회원가입
// const postSignIn = async data => {
//   const result = await api.post(`/signin`, { token: false, body: data })
//   if (result) {
//     return result.data
//   } else return false
// }

export default {
  getPartners,
  getContentCategorys,
  getCategoryStudentGroups,
  getStudentGroups,
  getCategoryStudentGroup,
  putCategoryStudentGroups,
  postCategoryStudentGroups,
  delCategoryStudentGroups,
  getPartnersList,
  getBelongings,
  putPartners,
  delPartners,
  getKeywordList,
  delKeyword,
  getKeywordXlDown,
  getBrandCategory,
  getCategoryContents,
  delCategoryContents,
  postCategoryContents,
  postCategoryContentsExcelUpload,
  getNotifications,
  delNotifications,
  getTargetNotifications,
  putTargetNotifications,
  postTargetNotifications,
  getPointRules,
  getPointRule,
  putPointRule,
  getRuleLearnAttitudes,
  getRuleAISenstives,
  getRuleLearnAttitude,
  getRuleAISenstive,
  putRuleLearnAttitude,
  putRuleAISensitive,
  getPartnersListExcel,
}
