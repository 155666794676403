/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect } from "react"

import "swiper/scss"

import BottomInfo from "../components/login/BottomInfo"

import SEO from "../components/seo"
import Layout from "../components/Layout"

import checked_on from "../images/checked_on.svg"
import checked_off from "../images/checked_off.svg"

import signupFin from "../images/signupFin.png"
import Validator from "validator"

import ModalPopup from "../popups/ModalPopup"
import ZipCodePopup from "../popups/ZipCodePopup"
import SignTermsPopup from "../popups/SignTermsPopup"

import SignContainer from "../containers/SignContainer"
import { useDispatch, useSelector } from "react-redux"
import { debounce } from "lodash"
import { dataCheckFn, popupFn } from "../utils/util"
import { navigate } from "gatsby-link"
//제휴사 회원가입
const AffiliatesSignup = props => {
  const { postSignUp, approchList, getMenusApprochPartner, term, getTerm, postAuthNumber, authLog, setAuthLogSeqEmpty, postAuthNumberCheck } = props
  const dispatch = useDispatch()
  const [step, setStep] = useState(0)
  //
  const [phoneNo, setPhoneNo] = useState("")
  const [authNo, setAuthNo] = useState("")
  const [phoneAlerts, setPhoneAlerts] = useState(false)
  const [affiliatesType, setSffiliatesType] = useState(0)
  const [affiliatesName, setSffiliatesName] = useState("")
  const [businessNumber, setBusinessNumber] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  // 서비스 약관동의
  const [serviceCheck, setServiceCheck] = useState(false)
  const [privacyCheck, setPrivacyCheck] = useState(false)
  const [marketing, setMarketing] = useState(false)

  // 알림 설정
  const [alarmToggle, setAlarmToggle] = useState(false)
  const [alarmCheck, setAlarmCheck] = useState({ email: false, sms: false })

  // 내 공부 장소
  const [searchText, setSearchText] = useState("")
  const [address, setAddress] = useState({
    addressNew: "",
    addressOld: "",
    detail: "",
    zipcode: "",
    nickname: "",
    region1: "",
    region2: "",
    region3: "",
    roadAddress: "",
  })

  const [minutes, setMinutes] = useState(3)
  const [seconds, setSeconds] = useState(0)
  const [errText, setErrText] = useState("")
  const [userAuthLogSeq, setuserAuthLogSeq] = useState("")
  const [authClick, setAuthClick] = useState(false)

  const [openZipPopup, setOpenZipPopup] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openModalText, setOpenModalText] = useState(false)
  const [openTermPopup, setOpenTermPopup] = useState({ open: false, idx: null })

  const [authorityList, setAuthorityList] = useState([])
  const [authorityListAll, setAuthorityListAll] = useState(false)
  const [authorityMessage, setAuthorityMessage] = useState(false)
  //권한
  const [member, setMember] = useState(0)
  const [todoSchedule, setTodoSchedule] = useState(0)
  const [studySchedule, setStudySchedule] = useState(0)
  const [serviceCon, setServiceCon] = useState(0)
  const [serviceKoo, setServiceKoo] = useState(0)
  const [message, setMessage] = useState(0)
  // 컨설턴트 추천
  const [consultantRegion, setConsultantRegion] = useState("")
  const [consultantGrade, setConsultantGrade] = useState("")
  const [consultantSubject, setConsultantSubject] = useState("")
  //getdata 약관
  const getData = async () => {
    await getTerm()
    await getMenusApprochPartner()
  }

  //타이머
  useEffect(() => {
    const countdown = setInterval(() => {
      if (parseInt(seconds) > 0) {
        setSeconds(parseInt(seconds) - 1)
      }
      if (parseInt(seconds) === 0) {
        if (parseInt(minutes) === 0) {
          clearInterval(countdown)
        } else {
          setMinutes(parseInt(minutes) - 1)
          setSeconds(59)
        }
      }
    }, 1000)
    return () => clearInterval(countdown)
  }, [minutes, seconds])
  //약관
  // const getData = async () => {
  //   await getTerm()
  // }
  //코드 요청
  const reqCode = debounce(async () => {
    if (Validator.isMobilePhone(phoneNo, "ko-KR")) {
      let result = await postAuthNumber({ phone: phoneNo })
      if (result != false) {
        //타이머초기화
        setMinutes(3)
        setSeconds(0)
        //타이머 on
        setAuthClick(true)
        //alerts off
        setPhoneAlerts(false)
        // setUserPhoneNum(phoneNo)
      }
    } else {
      setErrText("휴대전화 번호를 정확히 입력해주세요.")
      setPhoneAlerts(true)
    }
  }, 500)
  // 휴대폰 인증 완료
  const onClickPhoneAuthComplete = debounce(async () => {
    if (!dataCheckFn(authLog)) {
      setErrText("인증을 진행해주세요.")
      setPhoneAlerts(true)
      return false
    }
    let temp = {
      authNumber: authNo,
      authLogSeq: authLog.authLogSeq,
    }
    let result = await postAuthNumberCheck(temp)
    if (result) {
      //가입유저인지 체크
      if (authLog.status == "NONE") {
        setStep(1)
        setPhoneAlerts(false)
        // document.getElementsByClassName("layer-popup-layout")[0].scrollTo(0, 0)
      } else {
        // setOpenUserCheck(true)
        setErrText("가입된 회원 또는 승인대기중인 회원입니다.")
        setPhoneAlerts(true)
        return false
      }
    } else {
      setErrText("인증 번호를 정확히 입력해주세요.")
      setPhoneAlerts(true)
    }
  }, 500)
  // 서비스 약관동의 완료
  const onClickTermsComplete = () => {
    if (serviceCheck && privacyCheck) {
      setStep(2)
    } else {
      return false
    }
    // document.getElementsByClassName("layer-popup-layout active")[0].scrollTo(0, 0)
  }

  // 프로필 설정 완료
  const onClickProflieComplete = () => {
    if (Validator.isEmpty(affiliatesName, { ignore_whitespace: true })) {
      setOpenModalText("상호명을 입력해주세요.")
      setOpenModal(true)
      return false
    }
    const reg1 = /^(?:[0-9]{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1,2][0-9]|3[0,1]))-[1-4][0-9]{6}$/
    if (Validator.isEmpty(businessNumber, { ignore_whitespace: true })) {
      setOpenModalText("사업자번호 혹은 주민번호를 입력해주세요")
      setOpenModal(true)
      return false
    }
    if (Validator.isEmpty(address.addressOld, { ignore_whitespace: true })) {
      setOpenModalText("주소찾기로 주소를 입력해주세요")
      setOpenModal(true)
      return false
    }
    if (Validator.isEmpty(address.detail, { ignore_whitespace: true })) {
      setOpenModalText("상세주소를 입력해주세요")
      setOpenModal(true)
      return false
    }
    if (Validator.isEmpty(address.nickname, { ignore_whitespace: true })) {
      setOpenModalText("저장하실 주소의 닉네임을 입력해주세요")
      setOpenModal(true)
      return false
    }
    if (Validator.isEmpty(name, { ignore_whitespace: true })) {
      setOpenModalText("이름을 입력해주세요")
      setOpenModal(true)
      return false
    }
    if (!Validator.isEmail(email, { ignore_whitespace: true })) {
      setOpenModalText("정확한 이메일을 입력해주세요")
      setOpenModal(true)
      return false
    }
    if (Validator.isEmpty(consultantRegion, { ignore_whitespace: true })) {
      setOpenModalText("컨설턴트 추천 지역을 선택해주세요")
      setOpenModal(true)
      return false
    }
    if (Validator.isEmpty(consultantGrade, { ignore_whitespace: true })) {
      setOpenModalText("컨설턴트 추천 학년을 선택해주세요")
      setOpenModal(true)
      return false
    }
    if (Validator.isEmpty(consultantSubject, { ignore_whitespace: true })) {
      setOpenModalText("컨설턴트 추천 과목을 선택해주세요")
      setOpenModal(true)
      return false
    }

    setStep(3)
  }
  const signupFn = async () => {
    let temp = []

    // 권한 설정 flow
    // 권한 : 0 없음, 1 조회, 3 조회 수정
    // 0의 경우 권한 없음
    // 1 경우 조회 권한만
    // 1 보다 클경우 조회 + 수정권한 seq를 temp에 저장후 가입시 partnerMenuRequests에 넣어서 전송

    //회원관리
    if (member > 0) {
      for (let i of approchList[0].menu2) {
        if (member == 1) {
          if (i.menu2Name == "회원 조회") {
            temp = [...temp, i.menu2Seq]
          }
        } else {
          temp = [...temp, i.menu2Seq]
        }
      }
    }
    //스터디스케줄 todo
    if (todoSchedule > 0) {
      for (let i of approchList[1].menu2) {
        if (i.menu2Name == "스케줄 신청목록 조회") {
          temp = [...temp, i.menu2Seq]
        } else if (i.menu2Name == "스케줄 신청목록 승인/반려") {
          temp = [...temp, i.menu2Seq]
        }
      }
    }
    //스터디스케줄
    if (studySchedule > 0) {
      for (let i of approchList[1].menu2) {
        if (studySchedule == 1) {
          if (i.menu2Name == "스터디스케줄 조회" || i.menu2Name == "스터디스케줄 조회 ") {
            temp = [...temp, i.menu2Seq]
          }
        } else {
          if (i.menu2Name == "스터디스케줄 조회" || i.menu2Name == "스터디스케줄 조회 ") {
            temp = [...temp, i.menu2Seq]
          } else if (i.menu2Name == "스터디스케줄 등록/변경") {
            temp = [...temp, i.menu2Seq]
          }
        }
      }
    }
    //서비스 상품 관리
    if (serviceCon > 0 || serviceKoo > 0) {
      for (let i of approchList[2].menu2) {
        if (serviceCon >= 1 || serviceKoo >= 1) {
          if (i.menu2Name == "서비스상품 조회") {
            temp = [...temp, i.menu2Seq]
          }
        }
        if (serviceCon >= 3 && i.menu2Name == "컨텐츠 등록/변경") {
          temp = [...temp, i.menu2Seq]
        }
        if (serviceKoo >= 3 && i.menu2Name == "꾸미기아이템 등록/변경") {
          temp = [...temp, i.menu2Seq]
        }
      }
    }
    if (message > 0) {
      for (let i of approchList[3].menu2) {
        temp = [...temp, i.menu2Seq]
      }
    } else {
      for (let i of approchList[3].menu2) {
        if (i.menu2Name != "메시지 조회" && i.menu2Name != "메시지 등록/변경") {
          temp = [...temp, i.menu2Seq]
        }
      }
    }
    console.log(temp)
    //소속 기관 유형 :'STUDY_CAFE','CRAM_SCHOOL','CONSULTANT'
    let termList = []
    if (serviceCheck) termList = [...termList, term.USE]
    if (privacyCheck) termList = [...termList, term.PRIVACY]
    if (marketing) termList = [...termList, term.MARKETING]
    let signupData = {
      authLogSeq: authLog.authLogSeq,
      phone: phoneNo,
      type: affiliatesType == 0 ? "CRAM_SCHOOL" : affiliatesType == 1 ? "STUDY_CAFE" : "CONSULTANT",
      brandName: affiliatesName,
      businessNumber: businessNumber,
      name: name,
      email: email,
      address: {
        addressNew: address.addressNew,
        addressOld: address.addressOld,
        region1: address.region1,
        region2: address.region2,
        region3: address.region3,
        detail: address.detail,
        nickname: address.nickname,
        zipcode: address.zipcode,
      },
      termSeqs: termList,
      partnerMenuRequests: temp,
    }
    // console.log(signupData)
    let result = await postSignUp(signupData)
    if (result) {
      setStep(4)
    }
  }

  //약관 토글
  const onClickTermsToggle = () => {
    if (serviceCheck && privacyCheck && marketing) {
      setServiceCheck(false)
      setPrivacyCheck(false)
      setMarketing(false)
    } else {
      setServiceCheck(true)
      setPrivacyCheck(true)
      setMarketing(true)
    }
  }

  //메뉴 접근 승인 요청 완료 (회원가입)

  const onCompleteFindAddress = data => {
    // console.log("data", data)
    setAddress({
      addressNew: data.roadAddress,
      addressOld: data.jibunAddress != "" ? data.jibunAddress : data.roadAddress,

      detail: "",
      zipcode: data.zonecode,
      nickname: "",
      region1: data.sido,
      region2: data.sigungu,
      region3: data.bname,
    })
    setOpenZipPopup(false)
  }
  useEffect(() => {
    //인증정보 초기화
    setAuthLogSeqEmpty()
    //getdata
    getData()
  }, [])

  return (
    <Layout isLeftBar={false} isContainer={false}>
      <SEO title="Login" />
      {/* <TermsPopup /> */}
      <ModalPopup
        open={openModal}
        contents={openModalText}
        onClose={() => {
          setOpenModal(false)
          setOpenModalText("")
        }}
      />
      <SignTermsPopup open={openTermPopup.open} onClose={() => setOpenTermPopup({ open: false, idx: null })} idx={openTermPopup.idx} />
      <ZipCodePopup open={openZipPopup} onClose={() => setOpenZipPopup(false)} onComplete={onCompleteFindAddress} defaultQuery={searchText} />

      <div className="affiliates-signup">
        <div className="affiliates-signup-container">
          {step < 4 && (
            <div className="affiliates-signup-menu-tab">
              <div
                onClick={() => {
                  setStep(0)
                }}
                className={step == 0 ? "active" : ""}
              >
                사용자 인증
              </div>
              <div
                onClick={() => {
                  if (step > 1) {
                    setStep(1)
                  } else {
                    popupFn("앞단계로는 건너뛸 수 없습니다.")
                  }
                }}
                className={step == 1 ? "active" : ""}
              >
                약관 동의
              </div>
              <div
                onClick={() => {
                  if (step > 2) {
                    setStep(2)
                  } else {
                    popupFn("앞단계로는 건너뛸 수 없습니다.")
                  }
                }}
                className={step == 2 ? "active" : ""}
              >
                프로필 작성
              </div>
              <div
                onClick={() => {
                  if (step > 3) {
                    setStep(3)
                  } else {
                    popupFn("앞단계로는 건너뛸 수 없습니다.")
                  }
                }}
                className={step == 3 ? "active" : ""}
              >
                메뉴접근 승인요청
              </div>
            </div>
          )}

          {/* 휴대폰 인증 */}
          {step === 0 && (
            <div className="affiliates-signup-div mt-3">
              <div className="affiliates-signup-phone">
                <p className="affiliates-login-sub-title">LET ME UP 관리자 시스템</p>
                <p className="top-title mt-2">
                  학습집중도 · 주도적인 학습 습관을 키워주는
                  <br />
                  AI 개인 맞춤형 학습 멘토 서비스
                </p>
                <p className="affiliates-signup-phone-sm-p mt-5">휴대폰번호</p>
                <div className="d-flex">
                  <input
                    value={phoneNo}
                    onChange={e => setPhoneNo(e.target.value)}
                    type="text"
                    placeholder="‘-’ 없이 휴대전화번호를 입력해주세요."
                    className="fgb-input"
                  />
                  <button onClick={() => reqCode()} className="btn btn-primary font-white fgb-btn-m ms-2 flex-shrink-0">
                    {authClick == true ? "인증번호 재요청" : "인증번호 요청"}
                  </button>
                </div>
                <p className="affiliates-signup-phone-sm-p mt-4">인증번호</p>
                <div className="d-flex">
                  <input
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        onClickPhoneAuthComplete()
                      }
                    }}
                    value={authNo}
                    onChange={e => setAuthNo(e.target.value)}
                    type="text"
                    placeholder={
                      authClick == true ? `${minutes}:${seconds < 10 ? "0" + seconds : seconds}` : "문자로 전송된 인증번호를 입력해주세요."
                    }
                    className="fgb-input"
                  />
                  <div onClick={onClickPhoneAuthComplete} className="btn btn-primary font-white fgb-btn-m ms-2 flex-shrink-0">
                    인증하기
                  </div>
                </div>
                {phoneAlerts && (
                  <div style={{ marginTop: "3px" }} className="alert alert-danger">
                    {errText}
                  </div>
                )}
                <div className="d-flex justify-content-center mt-5">
                  <button className="btn btn-primary font-white fgb-btn-l" style={{ width: "100%" }} onClick={onClickPhoneAuthComplete}>
                    다음
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* 서비스 약관동의 */}
          {step === 1 && (
            <div className="affiliates-signup-div mt-3">
              <div className="affiliates-signup-term">
                <div className="d-flex align-items-center">
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={onClickTermsToggle}
                    src={serviceCheck && privacyCheck && marketing ? checked_on : checked_off}
                  />
                  <p className="affiliates-signup-term-p ms-2">모든 약관에 동의합니다.</p>
                </div>
                <div className="affiliates-signup-term-item-view mt-3">
                  <div className="affiliates-signup-term-item">
                    <img style={{ cursor: "pointer" }} onClick={() => setServiceCheck(!serviceCheck)} src={serviceCheck ? checked_on : checked_off} />
                    <div className="ms-3">
                      <div className="checkbox-view">
                        <p className="red-p">[필수]</p>
                        <p style={{ cursor: "pointer" }} onClick={() => setOpenTermPopup({ open: true, idx: 0 })} className="text-p">
                          렛미업 제휴사 서비스 이용약관에 동의합니다.
                        </p>
                      </div>
                      <div
                        className="button-view"
                        // onClick={() => dispatch(setTermsPopup({ open: true, type: 0 }))}
                      >
                        {/* <p>전문보기</p> */}
                      </div>
                    </div>
                  </div>
                  <div className="affiliates-signup-term-item">
                    <img style={{ cursor: "pointer" }} onClick={() => setPrivacyCheck(!privacyCheck)} src={privacyCheck ? checked_on : checked_off} />
                    <div className="ms-3">
                      <div className="checkbox-view">
                        <p className="red-p">[필수]</p>
                        <p style={{ cursor: "pointer" }} onClick={() => setOpenTermPopup({ open: true, idx: 1 })} className="text-p">
                          렛미업 제휴사 개인정보 처리방침에 동의합니다.
                        </p>
                      </div>
                      <div
                        className="button-view"
                        // onClick={() => dispatch(setTermsPopup({ open: true, type: 0 }))}
                      >
                        {/* <p>전문보기</p> */}
                      </div>
                    </div>
                  </div>
                  <div className="affiliates-signup-term-item">
                    <img style={{ cursor: "pointer" }} onClick={() => setMarketing(!marketing)} src={marketing ? checked_on : checked_off} />
                    <div className="ms-3">
                      <div className="checkbox-view">
                        <p className="red-p">[선택]</p>
                        <p style={{ cursor: "pointer" }} onClick={() => setOpenTermPopup({ open: true, idx: 2 })} className="text-p">
                          렛미업 제휴사 마케팅 정보수신에 동의합니다.
                        </p>
                      </div>
                      <div
                        className="button-view"
                        // onClick={() => dispatch(setTermsPopup({ open: true, type: 0 }))}
                      >
                        {/* <p>전문보기</p> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-5">
                  <button className="btn btn-primary font-white fgb-btn-l" style={{ width: "100%" }} onClick={onClickTermsComplete}>
                    다음
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* 프로필 설정 */}
          {step === 2 && (
            <div className="affiliates-signup-div mt-3">
              <div className="affiliates-signup-phone">
                <p className="affiliates-signup-phone-sm-p ">소속기관 유형</p>
                <select
                  className="form-select"
                  value={affiliatesType}
                  onChange={e => {
                    setSffiliatesType(e.target.value)
                  }}
                >
                  <option className="" value="0">
                    학원
                  </option>
                  <option className="" value="1">
                    스터디 카페
                  </option>
                  <option className="" value="2">
                    컨설턴트
                  </option>
                </select>
                <p className="affiliates-signup-phone-sm-p mt-4">소속기관 이름 (상호명)</p>
                <input
                  value={affiliatesName}
                  onChange={e => setSffiliatesName(e.target.value)}
                  type="text"
                  placeholder="개인의 경우, 이름을 입력해주세요"
                  className="fgb-input"
                />
                <p className="affiliates-signup-phone-sm-p mt-4">사업자 번호</p>
                <input
                  value={businessNumber}
                  onChange={e => setBusinessNumber(e.target.value)}
                  type="text"
                  placeholder="개인의 경우, 주민등록번호를 입력해주세요"
                  className="fgb-input"
                />
                <div className="d-flex justify-content-between align-items-center mt-3 mb-1">
                  <p className="affiliates-signup-phone-sm-p">주소</p>
                  <button onClick={() => setOpenZipPopup(true)} className="btn btn-primary font-white fgb-btn-m">
                    주소 찾기
                  </button>
                </div>
                <input value={address.addressOld} disabled type="text" placeholder="우편번호 + 기본 주소 입력" className="fgb-input" />
                <input
                  value={address.detail}
                  onChange={e => {
                    setAddress({ ...address, detail: e.target.value })
                  }}
                  type="text"
                  placeholder="상세 주소 입력"
                  className="fgb-input mt-1"
                />
                <input
                  value={address.nickname}
                  onChange={e => {
                    setAddress({ ...address, nickname: e.target.value })
                  }}
                  type="text"
                  placeholder="주소 닉네임 입력"
                  className="fgb-input mt-1"
                />
                <p className="affiliates-signup-phone-sm-p mt-4">이름</p>
                <input value={name} onChange={e => setName(e.target.value)} type="text" placeholder="이름을 입력해주세요" className="fgb-input" />
                <p className="affiliates-signup-phone-sm-p mt-4">이메일</p>
                <input
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  type="text"
                  placeholder="주로 사용하는 이메일 주소를 입력해주세요"
                  className="fgb-input"
                />
                <p className="affiliates-signup-phone-sm-p mt-4">컨설턴트 추천</p>
                <div>
                  <select
                    required
                    className="form-select"
                    value={consultantRegion}
                    onChange={e => {
                      setConsultantRegion(e.target.value)
                    }}
                  >
                    <option value="" disabled selected>
                      지역
                    </option>
                    <option className="" value="0">
                      학원
                    </option>
                    <option className="" value="1">
                      스터디 카페
                    </option>
                    <option className="" value="2">
                      컨설던트
                    </option>
                  </select>
                  <select
                    className="form-select"
                    value={consultantGrade}
                    onChange={e => {
                      setConsultantGrade(e.target.value)
                    }}
                  >
                    <option value="" disabled selected>
                      학년{" "}
                    </option>
                    <option className="" value="0">
                      학원
                    </option>
                    <option className="" value="1">
                      스터디 카페
                    </option>
                    <option className="" value="2">
                      컨설던트
                    </option>
                  </select>
                  <select
                    className="form-select"
                    value={consultantSubject}
                    onChange={e => {
                      setConsultantSubject(e.target.value)
                    }}
                  >
                    <option value="" disabled selected>
                      과목
                    </option>
                    <option className="" value="0">
                      학원
                    </option>
                    <option className="" value="1">
                      스터디 카페
                    </option>
                    <option className="" value="2">
                      컨설던트
                    </option>
                  </select>
                </div>
                <div className="d-flex justify-content-center mt-5">
                  <button className="btn btn-primary font-white fgb-btn-l " style={{ width: "100%" }} onClick={onClickProflieComplete}>
                    다음
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* 내 프로필 */}
          {step === 3 && (
            <div className="affiliates-signup-div mt-3">
              <div className="affiliates-signup-authority">
                <div className="d-flex justify-content-between mb-3">
                  <p className="ms-3">관리자 메뉴 설정</p>

                  <img
                    className="me-3"
                    onClick={() => {
                      if (authorityListAll == false) {
                        setAuthorityListAll(true)
                        setMember(3)
                        setTodoSchedule(3)
                        setStudySchedule(3)
                        setServiceCon(3)
                        setServiceKoo(3)
                        setMessage(3)
                      } else {
                        setMember(0)
                        setTodoSchedule(0)
                        setStudySchedule(0)
                        setServiceCon(0)
                        setServiceKoo(0)
                        setMessage(0)
                        setAuthorityListAll(false)
                      }
                    }}
                    src={authorityListAll ? checked_on : checked_off}
                  />
                </div>

                <div className="affiliates-signup-authority-item">
                  <div className="affiliates-signup-authority-item-1"> {"회원관리"}</div>
                  <div className="affiliates-signup-authority-item-2">
                    <p>{"회원 조회"}</p>
                    <img
                      onClick={() => {
                        if (member >= 1) {
                          setMember(0)
                        } else {
                          setMember(1)
                        }
                      }}
                      src={member >= 1 ? checked_on : checked_off}
                    />
                  </div>
                  <div className="affiliates-signup-authority-item-2">
                    <p>{"회원 등록"}</p>
                    <img
                      onClick={() => {
                        if (member >= 3) {
                          setMember(0)
                        } else {
                          setMember(3)
                        }
                      }}
                      src={member >= 3 ? checked_on : checked_off}
                    />
                  </div>
                </div>
                <div className="affiliates-signup-authority-item">
                  <div className="affiliates-signup-authority-item-1"> {"스터디 스케줄 관리"}</div>
                  <div className="affiliates-signup-authority-item-2">
                    <p>{"TO-DO 신청목록 조회"}</p>
                    <img
                      onClick={() => {
                        if (todoSchedule >= 3) {
                          setTodoSchedule(0)
                        } else {
                          setTodoSchedule(3)
                        }
                      }}
                      src={todoSchedule >= 3 ? checked_on : checked_off}
                    />
                  </div>
                  <div className="affiliates-signup-authority-item-2">
                    <p>{"스터디 스케줄 조회"}</p>
                    <img
                      onClick={() => {
                        if (studySchedule >= 1) {
                          setStudySchedule(0)
                        } else {
                          setStudySchedule(1)
                        }
                      }}
                      src={studySchedule >= 1 ? checked_on : checked_off}
                    />
                  </div>
                  <div className="affiliates-signup-authority-item-2">
                    <p>{"스터디 스케줄 등록"}</p>
                    <img
                      onClick={() => {
                        if (studySchedule >= 3) {
                          setStudySchedule(0)
                        } else {
                          setStudySchedule(3)
                        }
                      }}
                      src={studySchedule >= 3 ? checked_on : checked_off}
                    />
                  </div>
                </div>
                <div className="affiliates-signup-authority-item">
                  <div className="affiliates-signup-authority-item-1"> {"서비스 상품관리"}</div>
                  <div className="affiliates-signup-authority-item-2">
                    <p>{"서비스상품 조회"}</p>
                    <img
                      onClick={() => {
                        if (serviceCon >= 1 || serviceKoo >= 1) {
                          setServiceCon(0)
                          setServiceKoo(0)
                        } else {
                          setServiceCon(1)
                          setServiceKoo(1)
                        }
                      }}
                      src={serviceCon >= 1 || serviceKoo >= 1 ? checked_on : checked_off}
                    />
                  </div>
                  <div className="affiliates-signup-authority-item-2">
                    <p>{"컨텐츠 등록"}</p>
                    <img
                      onClick={() => {
                        if (serviceCon >= 3) {
                          setServiceCon(0)
                        } else {
                          setServiceCon(3)
                        }
                      }}
                      src={serviceCon >= 3 ? checked_on : checked_off}
                    />
                  </div>
                  <div className="affiliates-signup-authority-item-2">
                    <p>{"꾸미기 등록"}</p>
                    <img
                      onClick={() => {
                        if (serviceKoo >= 3) {
                          setServiceKoo(0)
                        } else {
                          setServiceKoo(3)
                        }
                      }}
                      src={serviceKoo >= 3 ? checked_on : checked_off}
                    />
                  </div>
                </div>
                <div className="affiliates-signup-authority-item">
                  <div className="affiliates-signup-authority-item-1"> {"내 계정 관리"}</div>
                  <div className="affiliates-signup-authority-item-2">
                    <p>{"메시지 관리"}</p>
                    <img
                      onClick={() => {
                        if (message >= 3) {
                          setMessage(0)
                        } else {
                          setMessage(3)
                        }
                      }}
                      src={message >= 3 ? checked_on : checked_off}
                    />
                  </div>
                </div>
                {/* {approchList.map((i, idx) => (
                <div key={idx} className="affiliates-signup-authority-item">
                  <div className="affiliates-signup-authority-item-1"> {i.menu1Name}</div>
                  {i.menu2.map((data, index) => {++-++
                    if (i.menu1Name == "내계정관리" && index == 0) {
                      return (
                        <div key={index} onClick={() => setAuthorityMessage(!authorityMessage)} className="affiliates-signup-authority-item-2">
                          <p>{"내 메세지 관리"}</p>
                          <img src={authorityMessage ? checked_on : checked_off} />
                        </div>
                      )
                    } else if (i.menu1Name == "내계정관리") {
                      return false
                    } else {
                      return (
                        <div key={index} className="affiliates-signup-authority-item-2">
                          <p>{data.menu2Name}</p>
                          <img
                            onClick={() => {
                              if (authorityList.indexOf(data.menu2Seq) == -1) {
                                setAuthorityList([...authorityList, data.menu2Seq])
                              } else {
                                let temp = [...authorityList]
                                let tempNum = authorityList.indexOf(data.menu2Seq)
                                temp.splice(tempNum, 1)
                                setAuthorityList(temp)
                              }
                            }}
                            src={authorityList.indexOf(data.menu2Seq) != -1 ? checked_on : checked_off}
                          />
                        </div>
                      )
                    }
                  })}
                </div>
              ))} */}
                <div className="d-flex justify-content-center mt-5">
                  <button className="btn btn-primary font-white fgb-btn-l " style={{ width: "100%" }} onClick={signupFn}>
                    다음
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* 가입완료 */}
          {step === 4 && (
            // <div className="affiliates-signup-div mt-4">
            <div className="affiliates-signup-fin">
              <p className="affiliates-signup-fin-sub-title">LET ME UP 관리자 시스템</p>

              <p className="affiliates-signup-fin-title">회원가입 등록이 정상적으로 완료되었습니다.</p>

              <img className="mt-5" src={signupFin} />
              <p className="affiliates-signup-fin-info">
                LET ME UP 운영자의 메뉴접근 승인처리가 완료되면 휴대전화 문자로 알려드립니다. <br />
                궁금한 점 있으시면 고객센터 1234-5678로 문의 부탁드립니다.
              </p>
              <div className="d-flex justify-content-center mt-5">
                <button onClick={() => navigate("/Affiliates")} className="btn btn-primary font-white fgb-btn-l " style={{ width: "600px" }}>
                  확인
                </button>
              </div>
            </div>
            // </div>
          )}
        </div>
      </div>
      <BottomInfo />
    </Layout>
  )
}
export default SignContainer(AffiliatesSignup)
