import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import {
  getUserListAction,
  getUserInfoAction,
  getUserLearningInfoAction,
  getUserCreatedStudyListAction,
  getUserParticipatedStudyListAction,
  getUserToDoListAction,
  getUserStudyTimeRankingListAction,
  getUsersFriendListAction,
  getWithdrawalMemberListAction,
  delWithdrawalMemberAction,
  getMemberPointInfoAction,
  getMemberPointAccumulatedAction,
  getMemberPointRewardedAction,
  getMemberPointChargedAction,
  getMemberPointPaidAction,
  getMemberPointAccumulatedExcelAction,
  getMemberPointRewardedExcelAction,
  getMemberPointChargedExcelAction,
  getMemberPointPaidExcelAction,
  getWithdrawalMemberListExcelAction,
  getUsersFriendListExcelAction,
  getUserListExcelAction,
  getUserCreatedStudyListExcelAction,
  getUserCreatedTodoListExcelAction,
  getUserStudyTimeRankingListExcelAction,
} from "../store/modules/member"

const mapStateToProps = state => ({
  userList: state.member.userList,
  userInfo: state.member.userInfo,
  userLearningInfo: state.member.userLearningInfo,
  userCreatedStudyList: state.member.userCreatedStudyList,
  userParticipatedStudyList: state.member.userParticipatedStudyList,
  userToDoList: state.member.userToDoList,
  userStudyTimeRankingList: state.member.userStudyTimeRankingList,
  usersFriendList: state.member.usersFriendList,
  withdrawalMemberList: state.member.withdrawalMemberList,
  pointInfo: state.member.pointInfo,
  pointAccumulated: state.member.pointAccumulated,
  pointRewarded: state.member.pointRewarded,
  pointCharged: state.member.pointCharged,
  pointPaid: state.member.pointPaid,
})

const mapDispatchToProps = dispatch => ({
  getUserList: param => dispatch(getUserListAction(param)),
  getUserInfo: param => dispatch(getUserInfoAction(param)),
  getUserLearningInfo: param => dispatch(getUserLearningInfoAction(param)),
  getUserCreatedStudyList: param => dispatch(getUserCreatedStudyListAction(param)),
  getUserParticipatedStudyList: param => dispatch(getUserParticipatedStudyListAction(param)),
  getUserToDoList: param => dispatch(getUserToDoListAction(param)),
  getUserStudyTimeRankingList: param => dispatch(getUserStudyTimeRankingListAction(param)),
  getUsersFriendList: param => dispatch(getUsersFriendListAction(param)),
  getWithdrawalMemberList: param => dispatch(getWithdrawalMemberListAction(param)),
  delWithdrawalMember: param => dispatch(delWithdrawalMemberAction(param)),
  getMemberPointInfo: param => dispatch(getMemberPointInfoAction(param)),
  getMemberPointAccumulated: param => dispatch(getMemberPointAccumulatedAction(param)),
  getMemberPointRewarded: param => dispatch(getMemberPointRewardedAction(param)),
  getMemberPointCharged: param => dispatch(getMemberPointChargedAction(param)),
  getMemberPointPaid: param => dispatch(getMemberPointPaidAction(param)),
  getMemberPointAccumulatedExcel: param => dispatch(getMemberPointAccumulatedExcelAction(param)),
  getMemberPointRewardedExcel: param => dispatch(getMemberPointRewardedExcelAction(param)),
  getMemberPointChargedExcel: param => dispatch(getMemberPointChargedExcelAction(param)),
  getMemberPointPaidExcel: param => dispatch(getMemberPointPaidExcelAction(param)),
  getWithdrawalMemberListExcel: param => dispatch(getWithdrawalMemberListExcelAction(param)),
  getUsersFriendListExcel: param => dispatch(getUsersFriendListExcelAction(param)),
  getUserListExcel: param => dispatch(getUserListExcelAction(param)),
  getUserCreatedStudyListExcel: param => dispatch(getUserCreatedStudyListExcelAction(param)),
  getUserCreatedTodoListExcel: param => dispatch(getUserCreatedTodoListExcelAction(param)),
  getUserStudyTimeRankingListExcel: param => dispatch(getUserStudyTimeRankingListExcelAction(param)),
})

const WithHoc = ChildComponent => props => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
