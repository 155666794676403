import { useEffect } from "react"
import { navigate } from "gatsby"
import { localStorgeGet } from "../utils/util"

const Index = () => {
  useEffect(() => {
    let type = localStorgeGet("loginUserType")
    if (type == "ADMIN") {
      navigate("/AdminMain")
    } else if (type == "PARTNER") {
      navigate("/AffiliatesMain")
    } else {
      navigate("/Admin")
    }
  }, [])

  return null
}

export default Index
