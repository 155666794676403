import api from "../utils/api"

//총 누적 공부시간
const getStatisticsStudyTime = async data => {
  const result = await api.get(`/statistics/study-time`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//총 누적 회원수
const getStatisticsMembers = async data => {
  const result = await api.get(`/statistics/members`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//총 누적 스터디 갯수
const getStatisticsStudyCount = async data => {
  const result = await api.get(`/statistics/study-count`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//총 누적 TODO 갯수
const getStatisticsTodoCount = async data => {
  const result = await api.get(`/statistics/todo-count`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//스터디별 공부 누적시간 현황
const getStatisticsStudies = async data => {
  const result = await api.get(`/statistics/studies?month=${data}`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//스터디 목록
const getMyStudies = async data => {
  const result = await api.get(`/my/studies`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//스터디 타겟 상세 스케줄 정보
const getMyStudiesSchedules = async data => {
  const result = await api.get(`/my/studies/${data}/schedules`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//내 todo 목록
const getMyTodos = async data => {
  const result = await api.get(`/my/todos`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//랭킹 키워드
const getRankingStudyKeyword = async data => {
  const result = await api.get(`/ranking/study-keyword`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//랭킹 스터디
const getRankingStudyBest = async data => {
  const result = await api.get(`/ranking/study-best`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//랭킹 누적 공부시간
const getRankingStudyTime = async data => {
  const result = await api.get(`/ranking/study-time`, { token: true })
  if (result) {
    return result.data
  } else return false
}
// 신규 회원 목록 조회

const getMemberRecently = async data => {
  const result = await api.get(`/members/recently`, { token: true })
  if (result) {
    return result.data
  } else return false
}

//내 계정정보 put edit
// const putAdminsProfile = async data => {
//   const result = await api.put(`/admins/profile`, { token: true, body: data })
//   if (result) {
//     return result.data
//   } else return false
// }

// admin로그인 회원가입
// const postSignIn = async data => {
//   const result = await api.post(`/signin`, { token: false, body: data })
//   if (result) {
//     return result.data
//   } else return false
// }

export default {
  getStatisticsStudyTime,
  getStatisticsMembers,
  getStatisticsStudyCount,
  getStatisticsTodoCount,
  getStatisticsStudies,
  getMyStudies,
  getMyStudiesSchedules,
  getMyTodos,
  getRankingStudyKeyword,
  getRankingStudyBest,
  getRankingStudyTime,
  getMemberRecently,
}
