import React, { useState } from "react"
import LayerPopupLayout from "../LayerPopupLayout"
import SignContainer from "../../containers/SignContainer"
import Validator from "validator"
import { debounce } from "lodash"
import { dataCheckFn, popupFn } from "../../utils/util"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"
import CloseBtn from "../../images/Close_Button.svg"
import img_popup_close_gray from "../../images/img_popup_close_gray.svg"
import { useEffect } from "react"
import OperationContainer from "../../containers/OperationContainer"

const PointPopup = props => {
  const { open, onClose, pointRuleEmpty, pointRule, putPointRule, getData = () => {} } = props

  const [motionTitle, setMotionTitle] = useState("")
  const [summary, setSummary] = useState("")
  const [frequencyType, setFrequencyType] = useState(true)
  const [frequencyTypeSel, setFrequencyTypeSel] = useState(1)
  const [pointType, setPointType] = useState(true)
  const [point, setPoint] = useState(0)
  const [isExpiration, setIsExpiration] = useState(true)
  const [isExpirationSel, setIsExpirationSel] = useState(30)
  const [isUse, setIsUse] = useState(false)
  const [isPopup, setIsPopup] = useState(false)
  const closePopup = e => {
    pointRuleEmpty()
    onClose()
  }
  const saveFn = async () => {
    let temp = {
      pointRuleSeq: pointRule.pointRuleSeq,
      action: motionTitle,
      frequencyType: frequencyType ? "ALWAYS" : "ONCE",
      frequency: frequencyTypeSel,
      isUse: isUse ? "Y" : "N",
      pointType: pointType ? "REWARD" : "SAVE",
      frequencyPoint: point,
      isExpiration: isExpiration ? "N" : "Y",
      expirationDate: isExpirationSel,
      isMessagePopup: isPopup ? "Y" : "N",
      message: summary,
    }

    console.log(temp)
    let result = await putPointRule(temp)
    if (result) {
      popupFn("저장되었습니다.")
      getData()
      closePopup()
    }
  }

  useEffect(() => {
    if (dataCheckFn(pointRule)) {
      setMotionTitle(pointRule.action)
      setFrequencyType(pointRule.frequencyType == "ONCE" ? false : true)
      setFrequencyTypeSel(pointRule.frequency)
      setIsUse(pointRule.isUse == "Y" ? true : false)
      setPoint(
        pointRule.frequencyPoint.indexOf("%") != -1
          ? Math.floor(pointRule.frequencyPoint.substring(0, pointRule.frequencyPoint.length - 1))
          : Math.floor(pointRule.frequencyPoint)
      )
      setIsExpiration(pointRule.isExpiration == "Y" ? false : true)
      setIsExpirationSel(pointRule.expirationDate)
      setSummary(pointRule.message)
      setIsPopup(pointRule.isMessagePopup == "Y" ? true : false)
    }
  }, [pointRule])
  return (
    <LayerPopupLayout open={open} onClose={() => closePopup()} isCloseBtn transparency zIndex={101}>
      <div className="learner-type-popup-container layer-popup-container">
        <p className="popup-title mb-3">렛미업 포인트 등록/수정</p>
        <div className="admin-account-info-container ">
          <div className="admin-account-info-box">
            <div className="admin-account-info-box-row div-1">
              <div className="admin-account-info-title-p ">포인트 지정대상 타이틀</div>
              <div className="admin-account-info-div">
                <div style={{ width: "330px", border: "none" }} className="admin-account-info-div-2">
                  <input value={motionTitle} onChange={e => setMotionTitle(e.target.value)} type="text" className="fgb-input" />
                </div>
              </div>
            </div>
            <div className="admin-account-info-box-row div-1">
              <div className="admin-account-info-title-p ">지급 빈도</div>
              <div className="admin-account-info-div">
                <div style={{ border: "none" }} className="admin-account-info-div-2">
                  <img onClick={() => setFrequencyType(true)} className="me-2" src={frequencyType ? radio_on : radio_off} />
                  <p>항상 </p>
                  <img onClick={() => setFrequencyType(false)} className="ms-4 me-2" src={!frequencyType ? radio_on : radio_off} />
                  <p>허용</p>
                  <div className="ms-4" style={{ width: "190px" }}>
                    <select
                      value={frequencyTypeSel}
                      disabled={frequencyType}
                      className="form-select"
                      onChange={e => {
                        setFrequencyTypeSel(e.target.value)
                      }}
                    >
                      <option className="" value="1">
                        1
                      </option>
                      <option className="" value="2">
                        2
                      </option>
                      <option className="" value="3">
                        3
                      </option>
                      <option className="" value="4">
                        4
                      </option>
                      <option className="" value="5">
                        5
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="admin-account-info-box-row div-1">
              <div className="admin-account-info-title-p ">사용 여부</div>
              <div className="admin-account-info-div">
                <div style={{ border: "none" }} className="admin-account-info-div-2">
                  <img onClick={() => setIsUse(true)} className="me-2" src={isUse ? radio_on : radio_off} />
                  <p>사용 </p>
                  <img onClick={() => setIsUse(false)} className="ms-4 me-2" src={!isUse ? radio_on : radio_off} />
                  <p>미사용</p>
                </div>
              </div>
            </div>
            <div className="admin-account-info-box-row div-1">
              <div className="admin-account-info-title-p ">유형</div>
              <div className="admin-account-info-div">
                <div style={{ border: "none" }} className="admin-account-info-div-2">
                  <img onClick={() => setPointType(true)} className="me-2" src={pointType ? radio_on : radio_off} />
                  <p>보상 </p>
                  <img onClick={() => setPointType(false)} className="ms-4 me-2" src={!pointType ? radio_on : radio_off} />
                  <p>적립</p>
                </div>
              </div>
            </div>
            <div className="admin-account-info-box-row div-1">
              <div className="admin-account-info-title-p ">자급 포인트</div>
              <div className="admin-account-info-div">
                <div style={{ width: "330px", border: "none" }} className="admin-account-info-div-2">
                  <input value={point} onChange={e => setPoint(e.target.value)} type="number" className="fgb-input" />
                  {dataCheckFn(pointRule) && pointRule.frequencyPoint.indexOf("%") != -1 ? "%" : ""}
                </div>
              </div>
            </div>
            <div className="admin-account-info-box-row div-1">
              <div className="admin-account-info-title-p ">소멸여부</div>
              <div className="admin-account-info-div">
                <div style={{ border: "none" }} className="admin-account-info-div-2">
                  <img onClick={() => setIsExpiration(true)} className="me-2" src={isExpiration ? radio_on : radio_off} />
                  <p>없음 </p>
                  <img onClick={() => setIsExpiration(false)} className="ms-4 me-2" src={!isExpiration ? radio_on : radio_off} />
                  <p>있음</p>
                  <div className="ms-4" style={{ width: "190px" }}>
                    <select
                      value={isExpirationSel}
                      disabled={isExpiration}
                      className="form-select"
                      onChange={e => {
                        setIsExpirationSel(e.target.value)
                      }}
                    >
                      <option className="" value="30">
                        30일
                      </option>
                      <option className="" value="60">
                        60일
                      </option>
                      <option className="" value="90">
                        90일
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="admin-account-info-box-row div-3">
              <div className="admin-account-info-title-p ">포인트 지급 안내메세지 작성</div>
              <div className="admin-account-info-div">
                <div style={{ flexDirection: "column" }} className="admin-account-info-div-textarea">
                  <textarea value={summary} onChange={e => setSummary(e.target.value)}></textarea>
                  <div style={{ width: "100%" }} className="d-flex justify-content-between align-items-center mt-3">
                    <div className="d-flex align-items-center">
                      <img onClick={() => setIsPopup(!isPopup)} className="me-2" src={isPopup ? checked_on : checked_off} />
                      <p>안내 메세지 팝업 창 제공 없음</p>
                    </div>
                    <button onClick={() => setSummary("")} className="btn fgb-btn-gray fgb-btn-m ">
                      초기화
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="permission-info-popup-btn-view">
          <button onClick={() => closePopup()} className="btn btn-primary fgb-btn-gray fgb-btn-l ">
            취소
          </button>
          <button onClick={() => saveFn()} className="btn btn-primary font-white fgb-btn-l ">
            저장
          </button>
        </div>
      </div>
    </LayerPopupLayout>
  )
}

export default OperationContainer(PointPopup)
const DUMY = [
  {
    depth2name: "공부에 집중이 안되나요?  잠깐 휴식하고 에너지 충전하고 돌아오세요. ",
    seq: "q1",
  },
  {
    depth2name: "집중하세요",
    seq: "q2",
  },
  {
    depth2name: "좀 더 열심히",
    seq: "q3",
  },
]
