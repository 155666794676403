/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect } from "react"

import "swiper/scss"

import BottomInfo from "../components/login/BottomInfo"

import SEO from "../components/seo"
import Layout from "../components/Layout"

import leftImg from "../images/leftImg.png"
import rightImg from "../images/RightImg.png"
import login_center_icon from "../images/login_center_icon.png"
import checked_on from "../images/checked_on.svg"
import checked_off from "../images/checked_off.svg"
import Validator from "validator"

import ModalPopup from "../popups/ModalPopup"

import SignContainer from "../containers/SignContainer"
import { localStorgeGet } from "../utils/util"

const Admin = props => {
  const { AdminPostSignin, tempInfo } = props
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isAuto, setIsAuto] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [tempPw, setTempPw] = useState("")
  const [openModaEmpty, setOpenModalEmpty] = useState(false)
  const adminLoginFn = async () => {
    if (!Validator.isEmail(email, { ignore_whitespace: true })) {
      setOpenModalEmpty(true)
      return false
    }

    // if (email.indexOf("samlab.co.kr") == -1 && email.indexOf("fgb.ai") == -1) {
    //   return false
    // }

    let temp = {
      email: email,
      password: password,
      isSave: isAuto,
    }

    let result = await AdminPostSignin(temp)

    if (result) {
      if (result != true) {
        setOpenModal(true)
        setTempPw("임시 비밀번호가 발급되었습니다.\n" + result)
      }
    }
  }
  useEffect(() => {
    let tempEmail = localStorgeGet("adminEmail")
    if (tempEmail) {
      setEmail(tempEmail)
    }
  }, [])

  return (
    <Layout isLeftBar={false} isContainer={false}>
      <SEO title="Login" />
      <ModalPopup open={openModal} onClose={() => setOpenModal(false)} contents={tempPw} onClickBtn={() => setOpenModal(false)} btn={["확인"]} />
      <ModalPopup
        open={openModaEmpty}
        onClose={() => setOpenModalEmpty(false)}
        contents={"공백은 사용할 수 없습니다."}
        onClickBtn={() => setOpenModalEmpty(false)}
        btn={["확인"]}
      />
      <div className="admin-login-container">
        <img src={leftImg} />
        <div className="admin-login-div">
          <p className="admin-login-title">
            반갑습니다. <br />
            오늘도 함께 성장하는 하루 보내세요.
          </p>
          <p className="admin-login-text">로그인</p>
          <div className="d-flex justify-content-center">
            <img src={login_center_icon} />
          </div>
          <p className="admin-login-sm-p">Email</p>
          <input value={email} onChange={e => setEmail(e.target.value)} type="text" placeholder="Email" className="fgb-input" />
          <p className="admin-login-sm-p">Password</p>
          <input
            onKeyDown={e => {
              if (e.key === "Enter") {
                adminLoginFn()
              }
            }}
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
            placeholder="password"
            className="fgb-input"
          />
          <div className="d-flex align-items-center admin-login-item-top-margin">
            <img src={isAuto ? checked_on : checked_off} onClick={() => setIsAuto(!isAuto)} />
            <p className="admin-login-sm-p no-margin">Email 저장하기</p>
          </div>
          <div className="d-flex justify-content-center admin-login-btn-top-margin">
            <div onClick={() => adminLoginFn()} className="btn btn-primary font-white fgb-btn-l login-btn">
              로그인
            </div>
          </div>
        </div>
        <img src={rightImg} />
      </div>
      <BottomInfo />
    </Layout>
  )
}

export default SignContainer(Admin)
