import { navigate } from "gatsby-link"
import { createAction, handleActions } from "redux-actions"
import SignApis from "../../apis/SignApis"
import { localStorgeSet, popupFn } from "../../utils/util"
import { setLoginPopup } from "./popup"

const SET_IS_LOGIN = "sign/SET_IS_LOGIN"
const USER_INFO = "sign/USER_INFO"
const AUTH_LOG_SEQ = "sign/AUTH_LOG_SEQ"
const TEMP_INFO = "sign/TEMP_INFO"
const TERM = "sign/TERM"
const PARTNER_APPROCH = "sign/PARTNER_APPROCH"
const PARTNER_PROFILES = "sign/PARTNER_PROFILES"
const PARTNER_PROFILES_INTRODUCTION = "sign/PARTNER_PROFILES_INTRODUCTION"
const PARTNER_PROFILES_INTRODUCTION_TODO = "sign/PARTNER_PROFILES_INTRODUCTION_TODO"
const PARTNER_PROFILES_INTRODUCTION_STUDY = "sign/PARTNER_PROFILES_INTRODUCTION_STUDY"
const PARTNER_PROFILES_TERMS = "sign/PARTNER_PROFILES_TERMS"
const PARTNER_PROFILES_AUTH = "sign/PARTNER_PROFILES_AUTH"
const PARTNER_PROFILES_PAYMENTS = "sign/PARTNER_PROFILES_PAYMENTS"
const PARTNER_PROFILES_PAYMENTS_TOTAL = "sign/PARTNER_PROFILES_PAYMENTS_TOTAL"
const PARTNER_PROFILES_POINT = "sign/PARTNER_PROFILES_POINT"
const PARTNER_PROFILES_POINT_TOTAL = "sign/PARTNER_PROFILES_POINT_TOTAL"
const SET_MESSAGES = "sign/SET_MESSAGES"
const SET_MESSAGES_TOTAL = "sign/SET_MESSAGES_TOTAL"
const SET_MESSAGES_STUDIES = "sign/SET_MESSAGES_STUDIES"
const SET_MESSAGES_MEMBERS = "sign/SET_MESSAGES_MEMBERS"
const SET_MESSAGES_USERS = "sign/SET_MESSAGES_USERS"

export const setIsLogin = createAction(SET_IS_LOGIN)

export const setUserInfo = createAction(USER_INFO)
export const setTempInfo = createAction(TEMP_INFO)
export const getTerm = createAction(TERM)
export const setAuthLogSeq = createAction(AUTH_LOG_SEQ)
export const setPartnerApproch = createAction(PARTNER_APPROCH)
export const setPartnerProfile = createAction(PARTNER_PROFILES)
export const setPartnerProfileIntroduction = createAction(PARTNER_PROFILES_INTRODUCTION)
export const setPartnerProfileIntroductionTodo = createAction(PARTNER_PROFILES_INTRODUCTION_TODO)
export const setPartnerProfileIntroductionStudy = createAction(PARTNER_PROFILES_INTRODUCTION_STUDY)
export const setPartnerProfileTerms = createAction(PARTNER_PROFILES_TERMS)
export const setPartnerProfileAuth = createAction(PARTNER_PROFILES_AUTH)
export const setPartnerProfilePayments = createAction(PARTNER_PROFILES_PAYMENTS)
export const setPartnerProfilePaymentsTotal = createAction(PARTNER_PROFILES_PAYMENTS_TOTAL)
export const setPartnerProfilePoint = createAction(PARTNER_PROFILES_POINT)
export const setPartnerProfilePointTotal = createAction(PARTNER_PROFILES_POINT_TOTAL)
export const setPartnerProfileMessages = createAction(SET_MESSAGES)
export const setPartnerProfileMessagesTotal = createAction(SET_MESSAGES_TOTAL)
export const setPartnerProfileMessagesStudies = createAction(SET_MESSAGES_STUDIES)
export const setPartnerProfileMessagesMembers = createAction(SET_MESSAGES_MEMBERS)
export const setPartnerProfileMessagesUsers = createAction(SET_MESSAGES_USERS)

const initState = {
  isLogin: false,
  term: {},
  userInfo: {},
  tempInfo: {},
  authLog: {},
  approchList: [],
  partnerProfile: {},
  partnerProfileIntrocution: {},
  partnerProfileIntrocutionTodo: [],
  partnerProfileIntrocutionStudy: [],
  profileTerms: [],
  profileAuth: [],
  profilePayments: [],
  profilePaymentsTotal: 0,
  profilePoint: [],
  profilePointTotal: 0,
  profileMessages: [],
  profileMessagesTotal: 0,
  profileMessagesStudies: [],
  profileMessagesMembers: [],
  profileMessagesUsers: [],
}

export default handleActions(
  {
    [SET_IS_LOGIN]: (state, action) => ({
      ...state,
      isLogin: action.payload,
    }),
    [TERM]: (state, action) => ({
      ...state,
      term: action.payload,
    }),
    [USER_INFO]: (state, action) => ({
      ...state,
      userInfo: action.payload,
    }),
    [TEMP_INFO]: (state, action) => ({
      ...state,
      tempInfo: action.payload,
    }),
    [AUTH_LOG_SEQ]: (state, action) => ({
      ...state,
      authLog: action.payload,
    }),
    [PARTNER_APPROCH]: (state, action) => ({
      ...state,
      approchList: action.payload,
    }),
    [PARTNER_PROFILES]: (state, action) => ({
      ...state,
      partnerProfile: action.payload,
    }),
    [PARTNER_PROFILES_INTRODUCTION]: (state, action) => ({
      ...state,
      partnerProfileIntrocution: action.payload,
    }),
    [PARTNER_PROFILES_INTRODUCTION_TODO]: (state, action) => ({
      ...state,
      partnerProfileIntrocutionTodo: action.payload,
    }),
    [PARTNER_PROFILES_INTRODUCTION_STUDY]: (state, action) => ({
      ...state,
      partnerProfileIntrocutionStudy: action.payload,
    }),
    [PARTNER_PROFILES_TERMS]: (state, action) => ({
      ...state,
      profileTerms: action.payload,
    }),
    [PARTNER_PROFILES_AUTH]: (state, action) => ({
      ...state,
      profileAuth: action.payload,
    }),
    [PARTNER_PROFILES_PAYMENTS]: (state, action) => ({
      ...state,
      profilePayments: action.payload,
    }),
    [PARTNER_PROFILES_PAYMENTS_TOTAL]: (state, action) => ({
      ...state,
      profilePaymentsTotal: action.payload,
    }),
    [PARTNER_PROFILES_POINT]: (state, action) => ({
      ...state,
      profilePoint: action.payload,
    }),
    [PARTNER_PROFILES_POINT_TOTAL]: (state, action) => ({
      ...state,
      profilePointTotal: action.payload,
    }),
    [SET_MESSAGES]: (state, action) => ({
      ...state,
      profileMessages: action.payload,
    }),
    [SET_MESSAGES_TOTAL]: (state, action) => ({
      ...state,
      profileMessagesTotal: action.payload,
    }),
    [SET_MESSAGES_STUDIES]: (state, action) => ({
      ...state,
      profileMessagesStudies: action.payload,
    }),
    [SET_MESSAGES_MEMBERS]: (state, action) => ({
      ...state,
      profileMessagesMembers: action.payload,
    }),
    [SET_MESSAGES_USERS]: (state, action) => ({
      ...state,
      profileMessagesUsers: action.payload,
    }),
  },
  initState
)
/* action api function */

// 로그인
export const AdminPostSignin = param => async dispatch => {
  const result = await SignApis.postSignIn(param)
  if (result.code == 0) {
    if (result.data.isTemporary == "Y") {
      //초기 계정생성
      // dispatch(setIsLogin(true))
      dispatch(setTempInfo(result.data))
      // localStorage.setItem("tempEmail", param.email)
      // localStorage.setItem("isTemporary", result.data.isTemporary)
      return result.data.temporaryPassword
    } else if (result.data.isTemporary == "N" && result.data.isFirst == "Y") {
      //초기 로그인 프로필 안한상태
      localStorgeSet(process.env.TOKEN_ID, result.data.accessToken)
      dispatch(setTempInfo(result.data))
      localStorgeSet("loginUserSeq", result.data.loginUserSeq)
      localStorgeSet("loginUserType", result.data.loginUserType)
      localStorgeSet("letMeUpAdminPhoneNum", result.data.phone)
      dispatch(setIsLogin(true))
      navigate("/account/MyAccountTemp")
      return true
    } else if (result.data.isTemporary == "N" && result.data.isFirst == "N") {
      //정상 로그인
      localStorgeSet(process.env.TOKEN_ID, result.data.accessToken)
      localStorgeSet("loginUserSeq", result.data.loginUserSeq)
      localStorgeSet("loginUserType", result.data.loginUserType)
      localStorgeSet("letMeUpAdminPhoneNum", result.data.phone)
      localStorgeSet("applicationType", result.data.applicationType)
      if (param.isSave) {
        localStorgeSet("adminEmail", param.email)
        // localStorage.setItem("adminPw", param.password)
      } else {
        localStorage.removeItem("adminEmail")
        // localStorage.removeItem("adminPw")
      }
      // if (param.email == localStorage.getItem("tempEmail")) {
      //   localStorage.removeItem("tempEmail")
      //   localStorage.removeItem("isTemporary")
      // }
      dispatch(setIsLogin(true))
      //main 이동
      navigate("/AdminMain")
      return true
    }
  } else {
    dispatch(setTempInfo({}))
    localStorage.removeItem(process.env.TOKEN_ID)
    localStorage.removeItem("loginUserSeq")
    localStorage.removeItem("loginUserType")
    localStorage.removeItem("letMeUpAdminPhoneNum")
    localStorage.removeItem("applicationType")
    return false
  }
}
// 비밀번호 체크
export const postAuthPasswordAction = param => async dispatch => {
  const result = await SignApis.postAuthPassword(param)
  if (result.code == 0) {
    return true
  } else return false
}
//내 계정정보 get
export const getAdminsProfileAction = param => async dispatch => {
  const result = await SignApis.getAdminsProfile(param)
  if (result.code == 0) {
    dispatch(setUserInfo(result.data))
    return true
  } else {
    dispatch(setUserInfo({}))
    return false
  }
}
//내 계정정보 put edit
export const putAdminsProfileAction = param => async dispatch => {
  const result = await SignApis.putAdminsProfile(param)
  if (result.code == 0) {
    // localStorage.removeItem("tempEmail")
    // localStorage.removeItem("isTemporary")
    dispatch(setTempInfo({}))
    return true
  } else return false
}
//권한 변경 요청
export const postAdminsProfileAuthAction = param => async dispatch => {
  const result = await SignApis.postAdminsProfileAuth(param)
  if (result.code == 0) {
    return true
  } else return false
}
//이메일 수정 인증 이메일 보내기
export const postAuthEmailAction = param => async dispatch => {
  const result = await SignApis.postAuthEmail(param)
  if (result.code == 0) {
    return true
  } else return false
}
//이메일 수정 인증 이메일 check
export const putAuthEmailAction = param => async dispatch => {
  const result = await SignApis.putAuthEmail(param)
  if (result.code == 0) {
    return true
  } else return false
}

// 로그인 상태 체크 Exploring
export const checkSignStatusExploring = param => async dispatch => {
  const result = await SignApis.checkSignStatus(param)
  if (result.code == 0) {
    // localStorage.setItem(process.env.TOKEN_ID, result.data.accessToken)
    dispatch(setIsLogin(true))

    return true
  } else return false
}
// 로그인 상태 체크
export const checkSignStatus = param => async dispatch => {
  const result = await SignApis.checkSignStatus(param)
  if (result.code == 0) {
    // localStorage.setItem(process.env.TOKEN_ID, result.data.accessToken)
    dispatch(setIsLogin(true))

    return true
  } else {
    // navigate("/exploring")
    // dispatch(setLoginPopup(true))
    return false
  }
}
// 로그아웃
export const postSignOut = param => async dispatch => {
  const result = await SignApis.postSignOut(param)
  if (result.code == 0) {
    localStorage.removeItem(process.env.TOKEN_ID)
    localStorage.removeItem("letMeUpPhoneNum")
    localStorage.removeItem("loginUserSeq")
    localStorage.removeItem("loginUserType")
    localStorage.removeItem("isVisited")
    localStorage.removeItem("applicationType")

    dispatch(setIsLogin(false))
    dispatch(setUserInfo({}))
    return true
  } else return false
}
//약관
export const getTermAction = param => async dispatch => {
  const result = await SignApis.getTerm(param)
  if (result.code == 0) {
    dispatch(getTerm(result.data))
    return true
  } else {
    dispatch(getTerm({}))
    return false
  }
}
// 인증번호 요청
export const postAuthNumberAction = param => async dispatch => {
  const result = await SignApis.postAuthNumber(param)
  if (result.code == 0) {
    dispatch(setAuthLogSeq(result.data))
    return true
  } else {
    dispatch(setAuthLogSeq({}))
    return false
  }
}

// 인증번호 인증 확인
export const postAuthNumberCheckAction = param => async dispatch => {
  const result = await SignApis.postAuthNumberCheck(param)
  if (result.code == 0) {
    return result.data
  } else return false
}
//제휴사 권한 목록
export const getMenusApprochPartnerAction = param => async dispatch => {
  const result = await SignApis.getMenusApprochPartner(param)
  if (result.code == 0) {
    dispatch(setPartnerApproch(result.data.partnerMenus))
    return true
  } else {
    dispatch(setPartnerApproch([]))
    return false
  }
}
//제휴사 회원가입
export const postSignUpAction = param => async dispatch => {
  const result = await SignApis.postSignUp(param)
  if (result.code == 0) {
    return true
  } else return false
}
//제휴사 내 계정정보 get
export const getProfliesPartnerAction = param => async dispatch => {
  const result = await SignApis.getProfliesPartner(param)
  if (result.code == 0) {
    dispatch(setPartnerProfile(result.data.profile))
    return true
  } else {
    dispatch(setPartnerProfile({}))
    return false
  }
}
//제휴사 계정정보 put edit
export const putProfliesPartnerAction = param => async dispatch => {
  const result = await SignApis.putProfliesPartner(param)
  if (result.code == 0) {
    return true
  } else return false
}
//제휴사 내 계정정보 소개페이지 get
export const getProfliesPartnerIntroductionAction = param => async dispatch => {
  const result = await SignApis.getProfliesPartnerIntroduction(param)
  if (result.code == 0) {
    dispatch(setPartnerProfileIntroduction(result.data.partnerPageSetting))
    return true
  } else {
    dispatch(setPartnerProfileIntroduction({}))
    return false
  }
}
//제휴사 계정정보 소개페이지 put edit
export const putProfliesPartnerIntroductionAction = param => async dispatch => {
  const result = await SignApis.putProfliesPartnerIntroduction(param)
  if (result.code == 0) {
    return true
  } else return false
}
//제휴사 내 계정정보 소개페이지 todo study get
export const getProfliesPartnerIntroductionTodoStudyAction = param => async dispatch => {
  const result = await SignApis.getProfliesPartnerIntroductionTodoStudy(param)
  if (result.code == 0) {
    dispatch(setPartnerProfileIntroductionTodo(result.data.todoPackages))
    dispatch(setPartnerProfileIntroductionStudy(result.data.studies))
    return true
  } else {
    dispatch(setPartnerProfileIntroductionTodo([]))
    dispatch(setPartnerProfileIntroductionStudy([]))

    return false
  }
}
//제휴사 계정정보 소개페이지 투두 스터디 전시여부 put edit
export const putProfliesPartnerIntroductionTodoStudyAction = param => async dispatch => {
  const result = await SignApis.putProfliesPartnerIntroductionTodoStudy(param)
  if (result.code == 0) {
    return true
  } else return false
}
//제휴사 계정정보 이용약관 get
export const getProfilesPartnerTermsAction = param => async dispatch => {
  const result = await SignApis.getProfilesPartnerTerms(param)
  if (result.code == 0) {
    dispatch(setPartnerProfileTerms(result.data.partnerTerms))
    return true
  } else {
    dispatch(setPartnerProfileTerms([]))
    return false
  }
}
//제휴사 계정정보 이용약관 put
export const putProfliesPartnerTermsAction = param => async dispatch => {
  const result = await SignApis.putProfliesPartnerTerms(param)
  if (result.code == 0) {
    return true
  } else {
    return false
  }
}
//제휴사 계정정보 이용약관 put
export const deleteProfilesPartnerWithdrawAction = param => async dispatch => {
  const result = await SignApis.deleteProfilesPartnerWithdraw(param)
  if (result.code == 0) {
    return true
  } else {
    return false
  }
}
//제휴사 계정정보 메뉴권한 get
export const getProfilesPartnerAuthoritiesAction = param => async dispatch => {
  const result = await SignApis.getProfilesPartnerAuthorities(param)
  if (result.code == 0) {
    dispatch(setPartnerProfileAuth(result.data.partnerMenus))
    return true
  } else {
    dispatch(setPartnerProfileAuth([]))
    return false
  }
}
//제휴사 계정정보 메뉴권한 put
export const putProfliesPartnerAuthoritiesAction = param => async dispatch => {
  const result = await SignApis.putProfliesPartnerAuthorities(param)
  if (result.code == 0) {
    return true
  } else {
    return false
  }
}
//제휴사 결제 내역 목록
export const getProfilePaymentsAction = param => async dispatch => {
  const result = await SignApis.getProfilePayments(param)
  if (result.code == 0) {
    dispatch(setPartnerProfilePayments(result.data.myPayments))
    dispatch(setPartnerProfilePaymentsTotal(result.data.totalCount))
    return true
  } else {
    dispatch(setPartnerProfilePayments([]))
    dispatch(setPartnerProfilePaymentsTotal(0))
    return false
  }
}
//제휴사 결제 내역 목록 Xl down
export const getProfilePaymentsXlDownAction = param => async dispatch => {
  const result = await SignApis.getProfilePaymentsXlDown(param)
  if (result) {
    return result
  } else {
    return false
  }
}
//제휴사 포인트 내역 목록
export const getProfilePointssAction = param => async dispatch => {
  const result = await SignApis.getProfilePoints(param)
  if (result.code == 0) {
    dispatch(setPartnerProfilePoint(result.data.myPoints))
    dispatch(setPartnerProfilePointTotal(result.data.totalCount))
    return true
  } else {
    dispatch(setPartnerProfilePoint([]))
    dispatch(setPartnerProfilePointTotal(0))
    return false
  }
}
//제휴사 결제 내역 목록 Xl down
export const getProfilePointsXlDownAction = param => async dispatch => {
  const result = await SignApis.getProfilePointXlDown(param)
  if (result) {
    return result
  } else {
    return false
  }
}
//메세지 관리
//메세지 목록 get
export const getMessagesAction = param => async dispatch => {
  const result = await SignApis.getMessages(param)
  if (result.code == 0) {
    dispatch(setPartnerProfileMessages(result.data.alarmLogs))
    dispatch(setPartnerProfileMessagesTotal(result.data.totalCount))
    return true
  } else {
    dispatch(setPartnerProfileMessages([]))
    dispatch(setPartnerProfileMessagesTotal(0))
    return false
  }
}
//메세지 목록 del
export const delMessagesAction = param => async dispatch => {
  const result = await SignApis.delMessages(param)
  if (result.code == 0) {
    return true
  } else {
    return false
  }
}
//스터디 코드 제목 목록
export const getMessagesStudiesAction = param => async dispatch => {
  const result = await SignApis.getMessagesStudies(param)
  if (result.code == 0) {
    dispatch(setPartnerProfileMessagesStudies(result.data.studySeqAndTitleList))
    return true
  } else {
    dispatch(setPartnerProfileMessagesStudies([]))
    return false
  }
}
//스터디 인원 검색
export const getMessagesMembersAction = param => async dispatch => {
  const result = await SignApis.getMessagesMembers(param)
  if (result.code == 0) {
    // dispatch(setPartnerProfileMessagesMembers([...result.data.memberSeqs.map(i => i.memberSeq)]))
    dispatch(setPartnerProfileMessagesMembers(result.data.memberSeqs))
    return true
  } else {
    dispatch(setPartnerProfileMessagesMembers([]))
    return false
  }
}
//받는사람 검색
export const getMessagesUsersAction = param => async dispatch => {
  const result = await SignApis.getMessagesUsers(param)
  if (result.code == 0) {
    if (result.data.members.length == 0) {
      popupFn("조회된 인원이 없습니다.")
    }
    dispatch(setPartnerProfileMessagesUsers(result.data.members))
    return true
  } else {
    dispatch(setPartnerProfileMessagesUsers([]))
    return false
  }
}
//메세지 보내기
export const postMessagesAction = param => async dispatch => {
  const result = await SignApis.postMessages(param)
  if (result.code == 0) {
    return true
  } else {
    return false
  }
}
//파일 업로드 단일
export const postUploadFileAction = param => async dispatch => {
  const result = await SignApis.postUploadFile(param)
  if (result.code == 0) {
    return result.data.file
  } else return false
}
