/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect, useRef } from "react"

import "swiper/scss"

import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import TopInfo from "../../components/common/TopInfo"
import CalendarComponent from "../../components/Calendar"
import Pagination from "../../components/common/pagination"

import moment from "moment"

import icon_calendar from "../../images/ion_calendar-number-outline.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import download from "../../images/download.svg"
import upload from "../../images/upload.svg"

import { dataCheckFn, excelImport, popupFn } from "../../utils/util"

import OperationContainer from "../../containers/OperationContainer"
import Validator from "validator"
import { useDownloadExcel, DownloadTableExcel } from "react-export-table-to-excel/src"

import PrivateRoute from "../../components/privateRoute"

const ContantsCategory = props => {
  const {
    categoryContentsListEmpty,
    postCategoryContents,
    delCategoryContents,
    getBrandCategory,
    brandList,
    categoryList,
    categoryContentsList,
    getCategoryContents,
    postCategoryContentsExcelUpload,
  } = props
  const [dataList, setDataList] = useState([])
  const [checkList, setCheckList] = useState([])
  const [brandSel, setBrandSel] = useState("")
  const [sel1, setSel1] = useState(0)
  const [sel2, setSel2] = useState(0)
  const [sel3, setSel3] = useState(0)
  const [sel4, setSel4] = useState(0)
  const [data, setData] = useState({})

  const contentsTable = useRef(null)

  // const [current, setCurrent] = useState(1)
  const [step, setStep] = useState(0)

  const getDate = async () => {
    let result = await getBrandCategory()
    if (result) {
      getStartData()
    }
  }
  const getSearchData = async () => {
    setStep(0)
    let temp = {
      brandName: brandSel,
      depth1: sel1 != 0 ? categoryList[sel1 - 1].depth1Name : "",
      depth2: sel2 != 0 ? categoryList[sel1 - 1].depth2[sel2 - 1].depth2Name : "",
      depth3: sel3 != 0 ? categoryList[sel1 - 1].depth2[sel2 - 1].depth3[sel3 - 1].depth3Name : "",
      depth4: sel4 != 0 ? categoryList[sel1 - 1].depth2[sel2 - 1].depth3[sel3 - 1].depth4[sel4 - 1].depth4Name : "",
    }
    console.log(temp)
    let result = await getCategoryContents(temp)
    if (result) {
      getDate()
    }
  }
  const getStartData = async () => {
    setStep(0)
    let temp = {
      brandName: brandSel,
      depth1: sel1 != 0 ? categoryList[sel1 - 1].depth1Name : "",
      depth2: sel2 != 0 ? categoryList[sel1 - 1].depth2[sel2 - 1].depth2Name : "",
      depth3: sel3 != 0 ? categoryList[sel1 - 1].depth2[sel2 - 1].depth3[sel3 - 1].depth3Name : "",
      depth4: sel4 != 0 ? categoryList[sel1 - 1].depth2[sel2 - 1].depth3[sel3 - 1].depth4[sel4 - 1].depth4Name : "",
    }
    let result = await getCategoryContents(temp)
  }
  const delFn = async () => {
    let temp = []
    for (let i = dataList.length - 1; i >= 0; i--) {
      let check = false
      for (let k of checkList) {
        if (k == i) {
          check = true
        }
      }
      if (!check) {
        temp = [...temp, dataList[i]]
      }
    }
    let tempReverse = []
    for (let i = temp.length - 1; i >= 0; i--) {
      tempReverse = [...tempReverse, temp[i]]
    }
    setDataList(tempReverse)
    setCheckList([])
  }
  const saveFn = async () => {
    let temp = []
    if (categoryContentsList.length == 0) {
      popupFn("초기 데이터가 필요합니다.\n조회 후 진행해주세요.")
      return false
    }
    for (let i of categoryContentsList[step].categories) {
      let check = false
      for (let j of dataList) {
        if (i.contentCategorySeq != "" && i.contentCategorySeq == j.contentCategorySeq) {
          check = true
        }
      }
      if (check == false) {
        temp = [...temp, i.contentCategorySeq]
      }
    }
    if (temp.length > 0) {
      let result = await delCategoryContents({ contentCategorySeqs: temp })
      if (result) {
        setSel1(0)
        setSel2(0)
        setSel3(0)
        setSel4(0)
      }
    }
    let temp2 = []
    for (let i of dataList) {
      if (
        i.contentCategorySeq == "" &&
        !Validator.isEmpty(i.depth1, { ignore_whitespace: true }) &&
        !Validator.isEmpty(i.brandName, { ignore_whitespace: true })
      ) {
        temp2 = [...temp2, i]
      }
    }
    console.log(temp2)
    if (temp2.length > 0) {
      let result = await postCategoryContents({ contentCategories: temp2 })
      if (result) {
        setSel1(0)
        setSel2(0)
        setSel3(0)
        setSel4(0)
      }
    }
    popupFn("저장되었습니다.")
    getDate()
  }
  //엑셀 업로드용 json 변환
  const excelUploadFn = async event => {
    let input = event.target
    let reader = new FileReader()
    let temp = {}
    reader.onload = async () => {
      let fileData = reader.result
      let wb = XLSX.read(fileData, { type: "binary" })

      // let tData = XLSX.utils.sheet_to_json(wb.Sheets[i])
      for (let i of wb.SheetNames) {
        let tData = XLSX.utils.sheet_to_json(wb.Sheets[i])
        temp = { ...temp, [i]: tData }
      }
      console.log("temp", temp)
      if (Object.keys(temp).length == 0 || temp == undefined) {
        return false
      }

      temp = temp[Object.keys(temp)[0]]
      let changeList = []
      for (let i of temp) {
        let inTemp = {}
        inTemp.brandName = i["브랜드명"]
        inTemp.depth1 = i["1Depth Category"]
        inTemp.depth2 = i["2Depth Category"]
        inTemp.depth3 = i["3Depth Category"]
        inTemp.depth4 = i["4Depth Category"]
        changeList = [...changeList, inTemp]
      }
      console.log(changeList)
      let result = await postCategoryContentsExcelUpload({ contentCategories: changeList })
      if (result) {
        popupFn("업로드 완료")
        setSel1(0)
        setSel2(0)
        setSel3(0)
        setSel4(0)
        getDate()
      }
    }
    reader.readAsBinaryString(input.files[0])
  }
  useEffect(() => {
    getDate()
    return () => {
      categoryContentsListEmpty()
    }
  }, [])
  useEffect(() => {
    if (dataCheckFn(categoryContentsList)) {
      setDataList([...categoryContentsList[step].categories.map(i => i)])
    }
  }, [categoryContentsList, step])
  useEffect(() => {
    console.log(data, "dataChange")
  }, [])
  return (
    <PrivateRoute>
      <Layout>
        <SEO title="컨텐츠 카테고리 관리" />
        <TopInfo title={["컨텐츠 카테고리 관리", "운영관리", "컨텐츠 카테고리 관리"]} />

        <div className="operation-learnerType-container">
          <div style={{ border: "1px solid #E1E1E1" }} className="operation-learnerType-table">
            <div className="operation-learnerType-table-name">
              <div>브랜드명</div>
            </div>
            <div className="operation-learnerType-table-contents justify-content-between">
              <div className="d-flex align-items-center">
                <div className="ms-4" style={{ width: "190px" }}>
                  <select
                    className="form-select"
                    onChange={e => {
                      setBrandSel(e.target.value)
                    }}
                  >
                    <option className="" value="">
                      브랜드
                    </option>
                    {brandList.map((i, idx) => (
                      <option className="" key={idx} value={i.brandName}>
                        {i.brandName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="operation-learnerType-table-name ms-4">
              <div>카테고리</div>
            </div>
            <div className="d-flex align-items-center">
              <div className="ms-2" style={{ width: "120px" }}>
                <select
                  className="form-select"
                  value={sel1}
                  onChange={e => {
                    setSel2(0)
                    setSel3(0)
                    setSel4(0)
                    setSel1(e.target.value)
                  }}
                >
                  <option className="" value={0}>
                    1depth Category
                  </option>
                  {categoryList.map((i, idx) => (
                    <option key={idx} className="" value={idx + 1}>
                      {i.depth1Name}
                    </option>
                  ))}
                </select>
              </div>
              {sel1 > 0 && (
                <div className="ms-1" style={{ width: "120px" }}>
                  <select
                    className="form-select"
                    value={sel2}
                    onChange={e => {
                      setSel2(e.target.value)
                      setSel3(0)
                      setSel4(0)
                    }}
                  >
                    <option className="" value={0}>
                      2depth Category
                    </option>
                    {categoryList[sel1 - 1].depth2.map((i, idx) => {
                      if (i.depth2Name == "") {
                        return false
                      } else {
                        return (
                          <option key={idx} className="" value={idx + 1}>
                            {i.depth2Name}
                          </option>
                        )
                      }
                    })}
                  </select>
                </div>
              )}
              {sel2 > 0 && (
                <div className="ms-1" style={{ width: "120px" }}>
                  <select
                    className="form-select"
                    value={sel3}
                    onChange={e => {
                      setSel3(e.target.value)

                      setSel4(0)
                    }}
                  >
                    <option className="" value={0}>
                      3depth Category
                    </option>
                    {categoryList[sel1 - 1].depth2[sel2 - 1].depth3.map((i, idx) => {
                      if (i.depth3Name == "") {
                        return false
                      } else {
                        return (
                          <option key={idx} className="" value={idx + 1}>
                            {i.depth3Name}
                          </option>
                        )
                      }
                    })}
                  </select>
                </div>
              )}
              {sel3 > 0 && (
                <div className="ms-1" style={{ width: "120px" }}>
                  <select
                    value={sel4}
                    className="form-select"
                    onChange={e => {
                      setSel4(e.target.value)
                    }}
                  >
                    <option className="" value={0}>
                      4depth Category
                    </option>
                    {categoryList[sel1 - 1].depth2[sel2 - 1].depth3[sel3 - 1].depth4.map((i, idx) => {
                      if (i.depth4Name == "") {
                        return false
                      } else {
                        return (
                          <option key={idx} className="" value={idx + 1}>
                            {i.depth4Name}
                          </option>
                        )
                      }
                    })}
                  </select>
                </div>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <div onClick={getSearchData} className="btn btn-primary font-white fgb-btn-l">
              조회
            </div>
          </div>
          <div className="contents-brand-tab mt-3 mb-3">
            {categoryContentsList.map((i, idx) => (
              <button
                key={idx}
                onClick={() => {
                  setStep(idx)
                  setCheckList([])
                  idx
                }}
                className={step == idx ? "active" : ""}
              >
                {i.brandName}
              </button>
            ))}
          </div>
          <div className="d-flex justify-content-between mt-4">
            <div className="d-flex align-items-center">
              <DownloadTableExcel filename={`컨텐츠 카테고리 목록`} sheet={`컨텐츠 카테고리 목록`} currentTableRef={contentsTable.current}>
                {/* <Button className="btn fgb-btn-m fgb-btn-grean"> Export excel </Button> */}
                <div className="btn fgb-btn-m fgb-btn-grean me-2">
                  <img src={download} />
                  <p>엑셀 다운로드</p>
                </div>
              </DownloadTableExcel>
              {/* <div style={{ display: "none" }}>
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className=""
                  table="contentsTable"
                  filename={`컨텐츠 카테고리 목록`}
                  sheet={`컨텐츠 카테고리 목록`}
                  buttonText={``}
                ></ReactHTMLTableToExcel>
              </div> */}

              <input
                style={{ display: "none" }}
                type="file"
                id="excelFile"
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={e => excelUploadFn(e)}
              />
              <button onClick={() => document.getElementById("excelFile").click()} className="btn fgb-btn-m fgb-btn-grean">
                <img src={upload} />
                <p>엑셀 업로드</p>
              </button>
            </div>
            <div className="d-flex justify-content-end">
              <div onClick={delFn} className="btn btn-primary font-white reverse fgb-btn-ms clear-button me-2">
                선택 삭제
              </div>
              <div
                onClick={() => {
                  setDataList([
                    ...dataList,
                    {
                      contentCategorySeq: "",
                      brandName: "",
                      depth1: "",
                      depth2: "",
                      depth3: "",
                      depth4: "",
                    },
                  ])
                }}
                className="btn btn-primary font-white  fgb-btn-m me-2"
              >
                행추가
              </div>
            </div>
          </div>
          <div className="operation-learnerType-list-table mt-3">
            <div className="fgb-table-div">
              <table className={`fgb-table mb-4`}>
                <thead>
                  <tr className={`fgb-table-title-tr`}>
                    <td>
                      <img
                        className="ms-4 me-2"
                        onClick={() => {
                          if (checkList.length == dataList.length) {
                            setCheckList([])
                          } else {
                            let temp = []
                            let idx = 0
                            for (let i of dataList) {
                              temp = [...temp, idx]
                              idx += 1
                            }
                            setCheckList(temp)
                          }
                        }}
                        src={checkList.length == dataList.length ? checked_on : checked_off}
                      />
                    </td>
                    {["brandName", "depth1", "depth2", "depth3", "depth4", "등록일", "변경일"].map((i, idx) => (
                      <td key={idx}>{i}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {dataList.map((i, idx) => (
                    <tr className={`fgb-table-tr ${checkList.indexOf(idx) != -1 ? "active" : ""}`} key={idx}>
                      <td>
                        <img
                          onClick={() => {
                            let check = checkList.indexOf(idx)
                            console.log(check)
                            if (check == -1) {
                              setCheckList([...checkList, idx])
                            } else {
                              let temp = [...checkList]
                              temp.splice(check, 1)
                              setCheckList(temp)
                            }
                          }}
                          className="ms-4 me-2"
                          src={checkList.indexOf(idx) != -1 ? checked_on : checked_off}
                        />
                      </td>
                      {i.contentCategorySeq != "" ? (
                        <td>{i.brandName}</td>
                      ) : (
                        <td>
                          <input
                            type="text"
                            className="fgb-input"
                            value={i.brandName}
                            onChange={e => {
                              let temp = [...dataList]
                              temp[idx].brandName = e.target.value
                              setDataList(temp)
                            }}
                          ></input>
                        </td>
                      )}
                      {i.contentCategorySeq != "" ? (
                        <td>{i.depth1}</td>
                      ) : (
                        <td>
                          <input
                            type="text"
                            className="fgb-input"
                            value={i.depth1}
                            onChange={e => {
                              let temp = [...dataList]
                              temp[idx].depth1 = e.target.value
                              setDataList(temp)
                            }}
                          ></input>
                        </td>
                      )}
                      {i.contentCategorySeq != "" ? (
                        <td>{i.depth2}</td>
                      ) : (
                        <td>
                          <input
                            type="text"
                            className="fgb-input"
                            value={i.depth2}
                            onChange={e => {
                              let temp = [...dataList]
                              temp[idx].depth2 = e.target.value
                              setDataList(temp)
                            }}
                          ></input>
                        </td>
                      )}
                      {i.contentCategorySeq != "" ? (
                        <td>{i.depth3}</td>
                      ) : (
                        <td>
                          <input
                            type="text"
                            className="fgb-input"
                            value={i.depth3}
                            onChange={e => {
                              let temp = [...dataList]
                              temp[idx].depth3 = e.target.value
                              setDataList(temp)
                            }}
                          ></input>
                        </td>
                      )}
                      {i.contentCategorySeq != "" ? (
                        <td>{i.depth4}</td>
                      ) : (
                        <td>
                          <input
                            type="text"
                            className="fgb-input"
                            value={i.depth4}
                            onChange={e => {
                              let temp = [...dataList]
                              temp[idx].depth4 = e.target.value
                              setDataList(temp)
                            }}
                          ></input>
                        </td>
                      )}
                      <td>{i.regDatetime ? moment(i.regDatetime).format("MM-DD-YYYY") : ""}</td>
                      <td>{i.updateDatetime ? moment(i.updateDatetime).format("MM-DD-YYYY") : ""}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div style={{ display: "none" }}>
                <table ref={contentsTable} id="contentsTable" className={`fgb-table mb-4`}>
                  <thead>
                    <tr className={`fgb-table-title-tr`}>
                      {["브랜드명", "1Depth Category", "2Depth Category", "3Depth Category", "4Depth Category", "등록일", "변경일"].map((i, idx) => (
                        <td key={idx}>{i}</td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={`fgb-table-title-tr`}>
                      {["브랜드명", "1Depth Category", "2Depth Category", "3Depth Category", "4Depth Category", "등록일", "변경일"].map((i, idx) => (
                        <td key={idx}>{i}</td>
                      ))}
                    </tr>
                    {dataList.map((i, idx) => (
                      <tr className={`fgb-table-tr ${checkList.indexOf(idx) != -1 ? "active" : ""}`} key={idx}>
                        {i.contentCategorySeq != "" ? (
                          <td>{i.brandName}</td>
                        ) : (
                          <td>
                            <input
                              type="text"
                              className="fgb-input"
                              value={i.brandName}
                              onChange={e => {
                                let temp = [...dataList]
                                temp[idx].brandName = e.target.value
                                setDataList(temp)
                              }}
                            ></input>
                          </td>
                        )}
                        {i.contentCategorySeq != "" ? (
                          <td>{i.depth1}</td>
                        ) : (
                          <td>
                            <input
                              type="text"
                              className="fgb-input"
                              value={i.depth1}
                              onChange={e => {
                                let temp = [...dataList]
                                temp[idx].depth1 = e.target.value
                                setDataList(temp)
                              }}
                            ></input>
                          </td>
                        )}
                        {i.contentCategorySeq != "" ? (
                          <td>{i.depth2}</td>
                        ) : (
                          <td>
                            <input
                              type="text"
                              className="fgb-input"
                              value={i.depth2}
                              onChange={e => {
                                let temp = [...dataList]
                                temp[idx].depth2 = e.target.value
                                setDataList(temp)
                              }}
                            ></input>
                          </td>
                        )}
                        {i.contentCategorySeq != "" ? (
                          <td>{i.depth3}</td>
                        ) : (
                          <td>
                            <input
                              type="text"
                              className="fgb-input"
                              value={i.depth3}
                              onChange={e => {
                                let temp = [...dataList]
                                temp[idx].depth3 = e.target.value
                                setDataList(temp)
                              }}
                            ></input>
                          </td>
                        )}
                        {i.contentCategorySeq != "" ? (
                          <td>{i.depth4}</td>
                        ) : (
                          <td>
                            <input
                              type="text"
                              className="fgb-input"
                              value={i.depth4}
                              onChange={e => {
                                let temp = [...dataList]
                                temp[idx].depth4 = e.target.value
                                setDataList(temp)
                              }}
                            ></input>
                          </td>
                        )}

                        <td>{i.contentCategorySeq != "" ? i.regDatetime : ""}</td>
                        <td>{i.contentCategorySeq != "" ? i.updateDatetime : ""}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="d-flex justify-content-center">
                <button className="btn btn-primary fgb-btn-gray fgb-btn-l ">취소</button>
                <button onClick={saveFn} className="ms-2 btn btn-primary font-white fgb-btn-l ">
                  저장
                </button>
              </div>
              {/* <Pagination current={current} setCurrent={setCurrent} total={200} /> */}
            </div>
          </div>
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default OperationContainer(ContantsCategory)
