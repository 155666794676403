/*
  추천 프로그램 등록 페이지
*/
import React, { useState, useEffect, useRef } from "react"

import "swiper/scss"

import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import moment from "moment"
import CalendarComponent from "../../components/Calendar"
import Pagination from "../../components/common/pagination"
import TopInfo from "../../components/common/TopInfo"
import RecommendationPopup from "../../popups/schedule/RecommendationPopup"

import CommonContainer from "../../containers/CommonContainer"
import RecommendContainer from "../../containers/RecommendContainer"

import { toStringByFormatting, downExcel, popupFn } from "../../utils/util"

import icon_calendar from "../../images/ion_calendar-number-outline.svg"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"
import download from "../../images/download.svg"
import CloseButton from "../../images/img_popup_close_black.svg"
import { set } from "lodash"
import PrivateRoute from "../../components/privateRoute"

const RegisterRecommendationProgram = props => {
  const { getRecommendList, recommendList, getStudentGroupList, studentGroupList, getRecommendationProgramExcel, delRecommendationProgram } = props

  const isMounted = useRef(false)

  // popup
  const [recommendationPopup, setRecommendationPopup] = useState({ status: false, isModify: false })
  const [info, setInfo] = useState()

  // pagination
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(1)

  // 조회 결과 전체 선택 체크 버튼
  const [allCheck, setAllCheck] = useState(false)
  const [checkList, setCheckList] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ])
  const [checkedSeq, setCheckedSeq] = useState([])

  // 학생 소속 드롭박스 값
  const [studentGroupSeqLevel1, setStudentGroupSeqLevel1] = useState([])

  //date 세팅용 state
  const [calendarPopup, setCalendarPopup] = useState(false)
  const [calendarPopup1, setCalendarPopup1] = useState(false)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [date, setDate] = useState(moment())
  const [popupType, setPopupType] = useState(false)

  // 변수들
  const [kind, setKind] = useState({
    // 구분 체크박스 (전체, 유료, 무료)
    all: true,
    paid: true,
    free: true,
  })
  const [learningGroup, setLearningGroup] = useState("") // 학습자 그룹 드롭박스 기본값: 전체
  const [use, setUse] = useState({
    // 사용 여부 체크박스 (전체, 사용, 미사용)
    all: true,
    y: true,
    n: true,
  })
  const [keyword, setKeyword] = useState() // 키워드 Input Box
  const [createdDate, setCreatedDate] = useState(moment()) // 생성일 기본값 ''
  const [isCreatedDateUnabled, setIsCreatedDateUnabled] = useState(false) // 생성일 입력시 true로

  useEffect(() => {
    async function getInitLists() {
      await getStudentGroupList()
    }

    async function ResetRecommendList() {
      await getRecommendList()
    }

    getInitLists()

    return () => {
      ResetRecommendList()
    }
  }, [])
  useEffect(() => {
    setStudentGroupSeqLevel1(studentGroupList)
  }, [studentGroupList])

  // pagination 페이지 바꿀 때
  useEffect(() => {
    if (isMounted.current) {
      handleSearch(current - 1, true)
      setCheckList([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false])
    } else {
      isMounted.current = true
    }
  }, [current])

  useEffect(() => {
    setTotal(recommendList?.totalCount)
  }, [recommendList])

  useEffect(() => {
    if (allCheck) {
      setCheckList([true, true, true, true, true, true, true, true, true, true, true, true, true, true, true])
    } else {
      setCheckList([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false])
    }
  }, [allCheck])

  const handleSearch = async (num, isSearch) => {
    if (endDate < startDate) {
      alert("조회 기간을 제대로 설정해주세요.")
    } else {
      const date_start = toStringByFormatting(startDate)
      const date_end = toStringByFormatting(endDate)
      const is_point = kind.paid && kind.free ? "" : kind.paid ? "N" : kind.free ? "Y" : ""
      const student_group_seq = learningGroup
      const is_use = use.y && use.n ? "" : use.y ? "Y" : use.n ? "N" : ""
      const tags_ = []
      tags_.push(keyword)
      const reg_datetime = createdDate.isValid() ? createdDate.format("YYYY-MM-DD") : "" // toStringByFormatting(createdDate)
      const page_ = num

      console.log(`
        date_start: ${date_start}
        date_end: ${date_end}
        is_point: ${is_point}
        student_group_seq: ${student_group_seq}
        is_use: ${is_use}
        tags_: ${tags_}
        reg_datetime: ${reg_datetime}
        page_: ${page_}
      `)

      if (isSearch) {
        const result = await getRecommendList({
          dateStart: date_start,
          dateEnd: date_end,
          isPoint: is_point,
          studentGroupSeq: student_group_seq,
          isUse: is_use,
          tags: tags_,
          regDatetime: reg_datetime,
          page: page_,
          count: 15,
        })
      } else {
        const result = await getRecommendationProgramExcel({
          dateStart: date_start,
          dateEnd: date_end,
          isPoint: is_point,
          studentGroupSeq: student_group_seq,
          isUse: is_use,
          tags: tags_,
          regDatetime: reg_datetime,
          page: page_,
          count: 15,
        })
        console.log(result)
        await downExcel({ result: result, fileName: "추천프로그램" })
      }
    }
  }

  const ChangeCheckList = item => {
    let list_ = []
    for (let i = 0; i < checkList.length; i++) {
      if (i === item) {
        list_.push(!checkList[i])
      } else {
        list_.push(checkList[i])
      }
    }
    setCheckList(list_)
  }

  const handleDelete = async () => {
    const seq = []
    if (recommendList?.recommendPrograms) {
      if (recommendList?.recommendPrograms.length > 0) {
        for (let i = 0; i < recommendList?.recommendPrograms.length; i++) {
          if (checkList[i] === true) {
            seq.push(recommendList?.recommendPrograms[i].recommendProgramSeq)
          }
        }
        if (seq.length === 0) {
          alert("삭제할 프로그램을 선택해주세요.")
        } else {
          const payload = { recommendProgramSeqs: seq }
          const result = await delRecommendationProgram(payload)
          if (result) {
            popupFn("삭제되었습니다.")
            handleSearch(current - 1, true)
            setCheckList([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false])
          }
        }
      } else {
        alert("삭제할 프로그램을 선택해주세요.")
      }
    }
  }

  const UnableModifyPopup = info => {
    setRecommendationPopup({ status: true, isModify: true })
    setInfo(info)
  }

  return (
    <PrivateRoute>
      <>
        {recommendationPopup.status && (
          <RecommendationPopup
            isModify={recommendationPopup.isModify}
            info={info}
            onClose={e => {
              setRecommendationPopup({ status: false, isModify: false })
              setInfo()
            }}
          />
        )}
        <Layout>
          <SEO title="추천 프로그램 등록" />
          <TopInfo title={["추천 프로그램 등록", "스터디 스케줄 관리", "추천 프로그램 등록"]} />

          <div className="recommendation-container">
            <div className="recommendation-filter-wrapper">
              <div className="table-row">
                <div className="element-l">조회 기간</div>
                <div className="element-r small-box" style={{ width: "260px" }}>
                  <div className="fgb-date-form">
                    <img src={icon_calendar} />
                    <div
                      className="fgb-date-input"
                      onClick={() => {
                        setPopupType("startDate")
                        setCalendarPopup(true)
                      }}
                    >
                      <p>{startDate.isValid() ? startDate.format("YYYY.MM.DD") : "시작일"}</p>
                    </div>
                    {calendarPopup && (
                      <div className="calendar-popup-back">
                        <div className="calendar-popup-div">
                          <CalendarComponent
                            date={date}
                            setDate={setDate}
                            selectedDate={popupType === "startDate" ? startDate : popupType === "endDate" ? endDate : createdDate}
                            setSelectedDate={popupType === "startDate" ? setStartDate : popupType === "endDate" ? setEndDate : setCreatedDate}
                            popup={setCalendarPopup}
                          />
                        </div>
                      </div>
                    )}
                    <p>~</p>
                    <img src={icon_calendar} />
                    <div
                      className="fgb-date-input"
                      onClick={() => {
                        setPopupType("endDate")
                        setCalendarPopup(true)
                      }}
                    >
                      <p>{endDate.isValid() ? endDate.format("YYYY.MM.DD") : "종료일"}</p>
                    </div>
                  </div>
                </div>
                <div className="element-l">구분</div>
                <div className="element-r" style={{ width: "210px" }}>
                  <div
                    className="check-box-wrapper"
                    onClick={() => {
                      if (kind.paid && kind.free) {
                        setKind({ paid: false, free: false })
                      } else {
                        setKind({ paid: true, free: true })
                      }
                    }}
                  >
                    <img src={kind.paid && kind.free ? checked_on : checked_off} />
                    <p>전체</p>
                  </div>
                  <div className="check-box-wrapper" onClick={() => setKind({ ...kind, paid: !kind.paid })}>
                    <img src={kind.paid ? checked_on : checked_off} />
                    <p>유료</p>
                  </div>
                  <div className="check-box-wrapper" onClick={() => setKind({ ...kind, free: !kind.free })}>
                    <img src={kind.free ? checked_on : checked_off} />
                    <p>무료</p>
                  </div>
                </div>
                <div style={{ minWidth: "100px" }} className="element-l">
                  학습자 그룹
                </div>
                <div className="element-r" style={{ width: "210px" }}>
                  <div style={{ width: "180px" }}>
                    <select
                      className="form-select"
                      style={{ backgroundColor: "#F2F8FC", border: "none", cursor: "pointer" }}
                      onChange={e => setLearningGroup(e.target.value)}
                    >
                      <option className="" value="">
                        전체
                      </option>
                      {studentGroupSeqLevel1 &&
                        studentGroupSeqLevel1?.studentGroups &&
                        studentGroupSeqLevel1?.studentGroups.map((i, idx) => (
                          <option value={i?.level1?.level1Seq} key={idx}>
                            {i?.level1?.level1Name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="table-row">
                <div style={{ width: "99.72px" }} className="element-l">
                  사용 여부
                </div>
                <div className="element-r" style={{ width: "260px", minWidth: "260px" }}>
                  <div
                    className="check-box-wrapper"
                    onClick={() => {
                      if (use.y && use.n) {
                        setUse({ y: false, n: false })
                      } else {
                        setUse({ y: true, n: true })
                      }
                    }}
                  >
                    <img src={use.y && use.n ? checked_on : checked_off} />
                    <p>전체</p>
                  </div>
                  <div className="check-box-wrapper" onClick={() => setUse({ ...use, y: !use.y })}>
                    <img src={use.y ? checked_on : checked_off} />
                    <p>사용</p>
                  </div>
                  <div className="check-box-wrapper" onClick={() => setUse({ ...use, n: !use.n })}>
                    <img src={use.n ? checked_on : checked_off} />
                    <p>미사용</p>
                  </div>
                </div>
                <div style={{ width: "99.72px" }} className="element-l">
                  키워드
                </div>
                <div className="element-r" style={{ width: "210px" }}>
                  <input className="input-box" value={keyword} onChange={e => setKeyword(e.target.value)} style={{ width: "180px" }} />
                </div>
                <div style={{ width: "100px", minWidth: "100px" }} className="element-l">
                  생성일
                </div>
                <div className="element-r" style={{ width: "210px" }}>
                  {calendarPopup1 && (
                    <div className="calendar-popup-back">
                      <div className="calendar-popup-div">
                        <CalendarComponent
                          date={date}
                          setDate={setDate}
                          selectedDate={popupType === "startDate" ? startDate : popupType === "endDate" ? endDate : createdDate}
                          setSelectedDate={popupType === "startDate" ? setStartDate : popupType === "endDate" ? setEndDate : setCreatedDate}
                          popup={setCalendarPopup1}
                        />
                      </div>
                    </div>
                  )}
                  <div className="fgb-date-form">
                    <img src={icon_calendar} />
                    <div
                      className="fgb-date-input"
                      onClick={() => {
                        setPopupType("createdDate")
                        setCalendarPopup1(true)
                      }}
                    >
                      <p>{createdDate.isValid() ? createdDate.format("YYYY.MM.DD") : "생성일"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="search-btn-wrapper">
              <div className="btn btn-primary font-white fgb-btn-l" onClick={() => handleSearch(0, true)}>
                조회
              </div>
            </div>

            <div className="result-info-wrapper">
              <div className="people-num">{recommendList?.totalCount ? recommendList.totalCount : 0}명</div>
              <div className="two-btn-wrapper">
                <div className="btn btn-primary font-white reverse fgb-btn-ms clear-button me-2" onClick={handleDelete} style={{ cursor: "pointer" }}>
                  선택 삭제
                </div>
                <div
                  className="excel-download-btn btn fgb-btn-m fgb-btn-grean"
                  style={{ borderRadius: "6px", marginRight: "6px" }}
                  onClick={e => handleSearch(current - 1, false)}
                >
                  <img src={download} />
                  <p>엑셀 다운로드</p>
                </div>
                <div className="btn btn-primary font-white fgb-btn-l" onClick={e => setRecommendationPopup({ status: true, isModify: false })}>
                  추천 프로그램 등록
                </div>
              </div>
            </div>

            {recommendList !== undefined && recommendList?.recommendPrograms !== undefined && recommendList?.recommendPrograms.length !== 0 ? (
              <>
                <div className="fgb-table-div">
                  <table className="fgb-table">
                    <thead>
                      <tr className="fgb-table-title-tr">
                        <td className="check-box-img" onClick={() => setAllCheck(!allCheck)}>
                          <img src={allCheck ? checked_on : checked_off} />
                        </td>
                        {Title.map((i, idx) => (
                          <td key={idx}>{i}</td>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {recommendList?.recommendPrograms.map((i, idx) => (
                        <tr className="fgb-table-tr" key={idx}>
                          <td onClick={() => ChangeCheckList(idx)}>
                            <img src={checkList[idx] ? checked_on : checked_off} />
                          </td>
                          <td onClick={() => UnableModifyPopup(i)}>{i?.isPoint === "Y" ? "유료" : "무료"}</td>
                          <td onClick={() => UnableModifyPopup(i)}>{i?.isUse === "Y" ? "사용" : "미사용"}</td>
                          <td onClick={() => UnableModifyPopup(i)}>{i?.studentGroupName}</td>
                          <td onClick={() => UnableModifyPopup(i)}>{i?.title}</td>
                          <td onClick={() => UnableModifyPopup(i)}>{i?.tag}</td>
                          <td onClick={() => UnableModifyPopup(i)}>{i?.dateStart}</td>
                          <td onClick={() => UnableModifyPopup(i)}>{i?.dateEnd}</td>
                          <td onClick={() => UnableModifyPopup(i)}>{i?.totalDay}</td>
                          <td onClick={() => UnableModifyPopup(i)}>{i?.isLoop === "Y" ? "반복" : "1회"}</td>
                          <td onClick={() => UnableModifyPopup(i)}>{i?.studyDay}</td>
                          <td onClick={() => UnableModifyPopup(i)}>{i?.studyTime}</td>
                          <td onClick={() => UnableModifyPopup(i)}>{i?.name}</td>
                          <td onClick={() => UnableModifyPopup(i)}>{i?.regDatetime}</td>
                          <td onClick={() => UnableModifyPopup(i)}>{i?.updateDatetime}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Pagination count={15} current={current} setCurrent={setCurrent} total={total} />
              </>
            ) : (
              <>
                {/* <div className="search-none-wrapper">
                  <p>조회 결과가 없습니다.</p>
                  <p>원하는 항목을 선택하고 '조회' 버튼을 눌러주십시오.</p>
                </div> */}
              </>
            )}
          </div>
        </Layout>
      </>
    </PrivateRoute>
  )
}

export default RecommendContainer(CommonContainer(RegisterRecommendationProgram))

const Title = [
  "구분",
  "사용 여부",
  "학습자 그룹",
  "프로그램 제목",
  "키워드",
  "시작일",
  "완료일",
  "기간(일)",
  "옵션",
  "공부일",
  "공부 시간",
  "등록자",
  "생성일",
  "변경일",
]
