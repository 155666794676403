import React from "react"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"

import samlab from "../../images/samlab.svg"
import Facebook_white from "../../images/Facebook_white.svg"
import Youtube_white from "../../images/Youtube_white.svg"
import Instagram_white from "../../images/Instagram_white.svg"

const BottomInfo = () => {
  const dispatch = useDispatch()
  const isLogin = useSelector(state => state.sign.isLogin)

  return (
    <div className="login-bottom-info-container">
      <div className="login-bottom-info-div">
        <img src={samlab} />
        <div className="login-bottom-info-box">
          <p>대표 : 심세용</p>
          <p>경기도 시흥시 은계번영길 2 서미프라자 6층 601호 602호</p>
          <p>sAMlab@samlab.CO.KR</p>
        </div>
        <div className="login-bottom-info-box-2">
          <p>TEL</p>
          <p>1688-4264</p>
        </div>
        <div className="login-bottom-follow-div">
          <p>fOLLOW US</p>
          <div className="login-bottom-follow-box">
            <img src={Facebook_white} />
            <img src={Youtube_white} />
            <img src={Instagram_white} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BottomInfo
