/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect } from "react"

import "swiper/scss"

import SEO from "../../components/seo"
import CalendarComponent from "../../components/Calendar"
import TopInfo from "../../components/common/TopInfo"
import Pagination from "../../components/common/pagination"

import Layout from "../../components/Layout"
import moment from "moment"

import icon_calendar from "../../images/ion_calendar-number-outline.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"

import PointPopup from "../../popups/operation/PointPopup"

import OperationContainer from "../../containers/OperationContainer"

import PrivateRoute from "../../components/privateRoute"

const Point = props => {
  const { getPointRules, pointRulesEmpty, pointRules, getPointRule } = props
  //date 세팅용 state
  const [calendarPopup, setCalendarPopup] = useState(false)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [date, setDate] = useState(moment())
  const [popupType, setPopupType] = useState(false)

  const [checkList, setCheckList] = useState([])

  const [openPointPopup, setOpenPointPopup] = useState(false)

  const [current, setCurrent] = useState(1)

  const [dateType, setDateType] = useState(true)
  const [isUse, setIsUse] = useState(true)
  const [isUnused, setIsUnused] = useState(true)
  const [isOnce, setIsOnce] = useState(true)
  const [isAlways, setIsAlways] = useState(true)
  const [isReward, setIsRewerd] = useState(true)
  const [isSave, setIsSave] = useState(true)
  const [isExpirationY, setIsExpirationY] = useState(true)
  const [isExpirationN, setIsExpirationN] = useState(true)
  const getDate = async () => {
    let temp = {
      dateType: dateType ? "REG_DATE" : "UPDATE_DATE",
      dateStart: startDate.format("YYYY-MM-DD"),
      dateEnd: endDate.format("YYYY-MM-DD"),
      isUse: isUse && isUnused ? "" : isUse ? "Y" : isUnused ? "N" : "",
      isExpiration: isExpirationY && isExpirationN ? "" : isExpirationY ? "Y" : isExpirationN ? "N" : "",
      frequencyType: isOnce && isAlways ? "" : isOnce ? "ONCE" : isAlways ? "ALWAYS" : "",
      pointType: isReward && isSave ? "" : isReward ? "REWARD" : isSave ? "SAVE" : "",
    }

    await getPointRules(temp)
  }
  useEffect(() => {
    return () => {
      pointRulesEmpty()
    }
  }, [])

  return (
    <PrivateRoute>
      <Layout>
        <SEO title="렛미업 포인트 관리" />
        <PointPopup getData={getDate} open={openPointPopup} onClose={() => setOpenPointPopup(false)} />
        <TopInfo title={["렛미업 포인트 관리", "운영관리", "렛미업 포인트 관리"]} />

        <div className="operation-learnerType-container">
          <div className="operation-learnerType-table ">
            <div className="operation-learnerType-table-name ">
              <div>조회 기간</div>
            </div>
            <div className="operation-learnerType-table-contents justify-content-between">
              <div className="d-flex align-items-center">
                <img onClick={() => setDateType(true)} className="ms-4 me-2" src={dateType ? radio_on : radio_off} />
                <p>등록일 </p>
                <img onClick={() => setDateType(false)} className="ms-4 me-2" src={!dateType ? radio_on : radio_off} />
                <p>변경일</p>
              </div>
              <div style={{ marginRight: "56px", marginLeft: "20px" }} className="fgb-date-form">
                <img src={icon_calendar} />
                <div
                  className="fgb-date-input"
                  onClick={() => {
                    setPopupType(false)
                    setCalendarPopup(true)
                  }}
                >
                  <p>{startDate.isValid() ? startDate.format("YYYY.MM.DD") : "시작일"}</p>
                </div>
                {calendarPopup && (
                  <div className="calendar-popup-back">
                    <div className="calendar-popup-div">
                      <CalendarComponent
                        date={date}
                        setDate={setDate}
                        selectedDate={popupType == false ? startDate : endDate}
                        setSelectedDate={popupType == false ? setStartDate : setEndDate}
                        popup={setCalendarPopup}
                      />
                    </div>
                  </div>
                )}
                <p>~</p>
                <img src={icon_calendar} />

                <div
                  className="fgb-date-input"
                  onClick={() => {
                    setPopupType(true)
                    setCalendarPopup(true)
                  }}
                >
                  <p>{endDate.isValid() ? endDate.format("YYYY.MM.DD") : "종료일"}</p>
                </div>
              </div>
            </div>
            <div className="operation-learnerType-table-name">
              <div>사용여부</div>
            </div>
            <div className="d-flex align-items-center">
              <img
                onClick={() => {
                  if (isUse && isUnused) {
                    setIsUse(false)
                    setIsUnused(false)
                  } else {
                    setIsUse(true)
                    setIsUnused(true)
                  }
                }}
                className="ms-3 me-2"
                src={isUse && isUnused ? checked_on : checked_off}
              />
              <p>전체 </p>
              <img onClick={() => setIsUse(!isUse)} className="ms-3 me-2" src={isUse ? checked_on : checked_off} />
              <p>사용</p>
              <img onClick={() => setIsUnused(!isUnused)} className="ms-3 me-2" src={isUnused ? checked_on : checked_off} />
              <p>미사용</p>
            </div>
          </div>
          <div className="operation-learnerType-table">
            <div className="operation-learnerType-table-name">
              <div>빈도</div>
            </div>
            <div className="d-flex align-items-center me-3">
              <img
                onClick={() => {
                  if (isOnce && isAlways) {
                    setIsOnce(false)
                    setIsAlways(false)
                  } else {
                    setIsOnce(true)
                    setIsAlways(true)
                  }
                }}
                className="ms-3 me-2"
                src={isOnce && isAlways ? checked_on : checked_off}
              />
              <p>전체 </p>
              <img onClick={() => setIsOnce(!isOnce)} className="ms-3 me-2" src={isOnce ? checked_on : checked_off} />
              <p>1회</p>
              <img onClick={() => setIsAlways(!isAlways)} className="ms-3 me-2" src={isAlways ? checked_on : checked_off} />
              <p>항상</p>
            </div>
            <div className="operation-learnerType-table-name">
              <div>유형</div>
            </div>
            <div className="d-flex align-items-center me-3">
              <img
                onClick={() => {
                  if (isReward && isSave) {
                    setIsRewerd(false)
                    setIsSave(false)
                  } else {
                    setIsRewerd(true)
                    setIsSave(true)
                  }
                }}
                className="ms-3 me-2"
                src={isReward && isSave ? checked_on : checked_off}
              />
              <p>전체 </p>
              <img onClick={() => setIsRewerd(!isReward)} className="ms-3 me-2" src={isReward ? checked_on : checked_off} />
              <p>보상</p>
              <img onClick={() => setIsSave(!isSave)} className="ms-3 me-2" src={isSave ? checked_on : checked_off} />
              <p>적립</p>
            </div>
            <div className="operation-learnerType-table-name">
              <div>소멸적용</div>
            </div>
            <div className="d-flex align-items-center">
              <img
                onClick={() => {
                  if (isExpirationY && isExpirationN) {
                    setIsExpirationY(false)
                    setIsExpirationN(false)
                  } else {
                    setIsExpirationY(true)
                    setIsExpirationN(true)
                  }
                }}
                className="ms-3 me-2"
                src={isExpirationY && isExpirationN ? checked_on : checked_off}
              />
              <p>전체 </p>
              <img onClick={() => setIsExpirationY(!isExpirationY)} className="ms-3 me-2" src={isExpirationY ? checked_on : checked_off} />
              <p>사용</p>
              <img onClick={() => setIsExpirationN(!isExpirationN)} className="ms-3 me-2" src={isExpirationN ? checked_on : checked_off} />
              <p>미사용</p>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <div onClick={getDate} className="btn btn-primary font-white fgb-btn-l">
              조회
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mt-4">
            <p>{pointRules.length.toLocaleString()}건</p>
            <div className="d-flex justify-content-end">
              <div className="btn btn-primary font-white reverse fgb-btn-ms clear-button me-2">선택 삭제</div>
              {/* <div className="btn btn-primary font-white  fgb-btn-m me-2">포인트 유형 등록</div> */}
            </div>
          </div>
          <div className="introduce-study-table mt-3">
            <div className="fgb-table-div">
              <table className={`fgb-table mb-4`}>
                <thead>
                  <tr className={`fgb-table-title-tr`}>
                    <td>
                      <img className="ms-4 me-2" src={false ? checked_on : checked_off} />
                    </td>
                    {["포인트 지급 대상 이름", "빈도", "지급 포인트", "유형", "사용여부", "소멸 적용", "소멸기간", "등록자", "등록일", "변경일"].map(
                      (i, idx) => (
                        <td key={idx}>{i}</td>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {pointRules.map((i, idx) => (
                    <tr className={`fgb-table-tr `} key={idx}>
                      <td>
                        <img className="ms-4 me-2" src={false ? checked_on : checked_off} />
                      </td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={async () => {
                          await getPointRule(i.pointRuleSeq)
                          setOpenPointPopup(true)
                        }}
                      >
                        {i.action}
                      </td>
                      <td>{i.frequencyType == "Y" ? "1회" : "항상"}</td>
                      <td>{i.frequencyPoint.indexOf("%") != -1 ? i.frequencyPoint : Math.floor(i.frequencyPoint).toLocaleString()}</td>
                      <td>{i.pointType == "REWORD" ? "보상" : "적립"}</td>
                      <td>{i.isUse}</td>
                      <td>{i.isExpiration}</td>
                      <td>{i.expirationDate}</td>
                      <td>{i.name}</td>
                      <td>{moment(i.regDatetime).format("YYYY-MM-DD")}</td>
                      <td>{i.updateDatetime != "" ? moment(i.updateDatetime).format("YYYY-MM-DD") : ""}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default OperationContainer(Point)
