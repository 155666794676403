import React, { useState } from "react"
import img_popup_close_gray from "../../images/img_popup_close_gray.svg"
import CloseBtn from "../../images/Close_Button.svg"
import LayerPopupLayout from "../LayerPopupLayout"
import OperationContainer from "../../containers/OperationContainer"
import SignContainer from "../../containers/SignContainer"
import MasterContainer from "../../containers/MasterContainer"
import ModalPopup from "../ModalPopup"

import Validator from "validator"
import { debounce } from "lodash"
import { dataCheckFn, popupFn } from "../../utils/util"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"
import { useEffect } from "react"
import moment from "moment"
const TYPE = { CRAM_SCHOOL: "학원", STUDY_CAFE: "스터디 카페", CONSULTANT: "컨설턴트" }
const STATUS = { REJECT: "반려", WAITING: "요청", APPROVAL: "승인" }
const AffiliatesInfoPopup = props => {
  const { dataFn = () => {}, open, onClose, targetPartnerInfo, putPartners, getMenusApprochPartner, approchList } = props

  //권한
  const [member, setMember] = useState(0)
  const [todoSchedule, setTodoSchedule] = useState(0)
  const [studySchedule, setStudySchedule] = useState(0)
  const [serviceCon, setServiceCon] = useState(0)
  const [serviceKoo, setServiceKoo] = useState(0)
  const [message, setMessage] = useState(0)

  const [status, setStatus] = useState(-1)

  const [openModal, setOpenModal] = useState(false)
  // 알림
  const [noti, setNoti] = useState(true)
  //메모
  const [memo, setMemo] = useState("")
  const closePopup = e => {
    onClose()
  }
  const putAdminsPermissionFn = async () => {
    console.log(member, todoSchedule, studySchedule, serviceCon, serviceKoo, message)
    if (status == -1) {
      popupFn("승인 거부여부를 선택해주세요")
      return false
    }
    let temp = []
    //회원관리
    if (status == 0) {
      if (member > 0) {
        for (let i of approchList[0].menu2) {
          if (member == 1) {
            if (i.menu2Name == "회원 조회") {
              temp = [...temp, i.menu2Seq]
            }
          } else if (member == 3) {
            if (i.menu2Name == "회원 조회") {
              temp = [...temp, i.menu2Seq]
            }
            if (i.menu2Name == "회원 등록/변경") {
              temp = [...temp, i.menu2Seq]
            }
          }
        }
      }
      //스터디스케줄 todo
      if (todoSchedule > 0) {
        for (let i of approchList[1].menu2) {
          // console.log("i.menuName", i.menuName)
          if (i.menu2Name == "스케줄 신청목록 조회") {
            temp = [...temp, i.menu2Seq]
          }
          if (i.menu2Name == "스케줄 신청목록 승인/반려") {
            temp = [...temp, i.menu2Seq]
          }
        }
      }
      //스터디스케줄
      if (studySchedule > 0) {
        for (let i of approchList[1].menu2) {
          if (studySchedule == 1) {
            if (i.menu2Name == "스터디스케줄 조회" || i.menu2Name == "스터디스케줄 조회 ") {
              temp = [...temp, i.menu2Seq]
            }
          } else if (studySchedule == 3) {
            if (i.menu2Name == "스터디스케줄 조회" || i.menu2Name == "스터디스케줄 조회 ") {
              temp = [...temp, i.menu2Seq]
            } else if (i.menu2Name == "스터디스케줄 등록/변경") {
              temp = [...temp, i.menu2Seq]
            }
          }
        }
      }
      //서비스 상품 관리
      if (serviceCon > 0 || serviceKoo > 0) {
        for (let i of approchList[2].menu2) {
          if (serviceCon >= 1 || serviceKoo >= 1) {
            if (i.menu2Name == "서비스상품 조회") {
              temp = [...temp, i.menu2Seq]
            }
          }
          if (serviceCon >= 3 && i.menu2Name == "컨텐츠 등록/변경") {
            temp = [...temp, i.menu2Seq]
          }
          if (serviceKoo >= 3 && i.menu2Name == "꾸미기아이템 등록/변경") {
            temp = [...temp, i.menu2Seq]
          }
        }
      }
      if (message > 0) {
        for (let i of approchList[3].menu2) {
          if (
            i.menu2Name == "프로필 조회" ||
            i.menu2Name == "프로필 등록/변경" ||
            i.menu2Name == "포인트내역 조회" ||
            i.menu2Name == "결제내역 조회" ||
            i.menu2Name == "메시지 조회" ||
            i.menu2Name == "메시지 등록/변경"
          ) {
            temp = [...temp, i.menu2Seq]
          }
        }
      } else {
        for (let i of approchList[3].menu2) {
          if (
            i.menu2Name == "프로필 조회" ||
            i.menu2Name == "프로필 등록/변경" ||
            i.menu2Name == "포인트내역 조회" ||
            i.menu2Name == "결제내역 조회"
          ) {
            temp = [...temp, i.menu2Seq]
          }
        }
      }
    }
    console.log(temp)

    let data = {
      partnerSeq: targetPartnerInfo.partnerSeq,
      processType: status == 0 ? "APPROVAL" : "REJECT",
      processedMenus: temp,
      memo: memo,
    }

    console.log(data)
    let result = await putPartners(data)
    if (result) {
      closePopup()
      dataFn()
    }
  }

  useEffect(() => {
    if (dataCheckFn(targetPartnerInfo)) {
      setMemo(targetPartnerInfo.memo)
      setStatus(targetPartnerInfo.status == "REJECT" ? 1 : targetPartnerInfo.status == "APPROVAL" ? 0 : -1)
      for (let i = 0; i < targetPartnerInfo.menuRequests.length; i++) {
        let data = targetPartnerInfo.menuRequests[i]
        if (data.menuName == "회원 조회" && data.menuRequestStatus != "REJECT") setMember(1)
        if (data.menuName == "회원 등록/변경" && data.menuRequestStatus != "REJECT") setMember(3)
        if (data.menuName == "스케줄 신청목록 조회" && data.menuRequestStatus != "REJECT") setTodoSchedule(1)
        if (data.menuName == "스케줄 신청목록 승인/반려" && data.menuRequestStatus != "REJECT") setTodoSchedule(3)
        if (data.menuName == "스터디스케줄 조회" && data.menuRequestStatus != "REJECT") setStudySchedule(1)
        if (data.menuName == "스터디스케줄 등록/변경" && data.menuRequestStatus != "REJECT") setStudySchedule(3)
        if (data.menuName == "서비스상품 조회" && data.menuRequestStatus != "REJECT") {
          setServiceCon(1)
          setServiceKoo(1)
        }
        if (data.menuName == "컨텐츠 등록/변경" && data.menuRequestStatus != "REJECT") setServiceCon(3)
        if (data.menuName == "꾸미기아이템 등록/변경" && data.menuRequestStatus != "REJECT") setServiceKoo(3)
        if (data.menuName == "메시지 조회" && data.menuRequestStatus != "REJECT") setMessage(1)
        if (data.menuName == "메시지 등록/변경" && data.menuRequestStatus != "REJECT") setMessage(3)
      }
    }
  }, [targetPartnerInfo])
  useEffect(() => {
    if (!open) {
      setMemo("")
      setMember(0)
      setTodoSchedule(0)
      setStudySchedule(0)
      setServiceCon(0)
      setServiceKoo(0)
      setMessage(0)
      setStatus(-1)
    }
  }, [open])
  console.log(member, todoSchedule, studySchedule, serviceCon, serviceKoo, message)
  return (
    <LayerPopupLayout open={open} onClose={() => closePopup()} isCloseBtn transparency zIndex={101}>
      {/* <ModalPopup open={openModal} onClose={()=>setOpenModal(false)} contents="저장되었습니다." /> */}
      <div className="permission-info-popup-container layer-popup-container">
        <p className="popup-title mb-3">제휴사 관리자 메뉴 접근 권한 설정</p>

        <div style={{ height: "80%", overflowY: "auto" }} className="partner-profile-container ">
          <div className="d-flex">
            <>
              <div style={{ width: "360px" }} className="partner-profile-basic-info me-3">
                <div className="partner-profile-basic-info-box-row div-1">
                  <div className="partner-profile-basic-info-title-p ">브랜드명</div>
                  <div className="partner-profile-basic-info-div">
                    <div className="partner-profile-basic-info-div-2">
                      <div className="input-box"> {targetPartnerInfo.brandName}</div>
                    </div>
                  </div>
                </div>
                <div className="partner-profile-basic-info-box-row div-1">
                  <div className="partner-profile-basic-info-title-p ">분류</div>
                  <div className="partner-profile-basic-info-div">
                    <div className="partner-profile-basic-info-div-2">{TYPE[targetPartnerInfo.type]}</div>
                  </div>
                </div>

                <div className="partner-profile-basic-info-box-row div-1">
                  <div className="partner-profile-basic-info-title-p ">사업자번호</div>
                  <div className="partner-profile-basic-info-div">
                    <div className="partner-profile-basic-info-div-2">
                      <div className="input-box">{targetPartnerInfo.businessNumber}</div>
                    </div>
                  </div>
                </div>
                <div className="partner-profile-basic-info-box-row div-1">
                  <div className="partner-profile-basic-info-title-p ">담당자</div>
                  <div className="partner-profile-basic-info-div">
                    <div className="partner-profile-basic-info-div-2">
                      <div className="input-box">{targetPartnerInfo.ownerName}</div>
                    </div>
                  </div>
                </div>

                <div className="partner-profile-basic-info-box-row div-1">
                  <div className="partner-profile-basic-info-title-p ">연락처</div>
                  <div className="partner-profile-basic-info-div">
                    <div className="partner-profile-basic-info-div-2">
                      <div className="input-box">{targetPartnerInfo.phone}</div>
                    </div>
                  </div>
                </div>
                <div className="partner-profile-basic-info-box-row div-1">
                  <div className="partner-profile-basic-info-title-p ">이메일</div>
                  <div className="partner-profile-basic-info-div">
                    <div className="partner-profile-basic-info-div-2">
                      <div className="input-box">{targetPartnerInfo.email}</div>
                    </div>
                  </div>
                </div>
                <div className="partner-profile-basic-info-box-row div-1">
                  <div className="partner-profile-basic-info-title-p ">권한 요청일시</div>
                  <div className="partner-profile-basic-info-div">
                    <div className="partner-profile-basic-info-div-2">
                      <div className="input-box">
                        {moment(targetPartnerInfo.applyDatetime).format("YYYY-MM-DD HH:MM") == "Invalid date"
                          ? ""
                          : moment(targetPartnerInfo.applyDatetime).format("YYYY-MM-DD HH:MM")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="partner-profile-basic-info-box-row div-1">
                  <div className="partner-profile-basic-info-title-p ">요청자</div>
                  <div className="partner-profile-basic-info-div">
                    <div className="partner-profile-basic-info-div-2">
                      <div className="input-box">{targetPartnerInfo.managerName || targetPartnerInfo.ownerName}</div>
                    </div>
                  </div>
                </div>
                <div className="partner-profile-basic-info-box-row div-1">
                  <div className="partner-profile-basic-info-title-p ">승인자</div>
                  <div className="partner-profile-basic-info-div">
                    <div className="partner-profile-basic-info-div-2">
                      <div className="input-box">{targetPartnerInfo.approvalAdminName}</div>
                    </div>
                  </div>
                </div>
                <div className="partner-profile-basic-info-box-row div-1">
                  <div className="partner-profile-basic-info-title-p ">승인처리</div>
                  <div className="partner-profile-basic-info-div">
                    <div className="partner-profile-basic-info-div-2">
                      <div className="input-box d-flex">
                        <img onClick={() => setStatus(0)} className="ms-3 me-2" src={status == 0 ? radio_on : radio_off} />
                        <p style={{ whiteSpace: "nowrap" }}>승인 </p>
                        <img onClick={() => setStatus(1)} className="ms-3 me-2" src={status == 1 ? radio_on : radio_off} />
                        <p style={{ whiteSpace: "nowrap" }}>반려</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="partner-profile-basic-info-box-row div-2">
                  <div className="partner-profile-basic-info-title-p ">메모</div>
                  <div className="partner-profile-basic-info-div">
                    <div className="info-div-textarea">
                      <textarea value={memo} onChange={e => setMemo(e.target.value)}></textarea>
                      <button style={{ width: "85px" }} className="btn fgb-btn-gray fgb-btn-s margin-btn ms-2 mt-5">
                        초기화
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
            <>
              <div className="partner-profile-authority">
                <div className="d-flex justify-content-between mb-4 mb-3">
                  <p className="partner-profile-authority-title ms-3">관리자 메뉴 설정</p>
                  <img
                    className="me-3"
                    onClick={() => {
                      if (member == 3 && todoSchedule == 3 && studySchedule == 3 && serviceCon == 3 && serviceKoo == 3 && message == 3) {
                        setMember(0)
                        setTodoSchedule(0)
                        setStudySchedule(0)
                        setServiceCon(0)
                        setServiceKoo(0)
                        setMessage(0)
                      } else {
                        setMember(3)
                        setTodoSchedule(3)
                        setStudySchedule(3)
                        setServiceCon(3)
                        setServiceKoo(3)
                        setMessage(3)
                      }
                    }}
                    src={
                      member == 3 && todoSchedule == 3 && studySchedule == 3 && serviceCon == 3 && serviceKoo == 3 && message == 3
                        ? checked_on
                        : checked_off
                    }
                  />
                </div>

                <div className="partner-profile-authority-item">
                  <div className="partner-profile-authority-item-1"> {"회원관리"}</div>
                  <div className="partner-profile-authority-item-2">
                    <p>{"회원 조회"}</p>
                    <img
                      onClick={() => {
                        if (member >= 1) {
                          setMember(0)
                        } else {
                          setMember(1)
                        }
                      }}
                      src={member >= 1 ? checked_on : checked_off}
                    />
                  </div>
                  <div className="partner-profile-authority-item-2">
                    <p>{"회원 등록"}</p>
                    <img
                      onClick={() => {
                        if (member >= 3) {
                          setMember(0)
                        } else {
                          setMember(3)
                        }
                      }}
                      src={member >= 3 ? checked_on : checked_off}
                    />
                  </div>
                </div>
                <div className="partner-profile-authority-item">
                  <div className="partner-profile-authority-item-1"> {"스터디 스케줄 관리"}</div>
                  <div className="partner-profile-authority-item-2">
                    <p>{"스케줄 신청목록 조회"}</p>
                    <img
                      onClick={() => {
                        if (todoSchedule >= 3) {
                          setTodoSchedule(0)
                        } else {
                          setTodoSchedule(3)
                        }
                      }}
                      src={todoSchedule >= 3 ? checked_on : checked_off}
                    />
                  </div>
                  <div className="partner-profile-authority-item-2">
                    <p>{"스터디 스케줄 조회"}</p>
                    <img
                      onClick={() => {
                        if (studySchedule >= 1) {
                          setStudySchedule(0)
                        } else {
                          setStudySchedule(1)
                        }
                      }}
                      src={studySchedule >= 1 ? checked_on : checked_off}
                    />
                  </div>
                  <div className="partner-profile-authority-item-2">
                    <p>{"스터디 스케줄 등록"}</p>
                    <img
                      onClick={() => {
                        if (studySchedule >= 3) {
                          setStudySchedule(0)
                        } else {
                          setStudySchedule(3)
                        }
                      }}
                      src={studySchedule >= 3 ? checked_on : checked_off}
                    />
                  </div>
                </div>
                <div className="partner-profile-authority-item">
                  <div className="partner-profile-authority-item-1"> {"서비스 상품관리"}</div>
                  <div className="partner-profile-authority-item-2">
                    <p>{"서비스상품 조회"}</p>
                    <img
                      onClick={() => {
                        if (serviceCon >= 1 || serviceKoo >= 1) {
                          setServiceCon(0)
                          setServiceKoo(0)
                        } else {
                          setServiceCon(1)
                          setServiceKoo(1)
                        }
                      }}
                      src={serviceCon >= 1 || serviceKoo >= 1 ? checked_on : checked_off}
                    />
                  </div>
                  <div className="partner-profile-authority-item-2">
                    <p>{"컨텐츠 등록"}</p>
                    <img
                      onClick={() => {
                        if (serviceCon >= 3) {
                          setServiceCon(0)
                        } else {
                          setServiceCon(3)
                        }
                      }}
                      src={serviceCon >= 3 ? checked_on : checked_off}
                    />
                  </div>
                  <div className="partner-profile-authority-item-2">
                    <p>{"꾸미기 등록"}</p>
                    <img
                      onClick={() => {
                        if (serviceKoo >= 3) {
                          setServiceKoo(0)
                        } else {
                          setServiceKoo(3)
                        }
                      }}
                      src={serviceKoo >= 3 ? checked_on : checked_off}
                    />
                  </div>
                </div>
                <div className="partner-profile-authority-item">
                  <div className="partner-profile-authority-item-1"> {"내 계정 관리"}</div>
                  <div className="partner-profile-authority-item-2">
                    <p>{"메시지 관리"}</p>
                    <img
                      onClick={() => {
                        if (message >= 3) {
                          setMessage(0)
                        } else {
                          setMessage(3)
                        }
                      }}
                      src={message >= 3 ? checked_on : checked_off}
                    />
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
        <div className="permission-info-popup-btn-view">
          <button onClick={() => closePopup()} className="btn btn-primary fgb-btn-gray fgb-btn-l ">
            취소
          </button>
          <button onClick={putAdminsPermissionFn} className="btn btn-primary font-white fgb-btn-l ">
            저장
          </button>
        </div>
      </div>
    </LayerPopupLayout>
  )
}

export default SignContainer(OperationContainer(AffiliatesInfoPopup))
