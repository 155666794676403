/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect, useRef } from "react"

import "swiper/scss"

import SEO from "../../components/seo"

import Layout from "../../components/Layout"
import ModalPopup from "../../popups/ModalPopup"
import TopInfo from "../../components/common/TopInfo"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"

import MyAccountEmailChangePopup from "../../popups/MyAccountEmailChangePopup"
import MyAccountPwChangePopup from "../../popups/MyAccountPwChangePopup"

import SignContainer from "../../containers/SignContainer"

import Validator from "validator"
import { debounce } from "lodash"

import PrivateRoute from "../../components/privateRoute"
import { navigate } from "gatsby-link"
import { popupFn } from "../../utils/util"

const MyAccount = props => {
  const { postUploadFile, postAdminsProfileAuth, editAdminsProfile, getAdminsProfile, setTempInfoEmpty, tempInfo, userInfo } = props
  //1 조회, 2등록변경 4승인반려
  //권한 state
  const [member, setMember] = useState(0)
  const [studySchedule, setStudySchedule] = useState(0)
  const [service, setService] = useState(0)
  const [exhibition, setExhibition] = useState(0)
  const [operation, setOperation] = useState(0)
  const [master, setMaster] = useState(0)
  // 알림
  const [noti, setNoti] = useState(true)
  //프로필 정보...
  const [name, setName] = useState("")
  const [nickname, setNickname] = useState("")
  const [phone, setPhone] = useState("")
  const [belong, setBelong] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  //프로필 이미지
  const [imageInfo, setImageInfo] = useState({ base64: null, file: null, imageUrl: null, name: null })
  //open popup
  const [openModal, setOpenModal] = useState(false)
  const [openModalAuth, setOpenModalAuth] = useState(false)
  const [openPw, setOpenPw] = useState(false)
  const [openEmail, setOpenEmail] = useState(false)
  const gallery_image_input = useRef()

  //base64
  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  //권한 수정 요청
  const postAdminsProfileAuthFn = async () => {
    let temp = [...userInfo.authorities]
    for (let i of temp) {
      if (i.menu1Name == "회원관리") {
        i.authorityNumber = member
      } else if (i.menu1Name == "스터디스케줄관리") {
        i.authorityNumber = studySchedule
      } else if (i.menu1Name == "서비스상품관리") {
        i.authorityNumber = service
      } else if (i.menu1Name == "전시관리") {
        i.authorityNumber = exhibition
      } else if (i.menu1Name == "운영관리") {
        i.authorityNumber = operation
      } else if (i.menu1Name == "마스터") {
        i.authorityNumber = master
      }
    }

    console.log(temp)
    let result = await postAdminsProfileAuth({ authorities: temp })
    if (result) {
      setOpenModalAuth(true)
    }
  }
  //프로필 수정
  const editPorfileFn = async () => {
    console.log("name", name)
    console.log("nickname", nickname)
    console.log("belong", belong)
    console.log("email", email)
    console.log("phone", phone)
    if (
      Validator.isEmpty(name, { ignore_whitespace: true }) ||
      Validator.isEmpty(nickname, { ignore_whitespace: true }) ||
      Validator.isEmpty(belong, { ignore_whitespace: true }) ||
      !Validator.isEmail(email, { ignore_whitespace: true }) ||
      Validator.isEmpty(phone, { ignore_whitespace: true })
      // !Validator.isMobilePhone(phone, "ko-KR")||
    ) {
      popupFn("입력데이터가 잘못되었습니다.")
      return false
    }
    //프로필
    let data = null
    if (imageInfo.imageUrl == null && imageInfo.file != null) {
      const fomrData = new FormData()
      await fomrData.append("file", imageInfo.file)
      data = await postUploadFile(fomrData)
    } else {
    }
    let temp = {
      name: name,
      nickname: nickname,
      affiliation: belong,
      phone: phone,
      profileImage: data ? data : imageInfo.imageUrl,
      email: email,
      password: password,
      isAgreeAlarm: noti ? "Y" : "N",
    }
    console.log(temp)
    let result = await editAdminsProfile(temp)
    if (result) {
      popupFn("등록되었습니다.")

      getData()
      navigate("/AdminMain")
    }
  }
  const getData = async () => {
    await getAdminsProfile()
  }
  useEffect(() => {
    getData()
    if (Object.keys(tempInfo).length > 0) {
      if (tempInfo.isFirst == "Y") {
        setPassword(tempInfo.temporaryPassword)
        setOpenModal(true)
      }
    }
    return () => {
      //나갈때 임시데이터 날림
      // setTempInfoEmpty()
    }
  }, [])
  useEffect(() => {
    if (Object.keys(userInfo).length > 0) {
      setName(userInfo.profile.name || "")
      setNickname(userInfo.profile.nickname || "")
      setPhone(userInfo.profile.phone || "")
      setBelong(userInfo.profile.affiliation || "")
      setEmail(userInfo.profile.email || "")
      setImageInfo({ ...imageInfo, imageUrl: userInfo.profile.profileImage })
      setNoti(userInfo.profile.isAgreeAlarm == "Y" ? true : false)
      for (let i of userInfo.authorities) {
        if (i.menu1Name == "회원관리") {
          setMember(i.authorityNumber)
        } else if (i.menu1Name == "스터디스케줄관리") {
          setStudySchedule(i.authorityNumber)
        } else if (i.menu1Name == "서비스상품관리") {
          setService(i.authorityNumber)
        } else if (i.menu1Name == "전시관리") {
          setExhibition(i.authorityNumber)
        } else if (i.menu1Name == "운영관리") {
          setOperation(i.authorityNumber)
        } else if (i.menu1Name == "마스터") {
          setMaster(i.authorityNumber)
        }
      }
    }
  }, [userInfo])
  return (
    // <PrivateRoute>
    <Layout>
      <SEO title="임시 내 계정 관리" />
      <TopInfo title={["내 계정 정보", "내 계정 관리", "내 계정 정보"]} />
      <ModalPopup
        open={openModal}
        onClose={() => setOpenModal(false)}
        contents={"비밀번호를 변경해주세요."}
        onClickBtn={() => setOpenModal(false)}
        btn={["확인"]}
      />
      <ModalPopup
        open={openModalAuth}
        onClose={() => setOpenModalAuth(false)}
        contents={"권한변경 신청 완료"}
        onClickBtn={() => setOpenModalAuth(false)}
        btn={["확인"]}
      />
      <MyAccountPwChangePopup open={openPw} onClose={setOpenPw} setPassword={setPassword} />
      <MyAccountEmailChangePopup setEmail={setEmail} open={openEmail} onClose={setOpenEmail} />
      <div className="admin-account-info-container">
        <div className="admin-account-info-box">
          <div className="admin-account-info-box-row div-3">
            <div className="admin-account-info-title-p ">기본 정보</div>
            <div className="admin-account-info-div">
              <div className="admin-account-info-div-2">
                <p>이름</p>
                <div className="input-box">
                  <input value={name || ""} onChange={e => setName(e.target.value)} type="text" className="fgb-input" />
                </div>
                <p>닉네임</p>
                <div className="input-box">
                  <input value={nickname || ""} onChange={e => setNickname(e.target.value)} type="text" className="fgb-input" />
                </div>
              </div>
              <div className="admin-account-info-div-2">
                <p>소속</p>
                <div className="input-box">
                  <input value={belong || ""} onChange={e => setBelong(e.target.value)} type="text" className="fgb-input" />
                </div>
                <p>전화번호</p>
                <div className="input-box">
                  <input placeholder="- 제외 입력" value={phone || ""} onChange={e => setPhone(e.target.value)} type="number" className="fgb-input" />
                </div>
              </div>
              <div className="admin-account-info-div-2">
                <p>사진</p>
                <div className="input-box">
                  <input readOnly value={imageInfo.name || imageInfo.imageUrl || ""} disabled type="text" className="fgb-input" />
                </div>
                <input
                  id={"gallery_image_input_temp"}
                  style={{ display: "none" }}
                  ref={gallery_image_input}
                  type={"file"}
                  onChange={async e => {
                    const file = e.target.files[0]
                    if (file !== undefined) {
                      const base64Image = await toBase64(file)

                      let tempList = {}
                      tempList.base64 = base64Image
                      tempList.file = file
                      tempList.name = file.name
                      tempList.imageUrl = null
                      await setImageInfo(tempList)
                      gallery_image_input.current.value = ""
                    }
                  }}
                  accept=".gif, .jpg, .png"
                  // accept={"image/*"}
                />
                <button
                  onClick={() => {
                    setImageInfo({ base64: null, file: null, imageUrl: null, name: null })
                  }}
                  className="btn fgb-btn-gray fgb-btn-s margin-btn"
                >
                  삭제
                </button>
                <button
                  onClick={() => document.getElementById("gallery_image_input_temp").click()}
                  className="btn fgb-btn-m fgb-btn-purple margin-btn"
                >
                  이미지 업로드
                </button>
              </div>
            </div>
          </div>
          <div className="admin-account-info-box-row div-2">
            <div className="admin-account-info-title-p ">로그인 정보</div>
            <div className="admin-account-info-div">
              <div className="admin-account-info-div-2">
                <p>이메일</p>
                <div className="input-box">
                  <input readOnly value={email || ""} disabled type="text" className="fgb-input" />
                </div>
                <button onClick={() => setOpenEmail(true)} className="btn btn-primary font-white fgb-btn-m margin-btn">
                  이메일 변경
                </button>
              </div>
              <div className="admin-account-info-div-2">
                <p>비밀번호</p>
                <div className="input-box">
                  <input value={password || ""} disabled readOnly type="password" className="fgb-input" />
                </div>
                <button onClick={() => setOpenPw(true)} className="btn btn-primary font-white fgb-btn-m margin-btn">
                  비밀번호 변경
                </button>
              </div>
            </div>
          </div>
          <div className="admin-account-info-box-row">
            <div className="admin-account-info-title-p">접근권한</div>
            <div className="admin-account-info-div">
              <div className="authority-table-top-div">
                <div style={{ width: "190px", margin: "10px 5px" }}>
                  <select
                    disabled
                    readOnly
                    className="form-select"
                    onChange={e => {
                      console.log(e.target.value)
                    }}
                  >
                    <option className="" value="일반관리자">
                      일반관리자
                    </option>
                    <option className="" value="일반관리자">
                      최고관리자
                    </option>
                  </select>
                </div>
                <button onClick={() => postAdminsProfileAuthFn()} className="btn btn-primary font-white fgb-btn-m margin-btn">
                  권한 변경 요청
                </button>
              </div>
              <div className="fgb-table-div">
                <table className={`fgb-table mb-3`}>
                  <thead>
                    <tr className={`fgb-table-title-tr`}>
                      {["", "1DEPTH 메뉴", "상태"].map((i, idx) => (
                        <td key={idx}>{i}</td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={`fgb-table-tr`}>
                      <td>{1}</td>
                      <td>회원관리</td>
                      <td className="authority-table-tr-edit">
                        <img
                          onClick={() => {
                            if (member >= 3) {
                              setMember(0)
                            } else {
                              setMember(3)
                            }
                          }}
                          src={member >= 3 ? checked_on : checked_off}
                        />
                        전체
                        <img
                          onClick={() => {
                            if (member >= 1) {
                              setMember(0)
                            } else {
                              setMember(1)
                            }
                          }}
                          src={member >= 1 ? checked_on : checked_off}
                        />
                        조회
                        <img
                          onClick={() => {
                            if (member >= 3) {
                              setMember(0)
                            } else {
                              setMember(3)
                            }
                          }}
                          src={member >= 3 ? checked_on : checked_off}
                        />
                        등록/변경
                      </td>
                    </tr>
                    <tr className={`fgb-table-tr`}>
                      <td>{2}</td>
                      <td>스터디 스케줄 관리</td>
                      <td className="authority-table-tr-edit">
                        <img
                          onClick={() => {
                            if (studySchedule >= 3) {
                              setStudySchedule(0)
                            } else {
                              setStudySchedule(3)
                            }
                          }}
                          src={studySchedule >= 3 ? checked_on : checked_off}
                        />
                        전체
                        <img
                          onClick={() => {
                            if (studySchedule >= 1) {
                              setStudySchedule(0)
                            } else {
                              setStudySchedule(1)
                            }
                          }}
                          src={studySchedule >= 1 ? checked_on : checked_off}
                        />
                        조회
                        <img
                          onClick={() => {
                            if (studySchedule >= 3) {
                              setStudySchedule(0)
                            } else {
                              setStudySchedule(3)
                            }
                          }}
                          src={studySchedule >= 3 ? checked_on : checked_off}
                        />
                        등록/변경
                      </td>
                    </tr>
                    <tr className={`fgb-table-tr`}>
                      <td>{3}</td>
                      <td>서비스 상품관리</td>
                      <td className="authority-table-tr-edit">
                        <img
                          onClick={() => {
                            if (service >= 3) {
                              setService(0)
                            } else {
                              setService(3)
                            }
                          }}
                          src={service >= 3 ? checked_on : checked_off}
                        />
                        전체
                        <img
                          onClick={() => {
                            if (service >= 1) {
                              setService(0)
                            } else {
                              setService(1)
                            }
                          }}
                          src={service >= 1 ? checked_on : checked_off}
                        />
                        조회
                        <img
                          onClick={() => {
                            if (service >= 3) {
                              setService(0)
                            } else {
                              setService(3)
                            }
                          }}
                          src={service >= 3 ? checked_on : checked_off}
                        />
                        등록/변경
                      </td>
                    </tr>

                    <tr className={`fgb-table-tr`}>
                      <td>{4}</td>
                      <td>전시관리</td>
                      <td className="authority-table-tr-edit">
                        <img
                          onClick={() => {
                            if (exhibition >= 3) {
                              setExhibition(0)
                            } else {
                              setExhibition(3)
                            }
                          }}
                          src={exhibition >= 3 ? checked_on : checked_off}
                        />
                        전체
                        <img
                          onClick={() => {
                            if (exhibition >= 1) {
                              setExhibition(0)
                            } else {
                              setExhibition(1)
                            }
                          }}
                          src={exhibition >= 1 ? checked_on : checked_off}
                        />
                        조회
                        <img
                          onClick={() => {
                            if (exhibition >= 3) {
                              setExhibition(0)
                            } else {
                              setExhibition(3)
                            }
                          }}
                          src={exhibition >= 3 ? checked_on : checked_off}
                        />
                        등록/변경
                      </td>
                    </tr>
                    <tr className={`fgb-table-tr`}>
                      <td>{5}</td>
                      <td>운영관리</td>
                      <td className="authority-table-tr-edit">
                        <img
                          onClick={() => {
                            if (operation >= 7) {
                              setOperation(0)
                            } else {
                              setOperation(7)
                            }
                          }}
                          src={operation >= 7 ? checked_on : checked_off}
                        />
                        전체
                        <img
                          onClick={() => {
                            if (operation >= 1) {
                              setOperation(0)
                            } else {
                              setOperation(1)
                            }
                          }}
                          src={operation >= 1 ? checked_on : checked_off}
                        />
                        조회
                        <img
                          onClick={() => {
                            if (operation >= 3) {
                              setOperation(0)
                            } else {
                              setOperation(3)
                            }
                          }}
                          src={operation >= 3 ? checked_on : checked_off}
                        />
                        등록/변경
                        <img
                          onClick={() => {
                            if (operation >= 7) {
                              setOperation(0)
                            } else {
                              setOperation(7)
                            }
                          }}
                          src={operation >= 7 ? checked_on : checked_off}
                        />
                        승인/반려
                      </td>
                    </tr>
                    <tr className={`fgb-table-tr`}>
                      <td>{6}</td>
                      <td>마스터</td>
                      <td className="authority-table-tr-edit">
                        <img
                          onClick={() => {
                            if (master >= 7) {
                              setMaster(0)
                            } else {
                              setMaster(7)
                            }
                          }}
                          src={master >= 7 ? checked_on : checked_off}
                        />
                        전체
                        <img
                          onClick={() => {
                            if (master >= 1) {
                              setMaster(0)
                            } else {
                              setMaster(1)
                            }
                          }}
                          src={master >= 1 ? checked_on : checked_off}
                        />
                        조회
                        <img
                          onClick={() => {
                            if (master >= 3) {
                              setMaster(0)
                            } else {
                              setMaster(3)
                            }
                          }}
                          src={master >= 3 ? checked_on : checked_off}
                        />
                        등록/변경
                        <img
                          onClick={() => {
                            if (master >= 7) {
                              setMaster(0)
                            } else {
                              setMaster(7)
                            }
                          }}
                          src={master >= 7 ? checked_on : checked_off}
                        />
                        승인/반려
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="admin-account-info-box-row div-1">
            <div className="admin-account-info-title-p ">알림 수신</div>
            <div className="admin-account-info-div">
              <div className="admin-account-info-div-2">
                <img onClick={() => setNoti(!noti)} src={noti ? radio_on : radio_off} />
                <p>사용</p>
                <img onClick={() => setNoti(!noti)} src={!noti ? radio_on : radio_off} />
                <p>미사용</p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3">
          <button onClick={() => editPorfileFn()} className="btn btn-primary font-white fgb-btn-l">
            확인
          </button>
        </div>
      </div>
    </Layout>
    // </PrivateRoute>
  )
}

export default SignContainer(MyAccount)
