import { navigate } from "gatsby-link"
import { createAction, handleActions } from "redux-actions"
import ExhibitionApis from "../../apis/ExhibitionApis"

const SET_DISPLAY_CONTENTS = "exhibition/SET_DISPLAY_CONTENTS"
const SET_DISPLAY_CONTENTS_TOTAL = "exhibition/SET_DISPLAY_CONTENTS_TOTAL"
const SET_DISPLAY_CONTENT = "exhibition/SET_DISPLAY_CONTENT"
const SET_DISPLAY_ORDER_NUMBER = "exhibition/SET_DISPLAY_ORDER_NUMBER"
const SET_PROMOTION_ADS = "exhibition/SET_PROMOTION_ADS"
const SET_PROMOTION_ADS_TOTAL = "exhibition/SET_PROMOTION_ADS_TOTAL"
const SET_PROMOTION_AD = "exhibition/SET_PROMOTION_AD"

export const setDisplayContents = createAction(SET_DISPLAY_CONTENTS)
export const setDisplayContentsTotal = createAction(SET_DISPLAY_CONTENTS_TOTAL)
export const setDisplayContent = createAction(SET_DISPLAY_CONTENT)
export const setDisplayOrderNumber = createAction(SET_DISPLAY_ORDER_NUMBER)
export const setPromotionAds = createAction(SET_PROMOTION_ADS)
export const setPromotionAdsTotal = createAction(SET_PROMOTION_ADS_TOTAL)
export const setPromotionAd = createAction(SET_PROMOTION_AD)

const initState = {
  displayContents: [],
  displayContentsTotal: 0,
  displayContent: {},
  displayOrderNumber: [],
  promotionAds: [],
  promotionAdsTotal: 0,
  promotionAd: {},
}

export default handleActions(
  {
    [SET_DISPLAY_CONTENTS]: (state, action) => ({
      ...state,
      displayContents: action.payload,
    }),
    [SET_DISPLAY_CONTENTS_TOTAL]: (state, action) => ({
      ...state,
      displayContentsTotal: action.payload,
    }),
    [SET_DISPLAY_CONTENT]: (state, action) => ({
      ...state,
      displayContent: action.payload,
    }),
    [SET_DISPLAY_ORDER_NUMBER]: (state, action) => ({
      ...state,
      displayOrderNumber: action.payload,
    }),
    [SET_PROMOTION_ADS]: (state, action) => ({
      ...state,
      promotionAds: action.payload,
    }),
    [SET_PROMOTION_ADS_TOTAL]: (state, action) => ({
      ...state,
      promotionAdsTotal: action.payload,
    }),
    [SET_PROMOTION_AD]: (state, action) => ({
      ...state,
      promotionAd: action.payload,
    }),
  },
  initState
)
// 전시 관리

//전시 목록 get
export const getDisplayContentsAction = param => async dispatch => {
  const result = await ExhibitionApis.getDisplayContents(param)
  if (result.code == 0) {
    dispatch(setDisplayContents(result.data.displayContents))
    dispatch(setDisplayContentsTotal(result.data.totalCount))
    return result.data
  } else {
    dispatch(setDisplayContents([]))
    dispatch(setDisplayContentsTotal(0))
    return false
  }
}
//전시 상세 get
export const getDisplayContentAction = param => async dispatch => {
  const result = await ExhibitionApis.getDisplayContent(param)
  if (result.code == 0) {
    dispatch(setDisplayContent(result.data.displayContent))
    return result.data
  } else {
    dispatch(setDisplayContent({}))
    return false
  }
}
//전시 게이트웨이 슬롯 get
export const getDisplayOrderNumbersAction = param => async dispatch => {
  const result = await ExhibitionApis.getDisplayOrderNumbers(param)
  if (result.code == 0) {
    dispatch(setDisplayOrderNumber(result.data.displayOrderNumbers))
    return result.data
  } else {
    dispatch(setDisplayOrderNumber([]))
    return false
  }
}
//전시 아이템 수정
export const putDisplayContentAction = param => async dispatch => {
  const result = await ExhibitionApis.putDisplayContent(param)
  if (result.code == 0) {
    return result.data
  } else {
    return false
  }
}
//전시 아이템 add
export const postDisplayContentAction = param => async dispatch => {
  const result = await ExhibitionApis.postDisplayContent(param)
  if (result.code == 0) {
    return result.data
  } else {
    return false
  }
}
//전시 아이템 del
export const delDisplayContentAction = param => async dispatch => {
  const result = await ExhibitionApis.delDisplayContent(param)
  if (result.code == 0) {
    return result.data
  } else {
    return false
  }
}
//전시 프로모션 등록
export const postPromotionAdsAction = param => async dispatch => {
  const result = await ExhibitionApis.postPromotionAds(param)
  if (result.code == 0) {
    return result.data
  } else {
    return false
  }
}
//전시 프로모션 수정
export const putPromotionAdsAction = param => async dispatch => {
  const result = await ExhibitionApis.putPromotionAds(param)
  if (result.code == 0) {
    return result.data
  } else {
    return false
  }
}
//전시 프로모션 del
export const delPromotionAdsAction = param => async dispatch => {
  const result = await ExhibitionApis.delPromotionAds(param)
  if (result.code == 0) {
    return result.data
  } else {
    return false
  }
}
//전시 프로모션 목록 get
export const getPromotionAdsAction = param => async dispatch => {
  const result = await ExhibitionApis.getPromotionAds(param)
  if (result.code == 0) {
    dispatch(setPromotionAds(result.data.promotionAds))
    dispatch(setPromotionAdsTotal(result.data.totalCount))
    return result.data
  } else {
    dispatch(setPromotionAds([]))
    dispatch(setPromotionAdsTotal(0))
    return false
  }
}
//전시 프로모션 get
export const getPromotionAdAction = param => async dispatch => {
  const result = await ExhibitionApis.getPromotionAd(param)
  if (result.code == 0) {
    dispatch(setPromotionAd(result.data.promotionAd))
    return result.data
  } else {
    dispatch(setPromotionAd({}))
    return false
  }
}
