import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import LayerPopupLayout from "./LayerPopupLayout"
import img_popup_close_black from "../images/img_popup_close_black.svg"

import SignContainer from "../containers/SignContainer"
import ModalPopup from "./ModalPopup"
import { dataCheckFn } from "../utils/util"

const TermsPopup = props => {
  const { open, onClose, idx = null, profileTerms, putProfliesPartnerTerms, getProfilesPartnerTerms } = props
  const [openModal, setOpenModal] = useState(false)
  const [isAgree, setIsAgree] = useState(false)

  const closePopup = () => {
    onClose()
  }
  useEffect(() => {
    if (profileTerms.length > 0 && idx != null) {
      setIsAgree(profileTerms[idx].status == "AGREE" ? true : false)
    }
  }, [profileTerms, open])
  return (
    <LayerPopupLayout open={open} onClose={closePopup} transparency zIndex={101}>
      <ModalPopup
        open={openModal}
        onClose={() => setOpenModal(false)}
        contents={"약관 동의 철회 시, 정상적인 서비스를\n제공받을 수 없습니다."}
        onClickBtn={async () => {
          let result = await putProfliesPartnerTerms({ termSeq: profileTerms[idx].termSeq, isAgree: "N" })
          if (result) {
            await getProfilesPartnerTerms()
            setOpenModal(false)
          }
        }}
        btn={["취소", "확인"]}
      />
      <div className="popup-terms-container">
        {/* desktop close btn */}
        <div className="d-flex justify-content-end">
          <button className="btn" onClick={closePopup}>
            <img src={img_popup_close_black} alt="popup close img" />
          </button>
        </div>
        <div className="popup-terms-div">
          <div className="d-flex justify-content-start align-items-center">
            <p>{idx != null && profileTerms[idx].title}</p>
            <div className="form-check form-switch form-switch-md ms-3">
              <input
                checked={isAgree}
                onChange={async e => {
                  if (idx == null) return false
                  let result = false
                  if (profileTerms[idx].status == "AGREE" && profileTerms[idx].isMust == "Y") {
                    setOpenModal(true)
                  } else if (profileTerms[idx].status == "AGREE") {
                    result = await putProfliesPartnerTerms({ termSeq: profileTerms[idx].termSeq, isAgree: "N" })
                  } else {
                    result = await putProfliesPartnerTerms({ termSeq: profileTerms[idx].termSeq, isAgree: "Y" })
                  }
                  if (result) {
                    await getProfilesPartnerTerms()
                  }
                }}
                className="form-check-input"
                type="checkbox"
              />
            </div>
          </div>
          {/* scroll */}
          <div className="popup-terms-scroll-view mt-2">
            <pre>{idx != null && profileTerms[idx].content}</pre>
          </div>
          {/* button */}
          <div className="d-flex justify-content-center mt-3">
            <button className="btn fgb-btn-gray fgb-btn-m" onClick={closePopup}>
              취소
            </button>
            <button className="btn btn-primary font-white fgb-btn-m ms-2" onClick={closePopup}>
              확인
            </button>
          </div>
        </div>
      </div>
    </LayerPopupLayout>
  )
}

export default SignContainer(TermsPopup)
