import React, { useState, useEffect, useRef } from "react"
import moment from "moment"
import CalendarComponent from "../../components/Calendar"
import Pagination from "../../components/common/pagination"

import CommonContainer from "../../containers/CommonContainer"
import ServiceContainer from "../../containers/ServiceContainer"
import SignContainer from "../../containers/SignContainer"

import CloseButton from "../../images/img_popup_close_black.svg"
import icon_calendar from "../../images/ion_calendar-number-outline.svg"
import download from "../../images/download.svg"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"

const ContentPopup = props => {
  const {
    onClose,
    data,
    getStudentGroupList,
    studentGroupList,
    putContent,
    getContentCategory,
    contentCategory,
    postUploadFile,
    getContentInfo,
    contentInfo,
  } = props

  const [brand, setBrand] = useState("") // 브랜드 드롭박스 기본값: Null
  const [learningGroup, setLearningGroup] = useState("")
  const [category1, setCategory1] = useState("") // 카테고리 첫 번째 드롭박스 기본값: Null
  const [category2, setCategory2] = useState("") // 카테고리 두 번째 드롭박스 기본값: Null
  const [category3, setCategory3] = useState("") // 카테고리 세 번째 드롭박스 기본값: Null
  const [categoryLevel1, setCategoryLevel1] = useState([])
  const [categoryLevel2, setCategoryLevel2] = useState([])
  const [categoryLevel3, setCategoryLevel3] = useState([])
  const [kind, setKind] = useState(0) // 유형 Radio 버튼  0: 정규,  1: 기획
  const [title, setTitle] = useState("") // 타이틀 Input 박스 기본값: Null, 최대 30자
  const [teacherName, setTeacherName] = useState("") // 강사 이름 Input 박스 기본값: Null
  const [pageUrlRadio, setPageUrlRadio] = useState(0) // 페이지 URL Radio 버튼  0: 없음,  1: 있음
  const [pageUrlInput, setPageUrlInput] = useState("") // 페이지 URL Input 박스 기본값: Null
  const [promotionUrlRadio, setPromotionUrlRadio] = useState(0) // 홍보영상 URL Radio 버튼  0: 없음,  1: 있음
  const [promotionUrlInput, setPromotionUrlInput] = useState("") // 홍보영상 URL Input 박스 기본값; Null
  const [affiliatesPrice, setAffiliatesPrice] = useState() // 제휴사 가격 기본값: 0
  const [saleRatio, setSaleRatio] = useState() // 할인율 기본값: 0
  const [letmeupPrice, setLetmeupPrice] = useState() // 렛미업 가격 기본값: 0
  const [registered, setRegistered] = useState("") // 등록자 Input 박스 기본값: Null
  const [display, setDisplay] = useState(0) // 화면 표시 Radio 버튼  0: 전시,  1: 미전시
  const [teacherPhoto, setTeacherPhoto] = useState() // 강사 사진 파일 기본값: Null
  const [file, setFile] = useState()
  const [photoUrl, setPhotoUrl] = useState()
  console.log("category3", category1, category2, category3)
  useEffect(() => {
    async function getLists() {
      await getStudentGroupList()
      await getContentCategory()
      await getContentInfo({ contentSeq: data?.contentSeq })
    }

    async function resetLists() {
      await getContentInfo()
    }

    getLists()

    return () => {
      resetLists()
    }
  }, [])

  useEffect(() => {
    setBrand(contentInfo?.content?.brandName)
    setLearningGroup(contentInfo?.content?.studentGroupSeq)
    setCategory1(contentInfo?.content?.categoryDepth1)
    setCategory2(contentInfo?.content?.categoryDepth2)
    setCategory3(contentInfo?.content?.categoryDepth3)
    setKind(contentInfo?.content?.type === "REGULAR" ? 0 : 1)
    setTitle(contentInfo?.content?.title)
    setTeacherName(contentInfo?.content?.teacherName)
    setPageUrlRadio(contentInfo?.content?.isHomepageUrl === "Y" ? 1 : 0)
    setPageUrlInput(contentInfo?.content?.homepageUrl)
    setPromotionUrlRadio(contentInfo?.content?.isVideoUrl === "Y" ? 1 : 0)
    setPromotionUrlInput(contentInfo?.content?.videoUrl)
    setAffiliatesPrice(contentInfo?.content?.partnerPrice)
    setLetmeupPrice(contentInfo?.content?.letmeupPrice)
    setRegistered(contentInfo?.content?.name)
    setDisplay(contentInfo?.content?.isDisplay === "Y" ? 0 : 1)
    setSaleRatio(contentInfo?.content?.discountRate)
    setPhotoUrl(contentInfo?.content?.teacherPhotoUrl)

    const imgEL = document.querySelector(".img__box")
    imgEL.src = contentInfo?.content?.teacherPhotoUrl
  }, [contentInfo])

  useEffect(() => {
    preview()
  }, [file])

  const handlePhotoDelete = () => {
    const imgEL = document.querySelector(".img__box")
    imgEL.src = ""
    setPhotoUrl()
  }

  useEffect(() => {
    setCategoryLevel1(contentCategory?.contentCategories)
  }, [contentCategory])

  useEffect(() => {
    if (contentCategory) {
      setCategoryLevel2(categoryLevel1.filter(i => i?.depth1Name === category1)[0]?.depth2)
      setCategoryLevel3([])
    }
  }, [category1])

  // console.log("categoryLevel2", contentCategory, categoryLevel2, categoryLevel2)
  useEffect(() => {
    // if (contentCategory && categoryLevel2 && categoryLevel1) {
    if (categoryLevel2) {
      setCategoryLevel3(categoryLevel2.filter(i => i?.depth2Name === category2)[0]?.depth3)
    }
    // }
  }, [categoryLevel2, category2])

  const preview = () => {
    if (!file) return false
    const imgEL = document.querySelector(".img__box")
    const reader = new FileReader()
    reader.onload = () => (imgEL.src = `${reader.result}`)
    reader.readAsDataURL(file[0])
  }

  const handleSave = async () => {
    let fileExist = false
    let uploadSuccess = false
    let file_url = ""

    if (file) {
      fileExist = true
      const formData = new FormData()
      await formData.append("file", file[0])
      const result = await postUploadFile(formData)

      if (result) {
        uploadSuccess = true
        file_url = result
      }
    }

    if (!fileExist || (uploadSuccess && fileExist)) {
      const payload = {
        contentSeq: contentInfo?.content?.contentSeq,
        studentGroupSeq: learningGroup,
        categoryDepth1: category1,
        categoryDepth2: category2,
        categoryDepth3: category3,
        isDisplay: display === 0 ? "Y" : "N",
        type: kind === 0 ? "REGULAR" : "PLAN",
        isHomepageUrl: pageUrlRadio === 1 ? "Y" : "N",
        isVideoUrl: promotionUrlRadio === 1 ? "Y" : "N",
        title: title,
        brandName: brand,
        teacherName: teacherName,
        homepageUrl: pageUrlRadio === 1 ? pageUrlInput : "",
        videoUrl: promotionUrlRadio === 1 ? promotionUrlInput : "",
        teacherPhotoUrl: fileExist && uploadSuccess ? file_url : photoUrl ? contentInfo?.content?.teacherPhotoUrl : "",
        partnerPrice: affiliatesPrice,
        letmeupPrice: letmeupPrice,
        discountRate: saleRatio,
      }

      console.log(payload)

      const result = await putContent(payload)

      if (result) {
        onClose()
      }
    }
  }

  return (
    <>
      <div onClick={onClose} className="schedule-popup-container content-popup-container">
        <div onClick={e => e.stopPropagation()} className="schedule-popup-wrapper" style={{ height: "860px", padding: "0 30px" }}>
          <img src={CloseButton} alt="close-popup-button" className="close-popup-button" onClick={onClose} />
          <div className="schedule-popup-title">컨텐츠 상세 보기</div>

          <div className="contents-reg-container">
            {/* |-- 100px --|--  227px --|-- 100px --|-------------- 553px --------------| */}
            {/* |-- 100px --|--  227px --|-- 100px --|-- 227px --|-- 100px --|-- 226px --| */}
            {/* |-- 100px --|--------------- 554px --------------|-- 100px --|-- 226px --| */}
            <div className="contents-reg-table-wrapper">
              <div style={{ display: "flex", justifyContent: "flex-start" }} className="table-row bottom-border">
                <div style={{ width: "99.8px" }} className="element-l right-border">
                  브랜드
                </div>
                <div className="element-r right-border small-box-l" style={{ width: "159px" }}>
                  <div style={{ width: "130px" }}>
                    <select
                      value={brand}
                      className="form-select"
                      style={{ backgroundColor: "#F2F8FC", border: "none", cursor: "pointer" }}
                      onChange={e => setBrand(e.target.value)}
                    >
                      <option className="" value="">
                        전체
                      </option>
                      {contentCategory?.brandNames &&
                        contentCategory?.brandNames.map((i, idx) => (
                          <option key={idx} value={i?.brandName}>
                            {i?.brandName}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="element-l right-border">학습자 그룹</div>
                <div className="stu-div">
                  <div className="element-r no-border big-box-r ">
                    {studentGroupList?.studentGroups &&
                      studentGroupList?.studentGroups.map((i, idx) => (
                        <div
                          key={idx}
                          style={{ paddingRight: "10px", minWidth: "fit-content" }}
                          className="check-box-wrapper"
                          onClick={() => setLearningGroup(i?.level1?.level1Seq)}
                        >
                          <img src={learningGroup === i?.level1?.level1Seq ? radio_on : radio_off} />
                          <p style={{ fontSize: "10px" }}>{i?.level1?.level1Name}</p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="table-row bottom-border">
                <div className="element-l right-border">카테고리</div>
                <div className="element-r right-border big-box-l">
                  <div style={{ width: "129px", marginRight: "10px" }}>
                    <select
                      className="form-select"
                      value={category1 || ""}
                      style={{ backgroundColor: "#F2F8FC", border: "none", cursor: "pointer" }}
                      onChange={e => setCategory1(e.target.value)}
                    >
                      <option className="" value="">
                        전체
                      </option>
                      {categoryLevel1 &&
                        categoryLevel1.map((i, idx) => (
                          <option value={i?.depth1Name} key={idx}>
                            {i?.depth1Name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div style={{ width: "129px", marginRight: "10px" }}>
                    <select
                      className="form-select"
                      style={{ backgroundColor: "#F2F8FC", border: "none", cursor: "pointer" }}
                      onChange={e => setCategory2(e.target.value)}
                      value={category2 || ""}
                    >
                      <option className="" value="">
                        전체
                      </option>
                      {categoryLevel2 &&
                        categoryLevel2.map((i, idx) => (
                          <option value={i?.depth2Name} key={idx}>
                            {i?.depth2Name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div style={{ width: "129px", marginRight: "10px" }}>
                    <select
                      className="form-select"
                      value={category3 || ""}
                      style={{ backgroundColor: "#F2F8FC", border: "none", cursor: "pointer" }}
                      onChange={e => setCategory3(e.target.value)}
                    >
                      <option className="" value="">
                        전체
                      </option>
                      {categoryLevel3 &&
                        categoryLevel3.map((i, idx) => (
                          <option value={i?.depth3Name} key={idx}>
                            {i?.depth3Name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div onClick={async () => await getContentCategory()} className="btn btn-primary font-white reverse fgb-btn-l inside-btn">
                    카테고리 조회
                  </div>
                </div>
                <div className="element-l right-border">유형</div>
                <div className="element-r no-border small-box-r">
                  <div className="radio-btn-wrapper" onClick={() => setKind(0)}>
                    <img src={kind == 0 ? radio_on : radio_off} />
                    <p>정규</p>
                  </div>
                  <div className="radio-btn-wrapper" onClick={() => setKind(1)}>
                    <img src={kind == 1 ? radio_on : radio_off} />
                    <p>기획</p>
                  </div>
                </div>
              </div>
              <div className="table-row bottom-border">
                <div className="element-l right-border">타이틀</div>
                <div className="element-r right-border big-box-l">
                  <input
                    className="input-box"
                    maxLength="30"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    placeholder="타이틀을 입력해주세요."
                    style={{ width: "356px", marginRight: "10px" }}
                  />
                  <div style={{ margin: "0 20px 0 10px" }}>{title?.length}자 / 30자</div>
                  <div className="btn btn-primary font-white reverse fgb-btn-l inside-btn">초기화</div>
                </div>
                <div className="element-l right-border">강사 이름</div>
                <div className="element-r no-border small-box-r">
                  <input className="input-box" value={teacherName} onChange={e => setTeacherName(e.target.value)} style={{ width: "196px" }} />
                </div>
              </div>
              <div className="table-row-photo-part-wrapper">
                <div className="table-row-photo-part-left">
                  <div className="table-row-short bottom-border right-border">
                    <div className="element-l right-border">페이지 URL</div>
                    <div className="element-r no-border big-box-l">
                      <div className="radio-btn-wrapper" onClick={() => setPageUrlRadio(0)}>
                        <img src={pageUrlRadio == 0 ? radio_on : radio_off} />
                        <p>없음</p>
                      </div>
                      <div className="radio-btn-wrapper" onClick={() => setPageUrlRadio(1)}>
                        <img src={pageUrlRadio == 1 ? radio_on : radio_off} />
                        <p>있음</p>
                      </div>
                      <input
                        className="input-box"
                        placeholder="페이지 URL을 입력해주세요."
                        value={pageUrlInput}
                        onChange={e => setPageUrlInput(e.target.value)}
                        style={{ width: "350px", marginRight: "10px" }}
                      />
                    </div>
                  </div>
                  <div className="table-row-short bottom-border right-border">
                    <div className="element-l right-border">홍보영상 URL</div>
                    <div className="element-r no-border big-box-l">
                      <div className="radio-btn-wrapper" onClick={() => setPromotionUrlRadio(0)}>
                        <img src={promotionUrlRadio == 0 ? radio_on : radio_off} />
                        <p>없음</p>
                      </div>
                      <div className="radio-btn-wrapper" onClick={() => setPromotionUrlRadio(1)}>
                        <img src={promotionUrlRadio == 1 ? radio_on : radio_off} />
                        <p>있음</p>
                      </div>
                      <input
                        className="input-box"
                        placeholder="홍보영상 URL을 입력해주세요."
                        value={promotionUrlInput}
                        onChange={e => setPromotionUrlInput(e.target.value)}
                        style={{ width: "350px", marginRight: "10px" }}
                      />
                    </div>
                  </div>
                  <div className="table-row-short bottom-border right-border">
                    <div className="element-l right-border">제휴사 가격</div>
                    <div className="element-r right-border small-box-l">
                      <input
                        className="input-box"
                        placeholder="0"
                        value={affiliatesPrice}
                        onChange={e => setAffiliatesPrice(e.target.value)}
                        style={{ width: "178px", textAlign: "right", paddingRight: "8px", marginRight: "7px" }}
                      />
                      <p>원</p>
                    </div>
                    <div className="element-l right-border">할인율</div>
                    <div className="element-r no-border small-box-l">
                      <input
                        className="input-box"
                        placeholder="0"
                        value={saleRatio}
                        onChange={e => setSaleRatio(e.target.value)}
                        style={{ width: "178px", textAlign: "right", paddingRight: "8px", marginRight: "7px" }}
                      />
                      <p>%</p>
                    </div>
                  </div>
                  <div className="table-row-short bottom-border right-border">
                    <div className="element-l right-border">렛미업 가격</div>
                    <div className="element-r right-border small-box-l">
                      <input
                        className="input-box"
                        placeholder="0"
                        value={letmeupPrice}
                        onChange={e => setLetmeupPrice(e.target.value)}
                        style={{ width: "178px", textAlign: "right", paddingRight: "8px", marginRight: "7px" }}
                      />
                      <p>원</p>
                    </div>
                    <div className="element-l right-border">등록자</div>
                    <div className="element-r no-border small-box-l">
                      <input className="input-box" value={registered} onChange={e => setRegistered(e.target.value)} style={{ width: "196px" }} />
                    </div>
                  </div>
                </div>
                <div className="table-row-photo-part-right bottom-border">
                  <div className="photo-part-l right-border" style={{ height: "239px" }}>
                    강사 사진
                  </div>
                  <div className="photo-part-r">
                    <div className="photo-delete-btn btn btn-primary font-white reverse fgb-btn-m" onClick={handlePhotoDelete}>
                      삭제
                    </div>
                    <input
                      id="file-add"
                      type="file"
                      style={{ display: "none" }}
                      onChange={e => setFile(e.target.files)}
                      accept=".jpg, .png, .gif, .svg, .jpeg"
                    />
                    <label htmlFor="file-add" className="photo-upload-btn btn btn-primary font-white fgb-btn-m">
                      업로드
                    </label>
                    <div className="photo-zone">
                      <img className="img__box" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-row no-border">
                <div className="element-l right-border">화면 표시</div>
                <div className="element-r right-border small-box-l">
                  <div className="radio-btn-wrapper" onClick={() => setDisplay(0)}>
                    <img src={display == 0 ? radio_on : radio_off} />
                    <p>전시</p>
                  </div>
                  <div className="radio-btn-wrapper" onClick={() => setDisplay(1)}>
                    <img src={display == 1 ? radio_on : radio_off} />
                    <p>미전시</p>
                  </div>
                </div>
                <div className="element-l right-border"></div>
                <div className="element-r right-border small-box-l"></div>
                <div className="element-l right-border"></div>
                <div className="element-r no-border small-box-r"></div>
              </div>
            </div>

            <div className="two-buttons-wrapper">
              <div className="btn btn-primary font-white reverse fgb-btn-l cancle-button" onClick={onClose}>
                취소
              </div>
              <div className="btn btn-primary font-white fgb-btn-l save-button" onClick={handleSave}>
                저장
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CommonContainer(SignContainer(ServiceContainer(ContentPopup)))
