import api from "../utils/api"

// admin로그인 회원가입
const postSignIn = async data => {
  const result = await api.post(`/signin`, { token: false, body: data })
  if (result) {
    return result.data
  } else return false
}
//비밀번호 확인
const postAuthPassword = async data => {
  const result = await api.post(`/auth/password`, { body: data, token: true })
  if (result) {
    return result.data
  } else return false
}

//내 계정정보 get
const getAdminsProfile = async data => {
  const result = await api.get(`/profiles/admin`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//내 계정정보 put edit
const putAdminsProfile = async data => {
  const result = await api.put(`/profiles/admin`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//제휴사 내 계정정보 get
const getProfliesPartner = async data => {
  const result = await api.get(`/profiles/partner`, { token: true })
  if (result) {
    return result.data
  } else return false
}

//제휴사 내 계정정보 소개페이지 get
const getProfliesPartnerIntroduction = async data => {
  const result = await api.get(`/profiles/partner/introduction-page`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//제휴사 내 계정정보 소개페이지 todo study get
const getProfliesPartnerIntroductionTodoStudy = async data => {
  const result = await api.get(`/profiles/partner/introduction-page/studies-todos`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//제휴사 내 계정정보 소개페이지 put
const putProfliesPartnerIntroduction = async data => {
  const result = await api.put(`/profiles/partner/introduction-page`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//제휴사 내 계정정보 소개페이지 put
const putProfliesPartnerIntroductionTodoStudy = async data => {
  const result = await api.put(`/profiles/partner/introduction-page/studies-todos/on-off`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}

//제휴사 내 계정정보 put edit
const putProfliesPartner = async data => {
  const result = await api.put(`/profiles/partner`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//권한 변경 요청
const postAdminsProfileAuth = async data => {
  const result = await api.post(`/profiles/admin/authorities`, { token: true, body: data })
  if (result) {
    return result.data
  } else {
    return false
  }
}
//제휴사 프로필정보 이용약관 get
const getProfilesPartnerTerms = async data => {
  const result = await api.get(`/profiles/partner/terms`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//제휴사 내 계정정보 약관 수정
const putProfliesPartnerTerms = async data => {
  const result = await api.put(`/profiles/partner/terms/${data.termSeq}`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//제휴사 프로필정보 메뉴권한 get
const getProfilesPartnerAuthorities = async data => {
  const result = await api.get(`/profiles/partner/authorities`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//제휴사 프로필정보 메뉴권한 put
const putProfliesPartnerAuthorities = async data => {
  const result = await api.put(`/profiles/partner/authorities`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}

//이메일 수정 인증이메일 보내기
const postAuthEmail = async data => {
  const result = await api.post(`/auth/emails`, { token: true, body: data })
  if (result) {
    return result.data
  } else {
    return false
  }
}
//이메일 수정 인증 check
const putAuthEmail = async data => {
  const result = await api.put(`/auth/emails`, { token: true, body: data })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 제휴사회원가입
const postSignUp = async data => {
  const result = await api.post(`/signup`, { token: false, body: data })
  if (result) {
    return result.data
  } else return false
}
// 로그인 상태 체크
const checkSignStatus = async data => {
  const result = await api.post(`/signin/status`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
// 로그아웃
const postSignOut = async data => {
  const result = await api.post(`/signout`, { body: data })
  if (result) {
    return result.data
  } else return false
}
// 약관
const getTerm = async data => {
  const result = await api.get(`/terms`)
  if (result) {
    return result.data
  } else return false
}
//인증번호요청
const postAuthNumber = async data => {
  const result = await api.post(`/auth/number`, { body: data, token: true })
  if (result) {
    return result.data
  } else return false
}
//인증번호인증 확인
const postAuthNumberCheck = async data => {
  const result = await api.put(`/auth/number`, { body: data })
  if (result) {
    return result.data
  } else return false
}
//제휴사 권한 목록
const getMenusApprochPartner = async data => {
  const result = await api.get(`/menus/approch/partner`)
  if (result) {
    return result.data
  } else return false
}
//제휴사 탈퇴
const deleteProfilesPartnerWithdraw = async data => {
  const result = await api.delete(`/profiles/partner/withdraw`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//제휴사 결제 내역 목록
const getProfilePayments = async data => {
  const result = await api.get(`/profiles/payments?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&page=${data.page}&count=${data.count}`, {
    body: data,
    token: true,
  })
  if (result) {
    return result.data
  } else return false
}
//제휴사 결제 내역 목록 xl
const getProfilePaymentsXlDown = async data => {
  const result = await api.get(`/profiles/payments/excel/download?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&page=${0}&count=9999999`, {
    body: data,
    token: true,
    options: { responseType: "blob" },
  })
  if (result) {
    return result.data
  } else return false
}

//파일 업로드 단일
const postUploadFile = async data => {
  const result = await api.post(`/upload/file`, { body: data, token: true })
  if (result) {
    return result.data
  } else {
    return false
  }
}
//제휴사 포인트 내역 목록
const getProfilePoints = async data => {
  const result = await api.get(
    `/profiles/points?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&userType=${data.userType}&studentGroupSeq=${data.studentGroupSeq}&page=${data.page}&count=${data.count}`,
    {
      body: data,
      token: true,
    }
  )
  if (result) {
    return result.data
  } else return false
}
//제휴사 포인트 내역 목록 xl
const getProfilePointXlDown = async data => {
  const result = await api.get(`/profiles/points/excel/download?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&page=${0}&count=9999999`, {
    body: data,
    token: true,
    options: { responseType: "blob" },
  })
  if (result) {
    return result.data
  } else return false
}
//메세지 관리
//메시지 목록
const getMessages = async data => {
  const result = await api.get(
    `/messages?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&type=${data.type}&page=${data.page}&count=${data.count}`,
    {
      body: data,
      token: true,
    }
  )
  if (result) {
    return result.data
  } else return false
}
//메세지 선택삭제
const delMessages = async data => {
  const result = await api.delete(`/messages`, {
    body: data,
    token: true,
  })
  if (result) {
    return result.data
  } else return false
}
//스터디 코드 제목 목록
const getMessagesStudies = async data => {
  const result = await api.get(`/messages/studies`, {
    body: data,
    token: true,
  })
  if (result) {
    return result.data
  } else return false
}
//스터디별 참여자 목록
const getMessagesMembers = async data => {
  const result = await api.get(`/messages/members?studySeqs=${data}`, {
    body: data,
    token: true,
  })
  if (result) {
    return result.data
  } else return false
}
//받는사람 검색
const getMessagesUsers = async data => {
  const result = await api.get(`/messages/users?wordType=${data.wordType}&word=${data.word}`, {
    body: data,
    token: true,
  })
  if (result) {
    return result.data
  } else return false
}
//메세지 보내기
const postMessages = async data => {
  const result = await api.post(`/messages`, {
    body: data,
    token: true,
  })
  if (result) {
    return result.data
  } else return false
}
export default {
  postSignIn,
  postAuthPassword,
  getAdminsProfile,
  putAdminsProfile,
  postAuthEmail,
  putAuthEmail,
  checkSignStatus,
  postAdminsProfileAuth,
  postAuthNumber,
  postAuthNumberCheck,
  getTerm,
  getMenusApprochPartner,
  postSignUp,
  postUploadFile,
  getProfliesPartner,
  putProfliesPartner,
  getProfliesPartnerIntroduction,
  putProfliesPartnerIntroduction,
  getProfliesPartnerIntroductionTodoStudy,
  putProfliesPartnerIntroductionTodoStudy,
  getProfilesPartnerTerms,
  putProfliesPartnerTerms,
  getProfilesPartnerAuthorities,
  putProfliesPartnerAuthorities,
  deleteProfilesPartnerWithdraw,
  getProfilePayments,
  getProfilePaymentsXlDown,
  getProfilePoints,
  getProfilePointXlDown,
  getMessages,
  delMessages,
  getMessagesStudies,
  getMessagesMembers,
  getMessagesUsers,
  postMessages,

  postSignOut,
}
