import React, { useState } from "react"
import LayerPopupLayout from "../LayerPopupLayout"
import Validator from "validator"
import { debounce } from "lodash"
import { dataCheckFn, popupFn } from "../../utils/util"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"
import CloseBtn from "../../images/Close_Button.svg"
import img_popup_close_gray from "../../images/img_popup_close_gray.svg"
import upload from "../../images/upload.svg"
import { useEffect } from "react"
import { check } from "prettier"
import ExhibitionContainer from "../../containers/ExhibitionContainer"
import ModalPreviewPopup from "../../popups/ModalPreviewPopup"

const TYPEX = ["RIGHT", "CENTER", "LEFT"]
const TYPEY = ["TOP", "CENTER", "BOTTOM"]
const AddIntroPopup = props => {
  const { open, onClose, displayContentEmpty, displayContent, getFn = () => {}, putDisplayContent, postUploadFile } = props

  const [fileType, setFileType] = useState(1)
  const [title, setTitle] = useState("")

  const [isPc, setIsPc] = useState(true)
  const [isMo, setIsMo] = useState(true)

  const [isSkip, setIsSkip] = useState(false)
  const [isSkipText, setIsSkipText] = useState("")

  const [imageInfoPc, setImageInfoPc] = useState({ base64: null, file: null, imageUrl: null, name: null })
  const [imageInfoMo, setImageInfoMo] = useState({ base64: null, file: null, imageUrl: null, name: null })
  const [movLink, setMovLink] = useState("")

  const [isUseTextPos, setIsUseTextPos] = useState(false)
  const [posY, setPosY] = useState("TOP")
  const [posX, setPosX] = useState("LEFT")

  const [isDisplay, setIsDisplay] = useState(false)

  const [openPreview, setOpenPreview] = useState({ contents: {}, open: "false" })

  const [summary, setSummary] = useState("")

  //base64
  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  const closePopup = e => {
    onClose()
  }
  const saveFn = async () => {
    let imgPc = null
    let imgMo = null
    if (imageInfoPc.imageUrl == null && imageInfoPc.file == null) {
      popupFn("이미지를 등록해주세요")
      return false
    }
    if (imageInfoMo.imageUrl == null && imageInfoMo.file == null) {
      popupFn("이미지를 등록해주세요")
      return false
    }
    if (Validator.isEmpty(title, { ignore_whitespace: true })) {
      popupFn("제목을 입력해주세요.")

      return false
    }
    if (fileType == 0 && Validator.isEmpty(movLink, { ignore_whitespace: true })) {
      popupFn("영상링크를 입력해주세요.")
      return false
    }
    if (!isPc && !isMo) {
      popupFn("플랫폼을 선택해주세요.")
      return false
    }
    if (imageInfoPc.imageUrl == null && imageInfoPc.file != null) {
      const fomrData = new FormData()
      await fomrData.append("file", imageInfoPc.file)
      imgPc = await postUploadFile(fomrData)
    }
    if (imageInfoMo.imageUrl == null && imageInfoMo.file != null) {
      const fomrData = new FormData()
      await fomrData.append("file", imageInfoMo.file)
      imgMo = await postUploadFile(fomrData)
    }
    let temp = {
      exhibitionContentSeq: displayContent.exhibitionContentSeq,
      type: "INTRO",
      fileType: fileType == 0 ? "VIDEO" : "IMAGE",
      title: title,
      deviceType: isPc && isMo ? "ALL" : isPc ? "PC" : isMo ? "MOBILE" : "",
      isAutoSlide: "",
      autoSlideInterval: "",
      isPossibleSkip: isSkip ? "Y" : "N",
      skipComment: isSkipText,
      pcImageUrl: imgPc ? imgPc : imageInfoPc.imageUrl,
      mobileImageUrl: imgMo ? imgMo : imageInfoMo.imageUrl,
      videoUrl: movLink,
      isUsingPageLink: "",
      pageUrl: "",
      displayOrderType: "",
      displayOrderNumber: "",
      isDisplayText: isUseTextPos ? "Y" : "N",
      textPositionVerticalType: posY,
      textPositionHorizonType: posX,
      text: summary,
      isDisplay: isDisplay ? "Y" : "N",
    }
    console.log(temp)
    let result = await putDisplayContent(temp)
    if (result) {
      popupFn("등록되었습니다.")
      getFn()
      closePopup()
    }
  }
  useEffect(() => {
    if (!open) displayContentEmpty()
  }, [open])
  useEffect(() => {
    if (Object.keys(displayContent).length > 0) {
      let i = displayContent
      if (i.deviceType == "ALL") {
        setIsPc(true)
        setIsMo(true)
      } else if (i.deviceType == "PC") {
        setIsPc(true)
      } else if (i.deviceType == "MOBILE") {
        setIsMo(true)
      }

      setIsDisplay(i.isDisplay == "Y" ? true : false)
      setIsSkip(i.isPossibleSkip == "Y" ? true : false)
      setIsSkipText(i.skipComment)
      setImageInfoPc({ ...imageInfoPc, imageUrl: i.pcImageUrl })
      setImageInfoMo({ ...imageInfoMo, imageUrl: i.mobileImageUrl })
      setTitle(i.title)
      setSummary(i.text)
      setMovLink(i.videoUrl)
      setIsUseTextPos(i.isDisplayText == "Y" ? true : false)
      setPosY(i.textPositionVerticalType)
      setPosX(i.textPositionHorizonType)
      setFileType(i.fileType == "VIDEO" ? 0 : 1)
    }
  }, [displayContent])

  return (
    <LayerPopupLayout open={open} onClose={() => closePopup()} isCloseBtn transparency zIndex={101}>
      <ModalPreviewPopup open={openPreview.open} onClose={() => setOpenPreview({ contents: {}, open: "false" })} contents={openPreview.contents} />
      <div className="learner-type-popup-container layer-popup-container">
        <p className="popup-title mb-3">Intro 이미지 등록/변경</p>
        <div className="admin-account-info-container ">
          <div className="admin-account-info-box">
            <div style={{ border: "none", borderBottom: "1px solid #E1E1E1" }} className="admin-account-info-box-row div-1">
              <div className="admin-account-info-title-p ">유형</div>
              <div className="admin-account-info-div">
                <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                  <img onClick={() => setFileType(0)} className=" me-2" src={fileType == 0 ? radio_on : radio_off} />
                  <p>동영상 </p>
                  <img onClick={() => setFileType(1)} className="ms-4 me-2" src={fileType == 1 ? radio_on : radio_off} />
                  <p>이미지</p>
                </div>
              </div>
            </div>
            <div style={{ border: "none", borderBottom: "1px solid #E1E1E1" }} className="admin-account-info-box-row div-1">
              <div className="admin-account-info-title-p ">Intro 제목</div>
              <div className="admin-account-info-div">
                <div style={{ width: "330px", border: "none" }} className="admin-account-info-div-2">
                  <input value={title || ""} onChange={e => setTitle(e.target.value)} type="text" className="fgb-input" />
                </div>
              </div>
            </div>
            <div style={{ border: "none", borderBottom: "1px solid #E1E1E1" }} className="admin-account-info-box-row div-1">
              <div className="admin-account-info-title-p ">플렛폼</div>
              <div className="admin-account-info-div">
                <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                  <img
                    onClick={() => {
                      if (isPc && isMo) {
                        setIsPc(false)
                        setIsMo(false)
                      } else {
                        setIsPc(true)
                        setIsMo(true)
                      }
                    }}
                    className=" me-2"
                    src={isPc && isMo ? checked_on : checked_off}
                  />
                  <p>전체 </p>
                  <img onClick={() => setIsPc(!isPc)} className="ms-3 me-2" src={isPc ? checked_on : checked_off} />
                  <p>PC</p>
                  <img onClick={() => setIsMo(!isMo)} className="ms-3 me-2" src={isMo ? checked_on : checked_off} />
                  <p className="">Mobile</p>
                </div>
              </div>
            </div>
            <div style={{ border: "none", borderBottom: "1px solid #E1E1E1" }} className="admin-account-info-box-row div-1">
              <div className="admin-account-info-title-p ">SKIP 체크 버튼</div>
              <div className="admin-account-info-div">
                <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                  <img onClick={() => setIsSkip(true)} className="me-2" src={isSkip ? radio_on : radio_off} />
                  <p>사용</p>
                  <img onClick={() => setIsSkip(false)} className="ms-4 me-2" src={!isSkip ? radio_on : radio_off} />
                  <p>사용하지 않음</p>
                  <div style={{ width: "220px", border: "none" }} className="admin-account-info-div-2 ms-4">
                    <input value={isSkipText || ""} onChange={e => setIsSkipText(e.target.value)} type="text" className="fgb-input" />
                  </div>
                  <div onClick={() => setIsSkipText("")} className="btn fgb-btn-gray fgb-btn-s ms-2">
                    삭제
                  </div>
                </div>
              </div>
            </div>

            <div style={{ padding: "20px 15px 0px" }} className="">
              <div className="admin-account-info-box-row div-1">
                <div className="admin-account-info-title-p ">PC BG 이미지</div>
                <div className="admin-account-info-div">
                  <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                    <div style={{ width: "220px", border: "none" }} className="admin-account-info-div-2 ms-2">
                      <input value={imageInfoPc.imageUrl || imageInfoPc.name || ""} readOnly type="text" className="fgb-input" />
                    </div>
                    <input
                      id={"gallery_image_input1"}
                      style={{ display: "none" }}
                      type={"file"}
                      onChange={async e => {
                        const file = e.target.files[0]
                        if (file !== undefined) {
                          const base64Image = await toBase64(file)

                          let tempList = {}
                          tempList.base64 = base64Image
                          tempList.file = file
                          tempList.name = file.name
                          tempList.imageUrl = null
                          console.log("tempList", tempList)
                          await setImageInfoPc(tempList)
                          e.target.value = ""
                        }
                      }}
                      accept=".gif, .jpg, .png"
                      // accept={"image/*"}
                    />
                    <div
                      onClick={() => setImageInfoPc({ base64: null, file: null, imageUrl: null, name: null })}
                      className="btn fgb-btn-gray fgb-btn-s ms-2"
                    >
                      삭제
                    </div>
                    <div onClick={() => setOpenPreview({ open: true, contents: imageInfoPc })} className="btn btn-primary font-white fgb-btn-m ms-2">
                      미리보기
                    </div>
                    <div onClick={() => document.getElementById("gallery_image_input1").click()} className="btn fgb-btn-m fgb-btn-purple ms-2">
                      <img src={upload} />
                      <p>파일 업로드</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="admin-account-info-box-row div-1">
                <div className="admin-account-info-title-p ">MO BG 이미지</div>
                <div className="admin-account-info-div">
                  <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                    <div style={{ width: "220px", border: "none" }} className="admin-account-info-div-2 ms-2">
                      <input value={imageInfoMo.imageUrl || imageInfoMo.name || ""} readOnly type="text" className="fgb-input" />
                    </div>
                    <div
                      onClick={() => setImageInfoMo({ base64: null, file: null, imageUrl: null, name: null })}
                      className="btn fgb-btn-gray fgb-btn-s ms-2"
                    >
                      삭제
                    </div>
                    <div onClick={() => setOpenPreview({ open: true, contents: imageInfoMo })} className="btn btn-primary font-white fgb-btn-m ms-2">
                      미리보기
                    </div>
                    <div onClick={() => document.getElementById("gallery_image_input2").click()} className="btn fgb-btn-m fgb-btn-purple ms-2">
                      <img src={upload} />
                      <p>파일 업로드</p>
                    </div>
                    <input
                      id={"gallery_image_input2"}
                      style={{ display: "none" }}
                      type={"file"}
                      onChange={async e => {
                        const file = e.target.files[0]
                        if (file !== undefined) {
                          const base64Image = await toBase64(file)

                          let tempList = {}
                          tempList.base64 = base64Image
                          tempList.file = file
                          tempList.name = file.name
                          tempList.imageUrl = null
                          console.log("tempList", tempList)
                          await setImageInfoMo(tempList)
                          e.target.value = ""
                        }
                      }}
                      accept=".gif, .jpg, .png"
                      // accept={"image/*"}
                    />
                  </div>
                </div>
              </div>
              <div className="admin-account-info-box-row div-1">
                <div className="admin-account-info-title-p ">영상링크</div>
                <div className="admin-account-info-div">
                  <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                    <div style={{ width: "330px", border: "none" }} className="admin-account-info-div-2 ms-2">
                      <input
                        placeholder="https://"
                        disabled={fileType == 1 ? true : false}
                        value={movLink || ""}
                        onChange={e => setMovLink(e.target.value)}
                        type="text"
                        className="fgb-input"
                      />
                    </div>
                    <div onClick={() => setMovLink("")} className="btn fgb-btn-gray fgb-btn-s ms-2">
                      삭제
                    </div>
                    <div
                      onClick={() => {
                        if (fileType == 0) {
                          window.open(movLink, "_blank")
                        }
                      }}
                      className="btn btn-primary font-white fgb-btn-m ms-2"
                    >
                      링크보기
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ padding: "20px 15px 0px" }} className="">
              <div className="admin-account-info-box-row div-1">
                <div className="admin-account-info-title-p ">텍스트 표시 위치</div>
                <div className="admin-account-info-div">
                  <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                    <img onClick={() => setIsUseTextPos(!isUseTextPos)} className="ms-4 me-2" src={isUseTextPos ? checked_on : checked_off} />
                    <p>사용 </p>
                    <div className="ms-4" style={{ width: "190px" }}>
                      <select
                        className="form-select"
                        value={posY}
                        onChange={e => {
                          setPosY(e.target.value)
                        }}
                      >
                        <option className="" value="TOP">
                          상단
                        </option>
                        <option className="" value="MIDDLE">
                          중단
                        </option>
                        <option className="" value="BOTTOM">
                          하단
                        </option>
                      </select>
                    </div>
                    <img onClick={() => setPosX("LEFT")} className="ms-3 me-2" src={posX == "LEFT" ? radio_on : radio_off} />
                    <p>왼쪽 </p>
                    <img onClick={() => setPosX("CENTER")} className="ms-3 me-2" src={posX == "CENTER" ? radio_on : radio_off} />
                    <p>중앙 </p>
                    <img onClick={() => setPosX("RIGHT")} className="ms-3 me-2" src={posX == "RIGHT" ? radio_on : radio_off} />
                    <p>오른쪽 </p>
                  </div>
                </div>
              </div>
              <div className="admin-account-info-box-row div-2">
                <div className="admin-account-info-title-p ">텍스트</div>
                <div className="admin-account-info-div">
                  <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                    <div style={{ width: "450px" }} className="admin-account-info-div-textarea ms-4">
                      <textarea value={summary || ""} onChange={e => setSummary(e.target.value)}></textarea>
                      <button onClick={() => setSummary("")} className="btn fgb-btn-gray fgb-btn-m mt-5 ms-2">
                        초기화
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ padding: "20px 15px 20px" }} className="">
              <div className="admin-account-info-box-row div-1">
                <div className="admin-account-info-title-p ">전시여부</div>
                <div className="admin-account-info-div">
                  <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                    <img onClick={() => setIsDisplay(true)} className="ms-3 me-2" src={isDisplay ? radio_on : radio_off} />
                    <p>전시 </p>
                    <img onClick={() => setIsDisplay(false)} className="ms-3 me-2" src={!isDisplay ? radio_on : radio_off} />
                    <p>비전시 </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="permission-info-popup-btn-view">
          <button onClick={() => closePopup()} className="btn btn-primary fgb-btn-gray fgb-btn-l ">
            취소
          </button>
          <button onClick={() => saveFn()} className="btn btn-primary font-white fgb-btn-l ">
            저장
          </button>
        </div>
      </div>
    </LayerPopupLayout>
  )
}

export default ExhibitionContainer(AddIntroPopup)
