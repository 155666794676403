import React from "react"
import { Pie } from "react-chartjs-2"
import { useSelector } from "react-redux"
// import MyContainer from "../../containers/"

const PieChart = props => {
  const { dataArr = [9, 0, 0, 0, 1, 0, 1, 14], labels = ["중학생", "고등학생", "대학생", "대입수험생", "취업/자격증", "공무원", "직장인", "기타"] } =
    props

  const cardColor = {
    중학생: "rgba(0,185,197,1)",
    고등학생: "rgba(0,197,144,1)",
    대학생: "rgba(66,207,157,1)",
    대입수험생: "rgba(147,211,117,1)",
    "취업/자격증": "rgba(255,184,0,1)",
    공무원: "rgba(255,123,82,1)",
    직장인: "rgba(156,73,255,1)",
    기타: "rgba(224,100,213,1)",
  }
  const data = {
    labels: labels,
    datasets: [
      {
        // labels: ["중학생", "고등학생", "대학생", "대입수험생", "취업/자격증", "공무원", "직장인", "기타"],
        data: dataArr,
        backgroundColor: [...labels.map(i => cardColor[i])],
        borderColor: "#ffffff",
        borderWidth: 1,
        radius: "100%",
      },
    ],
  }
  const chartOption = {
    plugins: {
      legend: {
        labels: {
          boxWidth: "10px",
        },
      },
    },
  }
  return (
    <>
      <Pie data={data} options={chartOption} />
    </>
  )
}
export default PieChart
