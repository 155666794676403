import React from "react"
import img_popup_close_gray from "../../images/img_popup_close_gray.svg"
import CloseButton from "../../images/img_popup_close_black.svg"
const XBtn = ({ onClose = () => {} }) => {
  return (
    <div className="comp-close-button-view ">
      <button className="btn"> </button>
      <button className="btn" onClick={onClose}>
        <img src={CloseButton} alt="popup close img" />
      </button>
    </div>
  )
}

export default XBtn
