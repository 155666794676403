import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import {
  getRecommendListAction,
  getRecommendationProgramInfoAction,
  postRecommendationProgramAction,
  delRecommendationProgramAction,
  getRecommendationProgramExcelAction
} from "../store/modules/recommend"

const mapStateToProps = state => ({
  recommendList: state.recommend.recommendList,
  recommendInfo: state.recommend.recommendInfo,
})

const mapDispatchToProps = dispatch => ({
  getRecommendList: param => dispatch(getRecommendListAction(param)),
  getRecommendationProgramInfo: param => dispatch(getRecommendationProgramInfoAction(param)),
  postRecommendationProgram: param => dispatch(postRecommendationProgramAction(param)),
  delRecommendationProgram: param => dispatch(delRecommendationProgramAction(param)),
  getRecommendationProgramExcel: param => dispatch(getRecommendationProgramExcelAction(param))
})

const WithHoc = ChildComponent => props => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)