import { navigate } from "gatsby-link"
import { createAction, handleActions } from "redux-actions"
import RecommendationApis from "../../apis/RecommendationApis"
import { popupFn } from "../../utils/util"

const GET_RECOMMEND_LIST = "recommend/GET_RECOMMEND_LIST"
const GET_RECOMMEND_INFO = "recommend/GET_RECOMMEND_INFO"

export const getRecommendList = createAction(GET_RECOMMEND_LIST)
export const getRecommendInfo = createAction(GET_RECOMMEND_INFO)

const initState = {
  recommendList: undefined,
  recommendInfo: undefined,
}

export default handleActions(
  {
    [GET_RECOMMEND_LIST]: (state, action) => ({
      ...state,
      recommendList: action.payload,
    }),
    [GET_RECOMMEND_INFO]: (state, action) => ({
      ...state,
      recommendInfo: action.payload,
    }),
  },
  initState
)

// 추천 프로그램 목록 조회
export const getRecommendListAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getRecommendList())
  } else {
    const result = await RecommendationApis.getRecommendationProgramList(param)
    if (result.code == 0) {
      if (result.data.recommendPrograms.length == 0) {
        // popupFn("조회된 데이터가 없습니다.")
      }
      dispatch(getRecommendList(result.data))
      return true
    } else {
      return false
    }
  }
}

// 추천 프로그램 정보 조회
export const getRecommendationProgramInfoAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getRecommendInfo())
  } else {
    const result = await RecommendationApis.getRecommendationProgramInfo(param)
    if (result.code === 0) {
      dispatch(getRecommendInfo(result.data))
      return true
    } else {
      return false
    }
  }
}

// 추천 프로그램 저장/수정
export const postRecommendationProgramAction = param => async dispatch => {
  const result = await RecommendationApis.postRecommendationProgram(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

// 추천 프로그램 삭제
export const delRecommendationProgramAction = param => async dispatch => {
  const result = await RecommendationApis.delRecommendationProgram(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

// 추천 프로그램 엑셀 다운로드
export const getRecommendationProgramExcelAction = param => async dispatch => {
  const result = await RecommendationApis.getRecommendationProgramExcel(param)
  return result
}
