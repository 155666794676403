import React, { useState, useEffect, useRef } from "react"
import moment from "moment"
import CalendarComponent from "../../components/Calendar"
import Pagination from "../../components/common/pagination"

import RecommendContainer from "../../containers/RecommendContainer"
import CommonContainer from "../../containers/CommonContainer"

import { popupFn, toStringByFormatting } from "../../utils/util"

import CloseButton from "../../images/img_popup_close_black.svg"
import icon_calendar from "../../images/ion_calendar-number-outline.svg"
import download from "../../images/download.svg"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"

const RecommendationPopup = props => {
  const {
    onClose,
    isModify,
    info = {},
    postRecommendationProgram,
    studentGroupList,
    getStudentGroupList,
    getRecommendationProgramInfo,
    recommendInfo,
  } = props

  //date 세팅용 state
  const [calendarPopup, setCalendarPopup] = useState(false)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [date, setDate] = useState(moment())
  const [popupType, setPopupType] = useState(false)

  // 변수들
  const [recommendProgramSeq, setRecommendProgramSeq] = useState("")
  const [title, setTitle] = useState("") // 프로그램 타이틀
  const [studyDate, setStudyDate] = useState({
    // 공부 일 체크박스 (월, 화, 수, 목, 금, 토, 일)
    MOM: false,
    THU: false,
    WED: false,
    THUR: false,
    FRI: false,
    SAT: false,
    SUN: false,
  })
  const [option, setOption] = useState(0) // 옵션 Radio Button (0: 1회,  1: 반복)
  const [tableIdx, setTableIdx] = useState({ option: 0, startTime: "", endTime: "" }) // 상세 시간표 1 (option { 0: 공부, 1: 브레이크 타임 }, startTime: 시작시간, endTime: 끝나는 시간)
  const [timeTable, setTimeTable] = useState([])
  const [timeArr, setTimeArr] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  const [learningGroup, setLearningGroup] = useState({ name: "", value: "" })
  const [keywordInput, setKeywordInput] = useState("")
  const [keywords, setKeywords] = useState([])
  const [whileUse, setWhileUse] = useState(0) // 사용 여부 (0: 사용,  1: 미사용)
  const [whilePoint, setWhilePoint] = useState(0) // 포인트 사용여부 (0: 무료,  1: 유료)
  const [point, setPoint] = useState() // 포인트 사용 여부 Input Box

  useEffect(() => {
    async function getList() {
      await getStudentGroupList()
    }

    async function initDataIfModify() {
      await getRecommendationProgramInfo({ recommendProgramSeq: info?.recommendProgramSeq })

      setStartDate(moment(info?.dateStart))
      setEndDate(moment(info?.dateEnd))
      setOption(info?.isLoop === "Y" ? 1 : 0)
      setWhilePoint(info?.isPoint === "Y" ? 1 : 0)
      setWhileUse(info?.isUse === "Y" ? 0 : 1)
      setRecommendProgramSeq(info?.recommendProgramSeq)
      setLearningGroup({ name: info?.studentGroupName, value: info?.studentGroupSeq })
      setTitle(info?.title)
    }

    async function ResetData() {
      await getRecommendationProgramInfo()
    }

    getList()
    if (isModify) {
      initDataIfModify()
    }

    return () => {
      ResetData()
    }
  }, [])

  useEffect(() => {
    if (recommendInfo !== undefined) {
      setTimeTable(recommendInfo.recommendProgram?.studySchedules)
      setKeywords(recommendInfo.recommendProgram?.tags)
      if (recommendInfo.recommendProgram?.isPoint === "Y") {
        setPoint(recommendInfo.recommendProgram?.point)
      }
      if (recommendInfo.recommendProgram?.dayTypes) {
        if (recommendInfo.recommendProgram?.dayTypes.length !== 0) {
          setStudyDate({
            MOM: recommendInfo.recommendProgram?.dayTypes.includes("MOM"),
            THU: recommendInfo.recommendProgram?.dayTypes.includes("THU"),
            WED: recommendInfo.recommendProgram?.dayTypes.includes("WED"),
            THUR: recommendInfo.recommendProgram?.dayTypes.includes("THUR"),
            FRI: recommendInfo.recommendProgram?.dayTypes.includes("FRI"),
            SAT: recommendInfo.recommendProgram?.dayTypes.includes("SAT"),
            SUN: recommendInfo.recommendProgram?.dayTypes.includes("SUN"),
          })
        }
      }
    }
  }, [recommendInfo])

  const handleAddTime = () => {
    if (tableIdx?.startTime === "" || tableIdx?.endTime === "") {
      alert("시간을 입력해주세요.")
    } else if (Number(tableIdx?.startTime.substr(9, 2)) >= Number(tableIdx?.endTime.substr(9, 2))) {
      alert("시간을 확인해주세요.")
    } else {
      const start_num = Number(tableIdx?.startTime.substr(9, 2))
      const end_num = Number(tableIdx?.endTime.substr(9, 2))
      let is_available = true
      for (let i = start_num; i < end_num; i++) {
        if (timeArr[i] === 1) {
          alert("겹치는 시간이 있습니다. 시간을 확인해주세요.")
          is_available = false
          break
        }
      }
      if (is_available) {
        setTimeTable([
          ...timeTable,
          {
            isBreaktime: tableIdx?.option === 0 ? "N" : "Y",
            startTime: tableIdx?.startTime.substr(0, 8),
            endTime: tableIdx?.endTime.substr(0, 8),
          },
        ])
        for (let i = start_num; i < end_num; i++) {
          timeArr[i] = 1
        }
        setTableIdx({ option: 0, startTime: "", endTime: "" })
      }
    }
  }

  const handleDeleteTime = item => {
    if (timeTable) {
      const time = timeTable[item]
      let start_ = Number(timeTable[item].startTime.substr(0, 2))
      let end_ = Number(timeTable[item].endTime.substr(0, 2))
      if (end_ === 0) end_ = 24

      for (let i = start_; i < end_; i++) {
        timeArr[i] = 0
      }

      setTimeTable(timeTable.filter((i, idx) => (item === idx ? "" : i)))
    }
  }

  const ChangeTimeFormat = time_ => {
    // 10:00:00 => 10:00 AM
    const time = Number(time_.substr(0, 2))
    if (time <= 12) {
      return String(time) + ":00 AM"
    } else {
      return String(time - 12) + ":00 PM"
    }
  }

  const RenderTime = () => {
    let result = []
    if (timeTable) {
      timeTable.map((i, idx) => {
        result.push(
          <div className="time-table-input-wrapper">
            <div className="radio-btn-wrapper disabled">
              <img src={i?.isBreaktime === "N" ? radio_on : radio_off} />
              <p>공부</p>
            </div>
            <div className="radio-btn-wrapper disabled">
              <img src={i?.isBreaktime === "Y" ? radio_on : radio_off} />
              <p>휴식시간</p>
            </div>
            <div className="selected-box">{ChangeTimeFormat(i?.startTime)}</div>
            <div className="selected-box">{ChangeTimeFormat(i?.endTime)}</div>
            <div className="add-btn btn btn-primary font-white fgb-btn-m reverse" onClick={() => handleDeleteTime(idx)}>
              삭제
            </div>
          </div>
        )
      })
    }
    return result
  }

  const RenderSelectTime = () => (
    <div className="time-table-input-wrapper">
      <div className="radio-btn-wrapper">
        <img src={tableIdx.option === 0 ? radio_on : radio_off} onClick={e => setTableIdx({ ...tableIdx, option: 0 })} />
        <p>공부</p>
      </div>
      <div className="radio-btn-wrapper">
        <img src={tableIdx.option === 1 ? radio_on : radio_off} onClick={e => setTableIdx({ ...tableIdx, option: 1 })} />
        <p>휴식시간</p>
      </div>
      <div style={{ width: "103px", marginRight: "7px" }}>
        <select
          className="form-select"
          style={{ backgroundColor: "#F2F8FC", border: "none", cursor: "pointer" }}
          onChange={e => setTableIdx({ ...tableIdx, startTime: e.target.value })}
        >
          <option className="" value="">
            선택
          </option>
          {Times.map((i, idx) => (
            <option value={i.value} key={idx}>
              {i.time}
            </option>
          ))}
        </select>
      </div>
      <div style={{ width: "103px", marginRight: "7px" }}>
        <select
          className="form-select"
          style={{ backgroundColor: "#F2F8FC", border: "none", cursor: "pointer" }}
          onChange={e => setTableIdx({ ...tableIdx, endTime: e.target.value })}
        >
          <option className="" value="">
            선택
          </option>
          {Times.map((i, idx) => (
            <option value={i.value} key={idx}>
              {i.time}
            </option>
          ))}
        </select>
      </div>
      <div className="add-btn btn btn-primary font-white fgb-btn-m" onClick={handleAddTime}>
        추가
      </div>
    </div>
  )

  const handleOnSave = async () => {
    if (endDate < startDate) {
      alert("조회 기간을 제대로 설정해주세요.")
    } else {
      const recommend_program_seq = isModify ? recommendProgramSeq : ""
      const title_ = title
      const date_start = toStringByFormatting(startDate)
      const date_end = toStringByFormatting(endDate)
      const student_group_seq = learningGroup.value
      const point_ = whilePoint === 0 ? 0 : Number(point)
      const is_point = whilePoint === 0 ? "N" : "Y"
      const is_loop = option === 0 ? "N" : "Y"
      const is_use = whileUse === 0 ? "Y" : "N"
      const day_types = []
      Days.map(i => {
        if (studyDate[i] === true) {
          day_types.push(i)
        }
      })
      const study_schedules = timeTable
      const tags_ = keywords

      if (title_ === "") {
        alert("프로그램 타이틀을 입력해주세요.")
      } else if (student_group_seq === "") {
        alert("추천 학습자 그룹을 선택해주세요.")
      } else if (day_types === []) {
        alert("공부일을 입력해주세요.")
      } else if (study_schedules === []) {
        alert("시간을 선택해주세요.")
      } else {
        const payload = {
          recommendProgramSeq: recommend_program_seq,
          title: title_,
          dateStart: date_start,
          dateEnd: date_end,
          studentGroupSeq: student_group_seq,
          point: point_,
          isPoint: is_point,
          isLoop: is_loop,
          isUse: is_use,
          dayTypes: day_types,
          studySchedules: study_schedules,
          tags: tags_,
        }

        console.log(payload)

        const result = await postRecommendationProgram(payload)
        if (result) {
          popupFn("등록되었습니다.")
          onClose()
        }
      }
    }
  }

  return (
    <div onClick={onClose} className="schedule-popup-container recommendation-popup-container">
      <div onClick={e => e.stopPropagation()} className="schedule-popup-wrapper">
        <img src={CloseButton} alt="close-popup-button" className="close-popup-button" onClick={onClose} />
        <div className="schedule-popup-title">추천 프로그램 등록</div>
        <div style={{ maxHeight: "450px" }} className="recommendation-filter-wrapper">
          <div className="table-row program-title">
            <div className="element-l">프로그램 타이틀</div>
            <div className="element-r">
              <textarea value={title} onChange={e => setTitle(e.target.value)} maxLength="20" />
              <p>{title.length}자 / 20자</p>
              <div className="btn btn-primary font-white fgb-btn-m" onClick={e => setTitle("")}>
                초기화
              </div>
            </div>
          </div>

          <div className="table-row learning-period">
            <div className="element-l">공부 기간</div>
            <div className="element-r">
              <div className="fgb-date-form">
                <img src={icon_calendar} />
                <div
                  className="fgb-date-input"
                  onClick={() => {
                    setPopupType(false)
                    setCalendarPopup(true)
                  }}
                >
                  <p>{startDate.isValid() ? startDate.format("YYYY.MM.DD") : "시작일"}</p>
                </div>
                {calendarPopup && (
                  <div className="calendar-popup-back" style={{ zIndex: "102" }}>
                    <div className="calendar-popup-div">
                      <CalendarComponent
                        date={date}
                        setDate={setDate}
                        selectedDate={popupType == false ? startDate : endDate}
                        setSelectedDate={popupType == false ? setStartDate : setEndDate}
                        popup={setCalendarPopup}
                      />
                    </div>
                  </div>
                )}
                <p>~</p>
                <img src={icon_calendar} />
                <div
                  className="fgb-date-input"
                  onClick={() => {
                    setPopupType(true)
                    setCalendarPopup(true)
                  }}
                >
                  <p>{endDate.isValid() ? endDate.format("YYYY.MM.DD") : "종료일"}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="table-row program-info">
            <div className="element-l">프로그램 상세</div>
            <div className="element-r">
              <div className="program-box">
                <div className="inner-table-row">
                  <div className="inner-element-l">공부일</div>
                  <div className="inner-element-r">
                    <div className="check-box-wrapper" onClick={() => setStudyDate({ ...studyDate, MOM: !studyDate.MOM })}>
                      <img src={studyDate.MOM ? checked_on : checked_off} />
                      <p>월</p>
                    </div>
                    <div className="check-box-wrapper" onClick={() => setStudyDate({ ...studyDate, THU: !studyDate.THU })}>
                      <img src={studyDate.THU ? checked_on : checked_off} />
                      <p>화</p>
                    </div>
                    <div className="check-box-wrapper" onClick={() => setStudyDate({ ...studyDate, WED: !studyDate.WED })}>
                      <img src={studyDate.WED ? checked_on : checked_off} />
                      <p>수</p>
                    </div>
                    <div className="check-box-wrapper" onClick={() => setStudyDate({ ...studyDate, THUR: !studyDate.THUR })}>
                      <img src={studyDate.THUR ? checked_on : checked_off} />
                      <p>목</p>
                    </div>
                    <div className="check-box-wrapper" onClick={() => setStudyDate({ ...studyDate, FRI: !studyDate.FRI })}>
                      <img src={studyDate.FRI ? checked_on : checked_off} />
                      <p>금</p>
                    </div>
                    <div className="check-box-wrapper" onClick={() => setStudyDate({ ...studyDate, SAT: !studyDate.SAT })}>
                      <img src={studyDate.SAT ? checked_on : checked_off} />
                      <p>토</p>
                    </div>
                    <div className="check-box-wrapper" onClick={() => setStudyDate({ ...studyDate, SUN: !studyDate.SUN })}>
                      <img src={studyDate.SUN ? checked_on : checked_off} />
                      <p>일</p>
                    </div>
                  </div>
                </div>
                <div className="inner-table-row">
                  <div className="inner-element-l">옵션</div>
                  <div className="inner-element-r">
                    <div className="radio-btn-wrapper">
                      <img src={option == 0 ? radio_on : radio_off} onClick={e => setOption(0)} />
                      <p>1회</p>
                    </div>
                    <div className="radio-btn-wrapper">
                      <img src={option == 1 ? radio_on : radio_off} onClick={e => setOption(1)} />
                      <p>반복</p>
                    </div>
                  </div>
                </div>
                <div className="inner-table-row time">
                  <div className="inner-element-l">시간</div>
                  <div className="inner-element-r">
                    {RenderTime()}
                    {RenderSelectTime()}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="table-row learning-group">
            <div style={{ width: "98.72px" }} className="element-l">
              추천 학습자 그룹
            </div>
            <div className="element-r" style={{ width: "592.8px", maxWidth: "592.8px", overflowX: "auto", overflowY: "hidden" }}>
              {studentGroupList &&
                studentGroupList?.studentGroups &&
                studentGroupList?.studentGroups.map((i, idx) => (
                  <div className="check-box-wrapper" onClick={() => setLearningGroup({ name: i?.level1?.level1Name, value: i?.level1?.level1Seq })}>
                    <img src={learningGroup.name === i?.level1?.level1Name ? checked_on : checked_off} />
                    <p>{i?.level1?.level1Name}</p>
                  </div>
                ))}
            </div>
          </div>

          <div className="table-row keywords">
            <div className="element-l">키워드</div>
            <div className="element-r">
              {keywords.length !== 0 &&
                keywords.map(i => (
                  <div className="keyword-box">
                    {i}
                    <img className="x-button" src={CloseButton} onClick={e => setKeywords(keywords.filter(item => item !== i))} />
                  </div>
                ))}
              {keywords.length < 5 && (
                <input
                  className="input-box"
                  value={keywordInput}
                  style={{ width: "125px" }}
                  placeholder="Enter or Tab"
                  onChange={e => setKeywordInput(e.target.value)}
                  onKeyPress={e => {
                    if (e.key === "Enter" && e.target.value !== undefined) {
                      setKeywordInput()
                      setKeywords([...keywords, e.target.value])
                      e.target.value = ""
                    }
                  }}
                />
              )}
            </div>
          </div>

          <div className="table-row last-row">
            <div className="element-l">사용 여부</div>
            <div className="element-r">
              <div className="radio-btn-wrapper">
                <img src={whileUse == 0 ? radio_on : radio_off} onClick={e => setWhileUse(0)} />
                <p>사용</p>
              </div>
              <div className="radio-btn-wrapper">
                <img src={whileUse == 1 ? radio_on : radio_off} onClick={e => setWhileUse(1)} />
                <p>미사용</p>
              </div>
            </div>
            <div className="element-l">포인트 사용 여부</div>
            <div className="element-r">
              <div className="radio-btn-wrapper point">
                <img src={whilePoint == 0 ? radio_on : radio_off} onClick={e => setWhilePoint(0)} />
                <p>무료</p>
              </div>
              <div className="radio-btn-wrapper point">
                <img src={whilePoint == 1 ? radio_on : radio_off} onClick={e => setWhilePoint(1)} />
                <p>유료</p>
              </div>
              <input className="input-box" value={point} onChange={e => setPoint(e.target.value)} style={{ width: "100px" }} />
            </div>
          </div>
        </div>

        <div className="two-buttons-wrapper">
          <div className="study-schedule-search-button btn btn-primary font-white reverse fgb-btn-l" onClick={onClose}>
            취소
          </div>
          <div className="confirm-button btn btn-primary font-white fgb-btn-l" onClick={handleOnSave}>
            저장
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecommendContainer(CommonContainer(RecommendationPopup))

const Times = [
  { time: "00:00 AM", value: "00:00:00,00" },
  { time: "01:00 AM", value: "01:00:00,01" },
  { time: "02:00 AM", value: "02:00:00,02" },
  { time: "03:00 AM", value: "03:00:00,03" },
  { time: "04:00 AM", value: "04:00:00,04" },
  { time: "05:00 AM", value: "05:00:00,05" },
  { time: "06:00 AM", value: "06:00:00,06" },
  { time: "07:00 AM", value: "07:00:00,07" },
  { time: "08:00 AM", value: "08:00:00,08" },
  { time: "09:00 AM", value: "09:00:00,09" },
  { time: "10:00 AM", value: "10:00:00,10" },
  { time: "11:00 AM", value: "11:00:00,11" },
  { time: "12:00 AM", value: "12:00:00,12" },
  { time: "01:00 PM", value: "13:00:00,13" },
  { time: "02:00 PM", value: "14:00:00,14" },
  { time: "03:00 PM", value: "15:00:00,15" },
  { time: "04:00 PM", value: "16:00:00,16" },
  { time: "05:00 PM", value: "17:00:00,17" },
  { time: "06:00 PM", value: "18:00:00,18" },
  { time: "07:00 PM", value: "19:00:00,19" },
  { time: "08:00 PM", value: "20:00:00,20" },
  { time: "09:00 PM", value: "21:00:00,21" },
  { time: "10:00 PM", value: "22:00:00,22" },
  { time: "11:00 PM", value: "23:00:00,23" },
  { time: "00:00 AM", value: "00:00:00,24" },
]
const Days = ["MOM", "THU", "WED", "THUR", "FRI", "SAT", "SUN"]
