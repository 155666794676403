import { navigate } from "gatsby-link"
import { createAction, handleActions } from "redux-actions"
import ScheduleApis from "../../apis/ScheduleApis"
import { popupFn } from "../../utils/util"

const GET_STUDY_LIST = "schedule/GET_STUDY_LIST"
const GET_STUDY_INFO = "schedule/GET_STUDY_INFO"
const GET_SCHEDULE_PARTICIPANTS_LIST = "schedule/GET_SCHEDULE_PARTICIPANTS_LIST"
const POST_STUDY_SCHEDULE_CAM_STUDY = "schedule/POST_STUDY_SCHEDULE_CAM_STUDY"
const PUT_STUDY_SCHEDULE_CAM_STUDY = "schedule/PUT_STUDY_SCHEDULE_CAM_STUDY"
const GET_SCHEDULE_CAMSTUDY_LIST = "schedule/GET_SCHEDULE_CAMSTUDY_LIST"
const GET_SCHEDULE_CAMSTUDY_INFO = "schedule/GET_SCHEDULE_CAMSTUDY_INFO"
const DEL_SCHEDULE_CAMSTUDY = "schedule/DEL_SCHEDULE_CAMSTUDY"
const PUT_SCHEDULE_CAMSTUDY = "schedule/PUT_SCHEDULE_CAMSTUDY"
const GET_TODO_LIST = "schedule/GET_TODO_LIST"
const GET_TODO_INFO = "schedule/GET_TODO_INFO"
const POST_TODO = "schedule/POST_TODO"
const PUT_TODO = "schedule/PUT_TODO"
const POST_TODO_SUBSCRIBER = "schedule/POST_TODO_SUBSCRIBER"
const DEL_TODO_SUBSCRIBER = "schedule/DEL_TODO_SUBSCRIBER"
const GET_TODO_ASSIGNMENT = "schedule/GET_TODO_ASSIGNMENT"
const PUT_TODO_ASSIGNMENT = "schedule/PUT_TODO_ASSIGNMENT"
const POST_TODO_ASSIGNMENT = "schedule/POST_TODO_ASSIGNMENT"
const DEL_TODO_ASSIGNMENT = "schedule/DEL_TODO_ASSIGNMENT"
const POST_TODO_KEYWORD = "schedule/POST_TODO_KEYWORD"
const DEL_TODO_KEYWORD = "schedule/DEL_TODO_KEYWORD"
const GET_SCHEDULE_TODO_LIST = "schedule/GET_SCHEDULE_TODO_LIST"
const GET_SCHEDULE_TODO_INFO = "schedule/GET_SCHEDULE_TODO_INFO"
const PUT_SCHEDULE_TODO = "schedule/PUT_SCHEDULE_TODO"
const DEL_SCHEDULE_TODO = "schedule/DEL_SCHEDULE_TODO"

export const getStudyList = createAction(GET_STUDY_LIST)
export const getStudyInfo = createAction(GET_STUDY_INFO)
export const getScheduleParticipantsList = createAction(GET_SCHEDULE_PARTICIPANTS_LIST)
export const postStudyScheduleCamStudy = createAction(POST_STUDY_SCHEDULE_CAM_STUDY)
export const putStudyScheduleCamStudy = createAction(PUT_STUDY_SCHEDULE_CAM_STUDY)
export const getScheduleCamstudyList = createAction(GET_SCHEDULE_CAMSTUDY_LIST)
export const getScheduleCamstudyInfo = createAction(GET_SCHEDULE_CAMSTUDY_INFO)
export const delScheduleCamstudy = createAction(DEL_SCHEDULE_CAMSTUDY)
export const putScheduleCamstudy = createAction(PUT_SCHEDULE_CAMSTUDY)
export const getToDoList = createAction(GET_TODO_LIST)
export const getToDoInfo = createAction(GET_TODO_INFO)
export const postToDo = createAction(POST_TODO)
export const putToDo = createAction(PUT_TODO)
export const postToDoSubscriber = createAction(POST_TODO_SUBSCRIBER)
export const delToDoSubscriber = createAction(DEL_TODO_SUBSCRIBER)
export const getToDoAssignment = createAction(GET_TODO_ASSIGNMENT)
export const putToDoAssignment = createAction(PUT_TODO_ASSIGNMENT)
export const postToDoAssignment = createAction(POST_TODO_ASSIGNMENT)
export const delToDoAssignment = createAction(DEL_TODO_ASSIGNMENT)
export const postToDoKeyword = createAction(POST_TODO_KEYWORD)
export const delToDoKeyword = createAction(DEL_TODO_KEYWORD)
export const getScheduleToDoList = createAction(GET_SCHEDULE_TODO_LIST)
export const getScheduleToDoInfo = createAction(GET_SCHEDULE_TODO_INFO)
export const putScheduleToDo = createAction(PUT_SCHEDULE_TODO)
export const delScheduleToDo = createAction(DEL_SCHEDULE_TODO)

const initState = {
  studyList: undefined,
  studyInfo: undefined,
  scheduleParticipantsList: undefined,
  scheduleCamstudyList: undefined,
  scheduleCamstudyInfo: undefined,
  todoList: undefined,
  todoInfo: undefined,
  scheduleTodoList: undefined,
  scheduleTodoInfo: undefined,
  todoAssignment: undefined,
}

export default handleActions(
  {
    [GET_STUDY_LIST]: (state, action) => ({
      ...state,
      studyList: action.payload,
    }),
    [GET_STUDY_INFO]: (state, action) => ({
      ...state,
      studyInfo: action.payload,
    }),
    [GET_SCHEDULE_PARTICIPANTS_LIST]: (state, action) => ({
      ...state,
      scheduleParticipantsList: action.payload,
    }),
    [GET_SCHEDULE_CAMSTUDY_LIST]: (state, action) => ({
      ...state,
      scheduleCamstudyList: action.payload,
    }),
    [GET_SCHEDULE_CAMSTUDY_INFO]: (state, action) => ({
      ...state,
      scheduleCamstudyInfo: action.payload,
    }),
    [GET_TODO_LIST]: (state, action) => ({
      ...state,
      todoList: action.payload,
    }),
    [GET_TODO_INFO]: (state, action) => ({
      ...state,
      todoInfo: action.payload,
    }),
    [GET_SCHEDULE_TODO_LIST]: (state, action) => ({
      ...state,
      scheduleTodoList: action.payload,
    }),
    [GET_TODO_ASSIGNMENT]: (state, action) => ({
      ...state,
      todoAssignment: action.payload,
    }),
    [GET_SCHEDULE_TODO_INFO]: (state, action) => ({
      ...state,
      scheduleTodoInfo: action.payload,
    }),
  },
  initState
)

// 스터디 목록 조회
export const getStudyListAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getStudyList())
  } else {
    const result = await ScheduleApis.getStudyList(param)
    if (result.code === 0) {
      // if (result.data.studies.length == 0) {
      //   popupFn("조회된 데이터가 없습니다.")
      // }
      dispatch(getStudyList(result.data))
      return true
    } else {
      return false
    }
  }
}
// 스터디 목록 excel
export const getStudyListExcelAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getStudyList())
  } else {
    const result = await ScheduleApis.getStudyListExcel(param)
    if (result) {
      return result
    } else {
      return false
    }
  }
}
// 스케줄 신청 캠스터디 excel
export const getScheduleCamstudyListExcelAction = param => async dispatch => {
  const result = await ScheduleApis.getScheduleCamstudyListExcel(param)
  if (result) {
    return result
  } else {
    return false
  }
}
// 스케줄 신청 TO-DO excel
export const getScheduleToDoListExcelAction = param => async dispatch => {
  const result = await ScheduleApis.getScheduleToDoListExcel(param)
  if (result) {
    return result
  } else {
    return false
  }
}

// 스터디 정보 조회
export const getStudyInfoAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getStudyInfo())
  } else {
    const result = await ScheduleApis.getStudyInfo(param)
    if (result.code === 0) {
      dispatch(getStudyInfo(result.data))
      return true
    } else {
      return false
    }
  }
}

// 스터디 정보 - 참여자 선택 입력 / 승인 요청 조회
export const getStudyMemberAction = param => async dispatch => {
  const result = await ScheduleApis.getStudyMember(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

// 스케줄 관리 참여자 추가 목록 조회
export const getScheduleParticipantsListAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getScheduleParticipantsList())
  } else {
    const result = await ScheduleApis.getScheduleParticipantsList(param)
    if (result.code === 0) {
      dispatch(getScheduleParticipantsList(result.data))
      return true
    } else {
      return false
    }
  }
}

// 스터디 등록
export const postStudyScheduleCamStudyAction = param => async dispatch => {
  const result = await ScheduleApis.postStudyScheduleCamStudy(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

// 스터디 수정
export const putStudyScheduleCamStudyAction = param => async dispatch => {
  const result = await ScheduleApis.putStudyScheduleCamStudy(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

// 스터디 스케줄 조회 - TO-DO 조회
export const getToDoListAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getToDoList())
  } else {
    const result = await ScheduleApis.getToDoList(param)
    if (result.code === 0) {
      if (result.data.todos.length == 0) {
        // popupFn("조회된 데이터가 없습니다.")
      }
      dispatch(getToDoList(result.data))
      return true
    } else {
      return false
    }
  }
}

// 스터디 스케줄 조회 - TO-DO 정보 조회
export const getToDoInfoAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getToDoInfo())
  } else {
    const result = await ScheduleApis.getToDoInfo(param)
    if (result.code === 0) {
      dispatch(getToDoInfo(result.data))
      return true
    } else {
      return false
    }
  }
}

// 스터디 스케줄 조회 - TO-DO 등록
export const postToDoAction = param => async dispatch => {
  const result = await ScheduleApis.postToDo(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

// 스터디 스케줄 조회 - TO-DO 수정
export const putToDoAction = param => async dispatch => {
  const result = await ScheduleApis.putToDo(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

// 스터디 스케줄 조회 - TO-DO 수정 - 구독자 추가 ( 등록된 TO-DO에 적용 )
export const postToDoSubscriberAction = param => async () => {
  const result = await ScheduleApis.postToDoSubscriber(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

// 스터디 스케줄 조회 - TO-DO 수정 - 구독자 삭제 ( 등록된 TO-DO에 적용 )
export const delToDoSubscriberAction = param => async () => {
  const result = await ScheduleApis.delToDoSubscriber(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

// 스터디 스케줄 조회 - TO-DO 수정 - 과제 정보 조회 ( 등록된 TO-DO에 적용 )
export const getToDoAssignmentAction = param => async dispatch => {
  const result = await ScheduleApis.getToDoAssignment(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

// 스터디 스케줄 조회 - TO-DO 수정 - 과제 수정 ( 등록된 TO-DO에 적용 )
export const putToDoAssignmentAction = param => async () => {
  const result = await ScheduleApis.putToDoAssignment(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

// 스터디 스케줄 조회 - TO-DO 수정 - 과제 추가 ( 등록된 TO-DO에 적용 )
export const postToDoAssignmentAction = param => async () => {
  const result = await ScheduleApis.postToDoAssignment(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

// 스터디 스케줄 조회 - TO-DO 수정 - 과제 삭제 ( 등록된 TO-DO에 적용 )
export const delToDoAssignmentAction = param => async () => {
  const result = await ScheduleApis.delToDoAssignment(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

// 스터디 스케줄 조회 - TO-DO 수정 - 키워드 추가 ( 등록된 TO-DO에 적용 )
export const postToDoKeywordAction = param => async () => {
  const result = await ScheduleApis.postToDoKeyword(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

// 스터디 스케줄 조회 - TO-DO 수정 - 키워드 삭제 ( 등록된 TO-DO에 적용 )
export const delToDoKeywordAction = param => async () => {
  const result = await ScheduleApis.delToDoKeyword(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

// 스케줄 신청 - 캠스터디 목록
export const getScheduleCamstudyListAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getScheduleCamstudyList())
  } else {
    const result = await ScheduleApis.getScheduleCamstudyList(param)
    if (result.code === 0) {
      if (result.data.studySubscriptions.length == 0) {
        // popupFn("조회된 데이터가 없습니다.")
      }
      dispatch(getScheduleCamstudyList(result.data))
      return true
    } else {
      return false
    }
  }
}

// 스케줄 신청 - 캠스터디 정보
export const getScheduleCamstudyInfoAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getScheduleCamstudyInfo())
  } else {
    const result = await ScheduleApis.getScheduleCamstudyInfo(param)
    if (result.code === 0) {
      dispatch(getScheduleCamstudyInfo(result.data))
      return true
    } else {
      return false
    }
  }
}

// 스케줄 신청 - 캠스터디 선택 삭제
export const delScheduleCamstudyAction = param => async dispatch => {
  const result = await ScheduleApis.delScheduleCamstudy(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

// 스케줄 신청 - 캠스터디 수정
export const putScheduleCamstudyAction = param => async dispatch => {
  const result = await ScheduleApis.putScheduleCamstudy(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

// 스케줄 신청 목록 조회 - TO-DO
export const getScheduleToDoListAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getScheduleToDoList())
  } else {
    const result = await ScheduleApis.getScheduleToDoList(param)
    if (result.code === 0) {
      dispatch(getScheduleToDoList(result.data))
      return true
    } else {
      return false
    }
  }
}

// 스케줄 신청 정보 조회 - TO-DO
export const getScheduleToDoInfoAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getScheduleToDoInfo())
  } else {
    const result = await ScheduleApis.getScheduleToDoInfo(param)
    if (result.code === 0) {
      dispatch(getScheduleToDoInfo(result.data))
      return true
    } else {
      return false
    }
  }
}

// 스케줄 신청 수정 - TO-DO
export const putScheduleToDoAction = param => async () => {
  const result = await ScheduleApis.putScheduleToDo(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

// 스케줄 신청 삭제 - TO-DO
export const delScheduleToDoAction = param => async () => {
  const result = await ScheduleApis.delScheduleToDo(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}
