/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect, useRef } from "react"

import "swiper/scss"

import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import moment from "moment"
import CalendarComponent from "../../components/Calendar"
import Pagination from "../../components/common/pagination"
import TopInfo from "../../components/common/TopInfo"
import ScheduleCamstudyPopup from "../../popups/schedule/ScheduleCamstudyPopup"
import ScheduleTodoPopup from "../../popups/schedule/ScheduleTodoPopup"

import CommonContainer from "../../containers/CommonContainer"
import ScheduleContainer from "../../containers/ScheduleContainer"

import { downExcel, toStringByFormatting } from "../../utils/util"

import icon_calendar from "../../images/ion_calendar-number-outline.svg"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"
import download from "../../images/download.svg"
import CloseButton from "../../images/img_popup_close_black.svg"
import { set } from "lodash"
import PrivateRoute from "../../components/privateRoute"

const StudyScheduleInquiry = props => {
  const { getStudyList, getStudyListExcel, studyList, getStudentGroupList, studentGroupList, getRegionList, regionList, getToDoList, todoList } =
    props

  const isMounted = useRef(false)
  const [userType, setUserType] = useState()

  //date 세팅용 state
  const [calendarPopup, setCalendarPopup] = useState(false)
  const [calendarPopup1, setCalendarPopup1] = useState(false)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [date, setDate] = useState(moment())
  const [popupType, setPopupType] = useState(false)

  // popups
  const [scheduleCamstudyPopup, setScheduleCamstudyPopup] = useState(false)
  const [scheduleTodoPopup, setScheduleTodoPopup] = useState(false)
  const [info, setInfo] = useState(undefined)

  // pagination
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(1)

  const [createDate, setCreateDate] = useState(moment()) // 생성일 날짜

  const [distinguish, setDistinguish] = useState(0) // 구분 체크박스 (0: 전체,  1: 유료,  2: 무료)
  const [distinguishType, setDistinguishType] = useState({ y: true, n: true }) // 구분 체크박스 (0: 전체,  1: 유료,  2: 무료)
  const [kind, setKind] = useState(0) // 유형 Radio Button (0: 캠스터디,  1: TO-DO)
  const [classify, setClassify] = useState({
    // 분류 체크박스 (렛미업, 학원, 컨설턴트, 개인)
    letmeup: true,
    academy: false,
    consultant: false,
    personal: false,
  })
  const [learningGroup, setLearningGroup] = useState("") // 학습자 그룹 드롭다운 기본값: 전체
  const [participatedNum, setParticipatedNum] = useState("") // 참여 인원 드롭다운 기본값: 전체    (4 ~ 30명, 전체면 0)
  const [periodInput, setPeriodInput] = useState("") // 기간 Input Box 기본값: null
  const [accumulatedTimeInput, setAccumulatedTimeInput] = useState("") // 누적 시간 Input Box 기본값: null
  const [achievementRateInput, setAchievementRateInput] = useState("") // 달성률 Input Box 기본값: null
  const [region, setRegion] = useState("") // 지역 드롭다운 기본값: 전체
  const [frontDisplay, setFrontDisplay] = useState(0) // 전시 체크박스 (0: 전체,  1: 전시,  2: 미전시)
  const [frontDisplayType, setFrontDisplayType] = useState({ y: true, n: true }) // 전시 체크박스 (0: 전체,  1: 전시,  2: 미전시)
  const [owner, setOwner] = useState(0) // 소유자 Radio Button (0: 이름,  1: 스크린 닉네임)
  const [ownerInput, setOwnerInput] = useState("") // 소유자 Input Box 기본값: null
  const [keyword, setKeyword] = useState("") // 키워드 Input Box 기본값: null
  const [createdDate, setCreatedDate] = useState(moment()) // 생성일 기본값 ''
  const [createdDateClicked, setCreatedDateClicked] = useState(false)

  useEffect(() => {
    async function getLists() {
      await getStudentGroupList()
      await getRegionList()
    }

    async function resetList() {
      await getStudyList()
      await getToDoList()
    }

    getLists()
    setUserType(localStorage.loginUserType)

    return () => {
      resetList()
    }
  }, [])

  useEffect(() => {
    if (isMounted.current) {
      handleSearch(current - 1)
    } else {
      isMounted.current = true
    }
  }, [current])

  useEffect(() => {
    setTotal(studyList?.totalCount)
  }, [studyList])

  const handleSearch = async num => {
    if (endDate < startDate) {
      alert("조회 기간을 제대로 설정해주세요.")
    } else {
      const date_start = toStringByFormatting(startDate)
      const date_end = toStringByFormatting(endDate)
      const is_free = distinguishType.y && distinguishType.n ? "" : distinguishType.y ? "Y" : "N"
      const study_type = []
      if (classify.letmeup) study_type.push("ADMIN")
      if (classify.academy) study_type.push("CRAM_SCHOOL")
      if (classify.consultant) study_type.push("CONSULTANT")
      if (classify.personal) study_type.push("USER")
      const depth_1 = learningGroup
      const study_member_count = participatedNum
      const period_ = periodInput === "" ? "" : Number(periodInput)
      const accumulated_time = accumulatedTimeInput === "" ? "" : Number(accumulatedTimeInput)
      const percent_ = achievementRateInput === "" ? "" : Number(achievementRateInput)
      const region_3 = region
      const display_ = frontDisplayType.y && frontDisplayType.n ? "" : frontDisplayType.y ? "Y" : "N"
      const reg_date = createdDateClicked ? toStringByFormatting(createdDate) : ""
      const name_ = owner === 0 ? ownerInput : ""
      const nickname_ = owner === 1 ? ownerInput : ""
      const keyword_ = keyword
      const page_ = num

      console.log(study_type)

      console.log(`
        dateStart: ${date_start}
        dateEnd: ${date_end}
        isFree: ${is_free}
        ownerType: ${study_type}
        depth1: ${depth_1}
        studyMemberCount: ${study_member_count}
        period: ${period_}
        accumulatedTime: ${accumulated_time}
        percent: ${percent_}
        region3: ${region_3}
        display: ${display_}
        regDate: ${reg_date}
        name: ${name_}
        nickname: ${nickname_}
        keyword: ${keyword_}
        page: ${page_}
        count: ${15}
      `)

      if (kind === 0) {
        const result = await getStudyList({
          dateStart: date_start,
          dateEnd: date_end,
          isFree: is_free,
          ownerType: study_type,
          depth1: depth_1,
          studyMemberCount: study_member_count,
          period: period_,
          accumulatedTime: accumulated_time,
          percent: percent_,
          region3: region_3,
          display: display_,
          regDate: reg_date,
          name: name_,
          nickname: nickname_,
          keyword: keyword_,
          page: page_,
          count: 15,
        })
      } else {
        const result = await getToDoList({
          dateStart: date_start,
          dateEnd: date_end,
          ownerType: study_type,
          depth1: depth_1,
          display: display_,
          name: name_,
          nickname: nickname_,
          keyword: keyword_,
          regDate: reg_date,
          page: page_,
          count: 15,
        })
      }
    }
  }
  const excelFn = async () => {
    if (endDate < startDate) {
      alert("조회 기간을 제대로 설정해주세요.")
    } else {
      const date_start = toStringByFormatting(startDate)
      const date_end = toStringByFormatting(endDate)
      const is_free = distinguishType.y && distinguishType.n ? "" : distinguishType.y ? "Y" : "N"

      const study_type = []
      if (classify.letmeup) study_type.push("ADMIN")
      if (classify.academy) study_type.push("CRAM_SCHOOL")
      if (classify.consultant) study_type.push("CONSULTANT")
      if (classify.personal) study_type.push("USER")
      const depth_1 = learningGroup
      const study_member_count = participatedNum
      const period_ = periodInput === "" ? "" : Number(periodInput)
      const accumulated_time = accumulatedTimeInput === "" ? "" : Number(accumulatedTimeInput)
      const percent_ = achievementRateInput === "" ? "" : Number(achievementRateInput)
      const region_3 = region
      const display_ = frontDisplayType.y && frontDisplayType.n ? "" : frontDisplayType.y ? "Y" : "N"
      const reg_date = createdDateClicked ? toStringByFormatting(createdDate) : ""
      const name_ = owner === 0 ? ownerInput : ""
      const nickname_ = owner === 1 ? ownerInput : ""
      const keyword_ = keyword
      const page_ = current - 1

      console.log(`
        dateStart: ${date_start}
        dateEnd: ${date_end}
        isFree: ${is_free}
        ownerType: ${study_type}
        depth1: ${depth_1}
        studyMemberCount: ${study_member_count}
        period: ${period_}
        accumulatedTime: ${accumulated_time}
        percent: ${percent_}
        region3: ${region_3}
        display: ${display_}
        regDate: ${reg_date}
        name: ${name_}
        nickname: ${nickname_}
        keyword: ${keyword_}
        page: ${page_}
        count: ${15}
      `)

      if (kind === 0) {
        const result = await getStudyListExcel({
          dateStart: date_start,
          dateEnd: date_end,
          isFree: is_free,
          ownerType: userType == "ADMIN" ? study_type : "",
          depth1: depth_1,
          studyMemberCount: study_member_count,
          period: period_,
          accumulatedTime: accumulated_time,
          percent: percent_,
          region3: region_3,
          display: display_,
          regDate: reg_date,
          name: name_,
          nickname: nickname_,
          keyword: keyword_,
          page: page_,
          count: 15,
        })
        if (result) {
          if (result.status === 200) await downExcel({ result: result, fileName: "스터디 목록" })
          else return false
        }
      } else {
        const result = await getStudyListExcel({
          dateStart: date_start,
          dateEnd: date_end,
          ownerType: study_type,
          depth1: depth_1,
          display: display_,
          name: name_,
          nickname: nickname_,
          keyword: keyword_,
          page: page_,
          count: 15,
        })
        if (result) {
          if (result.status === 200) await downExcel({ result: result, fileName: "TO-DO 목록" })
          else return false
        }
      }
    }
  }

  const handleElementOnClick = (kind_, seq) => {
    setInfo(seq)
    if (kind_ === 0) {
      setScheduleCamstudyPopup(true)
    } else {
      setScheduleTodoPopup(true)
    }
  }
  return (
    <PrivateRoute>
      <>
        {scheduleCamstudyPopup && info !== undefined && (
          <ScheduleCamstudyPopup
            onClose={e => {
              setScheduleCamstudyPopup(false)
              setInfo(undefined)
            }}
            studySeq={info}
          />
        )}
        {scheduleTodoPopup && info !== undefined && (
          <ScheduleTodoPopup
            onClose={e => {
              setScheduleTodoPopup(false)
              setInfo(undefined)
            }}
            data={info}
          />
        )}
        <Layout>
          <SEO title="스터디스케줄 조회" />
          <TopInfo title={["스터디 스케줄 조회", "스터디 스케줄 관리", "스터디 스케줄 조회"]} />

          <div className="study-schedule-inquiry-container">
            <div className="study-schedule-inquiry-table-wrapper">
              <div className="table-row bottom-border">
                <div className="element-l">조회 기간</div>
                <div className="element-r right-border right-s element-date-form">
                  <div className="fgb-date-form">
                    <img src={icon_calendar} />
                    <div
                      className="fgb-date-input"
                      onClick={() => {
                        setPopupType("startDate")
                        setCalendarPopup(true)
                      }}
                    >
                      <p>{startDate.isValid() ? startDate.format("YYYY.MM.DD") : "시작일"}</p>
                    </div>
                    {calendarPopup && (
                      <div className="calendar-popup-back">
                        <div className="calendar-popup-div">
                          <CalendarComponent
                            date={date}
                            setDate={setDate}
                            selectedDate={popupType === "startDate" ? startDate : popupType === "endDate" ? endDate : createdDate}
                            setSelectedDate={popupType === "startDate" ? setStartDate : popupType === "endDate" ? setEndDate : setCreatedDate}
                            popup={setCalendarPopup}
                          />
                        </div>
                      </div>
                    )}
                    <p>~</p>
                    <img src={icon_calendar} />
                    <div
                      className="fgb-date-input"
                      onClick={() => {
                        setPopupType("endDate")
                        setCalendarPopup(true)
                      }}
                    >
                      <p>{endDate.isValid() ? endDate.format("YYYY.MM.DD") : "종료일"}</p>
                    </div>
                  </div>
                </div>
                <div className="element-l">구분</div>
                <div className="element-r right-border right-m">
                  <div
                    className="check-box-wrapper"
                    onClick={() => {
                      if (distinguishType.y && distinguishType.n) {
                        setDistinguishType({ y: false, n: false })
                      } else {
                        setDistinguishType({ y: true, n: true })
                      }
                    }}
                  >
                    <img src={distinguishType.y && distinguishType.n ? checked_on : checked_off} />
                    <p>전체</p>
                  </div>
                  <div
                    className="check-box-wrapper"
                    onClick={() => setDistinguishType({ ...distinguishType, y: !distinguishType.y })}
                    style={{ marginRight: "5px" }}
                  >
                    <img src={distinguishType.y ? checked_on : checked_off} />
                    <p>유료</p>
                  </div>
                  <div className="check-box-wrapper" onClick={() => setDistinguishType({ ...distinguishType, n: !distinguishType.n })}>
                    <img src={distinguishType.n ? checked_on : checked_off} />
                    <p>무료</p>
                  </div>
                </div>
                <div className="element-l">유형</div>
                <div className="element-r right-m">
                  <div className="radio-btn-wrapper" onClick={() => setKind(0)}>
                    <img src={kind == 0 ? radio_on : radio_off} />
                    <p>캠스터디</p>
                  </div>
                  <div className="radio-btn-wrapper" onClick={() => setKind(1)}>
                    <img src={kind == 1 ? radio_on : radio_off} />
                    <p>TO-DO</p>
                  </div>
                </div>
              </div>
              <div className="table-row bottom-border">
                <div className="element-l">분류</div>
                {userType === "ADMIN" ? (
                  <div className="element-r right-border right-s">
                    <div className="check-box-wrapper" onClick={() => setClassify({ ...classify, letmeup: !classify.letmeup })}>
                      <img src={classify.letmeup ? checked_on : checked_off} />
                      <p>렛미업</p>
                    </div>
                    <div className="check-box-wrapper" onClick={() => setClassify({ ...classify, academy: !classify.academy })}>
                      <img src={classify.academy ? checked_on : checked_off} />
                      <p>학원</p>
                    </div>
                    <div className="check-box-wrapper" onClick={() => setClassify({ ...classify, consultant: !classify.consultant })}>
                      <img src={classify.consultant ? checked_on : checked_off} />
                      <p>컨설턴트</p>
                    </div>
                    <div className="check-box-wrapper" onClick={() => setClassify({ ...classify, personal: !classify.personal })}>
                      <img src={classify.personal ? checked_on : checked_off} />
                      <p>개인</p>
                    </div>
                  </div>
                ) : (
                  <div className="element-r right-border right-s"></div>
                )}
                <div className="element-l">학습자 그룹</div>
                <div className="element-r right-border right-m">
                  <div style={{ width: "180px" }}>
                    <select
                      className="form-select"
                      style={{ backgroundColor: "#F2F8FC", border: "none", cursor: "pointer" }}
                      onChange={e => setLearningGroup(e.target.value)}
                    >
                      <option className="" value="">
                        전체
                      </option>
                      {studentGroupList?.studentGroups &&
                        studentGroupList?.studentGroups.map((i, idx) => <option value={i?.level1?.level1Seq}>{i?.level1?.level1Name}</option>)}
                    </select>
                  </div>
                </div>
                <div className="element-l">참여 인원&#40;명&#41;</div>
                <div className="element-r right-m">
                  <div style={{ width: "180px" }}>
                    <select
                      className="form-select"
                      style={{
                        backgroundColor: "#F2F8FC",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onChange={e => setParticipatedNum(e.target.value)}
                    >
                      <option className="" value="">
                        전체
                      </option>
                      {PARTICIPATED_NUM.map(i => (
                        <option value={i}>{i}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="table-row bottom-border">
                <div className="element-l">기간&#40;일&#41;</div>
                <div className="element-r right-border right-s">
                  <input className="input-box" value={periodInput} onChange={e => setPeriodInput(e.target.value)} style={{ width: "210px" }} />
                </div>
                <div className="element-l">누적 시간</div>
                <div className="element-r right-border right-m">
                  <input
                    className="input-box"
                    value={accumulatedTimeInput}
                    onChange={e => setAccumulatedTimeInput(e.target.value)}
                    style={{ width: "180px" }}
                  />
                </div>
                <div className="element-l">달성률&#40;%&#41;</div>
                <div className="element-r right-m">
                  <input
                    className="input-box"
                    value={achievementRateInput}
                    onChange={e => setAchievementRateInput(e.target.value)}
                    style={{ width: "180px" }}
                  />
                </div>
              </div>
              <div className="table-row bottom-border">
                <div className="element-l">지역</div>
                <div className="element-r right-border right-s">
                  <div style={{ width: "210px" }}>
                    <select
                      className="form-select"
                      style={{
                        backgroundColor: "#F2F8FC",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onChange={e => setRegion(e.target.value)}
                    >
                      <option className="" value="">
                        전체
                      </option>
                      {regionList &&
                        regionList?.studyLocations &&
                        regionList?.studyLocations?.region3.map((i, idx) => (
                          <option value={i} key={idx}>
                            {i}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="element-l">Front 전시</div>
                <div className="element-r right-border right-m">
                  <div
                    className="check-box-wrapper"
                    onClick={() => {
                      if (frontDisplayType.y && frontDisplayType.n) {
                        setFrontDisplayType({ y: false, n: false })
                      } else {
                        setFrontDisplayType({ y: true, n: true })
                      }
                    }}
                    style={{ marginRight: "5px" }}
                  >
                    <img src={frontDisplayType.y && frontDisplayType.n ? checked_on : checked_off} />
                    <p>전체</p>
                  </div>
                  <div
                    className="check-box-wrapper"
                    onClick={() => setFrontDisplayType({ ...frontDisplayType, y: !frontDisplayType.y })}
                    style={{ marginRight: "5px" }}
                  >
                    <img src={frontDisplayType.y ? checked_on : checked_off} />
                    <p>전시</p>
                  </div>
                  <div className="check-box-wrapper" onClick={() => setFrontDisplayType({ ...frontDisplayType, n: !frontDisplayType.n })}>
                    <img src={frontDisplayType.n ? checked_on : checked_off} />
                    <p>미전시</p>
                  </div>
                </div>
                <div className="element-l">생성일</div>
                <div className="element-r right-m" style={{ justifyContent: "center" }}>
                  <div className="fgb-date-form">
                    <img src={icon_calendar} />
                    <div
                      className="fgb-date-input"
                      onClick={() => {
                        setPopupType("createdDate")
                        setCalendarPopup1(true)
                        setCreatedDateClicked(true)
                      }}
                    >
                      <p>{createdDate.isValid() ? createdDate.format("YYYY.MM.DD") : "생성일"}</p>
                    </div>
                  </div>
                  {calendarPopup1 && (
                    <div className="calendar-popup-back">
                      <div style={{ left: "-200px" }} className="calendar-popup-div">
                        <CalendarComponent
                          date={date}
                          setDate={setDate}
                          selectedDate={popupType === "startDate" ? startDate : popupType === "endDate" ? endDate : createdDate}
                          setSelectedDate={popupType === "startDate" ? setStartDate : popupType === "endDate" ? setEndDate : setCreatedDate}
                          popup={setCalendarPopup1}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="table-row no-border">
                <div className="element-l">소유자</div>
                <div className="element-r right-border right-l">
                  <div className="radio-btn-wrapper" onClick={() => setOwner(0)}>
                    <img src={owner == 0 ? radio_on : radio_off} />
                    <p>이름</p>
                  </div>
                  <div className="radio-btn-wrapper" onClick={() => setOwner(1)}>
                    <img src={owner == 1 ? radio_on : radio_off} />
                    <p>스크린 닉네임</p>
                  </div>
                  <input className="input-box" value={ownerInput} onChange={e => setOwnerInput(e.target.value)} style={{ width: "300px" }} />
                </div>
                <div className="element-l">키워드</div>
                <div className="element-r right-m">
                  <input className="input-box" value={keyword} onChange={e => setKeyword(e.target.value)} style={{ width: "180px" }} />
                </div>
              </div>
            </div>

            <div className="search-btn-wrapper">
              <div
                className="btn btn-primary font-white fgb-btn-l"
                onClick={() => {
                  handleSearch(0)
                  setCurrent(1)
                }}
              >
                조회
              </div>
            </div>

            <div className="result-info-wrapper">
              <div className="people-num">{kind == 0 ? studyList?.totalCount || 0 : todoList?.totalCount || 0}개</div>
              <div onClick={excelFn} className="excel-download-btn btn fgb-btn-m fgb-btn-grean" style={{ borderRadius: "6px" }}>
                <img src={download} />
                <p>엑셀 다운로드</p>
              </div>
            </div>

            {kind === 0 && studyList?.studies !== undefined && studyList?.studies.length !== 0 ? (
              <>
                <div className="fgb-table-div">
                  <table className="fgb-table">
                    <thead>
                      <tr className="fgb-table-title-tr">
                        <td>No.</td>
                        {TITLE.map((i, idx) => (
                          <td key={idx}>{i}</td>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {studyList?.studies.map((i, idx) => (
                        <tr style={{ cursor: "pointer" }} className="fgb-table-tr" key={idx} onClick={() => handleElementOnClick(0, i?.studySeq)}>
                          <td>{(current - 1) * 15 + idx + 1}</td>
                          <td>{i?.isFree === "Y" ? "무료" : "유료"}</td>
                          <td>{i?.type === "CAM_STUDY" ? "캠스터디" : "TO-DO"}</td>
                          <td>
                            {i?.ownerType === "ADMIN"
                              ? "렛미업"
                              : i?.ownerType === "CRAM_SCHOOL"
                              ? "학원"
                              : i?.ownerType === "CONSULTANT"
                              ? "컨설턴트"
                              : "개인"}
                          </td>
                          <td>{i?.name}</td>
                          <td>{i?.nickname}</td>
                          <td style={{ cursor: "pointer" }}>{i?.title}</td>
                          <td style={{ cursor: "pointer" }}>{i?.tag}</td>
                          <td>{i?.studyMemberCount}</td>
                          <td>{i?.dateStart}</td>
                          <td>{i?.dateEnd}</td>
                          <td>{i?.totalStudyDay}</td>
                          <td>{i?.planTime}</td>
                          <td>{i?.accumulatedTime}</td>
                          <td>{i?.percent}</td>
                          <td>{i?.region3}</td>
                          <td>{i?.settingSearching === "Y" ? "전시" : "미전시"}</td>
                          <td>{i?.regDatetime}</td>
                          <td>{i?.updateDatetime}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  current={current}
                  setCurrent={setCurrent}
                  total={kind == 0 ? studyList?.totalCount || 0 : todoList?.totalCount || 0}
                  count={15}
                />
              </>
            ) : kind === 1 && todoList?.todos !== undefined && todoList?.todos.length !== 0 ? (
              <>
                <div className="fgb-table-div">
                  <table className="fgb-table">
                    <thead>
                      <tr className="fgb-table-title-tr">
                        <td>No.</td>
                        {TITLE_TODO.map((i, idx) => (
                          <td key={idx}>{i}</td>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {todoList?.todos.map((i, idx) => (
                        <tr className="fgb-table-tr" key={idx} onClick={() => handleElementOnClick(1, { seq: i.seq, seqType: i?.seqType })}>
                          <td>{(current - 1) * 15 + idx + 1}</td>
                          <td>{i?.type === "CAM_STUDY" ? "캠스터디" : "TO-DO"}</td>
                          <td>{i?.ownerTypeName}</td>
                          <td>{i?.name}</td>
                          <td style={{ cursor: "pointer" }}>{i?.nickname}</td>
                          <td style={{ cursor: "pointer" }}>{i?.subscriberCount}</td>
                          <td style={{ cursor: "pointer" }}>{i?.title}</td>
                          <td>{i?.taskCount}</td>
                          <td>{i?.bookmarkCount}</td>
                          <td>{i?.favoriteCount}</td>
                          <td>{i?.responseCount}</td>
                          <td>{i?.regDatetime}</td>
                          <td>{i?.updateDatetime}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  current={current}
                  setCurrent={setCurrent}
                  total={kind == 0 ? studyList?.totalCount || 0 : todoList?.totalCount || 0}
                  count={15}
                />
              </>
            ) : (
              <>
                {/* <div className="search-none-wrapper">
                  <p>조회 결과가 없습니다.</p>
                  <p>원하는 항목을 선택하고 '조회' 버튼을 눌러주십시오.</p>
                </div> */}
              </>
            )}
          </div>
        </Layout>
      </>
    </PrivateRoute>
  )
}

export default CommonContainer(ScheduleContainer(StudyScheduleInquiry))

const PARTICIPATED_NUM = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]
const TITLE = [
  "구분",
  "유형",
  "분류",
  "소유자",
  "닉네임",
  "제목",
  "키워드",
  "인원",
  "시작일",
  "완료일",
  " 기간(일)",
  "(일)목표시간",
  "누적시간",
  "달성률(%)",
  "지역",
  "전시",
  "생성일",
  "변경일",
]
const TITLE_TODO = ["유형", "분류", "소유자", "닉네임", "구독자(명)", "대표 키워드", "과제 개수", "저장", "좋아요", "응답률", "생성일", "변경일"]
