/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect, useRef } from "react"

import "swiper/scss"

import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import moment from "moment"
import CalendarComponent from "../../components/Calendar"
import Pagination from "../../components/common/pagination"
import TopInfo from "../../components/common/TopInfo"
import CamstudyPopup from "../../popups/schedule/CamstudyPopup"
import TodoPopup from "../../popups/schedule/TodoPopup"
import { downExcel, localStorgeGet, toStringByFormatting } from "../../utils/util"

import CommonContainer from "../../containers/CommonContainer"
import ScheduleContainer from "../../containers/ScheduleContainer"

import icon_calendar from "../../images/ion_calendar-number-outline.svg"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"
import download from "../../images/download.svg"
import CloseButton from "../../images/img_popup_close_black.svg"
import PrivateRoute from "../../components/privateRoute"

const ScheduleInquiry = props => {
  const {
    getStudentGroupList,
    studentGroupList,
    getScheduleCamstudyList,
    scheduleCamstudyList,
    delScheduleCamstudy,
    getScheduleToDoList,
    scheduleTodoList,
    delScheduleToDo,
    getScheduleCamstudyListExcel,
    getScheduleToDoListExcel,
  } = props

  const isMounted = useRef(false)
  // popups
  const [camstudyPopup, setCamstudyPopup] = useState(false)
  const [todoPopup, setTodoPopup] = useState(false)
  const [popupInfo, setPopupInfo] = useState(undefined)

  // pagination
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(1)

  //date 세팅용 state
  const [calendarPopup, setCalendarPopup] = useState(false)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [date, setDate] = useState(moment())
  const [popupType, setPopupType] = useState(false)

  const [userType, setUserType] = useState("")

  // 결과 테이블
  const [allCheck, setAllCheck] = useState(false)
  const [checkList, setCheckList] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ])

  const [kind, setKind] = useState({ ADMIN: true, CRAM_SCHOOL: true, CONSULTANT: true, USER: true }) // 분류 (0: 렛미업,  1: 학원,  2: 컨설턴트,  3: 개인)
  const [option, setOption] = useState(0) // 구분 (0: 캠스터디,  1: TO-DO)
  const [status, setStatus] = useState({ all: true, reg: true, allow: true, not: true }) // 상태: (전체, 신청, 승인, 반려)
  const [learningGroup, setLearningGroup] = useState("") // 학습자 그룹
  const [regOption, setRegOption] = useState(0) // 신청자 옵션 (0: 이름,  1: 닉네임)
  const [regInput, setRegInput] = useState("") // 신청자 Input

  useEffect(() => {
    async function getLists() {
      await getStudentGroupList()
    }

    async function resetLists() {
      await getScheduleCamstudyList()
      await getScheduleToDoList()
    }

    getLists()
    setUserType(localStorage.loginUserType)

    return () => {
      resetLists()
    }
  }, [])

  // pagination 페이지 바꿀 때
  useEffect(() => {
    if (isMounted.current) {
      handleSearch(option, current - 1)
      setCheckList([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false])
    } else {
      isMounted.current = true
    }
  }, [current])

  useEffect(() => {
    if (allCheck) {
      setCheckList([true, true, true, true, true, true, true, true, true, true, true, true, true, true, true])
    } else {
      setCheckList([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false])
    }
  }, [allCheck])

  useEffect(() => {
    setTotal(scheduleCamstudyList?.totalCount)
  }, [scheduleCamstudyList])

  useEffect(() => {
    setTotal(scheduleTodoList?.totalCount)
  }, [scheduleTodoList])

  const ChangeCheckList = item => {
    let list_ = []
    for (let i = 0; i < checkList.length; i++) {
      if (i === item) {
        list_.push(!checkList[i])
      } else {
        list_.push(checkList[i])
      }
    }
    setCheckList(list_)
  }

  const excelFn = async () => {
    if (startDate > endDate) {
      alert("조회 기간을 확인해주세요.")
    } else {
      if (option === 0) {
        const date_start = toStringByFormatting(startDate)
        const date_end = toStringByFormatting(endDate)
        let user_type = []
        let status_ = []
        const student_group_seq = learningGroup
        const word_type = regOption === 0 ? "NAME" : "NICKNAME"
        const word_ = regInput
        const page_ = current - 1
        if (localStorgeGet("loginUserType") == "ADMIN") {
          if (kind.ADMIN) user_type.push("ADMIN")
          if (kind.CRAM_SCHOOL) user_type.push("CRAM_SCHOOL")
          if (kind.CONSULTANT) user_type.push("CONSULTANT")
          if (kind.USER) user_type.push("USER")
        }
        if (!(status.not && status.reg && status.allow)) {
          if (status.reg) status_.push("REGISTRATION")
          if (status.allow) status_.push("APPROVAL")
          if (status.not) status_.push("REJECTION")
        } else {
          status_.push("REGISTRATION")
          status_.push("APPROVAL")
          status_.push("REJECTION")
        }

        console.log(`
          dateStart: ${date_start}
          dateEnd: ${date_end}
          userType: ${user_type}
          status: ${status_}
          studentGroupSeq: ${student_group_seq}
          wordType: ${word_type}
          word: ${word_}
          page: ${page_}
        `)

        const result = await getScheduleCamstudyListExcel({
          dateStart: date_start,
          dateEnd: date_end,
          userType: user_type,
          status: status_,
          studentGroupSeq: student_group_seq,
          wordType: word_type,
          word: word_,
          page: current - 1,
        })
        if (result) {
          await downExcel({ result: result, fileName: "STUDY 신청목록" })
        }
      } else {
        const date_start = toStringByFormatting(startDate)
        const date_end = toStringByFormatting(endDate)
        let user_type = []
        let status_ = []
        const student_group_seq = learningGroup
        const word_type = regOption === 0 ? "NAME" : "NICKNAME"
        const word_ = regInput
        const page_ = current - 1
        if (localStorgeGet("loginUserType") == "ADMIN") {
          if (kind.ADMIN) user_type.push("ADMIN")
          if (kind.CRAM_SCHOOL) user_type.push("CRAM_SCHOOL")
          if (kind.CONSULTANT) user_type.push("CONSULTANT")
          if (kind.USER) user_type.push("USER")
        }
        if (!(status.not && status.reg && status.allow)) {
          if (status.reg) status_.push("REGISTRATION")
          if (status.allow) status_.push("APPROVAL")
          if (status.not) status_.push("REJECTION")
        } else {
          status_.push("REGISTRATION")
          status_.push("APPROVAL")
          status_.push("REJECTION")
        }

        console.log(`
          dateStart: ${date_start}
          dateEnd: ${date_end}
          userType: ${user_type}
          status: ${status_}
          studentGroupSeq: ${student_group_seq}
          wordType: ${word_type}
          word: ${word_}
          page: ${page_}
        `)

        const result = await getScheduleToDoListExcel({
          dateStart: date_start,
          dateEnd: date_end,
          userType: user_type,
          status: status_,
          studentGroupSeq: student_group_seq,
          wordType: word_type,
          word: word_,
          page: page_,
        })
        if (result) {
          await downExcel({ result: result, fileName: "TO-DO 신청목록" })
        }
      }
    }
  }
  const handleSearch = async (type, page) => {
    if (startDate > endDate) {
      alert("조회 기간을 확인해주세요.")
    } else {
      if (type === 0) {
        const date_start = toStringByFormatting(startDate)
        const date_end = toStringByFormatting(endDate)
        let user_type = []
        let status_ = []
        const student_group_seq = learningGroup
        const word_type = regOption === 0 ? "NAME" : "NICKNAME"
        const word_ = regInput
        const page_ = page
        if (localStorgeGet("loginUserType") == "ADMIN") {
          if (kind.ADMIN) user_type.push("ADMIN")
          if (kind.CRAM_SCHOOL) user_type.push("CRAM_SCHOOL")
          if (kind.CONSULTANT) user_type.push("CONSULTANT")
          if (kind.USER) user_type.push("USER")
        }
        if (!(status.not && status.reg && status.allow)) {
          if (status.reg) status_.push("REGISTRATION")
          if (status.allow) status_.push("APPROVAL")
          if (status.not) status_.push("REJECTION")
        } else {
          status_.push("REGISTRATION")
          status_.push("APPROVAL")
          status_.push("REJECTION")
        }

        console.log(`
          dateStart: ${date_start}
          dateEnd: ${date_end}
          userType: ${user_type}
          status: ${status_}
          studentGroupSeq: ${student_group_seq}
          wordType: ${word_type}
          word: ${word_}
          page: ${page_}
        `)

        const result = await getScheduleCamstudyList({
          dateStart: date_start,
          dateEnd: date_end,
          userType: user_type,
          status: status_,
          studentGroupSeq: student_group_seq,
          wordType: word_type,
          word: word_,
          page: page_,
        })
      } else {
        const date_start = toStringByFormatting(startDate)
        const date_end = toStringByFormatting(endDate)
        let user_type = []
        let status_ = []
        const student_group_seq = learningGroup
        const word_type = regOption === 0 ? "NAME" : "NICKNAME"
        const word_ = regInput
        const page_ = page
        if (localStorgeGet("loginUserType") == "ADMIN") {
          if (kind.ADMIN) user_type.push("ADMIN")
          if (kind.CRAM_SCHOOL) user_type.push("CRAM_SCHOOL")
          if (kind.CONSULTANT) user_type.push("CONSULTANT")
          if (kind.USER) user_type.push("USER")
        }
        if (!(status.not && status.reg && status.allow)) {
          if (status.reg) status_.push("REGISTRATION")
          if (status.allow) status_.push("APPROVAL")
          if (status.not) status_.push("REJECTION")
        } else {
          status_.push("REGISTRATION")
          status_.push("APPROVAL")
          status_.push("REJECTION")
        }

        console.log(`
          dateStart: ${date_start}
          dateEnd: ${date_end}
          userType: ${user_type}
          status: ${status_}
          studentGroupSeq: ${student_group_seq}
          wordType: ${word_type}
          word: ${word_}
          page: ${page_}
        `)

        const result = await getScheduleToDoList({
          dateStart: date_start,
          dateEnd: date_end,
          userType: user_type,
          status: status_,
          studentGroupSeq: student_group_seq,
          wordType: word_type,
          word: word_,
          page: page_,
        })
      }
    }
  }

  const handleDelete = async () => {
    if (option === 0) {
      if (scheduleCamstudyList?.studySubscriptions) {
        let subscription_seqs = []
        for (let i = 0; i < scheduleCamstudyList?.studySubscriptions.length; i++) {
          if (checkList[i]) {
            subscription_seqs.push(scheduleCamstudyList?.studySubscriptions[i].subscriptionSeq)
          }
        }
        if (subscription_seqs.length > 0) {
          const payload = { subscriptionSeqs: subscription_seqs }
          console.log(payload)
          const result = await delScheduleCamstudy(payload)
          if (result) {
            setCheckList([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false])
            handleSearch(0, 0)
          }
        } else {
          alert("삭제할 캠스터디를 선택해주세요.")
        }
      } else {
        alert("삭제할 캠스터디를 선택해주세요.")
      }
    } else {
      if (scheduleTodoList?.subscriptions) {
        let subscription_seqs = []
        for (let i = 0; i < scheduleTodoList?.subscriptions.length; i++) {
          if (checkList[i]) {
            subscription_seqs.push(scheduleTodoList?.subscriptions[i].subscriptonSeq)
          }
        }
        if (subscription_seqs.length > 0) {
          const payload = { subscriptionSeqs: subscription_seqs }
          console.log(payload)
          const result = await delScheduleToDo(payload)
          if (result) {
            setCheckList([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false])
            handleSearch(1, 0)
          }
        }
      }
    }
  }

  const ChangePopupInfoCamstudy = seq => {
    setPopupInfo(seq)
    setCamstudyPopup(true)
  }

  const ChangePopupInfoTodo = seq => {
    setPopupInfo(seq)
    setTodoPopup(true)
  }

  return (
    <PrivateRoute>
      <>
        {camstudyPopup && (
          <CamstudyPopup
            subscriptionSeq={popupInfo}
            onClose={() => {
              setCamstudyPopup(false)
              setPopupInfo(undefined)
            }}
          />
        )}
        {todoPopup && popupInfo !== undefined && (
          <TodoPopup
            subscriptionSeq={popupInfo}
            onClose={() => {
              setTodoPopup(false)
              setPopupInfo(undefined)
            }}
          />
        )}
        <Layout>
          <SEO title="스케줄 신청목록 조회" />
          <TopInfo title={["스케줄 신청목록 조회", "스터디 스케줄 관리", "스케줄 신청목록 조회"]} />
          <div className="schedule-inquiry-container">
            <div className="schedule-inquiry-filter-wrapper">
              <div className="table-row">
                <div className="element-l">조회 기간</div>
                <div className="element-r">
                  <div className="fgb-date-form">
                    <img src={icon_calendar} />
                    <div
                      className="fgb-date-input"
                      onClick={() => {
                        setPopupType(false)
                        setCalendarPopup(true)
                      }}
                    >
                      <p>{startDate.isValid() ? startDate.format("YYYY.MM.DD") : "시작일"}</p>
                    </div>
                    {calendarPopup && (
                      <div className="calendar-popup-back">
                        <div className="calendar-popup-div">
                          <CalendarComponent
                            date={date}
                            setDate={setDate}
                            selectedDate={popupType == false ? startDate : endDate}
                            setSelectedDate={popupType == false ? setStartDate : setEndDate}
                            popup={setCalendarPopup}
                          />
                        </div>
                      </div>
                    )}
                    <p>~</p>
                    <img src={icon_calendar} />
                    <div
                      className="fgb-date-input"
                      onClick={() => {
                        setPopupType(true)
                        setCalendarPopup(true)
                      }}
                    >
                      <p>{endDate.isValid() ? endDate.format("YYYY.MM.DD") : "종료일"}</p>
                    </div>
                  </div>
                </div>
                <div className="element-l">구분</div>
                <div className="element-r">
                  <div className="radio-btn-wrapper" onClick={() => setOption(0)}>
                    <img src={option == 0 ? radio_on : radio_off} />
                    <p>캠스터디</p>
                  </div>
                  <div className="radio-btn-wrapper" onClick={() => setOption(1)}>
                    <img src={option == 1 ? radio_on : radio_off} />
                    <p>TO-DO</p>
                  </div>
                </div>
              </div>
              <div className="table-row">
                <div className="element-l">분류</div>
                <div className="element-r">
                  <div
                    className="radio-btn-wrapper"
                    onClick={() => {
                      if (userType === "ADMIN") setKind({ ...kind, ADMIN: !kind.ADMIN })
                    }}
                  >
                    <img src={kind.ADMIN && userType === "ADMIN" ? checked_on : checked_off} />
                    <p style={{ color: userType === "ADMIN" ? "black" : "#B1B1B1" }}>렛미업</p>
                  </div>
                  <div
                    className="radio-btn-wrapper"
                    onClick={() => {
                      if (userType === "ADMIN") setKind({ ...kind, CRAM_SCHOOL: !kind.CRAM_SCHOOL })
                    }}
                  >
                    <img src={kind.CRAM_SCHOOL && userType === "ADMIN" ? checked_on : checked_off} />
                    <p style={{ color: userType === "ADMIN" ? "black" : "#B1B1B1" }}>학원</p>
                  </div>
                  <div
                    className="radio-btn-wrapper"
                    onClick={() => {
                      if (userType === "ADMIN") setKind({ ...kind, CONSULTANT: !kind.CONSULTANT })
                    }}
                  >
                    <img src={kind.CONSULTANT && userType === "ADMIN" ? checked_on : checked_off} />
                    <p style={{ color: userType === "ADMIN" ? "black" : "#B1B1B1" }}>컨설턴트</p>
                  </div>
                  <div
                    className="radio-btn-wrapper"
                    onClick={() => {
                      if (userType === "ADMIN") setKind({ ...kind, USER: !kind.USER })
                    }}
                  >
                    <img src={kind.USER && userType === "ADMIN" ? checked_on : checked_off} />
                    <p style={{ color: userType === "ADMIN" ? "black" : "#B1B1B1" }}>개인</p>
                  </div>
                </div>
                <div className="element-l">학습자 그룹</div>
                <div className="element-r">
                  <select
                    className="form-select"
                    style={{ backgroundColor: "#F2F8FC", border: "none", cursor: "pointer", width: "200px" }}
                    onChange={e => setLearningGroup(e.target.value)}
                  >
                    <option className="" value="">
                      전체
                    </option>
                    {studentGroupList?.studentGroups &&
                      studentGroupList?.studentGroups.map((i, idx) => (
                        <option key={idx} value={i?.level1?.level1Seq}>
                          {i?.level1?.level1Name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="table-row">
                <div className="element-l">상태</div>
                <div className="element-r">
                  <div
                    className="radio-btn-wrapper"
                    onClick={() => {
                      if (status.not && status.reg && status.allow) {
                        setStatus({ reg: false, not: false, allow: false })
                      } else {
                        setStatus({ reg: true, not: true, allow: true })
                      }
                    }}
                  >
                    <img src={status.not && status.reg && status.allow ? checked_on : checked_off} />
                    <p>전체</p>
                  </div>
                  <div className="radio-btn-wrapper" onClick={() => setStatus({ ...status, reg: !status.reg })}>
                    <img src={status.reg ? checked_on : checked_off} />
                    <p>신청</p>
                  </div>
                  <div className="radio-btn-wrapper" onClick={() => setStatus({ ...status, allow: !status.allow })}>
                    <img src={status.allow ? checked_on : checked_off} />
                    <p>승인</p>
                  </div>
                  <div className="radio-btn-wrapper" onClick={() => setStatus({ ...status, not: !status.not })}>
                    <img src={status.not ? checked_on : checked_off} />
                    <p>반려</p>
                  </div>
                </div>
                <div className="element-l">신청자</div>
                <div className="element-r">
                  <div className="radio-btn-wrapper" onClick={() => setRegOption(0)}>
                    <img src={regOption == 0 ? radio_on : radio_off} />
                    <p>이름</p>
                  </div>
                  <div className="radio-btn-wrapper" onClick={() => setRegOption(1)}>
                    <img src={regOption == 1 ? radio_on : radio_off} />
                    <p>스크린 닉네임</p>
                  </div>
                  <input className="user-info-input-box" value={regInput} onChange={e => setRegInput(e.target.value)} />
                </div>
              </div>
            </div>

            <div className="search-btn-wrapper">
              <div className="btn btn-primary font-white fgb-btn-l" onClick={() => handleSearch(option, 0)}>
                조회
              </div>
            </div>

            <div className="result-info-wrapper">
              <div className="people-num">
                {option === 0
                  ? scheduleCamstudyList?.totalCount
                    ? scheduleCamstudyList?.totalCount
                    : 0
                  : scheduleTodoList?.totalCount
                  ? scheduleTodoList?.totalCount
                  : 0}
                개
              </div>
              <div className="two-btn-wrapper">
                <div className="btn btn-primary font-white reverse fgb-btn-ms clear-button me-2" onClick={handleDelete}>
                  선택 삭제
                </div>
                <div onClick={excelFn} className="excel-download-btn btn fgb-btn-m fgb-btn-grean" style={{ borderRadius: "6px " }}>
                  <img src={download} />
                  <p>엑셀 다운로드</p>
                </div>
              </div>
            </div>

            {option === 0 && scheduleCamstudyList?.studySubscriptions !== undefined && scheduleCamstudyList?.studySubscriptions.length !== 0 ? (
              <>
                <div className="fgb-table-div">
                  <table className="fgb-table">
                    <thead>
                      <tr className="fgb-table-title-tr">
                        <td className="check-box-img" onClick={() => setAllCheck(!allCheck)}>
                          <img src={allCheck ? checked_on : checked_off} style={{ cursor: "pointer" }} />
                        </td>
                        {CAMSTUDY_TITLE.map((i, idx) => (
                          <td key={idx}>{i}</td>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {scheduleCamstudyList?.studySubscriptions.map((i, idx) => (
                        <tr className="fgb-table-tr" key={idx}>
                          <td onClick={() => ChangeCheckList(idx)}>
                            <img className="check-box-img" src={checkList[idx] ? checked_on : checked_off} />
                          </td>
                          <td onClick={() => ChangePopupInfoCamstudy(i?.subscriptionSeq)}>
                            {i?.status === "REGISTRATION" ? "신청" : i?.status === "APPROVAL" ? "승인" : i?.status === "REJECTION" ? "반려" : ""}
                          </td>
                          <td onClick={() => ChangePopupInfoCamstudy(i?.subscriptionSeq)}>{i?.regDatetime}</td>
                          <td onClick={() => ChangePopupInfoCamstudy(i?.subscriptionSeq)}>{i?.studentGroupName}</td>
                          <td onClick={() => ChangePopupInfoCamstudy(i?.subscriptionSeq)}>{i?.name}</td>
                          <td onClick={() => ChangePopupInfoCamstudy(i?.subscriptionSeq)}>{i?.nickname}</td>
                          <td style={{ cursor: "pointer" }} onClick={() => ChangePopupInfoCamstudy(i?.subscriptionSeq)}>
                            {i?.title}
                          </td>
                          <td style={{ cursor: "pointer" }} onClick={() => ChangePopupInfoCamstudy(i?.subscriptionSeq)}>
                            {i?.tags}
                          </td>
                          <td onClick={() => ChangePopupInfoCamstudy(i?.subscriptionSeq)}>{i?.dateStart}</td>
                          <td onClick={() => ChangePopupInfoCamstudy(i?.subscriptionSeq)}>{i?.dateEnd}</td>
                          <td onClick={() => ChangePopupInfoCamstudy(i?.subscriptionSeq)}>{i?.accumulatedCount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Pagination current={current} setCurrent={setCurrent} total={total} count={15} />
              </>
            ) : option === 1 && scheduleTodoList?.subscriptions !== undefined && scheduleTodoList?.subscriptions.length !== 0 ? (
              <>
                <div className="fgb-table-div">
                  <table className="fgb-table">
                    <thead>
                      <tr className="fgb-table-title-tr">
                        <td className="check-box-img" onClick={() => setAllCheck(!allCheck)}>
                          <img src={allCheck ? checked_on : checked_off} style={{ cursor: "pointer" }} />
                        </td>
                        {TODO_TITLE.map((i, idx) => (
                          <td key={idx}>{i}</td>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {scheduleTodoList?.subscriptions.map((i, idx) => (
                        <tr className="fgb-table-tr" key={idx}>
                          <td onClick={() => ChangeCheckList(idx)}>
                            <img className="check-box-img" src={checkList[idx] ? checked_on : checked_off} />
                          </td>
                          <td onClick={() => ChangePopupInfoTodo(i?.subscriptonSeq)}>
                            {i?.status === "REGISTRATION" ? "신청" : i?.status === "APPROVAL" ? "승인" : i?.status === "REJECTION" ? "반려" : ""}
                          </td>
                          <td onClick={() => ChangePopupInfoTodo(i?.subscriptonSeq)}>{i?.regDatetime}</td>
                          <td onClick={() => ChangePopupInfoTodo(i?.subscriptonSeq)}>{i?.studentGroupName}</td>
                          <td onClick={() => ChangePopupInfoTodo(i?.subscriptonSeq)}>{i?.name}</td>
                          <td onClick={() => ChangePopupInfoTodo(i?.subscriptonSeq)}>{i?.nickname}</td>
                          <td onClick={() => ChangePopupInfoTodo(i?.subscriptonSeq)}>{i?.title}</td>
                          <td onClick={() => ChangePopupInfoTodo(i?.subscriptonSeq)}>{i?.keywords}</td>
                          <td onClick={() => ChangePopupInfoTodo(i?.subscriptonSeq)}>{i?.taskCount}</td>
                          <td onClick={() => ChangePopupInfoTodo(i?.subscriptonSeq)}>{i?.accumulatedSubscriberCount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Pagination current={current} setCurrent={setCurrent} total={total} count={15} />
              </>
            ) : (
              <>
                {/* <div className="search-none-wrapper">
                <p>조회 결과가 없습니다.</p>
                <p>원하는 항목을 선택하고 '조회' 버튼을 눌러주십시오.</p>
              </div> */}
              </>
            )}
          </div>
        </Layout>
      </>
    </PrivateRoute>
  )
}

export default CommonContainer(ScheduleContainer(ScheduleInquiry))

const CAMSTUDY_TITLE = ["상태", "신청일", "학습자 그룹", "신청자", "닉네임", "캠스터디 제목", "키워드", "시작일", "종료일", "누적 신청자수"]
const TODO_TITLE = ["상태", "신청일", "학습자 그룹", "신청자", "닉네임", "TO-DO 제목", "키워드", "과제 개수", "누적 구독자수"]
