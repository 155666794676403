/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect } from "react"

import "swiper/scss"

import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import TopInfo from "../../components/common/TopInfo"

import Pagination from "../../components/common/pagination"
import CalendarComponent from "../../components/Calendar"
import MessageSendPopup from "../../popups/MessageSendPopup"

import icon_calendar from "../../images/ion_calendar-number-outline.svg"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import moment from "moment"
import SignContainer from "../../containers/SignContainer"
import PrivateRoute from "../../components/privateRoute"
const TYPE = { PLAN_CHANGE: "일정변경 안내", NOTICE: "공지사항", EVENT: "이벤트", OFFLINE_EVENT: "행사", ETC: "기타" }
const Message = props => {
  const { delMessages, profileMessages, profileMessagesTotal, getMessages, messagesEmpty, messagesTotalEmpty } = props
  //date 세팅용 state
  const [calendarPopup, setCalendarPopup] = useState(false)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [date, setDate] = useState(moment())
  const [popupType, setPopupType] = useState(false)
  const [type, setType] = useState("")

  const [current, setCurrent] = useState(1)
  const [checkList, setCheckList] = useState([])

  const [openMessageSend, setOpenMessageSend] = useState(false)
  const searchFn = async () => {
    if (startDate > endDate) return false
    let temp = {
      dateStart: startDate.format("YYYY-MM-DD"),
      dateEnd: endDate.format("YYYY-MM-DD"),
      page: 0,
      count: 15,
      type: type,
    }
    let result = await getMessages(temp)
    if (result) {
      setCurrent(1)
      setCheckList([])
    }
  }
  const delFn = async () => {
    if (checkList.length < 1) return false

    let result = await delMessages({ alarmLogSeqs: checkList })
    if (result) {
      searchFn()
    }
  }
  const pageFn = async num => {
    if (startDate > endDate) return false
    let temp = {
      dateStart: startDate.format("YYYY-MM-DD"),
      dateEnd: endDate.format("YYYY-MM-DD"),
      type: type,
      page: num - 1,
      count: 15,
    }
    let result = await getMessages(temp)
    if (result) {
      setCheckList([])
    }
  }

  useEffect(() => {
    return () => {
      messagesEmpty()
      messagesTotalEmpty()
    }
  }, [])

  return (
    <PrivateRoute>
      <Layout>
        <SEO title="내 메세지" />
        <TopInfo title={["내 메세지", "내 계정 관리", "내 메세지"]} />
        <MessageSendPopup open={openMessageSend} onClose={() => setOpenMessageSend(false)} />

        <div className="partner-profile-point-list">
          <div className="point-top-table my-order">
            <div className="point-top-table-name my-order">
              <div>조회 기간</div>
            </div>
            <div className="point-top-table-radio my-order">
              <div className="point-top-table-radio-row ">
                <div className="fgb-date-form ms-4">
                  <img src={icon_calendar} />
                  <div
                    className="fgb-date-input"
                    onClick={() => {
                      setPopupType(false)
                      setCalendarPopup(true)
                    }}
                  >
                    <p>{startDate.isValid() ? startDate.format("YYYY.MM.DD") : "시작일"}</p>
                  </div>
                  {calendarPopup && (
                    <div className="calendar-popup-back">
                      <div className="calendar-popup-div">
                        <CalendarComponent
                          date={date}
                          setDate={setDate}
                          selectedDate={popupType == false ? startDate : endDate}
                          setSelectedDate={popupType == false ? setStartDate : setEndDate}
                          popup={setCalendarPopup}
                        />
                      </div>
                    </div>
                  )}
                  <p>~</p>
                  <img src={icon_calendar} />

                  <div
                    className="fgb-date-input"
                    onClick={() => {
                      setPopupType(true)
                      setCalendarPopup(true)
                    }}
                  >
                    <p>{endDate.isValid() ? endDate.format("YYYY.MM.DD") : "종료일"}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="point-top-table-name my-order">
              <div>유형</div>
            </div>
            <div className="point-top-table-radio my-order">
              <div style={{ width: "220px" }} className="point-top-table-radio-row">
                <select
                  className="form-select ms-3"
                  value={type}
                  onChange={e => {
                    setType(e.target.value)
                  }}
                >
                  <option className="" value="">
                    전체
                  </option>
                  <option className="" value="PLAN_CHANGE">
                    일정변경 안내
                  </option>
                  <option className="" value="NOTICE">
                    공지사항
                  </option>
                  <option className="" value="EVENT">
                    이벤트
                  </option>
                  <option className="" value="OFFLINE_EVENT">
                    행사
                  </option>
                  <option className="" value="ETC">
                    기타
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <div onClick={searchFn} className="btn btn-primary font-white fgb-btn-l">
              조회
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <div onClick={delFn} className="btn btn-primary font-white reverse fgb-btn-ms clear-button me-2">
              선택 삭제
            </div>
            <div onClick={() => setOpenMessageSend(true)} className="btn btn-primary font-white fgb-btn-m me-2">
              메세지 보내기
            </div>
          </div>

          <div className="introduce-study-table mt-3">
            <div className="fgb-table-div">
              <table className={`fgb-table mb-4`}>
                <thead>
                  <tr className={`fgb-table-title-tr`}>
                    <td>
                      <img
                        onClick={() => {
                          if (checkList.length == profileMessages.length) {
                            setCheckList([])
                          } else {
                            let temp = []
                            for (let i of profileMessages) {
                              temp = [...temp, i.alarmLogSeq]
                            }
                            setCheckList(temp)
                          }
                        }}
                        className="ms-4 me-2"
                        src={checkList.length == profileMessages.length ? checked_on : checked_off}
                      />
                    </td>
                    {["유형", "제목", "수신 형태", "수신자", "발송일자"].map((i, idx) => (
                      <td key={idx}>{i}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {profileMessages.map((i, idx) => (
                    <tr className={`fgb-table-tr`} key={idx}>
                      <td>
                        <img
                          onClick={() => {
                            let check = checkList.indexOf(i.alarmLogSeq)
                            if (check == -1) {
                              setCheckList([...checkList, i.alarmLogSeq])
                            } else {
                              let temp = [...checkList]
                              temp.splice(check, 1)
                              setCheckList(temp)
                            }
                          }}
                          className="ms-4 me-2"
                          src={checkList.indexOf(i.alarmLogSeq) != -1 ? checked_on : checked_off}
                        />
                      </td>
                      <td>{TYPE[i.type]}</td>
                      <td>{i.title}</td>
                      <td>{i.isAlarmPush}</td>
                      <td>{i.receiverName}</td>
                      <td>{moment(i.regDatetime).format("YYYY-MM-DD HH:MM")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination count={15} fn={pageFn} current={current} setCurrent={setCurrent} total={profileMessagesTotal} />
            </div>
          </div>
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default SignContainer(Message)
