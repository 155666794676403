import React from "react"
import img_exploring_right_arrow from "../../images/img_exploring_right_arrow.svg"
import UP from "../../images/UP.svg"
import DOWN from "../../images/DOWN.svg"
import KEEP from "../../images/KEEP.svg"
import rank_flag from "../../images/rank_flag.svg"

const RankItem = ({
  title = "",
  list = [
    {
      nickname: "Dumy",
      isMine: 1,
      totalTime: "07:15:47",
      ranking: 1,
    },
  ],
  onClick,
  type = "",
}) => {
  return (
    <div className="rank-box">
      <div>
        <a
          onClick={e => {
            e.preventDefault()
            onClick()
          }}
        >
          {title} <img src={img_exploring_right_arrow} alt="right arrow img" />
        </a>
        <div className="rank-list">
          {list.length != 0
            ? list.map((i, idx) => {
                if (idx < 10) {
                  return (
                    <div key={idx} className="rank-item">
                      <div className="text-box">
                        {i.isMine == 1 ? <img className="is-mine" src={rank_flag} alt="ismine img" /> : null}
                        <p className="ranking">{idx + 1}</p>
                        <p className="title">{i.title || i.nickname || i.tag || "nickname"}</p>
                      </div>
                      {type == "" ? (
                        <p className="time">{i.totalTime}</p>
                      ) : (
                        <div className="up-down">
                          <p className="fluctuation">{i.rankingFluctuation || "-"}</p>
                          <div className="img-wrapper">
                            <img src={i.rankingUpDown == "KEEP" ? KEEP : i.rankingUpDown == "UP" ? UP : DOWN}></img>
                          </div>
                        </div>
                      )}
                    </div>
                  )
                } else {
                  return <div key={idx}></div>
                }
              })
            : ["", "", "", "", "", "", "", "", "", ""].map((i, idx) => {
                return (
                  <div key={idx} className="rank-item">
                    <div>
                      <p>{idx + 1}</p>
                      <p></p>
                    </div>
                  </div>
                )
              })}
        </div>
      </div>
    </div>
  )
}

export default RankItem
