import api from "../utils/api"

// 회원 목록 조회
const getUserList = async data => {
  const result = await api.get(
    `/users?dateType=${data.dateType}&startDate=${data.startDate}&endDate=${data.endDate}&studentGroupSeqLevel1=${data.studentGroupSeqLevel1}&studentGroupSeqLevel2=${data.studentGroupSeqLevel2}&belongingType=${data.belongingType}&belongingSeq=${data.belongingSeq}&wordType=${data.wordType}&word=${data.word}&region3=${data.region3}&marketingType=${data.marketingType}&accumulatedStudyTime=${data.accumulatedStudyTime}&achievementRate=${data.achievementRate}&point=${data.point}&page=${data.page}&count=15`,
    {
      token: true,
      body: data,
    }
  )
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 회원 정보 조회
const getUserInfo = async data => {
  const result = await api.get(`/users/${data.userSeq}`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 회원 정보 학습 요약 조회
const getUserLearningInfo = async data => {
  const result = await api.get(`/users/${data.userSeq}/summaries`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 회원 만든 스터디 목록 조회
const getUserCreatedStudyList = async data => {
  const result = await api.get(
    `/users/${data.userSeq}/studies/make?startDate=${data.startDate}&endDate=${data.endDate}&keyword=${data.keyword}&page=${data.page}&count=15`,
    {
      token: true,
      body: data,
    }
  )
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 회원 참여 스터디 목록 조회
const getUserParticipatedStudyList = async data => {
  const result = await api.get(
    `/users/${data.userSeq}/studies/join?startDate=${data.startDate}&endDate=${data.endDate}&keyword=${data.keyword}&page=${data.page}&count=15`,
    {
      token: true,
      body: data,
    }
  )
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 회원 참여 스터디 목록 엑셀 다운로드
const getUserParticipatedStudyListExcel = async data => {}

// 회원 TO-DO 목록 조회
const getUserToDoList = async data => {
  const result = await api.get(
    `/users/${data.userSeq}/todos?startDate=${data.startDate}&endDate=${data.endDate}&keyword=${data.keyword}&page=${data.page}&count=15`,
    {
      token: true,
      body: data,
    }
  )
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 회원 TO-DO 목록 조회 엑셀 다운로드
const getUserToDoListExcel = async data => {}

// 회원 누적 공부시간 랭킹 조회
const getUserStudyTimeRankingList = async data => {
  const result = await api.get(`/users/${data.userSeq}/ranking/accumulated-study-time?page=${data.page}&count=15`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 회원 친구 목록 조회
const getUsersFriendList = async data => {
  const result = await api.get(`/users/${data.userSeq}/friends?page=${data.page}&count=15`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 탈퇴 회원 목록 조회
const getWithdrawalMemberList = async data => {
  const result = await api.get(
    `/withdraw/users?startDate=${data.startDate}&endDate=${data.endDate}&wordType=${data.wordType}&word=${data.word}&studentGroupSeqLevel1=${data.studentGroupSeqLevel1}&studentGroupSeqLevel2=${data.studentGroupSeqLevel2}&belongingType=${data.belongingType}&page=${data.page}&count=15`,
    {
      token: true,
      body: data,
    }
  )
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 탈퇴 회원 삭제
const delWithdrawalMember = async data => {
  const result = await api.delete(`/withdraw/users`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 회원 포인트 현황 요약
const getMemberPointInfo = async data => {
  const result = await api.get(`/users/${data.userSeq}/points/summary?seqType=${data.seqType}&page=${data.page}&count=10000`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 회원 포인트 현황 적립 목록
const getMemberPointAccumulated = async data => {
  const result = await api.get(`/users/${data.userSeq}/points/save?seqType=${data.seqType}&page=${data.page}&count=15`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 회원 포인트 현황 보상 목록
const getMemberPointRewarded = async data => {
  const result = await api.get(`/users/${data.userSeq}/points/reward?seqType=${data.seqType}&page=${data.page}&count=15`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 회원 포인트 현황 충전 목록
const getMemberPointCharged = async data => {
  const result = await api.get(`/users/${data.userSeq}/points/charge?seqType=${data.seqType}&page=${data.page}&count=15`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 회원 결제 현황 목록
const getMemberPointPaid = async data => {
  const result = await api.get(
    `/users/${data.userSeq}/pays?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&payType=${data.payType}&page=${data.page}&count=15`,
    {
      token: true,
      body: data,
    }
  )
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 회원 포인트 현황 적립 목록 Excel
const getMemberPointAccumulatedExcel = async data => {
  const result = await api.get(`/users/${data.userSeq}/points/save/excel/download?seqType=${data.seqType}&page=${data.page}&count=15`, {
    token: true,
    options: { responseType: "blob" },
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 회원 포인트 현황 보상 목록 Excel
const getMemberPointRewardedExcel = async data => {
  const result = await api.get(`/users/${data.userSeq}/points/reward/excel/download?seqType=${data.seqType}&page=${data.page}&count=15`, {
    token: true,
    options: { responseType: "blob" },
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 회원 포인트 현황 충전 목록 Excel
const getMemberPointChargedExcel = async data => {
  const result = await api.get(`/users/${data.userSeq}/points/charge/excel/download?seqType=${data.seqType}&page=${data.page}&count=15`, {
    token: true,
    options: { responseType: "blob" },
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}
// 회원 결제 현황  목록 Excel
const getMemberPointPaidExcel = async data => {
  const result = await api.get(
    `/users/${data.userSeq}/pays/excel/download?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&payType=${data.payType}&page=${data.page}&count=15`,
    {
      token: true,
      options: { responseType: "blob" },
    }
  )
  if (result) {
    return result.data
  } else {
    return false
  }
}
// 탈퇴 회원 목록 조회 Excel
const getWithdrawalMemberListExcel = async data => {
  const result = await api.get(
    `/withdraw/users/excel/download?startDate=${data.startDate}&endDate=${data.endDate}&wordType=${data.wordType}&word=${data.word}&studentGroupSeqLevel1=${data.studentGroupSeqLevel1}&studentGroupSeqLevel2=${data.studentGroupSeqLevel2}&belongingType=${data.belongingType}&page=${data.page}&count=15`,
    {
      token: true,
      options: { responseType: "blob" },
      // body: data,
    }
  )
  if (result) {
    return result.data
  } else {
    return false
  }
}
// 회원 친구 목록 조회
const getUsersFriendListExcel = async data => {
  const result = await api.get(`/users/${data.userSeq}/friends/excel/download?page=${data.page}&count=15`, {
    token: true,
    options: { responseType: "blob" },
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 회원 목록 조회 엑셀 다운로드
const getUserListExcel = async data => {
  const result = await api.get(
    `/users/excel/download?dateType=${data.dateType}&startDate=${data.startDate}&endDate=${data.endDate}&studentGroupSeqLevel1=${data.studentGroupSeqLevel1}&studentGroupSeqLevel2=${data.studentGroupSeqLevel2}&belongingType=${data.belongingType}&belongingSeq=${data.belongingSeq}&wordType=${data.wordType}&word=${data.word}&region3=${data.region3}&marketingType=${data.marketingType}&accumulatedStudyTime=${data.accumulatedStudyTime}&achievementRate=${data.achievementRate}&point=${data.point}&page=${data.page}&count=15`,
    {
      token: true,
      options: { responseType: "blob" },
    }
  )
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 회원 만든 스터디 목록 엑셀 다운로드
const getUserCreatedStudyListExcel = async data => {
  const result = await api.get(
    `/users/${data.userSeq}/studies/join/excel/download?startDate=${data.startDate}&endDate=${data.endDate}&keyword=${data.keyword}&page=${data.page}&count=15`,
    {
      token: true,
      options: { responseType: "blob" },
    }
  )
  if (result) {
    return result.data
  } else {
    return false
  }
}
// 회원 만든 todo 목록 엑셀 다운로드
const getUserCreatedTodoListExcel = async data => {
  const result = await api.get(
    `/users/${data.userSeq}/todos/excel/download?startDate=${data.startDate}&endDate=${data.endDate}&keyword=${data.keyword}&page=${data.page}&count=15`,
    {
      token: true,
      options: { responseType: "blob" },
    }
  )
  if (result) {
    return result.data
  } else {
    return false
  }
}
// 회원 누적 공부시간 랭킹 엑셀 다운로드
const getUserStudyTimeRankingListExcel = async data => {
  const result = await api.get(`/users/${data.userSeq}/ranking/accumulated-study-time/excel/download?page=${data.page}&count=15`, {
    token: true,
    options: { responseType: "blob" },
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}
export default {
  getUserList,
  getUserListExcel,
  getUserInfo,
  getUserLearningInfo,
  getUserCreatedStudyList,
  getUserCreatedStudyListExcel,
  getUserParticipatedStudyList,
  getUserParticipatedStudyListExcel,
  getUserToDoList,
  getUserToDoListExcel,
  getUserStudyTimeRankingList,
  getUserStudyTimeRankingListExcel,
  getUsersFriendList,
  getUsersFriendListExcel,
  getWithdrawalMemberList,
  delWithdrawalMember,
  getMemberPointInfo,
  getMemberPointAccumulated,
  getMemberPointRewarded,
  getMemberPointCharged,
  getMemberPointPaid,
  getMemberPointAccumulatedExcel,
  getMemberPointRewardedExcel,
  getMemberPointChargedExcel,
  getMemberPointPaidExcel,
  getWithdrawalMemberListExcel,
  getUserCreatedTodoListExcel,
}
