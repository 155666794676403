/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect } from "react"

import "swiper/scss"

import moment from "moment"

import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import TopInfo from "../../components/common/TopInfo"
import CalendarComponent from "../../components/Calendar"
import Pagination from "../../components/common/pagination"

import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import download from "../../images/download.svg"
import icon_calendar from "../../images/ion_calendar-number-outline.svg"

import OperationContainer from "../../containers/OperationContainer"
import { downExcel, popupFn } from "../../utils/util"

import PrivateRoute from "../../components/privateRoute"

const Keyword = props => {
  const { delKeyword, getKeywordXlDown, getKeywordList, keywordListEmpty, keywordListTotalEmpty, keywordList, keywordListTotal } = props
  const [calendarPopup, setCalendarPopup] = useState(false)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [date, setDate] = useState(moment())
  const [popupType, setPopupType] = useState(false)
  const [keyword, setKeyword] = useState("")

  const [checkList, setCheckList] = useState([])

  const [current, setCurrent] = useState(1)
  const searchDataFn = async () => {
    if (startDate > endDate) return false
    let temp = {
      dateStart: startDate.format("YYYY-MM-DD"),
      dateEnd: endDate.format("YYYY-MM-DD"),
      tag: keyword,
      page: 0,
      count: 15,
    }
    let result = await getKeywordList(temp)
    if (result) {
      setCurrent(1)
      setCheckList([])
    }
  }
  const pageFn = async num => {
    if (startDate > endDate) return false
    let temp = {
      dateStart: startDate.format("YYYY-MM-DD"),
      dateEnd: endDate.format("YYYY-MM-DD"),
      tag: keyword,
      page: num - 1,
      count: 15,
    }
    let result = await getKeywordList(temp)
    if (result) {
      setCheckList([])
    }
  }
  const delFn = async () => {
    if (checkList.length < 1) return false
    let temp = { tags: checkList }
    let result = await delKeyword(temp)
    if (result) {
      popupFn("삭제되었습니다.")
      searchDataFn()
    }
  }
  const xlDownFn = async () => {
    let temp = {
      dateStart: startDate.format("YYYY-MM-DD"),
      dateEnd: endDate.format("YYYY-MM-DD"),
      tag: keyword,
    }
    let result = await getKeywordXlDown(temp)
    if (result) {
      await downExcel({ result: result, fileName: "키워드 목록" })
    }
  }
  useEffect(() => {
    return () => {
      keywordListEmpty()
      keywordListTotalEmpty()
    }
  }, [])
  return (
    <PrivateRoute>
      <Layout>
        <SEO title="키워드 관리" />
        <TopInfo title={["키워드 관리", "운영관리", "키워드 관리"]} />

        <div className="operation-learnerType-container">
          <div style={{ border: "1px solid #E1E1E1" }} className="operation-learnerType-table">
            <div className="operation-learnerType-table-name">
              <div>조회기간</div>
            </div>
            <div className="operation-learnerType-table-contents justify-content-between">
              <div className="fgb-date-form ms-4">
                <img src={icon_calendar} />
                <div
                  className="fgb-date-input"
                  onClick={() => {
                    setPopupType(false)
                    setCalendarPopup(true)
                  }}
                >
                  <p>{startDate.isValid() ? startDate.format("YYYY.MM.DD") : "시작일"}</p>
                </div>
                {calendarPopup && (
                  <div className="calendar-popup-back">
                    <div className="calendar-popup-div">
                      <CalendarComponent
                        date={date}
                        setDate={setDate}
                        selectedDate={popupType == false ? startDate : endDate}
                        setSelectedDate={popupType == false ? setStartDate : setEndDate}
                        popup={setCalendarPopup}
                      />
                    </div>
                  </div>
                )}
                <p>~</p>
                <img src={icon_calendar} />

                <div
                  className="fgb-date-input"
                  onClick={() => {
                    setPopupType(true)
                    setCalendarPopup(true)
                  }}
                >
                  <p>{endDate.isValid() ? endDate.format("YYYY.MM.DD") : "종료일"}</p>
                </div>
              </div>
            </div>
            <div className="operation-learnerType-table-name ms-4">
              <div>키워드 </div>
            </div>
            <div className="d-flex align-items-center">
              <input value={keyword} onChange={e => setKeyword(e.target.value)} type="text" className="fgb-input ms-3"></input>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <div onClick={searchDataFn} className="btn btn-primary font-white fgb-btn-l">
              조회
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-4">
            <p>{keywordListTotal.toLocaleString()}건</p>
            <div className="d-flex justify-content-end">
              <div onClick={delFn} className="btn btn-primary font-white reverse fgb-btn-ms clear-button me-2">
                선택 삭제
              </div>
              <div onClick={xlDownFn} className="btn fgb-btn-m fgb-btn-grean me-2">
                <img src={download} />
                <p>엑셀 다운로드</p>
              </div>
            </div>
          </div>
          <div className="operation-learnerType-list-table mt-3">
            <div className="fgb-table-div">
              <table className={`fgb-table mb-4`}>
                <thead>
                  <tr className={`fgb-table-title-tr`}>
                    <td>
                      <img
                        onClick={() => {
                          if (checkList.length == keywordList.length) {
                            setCheckList([])
                          } else {
                            let temp = []
                            for (let i of keywordList) {
                              temp = [...temp, i.tag]
                            }
                            setCheckList(temp)
                          }
                        }}
                        className="ms-4 me-2"
                        src={checkList.length == keywordList.length && keywordList.length > 0 ? checked_on : checked_off}
                      />
                    </td>
                    {["순위", "키워드", "총 사용 횟수(건)", "스터디 스케줄(건)", "TO-DO 스케줄(건)", "컨텐츠(건)"].map((i, idx) => (
                      <td key={idx}>{i}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {keywordList.map((i, idx) => (
                    <tr className={`fgb-table-tr `} key={idx}>
                      <td>
                        <img
                          onClick={() => {
                            let check = checkList.indexOf(i.tag)
                            console.log(check)
                            if (check == -1) {
                              setCheckList([...checkList, i.tag])
                            } else {
                              let temp = [...checkList]
                              temp.splice(check, 1)
                              setCheckList(temp)
                            }
                          }}
                          className="ms-4 me-2"
                          src={checkList.indexOf(i.tag) != -1 ? checked_on : checked_off}
                        />
                      </td>
                      <td>{i.ranking}</td>
                      <td>{i.tag}</td>
                      <td>{i.tagTotalCount}</td>
                      <td>{i.tagStudyCount}</td>
                      <td>{i.tagTodoCount}</td>
                      <td>{i.tagContentsCount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination count={15} fn={pageFn} current={current} setCurrent={setCurrent} total={keywordListTotal} />
            </div>
          </div>
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default OperationContainer(Keyword)
