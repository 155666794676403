import api from "../utils/api"

//학습자 그룹 스터디 개설 현황
const getStatisticsStudentGroupStudy = async data => {
  const result = await api.get(`/statistics/student-group-study?month=${data}`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//시간대별 이용 현황
const getStatisticsSignInTime = async data => {
  const result = await api.get(`/statistics/sign-in-time?month=${data}`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//회원가입 현황
const getStatisticsCustomer = async data => {
  const result = await api.get(`/statistics/customer-signup?month=${data}`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//내 계정정보 put edit
// const putAdminsProfile = async data => {
//   const result = await api.put(`/admins/profile`, { token: true, body: data })
//   if (result) {
//     return result.data
//   } else return false
// }

// admin로그인 회원가입
// const postSignIn = async data => {
//   const result = await api.post(`/signin`, { token: false, body: data })
//   if (result) {
//     return result.data
//   } else return false
// }

export default {
  getStatisticsStudentGroupStudy,
  getStatisticsSignInTime,
  getStatisticsCustomer,
}
