import { navigate } from "gatsby-link"
import { createAction, handleActions } from "redux-actions"
import OperationApis from "../../apis/OperationApis"

const SET_TARGET_PARTNER_INFO = "operation/SET_TARGET_PARTNER_INFO"
const SET_STUDENT_GROUPS = "operation/SET_STUDENT_GROUPS"
const SET_CATEGORY_STUDENT_GROUPS = "operation/SET_CATEGORY_STUDENT_GROUPS"
const SET_CATEGORY_STUDENT_GROUPS_TOTAL = "operation/SET_CATEGORY_STUDENT_GROUPS_TOTAL"
const SET_CATEGORY_STUDENT_GROUP = "operation/SET_CATEGORY_STUDENT_GROUP"
const SET_PARTNERS_LIST = "operation/SET_PARTNERS_LIST"
const SET_PARTNERS_LIST_TOTAL = "operation/SET_PARTNERS_LIST_TOTAL"
const SET_BELONGINGS = "operation/SET_BELONGINGS"
const SET_KETWORD_LIST = "operation/SET_KETWORD_LIST"
const SET_KETWORD_LIST_TOTAL = "operation/SET_KETWORD_LIST_TOTAL"
const SET_BRAND_LIST = "operation/SET_BRAND_LIST"
const SET_CATEGORY_LIST = "operation/SET_CATEGORY_LIST"
const CATEGORY_CONTENTS_LIST = "operation/CATEGORY_CONTENTS_LIST"
const SET_NOTICE_LIST = "operation/SET_NOTICE_LIST"
const SET_NOTICE_LIST_TOTAL = "operation/SET_NOTICE_LIST_TOTAL"
const SET_NOTICE_TARGET = "operation/SET_NOTICE_TARGET"
const SET_POINT_RULES = "operation/SET_POINT_RULES"
const SET_POINT_RULE = "operation/SET_POINT_RULE"
const SET_LEARN_ATTITUDES_LIST = "operation/SET_LEARN_ATTITUDES_LIST"
const SET_LEARN_ATTITUDE = "operation/SET_LEARN_ATTITUDE"
const SET_AI_SENSITIVES = "operation/SET_AI_SENSITIVES"
const SET_AI_SENSITIVE = "operation/SET_AI_SENSITIVE"

export const setTargetPartnerInfo = createAction(SET_TARGET_PARTNER_INFO)
export const setStudentGroups = createAction(SET_STUDENT_GROUPS)
export const setCategoryStudentGroups = createAction(SET_CATEGORY_STUDENT_GROUPS)
export const setCategoryStudentGroupsTotal = createAction(SET_CATEGORY_STUDENT_GROUPS_TOTAL)
export const setCategoryStudentGroup = createAction(SET_CATEGORY_STUDENT_GROUP)
export const setPartnersList = createAction(SET_PARTNERS_LIST)
export const setPartnersListTotal = createAction(SET_PARTNERS_LIST_TOTAL)
export const setBelongings = createAction(SET_BELONGINGS)
export const setKeywordList = createAction(SET_KETWORD_LIST)
export const setKeywordListTotal = createAction(SET_KETWORD_LIST_TOTAL)
export const setBrandList = createAction(SET_BRAND_LIST)
export const setCategoryList = createAction(SET_CATEGORY_LIST)
export const setCategoryContentsList = createAction(CATEGORY_CONTENTS_LIST)
export const setNoticeList = createAction(SET_NOTICE_LIST)
export const setNoticeListTotal = createAction(SET_NOTICE_LIST_TOTAL)
export const setNoticeTarget = createAction(SET_NOTICE_TARGET)
export const setPointRules = createAction(SET_POINT_RULES)
export const setPointRule = createAction(SET_POINT_RULE)
export const setLearnAttitudesList = createAction(SET_LEARN_ATTITUDES_LIST)
export const setAISensitives = createAction(SET_AI_SENSITIVES)
export const setLearnAttitude = createAction(SET_LEARN_ATTITUDE)
export const setAISensitive = createAction(SET_AI_SENSITIVE)

const initState = {
  targetPartnerInfo: {},
  studentGroups: [],
  categoryStudentGroups: [],
  categoryStudentGroupsTotal: 0,
  categoryStudentGroup: {},
  partnersList: [],
  partnersListTotal: 0,
  belongings: [],
  keywordList: [],
  keywordListTotal: 0,
  brandList: [],
  categoryList: [],
  categoryContentsList: [],
  noticeList: [],
  noticeListTotal: 0,
  noticeTarget: {},
  pointRules: [],
  pointRule: {},
  learnAttitudesList: [],
  learnAttitude: {},
  aiList: [],
  ai: {},
}

export default handleActions(
  {
    [SET_TARGET_PARTNER_INFO]: (state, action) => ({
      ...state,
      targetPartnerInfo: action.payload,
    }),
    [SET_STUDENT_GROUPS]: (state, action) => ({
      ...state,
      studentGroups: action.payload,
    }),
    [SET_CATEGORY_STUDENT_GROUPS]: (state, action) => ({
      ...state,
      categoryStudentGroups: action.payload,
    }),

    [SET_CATEGORY_STUDENT_GROUPS_TOTAL]: (state, action) => ({
      ...state,
      categoryStudentGroupsTotal: action.payload,
    }),
    [SET_CATEGORY_STUDENT_GROUP]: (state, action) => ({
      ...state,
      categoryStudentGroup: action.payload,
    }),
    [SET_PARTNERS_LIST]: (state, action) => ({
      ...state,
      partnersList: action.payload,
    }),
    [SET_PARTNERS_LIST_TOTAL]: (state, action) => ({
      ...state,
      partnersListTotal: action.payload,
    }),
    [SET_BELONGINGS]: (state, action) => ({
      ...state,
      belongings: action.payload,
    }),
    [SET_KETWORD_LIST]: (state, action) => ({
      ...state,
      keywordList: action.payload,
    }),
    [SET_KETWORD_LIST_TOTAL]: (state, action) => ({
      ...state,
      keywordListTotal: action.payload,
    }),
    [SET_BRAND_LIST]: (state, action) => ({
      ...state,
      brandList: action.payload,
    }),
    [SET_CATEGORY_LIST]: (state, action) => ({
      ...state,
      categoryList: action.payload,
    }),
    [CATEGORY_CONTENTS_LIST]: (state, action) => ({
      ...state,
      categoryContentsList: action.payload,
    }),
    [SET_NOTICE_LIST]: (state, action) => ({
      ...state,
      noticeList: action.payload,
    }),
    [SET_NOTICE_LIST_TOTAL]: (state, action) => ({
      ...state,
      noticeListTotal: action.payload,
    }),
    [SET_NOTICE_TARGET]: (state, action) => ({
      ...state,
      noticeTarget: action.payload,
    }),
    [SET_POINT_RULES]: (state, action) => ({
      ...state,
      pointRules: action.payload,
    }),
    [SET_POINT_RULE]: (state, action) => ({
      ...state,
      pointRule: action.payload,
    }),
    [SET_LEARN_ATTITUDES_LIST]: (state, action) => ({
      ...state,
      learnAttitudesList: action.payload,
    }),
    [SET_AI_SENSITIVES]: (state, action) => ({
      ...state,
      aiList: action.payload,
    }),
    [SET_LEARN_ATTITUDE]: (state, action) => ({
      ...state,
      learnAttitude: action.payload,
    }),
    [SET_AI_SENSITIVE]: (state, action) => ({
      ...state,
      ai: action.payload,
    }),
  },
  initState
)
/* action api function */

//운영관리
export const getPartnersAction = param => async dispatch => {
  const result = await OperationApis.getPartners(param)
  if (result.code == 0) {
    dispatch(setTargetPartnerInfo(result.data.partner))
    return true
  } else {
    dispatch(setTargetPartnerInfo({}))
    return false
  }
}

//학습자 그룹 get
export const getStudentGroupsAction = param => async dispatch => {
  const result = await OperationApis.getStudentGroups(param)
  if (result.code == 0) {
    dispatch(setStudentGroups(result.data.studentGroups))
    return true
  } else {
    dispatch(setStudentGroups([]))
    return false
  }
}
//학습자 그룹 유형 목록 get
export const getCategoryStudentGroupsAction = param => async dispatch => {
  const result = await OperationApis.getCategoryStudentGroups(param)
  if (result.code == 0) {
    dispatch(setCategoryStudentGroups(result.data.studentGroups))
    dispatch(setCategoryStudentGroupsTotal(result.data.totalCount))
    return true
  } else {
    dispatch(setCategoryStudentGroups([]))
    dispatch(setCategoryStudentGroupsTotal(0))
    return false
  }
}
//학습자 그룹 유형 get
export const getCategoryStudentGroupAction = param => async dispatch => {
  const result = await OperationApis.getCategoryStudentGroup(param)
  if (result.code == 0) {
    dispatch(setCategoryStudentGroup(result.data.studentGroupsDepth1))
    return true
  } else {
    dispatch(setCategoryStudentGroup({}))
    return false
  }
}
//학습자 그룹 유형 수정 put
export const putCategoryStudentGroupsAction = param => async dispatch => {
  const result = await OperationApis.putCategoryStudentGroups(param)
  if (result.code == 0) {
    return true
  } else {
    return false
  }
}
//학습자 그룹 유형 추가 post
export const postCategoryStudentGroupsAction = param => async dispatch => {
  const result = await OperationApis.postCategoryStudentGroups(param)
  if (result.code == 0) {
    return true
  } else {
    return false
  }
}
//학습자 그룹 유형 삭제
export const delCategoryStudentGroupsAction = param => async dispatch => {
  const result = await OperationApis.delCategoryStudentGroups(param)
  if (result.code == 0) {
    return true
  } else {
    return false
  }
}
//제휴사 목록 get
export const getPartnersListAction = param => async dispatch => {
  const result = await OperationApis.getPartnersList(param)
  if (result.code == 0) {
    dispatch(setPartnersList(result.data.partners))
    dispatch(setPartnersListTotal(result.data.totalCount))
    return true
  } else {
    dispatch(setPartnersList([]))
    dispatch(setPartnersListTotal(0))
    return false
  }
}
//제휴사 목록 get
export const getPartnersListExcelAction = param => async dispatch => {
  const result = await OperationApis.getPartnersListExcel(param)
  if (result) {
    return result
  } else {
    return false
  }
}
//브랜드 목록 get
export const getBelongingsAction = param => async dispatch => {
  const result = await OperationApis.getBelongings(param)
  if (result.code == 0) {
    dispatch(setBelongings(result.data.belongings))
    return true
  } else {
    dispatch(setBelongings([]))

    return false
  }
}

//제휴사 정보 권한 put
export const putPartnersAction = param => async dispatch => {
  const result = await OperationApis.putPartners(param)
  if (result.code == 0) {
    return true
  } else {
    return false
  }
}
//제휴사 정보 권한 del
export const delPartnersAction = param => async dispatch => {
  const result = await OperationApis.delPartners(param)
  if (result.code == 0) {
    return true
  } else {
    return false
  }
}
//키워드 목록 get
export const getKeywordListAction = param => async dispatch => {
  const result = await OperationApis.getKeywordList(param)
  if (result.code == 0) {
    dispatch(setKeywordList(result.data.keywordManagements))
    dispatch(setKeywordListTotal(result.data.totalCount))
    return true
  } else {
    dispatch(setKeywordList([]))
    dispatch(setKeywordListTotal(0))

    return false
  }
}
//제휴사 정보 권한 del
export const delKeywordAction = param => async dispatch => {
  const result = await OperationApis.delKeyword(param)
  if (result.code == 0) {
    return true
  } else {
    return false
  }
}
//키워드관리 내역 목록 xl
export const getKeywordXlDownAction = param => async dispatch => {
  const result = await OperationApis.getKeywordXlDown(param)
  if (result) {
    return result
  } else {
    return false
  }
}
//브랜드 목록
export const getBrandCategoryAction = param => async dispatch => {
  const result = await OperationApis.getBrandCategory(param)
  if (result.code == 0) {
    dispatch(setBrandList(result.data.brandNames))
    dispatch(setCategoryList(result.data.contentCategories))
    return true
  } else {
    dispatch(setBrandList([]))
    dispatch(setCategoryList([]))

    return false
  }
}
//카테고리 컨텐츠 목록
export const getCategoryContentsAction = param => async dispatch => {
  const result = await OperationApis.getCategoryContents(param)
  if (result.code == 0) {
    dispatch(setCategoryContentsList(result.data.contentCategories))
    return true
  } else {
    dispatch(setCategoryContentsList([]))

    return false
  }
}
//컨텐츠 카테고리 삭제
export const delCategoryContentsAction = param => async dispatch => {
  const result = await OperationApis.delCategoryContents(param)
  if (result.code == 0) {
    return true
  } else {
    return false
  }
}
//컨텐츠 카테고리 추가
export const postCategoryContentsAction = param => async dispatch => {
  const result = await OperationApis.postCategoryContents(param)
  if (result.code == 0) {
    return true
  } else {
    return false
  }
}
//컨텐츠 카테고리 엑셀 업로드
export const postCategoryContentsExcelUploadAction = param => async dispatch => {
  const result = await OperationApis.postCategoryContentsExcelUpload(param)
  if (result.code == 0) {
    return true
  } else {
    return false
  }
}
//공지사항 관리
//공지사항 목록 GET
export const getNotificationsAction = param => async dispatch => {
  const result = await OperationApis.getNotifications(param)
  if (result.code == 0) {
    dispatch(setNoticeList(result.data.notifications))
    dispatch(setNoticeListTotal(result.data.totalCount))
    return true
  } else {
    dispatch(setNoticeList([]))
    dispatch(setNoticeListTotal(0))

    return false
  }
}
//공지사항 목록 Del
export const dalNotificationsAction = param => async dispatch => {
  const result = await OperationApis.delNotifications(param)
  if (result.code == 0) {
    return true
  } else {
    return false
  }
}
//공지사항 상세 GET
export const getTargetNotificationsAction = param => async dispatch => {
  const result = await OperationApis.getTargetNotifications(param)
  if (result.code == 0) {
    dispatch(setNoticeTarget(result.data.notification))
    return true
  } else {
    dispatch(setNoticeTarget({}))
    return false
  }
}
//공지사항 상세 Put
export const putTargetNotificationsAction = param => async dispatch => {
  const result = await OperationApis.putTargetNotifications(param)
  if (result.code == 0) {
    return true
  } else {
    return false
  }
}
//공지사항 상세 Put
export const postTargetNotificationsAction = param => async dispatch => {
  const result = await OperationApis.postTargetNotifications(param)
  if (result.code == 0) {
    return true
  } else {
    return false
  }
}
//렛미업 포인트 관리
//렛미업 포인트 관리 목록 조회
export const getPointRulesAction = param => async dispatch => {
  const result = await OperationApis.getPointRules(param)
  if (result.code == 0) {
    dispatch(setPointRules(result.data.pointRules))
    return true
  } else {
    dispatch(setPointRules([]))
    return false
  }
}
//렛미업 포인트 관리 정보 조회
export const getPointRuleAction = param => async dispatch => {
  const result = await OperationApis.getPointRule(param)
  if (result.code == 0) {
    dispatch(setPointRule(result.data.pointRule))
    return true
  } else {
    dispatch(setPointRule({}))
    return false
  }
}
//렛미업 포인트 관리 정보 수정
export const putPointRuleAction = param => async dispatch => {
  const result = await OperationApis.putPointRule(param)
  if (result.code == 0) {
    return true
  } else {
    return false
  }
}
//AI학습규칙 관리
//학습규칙 목록 조회
export const getRuleLearnAttitudesAction = param => async dispatch => {
  const result = await OperationApis.getRuleLearnAttitudes(param)
  if (result.code == 0) {
    dispatch(setLearnAttitudesList(result.data.learnAttitudeRules))
    return true
  } else {
    dispatch(setLearnAttitudesList([]))
    return false
  }
}
//학습규칙 정보 조회
export const getRuleLearnAttitudeAction = param => async dispatch => {
  const result = await OperationApis.getRuleLearnAttitude(param)
  if (result.code == 0) {
    dispatch(setLearnAttitude(result.data.learnAttitudeRule))
    return true
  } else {
    dispatch(setLearnAttitude({}))
    return false
  }
}
//학습규칙 정보 수정
export const putRuleLearnAttitudeAction = param => async dispatch => {
  const result = await OperationApis.putRuleLearnAttitude(param)
  if (result.code == 0) {
    return true
  } else {
    return false
  }
}
//ai 목록 조회
export const getRuleAISenstivesAction = param => async dispatch => {
  const result = await OperationApis.getRuleAISenstives(param)
  if (result.code == 0) {
    dispatch(setAISensitives(result.data.aiSensitiveRules))
    return true
  } else {
    dispatch(setAISensitives([]))
    return false
  }
}
//AI 정보 조회
export const getRuleAISenstiveAction = param => async dispatch => {
  const result = await OperationApis.getRuleAISenstive(param)
  if (result.code == 0) {
    dispatch(setAISensitive(result.data.aiSensitiveRule))
    return true
  } else {
    dispatch(setAISensitive({}))
    return false
  }
}
//AI 정보 수정
export const putRuleAISensitiveAction = param => async dispatch => {
  const result = await OperationApis.putRuleAISensitive(param)
  if (result.code == 0) {
    return true
  } else {
    return false
  }
}
// 관리자 유형관리 수정
// export const putAdminsTypeAction = param => async dispatch => {
//   const result = await MasterApis.putAdminsType(param)
//   if (result.code == 0) {
//     return true
//   } else return false
// }

//어드민 삭제
// export const delAdminsAction = param => async dispatch => {
//   const result = await MasterApis.delAdmins(param)
//   if (result.code == 0) {
//     return true
//   } else return false
// }

// //권한 변경 요청
// export const postAdminsProfileAuthAction = param => async dispatch => {
//   const result = await SignApis.postAdminsProfileAuth(param)
//   if (result.code == 0) {
//     return true
//   } else return false
// }
