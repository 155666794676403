import { combineReducers } from "redux"
import sign from "./sign"
import popup from "./popup"
import config from "./config"
import navi from "./navi"
import recommend from "./recommend"
import member from "./member"
import common from "./common"
import adminMain from "./adminMain"
import master from "./master"
import schedule from "./schedule"
import operation from "./operation"
import affiliatesMain from "./affiliatesMain"
import service from "./service"
import payments from "./payments"
import exhibition from "./exhibition"

const rootReducer = combineReducers({
  master,
  sign,
  popup,
  config,
  navi,
  recommend,
  member,
  common,
  schedule,
  adminMain,
  operation,
  affiliatesMain,
  service,
  payments,
  exhibition,
})

export default rootReducer
