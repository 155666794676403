import { navigate } from "gatsby-link"
import { createAction, handleActions } from "redux-actions"
import ServiceApis from "../../apis/ServiceApis"
import { popupFn } from "../../utils/util"

const GET_DECORATION_ITEM_LIST = "service/GET_DECORATION_ITEM_LIST"
const GET_DECORATION_ITEM_LIST_EXCEL = "service/GET_DECORATION_ITEM_LIST_EXCEL"
const GET_DECORATION_ITEM_INFO = "service/GET_DECORATION_ITEM_INFO"
const PUT_DECORATION_ITEM = "service/PUT_DECORATION_ITEM"
const POST_DECORATION_ITEM = "service/POST_DECORATION_ITEM"
const GET_CONTENT_LIST = "service/GET_CONTENT_LIST"
const GET_CONTENT_LIST_EXCEL = "service/GET_CONTENT_LIST_EXCEL"
const GET_CONTENT_INFO = "service/GET_CONTENT_INFO"
const POST_CONTENT = "service/POST_CONTENT"
const PUT_CONTENT = "service/PUT_SERVICE"

export const getDecorationItemList = createAction(GET_DECORATION_ITEM_LIST)
export const getDecorationItemListExcel = createAction(GET_DECORATION_ITEM_LIST_EXCEL)
export const getDecorationItemInfo = createAction(GET_DECORATION_ITEM_INFO)
export const putDecorationItem = createAction(PUT_DECORATION_ITEM)
export const postDecorationItem = createAction(POST_DECORATION_ITEM)
export const getContentList = createAction(GET_CONTENT_LIST)
export const getContentListExcel = createAction(GET_CONTENT_LIST_EXCEL)
export const getContentInfo = createAction(GET_CONTENT_INFO)
export const postContent = createAction(POST_CONTENT)
export const putContent = createAction(PUT_CONTENT)

const initState = {
  decorationItemList: undefined,
  decorationItemInfo: undefined,
  contentList: undefined,
  contentInfo: undefined,
}

export default handleActions(
  {
    [GET_DECORATION_ITEM_LIST]: (state, action) => ({
      ...state,
      decorationItemList: action.payload,
    }),
    [GET_DECORATION_ITEM_INFO]: (state, action) => ({
      ...state,
      decorationItemInfo: action.payload,
    }),
    [GET_CONTENT_LIST]: (state, action) => ({
      ...state,
      contentList: action.payload,
    }),
    [GET_CONTENT_INFO]: (state, action) => ({
      ...state,
      contentInfo: action.payload,
    }),
  },
  initState
)

// 꾸미기 아이템 목록 조회
export const getDecorationItemListAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getDecorationItemList())
  } else {
    const result = await ServiceApis.getDecorationItemList(param)
    if (result.code === 0) {
      dispatch(getDecorationItemList(result.data))
      return true
    } else {
      return false
    }
  }
}

// 꾸미기 아이템 목록 조회 엑셀 다운로드
export const getDecorationItemListExcelAction = param => async dispatch => {
  const result = await ServiceApis.getDecorationItemListExcel(param)
  if (result) {
    return result
  } else {
    return false
  }
}

// 꾸미기 아이템 정보 조회
export const getDecorationItemInfoAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getDecorationItemInfo())
  } else {
    const result = await ServiceApis.getDecorationItemInfo(param)
    if (result.code === 0) {
      dispatch(getDecorationItemInfo(result.data))
      return true
    } else {
      return false
    }
  }
}

// 꾸미기 아이템 수정
export const putDecorationItemAction = param => async dispatch => {
  const result = await ServiceApis.putDecorationItem(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

// 꾸미기 아이템 추가
export const postDecorationItemAction = param => async dispatch => {
  const result = await ServiceApis.postDecorationItem(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

// 컨텐츠 목록 조회
export const getContentListAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getContentList())
  } else {
    const result = await ServiceApis.getContentList(param)
    if (result.code === 0) {
      // if (result.data.contents.length == 0) {
      //   popupFn("조회된 데이터가 없습니다.")
      // }
      dispatch(getContentList(result.data))
      return true
    } else {
      return false
    }
  }
}

// 컨텐츠 목록 엑셀 다운로드
export const getContentListExcelAction = param => async dispatch => {
  const result = await ServiceApis.getContentListExcel(param)
  if (result) {
    return result
  } else {
    return false
  }
}

// 컨텐츠 정보 조회
export const getContentInfoAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getContentInfo())
  } else {
    const result = await ServiceApis.getContentInfo(param)
    if (result.code === 0) {
      dispatch(getContentInfo(result.data))
      return true
    } else {
      return false
    }
  }
}

// 컨텐츠 등록
export const postContentAction = param => async dispatch => {
  const result = await ServiceApis.postContent(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

// 컨텐츠 수정
export const putContentAction = param => async dispatch => {
  const result = await ServiceApis.putContent(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}
