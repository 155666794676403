import api from "../utils/api"

//운영자 권한 조회
const getAdminMaster = async data => {
  const result = await api.get(
    `/admins?wordType=${data.wordType}&word=${data.word}&adminTypeSeq=${data.adminTypeSeq}&menu1Seq=${data.menu1Seq}&menu2Seq=${data.menu2Seq}&dateType=${data.dateType}&startDate=${data.startDate}&endDate=${data.endDate}`,
    { token: true }
  )
  if (result) {
    return result.data
  } else return false
}
//관리자 유형 목록
const getAdminType = async data => {
  const result = await api.get(`/admin/types`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//관리자 권한 1뎁스 메뉴
const getAdminMeue1 = async data => {
  const result = await api.get(`/menus/1`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//관리자 권한 1뎁스 메뉴
const getAdminMeue2 = async data => {
  const result = await api.get(`/menus/2?menu1Seq=${data}`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//관리자 개인 정보
const getAdminsInfo = async data => {
  const result = await api.get(`/admins/${data}`, { token: true })
  if (result) {
    return result.data
  } else return false
}
// 관리자 권한 수정
const putAdminsPermission = async data => {
  const result = await api.put(`/admins/${data.adminSeq}`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
// 관리자 권한 수정
const delAdmins = async data => {
  const result = await api.delete(`/admins`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
// 관리자 유형관리 수정
const putAdminsType = async data => {
  const result = await api.put(`/admin/types`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}

// admin로그인 회원가입
// const postSignIn = async data => {
//   const result = await api.post(`/signin`, { token: false, body: data })
//   if (result) {
//     return result.data
//   } else return false
// }

export default {
  getAdminMaster,
  getAdminType,
  getAdminMeue1,
  getAdminMeue2,
  getAdminsInfo,
  putAdminsPermission,
  delAdmins,
  putAdminsType,
}
