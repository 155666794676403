import React, { useState } from "react"
import LayerPopupLayout from "../LayerPopupLayout"
import SignContainer from "../../containers/SignContainer"
import Validator from "validator"
import { debounce } from "lodash"
import { dataCheckFn, popupFn } from "../../utils/util"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"
import CloseBtn from "../../images/Close_Button.svg"
import img_popup_close_gray from "../../images/img_popup_close_gray.svg"
import upload from "../../images/upload.svg"
import { useEffect } from "react"
import ModalPreviewPopup from "../../popups/ModalPreviewPopup"

import ExhibitionContainer from "../../containers/ExhibitionContainer"
const AddPromotionPopup = props => {
  const {
    open,
    onClose,
    postUploadFiles,
    putPromotionAds,
    postUploadFile,
    postPromotionAds,
    promotionAdEmpty,
    promotionAd,
    searchFn = () => {},
  } = props

  const [checkList, setCheckList] = useState([])
  const [checkIdx, setCheckIdx] = useState([])
  const [checkListMo, setCheckListMo] = useState([])
  const [checkIdxMo, setCheckIdxMo] = useState([])

  const [type, setType] = useState("LETMEUP")
  const [title, setTitle] = useState("")
  const [platform, setPlatform] = useState("ALL")
  const [bannerType, setBannerType] = useState("HORIZONTAL")
  const [bannerType2, setBannerType2] = useState("TOP")
  const [isVisible, setiIsvisible] = useState(false)

  const [openPreview, setOpenPreview] = useState({ contents: {}, open: "false" })
  const closePopup = e => {
    onClose()
  }

  const saveFn = debounce(async () => {
    let check = false
    //빈 배열
    if (platform == "ALL" && (checkList.length == 0 || checkListMo.length == 0)) {
      popupFn("전체 플랫폼 이미지를 채워주세요.")
      return false
    }
    if (platform == "PC" && checkList.length == 0) {
      popupFn("PC 플랫폼 이미지를 채워주세요.")
      return false
    }
    if (platform == "MOBILE" && checkListMo.length == 0) {
      popupFn("MOBILE 플랫폼 이미지를 채워주세요.")
      return false
    }
    //빈 옵션 검사
    for (let i of checkList) {
      if ((i.imageUrl == null && i.file == null) || i.pageUrl == "") check = true
    }
    for (let i of checkListMo) {
      if ((i.imageUrl == null && i.file == null) || i.pageUrl == "") check = true
    }
    console.log("checkList", checkList)
    if (check) {
      popupFn("비어있는 이미지가 있습니다.")
      return false
    }

    //가로는 1장 카드는 최대 3장
    if (bannerType == "HORIZONTAL" && (checkList.length > 1 || checkListMo.length > 1)) {
      popupFn("가로형 배너는 1장의 이미지만 가능합니다.")
      return false
    }
    if (bannerType == "CARD" && (checkList.length > 3 || checkListMo.length > 3)) {
      popupFn("카드 배너는 3장의 이미지까지 가능합니다.")
      return false
    }
    if (Validator.isEmpty(title, { ignore_whitespace: true })) {
      popupFn("제목을 입력해주세요.")
      return false
    }
    const fomrData = new FormData()
    const fomrDataMo = new FormData()
    let imgList = []
    let imgListMo = []
    for (let data of checkList) {
      if (data.imageUrl == null && data.file != null) {
        await fomrData.append("files", data.file)
      }
    }
    imgList = await postUploadFiles(fomrData)
    for (let data of checkListMo) {
      if (data.imageUrl == null && data.file != null) {
        await fomrDataMo.append("files", data.file)
      }
    }
    imgListMo = await postUploadFiles(fomrDataMo)
    let tempCheckList = [...checkList]
    let tempCheckListMo = [...checkListMo]
    for (let i = 0; i < tempCheckList.length; i++) {
      for (let data of imgList) {
        if (data.fileName == tempCheckList[i].fileName) {
          tempCheckList[i].imageUrl = data.fileUrl
          tempCheckList[i].file = null
          tempCheckList[i].base64 = null
        }
      }
    }
    for (let i = 0; i < tempCheckListMo.length; i++) {
      for (let data of imgListMo) {
        if (data.fileName == tempCheckListMo[i].fileName) {
          tempCheckListMo[i].imageUrl = data.fileUrl
          tempCheckListMo[i].file = null
          tempCheckList[i].base64 = null
        }
      }
    }
    let upTemp = []
    let upTempMo = []
    for (let i of tempCheckList) {
      let item = {
        fileName: i.fileName,
        imageUrl: i.imageUrl,
        pageUrl: i.pageUrl,
      }
      upTemp = [...upTemp, item]
    }
    for (let i of tempCheckListMo) {
      let item = {
        fileName: i.fileName,
        imageUrl: i.imageUrl,
        pageUrl: i.pageUrl,
      }
      upTempMo = [...upTempMo, item]
    }
    let temp = {
      type: type,
      title: title,
      deviceType: platform,
      bannerType: bannerType,
      bannerPosition: bannerType2,
      isDisplay: isVisible ? "Y" : "N",
      pcImagesAndPages: upTemp,
      mobileImagesAndPages: upTempMo,
    }
    if (dataCheckFn(promotionAd)) {
      temp.promotionAdSeq = promotionAd.promotionAdSeq
      let result = await putPromotionAds(temp)
      if (result) {
        popupFn("수정되었습니다.")
        searchFn()
        closePopup()
      }
    } else {
      let result = await postPromotionAds(temp)
      if (result) {
        popupFn("등록되었습니다.")
        searchFn()
        closePopup()
      }
    }
  }, 650)

  //base64
  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })

  const delFn = async () => {
    let temp = []
    for (let i = checkList.length - 1; i >= 0; i--) {
      let check = false
      for (let k of checkIdx) {
        if (k == i) {
          check = true
        }
      }
      if (!check) {
        temp = [...temp, checkList[i]]
      }
    }
    let tempReverse = []
    for (let i = temp.length - 1; i >= 0; i--) {
      tempReverse = [...tempReverse, temp[i]]
    }
    setCheckList(tempReverse)
    setCheckIdx([])
  }
  const delFnMo = async () => {
    let temp = []
    for (let i = checkListMo.length - 1; i >= 0; i--) {
      let check = false
      for (let k of checkIdxMo) {
        if (k == i) {
          check = true
        }
      }
      if (!check) {
        temp = [...temp, checkListMo[i]]
      }
    }
    let tempReverse = []
    for (let i = temp.length - 1; i >= 0; i--) {
      tempReverse = [...tempReverse, temp[i]]
    }
    setCheckListMo(tempReverse)
    setCheckIdxMo([])
  }
  useEffect(() => {
    //get 정보 저장
    if (dataCheckFn(promotionAd)) {
      setCheckList(promotionAd.pcImagesAndPages)
      setCheckListMo(promotionAd.mobileImagesAndPages)
      setType(promotionAd.type)
      setTitle(promotionAd.title)
      setPlatform(promotionAd.deviceType)
      setBannerType(promotionAd.bannerType)
      setBannerType2(promotionAd.bannerPosition)
      setiIsvisible(promotionAd.isDisplay == "Y" ? true : false)
    }
  }, [promotionAd])
  useEffect(() => {
    //팝업 초기화
    if (!open) {
      promotionAdEmpty()
      setCheckList([])
      setCheckIdx([])
      setCheckListMo([])
      setCheckIdxMo([])
      setType("LETMEUP")
      setTitle("")
      setPlatform("ALL")
      setBannerType("HORIZONTAL")
      setBannerType2("TOP")
      setiIsvisible(false)
      setOpenPreview({ contents: {}, open: "false" })
    }
  }, [open])
  return (
    <LayerPopupLayout open={open} onClose={() => closePopup()} isCloseBtn transparency zIndex={101}>
      <ModalPreviewPopup open={openPreview.open} onClose={() => setOpenPreview({ contents: {}, open: "false" })} contents={openPreview.contents} />
      <div className="learner-type-popup-container layer-popup-container">
        <p className="popup-title mb-3">프로모션 광고 배너 등록변경</p>
        <div className="admin-account-info-container ">
          <div className="admin-account-info-box">
            <div className="admin-account-info-box-row div-1">
              <div className="admin-account-info-title-p ">유형</div>
              <div className="admin-account-info-div">
                <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                  <div className="" style={{ width: "190px" }}>
                    <select
                      value={type}
                      className="form-select"
                      onChange={e => {
                        setType(e.target.value)
                      }}
                    >
                      <option className="" value="LETMEUP">
                        렛미업 프로모션
                      </option>
                      <option className="" value="PARTNER">
                        학원/컨설턴트 광고
                      </option>
                      <option className="" value="CONTENT">
                        컨텐츠 광고
                      </option>
                      <option className="" value="ETC">
                        기타
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="admin-account-info-box-row div-1">
              <div className="admin-account-info-title-p ">제목</div>
              <div className="admin-account-info-div">
                <div style={{ width: "330px", border: "none" }} className="admin-account-info-div-2">
                  <input value={title || ""} onChange={e => setTitle(e.target.value)} type="text" className="fgb-input" />
                </div>
              </div>
            </div>
            <div className="admin-account-info-box-row div-1">
              <div className="admin-account-info-title-p ">플렛폼</div>
              <div className="admin-account-info-div">
                <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                  <img onClick={() => setPlatform("ALL")} className=" me-2" src={platform == "ALL" ? radio_on : radio_off} />
                  <p>전체 </p>
                  <img onClick={() => setPlatform("PC")} className="ms-4 me-2" src={platform == "PC" ? radio_on : radio_off} />
                  <p>PC</p>
                  <img onClick={() => setPlatform("MOBILE")} className="ms-4 me-2" src={platform == "MOBILE" ? radio_on : radio_off} />
                  <p>MOBILE</p>
                </div>
              </div>
            </div>

            <div style={{ padding: "20px 15px 0px" }} className="">
              <div className="admin-account-info-box-row div-1">
                <div className="admin-account-info-title-p ">배너 형태</div>
                <div className="admin-account-info-div">
                  <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                    <img
                      onClick={() => {
                        setBannerType("HORIZONTAL")
                        if (bannerType2 == "HOLD") setBannerType2("TOP")
                      }}
                      className=" me-2"
                      src={bannerType == "HORIZONTAL" ? radio_on : radio_off}
                    />
                    <p>가로형 배너 </p>
                    <img
                      onClick={() => {
                        setBannerType("ROLLING")
                        if (bannerType2 == "HOLD") setBannerType2("TOP")
                      }}
                      className="ms-4 me-2"
                      src={bannerType == "ROLLING" ? radio_on : radio_off}
                    />
                    <p>롤링 배너</p>
                    <img
                      onClick={() => {
                        setBannerType("CARD")
                        setBannerType2("HOLD")
                      }}
                      className="ms-4 me-2"
                      src={bannerType == "CARD" ? radio_on : radio_off}
                    />
                    <p>카드 배너</p>
                  </div>
                </div>
              </div>
              <div className="admin-account-info-box-row div-1">
                <div className="admin-account-info-title-p ">표시 위치</div>
                <div className="admin-account-info-div">
                  <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                    <img
                      onClick={() => {
                        if (bannerType == "CARD") return false
                        setBannerType2("TOP")
                      }}
                      className="me-2"
                      src={bannerType2 == "TOP" ? radio_on : radio_off}
                    />
                    <p>상단 영역</p>
                    <img
                      onClick={() => {
                        if (bannerType == "CARD") return false
                        setBannerType2("BOTTOM")
                      }}
                      className="ms-4 me-2"
                      src={bannerType2 == "BOTTOM" ? radio_on : radio_off}
                    />
                    <p>하단 영역</p>
                    <img
                      onClick={() => {
                        if (bannerType != "CARD") return false
                        setBannerType2("HOLD")
                      }}
                      className="ms-4 me-2"
                      src={bannerType2 == "HOLD" ? radio_on : radio_off}
                    />
                    <p>고정 영역</p>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ padding: "20px 15px 0px" }} className="">
              <div className="admin-account-info-box-row">
                <div className="admin-account-info-title-p">
                  PC 이미지 및 <br />
                  페이지 링크
                </div>
                <div className="admin-account-info-div">
                  <div className="authority-table-top-div">
                    <div></div>
                    <div className="d-flex align-items-center justify-content-end mt-3 mb-3">
                      <button onClick={delFn} style={{ width: "80px" }} className="btn btn-primary font-white reverse fgb-btn-ms clear-button">
                        선택 삭제
                      </button>
                      <button
                        onClick={() => {
                          setCheckList([
                            ...checkList,
                            {
                              base64: null,
                              file: null,
                              imageUrl: null,
                              fileName: null,
                              pageUrl: null,
                            },
                          ])
                        }}
                        className="btn btn-primary font-white fgb-btn-s ms-2"
                      >
                        행추가
                      </button>
                    </div>
                  </div>
                  <table className={`fgb-table mb-3`}>
                    <tbody>
                      {checkList.length > 0 &&
                        checkList.map((i, idx) => (
                          <tr key={idx} className={`fgb-table-tr`}>
                            <td>
                              <img
                                onClick={() => {
                                  let check = checkIdx.indexOf(idx)
                                  if (check == -1) {
                                    setCheckIdx([...checkIdx, idx])
                                  } else {
                                    let temp = [...checkIdx]
                                    temp.splice(check, 1)
                                    setCheckIdx(temp)
                                  }
                                }}
                                className=""
                                src={checkIdx.indexOf(idx) != -1 ? checked_on : checked_off}
                              />
                            </td>
                            <td className="d-flex flex-column align-items-start">
                              <div className="d-flex">
                                <input
                                  value={i.imageUrl || i.fileName || ""}
                                  style={{ width: "180px" }}
                                  readOnly
                                  maxLength={300}
                                  type="text"
                                  className="fgb-input"
                                  disabled
                                />
                                <div
                                  onClick={() => {
                                    let temp = [...checkList]
                                    temp[idx] = {
                                      ...temp[idx],
                                      base64: null,
                                      file: null,
                                      imageUrl: null,
                                      fileName: null,
                                    }
                                    setCheckList(temp)
                                  }}
                                  className="btn fgb-btn-gray fgb-btn-s ms-2"
                                >
                                  삭제
                                </div>
                                <div
                                  onClick={() => setOpenPreview({ open: true, contents: i })}
                                  style={{ width: "90px" }}
                                  className="btn btn-primary font-white fgb-btn-s ms-2"
                                >
                                  미리보기
                                </div>
                                <input
                                  id={`gallery_image_input-${idx}`}
                                  style={{ display: "none" }}
                                  type={"file"}
                                  onChange={async e => {
                                    const file = e.target.files[0]
                                    if (file !== undefined) {
                                      const base64Image = await toBase64(file)

                                      let tempList = [...checkList]
                                      tempList[idx].base64 = base64Image
                                      tempList[idx].file = file
                                      tempList[idx].fileName = file.name
                                      tempList[idx].imageUrl = null
                                      await setCheckList(tempList)
                                      e.target.value = ""
                                    }
                                  }}
                                  accept=".gif, .jpg, .png"
                                  // accept={"image/*"}
                                />

                                <div
                                  onClick={() => document.getElementById(`gallery_image_input-${idx}`).click()}
                                  className="btn fgb-btn-m fgb-btn-purple ms-2"
                                >
                                  <img src={upload} />
                                  <p>이미지 업로드</p>
                                </div>
                              </div>
                              <div className="d-flex mt-2">
                                <input
                                  value={i.pageUrl || ""}
                                  onChange={e => {
                                    let temp = [...checkList]
                                    temp[idx].pageUrl = e.target.value
                                    setCheckList(temp)
                                  }}
                                  style={{ width: "180px" }}
                                  maxLength={300}
                                  type="text"
                                  className="fgb-input"
                                  placeholder="http://"
                                />
                                <div
                                  onClick={() => {
                                    let temp = [...checkList]

                                    temp[idx].pageUrl = ""

                                    setCheckList(temp)
                                  }}
                                  className="btn fgb-btn-gray fgb-btn-s ms-2"
                                >
                                  삭제
                                </div>
                                <div
                                  onClick={() => window.open(i.pageUrl, "_blank")}
                                  style={{ width: "90px" }}
                                  className="btn btn-primary font-white fgb-btn-s ms-2"
                                >
                                  링크보기
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div style={{ padding: "20px 15px 0px" }} className="">
              <div className="admin-account-info-box-row">
                <div className="admin-account-info-title-p">
                  MO 이미지 및<br />
                  페이지 링크
                </div>
                <div className="admin-account-info-div">
                  <div className="authority-table-top-div">
                    <div></div>
                    <div className="d-flex align-items-center justify-content-end mt-3 mb-3">
                      <button onClick={delFnMo} style={{ width: "80px" }} className="btn btn-primary font-white reverse fgb-btn-ms clear-button me-2">
                        선택 삭제
                      </button>
                      <button
                        onClick={() => {
                          setCheckListMo([
                            ...checkListMo,
                            {
                              base64: null,
                              file: null,
                              imageUrl: null,
                              fileName: null,
                              pageUrl: null,
                            },
                          ])
                        }}
                        className="btn btn-primary font-white fgb-btn-s ms-2"
                      >
                        행추가
                      </button>
                    </div>
                  </div>
                  <table className={`fgb-table mb-3`}>
                    <tbody>
                      {checkListMo.length > 0 &&
                        checkListMo.map((i, idx) => (
                          <tr key={idx} className={`fgb-table-tr`}>
                            <td>
                              <img
                                onClick={() => {
                                  let check = checkIdxMo.indexOf(idx)
                                  if (check == -1) {
                                    setCheckIdxMo([...checkIdxMo, idx])
                                  } else {
                                    let temp = [...checkIdxMo]
                                    temp.splice(check, 1)
                                    setCheckIdxMo(temp)
                                  }
                                }}
                                className=""
                                src={checkIdxMo.indexOf(idx) != -1 ? checked_on : checked_off}
                              />
                            </td>
                            <td className="d-flex flex-column align-items-start">
                              <div className="d-flex">
                                <input
                                  value={i.imageUrl || i.fileName || ""}
                                  style={{ width: "180px" }}
                                  readOnly
                                  maxLength={300}
                                  type="text"
                                  className="fgb-input"
                                  disabled
                                />
                                <div
                                  onClick={() => {
                                    let temp = [...checkListMo]
                                    temp[idx] = {
                                      ...temp[idx],
                                      base64: null,
                                      file: null,
                                      imageUrl: null,
                                      fileName: null,
                                    }
                                    setCheckListMo(temp)
                                  }}
                                  className="btn fgb-btn-gray fgb-btn-s ms-2"
                                >
                                  삭제
                                </div>
                                <div
                                  onClick={() => setOpenPreview({ open: true, contents: i })}
                                  style={{ width: "90px" }}
                                  className="btn btn-primary font-white fgb-btn-s ms-2"
                                >
                                  미리보기
                                </div>
                                <input
                                  id={`gallery_image_input-${idx}-mo`}
                                  style={{ display: "none" }}
                                  type={"file"}
                                  onChange={async e => {
                                    const file = e.target.files[0]
                                    if (file !== undefined) {
                                      const base64Image = await toBase64(file)

                                      let tempList = [...checkListMo]
                                      tempList[idx].base64 = base64Image
                                      tempList[idx].file = file
                                      tempList[idx].fileName = file.name
                                      tempList[idx].imageUrl = null

                                      await setCheckListMo(tempList)
                                      e.target.value = ""
                                    }
                                  }}
                                  accept=".gif, .jpg, .png"
                                  // accept={"image/*"}
                                />

                                <div
                                  onClick={() => document.getElementById(`gallery_image_input-${idx}-mo`).click()}
                                  className="btn fgb-btn-m fgb-btn-purple ms-2"
                                >
                                  <img src={upload} />
                                  <p>이미지 업로드</p>
                                </div>
                              </div>
                              <div className="d-flex mt-2">
                                <input
                                  value={i.pageUrl || ""}
                                  onChange={e => {
                                    let temp = [...checkListMo]
                                    temp[idx].pageUrl = e.target.value
                                    setCheckListMo(temp)
                                  }}
                                  style={{ width: "180px" }}
                                  maxLength={300}
                                  type="text"
                                  className="fgb-input"
                                  placeholder="http://"
                                />
                                <div
                                  onClick={() => {
                                    let temp = [...checkListMo]
                                    temp[idx].pageUrl = ""
                                    setCheckListMo(temp)
                                  }}
                                  className="btn fgb-btn-gray fgb-btn-s ms-2"
                                >
                                  삭제
                                </div>
                                <div
                                  onClick={() => window.open(i.pageUrl, "_blank")}
                                  style={{ width: "90px" }}
                                  className="btn btn-primary font-white fgb-btn-s ms-2"
                                >
                                  링크보기
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div style={{ padding: "20px 15px 20px" }} className="">
              <div className="admin-account-info-box-row div-1">
                <div className="admin-account-info-title-p ">전시여부</div>
                <div className="admin-account-info-div">
                  <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                    <img onClick={() => setiIsvisible(true)} className="ms-3 me-2" src={isVisible ? radio_on : radio_off} />
                    <p>전시 </p>
                    <img onClick={() => setiIsvisible(false)} className="ms-3 me-2" src={!isVisible ? radio_on : radio_off} />
                    <p>비전시 </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="permission-info-popup-btn-view">
          <button onClick={() => closePopup()} className="btn btn-primary fgb-btn-gray fgb-btn-l ">
            취소
          </button>
          <button onClick={() => saveFn()} className="btn btn-primary font-white fgb-btn-l ">
            저장
          </button>
        </div>
      </div>
    </LayerPopupLayout>
  )
}

export default ExhibitionContainer(AddPromotionPopup)
const DUMY = [
  {
    base64: null,
    file: null,
    imageUrl: null,
    fileName: null,
    pageUrl: null,
  },
]
