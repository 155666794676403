import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import {
  setCategoryStudentGroup,
  getCategoryStudentGroupAction,
  getPartnersAction,
  getStudentGroupsAction,
  getCategoryStudentGroupsAction,
  putCategoryStudentGroupsAction,
  postCategoryStudentGroupsAction,
  setCategoryStudentGroups,
  setCategoryStudentGroupsTotal,
  delCategoryStudentGroupsAction,
  getPartnersListAction,
  getBelongingsAction,
  putPartnersAction,
  delPartnersAction,
  setPartnersList,
  setPartnersListTotal,
  getKeywordListAction,
  setKeywordList,
  setKeywordListTotal,
  delKeywordAction,
  getKeywordXlDownAction,
  getBrandCategoryAction,
  getCategoryContentsAction,
  delCategoryContentsAction,
  postCategoryContentsAction,
  setCategoryContentsList,
  postCategoryContentsExcelUploadAction,
  getNotificationsAction,
  setNoticeList,
  setNoticeListTotal,
  dalNotificationsAction,
  getTargetNotificationsAction,
  setNoticeTarget,
  putTargetNotificationsAction,
  postTargetNotificationsAction,
  getPointRulesAction,
  setPointRules,
  getPointRuleAction,
  setPointRule,
  putPointRuleAction,
  getRuleLearnAttitudesAction,
  getRuleAISenstivesAction,
  setAISensitives,
  setLearnAttitudesList,
  getRuleLearnAttitudeAction,
  getRuleAISenstiveAction,
  putRuleLearnAttitudeAction,
  putRuleAISensitiveAction,
  getPartnersListExcelAction,
} from "../store/modules/operation"
import { postUploadFileAction } from "../store/modules/sign"

// redux state > component mapping
const mapStateToProps = state => ({
  targetPartnerInfo: state.operation.targetPartnerInfo,
  studentGroups: state.operation.studentGroups,
  categoryStudentGroups: state.operation.categoryStudentGroups,
  categoryStudentGroupsTotal: state.operation.categoryStudentGroupsTotal,
  categoryStudentGroup: state.operation.categoryStudentGroup,
  partnersList: state.operation.partnersList,
  partnersListTotal: state.operation.partnersListTotal,
  belongings: state.operation.belongings,
  keywordList: state.operation.keywordList,
  keywordListTotal: state.operation.keywordListTotal,
  brandList: state.operation.brandList,
  categoryList: state.operation.categoryList,
  categoryContentsList: state.operation.categoryContentsList,
  noticeList: state.operation.noticeList,
  noticeListTotal: state.operation.noticeListTotal,
  noticeTarget: state.operation.noticeTarget,
  pointRules: state.operation.pointRules,
  pointRule: state.operation.pointRule,
  learnAttitudesList: state.operation.learnAttitudesList,
  aiList: state.operation.aiList,
  learnAttitude: state.operation.learnAttitude,
  ai: state.operation.ai,
})

// redux action > component mapping
const mapDispatchToProps = dispatch => ({
  //타겟 제휴사 조회
  getPartners: param => dispatch(getPartnersAction(param)),
  getStudentGroups: param => dispatch(getStudentGroupsAction(param)),
  getCategoryStudentGroups: param => dispatch(getCategoryStudentGroupsAction(param)),
  getCategoryStudentGroup: param => dispatch(getCategoryStudentGroupAction(param)),
  putCategoryStudentGroup: param => dispatch(putCategoryStudentGroupsAction(param)),
  postCategoryStudentGroup: param => dispatch(postCategoryStudentGroupsAction(param)),
  delCategoryStudentGroups: param => dispatch(delCategoryStudentGroupsAction(param)),
  setCategoryStudentGroupEmpty: param => dispatch(setCategoryStudentGroup({})),
  setCategoryStudentGroupsEmpty: param => dispatch(setCategoryStudentGroups([])),
  setCategoryStudentGroupsTotalEmpty: param => dispatch(setCategoryStudentGroupsTotal(0)),
  getPartnersList: param => dispatch(getPartnersListAction(param)),
  partnersListEmpty: param => dispatch(setPartnersList([])),
  partnersListTotalEmpty: param => dispatch(setPartnersListTotal(0)),
  getBelongings: param => dispatch(getBelongingsAction(param)),
  putPartners: param => dispatch(putPartnersAction(param)),
  delPartners: param => dispatch(delPartnersAction(param)),
  getKeywordList: param => dispatch(getKeywordListAction(param)),
  keywordListEmpty: param => dispatch(setKeywordList([])),
  keywordListTotalEmpty: param => dispatch(setKeywordListTotal(0)),
  delKeyword: param => dispatch(delKeywordAction(param)),
  getKeywordXlDown: param => dispatch(getKeywordXlDownAction(param)),
  getBrandCategory: param => dispatch(getBrandCategoryAction(param)),
  getCategoryContents: param => dispatch(getCategoryContentsAction(param)),
  delCategoryContents: param => dispatch(delCategoryContentsAction(param)),
  postCategoryContents: param => dispatch(postCategoryContentsAction(param)),
  categoryContentsListEmpty: param => dispatch(setCategoryContentsList([])),
  postCategoryContentsExcelUpload: param => dispatch(postCategoryContentsExcelUploadAction(param)),
  getNotifications: param => dispatch(getNotificationsAction(param)),
  dalNotifications: param => dispatch(dalNotificationsAction(param)),
  noticeListEmpty: param => dispatch(setNoticeList([])),
  notificationsTotalEmpty: param => dispatch(setNoticeListTotal(0)),
  getTargetNotifications: param => dispatch(getTargetNotificationsAction(param)),
  setNoticeTargetEmpty: param => dispatch(setNoticeTarget({})),
  postUploadFile: param => dispatch(postUploadFileAction(param)),
  putTargetNotifications: param => dispatch(putTargetNotificationsAction(param)),
  postTargetNotifications: param => dispatch(postTargetNotificationsAction(param)),
  getPointRules: param => dispatch(getPointRulesAction(param)),
  pointRulesEmpty: param => dispatch(setPointRules([])),
  getPointRule: param => dispatch(getPointRuleAction(param)),
  pointRuleEmpty: param => dispatch(setPointRule({})),
  putPointRule: param => dispatch(putPointRuleAction(param)),
  getRuleLearnAttitudes: param => dispatch(getRuleLearnAttitudesAction(param)),
  getRuleAISenstives: param => dispatch(getRuleAISenstivesAction(param)),
  aiSensitivesEmpty: param => dispatch(setAISensitives([])),
  learnAttitudesListEmpty: param => dispatch(setLearnAttitudesList([])),
  getRuleLearnAttitude: param => dispatch(getRuleLearnAttitudeAction(param)),
  getRuleAISenstive: param => dispatch(getRuleAISenstiveAction(param)),
  putRuleLearnAttitude: param => dispatch(putRuleLearnAttitudeAction(param)),
  putRuleAISensitive: param => dispatch(putRuleAISensitiveAction(param)),
  getPartnersListExcel: param => dispatch(getPartnersListExcelAction(param)),
})

const WithHoc = ChildComponent => props => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
