/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect } from "react"

import "swiper/scss"

import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import moment from "moment"
import CalendarComponent from "../../components/Calendar"
import Pagination from "../../components/common/pagination"
import TopInfo from "../../components/common/TopInfo"

import { popupFn, toStringByFormatting } from "../../utils/util"

import ParticipantsPopup from "../../popups/schedule/ParticipantsPopup"
import SubscriberPopup from "../../popups/schedule/SubscriberPopup"
import CalendarPopup from "../../popups/schedule/CalendarPopup"
import AssignmentPopup from "../../popups/schedule/AssignmentPopup"
import AddStudyPopup from "../../popups/schedule/AddStudyPopup"
import AssignmentEditPopup from "../../popups/schedule/AssignmentEditPopup"

import CommonContainer from "../../containers/CommonContainer"
import ScheduleContainer from "../../containers/ScheduleContainer"

import icon_calendar from "../../images/ion_calendar-number-outline.svg"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"
import download from "../../images/download.svg"
import CloseButton from "../../images/img_popup_close_black.svg"

import PrivateRoute from "../../components/privateRoute"
import { navigate } from "gatsby"

const StudyScheduleReg = props => {
  const {
    getAttitudeList,
    getAiList,
    attitudeList,
    aiList,
    studentGroupList,
    getStudentGroupList,
    getUserInformation,
    userInformation,
    postStudyScheduleCamStudy,
    postToDo,
  } = props

  //date 세팅용 state
  const [calendarPopup, setCalendarPopup] = useState(false)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [date, setDate] = useState(moment())
  const [popupType, setPopupType] = useState(false)

  const [currentType, setCurrentType] = useState(0) // 선택 메뉴 0: 캠스터디  1: TO-DO
  const [classifyDropdownUnable, setClassifyDropdownUnable] = useState(false) // 분류 드롭다운 사용 가능 여부
  const [classify, setClassify] = useState("letmeup") // 분류 드롭다운 기본값 계속 바뀜
  const [ownerDropdownUnable, setOwnerDropdownUnable] = useState(false) // 개설자 드롭다운 사용 가능 여부
  const [owner, setOwner] = useState("No one") // 개설자 드롭다운 기본값 계속 바뀜
  const [basicInfo, setBasicInfo] = useState({ kind: "", name: "" })

  // Popups
  const [participantPopup, setParticipantPopup] = useState(false)
  const [addStudyPopup, setAddStudyPopup] = useState(false)
  const [subscriberPopup, setSubscriberPopup] = useState(false)
  const [timeTablePopup, setTimeTablePopup] = useState(false)
  const [assignmentPopup, setAssignmentPopup] = useState(false)
  const [assignmentEditPopup, setAssignmentEditPopup] = useState({ open: false, idx: 0, data: {} })

  // 캠스터디, TO-DO 공유함
  const [studyTitle, setStudyTitle] = useState("") // 스터디 타이틀 Input Box
  const [password, setPassword] = useState(1) // 비밀번호 Radio Button (0: 사용,  1: 미사용)
  const [passwordInput, setPasswordInput] = useState("") // 비밀번호 Input Box
  const [keywordInput, setKeywordInput] = useState("") // 키워드 Input 박스 기본값: null
  const [keywords, setKeywords] = useState([]) // 키워드 들어있는 배열

  // 캠스터디
  const [learningGroup, setLearningGroup] = useState({ name: "", value: "" })
  const [participants, setParticipants] = useState([]) // 참여자 목록 들어있는 배열
  const [participantsAllCheck, setParticipantsAllCheck] = useState(false) // 참여자 목록 전체 체크
  const [participantsCheckList, setParticipantsCheckList] = useState([]) // 참여자 목록 체크리스트
  const [timeTable, setTimeTable] = useState([
    // 스케줄 시간표
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
  ])
  const [timeSchedule, setTimeSchedule] = useState([])
  const [scheduleOption, setScheduleOption] = useState(0) // 스케줄 일정 옵션 (0: 1회,  1: 반복)
  const [camNotice, setCamNotice] = useState("") // 캠스터디 공지사항 textarea
  const [attitude, setAttitude] = useState({ text: "", value: "" })
  const [ai, setAi] = useState({ text: "", value: "" })
  const [studySchedule, setStudySchedule] = useState([]) // API에 들어가는 studySchedule 값
  const [usingCamera, setUsingCamera] = useState(false)
  const [usingMic, setUsingMic] = useState(false)
  const [usingSpeaker, setUsingSpeaker] = useState(false)

  // TO-DO
  const [assignment, setAssignment] = useState([]) // TO-DO 과제 목록 들어있는 배열
  const [assignmentAllCheck, setAssignmentAllCheck] = useState(false) // TO-DO 과제 목록 전체 체크
  const [assignmentCheckList, setAssignmentCheckList] = useState([])
  const [subscriber, setSubscriber] = useState([]) // TO-DO 구독자 들어있는 배열
  const [subscriberAllCheck, setSubscriberAllCheck] = useState(false) // TO-DO 구독자 전체 체크
  const [subscriberCheckList, setSubscriberCheckList] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [isShare, setIsShare] = useState(false)
  const [isAlarm, setIsAlarm] = useState(false)

  useEffect(() => {
    async function getLists() {
      await getAttitudeList()
      await getAiList()
      await getStudentGroupList()
      await getUserInformation()
    }

    getLists()
  }, [])

  useEffect(() => {
    if (password === 1) {
      const index = document.getElementById("password-input-box")
      index.value = ""
      setPasswordInput()
    }
  }, [password])

  useEffect(() => {
    let list_ = []

    for (let i = 0; i < participants.length; i++) {
      list_.push(false)
    }

    setParticipantsCheckList(list_)
  }, [participants])

  useEffect(() => {
    let list_ = []

    if (participantsAllCheck) {
      for (let i = 0; i < participantsCheckList.length; i++) {
        list_.push(true)
      }
    } else {
      for (let i = 0; i < participantsCheckList.length; i++) {
        list_.push(false)
      }
    }

    setParticipantsCheckList(list_)
  }, [participantsAllCheck])

  useEffect(() => {
    let list_ = []
    for (let i = 0; i < subscriber.length; i++) {
      list_.push(false)
    }
    setSubscriberCheckList(list_)
  }, [subscriber])

  useEffect(() => {
    let list_ = []
    for (let i = 0; i < assignment.length; i++) {
      list_.push(false)
    }
    setAssignmentCheckList(list_)
  }, [assignment])

  useEffect(() => {
    let list_ = []
    for (let i = 0; i < subscriberCheckList.length; i++) {
      list_.push(subscriberAllCheck)
    }
    setSubscriberCheckList(list_)
  }, [subscriberAllCheck])

  useEffect(() => {
    let list_ = []
    for (let i = 0; i < assignmentCheckList.length; i++) {
      list_.push(assignmentAllCheck)
    }
    setAssignmentCheckList(list_)
  }, [assignmentAllCheck])

  useEffect(() => {
    setBasicInfo({
      kind:
        userInformation?.loginUserType?.type === "ADMIN" ? "렛미업" : userInformation?.loginUserType?.type === "CRAM_SCHOOL" ? "학원" : "컨설턴트",
      name: userInformation?.loginUserType?.name,
    })
  }, [userInformation])

  useEffect(() => {
    setParticipants([])
    setSubscriber([])
    setAssignment([])
    setParticipantsAllCheck(false)
    setSubscriberAllCheck(false)
    setAssignmentAllCheck(false)
  }, [currentType])

  useEffect(() => {
    let list_ = []
    let list_idx = []
    const DAYS = ["월", "화", "수", "목", "금", "토", "일"]
    const DAYS_FOR_API = ["MOM", "THU", "WED", "THUR", "FRI", "SAT", "SUN"]

    for (let j = 0; j < 7; j++) {
      let study_time = []
      let rest_time = []
      let str_ = ""

      for (let i = 0; i < 24; i++) {
        if (timeTable[i][j] === 1) {
          study_time.push(i)
        } else if (timeTable[i][j] === 2) {
          rest_time.push(i)
        }
      }

      if (study_time.length !== 0 || rest_time.length !== 0) {
        str_ = str_ + DAYS[j] + ": "
      }

      let study_arr = []
      let study_idx = []
      let rest_arr = []
      let rest_idx = []

      for (let i = 0; i < study_time.length; i++) {
        if (i < 23) {
          if (study_idx.length === 0 && study_time[i + 1] === study_time[i] + 1) {
            study_idx.push(study_time[i])
          } else if (study_idx.length === 0 && study_time[i + 1] !== study_time[i] + 1) {
            study_arr.push([study_time[i], study_time[i] + 1])
          } else {
            if (study_time[i + 1] !== study_time[i] + 1) {
              study_idx.push(study_time[i] + 1)
              study_arr.push(study_idx)
              study_idx = []
            }
          }
        } else if (i === 23) {
          if (study_idx.length === 0) {
            study_arr.push([23, 24])
          } else {
            study_arr.push([study_idx[0], 24])
          }
        }
      }

      for (let i = 0; i < rest_time.length; i++) {
        if (i < 23) {
          if (rest_idx.length === 0 && rest_time[i + 1] === rest_time[i] + 1) {
            rest_idx.push(rest_time[i])
          } else if (rest_idx.length === 0 && rest_time[i + 1] !== rest_time[i] + 1) {
            rest_arr.push([rest_time[i], rest_time[i] + 1])
          } else {
            if (rest_time[i + 1] !== rest_time[i] + 1) {
              rest_idx.push(rest_time[i] + 1)
              rest_arr.push(rest_idx)
              rest_idx = []
            }
          }
        } else if (i === 23) {
          if (rest_idx.length === 0) {
            rest_arr.push([23, 24])
          } else {
            rest_arr.push([rest_idx[0], 24])
          }
        }
      }

      if (study_arr.length !== 0 && rest_arr.length === 0) {
        for (let k = 0; k < study_arr.length; k++) {
          str_ += `[공부: ${study_arr[k][0]}시 ~ ${study_arr[k][1]}시] `
        }
      } else if (study_arr.length === 0 && rest_arr.length !== 0) {
        for (let k = 0; k < rest_arr.length; k++) {
          str_ += `[휴식: ${rest_arr[k][0]}시 ~ ${rest_arr[k][1]}시] `
        }
      } else if (study_arr.length !== 0 && rest_arr.length !== 0) {
        let studyIdx = 0
        let restIdx = 0
        for (let k = 0; k < study_arr.length + rest_arr.length; k++) {
          if (studyIdx >= study_arr.length) {
            str_ += `[휴식: ${rest_arr[restIdx][0]}시 ~ ${rest_arr[restIdx][1]}시] `
            restIdx += 1
          } else if (restIdx >= rest_arr.length) {
            str_ += `[공부: ${study_arr[studyIdx][0]}시 ~ ${study_arr[studyIdx][1]}시] `
            studyIdx += 1
          } else {
            if (study_arr[studyIdx][0] < rest_arr[restIdx][0]) {
              str_ += `[공부: ${study_arr[studyIdx][0]}시 ~ ${study_arr[studyIdx][1]}시] `
              studyIdx += 1
            } else {
              str_ += `[휴식: ${rest_arr[restIdx][0]}시 ~ ${rest_arr[restIdx][1]}시] `
              restIdx += 1
            }
          }
        }
      }

      if (str_ !== "") {
        list_.push(str_)
      }

      // API에 필요한 인자 값 studySchedules 추출
      // studySchedules: [
      //   { dayType: 'MOM', isBreaktime: 'N', startTime: '00:00:00', endTime: '00:00:00' },
      //   ...,
      // ]
      if (study_arr.length !== 0) {
        for (let i = 0; i < study_arr.length; i++) {
          list_idx.push({
            dayType: DAYS_FOR_API[j],
            isBreaktime: "N",
            startTime: 0 <= study_arr[i][0] && study_arr[i][0] <= 9 ? "0" + String(study_arr[i][0]) + ":00:00" : String(study_arr[i][0]) + ":00:00",
            endTime: 0 <= study_arr[i][1] && study_arr[i][1] <= 9 ? "0" + String(study_arr[i][1]) + ":00:00" : String(study_arr[i][1]) + ":00:00",
          })
        }
      }

      if (rest_arr.length !== 0) {
        for (let i = 0; i < rest_arr.length; i++) {
          list_idx.push({
            dayType: DAYS_FOR_API[j],
            isBreaktime: "Y",
            startTime: 0 <= rest_arr[i][0] && rest_arr[i][0] <= 9 ? "0" + String(rest_arr[i][0]) + ":00:00" : String(rest_arr[i][0]) + ":00:00",
            endTime: 0 <= rest_arr[i][1] && rest_arr[i][1] <= 9 ? "0" + String(rest_arr[i][1]) + ":00:00" : String(rest_arr[i][1]) + ":00:00",
          })
        }
      }
    }

    setTimeSchedule(list_)
    setStudySchedule(list_idx)
  }, [timeTable])

  const TypeFn = type => {
    setCurrentType(type)
  }

  const RenderParticipation = () => {
    let result = []

    for (let i = 0; i < participants.length; i++) {
      result.push(
        <div className="p-table-row">
          <div className="element p-table-checkbox">
            <img
              src={participantsCheckList[i] ? checked_on : checked_off}
              onClick={() => {
                let list_ = []
                for (let j = 0; j < participants.length; j++) {
                  if (j === i) {
                    list_.push(!participantsCheckList[j])
                  } else {
                    list_.push(participantsCheckList[j])
                  }
                }
                setParticipantsCheckList(list_)
              }}
            />
          </div>
          {/* <div className="element p-table-group">{i?.s}</div> */}
          <div className="element p-table-name">{participants[i]?.name}</div>
          <div className="element p-table-nickname">{participants[i]?.nickname}</div>
          <div className="element p-table-affiliation">{participants[i]?.affiliation}</div>
          <div className="element p-table-attending-studies">{participants[i]?.recentlyStudy}</div>
          <div className="element p-table-learning-time">{participants[i]?.studyTime}</div>
          <div className="element p-table-ai">{participants[i]?.scoreAiSensitive}</div>
          <div className="element p-table-rule">{participants[i]?.scoreLearnAttitude}</div>
        </div>
      )
    }

    return result
  }

  const RenderBlankParticipation = () => {
    const blankBoxesLength = 8 - participants.length > 0 ? 8 - participants.length : 0
    let result = []

    for (let i = 0; i < blankBoxesLength; i++) {
      result.push(
        <div className="p-table-row">
          <div className="element p-table-checkbox">
            <img src={checked_off} />
          </div>
          {/* <div className="element p-table-group"></div> */}
          <div className="element p-table-name"></div>
          <div className="element p-table-nickname"></div>
          <div className="element p-table-affiliation"></div>
          <div className="element p-table-attending-studies"></div>
          <div className="element p-table-learning-time"></div>
          <div className="element p-table-ai"></div>
          <div className="element p-table-rule"></div>
        </div>
      )
    }

    return result
  }

  const RenderAssignment = () => {
    let result = []
    console.log("assignment", assignment)
    console.log("studentGroupList?.studentGroups", studentGroupList.studentGroups)
    for (let i = 0; i < assignment.length; i++) {
      result.push(
        <div className="p-table-row" style={{ display: "flex", height: "60px", borderBottom: "1px solid #E1E1E1" }}>
          <div className="element p-table-checkbox" style={{ width: "40px" }}>
            <img
              src={assignmentCheckList[i] ? checked_on : checked_off}
              onClick={() => {
                let list_ = []
                for (let j = 0; j < assignment.length; j++) {
                  if (j === i) {
                    list_.push(!assignmentCheckList[j])
                  } else {
                    list_.push(assignmentCheckList[j])
                  }
                }
                setAssignmentCheckList(list_)
              }}
            />
          </div>

          <div className="element table-group">{assignment[i]?.studentGroupName}</div>
          <div
            onClick={() => {
              setAssignmentEditPopup({ open: true, data: assignment[i], idx: i })
            }}
            style={{ cursor: "pointer" }}
            className="element table-class"
          >
            {assignment[i]?.subject}
          </div>
          <div
            onClick={() => {
              setAssignmentEditPopup({ open: true, data: assignment[i], idx: i })
            }}
            style={{ cursor: "pointer" }}
            className="element table-curiculum"
          >
            {assignment[i]?.book}
          </div>
          <div className="element table-range">{`${assignment[i]?.rangeStart} - ${assignment[i]?.rangeEnd}`}</div>
          <div className="element table-comment">{assignment[i]?.comment}</div>
          <div className="element table-time">{assignment[i]?.estimatedTimeRequired}</div>
        </div>
      )
    }

    return result
  }

  const RenderBlankAssignment = () => {
    const blankBoxesLength = 8 - assignment.length > 0 ? 8 - assignment.length : 0
    let result = []

    for (let i = 0; i < blankBoxesLength; i++) {
      result.push(
        <div className="table-row">
          <div className="element table-checkbox">
            <img src={checked_off} />
          </div>
          <div className="element table-group"></div>
          <div className="element table-class"></div>
          <div className="element table-curiculum"></div>
          <div className="element table-range"></div>
          <div className="element table-comment"></div>
          <div className="element table-time"></div>
        </div>
      )
    }

    return result
  }

  const RenderSubscriber = () => {
    let result = []

    for (let i = 0; i < subscriber.length; i++) {
      result.push(
        <div className="table-row">
          <div className="element table-checkbox">
            <img
              src={subscriberCheckList[i] ? checked_on : checked_off}
              onClick={() => {
                let list_ = []
                for (let j = 0; j < subscriber.length; j++) {
                  if (j === i) {
                    list_.push(!subscriberCheckList[j])
                  } else {
                    list_.push(subscriberCheckList[j])
                  }
                }
                setSubscriberCheckList(list_)
              }}
            />
          </div>
          <div className="element table-name">{subscriber[i]?.name}</div>
          <div className="element table-nickname">{subscriber[i]?.nickname}</div>
          <div className="element table-affiliation">{subscriber[i]?.affiliation}</div>
          <div className="element table-participated">{subscriber[i]?.recentlyStudy}</div>
          <div className="element table-time">{subscriber[i]?.studyTime}</div>
          <div className="element table-sensitivity">{subscriber[i]?.scoreAiSensitive}</div>
          <div className="element table-rule">{subscriber[i]?.scoreLearnAttitude}</div>
        </div>
      )
    }

    return result
  }

  const RenderBlankSubscriber = () => {
    const blankBoxesLength = 5 - subscriber.length > 0 ? 5 - subscriber.length : 0
    let result = []

    for (let i = 0; i < blankBoxesLength; i++) {
      result.push(
        <div className="table-row">
          <div className="element table-checkbox">
            <img src={checked_off} />
          </div>
          {/* <div className="element table-group"></div> */}
          <div className="element table-name"></div>
          <div className="element table-nickname"></div>
          <div className="element table-affiliation"></div>
          <div className="element table-participated"></div>
          <div className="element table-time"></div>
          <div className="element table-sensitivity"></div>
          <div className="element table-rule"></div>
        </div>
      )
    }

    return result
  }

  const DeleteParticipant = () => {
    setParticipants(participants.filter((i, idx) => (participantsCheckList[idx] ? "" : i)))
    setParticipantsAllCheck(false)
  }

  const DeleteSubscriber = () => {
    setSubscriber(subscriber.filter((i, idx) => (subscriberCheckList[idx] ? "" : i)))
    setSubscriberAllCheck(false)
  }

  const DeleteAssignment = () => {
    setAssignment(assignment.filter((i, idx) => (assignmentCheckList[idx] ? "" : i)))
    setAssignmentAllCheck(false)
  }

  const handleRegister = async () => {
    if (currentType === 0) {
      const student_group_seq = learningGroup?.value
      const is_loop = scheduleOption === 0 ? "N" : "Y"
      const is_using_password = password === 0 ? "Y" : "N"
      const setting_camera = usingCamera ? "Y" : "N"
      const setting_microphone = usingMic ? "Y" : "N"
      const setting_speaker = usingSpeaker ? "Y" : "N"
      const date_start = toStringByFormatting(startDate)
      const date_end = toStringByFormatting(endDate)
      const title_ = studyTitle
      const password_ = password === 1 ? "" : passwordInput
      const notice_ = camNotice
      const learn_attitude_rule_seq = attitude?.value
      const ai_sensitive_rule_seq = ai?.value
      const tags_ = keywords
      const insert_study_members = []
      for (let i = 0; i < participants.length; i++) {
        insert_study_members.push({
          userSeq: participants[i]?.userSeq,
          partnerSeq: participants[i]?.partnerSeq,
          adminSeq: participants[i]?.adminSeq,
        })
      }
      const study_schedules = studySchedule

      if (startDate > endDate) {
        alert("날짜를 확인해주세요.")
      } else if (student_group_seq === "") {
        alert("학습자 그룹을 선택해주세요.")
      } else if (is_using_password === 0 && password_ === "") {
        alert("비밀번호를 입력해주세요.")
      } else if (title_ === "") {
        alert("타이틀을 입력해주세요.")
      } else if (learn_attitude_rule_seq === "") {
        alert("상벌점 설정을 선택해주세요.")
      } else if (ai_sensitive_rule_seq === "") {
        alert("AI 민감도 설정을 선택해주세요.")
      } else if (study_schedules.length === 0) {
        alert("스케줄을 선택해주세요.")
      } else {
        const payload = {
          studentGroupSeq: student_group_seq,
          isLoop: is_loop,
          isUsingPassword: is_using_password,
          settingCamera: setting_camera,
          settingMicrophone: setting_microphone,
          settingSpeaker: setting_speaker,
          dateStart: date_start,
          dateEnd: date_end,
          title: title_,
          password: password_,
          notice: notice_,
          learnAttitudeRuleSeq: learn_attitude_rule_seq,
          aiSensitiveRuleSeq: ai_sensitive_rule_seq,
          tags: tags_,
          insertStudyMembers: insert_study_members,
          studySchedules: study_schedules,
        }
        console.log(payload)

        const result = await postStudyScheduleCamStudy(payload)
        if (result) {
          popupFn("등록되었습니다.")
          navigate("/studySchedule/StudyScheduleInquiry")
        } else {
          // alert("ERROR")
        }
      }
    } else {
      let subscribers_ = []
      for (let i = 0; i < subscriber.length; i++) {
        if (subscriber[i]?.adminSeq !== "") {
          subscribers_.push({ subscriberSeq: subscriber[i].adminSeq, subscriberType: "ADMIN" })
        } else if (subscriber[i]?.partnerSeq !== "") {
          subscribers_.push({ subscriberSeq: subscriber[i].partnerSeq, subscriberType: "PARTNER" })
        } else {
          subscribers_.push({ subscriberSeq: subscriber[i].userSeq, subscriberType: "USER" })
        }
      }

      const payload = {
        date: toStringByFormatting(startDate),
        title: studyTitle,
        isUsingPassword: password === 0 ? "Y" : "N",
        password: passwordInput,
        isOpen: isOpen ? "Y" : "N",
        isShareBuddy: isShare ? "Y" : "N",
        isAlarm: isAlarm ? "Y" : "N",
        keywords: keywords,
        tasks: assignment,
        subscribers: subscribers_,
      }

      console.log(payload)

      const result = await postToDo(payload)
      console.log(result)
      if (result) {
        popupFn("등록되었습니다.")
        navigate("/studySchedule/StudyScheduleInquiry")
      }
    }
  }

  return (
    <PrivateRoute>
      <>
        {participantPopup && (
          <ParticipantsPopup
            participants={participants}
            onClose={() => setParticipantPopup(false)}
            onSave={participants_ => setParticipants([...participants, ...participants_])}
          />
        )}
        {subscriberPopup && (
          <SubscriberPopup
            participants={subscriber}
            onClose={() => setSubscriberPopup(false)}
            onSave={subscriber_ => setSubscriber([...subscriber, ...subscriber_])}
          />
        )}
        {timeTablePopup && <CalendarPopup onClose={() => setTimeTablePopup(false)} timeTable={timeTable} />}
        {assignmentPopup && (
          <AssignmentPopup onClose={() => setAssignmentPopup(false)} onHandleSave={added => setAssignment([...assignment, added])} />
        )}
        {assignmentEditPopup.open && (
          <AssignmentEditPopup
            open={assignmentEditPopup.open}
            data={assignmentEditPopup.data}
            idx={assignmentEditPopup.idx}
            onClose={() => setAssignmentEditPopup({ open: false, idx: 0, data: {} })}
            onHandleSave={added => {
              let temp = [...assignment]
              temp[assignmentEditPopup.idx] = added
              setAssignment(temp)
              setAssignmentEditPopup({ open: false, idx: 0, data: {} })
            }}
          />
        )}
        {addStudyPopup && <AddStudyPopup onClose={() => setAddStudyPopup(false)} onSave={table => setTimeTable(table)} timeTable_={timeTable} />}

        <Layout>
          <SEO title="스터디스케줄 등록" />
          <TopInfo title={["스터디 스케줄 등록", "스터디 스케줄 관리", "스터디 스케줄 등록"]} />

          <div className="study-schedule-reg-container">
            <div className="select-btn-wrapper">
              <div className={`select-item ${currentType === 0 ? "selected" : "non-selected"}`} onClick={() => TypeFn(0)}>
                캠스터디
              </div>
              <div className={`select-item ${currentType === 1 ? "selected" : "non-selected"}`} onClick={() => TypeFn(1)}>
                TO-DO
              </div>
            </div>

            {currentType === 0 ? (
              <div className="filter-wrapper">
                <div className="element-l">유형</div>
                <div className="element-r">
                  <div className="radio-btn-wrapper">
                    <img src={currentType == 0 ? radio_on : radio_off} />
                    <p>캠스터디</p>
                  </div>
                  <div className="radio-btn-wrapper">
                    <img src={currentType == 1 ? radio_on : radio_off} />
                    <p>TO-DO</p>
                  </div>
                </div>
                <div className="element-l">분류</div>
                <div className="element-r">
                  <div style={{ width: "195px" }}>
                    <select
                      className="form-select"
                      style={{ color: classifyDropdownUnable ? "black" : "#818181", border: "none", cursor: "pointer" }}
                      disabled={classifyDropdownUnable ? false : true}
                      onChange={e => setClassify(e.target.value)}
                    >
                      <option className="" value="letmeup">
                        {basicInfo?.kind}
                      </option>
                    </select>
                  </div>
                </div>
                <div className="element-l">개설자</div>
                <div className="element-r">
                  <div style={{ width: "195px" }}>
                    <select
                      className="form-select"
                      style={{ color: ownerDropdownUnable ? "black" : "#818181", border: "none", cursor: "pointer" }}
                      disabled={ownerDropdownUnable ? false : true}
                      onChange={e => setOwner(e.target.value)}
                    >
                      <option className="" value="all">
                        {basicInfo?.name}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            ) : (
              <div className="filter-wrapper">
                <div className="element-l" style={{ width: "80px " }}>
                  유형
                </div>
                <div className="element-r">
                  <div style={{ padding: "0px" }} className="radio-btn-wrapper">
                    <img src={currentType == 0 ? radio_on : radio_off} />
                    <p>캠스터디</p>
                  </div>
                  <div style={{ padding: "0px", paddingLeft: "10px" }} className="radio-btn-wrapper">
                    <img src={currentType == 1 ? radio_on : radio_off} />
                    <p>TO-DO</p>
                  </div>
                </div>
                <div className="element-l" style={{ width: "80px " }}>
                  분류
                </div>
                <div className="element-r">
                  <div style={{ width: "164px", paddingRight: "20px" }}>
                    <select
                      className="form-select"
                      style={{ color: classifyDropdownUnable ? "black" : "#818181", border: "none", cursor: "pointer" }}
                      disabled={classifyDropdownUnable ? false : true}
                      onChange={e => setClassify(e.target.value)}
                    >
                      <option className="" value="letmeup">
                        {basicInfo?.kind}
                      </option>
                    </select>
                  </div>
                </div>
                <div className="element-l" style={{ width: "80px " }}>
                  날짜
                </div>
                <div className="element-r" style={{ borderRight: "1px solid #E1E1E1" }}>
                  <div className="fgb-date-form">
                    <img src={icon_calendar} />
                    <div
                      className="fgb-date-input"
                      onClick={() => {
                        setPopupType(false)
                        setCalendarPopup(true)
                      }}
                    >
                      <p>{startDate.isValid() ? startDate.format("YYYY.MM.DD") : "시작일"}</p>
                    </div>
                  </div>
                  {calendarPopup && (
                    <div className="calendar-popup-back" style={{ zIndex: "102" }}>
                      <div className="calendar-popup-div">
                        <CalendarComponent
                          date={date}
                          setDate={setDate}
                          selectedDate={popupType == false ? startDate : endDate}
                          setSelectedDate={popupType == false ? setStartDate : setEndDate}
                          popup={setCalendarPopup}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="element-l" style={{ width: "80px " }}>
                  개설자
                </div>
                <div className="element-r">
                  <div style={{ width: "145px" }}>
                    <select
                      className="form-select"
                      style={{ color: ownerDropdownUnable ? "black" : "#818181", border: "none", cursor: "pointer" }}
                      disabled={ownerDropdownUnable ? false : true}
                      onChange={e => setOwner(e.target.value)}
                    >
                      <option className="" value="all">
                        {basicInfo?.name}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            )}

            {currentType === 0 ? (
              <div className="cam-study-wrapper">
                <div className="upper-filter-wrapper">
                  <div className="table-row">
                    <div className="element-l">스터디 타이틀</div>
                    <div className="element-r" style={{ width: "300px", paddingRight: "15px" }}>
                      <input
                        className="input-box"
                        placeholder="제목 공백 포함 최대 30자 입력"
                        maxLength="30"
                        value={studyTitle}
                        onChange={e => setStudyTitle(e.target.value)}
                        style={{ width: "270px" }}
                      />
                    </div>
                    <div className="element-l">비밀번호</div>
                    <div className="element-r" style={{ width: "480px", display: "flex", alignItems: "center" }}>
                      <div className="radio-btn-wrapper">
                        <img src={password == 0 ? radio_on : radio_off} onClick={e => setPassword(0)} />
                        <p>사용</p>
                      </div>
                      <div className="radio-btn-wrapper">
                        <img src={password == 1 ? radio_on : radio_off} onClick={e => setPassword(1)} />
                        <p>미사용</p>
                      </div>
                      <input
                        className="input-box"
                        id="password-input-box"
                        placeholder="최소 4자 입력"
                        value={passwordInput}
                        onChange={e => setPasswordInput(e.target.value)}
                        disabled={password === 0 ? false : true}
                        style={{ width: "190px" }}
                      />
                    </div>
                  </div>
                  <div className="table-row">
                    <div className="element-l">키워드</div>
                    <div className="element-r" style={{ width: "880px", display: "flex", alignItems: "center" }}>
                      {keywords.length !== 0 &&
                        keywords.map(i => (
                          <div className="keyword-box">
                            {i}
                            <img className="x-button" src={CloseButton} onClick={e => setKeywords(keywords.filter(item => item !== i))} />
                          </div>
                        ))}
                      {keywords.length < 5 && (
                        <input
                          className="input-box"
                          value={keywordInput}
                          placeholder="Enter or Tab"
                          onChange={e => setKeywordInput(e.target.value)}
                          style={{ width: "158px", textAlign: "center" }}
                          onKeyPress={e => {
                            if (e.key === "Enter" && e.target.value !== undefined) {
                              setKeywordInput()
                              setKeywords([...keywords, e.target.value])
                              e.target.value = ""
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="participation">
                  <div className="participation-title">참여자</div>
                  <div className="table-wrapper">
                    <div className="table-row" style={{ height: "60px" }}>
                      <div className="element-l" style={{ height: "59px" }}>
                        학습자 그룹
                      </div>
                      <div className="element-r learning-group" style={{ height: "59px", display: "flex", alignItems: "center" }}>
                        {studentGroupList &&
                          studentGroupList?.studentGroups &&
                          studentGroupList?.studentGroups.map((i, idx) => (
                            <div
                              key={idx}
                              className="check-box-wrapper"
                              onClick={() => setLearningGroup({ name: i?.level1?.level1Name, value: i?.level1?.level1Seq })}
                            >
                              <img src={learningGroup.name === i?.level1?.level1Name ? radio_on : radio_off} />
                              <p>{i?.level1?.level1Name}</p>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="table-row" style={{ height: "635px" }}>
                      <div className="element-l" style={{ height: "633px", lineHeight: "633px" }}>
                        참여자 목록
                      </div>
                      <div className="element-r" style={{ height: "633px" }}>
                        <div className="participation-info-wrapper">
                          <div className="people-num">{participants.length}명</div>
                          <div className="info-two-buttons">
                            <div className="btn btn-primary font-white reverse fgb-btn-m clear-button" onClick={() => DeleteParticipant()}>
                              선택 삭제
                            </div>
                            <div className="btn btn-primary font-white fgb-btn-m add-button" onClick={() => setParticipantPopup(true)}>
                              참여자 추가
                            </div>
                          </div>
                        </div>
                        <div className="participation-table">
                          {" "}
                          {/* width: 800px */}
                          <div className="info-row">
                            <div className="element p-table-checkbox">
                              <img
                                src={participantsAllCheck ? checked_on : checked_off}
                                onClick={() => setParticipantsAllCheck(!participantsAllCheck)}
                              />
                            </div>
                            {/* <div className="element p-table-group">학습자 그룹</div> */}
                            <div className="element p-table-name">이름</div>
                            <div className="element p-table-nickname">닉네임</div>
                            <div className="element p-table-affiliation">소속</div>
                            <div className="element p-table-attending-studies">참여중인 스터디</div>
                            <div className="element p-table-learning-time">누적 공부 시간</div>
                            <div className="element p-table-ai">AI 모션 민감도</div>
                            <div className="element p-table-rule">학습 규칙 준수</div>
                          </div>
                          <div>
                            {RenderParticipation()}
                            {RenderBlankParticipation()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="schedule">
                  <div className="info-area">
                    <div className="schedule-title">스케줄 일정</div>
                    <div className="buttons">
                      <div className="btn btn-primary font-white reverse fgb-btn-m" onClick={() => setTimeTablePopup(true)}>
                        캘린더 보기
                      </div>
                      <div className="btn btn-primary font-white fgb-btn-m" onClick={() => setAddStudyPopup(true)}>
                        상세 스케줄 추가
                      </div>
                    </div>
                  </div>
                  <div className="table-wrapper">
                    <div className="table-row">
                      <div className="element-l">공부 기간</div>
                      <div className="element-r" style={{ borderRight: "1px solid #E1E1E1" }}>
                        <div className="fgb-date-form">
                          <img src={icon_calendar} />
                          <div
                            className="fgb-date-input"
                            onClick={() => {
                              setPopupType(false)
                              setCalendarPopup(true)
                            }}
                          >
                            <p>{startDate.isValid() ? startDate.format("YYYY.MM.DD") : "시작일"}</p>
                          </div>
                          {calendarPopup && (
                            <div className="calendar-popup-back" style={{ zIndex: "102" }}>
                              <div className="calendar-popup-div">
                                <CalendarComponent
                                  date={date}
                                  setDate={setDate}
                                  selectedDate={popupType == false ? startDate : endDate}
                                  setSelectedDate={popupType == false ? setStartDate : setEndDate}
                                  popup={setCalendarPopup}
                                />
                              </div>
                            </div>
                          )}
                          <p>~</p>
                          <img src={icon_calendar} />
                          <div
                            className="fgb-date-input"
                            onClick={() => {
                              setPopupType(true)
                              setCalendarPopup(true)
                            }}
                          >
                            <p>{endDate.isValid() ? endDate.format("YYYY.MM.DD") : "종료일"}</p>
                          </div>
                        </div>
                      </div>
                      <div className="element-l">옵션</div>
                      <div className="element-r">
                        <div className="radio-btn-wrapper">
                          <img src={scheduleOption == 0 ? radio_on : radio_off} onClick={e => setScheduleOption(0)} />
                          <p>1회</p>
                        </div>
                        <div className="radio-btn-wrapper">
                          <img src={scheduleOption == 1 ? radio_on : radio_off} onClick={e => setScheduleOption(1)} />
                          <p>반복</p>
                        </div>
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="element-l" style={{ height: "68px" }}>
                        상세 스케줄
                      </div>
                      <div className="element-r-big schedule-list">
                        {timeSchedule.length !== 0 && timeSchedule.map(i => <div className="schedule-block">{i}</div>)}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="study-attitude">
                  <div className="attitude-title">공부 태도</div>
                  <div className="table-wrapper">
                    <div className="element-l">상벌점 설정</div>
                    <div className="element-r">
                      <div className="attitude-wrapper">
                        {attitudeList &&
                          attitudeList?.learnAttitudeRules.map((i, idx) => {
                            return (
                              <div className="attitude-box">
                                <img
                                  src={attitude.value === i?.learnAttitudeRuleSeq ? radio_on : radio_off}
                                  onClick={e => setAttitude({ text: i?.content, value: i?.learnAttitudeRuleSeq })}
                                />
                                <p>{i.content}</p>
                              </div>
                            )
                          })}
                      </div>
                    </div>
                    <div className="element-l">AI 민감도 설정</div>
                    <div className="element-r">
                      <div className="attitude-wrapper">
                        {aiList &&
                          aiList?.aiSensitiveRules.map((i, idx) => {
                            return (
                              <div className="attitude-box">
                                <img
                                  src={ai.value === i?.aiSensitiveRuleSeq ? radio_on : radio_off}
                                  onClick={e => setAi({ text: i?.content, value: i?.aiSensitiveRuleSeq })}
                                />
                                <p>{i.content}</p>
                              </div>
                            )
                          })}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="initial-setting">
                  <div className="setting-title">캠스터디 초기 장치 설정</div>
                  <div className="setting-area">
                    <div className="check-box" onClick={() => setUsingCamera(!usingCamera)}>
                      <img src={usingCamera ? checked_on : checked_off} />
                      <p>카메라</p>
                    </div>
                    <div className="check-box" onClick={() => setUsingMic(!usingMic)}>
                      <img src={usingMic ? checked_on : checked_off} />
                      <p>마이크</p>
                    </div>
                    <div className="check-box" onClick={() => setUsingSpeaker(!usingSpeaker)}>
                      <img src={usingSpeaker ? checked_on : checked_off} />
                      <p>스피커</p>
                    </div>
                  </div>
                </div>

                <div className="notice">
                  <div className="notice-title">캠스터디 내부 공지사항</div>
                  <textarea maxLength="50" value={camNotice} onChange={e => setCamNotice(e.target.value)} />
                  <div className="info-area">
                    <div className="text-max-info">공백 포함 최대 50자 입력</div>
                    <div className="text-num-wrapper">
                      <div className="text-num">{camNotice.length}자 / 50자</div>
                      <div className="btn btn-primary font-white fgb-btn-m reset-button" onClick={e => setCamNotice("")}>
                        초기화
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="to-do-wrapper">
                <div className="upper-filter-wrapper">
                  <div className="table-row">
                    <div className="element-l">TO-DO 타이틀</div>
                    <div className="element-r" style={{ width: "300px" }}>
                      <input
                        className="input-box"
                        placeholder="제목 공백 포함 최대 30자 입력"
                        maxLength="30"
                        value={studyTitle}
                        onChange={e => setStudyTitle(e.target.value)}
                        style={{ width: "270px" }}
                      />
                    </div>
                    <div className="element-l">비밀번호</div>
                    <div className="element-r" style={{ width: "480px", display: "flex", alignItems: "center" }}>
                      <div className="radio-btn-wrapper">
                        <img src={password == 0 ? radio_on : radio_off} onClick={e => setPassword(0)} />
                        <p>사용</p>
                      </div>
                      <div className="radio-btn-wrapper">
                        <img src={password == 1 ? radio_on : radio_off} onClick={e => setPassword(1)} />
                        <p>미사용</p>
                      </div>
                      <input
                        className="input-box"
                        id="password-input-box"
                        placeholder="최소 4자 입력"
                        value={passwordInput}
                        onChange={e => setPasswordInput(e.target.value)}
                        disabled={password === 0 ? false : true}
                        style={{ width: "190px" }}
                      />
                    </div>
                  </div>
                  <div className="table-row">
                    <div className="element-l">키워드</div>
                    <div className="element-r" style={{ width: "880px", display: "flex", alignItems: "center" }}>
                      {keywords.length !== 0 &&
                        keywords.map(i => (
                          <div className="keyword-box">
                            {i}
                            <img className="x-button" src={CloseButton} onClick={e => setKeywords(keywords.filter(item => item !== i))} />
                          </div>
                        ))}
                      {keywords.length < 5 && (
                        <input
                          className="input-box"
                          value={keywordInput}
                          placeholder="Enter or Tab"
                          onChange={e => setKeywordInput(e.target.value)}
                          style={{ width: "158px", textAlign: "center" }}
                          onKeyPress={e => {
                            if (e.key === "Enter" && e.target.value !== undefined) {
                              setKeywordInput()
                              setKeywords([...keywords, e.target.value])
                              e.target.value = ""
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="list">
                  <div className="list-title">TO-DO 목록</div>
                  <div className="table-wrapper">
                    <div className="element-l">과제 목록</div>
                    <div className="element-r">
                      <div className="list-info-wrapper">
                        <div className="people-num">{"5"}개</div>
                        <div className="info-two-buttons">
                          <div className="btn btn-primary font-white reverse fgb-btn-m clear-button" onClick={DeleteAssignment}>
                            선택 삭제
                          </div>
                          <div className="btn btn-primary font-white fgb-btn-m add-button" onClick={() => setAssignmentPopup(true)}>
                            과제 추가
                          </div>
                        </div>
                      </div>
                      <div className="list-table">
                        <div className="info-row">
                          <div className="element table-checkbox">
                            <img src={assignmentAllCheck ? checked_on : checked_off} onClick={() => setAssignmentAllCheck(!assignmentAllCheck)} />
                          </div>
                          <div className="element table-group">학습자 그룹</div>
                          <div className="element table-class">과목</div>
                          <div className="element table-curiculum">교재</div>
                          <div className="element table-range">범위</div>
                          <div className="element table-comment">코멘트</div>
                          <div className="element table-time">예상 소요 시간</div>
                        </div>

                        <div>
                          {RenderAssignment()}
                          {RenderBlankAssignment()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="subscriber">
                  <div className="subscriber-title">구독자</div>
                  <div className="table-wrapper">
                    <div className="element-l">구독자 목록</div>
                    <div className="element-r">
                      <div className="list-info-wrapper">
                        <div className="people-num">{subscriber.length}명</div>
                        <div className="info-two-buttons">
                          <div className="btn btn-primary font-white reverse fgb-btn-m clear-button" onClick={DeleteSubscriber}>
                            선택 삭제
                          </div>
                          <div className="btn btn-primary font-white fgb-btn-m add-button" onClick={() => setSubscriberPopup(true)}>
                            구독자 추가
                          </div>
                        </div>
                      </div>
                      <div className="subscriber-table">
                        <div className="info-row">
                          <div className="element table-checkbox">
                            <img src={subscriberAllCheck ? checked_on : checked_off} onClick={() => setSubscriberAllCheck(!subscriberAllCheck)} />
                          </div>
                          {/* <div className="element table-group">학습자 그룹</div> */}
                          <div className="element table-name">이름</div>
                          <div className="element table-nickname">닉네임</div>
                          <div className="element table-affiliation">소속</div>
                          <div className="element table-participated">참여중인 스터디</div>
                          <div className="element table-time">누적 공부 시간</div>
                          <div className="element table-sensitivity">AI 모션 민감도</div>
                          <div className="element table-rule">학습 규칙 준수</div>
                        </div>

                        <div>
                          {RenderSubscriber()}
                          {RenderBlankSubscriber()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="initial-setting">
                  <div className="setting-title">TO-DO 설정</div>
                  <div className="setting-area">
                    <div className="check-box">
                      <img src={isOpen ? checked_on : checked_off} onClick={() => setIsOpen(!isOpen)} />
                      <p>TO-DO 스케줄 공개</p>
                    </div>
                    <div className="check-box">
                      <img src={isShare ? checked_on : checked_off} onClick={() => setIsShare(!isShare)} />
                      <p>친구 공유 허용</p>
                    </div>
                    <div className="check-box">
                      <img src={isAlarm ? checked_on : checked_off} onClick={() => setIsAlarm(!isAlarm)} />
                      <p>TO-DO 알림 허용</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="two-buttons-wrapper">
              <div className="btn btn-primary font-white reverse fgb-btn-l cancle-button">취소</div>
              <div className="btn btn-primary font-white fgb-btn-l save-button" onClick={handleRegister}>
                저장
              </div>
            </div>
          </div>
        </Layout>
      </>
    </PrivateRoute>
  )
}

export default CommonContainer(ScheduleContainer(StudyScheduleReg))

const Days = {
  MON: "월",
  TUE: "화",
  WED: "수",
  THU: "목",
  FRI: "금",
  SAT: "토",
  SUN: "일",
}
