import { navigate } from "gatsby-link"
import { createAction, handleActions } from "redux-actions"
import AffiliatesMainApis from "../../apis/AffiliatesMainApis"

const SET_PARTNER_MAIN_STUDYTIME = "affiliatesMain/SET_PARTNER_MAIN_STUDYTIME"
const SET_PARTNER_MAIN_MEMBER = "affiliatesMain/SET_PARTNER_MAIN_MEMBER"
const SET_PARTNER_MAIN_STUDY = "affiliatesMain/SET_PARTNER_MAIN_STUDY"
const SET_PARTNER_MAIN_TODO = "affiliatesMain/SET_PARTNER_MAIN_TODO"
const SET_PARTNER_MAIN_STUDIES = "affiliatesMain/SET_PARTNER_MAIN_STUDIES"
const SET_PARTNER_MAIN_MY_STUDIES = "affiliatesMain/SET_PARTNER_MAIN_MY_STUDIES"
const SET_PARTNER_MAIN_MY_STUDIES_SCHEDULES = "affiliatesMain/SET_PARTNER_MAIN_MY_STUDIES_SCHEDULES"
const SET_PARTNER_MAIN_MY_TODOS = "affiliatesMain/SET_PARTNER_MAIN_MY_TODOS"
const SET_PARTNER_MAIN_RANK_KEYWORD = "affiliatesMain/SET_PARTNER_MAIN_RANK_KEYWORD"
const SET_PARTNER_MAIN_RANK_STUDY = "affiliatesMain/SET_PARTNER_MAIN_RANK_STUDY"
const SET_PARTNER_MAIN_RANK_TIME = "affiliatesMain/SET_PARTNER_MAIN_RANK_TIME"
const SET_PARTNER_MAIN_MEMBER_RECENTLY = "affiliatesMain/SET_PARTNER_MAIN_MEMBER_RECENTLY"

export const setPartnerMainStudyTime = createAction(SET_PARTNER_MAIN_STUDYTIME)
export const setPartnerMainMember = createAction(SET_PARTNER_MAIN_MEMBER)
export const setPartnerMainStudy = createAction(SET_PARTNER_MAIN_STUDY)
export const setPartnerMainDoto = createAction(SET_PARTNER_MAIN_TODO)
export const setPartnerMainStudies = createAction(SET_PARTNER_MAIN_STUDIES)
export const setPartnerMainMyStudies = createAction(SET_PARTNER_MAIN_MY_STUDIES)
export const setPartnerMainMyStudiesSchedules = createAction(SET_PARTNER_MAIN_MY_STUDIES_SCHEDULES)
export const setPartnerMainTodos = createAction(SET_PARTNER_MAIN_MY_TODOS)
export const setPartnerMainRankKeyword = createAction(SET_PARTNER_MAIN_RANK_KEYWORD)
export const setPartnerMainRankStudy = createAction(SET_PARTNER_MAIN_RANK_STUDY)
export const setPartnerMainRankTime = createAction(SET_PARTNER_MAIN_RANK_TIME)
export const setPartnerMainMemberRecently = createAction(SET_PARTNER_MAIN_MEMBER_RECENTLY)

const initState = {
  partnerMainStudyTime: {},
  partnerMainMember: {},
  partnerMainStudy: {},
  partnerMainTodo: {},
  partnerMainStudyList: [],
  partnerMainMyStudies: [],
  partnerMainMyStudiesSchedules: {},
  partnerMainMyTodos: [],
  partnerMainRankKeyword: [],
  partnerMainRankStudy: [],
  partnerMainRankTime: [],
  partnerMainMemberRecently: [],
}

export default handleActions(
  {
    [SET_PARTNER_MAIN_STUDYTIME]: (state, action) => ({
      ...state,
      partnerMainStudyTime: action.payload,
    }),
    [SET_PARTNER_MAIN_MEMBER]: (state, action) => ({
      ...state,
      partnerMainMember: action.payload,
    }),
    [SET_PARTNER_MAIN_STUDY]: (state, action) => ({
      ...state,
      partnerMainStudy: action.payload,
    }),
    [SET_PARTNER_MAIN_TODO]: (state, action) => ({
      ...state,
      partnerMainTodo: action.payload,
    }),
    [SET_PARTNER_MAIN_STUDIES]: (state, action) => ({
      ...state,
      partnerMainStudyList: action.payload,
    }),
    [SET_PARTNER_MAIN_MY_STUDIES]: (state, action) => ({
      ...state,
      partnerMainMyStudies: action.payload,
    }),
    [SET_PARTNER_MAIN_MY_STUDIES_SCHEDULES]: (state, action) => ({
      ...state,
      partnerMainMyStudiesSchedules: action.payload,
    }),
    [SET_PARTNER_MAIN_MY_TODOS]: (state, action) => ({
      ...state,
      partnerMainMyTodos: action.payload,
    }),
    [SET_PARTNER_MAIN_RANK_KEYWORD]: (state, action) => ({
      ...state,
      partnerMainRankKeyword: action.payload,
    }),
    [SET_PARTNER_MAIN_RANK_STUDY]: (state, action) => ({
      ...state,
      partnerMainRankStudy: action.payload,
    }),
    [SET_PARTNER_MAIN_RANK_TIME]: (state, action) => ({
      ...state,
      partnerMainRankTime: action.payload,
    }),
    [SET_PARTNER_MAIN_MEMBER_RECENTLY]: (state, action) => ({
      ...state,
      partnerMainMemberRecently: action.payload,
    }),
  },
  initState
)
/* action api function */

//총 누적 공부시간
export const getStatisticsStudyTimeAction = param => async dispatch => {
  const result = await AffiliatesMainApis.getStatisticsStudyTime(param)
  if (result.code == 0) {
    dispatch(setPartnerMainStudyTime(result.data.accumulatedStudyTimeInformation))
    return true
  } else {
    dispatch(setPartnerMainStudyTime({}))
    return false
  }
}
//총 누적 회원수
export const getStatisticsMembersAction = param => async dispatch => {
  const result = await AffiliatesMainApis.getStatisticsMembers(param)
  if (result.code == 0) {
    dispatch(setPartnerMainMember(result.data.accumulatedMemberInformation))
    return true
  } else {
    dispatch(setPartnerMainMember({}))
    return false
  }
}
//총 누적 스터디
export const getStatisticsStudyCountAction = param => async dispatch => {
  const result = await AffiliatesMainApis.getStatisticsStudyCount(param)
  if (result.code == 0) {
    dispatch(setPartnerMainStudy(result.data.accumulatedStudyCountInfomation))
    return true
  } else {
    dispatch(setPartnerMainStudy({}))
    return false
  }
}
//총 누적 Todo
export const getStatisticsTodoCountAction = param => async dispatch => {
  const result = await AffiliatesMainApis.getStatisticsTodoCount(param)
  if (result.code == 0) {
    dispatch(setPartnerMainDoto(result.data.accumulatedTodoCountInformation))
    return true
  } else {
    dispatch(setPartnerMainDoto({}))
    return false
  }
}
//공부 누적시간 현황
export const getStatisticsStudiesAction = param => async dispatch => {
  const result = await AffiliatesMainApis.getStatisticsStudies(param)
  if (result.code == 0) {
    dispatch(setPartnerMainStudies(result.data.informationGroupByStudy))
    return true
  } else {
    dispatch(setPartnerMainStudies([]))
    return false
  }
}
//내 스터디 리스트
export const getMyStudiesAction = param => async dispatch => {
  const result = await AffiliatesMainApis.getMyStudies(param)
  if (result.code == 0) {
    dispatch(setPartnerMainMyStudies(result.data.studies))
    return true
  } else {
    dispatch(setPartnerMainMyStudies([]))
    return false
  }
}
//내 스터디 타겟 스케줄 정보
export const getMyStudiesSchedulesAction = param => async dispatch => {
  const result = await AffiliatesMainApis.getMyStudiesSchedules(param)
  if (result.code == 0) {
    dispatch(setPartnerMainMyStudiesSchedules(result.data.detailStudySchedules))
    return true
  } else {
    dispatch(setPartnerMainMyStudiesSchedules({}))
    return false
  }
}
//내 투두 리스트
export const getMyTodosAction = param => async dispatch => {
  const result = await AffiliatesMainApis.getMyTodos(param)
  if (result.code == 0) {
    dispatch(setPartnerMainTodos(result.data.todos))
    return true
  } else {
    dispatch(setPartnerMainTodos([]))
    return false
  }
}
//랭킹 키워드
export const getRankingStudyKeywordAction = param => async dispatch => {
  const result = await AffiliatesMainApis.getRankingStudyKeyword(param)
  if (result.code == 0) {
    dispatch(setPartnerMainRankKeyword(result.data.keywordStatistics))
    return true
  } else {
    dispatch(setPartnerMainRankKeyword([]))
    return false
  }
}
//랭킹 스터디
export const getRankingStudyBestAction = param => async dispatch => {
  const result = await AffiliatesMainApis.getRankingStudyBest(param)
  if (result.code == 0) {
    dispatch(setPartnerMainRankStudy(result.data.studyStatistics))
    return true
  } else {
    dispatch(setPartnerMainRankStudy([]))
    return false
  }
}
//랭킹 스터디 누적시간
export const getRankingStudyTimeAction = param => async dispatch => {
  const result = await AffiliatesMainApis.getRankingStudyTime(param)
  if (result.code == 0) {
    dispatch(setPartnerMainRankTime(result.data.userStudyStatistics))
    return true
  } else {
    dispatch(setPartnerMainRankTime([]))
    return false
  }
}
//랭킹 스터디 누적시간
export const getMemberRecentlyAction = param => async dispatch => {
  const result = await AffiliatesMainApis.getMemberRecently(param)
  if (result.code == 0) {
    dispatch(setPartnerMainMemberRecently(result.data.membersRecently))
    return true
  } else {
    dispatch(setPartnerMainMemberRecently([]))
    return false
  }
}
