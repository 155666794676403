import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { WidthToDP } from "../../utils/util"
import right_arrow_page from "../../images/right_arrow_page.svg"
import left_arrow_page from "../../images/left_arrow_page.svg"

const Pagination = props => {
  const { fn = num => {}, current, setCurrent, total, count = 10, style = {} } = props

  const pageCount = 5
  const itemCount = count

  const toFirstPage = () => {
    fn(1)
    setCurrent(1)
  }
  const toPrevPage = () => {
    if (current > 1) {
      fn(current - 1)
      setCurrent(current - 1)
    }
  }
  const toNextPage = () => {
    if (total == 0) return false
    if (current < Math.ceil(total / itemCount)) {
      fn(current + 1)
      setCurrent(current + 1)
    }
  }
  const toLastPage = () => {
    // console.log(total, itemCount)
    if (total == 0) return false
    fn(Math.ceil(total / itemCount))
    setCurrent(Math.ceil(total / itemCount))
  }

  const renderPages = () => {
    let firstNum = 1
    if (current > 5) firstNum += Math.floor((current - 1) / pageCount) * pageCount

    return Array(pageCount)
      .fill(0)
      .map((pagerItem, pagerIndex) => {
        if (firstNum + pagerIndex <= Math.ceil(total / itemCount)) {
          return (
            <PagerNumButton
              key={pagerIndex}
              color={current === firstNum + pagerIndex ? "#00C3C5" : "none"}
              onClick={() => {
                setCurrent(firstNum + pagerIndex)
                fn(firstNum + pagerIndex)
              }}
            >
              <PagerItemText color={current === firstNum + pagerIndex ? "white" : "#334D6E"}>{firstNum + pagerIndex}</PagerItemText>
            </PagerNumButton>
          )
        }
      })
  }

  return (
    <PagerContainer style={{ ...style }}>
      <PagerBox>
        <PagerCircleButton color={"transparent"} onClick={toPrevPage}>
          <img src={left_arrow_page} alt={"처음으로 아이콘"} />
        </PagerCircleButton>

        {(Math.ceil(total / count) || 1) > 5 && (
          <button onClick={toFirstPage} style={{ margin: "0px 20px", color: "#334D6E", fontSize: "15px" }}>
            1
          </button>
        )}
        {(Math.ceil(total / count) || 1) > 5 && <div style={{ margin: "0px 20px", color: "#334D6E", fontSize: "15px" }}>…</div>}
        {renderPages()}
        {(Math.ceil(total / count) || 1) > 5 && <div style={{ margin: "0px 20px", color: "#334D6E", fontSize: "15px" }}>…</div>}
        {(Math.ceil(total / count) || 1) > 5 && (
          <button onClick={toLastPage} style={{ margin: "0px 20px", color: "#334D6E", fontSize: "15px" }}>
            {Math.ceil(total / count) || 1}
          </button>
        )}

        <PagerCircleButton color={"transparent"} onClick={toNextPage}>
          <img src={right_arrow_page} alt={"처음으로 아이콘"} />
        </PagerCircleButton>
      </PagerBox>
    </PagerContainer>
  )
}

const PagerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const PagerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
`
const PagerCircleButton = styled.button`
  background-color: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 45px;
  border: ${props => (props.color === "transparent" ? "none" : `${WidthToDP(1)}px solid #c7c7c7`)};
`
const PagerNumButton = styled.button`
  background: ${props => props.color};

  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 36px;
  min-height: 36px;
  border-radius: 6px;

  /* margin-right: ${WidthToDP(8)}px; */
  /* border: ${props => (props.color === "transparent" ? "none" : `${WidthToDP(1)}px solid #c7c7c7`)}; */
  &:nth-child(3) {
    /* margin-left: ${WidthToDP(8)}px; */
  }
  &:hover {
    background: #f1f1f1;
  }
`
const PagerItemText = styled.p`
  color: ${props => props.color};
  font-size: 13px;
  /* line-height: 19px; */
`

Pagination.propTypes = {
  current: PropTypes.any,
  setCurrent: PropTypes.func,
  total: PropTypes.number,
}

export default Pagination
