import { navigate } from "gatsby-link"
import { createAction, handleActions } from "redux-actions"
import AdminMainApis from "../../apis/AdminMainApis"

const SET_ADMIN_MAIN_STUDY = "adminMain/SET_ADMIN_MAIN_STUDY"
const SET_ADMIN_MAIN_SIGN_IN_TIME = "adminMain/SET_ADMIN_MAIN_SIGN_IN_TIME"
const SET_ADMIN_MAIN_CUSTOMER = "adminMain/SET_ADMIN_MAIN_CUSTOMER"

export const setAdminMainSutdy = createAction(SET_ADMIN_MAIN_STUDY)
export const setAdminMainSignInTime = createAction(SET_ADMIN_MAIN_SIGN_IN_TIME)
export const setAdminMainCustomer = createAction(SET_ADMIN_MAIN_CUSTOMER)

const initState = {
  adminMainStudy: [],
  adminMainSignInTime: [],
  adminMainCustomer: [],
}

export default handleActions(
  {
    [SET_ADMIN_MAIN_STUDY]: (state, action) => ({
      ...state,
      adminMainStudy: action.payload,
    }),
    [SET_ADMIN_MAIN_SIGN_IN_TIME]: (state, action) => ({
      ...state,
      adminMainSignInTime: action.payload,
    }),
    [SET_ADMIN_MAIN_CUSTOMER]: (state, action) => ({
      ...state,
      adminMainCustomer: action.payload,
    }),
  },
  initState
)
/* action api function */

//학습자 그룹 스터디 개설 현황
export const getStatisticsStudentGroupStudyAction = param => async dispatch => {
  const result = await AdminMainApis.getStatisticsStudentGroupStudy(param)
  if (result.code == 0) {
    dispatch(setAdminMainSutdy(result.data.statisticsStudentGroupStudy))
    return true
  } else {
    dispatch(setAdminMainSutdy([]))
    return false
  }
}
//시간대별 이용 현황
export const getStatisticsSignInTimeAction = param => async dispatch => {
  const result = await AdminMainApis.getStatisticsSignInTime(param)
  if (result.code == 0) {
    dispatch(setAdminMainSignInTime(result.data.statisticMonthly))
    return true
  } else {
    dispatch(setAdminMainSignInTime([]))
    return false
  }
}
//회원가입 현황
export const getStatisticsCustomerAction = param => async dispatch => {
  const result = await AdminMainApis.getStatisticsCustomer(param)
  if (result.code == 0) {
    dispatch(setAdminMainCustomer(result.data.customerSignUpStatistics))
    return true
  } else {
    dispatch(setAdminMainCustomer([]))
    return false
  }
}
