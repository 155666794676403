import api from "../utils/api"

// 꾸미기 아이템 목록 조회
const getDecorationItemList = async data => {
  const result = await api.get(
    `/service-product/items?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&paymentType=${data.paymentType}&isDisplay=${data.isDisplay}&type=${data.type}&minPoint=${data.minPoint}&maxPoint=${data.maxPoint}&page=${data.page}&count=15`,
    {
      token: true,
      body: data,
    }
  )
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 꾸미기 아이템 목록 엑셀 다운로드
const getDecorationItemListExcel = async data => {
  const result = await api.get(
    `/service-product/items/excel/download?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&paymentType=${data.paymentType}&isDisplay=${data.isDisplay}&type=${data.type}&minPoint=${data.minPoint}&maxPoint=${data.maxPoint}&page=${data.page}&count=15`,
    {
      token: true,
      body: data,
      options: { responseType: "blob" },
    }
  )
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 꾸미기 아이템 정보 조회
const getDecorationItemInfo = async data => {
  const result = await api.get(`/service-product/item?itemPackageSeq=${data.itemPackageSeq}&type=${data.type}`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 꾸미기 아이템 수정
const putDecorationItem = async data => {
  const result = await api.put(`/service-product/item`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 꾸미기 아이템 추가
const postDecorationItem = async data => {
  const result = await api.post(`/service-product/item`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 컨텐츠 목록 조회
const getContentList = async data => {
  const result = await api.get(
    `/service-product/contents?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&type=${data.type}&isDisplay=${data.isDisplay}&brandName=${data.brandName}&categoryDepth1=${data.categoryDepth1}&categoryDepth2=${data.categoryDepth2}&categoryDepth3=${data.categoryDepth3}&studentGroupSeq=${data.studentGroupSeq}&priceType=${data.priceType}&minPrice=${data.minPrice}&maxPrice=${data.maxPrice}&teacherName=${data.teacherName}&isTeacherPhoto=${data.isTeacherPhoto}&isVideoUrl=${data.isVideoUrl}&page=${data.page}&count=15`,
    {
      token: true,
      body: data,
    }
  )
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 컨텐츠 목록 엑셀 다운로드
const getContentListExcel = async data => {
  const result = await api.get(
    `/service-product/contents/excel/download?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&type=${data.type}&isDisplay=${data.isDisplay}&brandName=${data.brandName}&categoryDepth1=${data.categoryDepth1}&categoryDepth2=${data.categoryDepth2}&categoryDepth3=${data.categoryDepth3}&studentGroupSeq=${data.studentGroupSeq}&priceType=${data.priceType}&minPrice=${data.minPrice}&maxPrice=${data.maxPrice}&teacherName=${data.teacherName}&isTeacherPhoto=${data.isTeacherPhoto}&isVideoUrl=${data.isVideoUrl}&page=${data.page}&count=15`,
    {
      token: true,
      body: data,
      options: { responseType: "blob" },
    }
  )
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 컨텐츠 정보 조회
const getContentInfo = async data => {
  const result = await api.get(`/service-product/content?contentSeq=${data.contentSeq}`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 컨텐츠 등록
const postContent = async data => {
  const result = await api.post(`/service-product/content`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 컨텐츠 수정
const putContent = async data => {
  const result = await api.put(`/service-product/content`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

export default {
  getDecorationItemList,
  getDecorationItemListExcel,
  getDecorationItemInfo,
  putDecorationItem,
  postDecorationItem,
  getContentList,
  getContentListExcel,
  getContentInfo,
  postContent,
  putContent,
}
