import api from "../utils/api"

// 추천 프로그램 목록

// dateStart, dateEnd (YYYY-MM-DD)
// isPoint 구분(=포인트 사용 여부), {''(전체)|'Y'(무료)|'N'(유료)}
// studentGroupSeq 학습자 그룹(=학습자 소속 그룹_level1 코드), {''(전체)|string}
// isUse 사용 여부, {''(전체)|'Y'(사용)|'N'(미사용)}
// tags 키워드, [string, ...]
// regDatetime [{"key":"regDatetime","value":"","equals":true,"description":"생성일(=등록일시), {''|'YYYY-MM-DD'}","enabled":true}]
// page 현재 페이지
// count 한 페이지당 row 수
const getRecommendationProgramList = async data => {
  const result = await api.get(
    `/study-schedule/recommend-programs?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&isPoint=${data.isPoint}&studentGroupSeq=${data.studentGroupSeq}&isUse=${data.isUse}&tags=${data.tags}&regDatetime=${data.regDatetime}&page=${data.page}&count=15`,
    {
      token: true,
      body: data,
    }
  )
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 추천 프로그램 정보
const getRecommendationProgramInfo = async data => {
  const result = await api.get(`/study-schedule/recommend-program?recommendProgramSeq=${data.recommendProgramSeq}`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 추천 프로그램 저장/수정
const postRecommendationProgram = async data => {
  const result = await api.post(`/study-schedule/recommend-program`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 추천 프로그램 삭제
const delRecommendationProgram = async data => {
  const result = await api.delete(`/study-schedule/recommend-programs`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 추천 프로그램 엑셀 다운로드
const getRecommendationProgramExcel = async data => {
  const result = await api.get(
    `/study-schedule/recommend-programs/excel/download?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&isPoint=${data.isPoint}&studentGroupSeq=${data.studentGroupSeq}&isUse=${data.isUse}&tags=${data.tags}&regDatetime=${data.regDatetime}&page=${data.page}&count=15`,
    {
      token: true,
      options: { responseType: "blob" },
    }
  )
  if (result) {
    return result.data
  } else {
    return false
  }
}

export default {
  getRecommendationProgramList,
  getRecommendationProgramInfo,
  postRecommendationProgram,
  delRecommendationProgram,
  getRecommendationProgramExcel,
}
