import React, { useState, useEffect, useRef } from "react"
import moment from "moment"
import CalendarComponent from "../../components/Calendar"
import Pagination from "../../components/common/pagination"

import ServiceContainer from "../../containers/ServiceContainer"
import SignContainer from "../../containers/SignContainer"

import CloseButton from "../../images/img_popup_close_black.svg"
import icon_calendar from "../../images/ion_calendar-number-outline.svg"
import download from "../../images/download.svg"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"

import ModalPreviewPopup from "../../popups/ModalPreviewPopup"
import { popupFn } from "../../utils/util"

const ItemPopup = props => {
  const { data, onClose, postUploadFile, putDecorationItem, getDecorationItemInfo, decorationItemInfo } = props

  const isMounted = useRef(false)

  const [type, setType] = useState(0) // 타이틀 Radio 버튼 (0: 필터,  1: 스티커)
  const [titleInput, setTitleInput] = useState("") // 타이틀 Input 박스 기본값: null
  const [owner, setOwner] = useState("") // 개설자 Input 박스 기본값: null
  const [keywordInput, setKeywordInput] = useState("") // 키워드 Input 박스 기본값: null
  const [keywords, setKeywords] = useState([]) // 키워드 들어있는 배열

  const [allCheck, setAllCheck] = useState(false) // 전체 체크
  const [checkList, setCheckList] = useState([])
  const [files, setFiles] = useState([])
  const [filesInfo, setFilesInfo] = useState([])
  const [existedFiles, setExistedFiles] = useState([]) // 원래 있던 파일들

  const [openPreview, setOpenPreview] = useState({ contents: {}, open: "false" })

  useEffect(() => {
    async function getLists() {
      if (data) {
        await getDecorationItemInfo({
          itemPackageSeq: data.itemPackageSeq,
          type: data.type,
        })
      }
    }

    async function resetLists() {
      await getDecorationItemInfo()
    }

    getLists()

    return () => {
      resetLists()
    }
  }, [])

  useEffect(() => {
    if (decorationItemInfo !== undefined) {
      setType(decorationItemInfo?.item?.type === "FILTER" ? 0 : 1)
      setTitleInput(decorationItemInfo?.item?.title)
      setOwner(decorationItemInfo?.item?.name)
      setKeywords(decorationItemInfo?.item?.tags)
      setExistedFiles(decorationItemInfo?.item?.items)
      let _files_ = []
      let _files_info_ = []
      for (let i = 0; i < decorationItemInfo?.item?.items.length; i++) {
        _files_.push({
          name: decorationItemInfo?.item?.items[i].fileName,
          url: decorationItemInfo?.item?.items[i].fileUrl,
        })
        _files_info_.push({
          isFree: decorationItemInfo?.item?.items[i].paymentType === "FREE" ? "무료" : "유료",
          fileName: decorationItemInfo?.item?.items[i].fileName,
          point: decorationItemInfo?.item?.items[i].point,
          isDisplay: decorationItemInfo?.item?.items[i].isDisplay === "Y" ? 0 : 1,
          fileUrl: decorationItemInfo?.item?.items[i].fileUrl,
          itemSeq: decorationItemInfo?.item?.items[i].itemSeq,
        })
      }
      setFilesInfo(_files_info_)
      setFiles(_files_)
    }
  }, [decorationItemInfo])

  const ChangeDisplay = (idx, type) => {
    setFilesInfo(
      filesInfo.map((i, index) =>
        idx === index
          ? {
              ...i,
              isDisplay: type,
            }
          : i
      )
    )
  }

  const ChangePoint = (idx, pointValue) => {
    setFilesInfo(
      filesInfo.map((i, index) =>
        idx === index
          ? {
              ...i,
              point: pointValue,
            }
          : i
      )
    )
  }

  const ChangeIsFree = (idx, value) => {
    setFilesInfo(
      filesInfo.map((i, index) =>
        idx === index
          ? {
              ...i,
              isFree: value,
            }
          : i
      )
    )
  }

  useEffect(() => {
    let list_ = []
    for (let i = 0; i < filesInfo.length; i++) {
      list_.push(false)
    }
    setCheckList(list_)
  }, [filesInfo])

  useEffect(() => {
    let list_ = []
    for (let i = 0; i < filesInfo.length; i++) {
      list_.push(allCheck)
    }
    setCheckList(list_)
  }, [allCheck])

  const RenderItems = () => {
    let result = []
    // console.log("filesInfo", filesInfo)
    for (let i = 0; i < filesInfo.length; i++) {
      result.push(
        <div className="filter-table-filter-row">
          <div style={{ width: "40px" }}>
            <img
              src={checkList[i] ? checked_on : checked_off}
              onClick={() => {
                let list_ = []
                for (let j = 0; j < filesInfo.length; j++) {
                  if (j === i) {
                    list_.push(!checkList[j])
                  } else {
                    list_.push(checkList[j])
                  }
                }
                setCheckList(list_)
              }}
            />
          </div>
          <div
            onClick={() => {
              ChangeIsFree(i, filesInfo[i]?.isFree == "무료" ? "유료" : "무료")
            }}
            style={{ width: "80px" }}
          >
            <input
              disabled
              className="input-box"
              value={filesInfo[i]?.isFree}
              style={{ width: "64px", textAlign: "center", margin: "0px", cursor: "pointer" }}
            />
          </div>
          <div style={{ width: "300px" }}>{filesInfo[i]?.fileName}</div>
          <div style={{ width: "80px" }}>
            <input
              className="input-box"
              value={filesInfo[i]?.isFree == "유료" ? filesInfo[i]?.point : 0}
              onChange={e => ChangePoint(i, e.target.value)}
              style={{ width: "64px", textAlign: "center", margin: "0px" }}
            />
          </div>
          <div onClick={() => setOpenPreview({ open: true, contents: { fileUrl: filesInfo[i]?.fileUrl } })} style={{ width: "80px" }}>
            <div
              className="btn btn-primary font-white reverse fgb-btn-m"
              style={{ border: "1px solid", height: "38px", fontSize: "12px", width: "65px", padding: "0", margin: "11px auto" }}
            >
              미리보기
            </div>
          </div>
          <div style={{ width: "220px" }} style={{ display: "flex", width: "220px", justifyContent: "center" }}>
            <div className="radio-btn-wrapper" onClick={() => ChangeDisplay(i, 0)} style={{ border: "none" }}>
              <img src={filesInfo[i]?.isDisplay === 0 ? radio_on : radio_off} />
              <p>전시</p>
            </div>
            <div className="radio-btn-wrapper" onClick={() => ChangeDisplay(i, 1)}>
              <img src={filesInfo[i]?.isDisplay === 1 ? radio_on : radio_off} />
              <p>미전시</p>
            </div>
          </div>
        </div>
      )
    }

    return result
  }

  const RenderBlankBoxes = () => {
    const blankBoxesLength = 5 - files.length
    let result = []

    for (let i = 0; i < blankBoxesLength; i++) {
      result.push(
        <div className="filter-table-filter-row">
          <div style={{ width: "40px" }}>
            <img src={checked_off} />
          </div>
          <div style={{ width: "80px" }}></div>
          <div style={{ width: "300px" }}></div>
          <div style={{ width: "80px" }}></div>
          <div style={{ width: "80px" }}></div>
          <div style={{ width: "220px" }}></div>
        </div>
      )
    }

    return result
  }

  const deleteFiles = () => {
    setFilesInfo(filesInfo.filter((i, idx) => (checkList[idx] ? "" : i)))
    setFiles(files.filter((i, idx) => (checkList[idx] ? "" : i)))
    setAllCheck(false)
  }

  const fileChangeHandler = event => {
    const fileArray = event.target.files
    let idx_ = []
    let idx_name = []
    for (let i = 0; i < fileArray.length; i++) {
      idx_.push(fileArray[i])
      idx_name.push({
        isFree: "무료",
        fileName: fileArray[i]?.name,
        point: 0,
        isDisplay: 0,
      })
    }
    setFiles([...files, ...idx_])
    setFilesInfo([...filesInfo, ...idx_name])
  }

  const handleSave = async () => {
    const type_ = type === 0 ? "FILTER" : "STICKER"
    const title_ = titleInput
    const tags_ = keywords
    let insert_items = []
    let delete_items = []

    let isSuccess = true

    for (let i = 0; i < files.length; i++) {
      if (files[i].size !== undefined) {
        const formData = new FormData()
        await formData.append("file", files[i])
        const result = await postUploadFile(formData)
        if (!result) {
          isSuccess = false
        } else {
          insert_items.push({
            paymentType: filesInfo[i]?.isFree === "무료" ? "FREE" : "PAY",
            fileName: filesInfo[i]?.fileName,
            fileUrl: result,
            filterSource: "",
            point: filesInfo[i]?.isFree === "무료" || filesInfo[i]?.isFree === "FREE" ? 0 : filesInfo[i]?.point,
            isDisplay: filesInfo[i]?.isDisplay === 0 ? "Y" : "N",
          })
        }
      }
    }

    for (let i = 0; i < existedFiles.length; i++) {
      let exist_ = false
      for (let j = 0; j < filesInfo.length; j++) {
        if (existedFiles[i]?.fileName === filesInfo[j]?.fileName) {
          exist_ = true
        }
      }
      if (!exist_) {
        delete_items.push(existedFiles[i]?.itemSeq)
      }
    }
    let temp = []
    for (let data of filesInfo) {
      if (data.itemSeq) {
        let check = true
        for (let j of delete_items) {
          if (j == data.itemSeq) {
            check = false
          }
        }
        data.isDisplay = data.isDisplay == 0 ? "Y" : "N"
        data.paymentType = data.isFree === "무료" ? "FREE" : "PAY"
        if (check) {
          temp = [...temp, data]
        }
      }
    }
    if (isSuccess) {
      const payload = {
        itemPackageSeq: data?.itemPackageSeq,
        type: type_,
        title: title_,
        deleteItems: delete_items,
        insertItems: insert_items,
        updateItems: temp,
        tags: tags_,
      }
      // console.log(payload)
      // console.log("temp", temp)
      // console.log("filesInfo", filesInfo)

      const result_ = await putDecorationItem(payload)

      // console.log(result_)
      if (result_) {
        popupFn("저장되었습니다.")
        onClose()
      }
    }
  }

  return (
    <div className="schedule-popup-container item-popup-container">
      <ModalPreviewPopup open={openPreview.open} onClose={() => setOpenPreview({ contents: {}, open: "false" })} contents={openPreview.contents} />
      <div className="schedule-popup-wrapper" style={{ height: "860px", padding: "0 30px" }}>
        <img src={CloseButton} alt="close-popup-button" className="close-popup-button" onClick={onClose} />
        <div className="schedule-popup-title">꾸미기 아이템 상세 보기</div>

        <div className="adornment-reg-upper-table-wrapper">
          <div className="element-l right-border">타이틀</div>
          <div className="element-r right-border" style={{ width: "550px" }}>
            <div className="radio-btn-wrapper" style={{ padding: "0", marginRight: "15px" }}>
              <img src={type == 0 ? radio_on : radio_off} />
              <p>필터</p>
            </div>
            <div className="radio-btn-wrapper" style={{ padding: "0", marginRight: "15px" }}>
              <img src={type == 1 ? radio_on : radio_off} />
              <p>스티커</p>
            </div>
            <input
              className="input-box"
              placeholder="제목 공백 포함 최대 10자 입력"
              maxLength="10"
              value={titleInput}
              onChange={e => setTitleInput(e.target.value)}
              style={{ width: "350px", marginRight: "10px" }}
            />
          </div>
          <div className="element-l right-border">개설자</div>
          <div className="element-r" style={{ width: "230px" }}>
            <input
              className="input-box"
              placeholder=""
              value={owner}
              onChange={e => setOwner(e.target.value)}
              style={{ width: "190px", marginRight: "10px" }}
            />
          </div>
        </div>

        <div style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }} className="adornment-reg-down-table-wrapper">
          <div className="table-row">
            <div className="element-l right-border">키워드</div>
            <div className="element-r" style={{ width: "880px" }}>
              {keywords.length !== 0 &&
                keywords.map(i => (
                  <div className="keyword-box">
                    {i}
                    <img className="x-button" src={CloseButton} onClick={e => setKeywords(keywords.filter(item => item !== i))} />
                  </div>
                ))}
              {keywords.length < 5 && (
                <input
                  className="input-box"
                  value={keywordInput}
                  placeholder="Enter or Tab"
                  onChange={e => setKeywordInput(e.target.value)}
                  style={{ width: "158px", textAlign: "center" }}
                  onKeyPress={e => {
                    if (e.key === "Enter" && e.target.value !== undefined) {
                      setKeywordInput()
                      setKeywords([...keywords, e.target.value])
                      e.target.value = ""
                    }
                  }}
                />
              )}
            </div>
          </div>
          <div className="item-list-container">
            <div className="item-list-wrapper">
              <div className="item-list-left-part">아이템 목록</div>
              <div className="item-list-right-part">
                <div className="buttons">
                  <p>{"5"}개</p>
                  <div>
                    <div className="btn btn-primary font-white reverse fgb-btn-m" onClick={deleteFiles}>
                      선택 삭제
                    </div>
                    <input
                      id="file-add"
                      type="file"
                      multiple
                      style={{ display: "none" }}
                      onChange={fileChangeHandler}
                      accept=".jpg, .png, .gif, .svg, .jpeg"
                    />
                    <label
                      htmlFor="file-add"
                      className="btn btn-primary font-white fgb-btn-m"
                      style={{ width: "85px", height: "35px", marginLeft: "5px" }}
                    >
                      추가
                    </label>
                  </div>
                </div>
                <div className="add-filter-table-wrapper" style={{ overflowY: "scroll" }}>
                  <div className="filter-table-detail-row">
                    <div style={{ width: "40px" }}>
                      <img onClick={e => setAllCheck(!allCheck)} src={allCheck ? checked_on : checked_off} />
                    </div>
                    <div style={{ width: "80px" }}>구분</div>
                    <div style={{ width: "300px" }}>파일명</div>
                    <div style={{ width: "80px" }}>포인트</div>
                    <div style={{ width: "80px" }}>미리 보기</div>
                    <div style={{ width: "220px" }}>화면 표시</div>
                  </div>

                  <div>
                    {RenderItems()}
                    {RenderBlankBoxes()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="two-buttons-wrapper">
          <div className="btn btn-primary font-white reverse fgb-btn-l cancle-button" onClick={onClose}>
            취소
          </div>
          <div className="btn btn-primary font-white fgb-btn-l save-button" onClick={handleSave}>
            저장
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignContainer(ServiceContainer(ItemPopup))
