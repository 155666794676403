/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect } from "react"

import "swiper/scss"

import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import CalendarComponent from "../../components/Calendar"
import TopInfo from "../../components/common/TopInfo"
import Pagination from "../../components/common/pagination"
import OperationContainer from "../../containers/OperationContainer"
import SignContainer from "../../containers/SignContainer"

import AffiliatesInfoPopup from "../../popups/operation/AffiliatesInfoPopup"

import moment from "moment"

import icon_calendar from "../../images/ion_calendar-number-outline.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import download from "../../images/download.svg"

import ModalPopup from "../../popups/ModalPopup"

import PrivateRoute from "../../components/privateRoute"
import { downExcel, popupFn } from "../../utils/util"
const STATUS = { REJECT: "반려", WAITING: "요청", APPROVAL: "승인" }
const TYPE = { CRAM_SCHOOL: "학원", STUDY_CAFE: "스터디 카페", CONSULTANT: "컨설턴트" }
const Affiliates = props => {
  const {
    partnersListTotalEmpty,
    partnersListEmpty,
    getPartnersListTotalEmpty,
    delPartners,
    getPartnersList,
    getPartners,
    getBelongings,
    belongings,
    partnersList,
    partnersListTotal,
    getPartnersListExcel,
    getMenusApprochPartner,
  } = props
  //date 세팅용 state
  const [calendarPopup, setCalendarPopup] = useState(false)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [date, setDate] = useState(moment())
  const [popupType, setPopupType] = useState(false)
  const [blandType, setBlandType] = useState("")
  const [type, setType] = useState("")
  const [nameType, setNameType] = useState(0)
  const [keyword, setKeyword] = useState("")

  //접근 권한
  const [statusReq, setStatusReq] = useState(true)
  const [statusAc, setStatusAc] = useState(true)
  const [statusRej, setStatusRej] = useState(true)

  const [current, setCurrent] = useState(1)
  const [checkList, setCheckList] = useState([])

  const [openaffiliatesInfo, setOpneAffiliatesInfo] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openModal2, setOpenModal2] = useState(false)
  const getDate = async () => {
    await getBelongings()
    await getMenusApprochPartner()
  }
  const searchFn = async () => {
    if (startDate > endDate) return false
    let tempType = ""
    if (statusReq && statusAc && statusRej) {
      tempType = ""
    } else {
      if (statusReq) {
        tempType += "WAITING,"
      }
      if (statusAc) {
        tempType += "APPROVAL,"
      }
      if (statusRej) {
        tempType += "REJECT,"
      }
    }
    if (tempType != "" && tempType.substr(-1) == ",") {
      tempType = tempType.substr(0, tempType.length - 1)
    }
    let temp = {
      dateStart: startDate.format("YYYY-MM-DD"),
      dateEnd: endDate.format("YYYY-MM-DD"),
      status: tempType,
      type: type,
      brandName: blandType,
      nameType: nameType == 0 ? "OWNER" : "MANAGER",
      nameWord: keyword,
      page: 0,
      count: 15,
    }
    console.log(temp)
    let result = await getPartnersList(temp)
    if (result) {
      setCurrent(1)
      setCheckList([])
    }
  }
  const excelFn = async () => {
    if (startDate > endDate) return false
    let tempType = ""
    if (statusReq && statusAc && statusRej) {
      tempType = ""
    } else {
      if (statusReq) {
        tempType += "WAITING,"
      }
      if (statusAc) {
        tempType += "APPROVAL,"
      }
      if (statusRej) {
        tempType += "REJECT,"
      }
    }
    let temp = {
      dateStart: startDate.format("YYYY-MM-DD"),
      dateEnd: endDate.format("YYYY-MM-DD"),
      status: tempType,
      type: type,
      brandName: blandType,
      nameType: nameType == 0 ? "OWNER" : "MANAGER",
      nameWord: keyword,
      page: 0,
      count: 15,
    }
    console.log(temp)
    let result = await getPartnersListExcel(temp)
    if (result) {
      await downExcel({ result: result, fileName: "제휴사 관리 목록" })
    }
  }
  const pageFn = async num => {
    if (startDate > endDate) return false
    let tempType = ""
    if (statusReq && statusAc && statusRej) {
      tempType = ""
    } else {
      if (statusReq) {
        tempType += "WAITING,"
      }
      if (statusAc) {
        tempType += "APPROVAL,"
      }
      if (statusRej) {
        tempType += "REJECT,"
      }
    }
    let temp = {
      dateStart: startDate.format("YYYY-MM-DD"),
      dateEnd: endDate.format("YYYY-MM-DD"),
      status: tempType,
      type: type,
      brandName: blandType,
      nameType: nameType == 0 ? "OWNER" : "MANAGER",
      nameWord: keyword,
      page: num || 0,
      count: 15,
    }
    let result = await getPartnersList(temp)
    if (result) {
      setCurrent(num || 1)
      setCheckList([])
    }
  }
  const delFn = async () => {
    let check = false
    for (let i of partnersList) {
      for (let j of checkList) {
        if (i.partnerSeq == j && i.status == "APPROVAL") {
          check = true
        }
      }
    }
    if (!check) {
      setOpenModal2(true)
    } else {
      setOpenModal(true)
    }
  }
  useEffect(() => {
    getDate()
    return () => {
      partnersListTotalEmpty()
      partnersListEmpty()
    }
  }, [])

  return (
    <PrivateRoute>
      <Layout>
        <SEO title="제휴사 관리" />
        <TopInfo title={["제휴사 관리", "운영관리", "제휴사 관리"]} />
        <AffiliatesInfoPopup dataFn={pageFn} open={openaffiliatesInfo} onClose={() => setOpneAffiliatesInfo(false)} />
        <ModalPopup
          open={openModal}
          onClose={() => {
            setOpenModal(false)
          }}
          contents={"승인된 권한내역은 삭제가 불가능 합니다."}
          onClickBtn={() => setOpenModal(false)}
          btn={["확인"]}
        />
        <ModalPopup
          open={openModal2}
          onClose={() => {
            setOpenModal2(false)
          }}
          contents={"정말 삭제하시겠습니까?"}
          onClickBtn={async () => {
            let temp = { partnerSeqs: checkList }
            let result = await delPartners(temp)
            if (result) {
              popupFn("삭제되었습니다.")
              setOpenModal2(false)
              searchFn()
            }
          }}
          btn={["취소", "확인"]}
        />

        <div className="operation-learnerType-container">
          <div className="operation-learnerType-table ">
            <div className="operation-learnerType-table-name ">
              <div>조회 기간</div>
            </div>
            <div style={{ width: "297px" }} className="operation-learnerType-table-contents justify-content-between">
              <div className="fgb-date-form ms-2">
                <img src={icon_calendar} />
                <div
                  className="fgb-date-input"
                  onClick={() => {
                    setPopupType(false)
                    setCalendarPopup(true)
                  }}
                >
                  <p>{startDate.isValid() ? startDate.format("YYYY.MM.DD") : "시작일"}</p>
                </div>
                {calendarPopup && (
                  <div className="calendar-popup-back">
                    <div className="calendar-popup-div">
                      <CalendarComponent
                        date={date}
                        setDate={setDate}
                        selectedDate={popupType == false ? startDate : endDate}
                        setSelectedDate={popupType == false ? setStartDate : setEndDate}
                        popup={setCalendarPopup}
                      />
                    </div>
                  </div>
                )}
                <p>~</p>
                <img src={icon_calendar} />

                <div
                  className="fgb-date-input"
                  onClick={() => {
                    setPopupType(true)
                    setCalendarPopup(true)
                  }}
                >
                  <p>{endDate.isValid() ? endDate.format("YYYY.MM.DD") : "종료일"}</p>
                </div>
              </div>
            </div>
            <div className="operation-learnerType-table-name">
              <div>접근권한</div>
            </div>
            <div className="d-flex align-items-center">
              <img
                onClick={() => {
                  if (statusReq && statusAc && statusRej) {
                    setStatusReq(false)
                    setStatusAc(false)
                    setStatusRej(false)
                  } else {
                    setStatusReq(true)
                    setStatusAc(true)
                    setStatusRej(true)
                  }
                }}
                className="ms-1 me-2"
                src={statusReq && statusAc && statusRej ? checked_on : checked_off}
              />
              <p>전체 </p>
              <img onClick={() => setStatusReq(!statusReq)} className="ms-1 me-2" src={statusReq ? checked_on : checked_off} />
              <p>요청</p>
              <img onClick={() => setStatusAc(!statusAc)} className="ms-1 me-2" src={statusAc ? checked_on : checked_off} />
              <p>승인</p>
              <img onClick={() => setStatusRej(!statusRej)} className="ms-1 me-2" src={statusRej ? checked_on : checked_off} />
              <p>반려</p>
            </div>
            <div className="operation-learnerType-table-name ms-2">
              <div>분류</div>
            </div>
            <div className="d-flex align-items-center me-3">
              <div className="ms-4" style={{ width: "100px" }}>
                <select
                  className="form-select"
                  onChange={e => {
                    setType(e.target.value)
                  }}
                >
                  <option className="" value="">
                    전체
                  </option>
                  <option className="" value="CRAM_SCHOOL">
                    학원
                  </option>
                  <option className="" value="CONSULTANT">
                    컨설턴트
                  </option>
                  <option className="" value="STUDY_CAFE">
                    스터디카페
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="operation-learnerType-table">
            <div className="operation-learnerType-table-name">
              <div>브랜드명</div>
            </div>
            <div style={{ width: "297px" }} className="d-flex align-items-center ">
              <div className="ms-4" style={{ width: "190px" }}>
                <select
                  className="form-select"
                  onChange={e => {
                    setBlandType(e.target.value)
                    console.log(e.target.value)
                  }}
                >
                  {belongings.map((i, idx) => (
                    <option key={idx} className="" value={i.name}>
                      {i.belongingType == "ALL" ? "전체" : i.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="operation-learnerType-table-name">
              <div>이름</div>
            </div>
            <div className="d-flex align-items-center ">
              <img onClick={() => setNameType(0)} className="ms-3 me-2" src={nameType == 0 ? radio_on : radio_off} />
              <p style={{ whiteSpace: "nowrap" }}>대표 </p>
              <img onClick={() => setNameType(1)} className="ms-3 me-2" src={nameType == 1 ? radio_on : radio_off} />
              <p style={{ whiteSpace: "nowrap" }}>담당자</p>
              <input value={keyword} onChange={e => setKeyword(e.target.value)} type="text" className="fgb-input ms-3"></input>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <div onClick={searchFn} className="btn btn-primary font-white fgb-btn-l">
              조회
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mt-4">
            <p>{partnersListTotal.toLocaleString()}건</p>
            <div className="d-flex justify-content-end">
              <div onClick={delFn} className="btn btn-primary font-white reverse fgb-btn-ms clear-button me-2">
                선택 삭제
              </div>
              <div onClick={excelFn} className="btn fgb-btn-m fgb-btn-grean me-2">
                <img src={download} />
                <p>엑셀 다운로드</p>
              </div>
            </div>
          </div>
          <div className="introduce-study-table mt-3">
            <div className="fgb-table-div">
              <table className={`fgb-table mb-4`}>
                <thead>
                  <tr className={`fgb-table-title-tr`}>
                    <td>
                      <img
                        onClick={() => {
                          if (checkList.length == partnersList.length) {
                            setCheckList([])
                          } else {
                            let temp = []
                            for (let i of partnersList) {
                              temp = [...temp, i.partnerSeq]
                            }
                            setCheckList(temp)
                          }
                        }}
                        className="ms-4 me-2"
                        src={checkList.length == partnersList.length && partnersList.length > 0 ? checked_on : checked_off}
                      />
                    </td>
                    {[
                      "접근 권한",
                      "승인자",
                      "요청일시",
                      "승인일시",
                      "분류",
                      "브랜드명",
                      "담당자",
                      "사업자번호",
                      "연락처",
                      "이메일",
                      "보유 포인트",
                      "누적 결제금액",
                      "학습자",
                      "캠스터디",
                      "TO-DO",
                      "가입일",
                    ].map((i, idx) => (
                      <td key={idx}>{i}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {partnersList.map((i, idx) => (
                    <tr className={`fgb-table-tr `} key={idx}>
                      <td>
                        <img
                          onClick={() => {
                            let check = checkList.indexOf(i.partnerSeq)
                            console.log(check)
                            if (check == -1) {
                              setCheckList([...checkList, i.partnerSeq])
                            } else {
                              let temp = [...checkList]
                              temp.splice(check, 1)
                              setCheckList(temp)
                            }
                          }}
                          className="ms-4 me-2"
                          src={checkList.indexOf(i.partnerSeq) != -1 ? checked_on : checked_off}
                        />
                      </td>
                      <td
                        onClick={async () => {
                          await getPartners(i.partnerSeq)
                          setOpneAffiliatesInfo(true)
                        }}
                      >
                        {STATUS[i.status]}
                      </td>
                      <td
                        onClick={async () => {
                          await getPartners(i.partnerSeq)
                          setOpneAffiliatesInfo(true)
                        }}
                      >
                        {i.approvalAdminName}
                      </td>
                      <td
                        onClick={async () => {
                          await getPartners(i.partnerSeq)
                          setOpneAffiliatesInfo(true)
                        }}
                      >
                        {moment(i.applyDatetime).format("YYYY-MM-DD HH:MM")}
                      </td>
                      <td
                        onClick={async () => {
                          await getPartners(i.partnerSeq)
                          setOpneAffiliatesInfo(true)
                        }}
                      >
                        {moment(i.approvalDatetime).format("YYYY-MM-DD HH:MM") == "Invalid date"
                          ? ""
                          : moment(i.approvalDatetime).format("YYYY-MM-DD HH:MM")}
                      </td>
                      <td
                        onClick={async () => {
                          await getPartners(i.partnerSeq)
                          setOpneAffiliatesInfo(true)
                        }}
                      >
                        {TYPE[i.type]}
                      </td>
                      <td
                        onClick={async () => {
                          await getPartners(i.partnerSeq)
                          setOpneAffiliatesInfo(true)
                        }}
                      >
                        {i.brandName}
                      </td>
                      <td
                        onClick={async () => {
                          await getPartners(i.partnerSeq)
                          setOpneAffiliatesInfo(true)
                        }}
                      >
                        {i.managerName || i.ownerName}
                      </td>
                      <td
                        onClick={async () => {
                          await getPartners(i.partnerSeq)
                          setOpneAffiliatesInfo(true)
                        }}
                      >
                        {i.businessNumber}
                      </td>
                      <td
                        onClick={async () => {
                          await getPartners(i.partnerSeq)
                          setOpneAffiliatesInfo(true)
                        }}
                      >
                        {i.phone}
                      </td>
                      <td
                        onClick={async () => {
                          await getPartners(i.partnerSeq)
                          setOpneAffiliatesInfo(true)
                        }}
                      >
                        {i.email}
                      </td>
                      <td
                        onClick={async () => {
                          await getPartners(i.partnerSeq)
                          setOpneAffiliatesInfo(true)
                        }}
                      >
                        {i.point}
                      </td>
                      <td
                        onClick={async () => {
                          await getPartners(i.partnerSeq)
                          setOpneAffiliatesInfo(true)
                        }}
                      >
                        {i.accumulatedPayAmount}
                      </td>
                      <td
                        onClick={async () => {
                          await getPartners(i.partnerSeq)
                          setOpneAffiliatesInfo(true)
                        }}
                      >
                        {i.memberAmount}
                      </td>
                      <td
                        onClick={async () => {
                          await getPartners(i.partnerSeq)
                          setOpneAffiliatesInfo(true)
                        }}
                      >
                        {i.studyAmount}
                      </td>
                      <td
                        onClick={async () => {
                          await getPartners(i.partnerSeq)
                          setOpneAffiliatesInfo(true)
                        }}
                      >
                        {i.todoAmount}
                      </td>
                      <td
                        onClick={async () => {
                          await getPartners(i.partnerSeq)
                          setOpneAffiliatesInfo(true)
                        }}
                      >
                        {moment(i.regDatetime).format("YYYY-MM-DD")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination fn={pageFn} count={15} current={current} setCurrent={setCurrent} total={partnersListTotal} />
            </div>
          </div>
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default SignContainer(OperationContainer(Affiliates))
