import React, { useEffect, useState } from "react"
import { debounce } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import { dataCheckFn } from "../utils/util"
import LayerPopupLayout from "./LayerPopupLayout"

import img_popup_close_gray from "../images/img_popup_close_gray.svg"

const IMGTYPE = { BOTTOM: "bottom", TOP: "up", SEPARATION: "both", ONLY_IMAGE: "full" }
const IMGTYPE_ARR = ["bottom", "up", "both", "full"]
const FONT = ["Tahoma", "Noto Sans", "Pretendard"]

const ConsultantProfilePopup = props => {
  const { open, onClose, data = {} } = props

  const initState = () => {}
  const closePopup = () => {
    onClose()
    initState()
  }

  return (
    <LayerPopupLayout open={open} onClose={closePopup} transparency isMCloseBtn zIndex={120}>
      {dataCheckFn(data) && (
        <div className="popup-consultant-profile-container">
          <div className="consultant-profile-close-div">
            <button className="btn" onClick={closePopup}>
              <img src={img_popup_close_gray} alt="popup close img" />
            </button>
          </div>

          <p className="popup-consultant-profile-title">{"학원&컨설턴트 프로필 보기"}</p>

          {IMGTYPE_ARR[data.templateType] != "full" ? (
            <div className="popup-consultant-profile-img-div">
              <img src={data.imageInfoPc.imageUrl || data.imageInfoPc.base64 || ""} />

              <div className={IMGTYPE_ARR[data.templateType]}>
                <pre className={`popup-consultant-profile-img-title p-font-${data.isTitleFont + 1}`}>{data.title}</pre>
                <pre className={`popup-consultant-profile-img-sub p-font-${data.isSubTitleFont + 1}`}>{data.subTitle}</pre>
              </div>
            </div>
          ) : (
            <div className="popup-consultant-profile-img-div">
              <img src={data.imageInfoPc.imageUrl || data.imageInfoPc.base64 || ""} />
            </div>
          )}

          <div className="popup-profile-border-view">
            <pre className="popup-consultant-profile-info">{data.introduction}</pre>
            {/* <pre dangerouslySetInnerHTML={{ __html: data.introduction }} className="popup-consultant-profile-info"></pre> */}
          </div>
        </div>
      )}
    </LayerPopupLayout>
  )
}

export default ConsultantProfilePopup
