import React, { useState } from "react"
import { useDispatch } from "react-redux"

import img_exploring_card_eyes from "../../images/img_exploring_card_eyes.svg"
import img_exploring_card_calendar from "../../images/img_exploring_card_calendar.svg"
import img_exploring_card_time from "../../images/img_exploring_card_time.svg"
import img_exploring_card_smile from "../../images/img_exploring_card_smile.svg"
import img_exploring_card_profile_icon from "../../images/img_exploring_card_profile_icon.svg"
import temp_profile from "../../images/temp_profile.svg"
import moment from "moment"

const StudyItem = ({
  data = {
    study_seq: "1640135894436_tejh1z8u",
    viewCount: 2,
    title: "학원스터디 ",
    dateStart: "2021-12-23 00:00:00",
    dateEnd: "2021-12-31 23:59:59",
    studyMemberTotalCount: 4,
    studyMemberCount: 2,
    profileImage: "https://samlab-letmeup.s3.ap-northeast-2.amazonaws.com/files/origin/20211221093658_vrk5z3a409vt02d3jvb7.jpg",
    nickname: null,
    region1: "경기",
    region2: "성남시 분당구",
    region3: "수내동",
    timeOfWeek: 10,
    planTimeOfWeek: 10,
    tags: ["학원가자"],
  },
  data2 = {},
  setOpenStudyInfo,
}) => {
  // const { type, status, title, keywords, period, time, score, user } = data
  const dispatch = useDispatch()
  const [participation, setParticipation] = useState([])
  const cardColor = {
    "취업/자격증": 5,
    중학생: 1,
    고등학생: 2,
    대학생: 3,
    대입수험생: 4,
    공무원: 6,
    기타: 8,
    직장인: 7,
  }
  // useEffect(() => {
  //   if (Object.keys(data).length != 0) {
  //     if (data.isParticipation != "") {
  //       setParticipation(data.isParticipation.split(" "))
  //     }
  //   }
  // }, [data])
  return (
    <div className="common-study-card-box" style={{ margin: 0, padding: 0 }}>
      <div
        className={`card common-study-card common-study-card-${data.studentGroupName ? cardColor[data.studentGroupName] : 8}`}
        style={{ width: "100%", height: "250px" }}
        onClick={() => setOpenStudyInfo({ opne: true, data: data })}
      >
        <div className="common-card-top">
          <div>
            {/* {Object.keys(data).length > 0 &&
              data.isParticipation.split(" ").map((i, idx) => (
                <p className="common-card-top-p" key={idx}>
                  {i}
                </p>
              ))} */}
          </div>
          <div>
            <img src={img_exploring_card_eyes} alt="eyes img" />
            <p>{(data.viewCount || 0).toLocaleString()}</p>
          </div>
        </div>
        <p className="common-card-title">{data.title}</p>
        <p className="common-card-keywords">
          {data.tags.length > 0 &&
            data.tags.map((i, idx) => {
              let str = "#" + i + " "
              return str
            })}
        </p>

        <div className="common-card-study-info">
          <div className="common-card-study-info-calendar">
            <img src={img_exploring_card_calendar} alt="calendar img" />
            <p>
              {data.dateStart == data.dateEnd
                ? moment(data.dateStart).isValid() == true && moment(data.dateStart).format("MM/DD")
                : (moment(data.dateStart).isValid() == true && moment(data.dateStart).format("MM/DD")) +
                  "~" +
                  (moment(data.dateEnd).isValid() == true && moment(data.dateEnd).format("MM/DD"))}
            </p>
          </div>
          <div className="common-card-study-info-time">
            <img src={img_exploring_card_time} alt="time img" />
            <p>{data.isFree == "Y" ? "주1회 1시간" : `주${data.timeOfWeek}회 ${data.planTimeOfWeek}시간`}</p>
          </div>
          <div className="common-card-study-info-smile">
            <img src={img_exploring_card_smile} alt="smile img" />
            <p>{`${data.studyMemberCount || 0}/${data.studyMemberTotalCount || 0}`}</p>
          </div>
        </div>

        <div className="common-card-bottom">
          <div>
            <div
              className="common-card-bottom-avatar"
              onClick={e => {
                e.stopPropagation()
              }}
            >
              <img src={data.profileImage != null && data.profileImage != "" ? data.profileImage : temp_profile} alt="profile img" />
              <img />
            </div>

            <div className="common-card-bottom-user">
              <p>{data.nickname != "" && data.nickname != null ? data.nickname : "nickname"}</p>
              <p>{`${data.region1 + " " + data.region2 + " " + data.region3 + " "} `}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StudyItem
