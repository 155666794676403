import React, { useState } from "react"
import LayerPopupLayout from "../LayerPopupLayout"
import SignContainer from "../../containers/SignContainer"
import Validator from "validator"
import { debounce } from "lodash"
import { dataCheckFn, popupFn } from "../../utils/util"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"
import CloseBtn from "../../images/Close_Button.svg"
import img_popup_close_gray from "../../images/img_popup_close_gray.svg"
import { useEffect } from "react"
import OperationContainer from "../../containers/OperationContainer"
const LearningRulesPopup = props => {
  const { open, onClose, learnAttitude, putRuleLearnAttitude, getdata = () => {} } = props

  const [checkList, setCheckList] = useState([])
  const [checkIdx, setCheckIdx] = useState([])
  const [motionTitle, setMotionTitle] = useState("")
  const [summary, setSummary] = useState("")

  const [isFcm, setIsFcm] = useState(false)
  const [isSMS, setIsSMS] = useState(false)
  const [pushType, setPuchType] = useState(0)

  const [isUse, setIsUse] = useState(true)

  const closePopup = e => {
    onClose()
  }
  const saveFn = async () => {
    if (Validator.isEmpty(motionTitle, { ignore_whitespace: true })) {
      popupFn("타이틀을 입력해주세요.")
      return false
    }
    if (Validator.isEmpty(summary, { ignore_whitespace: true })) {
      popupFn("요약을 입력해주세요.")
      return false
    }
    let temp = {
      learnAttitudeRuleSeq: learnAttitude.learnAttitudeRuleSeq,
      title: motionTitle,
      content: summary,
      pushType: isFcm && isSMS ? "ALL" : isFcm ? "IN_APP" : isSMS ? "SMS" : "",
      isUse: isUse ? "Y" : "N",
      learnAttitudeMessages: [
        ...checkList.map(i => {
          if (!Validator.isEmpty(i.message, { ignore_whitespace: true })) return i.message
        }),
      ],
    }

    console.log("temp", temp)
    let result = await putRuleLearnAttitude(temp)
    if (result) {
      popupFn("저장되었습니다.")
      getdata()
      closePopup()
    }
  }
  const delFn = async () => {
    let temp = []
    for (let i = checkList.length - 1; i >= 0; i--) {
      let check = false
      for (let k of checkIdx) {
        if (k == i) {
          check = true
        }
      }
      if (!check) {
        temp = [...temp, checkList[i]]
      }
    }
    let tempReverse = []
    for (let i = temp.length - 1; i >= 0; i--) {
      tempReverse = [...tempReverse, temp[i]]
    }
    setCheckList(tempReverse)
    setCheckIdx([])
  }

  useEffect(() => {
    if (dataCheckFn(learnAttitude)) {
      setCheckList([...learnAttitude.learnAttitudeMessages])
      setMotionTitle(learnAttitude.title)
      setSummary(learnAttitude.content)
      if (learnAttitude.pushType == "ALL") {
        setIsFcm(true)
        setIsSMS(true)
      } else if (learnAttitude.pushType == "FCM") {
        setIsFcm(true)
      } else if (learnAttitude.pushType == "SMS") {
        setIsSMS(true)
      } else {
        setIsFcm(false)
        setIsSMS(false)
      }
      setIsUse(learnAttitude.isUse == "Y" ? true : false)
    }
  }, [learnAttitude])
  return (
    <LayerPopupLayout open={open} onClose={() => closePopup()} isCloseBtn transparency zIndex={101}>
      <div className="learner-type-popup-container layer-popup-container">
        <p className="popup-title mb-3">학습 규칙 등록/변경</p>
        <div className="admin-account-info-container ">
          <div className="admin-account-info-box">
            <div className="admin-account-info-box-row div-1">
              <div className="admin-account-info-title-p ">학습규칙 타이틀</div>
              <div className="admin-account-info-div">
                <div style={{ width: "330px", border: "none" }} className="admin-account-info-div-2">
                  <input value={motionTitle} onChange={e => setMotionTitle(e.target.value)} type="text" className="fgb-input" />
                </div>
              </div>
            </div>
            <div className="admin-account-info-box-row div-2">
              <div className="admin-account-info-title-p ">학습규칙 요약</div>
              <div className="admin-account-info-div">
                <div className="admin-account-info-div-textarea">
                  <textarea value={summary} onChange={e => setSummary(e.target.value)}></textarea>
                  <button onClick={() => setSummary("")} className="btn fgb-btn-gray fgb-btn-m mt-5 ms-2">
                    초기화
                  </button>
                </div>
              </div>
            </div>

            <div className="admin-account-info-box-row div-1">
              <div className="admin-account-info-title-p ">Push 유형</div>
              <div className="admin-account-info-div">
                <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                  <img
                    onClick={() => {
                      if (isFcm && isSMS) {
                        setIsFcm(false)
                        setIsSMS(false)
                      } else {
                        setIsFcm(true)
                        setIsSMS(true)
                      }
                    }}
                    className=" me-2"
                    src={isFcm && isSMS ? checked_on : checked_off}
                  />
                  <p>전체 </p>
                  <img onClick={() => setIsFcm(!isFcm)} className="ms-4 me-2" src={isFcm ? checked_on : checked_off} />
                  <p>in-app 알림</p>
                  <img onClick={() => setIsSMS(!isSMS)} className="ms-4 me-2" src={isSMS ? checked_on : checked_off} />
                  <p>SMS 알림</p>
                </div>
              </div>
            </div>
            <div className="admin-account-info-box-row">
              <div className="admin-account-info-title-p">알림메세지</div>
              <div className="admin-account-info-div">
                <div className="authority-table-top-div">
                  <div>※ 알림 메시지는 최대 30자까지 입력</div>
                  <div className="d-flex align-items-center justify-content-end mt-3 mb-3">
                    <button onClick={delFn} style={{ width: "80px" }} className="btn btn-primary font-white reverse fgb-btn-ms clear-button">
                      선택 삭제
                    </button>
                    <button
                      onClick={() => {
                        setCheckList([
                          ...checkList,
                          {
                            message: "",
                            learnAttitudeMessageSeq: "",
                          },
                        ])
                      }}
                      className="btn btn-primary font-white fgb-btn-s ms-2"
                    >
                      행추가
                    </button>
                  </div>
                </div>
                <table className={`fgb-table mb-3`}>
                  <tbody>
                    {checkList.length > 0 &&
                      checkList.map((i, idx) => (
                        <tr key={idx} className={`fgb-table-tr`}>
                          <td>
                            <img
                              onClick={() => setCheckIdx([...checkIdx, idx])}
                              className=""
                              src={checkIdx.indexOf(idx) != -1 ? checked_on : checked_off}
                            />
                          </td>
                          <td>
                            <input
                              maxLength={300}
                              value={i.message || ""}
                              onChange={e => {
                                let temp = [...checkList]
                                temp[idx].message = e.target.value
                                setCheckList(temp)
                              }}
                              type="text"
                              className="fgb-input"
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="admin-account-info-box-row div-1">
              <div className="admin-account-info-title-p">사용여부</div>
              <div className="admin-account-info-div">
                <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                  <img onClick={() => setIsUse(true)} className=" me-2" src={isUse ? radio_on : radio_off} />
                  <p>사용</p>
                  <img onClick={() => setIsUse(false)} className="ms-4 me-2" src={!isUse ? radio_on : radio_off} />
                  <p>미사용</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="permission-info-popup-btn-view">
          <button onClick={() => closePopup()} className="btn btn-primary fgb-btn-gray fgb-btn-l ">
            취소
          </button>
          <button onClick={() => saveFn()} className="btn btn-primary font-white fgb-btn-l ">
            저장
          </button>
        </div>
      </div>
    </LayerPopupLayout>
  )
}

export default OperationContainer(LearningRulesPopup)
