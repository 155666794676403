import React, { useState } from "react"
import LayerPopupLayout from "../popups/LayerPopupLayout"
import SignContainer from "../containers/SignContainer"
import Validator from "validator"
import { debounce } from "lodash"
import { dataCheckFn, popupFn } from "../utils/util"
import checked_on from "../images/checked_on.svg"
import checked_off from "../images/checked_off.svg"
import radio_on from "../images/radio_on.svg"
import radio_off from "../images/radio_off.svg"
import CloseBtn from "../images/Close_Button.svg"
import img_popup_close_gray from "../images/img_popup_close_gray.svg"
import { useEffect } from "react"
const TYPE = {
  PLAN_CHANGE: "일정변경 안내",
  NOTICE: "공지사항",
  EVENT: "이벤트",
  OFFLINE_EVENT: "행사",
  ETC: "기타",
}
const MessageSendPopup = props => {
  const {
    open,
    onClose,
    getMessagesStudies,
    messagesStudiesEmpty,
    profileMessagesStudies,
    getMessagesMembers,
    partnerProfileMessagesMembersEmpty,
    profileMessagesMembers,
    getMessagesUsers,
    partnerProfileMessagesUsersEmpty,
    profileMessagesUsers,
    postMessages,
  } = props
  //권한 state

  const [title, setTitle] = useState("")
  const [content, setContent] = useState("")
  const [isFcm, setIsFcm] = useState(false)
  const [isEmail, setIsEmail] = useState(false)
  const [nameType, setNameType] = useState(true)
  const [type, setType] = useState("PLAN_CHANGE")
  const [searchText, setSearchText] = useState("")

  const [checkList, setCheckList] = useState([])
  const [checkStudyList, setCheckStudyList] = useState([])
  const [checkStudyListName, setCheckStudyListName] = useState([])

  const [openStudy, setOpenStudy] = useState(false)

  const closePopup = e => {
    onClose()
    setCheckStudyList([])
    setCheckStudyListName([])
    setCheckList([])
    setContent("")
    setTitle("")
    setType("PLAN_CHANGE")
    setSearchText("")
    setOpenStudy(false)
    partnerProfileMessagesUsersEmpty()
    partnerProfileMessagesMembersEmpty()
  }
  const getData = async () => {
    await getMessagesStudies()
  }
  const messageFn = async () => {
    if (Validator.isEmpty(title, { ignore_whitespace: true })) {
      popupFn("제목을 입력해 주세요")
      return false
    }
    if (Validator.isEmpty(content, { ignore_whitespace: true })) {
      popupFn("메세지 내용을 입력해 주세요")

      return false
    }
    if (!isFcm && !isEmail) {
      popupFn("하나 이상의 전달방식이 필요합니다.")
      return false
    }

    let seqList = [...checkList, ...profileMessagesMembers]
    let set = new Set(seqList)
    set = [...set]
    if (seqList.length < 1) {
      popupFn("선택된 인원이 없거나 선택한 스터디스케줄에 소속인원이 없습니다.")
      return false
    }
    let temp = {
      type: type,
      title: title,
      isAlarmPush: isFcm ? "Y" : "N",
      isAlarmEmail: isEmail ? "Y" : "N",
      receiverSeqs: set,
      content: content,
    }
    console.log("temp", temp)
    let result = await postMessages(temp)
    if (result) {
      popupFn("메세지를 전송했습니다.")
      closePopup()
    }
  }
  const searchFn = async () => {
    let temp = {
      wordType: nameType ? "NAME" : "NICKNAME",
      word: searchText,
    }
    let result = await getMessagesUsers(temp)
    if (result) {
      setCheckList([])
    }
  }
  useEffect(() => {
    if (open) {
      getData()
    } else {
      messagesStudiesEmpty()
    }
    partnerProfileMessagesMembersEmpty()
  }, [open])

  return (
    <LayerPopupLayout
      open={open}
      onClose={() => closePopup()}
      isCloseBtn
      transparency
      zIndex={101}
    >
      <div className="message-send-popup-container layer-popup-container">
        <p className="popup-title mb-3">메세지 보내기</p>
        <div className="admin-account-info-container ">
          <div className="admin-account-info-box">
            <div className="admin-account-info-box-row div-13">
              <div className="admin-account-info-title-p ">유형</div>
              <div className="admin-account-info-div">
                <div
                  style={{ width: "330px", border: "none" }}
                  className="admin-account-info-div-2"
                >
                  <select
                    className="form-select ms-3"
                    value={type}
                    onChange={e => {
                      setType(e.target.value)
                    }}
                  >
                    <option className="" value="PLAN_CHANGE">
                      일정변경 안내
                    </option>
                    <option className="" value="NOTICE">
                      공지사항
                    </option>
                    <option className="" value="EVENT">
                      이벤트
                    </option>
                    <option className="" value="OFFLINE_EVENT">
                      행사
                    </option>
                    <option className="" value="ETC">
                      기타
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div className="admin-account-info-box-row div-13">
              <div className="admin-account-info-title-p ">제목</div>
              <div className="admin-account-info-div">
                <div
                  style={{ width: "330px", border: "none" }}
                  className="admin-account-info-div-2 ms-3"
                >
                  <input
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    type="text"
                    className="fgb-input"
                  />
                </div>
              </div>
            </div>
            <div className="admin-account-info-box-row div-1">
              <div className="admin-account-info-title-p ">전달 방식</div>
              <div className="admin-account-info-div">
                <div className="admin-account-info-div-2 ms-3">
                  <img
                    onClick={() => {
                      if (isFcm && isEmail) {
                        setIsEmail(false)
                        setIsFcm(false)
                      } else {
                        setIsEmail(true)
                        setIsFcm(true)
                      }
                    }}
                    className="me-2"
                    src={isFcm && isEmail ? checked_on : checked_off}
                  />
                  전체
                  <img
                    onClick={() => setIsFcm(!isFcm)}
                    className="me-2 ms-3"
                    src={isFcm ? checked_on : checked_off}
                  />
                  APP Push 알림
                  <img
                    onClick={() => setIsEmail(!isEmail)}
                    className="me-2 ms-3"
                    src={isEmail ? checked_on : checked_off}
                  />
                  이메일
                </div>
              </div>
            </div>
            <div className="admin-account-info-box-row">
              <div className="admin-account-info-title-p">받는사람</div>
              <div className="admin-account-info-div">
                <div className="authority-table-top-div">
                  <div
                    style={{
                      width: "200px",
                      margin: "10px 5px",
                      position: "relative",
                    }}
                  >
                    <div
                      onClick={async () => {
                        if (openStudy) {
                          if (checkStudyList.length > 0) {
                            console.log("checkStudyList", checkStudyList)
                            let temp = ""
                            temp += checkStudyList.map(i => i)
                            console.log(temp, "temp")

                            await getMessagesMembers(temp)
                          } else {
                            partnerProfileMessagesMembersEmpty()
                          }
                        }
                        setOpenStudy(!openStudy)
                      }}
                      disabled
                      style={{ cursor: "pointer" }}
                      className="form-select"
                      onChange={e => {
                        console.log(e.target.value)
                      }}
                    >
                      스터디 스케줄
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        width: "100%",
                        maxHeight: "180px",
                        background: "white",
                        zIndex: 500,
                        overflowY: "auto",
                        boxShadow: "2px 2px 5px rgba(0,0,0,0.05)",
                        padding: "5px 7px",
                        display: openStudy ? "block" : "none",
                      }}
                    >
                      <div className="d-flex align-items-center mt-1 mb-1">
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (
                              checkStudyList.length ==
                              profileMessagesStudies.length
                            ) {
                              setCheckStudyList([])
                              setCheckStudyListName([])
                            } else {
                              let temp = []
                              let temp1 = []
                              for (let data of profileMessagesStudies) {
                                temp = [...temp, data.studySeq]
                                temp1 = [...temp1, data.title]
                              }
                              setCheckStudyList(temp)
                              setCheckStudyListName(temp1)
                            }
                          }}
                          className=""
                          src={
                            checkStudyList.length ==
                            profileMessagesStudies.length
                              ? checked_on
                              : checked_off
                          }
                        />
                        <p className="ms-2">{"전체"}</p>
                      </div>
                      {profileMessagesStudies.map((i, idx) => (
                        <div
                          key={idx}
                          className="d-flex align-items-center mt-1 mb-1"
                        >
                          <img
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              let check = checkStudyList.indexOf(i.studySeq)
                              if (check == -1) {
                                setCheckStudyList([
                                  ...checkStudyList,
                                  i.studySeq,
                                ])
                                setCheckStudyListName([
                                  ...checkStudyListName,
                                  i.title,
                                ])
                              } else {
                                let temp = [...checkStudyList]
                                let temp1 = [...checkStudyListName]
                                temp.splice(check, 1)
                                temp1.splice(check, 1)
                                setCheckStudyList(temp)
                                setCheckStudyListName(temp1)
                              }
                            }}
                            className=""
                            src={
                              checkStudyList.indexOf(i.studySeq) != -1
                                ? checked_on
                                : checked_off
                            }
                          />
                          <p className="ms-2">{i.title}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <p style={{ width: "100%" }} className="ms-2">
                    선택 스케줄 :{" "}
                    {checkStudyListName.map((i, idx) =>
                      checkStudyListName.length - 1 == idx ? i + " " : i + ", "
                    )}
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex justify-content-end align-items-center">
                    <img
                      onClick={() => setNameType(true)}
                      className="me-2 ms-3"
                      src={nameType ? radio_on : radio_off}
                    />
                    이름
                    <img
                      onClick={() => setNameType(false)}
                      className="me-2 ms-3"
                      src={!nameType ? radio_on : radio_off}
                    />
                    스크린 닉네임
                  </div>
                  <div className="d-flex justify-content-end align-items-center ">
                    <div
                      style={{ width: "160px" }}
                      className="admin-account-info-div-2 ms-3"
                    >
                      <input
                        value={searchText}
                        onChange={e => setSearchText(e.target.value)}
                        type="text"
                        className="fgb-input"
                      />
                    </div>
                    <button
                      onClick={searchFn}
                      className="btn btn-primary font-white fgb-btn-s ms-3"
                    >
                      조회
                    </button>
                  </div>
                </div>
                <table className={`fgb-table mb-3`}>
                  <thead>
                    <tr className={`fgb-table-title-tr`}>
                      {["", "이름", "닉네임", "이메일 주소"].map((i, idx) => (
                        <td key={idx}>{i}</td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {profileMessagesUsers.map((i, idx) => (
                      <tr key={idx} className={`fgb-table-tr`}>
                        <td>
                          <img
                            onClick={() => {
                              let check = checkList.indexOf(i.memberSeq)
                              if (check == -1) {
                                setCheckList([...checkList, i.memberSeq])
                              } else {
                                let temp = [...checkList]
                                temp.splice(check, 1)
                                setCheckList(temp)
                              }
                            }}
                            className=""
                            src={
                              checkList.indexOf(i.memberSeq) != -1
                                ? checked_on
                                : checked_off
                            }
                          />
                        </td>
                        <td>{i.name}</td>
                        <td>{i.nickname}</td>
                        <td>{i.email}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="admin-account-info-box-row div-3">
              <div className="admin-account-info-title-p">메세지 상세</div>
              <div className="admin-account-info-div">
                <div className="admin-account-info-div-textarea">
                  <textarea
                    value={content}
                    onChange={e => setContent(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="permission-info-popup-btn-view">
          <button
            onClick={() => closePopup()}
            className="btn btn-primary fgb-btn-gray fgb-btn-l "
          >
            취소
          </button>
          <button
            onClick={messageFn}
            className="btn btn-primary font-white fgb-btn-l "
          >
            보내기
          </button>
        </div>
      </div>
    </LayerPopupLayout>
  )
}

export default SignContainer(MessageSendPopup)
const DUMY = [
  {
    seq: 1,
    name: "cs",
    nickname: "copu1",
    email: "cocoy@fgb.ai",
  },
  {
    seq: 2,
    name: "cs2",
    nickname: "copu2",
    email: "cocoy@fgb.ai",
  },
  {
    seq: 3,
    name: "cs3",
    nickname: "copu3",
    email: "cocoy@fgb.ai",
  },
  {
    seq: 4,
    name: "cs4",
    nickname: "copu4",
    email: "cocoy@fgb.ai",
  },
]
