import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import LayerPopupLayout from "./LayerPopupLayout"
import img_popup_close_black from "../images/img_popup_close_black.svg"

import SignContainer from "../containers/SignContainer"
import ModalPopup from "./ModalPopup"
import { dataCheckFn } from "../utils/util"

import img_checkbox from "../images/img_checkbox.svg"
import img_checkbox_active from "../images/img_checkbox_active.svg"

const TermsNotiPopup = props => {
  const { open, onClose, profileTerms, data, putProfliesPartnerTerms, getProfilesPartnerTerms } = props
  const [openModal, setOpenModal] = useState(false)
  const [isAgree, setIsAgree] = useState(false)

  // 알림 설정
  const [alarmToggle, setAlarmToggle] = useState(false)
  const [alarmCheck, setAlarmCheck] = useState({ email: false, sms: false })

  const closePopup = () => {
    getProfilesPartnerTerms()
    onClose()
  }

  useEffect(() => {
    if (profileTerms.length > 0) {
      setIsAgree(profileTerms[2].status == "AGREE" ? true : false)
      setAlarmToggle(profileTerms[2].isAgreeAlarm == "Y" ? true : false)
      switch (profileTerms[2].marketingType) {
        case "NONE":
          setAlarmCheck({ email: false, sms: false })
          break
        case "EMAIL":
          setAlarmCheck({ email: true, sms: false })
          break
        case "SNS/SMS":
          setAlarmCheck({ email: false, sms: true })
          break
        case "ALL":
          setAlarmCheck({ email: true, sms: true })
          break
        default:
          break
      }
    }
  }, [profileTerms, open])

  const onClickAlarmTotalCheck = async () => {
    if (!alarmCheck.email || !alarmCheck.sms) {
      setAlarmCheck({ email: true, sms: true })
    } else {
      setAlarmCheck({ email: false, sms: false })
    }
  }
  useEffect(async () => {
    if (open) {
      let result = await putProfliesPartnerTerms({
        termSeq: profileTerms[2].termSeq,
        isAgree: isAgree ? "Y" : "N",
        marketingType:
          !alarmCheck.email && !alarmCheck.sms ? "NONE" : alarmCheck.email && alarmCheck.sms ? "ALL" : alarmCheck.email ? "EMAIL" : "SNS/SMS",
        isAgreeAlarm: alarmToggle ? "Y" : "N",
      })
    }
  }, [alarmCheck])
  if (!data) return <></>
  else
    return (
      <LayerPopupLayout open={open} onClose={closePopup} transparency zIndex={101}>
        <ModalPopup
          open={openModal}
          onClose={() => setOpenModal(false)}
          contents={"약관 동의 철회 시, 정상적인 서비스를\n제공받을 수 없습니다."}
          onClickBtn={async () => {
            let result = await putProfliesPartnerTerms({ termSeq: profileTerms[idx].termSeq, isAgree: "N" })
            if (result) {
              await getProfilesPartnerTerms()
              setOpenModal(false)
            }
          }}
          btn={["취소", "확인"]}
        />
        <div className="popup-terms-container">
          {/* desktop close btn */}
          <div className="d-flex justify-content-end">
            <button className="btn" onClick={closePopup}>
              <img src={img_popup_close_black} alt="popup close img" />
            </button>
          </div>
          <div className="popup-terms-div">
            <div className="d-flex justify-content-start align-items-center">
              <p>{profileTerms[2].title}</p>
              <div className="form-check form-switch form-switch-md ms-3">
                <input
                  checked={isAgree}
                  onChange={async e => {
                    let result = false
                    if (profileTerms[2].status == "AGREE" && profileTerms[2].isMust == "Y") {
                      setOpenModal(true)
                    } else if (profileTerms[2].status == "AGREE") {
                      result = await putProfliesPartnerTerms({
                        termSeq: profileTerms[2].termSeq,
                        isAgree: "N",
                        marketingType:
                          !alarmCheck.email && !alarmCheck.sms
                            ? "NONE"
                            : alarmCheck.email && alarmCheck.sms
                            ? "ALL"
                            : alarmCheck.email
                            ? "EMAIL"
                            : "SNS/SMS",
                        isAgreeAlarm: alarmToggle ? "Y" : "N",
                      })
                    } else {
                      result = await putProfliesPartnerTerms({
                        termSeq: profileTerms[2].termSeq,
                        isAgree: "Y",
                        marketingType:
                          !alarmCheck.email && !alarmCheck.sms
                            ? "NONE"
                            : alarmCheck.email && alarmCheck.sms
                            ? "ALL"
                            : alarmCheck.email
                            ? "EMAIL"
                            : "SNS/SMS",
                        isAgreeAlarm: alarmToggle ? "Y" : "N",
                      })
                    }
                    if (result) {
                      await getProfilesPartnerTerms()
                    }
                  }}
                  className="form-check-input"
                  type="checkbox"
                />
              </div>
            </div>
            {/* scroll */}
            <div className="popup-terms-scroll-view mt-2">
              <div className="popup-terms-scroll-view-div">
                <div className="popup-terms-scroll-view-item-border">
                  <div className="popup-terms-scroll-view-item">
                    <p>알림 받기</p>
                    <div className="form-check form-switch form-switch-md">
                      <input
                        checked={alarmToggle}
                        onChange={async () => {
                          let result = false
                          if (alarmToggle) {
                            result = await putProfliesPartnerTerms({
                              termSeq: profileTerms[2].termSeq,
                              isAgree: isAgree ? "Y" : "N",
                              marketingType:
                                !alarmCheck.email && !alarmCheck.sms
                                  ? "NONE"
                                  : alarmCheck.email && alarmCheck.sms
                                  ? "ALL"
                                  : alarmCheck.email
                                  ? "EMAIL"
                                  : "SNS/SMS",
                              isAgreeAlarm: "N",
                            })
                          } else {
                            result = await putProfliesPartnerTerms({
                              termSeq: profileTerms[2].termSeq,
                              isAgree: isAgree ? "Y" : "N",
                              marketingType:
                                !alarmCheck.email && !alarmCheck.sms
                                  ? "NONE"
                                  : alarmCheck.email && alarmCheck.sms
                                  ? "ALL"
                                  : alarmCheck.email
                                  ? "EMAIL"
                                  : "SNS/SMS",
                              isAgreeAlarm: "Y",
                            })
                          }
                          if (result) {
                            await getProfilesPartnerTerms()
                          }

                          // setAlarmToggle(!alarmToggle)
                        }}
                        className="form-check-input"
                        type="checkbox"
                      />
                    </div>
                  </div>
                  <p>휴대폰으로 본인 인증, 구매, 스터디 진행 관련 등의 정보를 받습니다. </p>
                </div>
                <div className="popup-terms-scroll-view-item-border">
                  <div className="popup-terms-scroll-view-item">
                    <p>광고성 알림 받기</p>
                    <div className="form-check form-switch form-switch-md">
                      <input
                        checked={alarmCheck.email || alarmCheck.sms || false}
                        onChange={onClickAlarmTotalCheck}
                        className="form-check-input"
                        type="checkbox"
                      />
                    </div>
                  </div>
                  <p>포인트, 프로모션, 이벤트 등 렛미업의 다양한 혜택정보를 받습니다.</p>
                  <div className="checkbox-view">
                    <button className="btn checkbox " onClick={onClickAlarmTotalCheck}>
                      <img src={alarmCheck.email && alarmCheck.sms ? img_checkbox_active : img_checkbox} alt="checkbox img" />
                      <p>전체</p>
                    </button>

                    <button className="btn checkbox " onClick={() => setAlarmCheck({ ...alarmCheck, email: !alarmCheck.email })}>
                      <img src={alarmCheck.email ? img_checkbox_active : img_checkbox} alt="checkbox img" />
                      <p>이메일</p>
                    </button>
                    <button className="btn checkbox " onClick={() => setAlarmCheck({ ...alarmCheck, sms: !alarmCheck.sms })}>
                      <img src={alarmCheck.sms ? img_checkbox_active : img_checkbox} alt="checkbox img" />
                      <p>SNS문자</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* button */}
          <div className="d-flex justify-content-center mt-3">
            <button className="btn fgb-btn-gray fgb-btn-m" onClick={closePopup}>
              취소
            </button>
            <button className="btn btn-primary font-white fgb-btn-m ms-2" onClick={closePopup}>
              확인
            </button>
          </div>
        </div>
      </LayerPopupLayout>
    )
}

export default SignContainer(TermsNotiPopup)
