/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect } from "react"

import "swiper/scss"

import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import TopInfo from "../../components/common/TopInfo"
import CalendarComponent from "../../components/Calendar"
import Pagination from "../../components/common/pagination"

import NoticePopup from "../../popups/operation/NoticePopup"

import moment from "moment"

import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import icon_calendar from "../../images/ion_calendar-number-outline.svg"

import OperationContainer from "../../containers/OperationContainer"

import PrivateRoute from "../../components/privateRoute"
import { popupFn } from "../../utils/util"
const TYPE = {
  전체: "",
  "(PR) News": "NEWS",
  EVENT안내: "EVENT",
  "정책변경 안내": "POLICY",
  "시스템 안내": "SYSTEM",
  "긴급공지 안내": "EMERGENCY",
  "자주하는 질의응답": "FAQ",
}
const TYPE2 = {
  NEWS: "(PR) News",
  EVENT: "EVENT안내",
  POLICY: "정책변경 안내",
  SYSTEM: "시스템 안내",
  EMERGENCY: "긴급공지 안내",
  FAQ: "자주하는 질의응답",
}
const Notice = props => {
  const { getTargetNotifications, dalNotifications, getNotifications, noticeListEmpty, notificationsTotalEmpty, noticeList, noticeListTotal } = props
  const [calendarPopup, setCalendarPopup] = useState(false)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [date, setDate] = useState(moment())
  const [popupType, setPopupType] = useState(false)

  const [checkList, setCheckList] = useState([])

  const [current, setCurrent] = useState(1)

  const [openNotice, setOpenNotice] = useState(false)
  const [type, setType] = useState("")
  const searchFn = async () => {
    if (startDate > endDate) return false
    let temp = {
      dateStart: startDate.format("YYYY-MM-DD"),
      dateEnd: endDate.format("YYYY-MM-DD"),
      type: TYPE[type] || "",
      page: 0,
      count: 15,
    }
    console.log(temp)
    let result = await getNotifications(temp)
    if (result) {
      setCurrent(1)
      setCheckList([])
    }
  }
  const pageFn = async num => {
    if (startDate > endDate) return false
    let temp = {
      dateStart: startDate.format("YYYY-MM-DD"),
      dateEnd: endDate.format("YYYY-MM-DD"),
      type: TYPE[type] || "",

      page: num - 1,
      count: 15,
    }
    let result = await getCategoryStudentGroups(temp)
    if (result) {
      setCheckList([])
    }
  }
  const delFn = async () => {
    if (checkList.length < 1) {
      return false
    }
    let temp = { noticeSeqs: checkList }
    let result = await dalNotifications(temp)
    if (result) {
      popupFn("삭제되었습니다.")
      searchFn()
    }
  }
  useEffect(() => {
    return () => {
      noticeListEmpty()
      notificationsTotalEmpty()
    }
  }, [])
  return (
    <PrivateRoute>
      <Layout>
        <SEO title="공지사항 관리" />
        <TopInfo title={["공지사항 관리", "운영관리", "공지사항 관리"]} />
        <NoticePopup getDate={searchFn} open={openNotice} onClose={() => setOpenNotice(false)} />

        <div className="operation-learnerType-container">
          <div style={{ border: "1px solid #E1E1E1" }} className="operation-learnerType-table">
            <div className="operation-learnerType-table-name">
              <div>조회기간</div>
            </div>
            <div className="operation-learnerType-table-contents justify-content-between">
              <div className="fgb-date-form ms-4">
                <img src={icon_calendar} />
                <div
                  className="fgb-date-input"
                  onClick={() => {
                    setPopupType(false)
                    setCalendarPopup(true)
                  }}
                >
                  <p>{startDate.isValid() ? startDate.format("YYYY.MM.DD") : "시작일"}</p>
                </div>
                {calendarPopup && (
                  <div className="calendar-popup-back">
                    <div className="calendar-popup-div">
                      <CalendarComponent
                        date={date}
                        setDate={setDate}
                        selectedDate={popupType == false ? startDate : endDate}
                        setSelectedDate={popupType == false ? setStartDate : setEndDate}
                        popup={setCalendarPopup}
                      />
                    </div>
                  </div>
                )}
                <p>~</p>
                <img src={icon_calendar} />

                <div
                  className="fgb-date-input"
                  onClick={() => {
                    setPopupType(true)
                    setCalendarPopup(true)
                  }}
                >
                  <p>{endDate.isValid() ? endDate.format("YYYY.MM.DD") : "종료일"}</p>
                </div>
              </div>
            </div>
            <div className="operation-learnerType-table-name ms-4">
              <div>유형</div>
            </div>
            <div className="d-flex align-items-center">
              <div className="ms-4" style={{ width: "180px" }}>
                <select
                  className="form-select"
                  onChange={e => {
                    setType(e.target.value)
                  }}
                >
                  <option className="" value="">
                    전체
                  </option>
                  <option className="" value="(PR) News">
                    (PR) News
                  </option>
                  <option className="" value="EVENT안내">
                    EVENT안내
                  </option>
                  <option className="" value="정책변경 안내">
                    정책변경 안내
                  </option>
                  <option className="" value="시스템 안내">
                    시스템 안내
                  </option>
                  <option className="" value="긴급공지 안내">
                    긴급공지 안내
                  </option>
                  <option className="" value="자주하는 질의응답">
                    자주하는 질의응답
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <div onClick={searchFn} className="btn btn-primary font-white fgb-btn-l">
              조회
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-4">
            <p>{noticeListTotal.toLocaleString()}건</p>
            <div className="d-flex justify-content-end">
              <div onClick={delFn} className="btn btn-primary font-white reverse fgb-btn-ms clear-button me-2">
                선택 삭제
              </div>
              <div onClick={() => setOpenNotice(true)} className="btn btn-primary font-white fgb-btn-m me-2">
                공지사항 등록
              </div>
            </div>
          </div>
          <div className="operation-learnerType-list-table mt-3">
            <div className="fgb-table-div">
              <table className={`fgb-table mb-4`}>
                <thead>
                  <tr className={`fgb-table-title-tr`}>
                    <td>
                      <img
                        onClick={() => {
                          if (checkList.length == noticeList.length) {
                            setCheckList([])
                          } else {
                            let temp = []
                            for (let i of noticeList) {
                              temp = [...temp, i.noticeSeq]
                            }
                            setCheckList(temp)
                          }
                        }}
                        className="ms-4 me-2"
                        src={checkList.length == noticeList.length && noticeList.length > 0 ? checked_on : checked_off}
                      />
                    </td>
                    {["유형", "공지사항 제목", "전시 여부", "등록자", "등록일", "변경일"].map((i, idx) => (
                      <td key={idx}>{i}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {noticeList.map((i, idx) => (
                    <tr className={`fgb-table-tr ${checkList.indexOf(i.noticeSeq) != -1 ? "active" : ""}`} key={idx}>
                      <td>
                        <img
                          onClick={() => {
                            let check = checkList.indexOf(i.noticeSeq)
                            console.log(check)
                            if (check == -1) {
                              setCheckList([...checkList, i.noticeSeq])
                            } else {
                              let temp = [...checkList]
                              temp.splice(check, 1)
                              setCheckList(temp)
                            }
                          }}
                          className="ms-4 me-2"
                          src={checkList.indexOf(i.noticeSeq) != -1 ? checked_on : checked_off}
                        />
                      </td>
                      <td>{TYPE2[i.type]}</td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={async () => {
                          await getTargetNotifications(i.noticeSeq)
                          setOpenNotice(true)
                        }}
                      >
                        {i.subject}
                      </td>
                      <td>{i.isDisplay}</td>
                      <td>{i.adminName}</td>
                      <td>{moment(i.regDatetime).format("YYYY-MM-DD")}</td>
                      <td>{i.updateDatetime == "" ? "" : moment(i.updateDatetime).format("YYYY-MM-DD")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination count={15} fn={pageFn} current={current} setCurrent={setCurrent} total={noticeListTotal} />
            </div>
          </div>
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default OperationContainer(Notice)
