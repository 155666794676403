import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import {
  getDecorationItemListAction,
  getDecorationItemListExcelAction,
  getDecorationItemInfoAction,
  putDecorationItemAction,
  postDecorationItemAction,
  getContentListAction,
  getContentListExcelAction,
  getContentInfoAction,
  postContentAction,
  putContentAction
} from "../store/modules/service"

const mapStateToProps = state => ({
  decorationItemList: state.service.decorationItemList,
  decorationItemInfo: state.service.decorationItemInfo,
  contentList: state.service.contentList,
  contentInfo: state.service.contentInfo
})

const mapDispatchToProps = dispatch => ({
  getDecorationItemList: param => dispatch(getDecorationItemListAction(param)),
  getDecorationItemListExcel: param => dispatch(getDecorationItemListExcelAction(param)),
  getDecorationItemInfo: param => dispatch(getDecorationItemInfoAction(param)),
  putDecorationItem: param => dispatch(putDecorationItemAction(param)),
  postDecorationItem: param => dispatch(postDecorationItemAction(param)),
  getContentList: param => dispatch(getContentListAction(param)),
  getContentListExcel: param => dispatch(getContentListExcelAction(param)),
  getContentInfo: param => dispatch(getContentInfoAction(param)),
  postContent: param => dispatch(postContentAction(param)),
  putContent: param => dispatch(putContentAction(param)),
})

const WithHoc = ChildComponent => props => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)