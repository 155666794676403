import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import {
  putAdminsPermissionAction,
  getAdminMasterPopAction,
  getAdminsInfoAction,
  getAdminMasterAction,
  getAdminTypeAction,
  getAdminMeue1Action,
  getAdminMeue2Action,
  delAdminsAction,
  setAdminInfo,
  setAdminMasterPopList,
  putAdminsTypeAction,
} from "../store/modules/master"

// redux state > component mapping
const mapStateToProps = state => ({
  masterList: state.master.masterList,
  masterPopList: state.master.masterPopList,
  adminTypeList: state.master.adminTypeList,
  admin1DepthList: state.master.admin1Depth,
  admin2DepthList: state.master.admin2Depth,
  adminInfo: state.master.adminInfo,
})

// redux action > component mapping
const mapDispatchToProps = dispatch => ({
  //관리자 조회
  getAdminMasterList: param => dispatch(getAdminMasterAction(param)),
  //팝업에서 관리자 조회
  getAdminMasterPopList: param => dispatch(getAdminMasterPopAction(param)),
  //관리자 유형
  getAdminType: param => dispatch(getAdminTypeAction(param)),
  //1뎁스 메뉴 조회
  getAdminMeue1: param => dispatch(getAdminMeue1Action(param)),
  //2뎁스 메뉴 조회
  getAdminMeue2: param => dispatch(getAdminMeue2Action(param)),
  //관리자 단일 조회
  getAdminsInfo: param => dispatch(getAdminsInfoAction(param)),
  //관리자 권한 수정
  putAdminsPermission: param => dispatch(putAdminsPermissionAction(param)),
  // 관리자 유형관리 수정
  putAdminsType: param => dispatch(putAdminsTypeAction(param)),
  //삭제
  delAdmins: param => dispatch(delAdminsAction(param)),
  //어드민 정보 비우기
  emptyAdminInfo: param => dispatch(setAdminInfo({})),
  emptyAdminMasterPopList: param => dispatch(setAdminMasterPopList([])),
})

const WithHoc = ChildComponent => props => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
