import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import {
  getStatisticsStudyTimeAction,
  getStatisticsMembersAction,
  getStatisticsStudyCountAction,
  getStatisticsTodoCountAction,
  getStatisticsStudiesAction,
  getMyStudiesAction,
  getMyStudiesSchedulesAction,
  getMyTodosAction,
  getRankingStudyKeywordAction,
  getRankingStudyBestAction,
  getRankingStudyTimeAction,
  getMemberRecentlyAction,
} from "../store/modules/affiliatesMain"

// redux state > component mapping
const mapStateToProps = state => ({
  partnerMainStudyTime: state.affiliatesMain.partnerMainStudyTime,
  partnerMainMember: state.affiliatesMain.partnerMainMember,
  partnerMainStudy: state.affiliatesMain.partnerMainStudy,
  partnerMainTodo: state.affiliatesMain.partnerMainTodo,
  partnerMainStudyList: state.affiliatesMain.partnerMainStudyList,
  partnerMainMyStudies: state.affiliatesMain.partnerMainMyStudies,
  partnerMainMyStudiesSchedules: state.affiliatesMain.partnerMainMyStudiesSchedules,
  partnerMainMyTodos: state.affiliatesMain.partnerMainMyTodos,
  partnerMainRankKeyword: state.affiliatesMain.partnerMainRankKeyword,
  partnerMainRankStudy: state.affiliatesMain.partnerMainRankStudy,
  partnerMainRankTime: state.affiliatesMain.partnerMainRankTime,
  partnerMainMemberRecently: state.affiliatesMain.partnerMainMemberRecently,
  partnerProfile: state.sign.partnerProfile,
})

// redux action > component mapping
const mapDispatchToProps = dispatch => ({
  //총 스터디 누적 현황 get
  getStatisticsStudyTime: param => dispatch(getStatisticsStudyTimeAction(param)),
  //총 누적 인원 현황 get
  getStatisticsMembers: param => dispatch(getStatisticsMembersAction(param)),
  //총 누적 스터디 현황 get
  getStatisticsStudyCount: param => dispatch(getStatisticsStudyCountAction(param)),
  //총 누적 todo 현황 get
  getStatisticsTodoCount: param => dispatch(getStatisticsTodoCountAction(param)),
  //공부 누적 시간 현황
  getStatisticsStudies: param => dispatch(getStatisticsStudiesAction(param)),
  //내 스터디 리스트
  getMyStudies: param => dispatch(getMyStudiesAction(param)),
  //내 스터디 타겟 스케줄 정보
  getMyStudiesSchedules: param => dispatch(getMyStudiesSchedulesAction(param)),
  //내 todos get
  getMyTodos: param => dispatch(getMyTodosAction(param)),
  //랭킹 키워드 get
  getRankingStudyKeyword: param => dispatch(getRankingStudyKeywordAction(param)),
  //랭킹 스터디 get
  getRankingStudyBest: param => dispatch(getRankingStudyBestAction(param)),
  //랭킹 누적시간 get
  getRankingStudyTime: param => dispatch(getRankingStudyTimeAction(param)),
  //신규가입  get
  getMemberRecently: param => dispatch(getMemberRecentlyAction(param)),
})

const WithHoc = ChildComponent => props => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
