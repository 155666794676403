import React, { useState, useEffect } from "react"
import ScheduleContainer from "../../containers/ScheduleContainer"

import CloseButton from "../../images/img_popup_close_black.svg"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"

const CamstudyPopup = props => {
  const { onClose, subscriptionSeq, getScheduleCamstudyInfo, scheduleCamstudyInfo, putScheduleCamstudy } = props

  const [status, setStatus] = useState(0) // 상태 0: 승인, 1: 반려
  const [checkAll, setCheckAll] = useState(false)
  const [app, setApp] = useState(false)
  const [email, setEmail] = useState(false)
  const [about, setAbout] = useState("") // 안내 정보 고지

  useEffect(() => {
    async function getLists() {
      await getScheduleCamstudyInfo({ subscriptionSeq: subscriptionSeq })
    }

    async function resetLists() {
      await getScheduleCamstudyInfo()
    }

    getLists()

    return () => {
      resetLists()
    }
  }, [])

  useEffect(() => {
    setStatus(
      scheduleCamstudyInfo?.studySubscription?.status === "APPROVAL" ? 1 : scheduleCamstudyInfo?.studySubscription?.status === "REJECTION" ? 2 : 0
    )
    setAbout(scheduleCamstudyInfo?.studySubscription?.alarmContent !== undefined ? scheduleCamstudyInfo?.studySubscription?.alarmContent : "")
    setApp(scheduleCamstudyInfo?.studySubscription?.isAlarmPush === "Y" ? true : false)
    setEmail(scheduleCamstudyInfo?.studySubscription?.isAlarmEmail === "Y" ? true : false)
  }, [scheduleCamstudyInfo])

  const handleCheck = () => {
    if (checkAll) {
      setCheckAll(false)
      setApp(false)
      setEmail(false)
    } else {
      setCheckAll(true)
      setApp(true)
      setEmail(true)
    }
  }

  useEffect(() => {
    if (app && email) {
      setCheckAll(true)
    } else {
      setCheckAll(false)
    }
  }, [app, email])

  const handleSave = async () => {
    const subscription_seq = scheduleCamstudyInfo?.studySubscription?.subscriptionSeq
    const status_ = status === 1 ? "APPROVAL" : status === 2 ? "REJECTION" : ""
    const is_alarm_push = app ? "Y" : "N"
    const is_alarm_email = email ? "Y" : "N"
    const alarm_content = about
    const receive_phone = scheduleCamstudyInfo?.studySubscription?.receivePhone
    const receive_email = scheduleCamstudyInfo?.studySubscription?.receiveEmail

    if (status_ === "") {
      alert("신청 상태를 선택해주세요.")
    } else if (alarm_content === "") {
      alert("안내 정보 고지를 입력해주세요.")
    } else {
      console.log("scheduleCamstudyInfo", scheduleCamstudyInfo.studySubscription)
      const payload = {
        subscriptionSeq: subscription_seq,
        status: status_,
        isAlarmPush: is_alarm_push,
        isAlarmEmail: is_alarm_email,
        alarmContent: alarm_content,
        receivePhone: receive_phone,
        receiveEmail: receive_email,
        studySeq: scheduleCamstudyInfo.studySubscription.studySeq,
        applicantUserSeq: scheduleCamstudyInfo.studySubscription.userSeq,
        applicantPartnerSeq: scheduleCamstudyInfo.studySubscription.partnerSeq,
        applicantAdminSeq: scheduleCamstudyInfo.studySubscription.adminSeq,
      }

      console.log(payload)
      const result = await putScheduleCamstudy(payload)

      if (result) {
        onClose()
      }
    }
  }

  return (
    <div onClick={onClose} className="schedule-popup-container camstudy-popup-container">
      <div onClick={e => e.stopPropagation()} className="schedule-popup-wrapper">
        <img src={CloseButton} alt="close-popup-button" className="close-popup-button" onClick={onClose} />
        <div className="schedule-popup-title">캠스터디 신청 업무 처리</div>

        <div className="table-wrapper">
          <div className="table-row">
            <div className="element-l">신청자</div>
            <div className="element-r">{scheduleCamstudyInfo?.studySubscription?.name}</div>
            <div className="element-l">학습자 소속 그룹</div>
            <div className="element-r">{scheduleCamstudyInfo?.studySubscription?.studentGroupsName}</div>
          </div>
          <div className="table-row">
            <div className="element-l">캠스터디 스케줄 타이틀</div>
            <div className="element-r">{scheduleCamstudyInfo?.studySubscription?.title}</div>
            <div className="element-l">상태</div>
            <div className="element-r">
              <div className="radio-btn-wrapper" onClick={() => setStatus(1)}>
                <img src={status == 1 ? radio_on : radio_off} />
                <p>승인</p>
              </div>
              <div className="radio-btn-wrapper" onClick={() => setStatus(2)}>
                <img src={status == 2 ? radio_on : radio_off} />
                <p>반려</p>
              </div>
            </div>
          </div>
          <div className="table-row-big">
            <div style={{ minWidth: "99.73px" }} className="element-l-big">
              안내 정보 고지
            </div>
            <div className="element-r-big">
              <div className="three-checks">
                <div className="check-box-wrapper" onClick={() => handleCheck()}>
                  <img src={checkAll ? checked_on : checked_off} />
                  <p>전체</p>
                </div>
                <div className="check-box-wrapper" onClick={() => setApp(!app)}>
                  <img src={app ? checked_on : checked_off} />
                  <p>APP Push 알림</p>
                </div>
                <div className="check-box-wrapper" onClick={() => setEmail(!email)}>
                  <img src={email ? checked_on : checked_off} />
                  <p>이메일</p>
                </div>
              </div>
              <textarea value={about} onChange={e => setAbout(e.target.value)} maxLength={200} />
              <div className="textarea-info">
                <p>{about.length}자 / 200자</p>
                <div className="reset-btn btn btn-primary font-white fgb-btn-m" onClick={e => setAbout("")}>
                  초기화
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="two-buttons-wrapper">
          <div className="btn btn-primary font-white reverse fgb-btn-m" onClick={onClose}>
            취소
          </div>
          <div className="btn btn-primary font-white fgb-btn-m" onClick={handleSave}>
            저장
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScheduleContainer(CamstudyPopup)
