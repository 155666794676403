import React from "react"
import DaumPostcode from "react-daum-postcode"
import LayerPopupLayout from "./LayerPopupLayout"

const ZipCodePppup = ({ open, onClose, onComplete, defaultQuery }) => {
  const closePopup = () => {
    onClose()
  }

  if (open)
    return (
      <LayerPopupLayout
        style={{ alignItems: "flex-start", marginTop: "40px" }}
        open={open}
        onClose={closePopup}
        transparency
        isMCloseBtn
        zIndex={102}
      >
        <div className="popup-zipcode-container">
          <div className="popup-zipcode-div">
            <DaumPostcode style={{ flex: 1 }} onComplete={onComplete} defaultQuery={defaultQuery} />
            <button className="btn btn-primary font-white popup-zipcode-close-btn" onClick={closePopup}>
              닫기
            </button>
          </div>
        </div>
      </LayerPopupLayout>
    )
  else return <div></div>
}

export default ZipCodePppup
