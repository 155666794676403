import React, { useState } from "react"
import img_popup_close_gray from "../../images/img_popup_close_gray.svg"
import CloseBtn from "../../images/Close_Button.svg"
import LayerPopupLayout from "../LayerPopupLayout"
import MasterContainer from "../../containers/MasterContainer"
import Validator from "validator"
import { debounce } from "lodash"
import { dataCheckFn, popupFn } from "../../utils/util"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import { useEffect } from "react"
import ModalPopup from "../ModalPopup"

const PermissionTypePopup = props => {
  const { open, onClose, getAdminType, adminTypeList, putAdminsType } = props
  const [dataList, setDataList] = useState([])
  const [checkList, setCheckList] = useState([])
  const [openModal, setOpenModal] = useState(false)

  const closePopup = e => {
    onClose()
  }
  const getData = async () => {
    await getAdminType()
  }
  const delFn = async () => {
    let temp = []
    for (let i = dataList.length - 1; i >= 0; i--) {
      let check = false
      for (let k of checkList) {
        if (k == i) {
          check = true
        }
      }
      if (!check) {
        temp = [...temp, dataList[i]]
      }
    }
    let tempReverse = []
    for (let i = temp.length - 1; i >= 0; i--) {
      tempReverse = [...tempReverse, temp[i]]
    }
    setDataList(tempReverse)
    setCheckList([])
  }
  const putAdminsTypeFn = debounce(async () => {
    let temp = []
    for (let i = 0; i < dataList.length; i++) {
      if (!Validator.isEmpty(dataList[i].name, { ignore_whitespace: true })) {
        temp = [...temp, dataList[i]]
      }
    }
    // console.log(temp)
    let result = await putAdminsType({ adminTypes: temp })
    console.log(result, "result")
    if (result) {
      getData()
      popupFn("저장되었습니다.")
      setOpenModal(true)
    }
  }, 300)
  useEffect(() => {
    if (open) {
      getData()
    }
  }, [open])
  useEffect(() => {
    if (dataCheckFn(adminTypeList)) {
      setDataList(adminTypeList)
    }
  }, [adminTypeList])
  return (
    <LayerPopupLayout open={open} onClose={() => closePopup()} isCloseBtn transparency zIndex={101}>
      <ModalPopup open={openModal} onClose={() => setOpenModal(false)} contents={"저장되었습니다."} />
      <div className="permission-type-popup-container layer-popup-container">
        <p className="popup-title">관리자 유형 관리</p>
        <div className="d-flex justify-content-end mt-5">
          <button onClick={delFn} className="btn btn-primary fgb-btn-gray fgb-btn-m me-2">
            선택삭제
          </button>
          <button
            onClick={() => {
              setDataList([...dataList, { authorityNumber: 0, name: "", seq: "" }])
            }}
            className="btn btn-primary font-white fgb-btn-m"
          >
            행추가
          </button>
        </div>
        <div className="permission-type-popup-table mt-3">
          <table className={`fgb-table`}>
            <tbody>
              {dataList.map((i, idx) => (
                <tr className={`fgb-table-tr ${checkList.indexOf(idx) != -1 ? "active" : ""}`} key={idx}>
                  <td>
                    <div className="d-flex  justify-content-start align-items-center ps-2 pt-2 pb-2">
                      <img
                        onClick={() => {
                          if (idx <= 2) return false
                          let check = checkList.indexOf(idx)
                          if (check == -1) {
                            setCheckList([...checkList, idx])
                          } else {
                            let temp = [...checkList]
                            temp.splice(check, 1)
                            setCheckList(temp)
                          }
                        }}
                        className="ms-3 me-3"
                        src={checkList.indexOf(idx) != -1 ? checked_on : checked_off}
                      />
                      <div className="d-flex flex-column ms-3">
                        <div className="d-flex align-items-center">
                          <p className="flex-shrink-0 ">운영자 유형</p>
                          <input
                            value={i.name}
                            onChange={e => {
                              if (idx >= 3) {
                                let temp = [...dataList]
                                temp[idx].name = e.target.value
                                setDataList(temp)
                              }
                            }}
                            type="text"
                            className="fgb-input ms-4"
                          />
                        </div>
                        <div className="d-flex align-items-center mt-4">
                          <p className="">운영자 권한</p>
                          <p className="d-flex align-items-center ms-4">
                            <img
                              className="me-1 ms-2"
                              onClick={() => {
                                let temp = [...dataList]
                                if (i.authorityNumber >= 7) {
                                  temp[idx].authorityNumber = 0
                                } else {
                                  temp[idx].authorityNumber = 7
                                }
                                setDataList(temp)
                              }}
                              src={i.authorityNumber >= 7 ? checked_on : checked_off}
                            />
                            전체
                            <img
                              className="me-1 ms-2"
                              onClick={() => {
                                let temp = [...dataList]
                                if (i.authorityNumber >= 1) {
                                  temp[idx].authorityNumber = 0
                                } else {
                                  temp[idx].authorityNumber = 1
                                }
                                setDataList(temp)
                              }}
                              src={i.authorityNumber >= 1 ? checked_on : checked_off}
                            />
                            조회
                            <img
                              className="me-1 ms-2"
                              onClick={() => {
                                let temp = [...dataList]
                                if (i.authorityNumber >= 3) {
                                  temp[idx].authorityNumber = 0
                                } else {
                                  temp[idx].authorityNumber = 3
                                }
                                setDataList(temp)
                              }}
                              src={i.authorityNumber >= 3 ? checked_on : checked_off}
                            />
                            등록/변경
                            <img
                              className="me-1 ms-2"
                              onClick={() => {
                                let temp = [...dataList]
                                if (i.authorityNumber >= 7) {
                                  temp[idx].authorityNumber = 0
                                } else {
                                  temp[idx].authorityNumber = 7
                                }
                                setDataList(temp)
                              }}
                              src={i.authorityNumber >= 7 ? checked_on : checked_off}
                            />
                            승인/반려
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="permission-type-popup-btn-view">
          <button onClick={() => closePopup()} className="btn btn-primary fgb-btn-gray fgb-btn-l ">
            취소
          </button>
          <button onClick={putAdminsTypeFn} className="btn btn-primary font-white fgb-btn-l ">
            저장
          </button>
        </div>
      </div>
    </LayerPopupLayout>
  )
}

export default MasterContainer(PermissionTypePopup)
const DUMY = [
  {
    group: "a",
    class: 2,
    name: "asdfew",
    num: 6,
  },
  {
    group: "a",
    class: 2,
    name: "asdfew",
    num: 6,
  },
  {
    group: "a",
    class: 2,
    name: "asdfew",
    num: 6,
  },
  {
    group: "a",
    class: 2,
    name: "asdfew",
    num: 6,
  },
  {
    group: "a",
    class: 2,
    name: "asdfew",
    num: 6,
  },
  {
    group: "a",
    class: 2,
    name: "asdfew",
    num: 6,
  },
]
