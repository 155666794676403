/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect } from "react"

import "swiper/scss"

import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import TopInfo from "../../components/common/TopInfo"

import moment from "moment"

import Pagination from "../../components/common/pagination"
import CalendarComponent from "../../components/Calendar"

import LearnerTypePopup from "../../popups/operation/LearnerTypePopup"

import icon_calendar from "../../images/ion_calendar-number-outline.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"

import OperationContainer from "../../containers/OperationContainer"
import PrivateRoute from "../../components/privateRoute"
const LearnerType = props => {
  const {
    categoryStudentGroup,
    getCategoryStudentGroup,
    studentGroups,
    categoryStudentGroups,
    categoryStudentGroupsTotal,
    getStudentGroups,
    getCategoryStudentGroups,
    setCategoryStudentGroupsEmpty,
    setCategoryStudentGroupsTotalEmpty,
    delCategoryStudentGroups,
  } = props
  //date 세팅용 state
  const [calendarPopup, setCalendarPopup] = useState(false)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [date, setDate] = useState(moment())
  const [popupType, setPopupType] = useState(false)
  const [dateType, setDateType] = useState(true)
  const [checkList, setCheckList] = useState([])
  const [depth1, setDepth1] = useState([
    {
      level1: {
        level1Seq: "",
        level1Name: "전체",
        level2: [],
      },
    },
  ])
  const [depth1Sel, setDepth1Sel] = useState(0)
  const [depth2, setDepth2] = useState([
    {
      level2Seq: "",
      level2Name: "전체",
    },
  ])
  const [depth2Sel, setDepth2Sel] = useState(0)

  const [current, setCurrent] = useState(1)

  const [openLearnerTypePopup, setOpenLearnerTypePopup] = useState(false)

  const getData = async () => {
    await getStudentGroups()
  }
  const delFn = async () => {
    if (checkList.length <= 0) {
      return false
    }
    let temp = { studentGroupSeqs: checkList }

    let result = await delCategoryStudentGroups(temp)
    if (result) {
      searchFn()
    }
  }

  const searchFn = async () => {
    if (startDate > endDate) return false
    let temp = {
      dateType: dateType ? "REG_DATETIME" : "UPDATE_DATETIME",
      dateStart: startDate.format("YYYY-MM-DD"),
      dateEnd: endDate.format("YYYY-MM-DD"),
      studentGroupSeqDepth1: depth1[depth1Sel].level1.level1Seq,
      studentGroupSeqDepth2: depth2[depth2Sel].level2Seq,
      page: 0,
      count: 15,
    }
    console.log(temp)
    let result = await getCategoryStudentGroups(temp)
    if (result) {
      setCurrent(1)
      setCheckList([])
    }
  }
  const pageFn = async num => {
    if (startDate > endDate) return false
    let temp = {
      dateType: dateType ? "REG_DATETIME" : "UPDATE_DATETIME",
      dateStart: startDate.format("YYYY-MM-DD"),
      dateEnd: endDate.format("YYYY-MM-DD"),
      studentGroupSeqDepth1: depth1[depth1Sel].level1.level1Seq,
      studentGroupSeqDepth2: depth2[depth2Sel].level2Seq,
      page: num - 1,
      count: 15,
    }
    let result = await getCategoryStudentGroups(temp)
    if (result) {
      setCheckList([])
    }
  }
  useEffect(() => {
    getData()
    return () => {
      setCategoryStudentGroupsEmpty()
      setCategoryStudentGroupsTotalEmpty()
    }
  }, [])

  useEffect(() => {
    setDepth1([
      {
        level1: {
          level1Seq: "",
          level1Name: "전체",
          level2: [],
        },
      },
      ...studentGroups.map(i => {
        return i
      }),
    ])
  }, [studentGroups])

  useEffect(() => {
    if (depth1.length > 0) {
      setDepth2Sel(0)
      setDepth2([
        {
          level2Seq: "",
          level2Name: "전체",
        },
        ...depth1[depth1Sel].level1.level2.map(i => {
          console.log(i)
          return i
        }),
      ])
    }
  }, [depth1Sel])
  return (
    <PrivateRoute>
      <Layout>
        <SEO title="학습자 유형 관리" />
        <TopInfo title={["학습자유형 관리", "운영관리", "학습자유형 관리"]} />
        <LearnerTypePopup searchFn={searchFn} open={openLearnerTypePopup} onClose={() => setOpenLearnerTypePopup(false)} />

        <div className="operation-learnerType-container">
          <div className="operation-learnerType-table ">
            <div className="operation-learnerType-table-name ">
              <div>조회 기간</div>
            </div>
            <div className="operation-learnerType-table-contents justify-content-between">
              <div className="d-flex align-items-center">
                <img onClick={() => setDateType(true)} className="ms-4 me-2" src={dateType ? radio_on : radio_off} />
                <p>등록일 </p>
                <img onClick={() => setDateType(false)} className="ms-4 me-2" src={!dateType ? radio_on : radio_off} />
                <p>변경일</p>
              </div>
              <div className="fgb-date-form ms-4">
                <img src={icon_calendar} />
                <div
                  className="fgb-date-input"
                  onClick={() => {
                    setPopupType(false)
                    setCalendarPopup(true)
                  }}
                >
                  <p>{startDate.isValid() ? startDate.format("YYYY.MM.DD") : "시작일"}</p>
                </div>
                {calendarPopup && (
                  <div className="calendar-popup-back">
                    <div className="calendar-popup-div">
                      <CalendarComponent
                        date={date}
                        setDate={setDate}
                        selectedDate={popupType == false ? startDate : endDate}
                        setSelectedDate={popupType == false ? setStartDate : setEndDate}
                        popup={setCalendarPopup}
                      />
                    </div>
                  </div>
                )}
                <p>~</p>
                <img src={icon_calendar} />

                <div
                  className="fgb-date-input"
                  onClick={() => {
                    setPopupType(true)
                    setCalendarPopup(true)
                  }}
                >
                  <p>{endDate.isValid() ? endDate.format("YYYY.MM.DD") : "종료일"}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="operation-learnerType-table">
            <div className="operation-learnerType-table-name">
              <div>학습자 그룹</div>
            </div>
            <div className="operation-learnerType-table-contents">
              <div className="ms-4" style={{ width: "190px" }}>
                <select
                  className="form-select"
                  onChange={e => {
                    console.log(e.target.value)
                    setDepth1Sel(e.target.value)
                  }}
                >
                  {depth1.map((i, idx) => (
                    <option key={idx} className="" value={idx}>
                      {i.level1.level1Name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="ms-2" style={{ width: "190px" }}>
                <select
                  className="form-select"
                  onChange={e => {
                    console.log(e.target.value)
                    setDepth2Sel(e.target.value)
                  }}
                >
                  {depth2.map((i, idx) => (
                    <option key={idx} className="" value={idx}>
                      {i.level2Name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <div onClick={searchFn} className="btn btn-primary font-white fgb-btn-l">
              조회
            </div>
          </div>
          <div className="d-flex justify-content-between mt-4">
            <p>{categoryStudentGroupsTotal.toLocaleString()}건</p>
            <div className="d-flex justify-content-end">
              <div onClick={delFn} className="btn btn-primary font-white reverse fgb-btn-ms clear-button me-2">
                선택 삭제
              </div>
              <div onClick={() => setOpenLearnerTypePopup(true)} className="btn btn-primary font-white  fgb-btn-m me-2">
                학습자 유형 등록
              </div>
            </div>
          </div>
          <div className="introduce-study-table mt-3">
            <div className="fgb-table-div">
              <table className={`fgb-table mb-4`}>
                <thead>
                  <tr className={`fgb-table-title-tr`}>
                    <td>
                      <img
                        onClick={() => {
                          if (checkList.length == categoryStudentGroups.length) {
                            setCheckList([])
                          } else {
                            let temp = []
                            for (let i of categoryStudentGroups) {
                              temp = [...temp, i.seq]
                            }
                            setCheckList(temp)
                          }
                        }}
                        className="ms-4 me-2"
                        src={checkList.length == categoryStudentGroups.length && categoryStudentGroups.length > 0 ? checked_on : checked_off}
                      />
                    </td>
                    {[
                      "학습자그룹 1DEPTH",
                      "학습자 그룹 2DEPTH",
                      "학습자  (명)",
                      "누적 스터디 참여 횟수(건)",
                      "평균 누적공부시간",
                      "등록일시",
                      "변경일시",
                    ].map((i, idx) => (
                      <td key={idx}>{i}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {categoryStudentGroups.map((i, idx) => (
                    <tr style={{ cursor: "pointer" }} className={`fgb-table-tr ${checkList.indexOf(i.seq) != -1 ? "active" : ""}`} key={idx}>
                      <td>
                        <img
                          onClick={() => {
                            let check = checkList.indexOf(i.seq)
                            console.log(check)
                            if (check == -1) {
                              setCheckList([...checkList, i.seq])
                            } else {
                              let temp = [...checkList]
                              temp.splice(check, 1)
                              setCheckList(temp)
                            }
                          }}
                          className="ms-4 me-2"
                          src={checkList.indexOf(i.seq) != -1 ? checked_on : checked_off}
                        />
                      </td>
                      <td
                        onClick={async () => {
                          await getCategoryStudentGroup(i.seq)
                          setOpenLearnerTypePopup(true)
                        }}
                      >
                        {i.studentGroupDepth1Name}
                      </td>
                      <td
                        onClick={async () => {
                          await getCategoryStudentGroup(i.seq)
                          setOpenLearnerTypePopup(true)
                        }}
                      >
                        {i.studentGroupDepth2Name}
                      </td>
                      <td
                        onClick={async () => {
                          await getCategoryStudentGroup(i.seq)
                          setOpenLearnerTypePopup(true)
                        }}
                      >
                        {i.studentCount}
                      </td>
                      <td
                        onClick={async () => {
                          await getCategoryStudentGroup(i.seq)
                          setOpenLearnerTypePopup(true)
                        }}
                      >
                        {i.accumulatedJoinCount}
                      </td>
                      <td
                        onClick={async () => {
                          await getCategoryStudentGroup(i.seq)
                          setOpenLearnerTypePopup(true)
                        }}
                      >
                        {i.accumulatedStudyTime}
                      </td>
                      <td
                        onClick={async () => {
                          await getCategoryStudentGroup(i.seq)
                          setOpenLearnerTypePopup(true)
                        }}
                      >
                        {moment(i.regDatetime).format("YYYY-MM-DD HH:MM")}
                      </td>
                      <td
                        onClick={async () => {
                          await getCategoryStudentGroup(i.seq)
                          setOpenLearnerTypePopup(true)
                        }}
                      >
                        {moment(i.updateDatetime).format("YYYY-MM-DD HH:MM")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination fn={pageFn} count={15} current={current} setCurrent={setCurrent} total={categoryStudentGroupsTotal} />
            </div>
          </div>
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default OperationContainer(LearnerType)
