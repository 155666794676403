import { navigate } from "gatsby-link"
import { createAction, handleActions } from "redux-actions"
import MemberApis from "../../apis/MemberApis"
import { popupFn } from "../../utils/util"

const GET_USER_LIST = "member/GET_USER_LIST"
const GET_USER_INFO = "member/GET_USER_INFO"
const GET_USER_LEARNING_INFO = "member/GET_USER_LEARNING_INFO"
const GET_USER_CREATED_STUDY_LIST = "member/GET_USER_CREATED_STUDY_LIST"
const GET_USER_PARTICIPATED_STUDY_LIST = "member/GET_USER_PARTICIPATED_STUDY_LIST"
const GET_USER_TO_DO_LIST = "member/GET_USER_TO_DO_LIST"
const GET_USER_STUDY_TIME_RANKING_LIST = "member/GET_USER_STUDY_TIME_RANKING_LIST"
const GET_USERS_FRIEND_LIST = "member/GET_USERS_FRIEND_LIST"
const GET_WITHDRAWAL_MEMBER_LIST = "member/GET_WITHDRAWAL_MEMBER_LIST"
const DEL_WITHDRAWAL_MEMBER = "member/DEL_WITHDRAWAL_MEMBER"
const GET_MEMBER_POINT_INFO = "member/GET_MEMBER_POINT_INFO"
const GET_MEMBER_POINT_ACCUMULATED = "member/GET_MEMBER_POINT_ACCUMULATED"
const GET_MEBMER_POINT_REWARDED = "member/GET_MEBMER_POINT_REWARDED"
const GET_MEMBER_POINT_CHARGED = "member/GET_MEMBER_POINT_CHARGED"
const GET_MEMBER_POINT_PAID = "member/GET_MEMBER_POINT_PAID"

export const getUserList = createAction(GET_USER_LIST)
export const getUserInfo = createAction(GET_USER_INFO)
export const getUserLearningInfo = createAction(GET_USER_LEARNING_INFO)
export const getUserCreatedStudyList = createAction(GET_USER_CREATED_STUDY_LIST)
export const getUserParticipatedStudyList = createAction(GET_USER_PARTICIPATED_STUDY_LIST)
export const getUserToDoList = createAction(GET_USER_TO_DO_LIST)
export const getUserStudyTimeRankingList = createAction(GET_USER_STUDY_TIME_RANKING_LIST)
export const getUsersFriendList = createAction(GET_USERS_FRIEND_LIST)
export const getWithdrawalMemberList = createAction(GET_WITHDRAWAL_MEMBER_LIST)
export const delWithdrawalMember = createAction(DEL_WITHDRAWAL_MEMBER)
export const getMemberPointInfo = createAction(GET_MEMBER_POINT_INFO)
export const getMemberPointAccumulated = createAction(GET_MEMBER_POINT_ACCUMULATED)
export const getMemberPointRewarded = createAction(GET_MEBMER_POINT_REWARDED)
export const getMemberPointCharged = createAction(GET_MEMBER_POINT_CHARGED)
export const getMemberPointPaid = createAction(GET_MEMBER_POINT_PAID)

const initState = {
  userList: undefined,
  userInfo: undefined,
  userLearningInfo: undefined,
  userCreatedStudyList: undefined,
  userParticipatedStudyList: undefined,
  userToDoList: undefined,
  userStudyTimeRankingList: undefined,
  usersFriendList: undefined,
  withdrawalMemberList: undefined,
  pointInfo: undefined,
  pointAccumulated: undefined,
  pointRewarded: undefined,
  pointCharged: undefined,
  pointPaid: undefined,
}

export default handleActions(
  {
    [GET_USER_LIST]: (state, action) => ({
      ...state,
      userList: action.payload,
    }),
    [GET_USER_INFO]: (state, action) => ({
      ...state,
      userInfo: action.payload,
    }),
    [GET_USER_LEARNING_INFO]: (state, action) => ({
      ...state,
      userLearningInfo: action.payload,
    }),
    [GET_USER_CREATED_STUDY_LIST]: (state, action) => ({
      ...state,
      userCreatedStudyList: action.payload,
    }),
    [GET_USER_PARTICIPATED_STUDY_LIST]: (state, action) => ({
      ...state,
      userParticipatedStudyList: action.payload,
    }),
    [GET_USER_TO_DO_LIST]: (state, action) => ({
      ...state,
      userToDoList: action.payload,
    }),
    [GET_USER_STUDY_TIME_RANKING_LIST]: (state, action) => ({
      ...state,
      userStudyTimeRankingList: action.payload,
    }),
    [GET_USERS_FRIEND_LIST]: (state, action) => ({
      ...state,
      usersFriendList: action.payload,
    }),
    [GET_WITHDRAWAL_MEMBER_LIST]: (state, action) => ({
      ...state,
      withdrawalMemberList: action.payload,
    }),
    [GET_MEMBER_POINT_INFO]: (state, action) => ({
      ...state,
      pointInfo: action.payload,
    }),
    [GET_MEMBER_POINT_ACCUMULATED]: (state, action) => ({
      ...state,
      pointAccumulated: action.payload,
    }),
    [GET_MEBMER_POINT_REWARDED]: (state, action) => ({
      ...state,
      pointRewarded: action.payload,
    }),
    [GET_MEMBER_POINT_CHARGED]: (state, action) => ({
      ...state,
      pointCharged: action.payload,
    }),
    [GET_MEMBER_POINT_PAID]: (state, action) => ({
      ...state,
      pointPaid: action.payload,
    }),
  },
  initState
)

// 회원 목록 조회
export const getUserListAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getUserList())
  } else {
    const result = await MemberApis.getUserList(param)
    if (result.code == 0) {
      if (result.data.users.length == 0) {
        // popupFn("조회된 데이터가 없습니다.")
      }
      dispatch(getUserList(result.data))
      return true
    } else {
      return false
    }
  }
}

// 회원 정보 조회
export const getUserInfoAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getUserInfo())
  } else {
    const result = await MemberApis.getUserInfo(param)
    if (result.code == 0) {
      dispatch(getUserInfo(result.data))
      return true
    } else {
      return false
    }
  }
}

// 회원 정보 학습 요약 조회
export const getUserLearningInfoAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getUserLearningInfo())
  } else {
    const result = await MemberApis.getUserLearningInfo(param)
    if (result.code == 0) {
      dispatch(getUserLearningInfo(result.data))
      return true
    } else {
      return false
    }
  }
}

// 회원 만든 스터디 목록 조회
export const getUserCreatedStudyListAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getUserCreatedStudyList())
  } else {
    const result = await MemberApis.getUserCreatedStudyList(param)
    if (result.code == 0) {
      if (result.data.studiesMake.length == 0) {
        // popupFn("조회된 데이터가 없습니다.")
      }
      dispatch(getUserCreatedStudyList(result.data))
      return true
    } else {
      return false
    }
  }
}

// 회원 참여 스터디 목록 조회
export const getUserParticipatedStudyListAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getUserParticipatedStudyList())
  } else {
    const result = await MemberApis.getUserParticipatedStudyList(param)
    if (result.code == 0) {
      if (result.data.studiesJoin.length == 0) {
        // popupFn("조회된 데이터가 없습니다.")
      }
      dispatch(getUserParticipatedStudyList(result.data))
      return true
    } else {
      return false
    }
  }
}

// 회원 TO-DO 목록 조회
export const getUserToDoListAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getUserToDoList())
  } else {
    const result = await MemberApis.getUserToDoList(param)
    if (result.code == 0) {
      if (result.data.todos.length == 0) {
        // popupFn("조회된 데이터가 없습니다.")
      }
      dispatch(getUserToDoList(result.data))
      return true
    } else {
      return false
    }
  }
}

// 회원 누적 공부시간 랭킹 조회
export const getUserStudyTimeRankingListAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getUserStudyTimeRankingList())
  } else {
    const result = await MemberApis.getUserStudyTimeRankingList(param)
    if (result.code == 0) {
      dispatch(getUserStudyTimeRankingList(result.data))
      return true
    } else {
      return false
    }
  }
}

// 회원 친구 목록 조회
export const getUsersFriendListAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getUsersFriendList())
  } else {
    const result = await MemberApis.getUsersFriendList(param)
    if (result.code == 0) {
      dispatch(getUsersFriendList(result.data))
      return true
    } else {
      return false
    }
  }
}

// 탈퇴 회원 목록 조회
export const getWithdrawalMemberListAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getWithdrawalMemberList())
  } else {
    const result = await MemberApis.getWithdrawalMemberList(param)
    if (result.code === 0) {
      if (result.data.withdrawUsers.length == 0) {
        // popupFn("조회된 데이터가 없습니다.")
      }
      dispatch(getWithdrawalMemberList(result.data))
      return true
    } else {
      return false
    }
  }
}

// 탈퇴 회원 삭제
export const delWithdrawalMemberAction = param => async dispatch => {
  const result = await MemberApis.delWithdrawalMember(param)
  if (result.code === 0) {
    return true
  } else {
    return false
  }
}

// 회원 포인트 현황 요약
export const getMemberPointInfoAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getMemberPointInfo())
  } else {
    const result = await MemberApis.getMemberPointInfo(param)
    if (result.code === 0) {
      dispatch(getMemberPointInfo(result.data))
      return true
    } else {
      return false
    }
  }
}

// 회원 포인트 현황 적립 목록
export const getMemberPointAccumulatedAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getMemberPointAccumulated())
  } else {
    const result = await MemberApis.getMemberPointAccumulated(param)
    if (result.code === 0) {
      dispatch(getMemberPointAccumulated(result.data))
      return true
    } else {
      return false
    }
  }
}

// 회원 포인트 현황 보상 목록
export const getMemberPointRewardedAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getMemberPointRewarded())
  } else {
    const result = await MemberApis.getMemberPointRewarded(param)
    if (result.code === 0) {
      dispatch(getMemberPointRewarded(result.data))
      return true
    } else {
      return false
    }
  }
}

// 회원 포인트 현황 충전 목록
export const getMemberPointChargedAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getMemberPointCharged())
  } else {
    const result = await MemberApis.getMemberPointCharged(param)
    if (result.code === 0) {
      dispatch(getMemberPointCharged(result.data))
      return true
    } else {
      return false
    }
  }
}

// 회원 결제 현황 목록
export const getMemberPointPaidAction = param => async dispatch => {
  if (param === undefined) {
    dispatch(getMemberPointPaid())
  } else {
    const result = await MemberApis.getMemberPointPaid(param)
    if (result.code === 0) {
      dispatch(getMemberPointPaid(result.data))
      return true
    } else {
      return false
    }
  }
}

// 회원 포인트 현황 적립 목록 Excel
export const getMemberPointAccumulatedExcelAction = param => async dispatch => {
  const result = await MemberApis.getMemberPointAccumulatedExcel(param)
  if (result) {
    return result
  } else {
    return false
  }
}

// 회원 포인트 현황 보상 목록 Excel
export const getMemberPointRewardedExcelAction = param => async dispatch => {
  const result = await MemberApis.getMemberPointRewardedExcel(param)
  if (result) {
    return result
  } else {
    return false
  }
}

// 회원 포인트 현황 충전 목록 Excel
export const getMemberPointChargedExcelAction = param => async dispatch => {
  const result = await MemberApis.getMemberPointChargedExcel(param)
  if (result) {
    return result
  } else {
    return false
  }
}
// 회원 결제 현황  목록 Excel
export const getMemberPointPaidExcelAction = param => async dispatch => {
  const result = await MemberApis.getMemberPointPaidExcel(param)
  if (result) {
    return result
  } else {
    return false
  }
}
// 회원 결제 현황  목록 Excel
export const getWithdrawalMemberListExcelAction = param => async dispatch => {
  const result = await MemberApis.getWithdrawalMemberListExcel(param)
  if (result) {
    return result
  } else {
    return false
  }
}
// 회원 결제 현황  목록 Excel
export const getUsersFriendListExcelAction = param => async dispatch => {
  const result = await MemberApis.getUsersFriendListExcel(param)
  if (result) {
    return result
  } else {
    return false
  }
}
// 회원   목록 Excel
export const getUserListExcelAction = param => async dispatch => {
  const result = await MemberApis.getUserListExcel(param)
  if (result) {
    return result
  } else {
    return false
  }
}
// 회원 스터디목록 Excel
export const getUserCreatedStudyListExcelAction = param => async dispatch => {
  const result = await MemberApis.getUserCreatedStudyListExcel(param)
  if (result) {
    return result
  } else {
    return false
  }
}
// 회원 투두목록 Excel
export const getUserCreatedTodoListExcelAction = param => async dispatch => {
  const result = await MemberApis.getUserCreatedTodoListExcel(param)
  if (result) {
    return result
  } else {
    return false
  }
}
// 회원 누적 공부시간 랭킹 엑셀 다운로드
export const getUserStudyTimeRankingListExcelAction = param => async dispatch => {
  const result = await MemberApis.getUserStudyTimeRankingListExcel(param)
  if (result) {
    return result
  } else {
    return false
  }
}
