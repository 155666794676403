import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import { getStatisticsStudentGroupStudyAction, getStatisticsSignInTimeAction, getStatisticsCustomerAction } from "../store/modules/adminMain"

// redux state > component mapping
const mapStateToProps = state => ({
  adminMainStudy: state.adminMain.adminMainStudy,
  adminMainSignInTime: state.adminMain.adminMainSignInTime,
  adminMainCustomer: state.adminMain.adminMainCustomer,
})

// redux action > component mapping
const mapDispatchToProps = dispatch => ({
  //학습자그룹 스터디 스케줄 개설 형황 get
  getStatisticsStudentGroupStudy: param => dispatch(getStatisticsStudentGroupStudyAction(param)),
  //시간대별 이용 현황 get
  getStatisticsSignInTime: param => dispatch(getStatisticsSignInTimeAction(param)),
  //회원 가입 현황 get
  getStatisticsCustomer: param => dispatch(getStatisticsCustomerAction(param)),
})

const WithHoc = ChildComponent => props => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
