import api from "../utils/api"

// 스터디 목록 조회
const getStudyList = async data => {
  const result = await api.get(
    `/study-schedule/camstudys?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&isFree=${data.isFree}&ownerType=${data.ownerType}&depth1=${data.depth1}&studyMemberCount=${data.studyMemberCount}&period=${data.period}&accumulatedTime=${data.accumulatedTime}&percent=${data.percent}&region3=${data.region3}&display=${data.display}&regDate=${data.regDate}&name=${data.name}&nickname=${data.nickname}&keyword=${data.keyword}&page=${data.page}&count=15`,
    {
      token: true,
      body: data,
    }
  )
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스터디 정보 조회
const getStudyInfo = async data => {
  const result = await api.get(`/study-schedule/camstudy?studySeq=${data.studySeq}`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스터디 정보 - 참여자 선택 입력 / 승인 요청 조회
const getStudyMember = async data => {
  const result = await api.get(`/study-schedule/camstudy/member/${data.status}?studySeq=${data.studySeq}`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스터디 목록 엑셀 다운로드
const getStudyListExcel = async data => {
  const result = await api.get(
    `/study-schedule/camstudys/excel/download?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&isFree=${data.isFree || ""}&ownerType=${
      data.ownerType
    }&depth1=${data.depth1}&studyMemberCount=${data.studyMemberCount || ""}&period=${data.period || ""}&accumulatedTime=${
      data.accumulatedTime || ""
    }&percent=${data.percent || ""}&region3=${data.region3 || ""}&display=${data.display}&regDate=${data.regDate || ""}&name=${data.name}&nickname=${
      data.nickname
    }&keyword=${data.keyword}&page=${data.page}&count=15`,
    {
      token: true,
      body: data,
      options: { responseType: "blob" },
    }
  )
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 참여자 추가 목록 조회
const getScheduleParticipantsList = async data => {
  const result = await api.get(
    `/people-add?userSeqs=${data.userSeqs}&partnerSeqs=${data.partnerSeqs}&adminSeqs=${data.adminSeqs}&wordType=${data.wordType}&word=${data.word}&tags=${data.tags}&studentGroupSeq=${data.studentGroupSeq}&affiliation=${data.affiliation}`,
    {
      token: true,
      body: data,
    }
  )
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스터디 등록
const postStudyScheduleCamStudy = async data => {
  const result = await api.post(`/study-schedule/camstudy`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스터디 수정
const putStudyScheduleCamStudy = async data => {
  const result = await api.put(`/study-schedule/camstudy`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스터디 스케줄 조회 - TO-DO 조회
const getToDoList = async data => {
  const result = await api.get(
    `/todos?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&ownerType=${data.ownerType}&depth1=${data.depth1}&display=${data.display}&regDate=${data.regDate}&name=${data.name}&nickname=${data.nickname}&keyword=${data.keyword}&page=${data.page}&count=15`,
    {
      token: true,
      body: data,
    }
  )
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스터디 스케줄 조회 - TO-DO 정보 조회
const getToDoInfo = async data => {
  const result = await api.get(`/todos/${data.seqType}/${data.seq}`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스터디 스케줄 조회 - TO-DO 등록
const postToDo = async data => {
  const result = await api.post(`/todos`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스터디 스케줄 조회 - TO-DO 수정
const putToDo = async data => {
  const result = await api.put(`/todos`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스터디 스케줄 조회 - TO-DO 수정 - 구독자 추가 ( 등록된 TO-DO에 적용 )
const postToDoSubscriber = async data => {
  const result = await api.post(`/todos/${data.seqType}/${data.seq}/subscribers`, {
    token: true,
    body: data.data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스터디 스케줄 조회 - TO-DO 수정 - 구독자 삭제 ( 등록된 TO-DO에 적용 )
const delToDoSubscriber = async data => {
  const result = await api.delete(`/todos/:${data.seqType}/${data.seq}/subscribers`, {
    token: true,
    body: data.data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스터디 스케줄 조회 - TO-DO 수정 - 과제 정보 조회 ( 등록된 TO-DO에 적용 )
const getToDoAssignment = async data => {
  const result = await api.get(`/todos/${data.seqType}/${data.seq}/tasks/${data.todoTaskSeq}`, {
    token: true,
    body: data.data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스터디 스케줄 조회 - TO-DO 수정 - 과제 수정 ( 등록된 TO-DO에 적용 )
const putToDoAssignment = async data => {
  const result = await api.put(`/todos/${data.seqType}/${data.seq}/tasks`, {
    token: true,
    body: data.data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스터디 스케줄 조회 - TO-DO 수정 - 과제 추가 ( 등록된 TO-DO에 적용 )
const postToDoAssignment = async data => {
  const result = await api.post(`/todos/${data.seqType}/${data.seq}/tasks`, {
    token: true,
    body: data.data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스터디 스케줄 조회 - TO-DO 수정 - 과제 삭제 ( 등록된 TO-DO에 적용 )
const delToDoAssignment = async data => {
  const result = await api.delete(`/todos/${data.seqType}/${data.seq}/tasks`, {
    token: true,
    body: data.data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스터디 스케줄 조회 - TO-DO 수정 - 키워드 추가 ( 등록된 TO-DO에 적용 )
const postToDoKeyword = async data => {
  const result = await api.post(`/todos/${data.seqType}/${data.seq}/keywords`, {
    token: true,
    body: data.data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스터디 스케줄 조회 - TO-DO 수정 - 키워드 삭제 ( 등록된 TO-DO에 적용 )
const delToDoKeyword = async data => {
  const result = await api.delete(`/todos/${data.seqType}/${data.seq}/keywords`, {
    token: true,
    body: data.data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스케줄 신청 - 캠스터디 목록 조회
const getScheduleCamstudyList = async data => {
  const result = await api.get(
    `/study-schedule/camstudy/subscriptions?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&userType=${data.userType}&status=${data.status}&studentGroupSeq=${data.studentGroupSeq}&wordType=${data.wordType}&word=${data.word}&page=${data.page}&count=15`,
    {
      token: true,
      body: data,
    }
  )
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스케줄 신청 - 캠스터디 정보
const getScheduleCamstudyInfo = async data => {
  const result = await api.get(`/study-schedule/camstudy/subscription?subscriptionSeq=${data.subscriptionSeq}`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스케줄 신청 - 캠스터디 삭제
const delScheduleCamstudy = async data => {
  const result = await api.delete(`/study-schedule/camstudy/subscriptions`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스케줄 신청 - 캠스터디 수정
const putScheduleCamstudy = async data => {
  const result = await api.put(`/study-schedule/camstudy/subscription`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스케줄 신청 목록 조회 - TO-DO
const getScheduleToDoList = async data => {
  const result = await api.get(
    `/todo-subscriptions?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&userType=${data.userType}&status=${data.status}&studentGroupSeq=${data.studentGroupSeq}&wordType=${data.wordType}&word=${data.word}&page=${data.page}&count=15`,
    {
      token: true,
      body: data,
    }
  )
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스케줄 신청 정보 조회 - TO-DO
const getScheduleToDoInfo = async data => {
  const result = await api.get(`/todo-subscriptions/${data.subscriptionSeq}`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스케줄 신청 수정 - TO-DO
const putScheduleToDo = async data => {
  const result = await api.put(`/todo-subscriptions`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스케줄 신청 삭제 - TO-DO
const delScheduleToDo = async data => {
  const result = await api.delete(`/todo-subscriptions`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 스케줄 신청 - 캠스터디 목록 조회
const getScheduleCamstudyListExcel = async data => {
  const result = await api.get(
    `/study-schedule/camstudy/subscriptions/excel/download?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&userType=${data.userType}&status=${data.status}&studentGroupSeq=${data.studentGroupSeq}&wordType=${data.wordType}&word=${data.word}&page=${data.page}&count=15`,
    {
      token: true,
      body: data,
      options: { responseType: "blob" },
    }
  )
  if (result) {
    return result.data
  } else {
    return false
  }
}
// 스케줄 신청 목록 조회 - TO-DO
const getScheduleToDoListExcel = async data => {
  const result = await api.get(
    `/todo-subscriptions/excel/download?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&userType=${data.userType}&status=${data.status}&studentGroupSeq=${data.studentGroupSeq}&wordType=${data.wordType}&word=${data.word}&page=${data.page}&count=15`,
    {
      token: true,
      body: data,
      options: { responseType: "blob" },
    }
  )
  if (result) {
    return result.data
  } else {
    return false
  }
}

export default {
  getStudyList,
  getStudyInfo,
  getStudyMember,
  getStudyListExcel,
  getScheduleParticipantsList,
  postStudyScheduleCamStudy,
  putStudyScheduleCamStudy,
  getScheduleCamstudyList,
  getScheduleCamstudyInfo,
  delScheduleCamstudy,
  putScheduleCamstudy,
  getToDoList,
  getToDoInfo,
  postToDo,
  putToDo,
  postToDoSubscriber,
  delToDoSubscriber,
  getToDoAssignment,
  putToDoAssignment,
  postToDoAssignment,
  delToDoAssignment,
  postToDoKeyword,
  delToDoKeyword,
  getScheduleToDoList,
  getScheduleToDoInfo,
  putScheduleToDo,
  delScheduleToDo,
  getScheduleCamstudyListExcel,
  getScheduleToDoListExcel,
}
