/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect } from "react"

import "swiper/scss"

import SEO from "../../components/seo"
import Validator from "validator"

import Layout from "../../components/Layout"
import TopInfo from "../../components/common/TopInfo"
import PrivateRoute from "../../components/privateRoute"

import SignContainer from "../../containers/SignContainer"

import ModalPopup from "../../popups/ModalPopup"
import ZipCodePopup from "../../popups/ZipCodePopup"
import TermsPopup from "../../popups/TermsPopup"
import TermsNotiPopup from "../../popups/TermsNotiPopup"
import ProfileEmailChangePopup from "../../popups/ProfileEmailChangePopup"
import MyAccountPhoneChangePopup from "../../popups/MyAccountPhoneChangePopup"
import ConsultantProfilePopup from "../../popups/ConsultantProfilePopup"

import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"
import img_frame from "../../images/img_frame.svg"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import { navigate } from "gatsby-link"
import { dataCheckFn, localStorgeGet, popupFn } from "../../utils/util"
import moment from "moment"

import { setIsLogin } from "../../store/modules/sign"
import { useDispatch } from "react-redux"
const FONT = ["TAHOMA", "NOTO_SANS", "PRETENDARD"]
const TYPE = ["CRAM_SCHOOL", "STUDY_CAFE", "CONSULTANT"]
const MANAGER_TYPE = { 원장: "PRINCIPAL_TEACHER", 강사: "TEACHER", 직원: "EMPLOYEE" }

const IMGTYPE = { BOTTOM: "bottom", TOP: "up", SEPARATION: "both", ONLY_IMAGE: "full" }
const IMGTYPE_ARR = ["bottom", "up", "both", "full"]
const Profile = props => {
  const {
    partnerProfileIntrocution,
    getProfliesPartnerIntroduction,
    putProfliesPartner,
    partnerProfile,
    getProfliesPartner,
    term,
    getTerm,
    postUploadFile,
    setUserInfoEmpty,
    setPartnerProfileEmpty,
    putProfliesPartnerIntroduction,
    getProfliesPartnerIntroductionTodoStudy,
    partnerProfileIntrocutionTodo,
    partnerProfileIntrocutionStudy,
    putProfliesPartnerIntroductionTodoStudy,
    getProfilesPartnerTerms,
    profileTerms,
    getProfilesPartnerAuth,
    putProfliesPartnerAuth,
    profileAuth,
    deleteProfilesPartnerWithdraw,
  } = props
  const dispatch = useDispatch()

  const [step, setStep] = useState(0)
  const [introduceStep, setIntroduceStep] = useState(0)
  //기본 정보
  const [phoneNo, setPhoneNo] = useState("")
  const [phoneAlerts, setPhoneAlerts] = useState(false)
  const [affiliatesType, setSffiliatesType] = useState(0)
  const [affiliatesName, setSffiliatesName] = useState("")
  const [businessNumber, setBusinessNumber] = useState("")
  const [manager, setManager] = useState("")
  const [managerType, setManagerType] = useState("PRINCIPAL_TEACHER")
  const [email, setEmail] = useState("")
  const [searchText, setSearchText] = useState("")

  //프로필 이미지
  const [imageInfo, setImageInfo] = useState({ base64: null, file: null, imageUrl: null, name: null })
  const [address, setAddress] = useState({
    addressNew: "",
    addressOld: "",
    detail: "",
    zipcode: "",
    nickname: "",
    region1: "",
    region2: "",
    region3: "",
    roadAddress: "",
  })
  //popup
  const [openZipPopup, setOpenZipPopup] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openSecessionModal, setOpenSecessionModal] = useState(false)
  const [openModalText, setOpenModalText] = useState("")
  const [openEmail, setOpenEmail] = useState(false)
  const [openPhone, setOpenPhone] = useState(false)
  const [openTerm, setOpenTerm] = useState({ open: false, idx: null })
  const [openTerm2, setOpenTerm2] = useState({ open: false, data: null })
  const [openPreview, setOpenPreview] = useState(false)

  //소개페이지
  //템플릿 타입
  const [templateType, setTemplateType] = useState(3)
  //제목 한줄 요약
  const [isTitle, setIsTitle] = useState(true)
  const [isSubTitle, setIsSubTitle] = useState(true)
  const [isTitleFont, setIsTitleFont] = useState(0)
  const [isSubTitleFont, setSubIsTitleFont] = useState(0)
  const [title, setTitle] = useState("")
  const [subTitle, setSubTitle] = useState("")
  const [introduction, setIntroduction] = useState("")
  //이미지
  const [imageInfoPc, setImageInfoPc] = useState({ base64: null, file: null, imageUrl: null, name: null })
  const [imageInfoPcThum, setImageInfoPcThum] = useState({ base64: null, file: null, imageUrl: null, name: null })
  const [imageInfoMo, setImageInfoMo] = useState({ base64: null, file: null, imageUrl: null, name: null })
  const [imageInfoMoThum, setImageInfoMoThum] = useState({ base64: null, file: null, imageUrl: null, name: null })
  //상담 active
  const [isActiveCounseling, setIsActiveCounseling] = useState(true)
  //스터디 투두 체크리스트
  const [introductionCheckList, setIntroductionCheckList] = useState([])
  //권한
  const [authorityListAll, setAuthorityListAll] = useState(false)
  const [member, setMember] = useState(0)
  const [todoSchedule, setTodoSchedule] = useState(0)
  const [studySchedule, setStudySchedule] = useState(0)
  const [serviceCon, setServiceCon] = useState(0)
  const [serviceKoo, setServiceKoo] = useState(0)
  const [message, setMessage] = useState(0)
  //getDate
  const getData = async () => {
    await getProfliesPartner()

    await getProfliesPartnerIntroduction()
    await getProfliesPartnerIntroductionTodoStudy()

    await getProfilesPartnerAuth()

    await getProfilesPartnerTerms()

    await getTerm()
  }
  //base64
  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })

  //주소찾기
  const onCompleteFindAddress = data => {
    console.log("data", data)
    setAddress({
      addressNew: data.roadAddress,
      addressOld: data.jibunAddress != "" ? data.jibunAddress : data.roadAddress,

      detail: "",
      zipcode: data.zonecode,
      nickname: "",
      region1: data.sido,
      region2: data.sigungu,
      region3: data.bname,
    })
    setOpenZipPopup(false)
  }
  //권한 수정 fn
  const authorityFn = async () => {
    let temp = []
    //회원관리
    if (member > 0) {
      for (let i of profileAuth[0].menu2) {
        if (member == 1) {
          if (i.menu2Name == "회원 조회") {
            temp = [...temp, i.menu2Seq]
          }
        } else {
          temp = [...temp, i.menu2Seq]
        }
      }
    }
    //스터디스케줄 todo
    if (todoSchedule > 0) {
      for (let i of profileAuth[1].menu2) {
        if (i.menu2Name == "스케줄 신청목록 조회") {
          temp = [...temp, i.menu2Seq]
        }
        if (i.menu2Name == "스케줄 신청목록 승인/반려") {
          temp = [...temp, i.menu2Seq]
        }
      }
    }
    //스터디스케줄
    if (studySchedule > 0) {
      for (let i of profileAuth[1].menu2) {
        if (studySchedule == 1) {
          if (i.menu2Name == "스터디스케줄 조회" || i.menu2Name == "스터디스케줄 조회 ") {
            temp = [...temp, i.menu2Seq]
          }
        } else {
          if (i.menu2Name == "스터디스케줄 조회" || i.menu2Name == "스터디스케줄 조회 ") {
            temp = [...temp, i.menu2Seq]
          } else if (i.menu2Name == "스터디스케줄 등록/변경") {
            temp = [...temp, i.menu2Seq]
          }
        }
      }
    }
    //서비스 상품 관리
    if (serviceCon > 0 || serviceKoo > 0) {
      for (let i of profileAuth[2].menu2) {
        if (serviceCon >= 1 || serviceKoo >= 1) {
          if (i.menu2Name == "서비스상품 조회") {
            temp = [...temp, i.menu2Seq]
          }
        }
        if (serviceCon >= 3 && i.menu2Name == "컨텐츠 등록/변경") {
          temp = [...temp, i.menu2Seq]
        }
        if (serviceKoo >= 3 && i.menu2Name == "꾸미기아이템 등록/변경") {
          temp = [...temp, i.menu2Seq]
        }
      }
    }
    if (message > 0) {
      for (let i of profileAuth[3].menu2) {
        temp = [...temp, i.menu2Seq]
      }
    } else {
      for (let i of profileAuth[3].menu2) {
        if (i.menu2Name != "메시지 조회" && i.menu2Name != "메시지 등록/변경") {
          temp = [...temp, i.menu2Seq]
        }
      }
    }
    console.log(temp)

    let partnerMenuRequests = {
      processType: "REQUEST",
      processedMenus: temp,
    }
    let result = await putProfliesPartnerAuth(partnerMenuRequests)
    if (result) {
      setOpenModalText("권한 신청 완료")
      setOpenModal(true)
      await getProfilesPartnerAuth()
    }
  }
  //기본정보 put
  const putProfliesPartnerFn = async () => {
    if (Validator.isEmpty(affiliatesName, { ignore_whitespace: true })) {
      setOpenModalText("상호명을 입력해주세요.")
      setOpenModal(true)
      return false
    }
    if (Validator.isEmpty(businessNumber, { ignore_whitespace: true })) {
      setOpenModalText("사업자번호 혹은 주민번호를 입력해주세요")
      setOpenModal(true)
      return false
    }
    if (Validator.isEmpty(address.addressOld, { ignore_whitespace: true })) {
      setOpenModalText("주소찾기로 주소를 입력해주세요")
      setOpenModal(true)
      return false
    }
    if (Validator.isEmpty(address.detail, { ignore_whitespace: true })) {
      setOpenModalText("상세주소를 입력해주세요")
      setOpenModal(true)
      return false
    }
    // if (Validator.isEmpty(address.nickname, { ignore_whitespace: true })) {
    //   setOpenModalText("저장하실 주소의 닉네임을 입력해주세요")
    //   setOpenModal(true)
    //   return false
    // }
    //프로필
    let data = null
    if (imageInfo.imageUrl == null && imageInfo.file != null) {
      const fomrData = new FormData()
      await fomrData.append("file", imageInfo.file)
      data = await postUploadFile(fomrData)
    }
    let temp = {
      partnerSeq: localStorgeGet("loginUserType") == "PARTNER" ? localStorgeGet("loginUserType") : "",
      adminSeq: localStorgeGet("loginUserType") == "ADMIN" ? localStorgeGet("loginUserType") : "",
      type: TYPE[affiliatesType],
      brandName: affiliatesName,
      addressNew: address.addressNew,
      addressOld: address.addressOld,
      addressDetail: address.detail,
      region1: address.region1,
      region2: address.region2,
      region3: address.region3,
      businessNumber: businessNumber,
      profileImage: data ? data : imageInfo.imageUrl,
      managerType: MANAGER_TYPE[managerType],
      managerName: manager,
      phone: phoneNo,
      email: email,
    }
    console.log(temp)
    let result = await putProfliesPartner(temp)
    if (result) {
      if (phoneNo != localStorgeGet("letMeUpAdminPhoneNum")) {
        localStorage.removeItem(process.env.TOKEN_ID)
        localStorage.removeItem("loginUserSeq")
        localStorage.removeItem("loginUserType")
        localStorage.removeItem("letMeUpAdminPhoneNum")
        localStorage.removeItem("applicationType")
        dispatch(setIsLogin(false))
        setPartnerProfileEmpty()
        setUserInfoEmpty()
        navigate("/Affiliates")
      } else {
        localStorage.removeItem("letMeUpAdminPhoneNum")
        await getProfliesPartner()
        popupFn("저장되었습니다.")
        navigate("/AffiliatesMain")
      }
    }
  }
  //소개페이지 put
  const putProfliesPartnerIntroductionFn = async () => {
    let imgPc = null
    let imgMo = null
    let imgPcThum = null
    let imgMoThum = null
    if (imageInfoPc.imageUrl == null && imageInfoPc.file != null) {
      const fomrData = new FormData()
      await fomrData.append("file", imageInfoPc.file)
      imgPc = await postUploadFile(fomrData)
    }
    if (imageInfoMo.imageUrl == null && imageInfoMo.file != null) {
      const fomrData = new FormData()
      await fomrData.append("file", imageInfoMo.file)
      imgMo = await postUploadFile(fomrData)
    }
    if (imageInfoPcThum.imageUrl == null && imageInfoPcThum.file != null) {
      const fomrData = new FormData()
      await fomrData.append("file", imageInfoPcThum.file)
      imgPcThum = await postUploadFile(fomrData)
    }
    if (imageInfoMoThum.imageUrl == null && imageInfoMoThum.file != null) {
      const fomrData = new FormData()
      await fomrData.append("file", imageInfoMoThum.file)
      imgMoThum = await postUploadFile(fomrData)
    }

    let temp = {
      partnerPageSettingSeq: partnerProfileIntrocution.partnerPageSettingSeq,
      mainTemplateType: templateType == 0 ? "BOTTOM" : templateType == 1 ? "TOP" : templateType == 2 ? "SEPARATION" : "ONLY_IMAGE",
      isDisplayTitle: isTitle ? "Y" : "N",
      title: title,
      titleFontType: FONT[isTitleFont],
      isDisplaySummary: isSubTitle ? "Y" : "N",
      summary: subTitle,
      summaryFontType: FONT[isSubTitleFont],
      imageUrlPc: imgPc ? imgPc : imageInfoPc.imageUrl,
      imageUrlMobile: imgMo ? imgMo : imageInfoMo.imageUrl,
      thumbnailUrlLarge: imgPcThum ? imgPcThum : imageInfoPcThum.imageUrl,
      thumbnailUrlMedium: imgMoThum ? imgMoThum : imageInfoMoThum.imageUrl,
      isActiveCounseling: isActiveCounseling ? "Y" : "N",
      introduction: introduction,
    }
    console.log("소개", temp)
    let result = await putProfliesPartnerIntroduction(temp)
    if (result) {
      popupFn("저장되었습니다.")
      getData()
    }
  }
  //소개페이지 todo study 전시 비전시
  const putProfliesPartnerIntroductionTodoStudyFn = async yN => {
    let temp = {
      targetType: introduceStep == 0 ? "STUDY" : "TODO",
      isDisplay: yN,
      studySeqs: introduceStep == 0 ? introductionCheckList : [],
      todoPackageSeqs: introduceStep == 1 ? introductionCheckList : [],
    }

    let result = await putProfliesPartnerIntroductionTodoStudy(temp)
    if (result) {
      popupFn("저장되었습니다.")
      await getProfliesPartnerIntroductionTodoStudy()
      setIntroductionCheckList([])
    }
  }
  const deleteProfilesPartnerWithdrawFn = async () => {
    let result = await deleteProfilesPartnerWithdraw()
    if (result) {
      localStorage.removeItem(process.env.TOKEN_ID)
      localStorage.removeItem("loginUserSeq")
      localStorage.removeItem("loginUserType")
      localStorage.removeItem("letMeUpAdminPhoneNum")
      localStorage.removeItem("applicationType")
      dispatch(setIsLogin(false))
      setPartnerProfileEmpty()
      setUserInfoEmpty()
      navigate("/Affiliates")
    }
  }
  useEffect(() => {
    getData()
  }, [])
  useEffect(() => {
    if (dataCheckFn(partnerProfile)) {
      setSffiliatesType(partnerProfile.type == "CRAM_SCHOOL" ? 0 : partnerProfile.type == "STUDY_CAFE" ? 1 : 2)
      setSffiliatesName(partnerProfile.brandName)
      setAddress({
        ...address,
        addressOld: partnerProfile.addressOld,
        addressNew: partnerProfile.addressNew,
        detail: partnerProfile.addressDetail,
        region1: partnerProfile.region1,
        region2: partnerProfile.region2,
        region3: partnerProfile.region3,
      })
      setBusinessNumber(partnerProfile.businessNumber)
      setManager(partnerProfile.managerName)
      setManagerType(partnerProfile.managerType == "PRINCIPAL_TEACHER" ? "원장" : partnerProfile.managerType == "TEACHER" ? "강사" : "직원")
      setImageInfo({ ...imageInfo, imageUrl: partnerProfile.profileImage })
      // setImageInfo()
      setPhoneNo(partnerProfile.phone)
      setEmail(partnerProfile.email)
    }
  }, [partnerProfile])
  useEffect(() => {
    if (dataCheckFn(partnerProfileIntrocution)) {
      setTemplateType(
        IMGTYPE_ARR.indexOf(IMGTYPE[partnerProfileIntrocution.mainTemplateType]) == -1
          ? 0
          : IMGTYPE_ARR.indexOf(IMGTYPE[partnerProfileIntrocution.mainTemplateType])
      )
      setIsTitle(partnerProfileIntrocution.isDisplayTitle != "" || partnerProfileIntrocution.isDisplayTitle != "Y" ? true : false)
      setIsSubTitle(partnerProfileIntrocution.isDisplaySummary != "" || partnerProfileIntrocution.isDisplaySummary != "Y" ? true : false)
      setIsTitleFont(FONT.indexOf(partnerProfileIntrocution.titleFontType))
      setSubIsTitleFont(FONT.indexOf(partnerProfileIntrocution.summaryFontType))
      setTitle(partnerProfileIntrocution.title)
      setSubTitle(partnerProfileIntrocution.summary)
      setIntroduction(partnerProfileIntrocution.introduction)
      setImageInfoPc({ ...imageInfoPc, imageUrl: partnerProfileIntrocution.imageUrlPc })
      setImageInfoPcThum({ ...imageInfoPcThum, imageUrl: partnerProfileIntrocution.thumbnailUrlLarge })
      setImageInfoMo({ ...imageInfoMo, imageUrl: partnerProfileIntrocution.imageUrlMobile })
      setImageInfoMoThum({ ...imageInfoMoThum, imageUrl: partnerProfileIntrocution.thumbnailUrlMedium })
    }
  }, [partnerProfileIntrocution])
  //소개페이지 투두 스터디 탭변경시 체크 초기화
  useEffect(() => {
    setIntroductionCheckList([])
  }, [introduceStep])
  //초기 권한 불러와 설정
  useEffect(() => {
    if (profileAuth.length > 0) {
      for (let i = 0; i < profileAuth.length; i++) {
        for (let j = 0; j < profileAuth[i].menu2.length; j++) {
          if (profileAuth[i].menu1Name == "회원관리") {
            if (profileAuth[i].menu2[j].menu2Name == "회원조회" && profileAuth[i].menu2[j].status == "APPROVAL") {
              setMember(1)
            }
            if (profileAuth[i].menu2[j].menu2Name == "회원 등록/변경" && profileAuth[i].menu2[j].status == "APPROVAL") {
              setMember(3)
            }
          }
          if (profileAuth[i].menu1Name == "스터디스케줄관리") {
            if (profileAuth[i].menu2[j].menu2Name == "스케줄 신청목록 승인/반려" && profileAuth[i].menu2[j].status == "APPROVAL") {
              setTodoSchedule(3)
            }
            if (profileAuth[i].menu2[j].menu2Name == "스터디스케줄 조회" && profileAuth[i].menu2[j].status == "APPROVAL") {
              setStudySchedule(1)
            }
            if (profileAuth[i].menu2[j].menu2Name == "스터디스케줄 등록/변경" && profileAuth[i].menu2[j].status == "APPROVAL") {
              setStudySchedule(3)
            }
          }
          if (profileAuth[i].menu1Name == "서비스상품관리") {
            if (profileAuth[i].menu2[j].menu2Name == "서비스상품 조회" && profileAuth[i].menu2[j].status == "APPROVAL") {
              setServiceCon(1)
              setServiceKoo(1)
            }
            if (profileAuth[i].menu2[j].menu2Name == "컨텐츠 등록/변경" && profileAuth[i].menu2[j].status == "APPROVAL") {
              setServiceCon(3)
            }
            if (profileAuth[i].menu2[j].menu2Name == "꾸미기아이템 등록/변경" && profileAuth[i].menu2[j].status == "APPROVAL") {
              setServiceKoo(3)
            }
          }
          if (profileAuth[i].menu1Name == "내계정관리") {
            if (profileAuth[i].menu2[j].menu2Name == "메시지 조회" && profileAuth[i].menu2[j].status == "APPROVAL") {
              setMessage(1)
            }
            if (profileAuth[i].menu2[j].menu2Name == "메시지 등록/변경" && profileAuth[i].menu2[j].status == "APPROVAL") {
              setMessage(3)
            }
          }
        }
      }
    }
  }, [profileAuth])
  return (
    <PrivateRoute>
      <Layout>
        <SEO title="프로필" />
        <ModalPopup
          open={openModal}
          contents={openModalText}
          onClose={() => {
            setOpenModal(false)
            setOpenModalText("")
          }}
        />
        <ModalPopup
          open={openSecessionModal}
          contents={"렛미업 제휴사 탈퇴 처리 되면 정상적인\n서비스 이용이 불가합니다.\n계속 진행하시겠습니까?"}
          onClose={() => {
            setOpenSecessionModal(false)
          }}
          onClickBtn={() => {
            deleteProfilesPartnerWithdrawFn()
            setOpenSecessionModal(false)
            console.log("탈!")
          }}
          btn={["취소", "확인"]}
        />
        <ConsultantProfilePopup
          data={{
            templateType: templateType,
            title: isTitle ? title : "",
            subTitle: isSubTitle ? subTitle : "",
            isTitleFont: isTitleFont,
            isSubTitleFont: isSubTitleFont,
            imageInfoPc: imageInfoPc,
            introduction: introduction,
          }}
          open={openPreview}
          onClose={() => setOpenPreview(false)}
        />
        <TermsPopup open={openTerm.open} idx={openTerm.idx} onClose={() => setOpenTerm({ open: false, idx: null })} />
        <TermsNotiPopup
          getProfilesPartnerTerms={getProfilesPartnerTerms}
          open={openTerm2.open}
          data={openTerm2.data}
          onClose={() => setOpenTerm2({ open: false, data: null })}
        />
        <ProfileEmailChangePopup setEmail={setEmail} open={openEmail} onClose={setOpenEmail} />
        <MyAccountPhoneChangePopup setPhone={setPhoneNo} open={openPhone} onClose={setOpenPhone} />
        <ZipCodePopup open={openZipPopup} onClose={() => setOpenZipPopup(false)} onComplete={onCompleteFindAddress} defaultQuery={searchText} />
        <TopInfo title={["프로필", "내 계정 관리", "프로필"]} />
        <div className="partner-profile-container">
          <div className="partner-profile-menu-tab">
            <div onClick={() => setStep(0)} className={step == 0 ? "active" : ""}>
              기본정보
            </div>
            <div onClick={() => setStep(1)} className={step == 1 ? "active" : ""}>
              소개 페이지
            </div>
            <div onClick={() => setStep(2)} className={step == 2 ? "active" : ""}>
              메뉴 권한
            </div>
            <div onClick={() => setStep(3)} className={step == 3 ? "active" : ""}>
              이용 약관
            </div>
          </div>
          {step == 0 && (
            <>
              <div className="partner-profile-basic-info mt-4">
                <div className="partner-profile-basic-info-box-row div-1">
                  <div className="partner-profile-basic-info-title-p ">소속기관 유형</div>
                  <div className="partner-profile-basic-info-div">
                    <div className="partner-profile-basic-info-div-2">
                      <select
                        className="form-select"
                        value={affiliatesType}
                        onChange={e => {
                          setSffiliatesType(e.target.value)
                        }}
                      >
                        <option className="" value="0">
                          학원
                        </option>
                        <option className="" value="1">
                          스터디 카페
                        </option>
                        <option className="" value="2">
                          컨설턴트
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="partner-profile-basic-info-box-row div-1">
                  <div className="partner-profile-basic-info-title-p ">소속기관 이름</div>
                  <div className="partner-profile-basic-info-div">
                    <div className="partner-profile-basic-info-div-2">
                      <div className="input-box">
                        <input value={affiliatesName || ""} onChange={e => setAffiliatesName(e.target.value)} type="text" className="fgb-input" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="partner-profile-basic-info-box-row div-2">
                  <div className="partner-profile-basic-info-title-p ">주소</div>
                  <div className="partner-profile-basic-info-div">
                    <div className="partner-profile-basic-info-div-2">
                      <div className="input-box">
                        <input value={address.addressOld || ""} disabled readOnly type="text" className="fgb-input" />
                      </div>
                      <button onClick={() => setOpenZipPopup(true)} className="btn btn-primary font-white fgb-btn-m margin-btn">
                        주소 찾기
                      </button>
                    </div>
                    <div className="partner-profile-basic-info-div-2">
                      <div className="input-box">
                        <input
                          value={address.detail || ""}
                          onChange={e => setAddress({ ...address, detail: e.target.value })}
                          type="text"
                          className="fgb-input"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="partner-profile-basic-info-box-row div-1">
                  <div className="partner-profile-basic-info-title-p ">사업자번호</div>
                  <div className="partner-profile-basic-info-div">
                    <div className="partner-profile-basic-info-div-2">
                      <div className="input-box">
                        <input
                          placeholder="- 제외 입력"
                          value={businessNumber || ""}
                          onChange={e => setBusinessNumber(e.target.value)}
                          type="number"
                          className="fgb-input"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="partner-profile-basic-info-box-row div-1">
                  <div className="partner-profile-basic-info-title-p ">담당자</div>
                  <div className="partner-profile-basic-info-div">
                    <div className="partner-profile-basic-info-div-2">
                      <select
                        style={{ width: "120px" }}
                        className="form-select"
                        value={managerType}
                        onChange={e => {
                          setManagerType(e.target.value)
                        }}
                      >
                        <option className="" value="원장">
                          원장
                        </option>
                        <option className="" value="강사">
                          강사
                        </option>
                        <option className="" value="직원">
                          직원
                        </option>
                      </select>
                      <div className="input-box ms-2">
                        <input value={manager || ""} onChange={e => setManager(e.target.value)} type="text" className="fgb-input" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="partner-profile-basic-info-box-row div-1">
                  <div className="partner-profile-basic-info-title-p ">사진</div>
                  <div className="partner-profile-basic-info-div">
                    <div className="partner-profile-basic-info-div-2">
                      <div className="input-box">
                        <input value={imageInfo.name || imageInfo.imageUrl || ""} disabled readOnly type="text" className="fgb-input" />
                      </div>
                      <button
                        onClick={() => setImageInfo({ base64: null, file: null, imageUrl: null, name: null })}
                        className="btn fgb-btn-gray fgb-btn-s margin-btn"
                      >
                        삭제
                      </button>
                      <button
                        onClick={() => document.getElementById("gallery_image_input_me").click()}
                        className="btn fgb-btn-m fgb-btn-purple margin-btn"
                      >
                        이미지 업로드
                      </button>
                      <input
                        id={"gallery_image_input_me"}
                        style={{ display: "none" }}
                        type={"file"}
                        onChange={async e => {
                          const file = e.target.files[0]
                          if (file !== undefined) {
                            const base64Image = await toBase64(file)

                            let tempList = {}
                            tempList.base64 = base64Image
                            tempList.file = file
                            tempList.name = file.name
                            tempList.imageUrl = null

                            await setImageInfo(tempList)
                            e.target.value = ""
                          }
                        }}
                        accept=".gif, .jpg, .png"
                        // accept={"image/*"}
                      />
                    </div>
                  </div>
                </div>
                <div className="partner-profile-basic-info-box-row div-1">
                  <div className="partner-profile-basic-info-title-p ">휴대전화 번호</div>
                  <div className="partner-profile-basic-info-div">
                    <div className="partner-profile-basic-info-div-2">
                      <div className="input-box">
                        <input disabled readOnly value={phoneNo || ""} type="text" className="fgb-input" />
                      </div>
                      <button onClick={() => setOpenPhone(true)} className="btn btn-primary font-white fgb-btn-m margin-btn">
                        휴대전화 번호 변경
                      </button>
                    </div>
                  </div>
                </div>
                <div className="partner-profile-basic-info-box-row div-1">
                  <div className="partner-profile-basic-info-title-p ">이메일</div>
                  <div className="partner-profile-basic-info-div">
                    <div className="partner-profile-basic-info-div-2">
                      <div className="input-box">
                        <input disabled readOnly value={email || ""} type="text" className="fgb-input" />
                      </div>
                      <button onClick={() => setOpenEmail(true)} className="btn btn-primary font-white fgb-btn-m margin-btn">
                        이메일 변경
                      </button>
                    </div>
                  </div>
                </div>
                <div className="partner-profile-basic-info-box-row div-1">
                  <div className="partner-profile-basic-info-title-p ">가입일시</div>
                  <div className="partner-profile-basic-info-div">
                    <div className="partner-profile-basic-info-div-2">
                      <div className="input-box">
                        <input value={partnerProfile.regDatetime || ""} disabled type="text" className="fgb-input" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-4">
                <button className="btn btn-primary fgb-btn-gray fgb-btn-l">취소</button>
                <button onClick={putProfliesPartnerFn} className="btn btn-primary font-white fgb-btn-l ms-3">
                  저장
                </button>
              </div>
            </>
          )}
          {step == 1 && (
            <div className="partner-profile-introduce mt-4">
              <div className="introduce-template">
                <div className="introduce-template-name-div">
                  <p>
                    학원(강사)소개 페이지
                    <br /> 상단 메인 이미지
                    <br />
                    템플릿
                  </p>
                </div>
                <div className="introduce-template-div ms-4">
                  <div className="introduce-template-1">
                    <div className="introduce-template-title"></div>
                    <div className="introduce-template-sub mt-1"></div>
                  </div>
                  <div className="introduce-template-div-info mt-2">
                    <img onClick={() => setTemplateType(0)} className="me-2" src={templateType == 0 ? radio_on : radio_off} />
                    <p>제목 + 한줄요약 하단 배치</p>
                  </div>
                </div>
                <div className="introduce-template-div ms-4">
                  <div className="introduce-template-2">
                    <div className="introduce-template-title"></div>
                    <div className="introduce-template-sub mt-1"></div>
                  </div>
                  <div className="introduce-template-div-info mt-2">
                    <img onClick={() => setTemplateType(1)} className="me-2" src={templateType == 1 ? radio_on : radio_off} />
                    <p>제목 + 한줄요약 상단 배치</p>
                  </div>
                </div>
                <div className="introduce-template-div ms-4">
                  <div className="introduce-template-3">
                    <div className="introduce-template-title"></div>
                    <div className="introduce-template-sub mt-1"></div>
                  </div>
                  <div className="introduce-template-div-info mt-2">
                    <img onClick={() => setTemplateType(2)} className="me-2" src={templateType == 2 ? radio_on : radio_off} />
                    <p>제목, 한줄요약 분리 배치</p>
                  </div>
                </div>
                <div className="introduce-template-div ms-5">
                  <div className="introduce-template-1"></div>
                  <div className="introduce-template-div-info mt-2">
                    <img onClick={() => setTemplateType(3)} className="me-2" src={templateType == 3 ? radio_on : radio_off} />
                    <p>Full BG 이미지</p>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <p className="partner-sub-p"> 소개 페이지 제목 및 한줄요약 정보</p>
                <button onClick={async () => await await getProfliesPartnerIntroduction()} className="btn btn-primary fgb-btn-gray fgb-btn-s">
                  초기화
                </button>
              </div>
              <div className="introduce-title-info-div mt-3">
                <div className="introduce-title-info-table-name">
                  <div>제목 표시</div>
                  <div>제목 폰트</div>
                  <div>한줄 요약 표시</div>
                  <div>한줄 요약 폰트</div>
                </div>
                <div className="introduce-title-info-table-radio">
                  <div className="introduce-title-info-table-radio-row ">
                    <img onClick={() => setIsTitle(!isTitle)} className="ms-4 me-2" src={isTitle == true ? radio_on : radio_off} />
                    <p>제목 표시</p>
                    <img onClick={() => setIsTitle(!isTitle)} className="ms-4 me-2" src={isTitle != true ? radio_on : radio_off} />
                    <p>제목 표시 하지 않음</p>
                  </div>
                  <div className="introduce-title-info-table-radio-row ">
                    <img onClick={() => setIsTitleFont(0)} className="ms-4 me-2" src={isTitleFont == 0 ? radio_on : radio_off} />
                    <p>Tahoma </p>
                    <img onClick={() => setIsTitleFont(1)} className="ms-4 me-2" src={isTitleFont == 1 ? radio_on : radio_off} />
                    <p>Noto Sans</p>
                    <img onClick={() => setIsTitleFont(2)} className="ms-4 me-2" src={isTitleFont == 2 ? radio_on : radio_off} />
                    <p>Pretendard</p>
                  </div>
                  <div className="introduce-title-info-table-radio-row ">
                    <img onClick={() => setIsSubTitle(!isSubTitle)} className="ms-4 me-2" src={isSubTitle == true ? radio_on : radio_off} />
                    <p>한 줄 요약 화면표시</p>
                    <img onClick={() => setIsSubTitle(!isSubTitle)} className="ms-4 me-2" src={isSubTitle != true ? radio_on : radio_off} />
                    <p>한 줄 요약 화면표시 하지 않음</p>
                  </div>
                  <div className="introduce-title-info-table-radio-row ">
                    <img onClick={() => setSubIsTitleFont(0)} className="ms-4 me-2" src={isSubTitleFont == 0 ? radio_on : radio_off} />
                    <p>Tahoma </p>
                    <img onClick={() => setSubIsTitleFont(1)} className="ms-4 me-2" src={isSubTitleFont == 1 ? radio_on : radio_off} />
                    <p>Noto Sans</p>
                    <img onClick={() => setSubIsTitleFont(2)} className="ms-4 me-2" src={isSubTitleFont == 2 ? radio_on : radio_off} />
                    <p>Pretendard</p>
                  </div>
                </div>
                <div className="introduce-title-info-table-name-2">
                  <div>제목</div>
                  <div>한줄 요약</div>
                </div>
                <div className="introduce-title-info-table-input">
                  <div>
                    <input
                      placeholder="제목 공백 포함 최대 30자 입력"
                      maxLength={300}
                      value={title || ""}
                      onChange={e => setTitle(e.target.value)}
                      type="text"
                      className="fgb-input"
                    />
                  </div>
                  <div>
                    <input
                      placeholder="제목 공백 포함 최대 80자 입력"
                      maxLength={80}
                      value={subTitle || ""}
                      onChange={e => setSubTitle(e.target.value)}
                      type="text"
                      className="fgb-input"
                    />
                  </div>
                </div>
              </div>
              <p className="partner-sub-p mt-3"> 소개페이지 이미지</p>
              <div className="introduce-img-div mt-3">
                <div className="introduce-img-div-name">
                  <div>PC 원본 사이즈</div>
                  <div>L) 썸네일 </div>
                </div>
                <div className="introduce-img-div-img-view-col">
                  <div className="introduce-img-div-img-view">
                    <img src={imageInfoPc.imageUrl || imageInfoPc.base64 || img_frame} />
                    <div className="introduce-img-div-img-view-right">
                      <p>764px * 319px</p>
                      <div className="d-flex align-items-center mt-2">
                        <button onClick={() => document.getElementById("gallery_image_input1").click()} className="btn fgb-btn-purple fgb-btn-s">
                          업로드
                        </button>
                        <button
                          onClick={() => setImageInfoPc({ base64: null, file: null, imageUrl: null, name: null })}
                          className="btn fgb-btn-gray fgb-btn-s ms-2"
                        >
                          삭제
                        </button>
                        <input
                          id={"gallery_image_input1"}
                          style={{ display: "none" }}
                          type={"file"}
                          onChange={async e => {
                            const file = e.target.files[0]
                            if (file !== undefined) {
                              const base64Image = await toBase64(file)

                              let tempList = {}
                              tempList.base64 = base64Image
                              tempList.file = file
                              tempList.name = file.name
                              tempList.imageUrl = null
                              await setImageInfoPc(tempList)
                              e.target.value = ""
                            }
                          }}
                          accept=".gif, .jpg, .png"
                          // accept={"image/*"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="introduce-img-div-img-view">
                    <img src={imageInfoPcThum.imageUrl || imageInfoPcThum.base64 || img_frame} />
                    <div className="introduce-img-div-img-view-right">
                      <p>764px * 319px</p>
                      <div className="d-flex align-items-center mt-2">
                        <button onClick={() => document.getElementById("gallery_image_input2").click()} className="btn fgb-btn-purple fgb-btn-s">
                          업로드
                        </button>
                        <button
                          onClick={() => setImageInfoPcThum({ base64: null, file: null, imageUrl: null, name: null })}
                          className="btn fgb-btn-gray fgb-btn-s ms-2"
                        >
                          삭제
                        </button>
                        <input
                          id={"gallery_image_input2"}
                          style={{ display: "none" }}
                          type={"file"}
                          onChange={async e => {
                            const file = e.target.files[0]
                            if (file !== undefined) {
                              const base64Image = await toBase64(file)

                              let tempList = {}
                              tempList.base64 = base64Image
                              tempList.file = file
                              tempList.name = file.name
                              tempList.imageUrl = null

                              await setImageInfoPcThum(tempList)
                              e.target.value = ""
                            }
                          }}
                          accept=".gif, .jpg, .png"
                          // accept={"image/*"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="introduce-img-div-name">
                  <div>MO 원본 사이즈</div>
                  <div>(M) 썸네일 </div>
                </div>
                <div className="introduce-img-div-img-view-col">
                  <div className="introduce-img-div-img-view">
                    <img src={imageInfoMo.imageUrl || imageInfoMo.base64 || img_frame} />
                    <div className="introduce-img-div-img-view-right">
                      <p>414px * 172px</p>
                      <div className="d-flex align-items-center mt-2">
                        <button onClick={() => document.getElementById("gallery_image_input3").click()} className="btn fgb-btn-purple fgb-btn-s">
                          업로드
                        </button>
                        <button
                          onClick={() => setImageInfoMo({ base64: null, file: null, imageUrl: null, name: null })}
                          className="btn fgb-btn-gray fgb-btn-s ms-2"
                        >
                          삭제
                        </button>
                        <input
                          id={"gallery_image_input3"}
                          style={{ display: "none" }}
                          type={"file"}
                          onChange={async e => {
                            const file = e.target.files[0]
                            if (file !== undefined) {
                              const base64Image = await toBase64(file)

                              let tempList = {}
                              tempList.base64 = base64Image
                              tempList.file = file
                              tempList.name = file.name
                              tempList.imageUrl = null

                              await setImageInfoMo(tempList)
                              e.target.value = ""
                            }
                          }}
                          accept=".gif, .jpg, .png"
                          // accept={"image/*"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="introduce-img-div-img-view">
                    <img src={imageInfoMoThum.imageUrl || imageInfoMoThum.base64 || img_frame} />
                    <div className="introduce-img-div-img-view-right">
                      <p> 414px * 172px</p>
                      <div className="d-flex align-items-center mt-2">
                        <button onClick={() => document.getElementById("gallery_image_input4").click()} className="btn fgb-btn-purple fgb-btn-s">
                          업로드
                        </button>
                        <button
                          onClick={() => setImageInfoMoThum({ base64: null, file: null, imageUrl: null, name: null })}
                          className="btn fgb-btn-gray fgb-btn-s ms-2"
                        >
                          삭제
                        </button>
                        <input
                          id={"gallery_image_input4"}
                          style={{ display: "none" }}
                          type={"file"}
                          onChange={async e => {
                            const file = e.target.files[0]
                            if (file !== undefined) {
                              const base64Image = await toBase64(file)

                              let tempList = {}
                              tempList.base64 = base64Image
                              tempList.file = file
                              tempList.name = file.name
                              tempList.imageUrl = null

                              await setImageInfoMoThum(tempList)
                              e.target.value = ""
                            }
                          }}
                          accept=".gif, .jpg, .png"
                          // accept={"image/*"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="partner-sub-p mt-3"> 상담 신청 버튼 활성화</p>

              <div style={{ height: "60px" }} className="introduce-text-area-div mt-3">
                <div className="introduce-text-area-div-name">상담 신청</div>
                <div className="introduce-text-area-div-right align-items-center">
                  <img
                    onClick={() => setIsActiveCounseling(!isActiveCounseling)}
                    className="me-2"
                    src={isActiveCounseling == true ? radio_on : radio_off}
                  />
                  <p>활성</p>
                  <img
                    onClick={() => setIsActiveCounseling(!isActiveCounseling)}
                    className="ms-4 me-2"
                    src={isActiveCounseling != true ? radio_on : radio_off}
                  />
                  <p>비활성</p>
                </div>
              </div>

              <p className="partner-sub-p mt-3"> 소개 글</p>
              <div className="introduce-text-area-div mt-3">
                <div className="introduce-text-area-div-name">내용</div>
                <div className="introduce-text-area-div-right">
                  <textarea value={introduction} onChange={e => setIntroduction(e.target.value)}></textarea>
                  <button onClick={() => setIntroduction("")} className="btn fgb-btn-gray fgb-btn-s margin-btn ms-3">
                    초기화
                  </button>
                </div>
              </div>
              <p className="partner-sub-p mt-3"> 스터디 스케줄</p>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <div style={{ width: "200px" }} className="partner-profile-study-menu-tab ">
                  <div onClick={() => setIntroduceStep(0)} className={introduceStep == 0 ? "active" : ""}>
                    캠스터디
                  </div>
                  <div onClick={() => setIntroduceStep(1)} className={introduceStep == 1 ? "active" : ""}>
                    TO-DO
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button onClick={() => putProfliesPartnerIntroductionTodoStudyFn("N")} className="btn btn-primary font-white fgb-btn-m">
                    화면에 비전시
                  </button>
                  <button onClick={() => putProfliesPartnerIntroductionTodoStudyFn("Y")} className="btn btn-primary font-white fgb-btn-m ms-2">
                    화면에 전시
                  </button>
                  <button
                    onClick={() => navigate("/studySchedule/StudyScheduleInquiry")}
                    style={{ width: "130px" }}
                    className="btn btn-primary font-white fgb-btn-m ms-2"
                  >
                    스터디 스케줄 조회
                  </button>
                </div>
              </div>
              {introduceStep == 0 && (
                <div className="introduce-study-table mt-5">
                  <div className="fgb-table-div">
                    <table className={`fgb-table`}>
                      <thead>
                        <tr className={`fgb-table-title-tr`}>
                          <td>
                            <img
                              onClick={() => {
                                if (introductionCheckList.length == partnerProfileIntrocutionStudy.length) {
                                  setIntroductionCheckList([])
                                } else {
                                  let temp = []
                                  for (let i of partnerProfileIntrocutionStudy) {
                                    temp = [...temp, i.studySeq]
                                  }
                                  setIntroductionCheckList(temp)
                                }
                              }}
                              src={
                                introductionCheckList.length == partnerProfileIntrocutionStudy.length && partnerProfileIntrocutionStudy.length > 0
                                  ? checked_on
                                  : checked_off
                              }
                            />
                          </td>
                          {[
                            "학습자그룹",
                            "소유자",
                            "닉네임",
                            "제목",
                            "참여인원",
                            "시작일",
                            "완료일",
                            "기간(일)",
                            "(일)목표시간",
                            "누적시간",
                            "달성율(%)",
                            "전시",
                            "생성일",
                            "변경일",
                          ].map((i, idx) => (
                            <td key={idx}>{i}</td>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {partnerProfileIntrocutionStudy.map((i, idx) => (
                          <tr className={`fgb-table-tr ${introductionCheckList.indexOf(i.studySeq) != -1 ? "active" : ""}`} key={idx}>
                            <td>
                              <img
                                onClick={() => {
                                  let check = introductionCheckList.indexOf(i.studySeq)
                                  if (check == -1) {
                                    setIntroductionCheckList([...introductionCheckList, i.studySeq])
                                  } else {
                                    let temp = [...introductionCheckList]
                                    temp.splice(check, 1)
                                    setIntroductionCheckList(temp)
                                  }
                                }}
                                src={introductionCheckList.indexOf(i.studySeq) != -1 ? checked_on : checked_off}
                              />
                            </td>
                            <td>{i.studentGroupName}</td>
                            <td>{i.name}</td>
                            <td>{i.nickname}</td>
                            <td>{i.title}</td>
                            <td>
                              {i.studyMemberCount}/{i.studyMemberTotalCount}
                            </td>
                            <td>{moment(i.dateStart).format("MM/DD")}</td>
                            <td>{moment(i.dateEnd).format("MM/DD")}</td>
                            <td>{i.totalStudyDay}</td>
                            <td>{i.planTime}</td>
                            <td>{i.accumulatedStudyTime}</td>
                            <td>{i.achievementRate.toFixed(2) || 0}%</td>
                            <td>{i.isDisplay}</td>
                            <td>{moment(i.regDatetime).format("MM/DD")}</td>
                            <td>{moment(i.updateDatetime).format("MM/DD")}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              {introduceStep == 1 && (
                <div className="introduce-study-table mt-5">
                  <div className="fgb-table-div">
                    <table className={`fgb-table`}>
                      <thead>
                        <tr className={`fgb-table-title-tr`}>
                          <td>
                            <img
                              onClick={() => {
                                if (introductionCheckList.length == partnerProfileIntrocutionTodo.length) {
                                  setIntroductionCheckList([])
                                } else {
                                  let temp = []
                                  for (let i of partnerProfileIntrocutionTodo) {
                                    temp = [...temp, i.todoPackageSeq]
                                  }
                                  setIntroductionCheckList(temp)
                                }
                              }}
                              src={
                                introductionCheckList.length == partnerProfileIntrocutionTodo.length && partnerProfileIntrocutionTodo.length > 0
                                  ? checked_on
                                  : checked_off
                              }
                            />
                          </td>
                          {[
                            "학습자그룹",
                            "소유자",
                            "닉네임",
                            "제목(대표키워드)",
                            "구독자(명)",
                            "과제개수",
                            "저장",
                            "좋아요",
                            "응답률(%)",
                            "전시",
                            "생성일",
                            "변경일",
                          ].map((i, idx) => (
                            <td key={idx}>{i}</td>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {partnerProfileIntrocutionTodo.map((i, idx) => (
                          <tr className={`fgb-table-tr ${introductionCheckList.indexOf(i.todoPackageSeq) != -1 ? "active" : ""}`} key={idx}>
                            <td>
                              <img
                                onClick={() => {
                                  let check = introductionCheckList.indexOf(i.todoPackageSeq)
                                  if (check == -1) {
                                    setIntroductionCheckList([...introductionCheckList, i.todoPackageSeq])
                                  } else {
                                    let temp = [...introductionCheckList]
                                    temp.splice(check, 1)
                                    setIntroductionCheckList(temp)
                                  }
                                }}
                                src={introductionCheckList.indexOf(i.todoPackageSeq) != -1 ? checked_on : checked_off}
                              />
                            </td>
                            <td>{i.studentGroupName}</td>
                            <td>{i.name}</td>
                            <td>{i.nickname}</td>
                            <td>{i.title}</td>
                            <td>{i.subscriberCount}</td>
                            <td>{i.taskCount}</td>
                            <td>{i.bookmarkCount}</td>
                            <td>{i.favoriteCount}</td>
                            <td>{i.responseRate.toFixed(2) || 0}%</td>
                            <td>{i.isDisplay}</td>
                            <td>{moment(i.regDatetime).format("MM/DD")}</td>
                            <td>{moment(i.updateDatetime).format("MM/DD")}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              <div className="d-flex justify-content-center mt-4">
                <button className="btn fgb-btn-gray fgb-btn-l">취소</button>
                <button onClick={() => setOpenPreview(true)} className="btn btn-primary font-white fgb-btn-l ms-2">
                  미리 보기
                </button>
                <button onClick={putProfliesPartnerIntroductionFn} className="btn btn-primary font-white fgb-btn-l ms-2">
                  저장
                </button>
              </div>
            </div>
          )}
          {step == 2 && (
            <div className="partner-profile-authority">
              <div className="d-flex justify-content-between mb-4 mb-3">
                <p className="partner-profile-authority-title ms-3">관리자 메뉴 설정</p>
                <img
                  className="me-3"
                  onClick={() => {
                    if (member == 3 && todoSchedule == 3 && studySchedule == 3 && serviceCon == 3 && serviceKoo == 3 && message == 3) {
                      setMember(0)
                      setTodoSchedule(0)
                      setStudySchedule(0)
                      setServiceCon(0)
                      setServiceKoo(0)
                      setMessage(0)
                    } else {
                      setMember(3)
                      setTodoSchedule(3)
                      setStudySchedule(3)
                      setServiceCon(3)
                      setServiceKoo(3)
                      setMessage(3)
                    }
                  }}
                  src={
                    member == 3 && todoSchedule == 3 && studySchedule == 3 && serviceCon == 3 && serviceKoo == 3 && message == 3
                      ? checked_on
                      : checked_off
                  }
                />
              </div>

              <div className="partner-profile-authority-item">
                <div className="partner-profile-authority-item-1"> {"회원관리"}</div>
                <div className="partner-profile-authority-item-2">
                  <p>{"회원 조회"}</p>
                  <img
                    onClick={() => {
                      if (member >= 1) {
                        setMember(0)
                      } else {
                        setMember(1)
                      }
                    }}
                    src={member >= 1 ? checked_on : checked_off}
                  />
                </div>
                <div className="partner-profile-authority-item-2">
                  <p>{"회원 등록"}</p>
                  <img
                    onClick={() => {
                      if (member >= 3) {
                        setMember(0)
                      } else {
                        setMember(3)
                      }
                    }}
                    src={member >= 3 ? checked_on : checked_off}
                  />
                </div>
              </div>
              <div className="partner-profile-authority-item">
                <div className="partner-profile-authority-item-1"> {"스터디 스케줄 관리"}</div>
                <div className="partner-profile-authority-item-2">
                  <p>{"TO-DO 신청목록 조회"}</p>
                  <img
                    onClick={() => {
                      if (todoSchedule >= 3) {
                        setTodoSchedule(0)
                      } else {
                        setTodoSchedule(3)
                      }
                    }}
                    src={todoSchedule >= 3 ? checked_on : checked_off}
                  />
                </div>
                <div className="partner-profile-authority-item-2">
                  <p>{"스터디 스케줄 조회"}</p>
                  <img
                    onClick={() => {
                      if (studySchedule >= 1) {
                        setStudySchedule(0)
                      } else {
                        setStudySchedule(1)
                      }
                    }}
                    src={studySchedule >= 1 ? checked_on : checked_off}
                  />
                </div>
                <div className="partner-profile-authority-item-2">
                  <p>{"스터디 스케줄 등록"}</p>
                  <img
                    onClick={() => {
                      if (studySchedule >= 3) {
                        setStudySchedule(0)
                      } else {
                        setStudySchedule(3)
                      }
                    }}
                    src={studySchedule >= 3 ? checked_on : checked_off}
                  />
                </div>
              </div>
              <div className="partner-profile-authority-item">
                <div className="partner-profile-authority-item-1"> {"서비스 상품관리"}</div>
                <div className="partner-profile-authority-item-2">
                  <p>{"서비스상품 조회"}</p>
                  <img
                    onClick={() => {
                      if (serviceCon >= 1 || serviceKoo >= 1) {
                        setServiceCon(0)
                        setServiceKoo(0)
                      } else {
                        setServiceCon(1)
                        setServiceKoo(1)
                      }
                    }}
                    src={serviceCon >= 1 || serviceKoo >= 1 ? checked_on : checked_off}
                  />
                </div>
                <div className="partner-profile-authority-item-2">
                  <p>{"컨텐츠 등록"}</p>
                  <img
                    onClick={() => {
                      if (serviceCon >= 3) {
                        setServiceCon(0)
                      } else {
                        setServiceCon(3)
                      }
                    }}
                    src={serviceCon >= 3 ? checked_on : checked_off}
                  />
                </div>
                <div className="partner-profile-authority-item-2">
                  <p>{"꾸미기 등록"}</p>
                  <img
                    onClick={() => {
                      if (serviceKoo >= 3) {
                        setServiceKoo(0)
                      } else {
                        setServiceKoo(3)
                      }
                    }}
                    src={serviceKoo >= 3 ? checked_on : checked_off}
                  />
                </div>
              </div>
              <div className="partner-profile-authority-item">
                <div className="partner-profile-authority-item-1"> {"내 계정 관리"}</div>
                <div className="partner-profile-authority-item-2">
                  <p>{"메시지 관리"}</p>
                  <img
                    onClick={() => {
                      if (message >= 3) {
                        setMessage(0)
                      } else {
                        setMessage(3)
                      }
                    }}
                    src={message >= 3 ? checked_on : checked_off}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-center mt-4">
                <button
                  onClick={async () => {
                    await getProfilesPartnerAuth()
                  }}
                  className="btn fgb-btn-gray fgb-btn-l"
                >
                  취소
                </button>
                <button onClick={authorityFn} className="btn btn-primary font-white fgb-btn-l ms-2">
                  저장
                </button>
              </div>
            </div>
          )}
          {step == 3 && (
            <div className="partner-profile-term">
              <div className="d-flex justify-content-end mt-3 mb-3">
                <button onClick={() => setOpenSecessionModal(true)} style={{ width: "140px" }} className="btn fgb-btn-gray fgb-btn-m">
                  렛미업 제휴사 탈퇴
                </button>
              </div>
              <div className="fgb-table-div">
                <table className={`fgb-table`}>
                  <thead>
                    <tr className={`fgb-table-title-tr`}>
                      {["", "약관 유형", "약관 제목", "상태", "처리일자"].map((i, idx) => (
                        <td key={idx}>{i}</td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {profileTerms.map((i, idx) => (
                      <tr className={`fgb-table-tr`} key={idx}>
                        <td>{idx + 1}</td>
                        <td>{i.type == "USE" || i.type == "PRIVACY" ? "필수" : "선택"}</td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (idx < 2) {
                              setOpenTerm({ open: true, idx: idx })
                            } else {
                              setOpenTerm2({ open: true, data: i })
                            }
                          }}
                        >
                          {i.title}
                        </td>
                        <td>{i.status == "AGREE" ? "Y" : "N"}</td>
                        <td>{moment(i.regDatetime).format("YYYY / MM / DD")}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center mt-4">
                <button className="btn fgb-btn-gray fgb-btn-l">취소</button>
                <button onClick={() => popupFn("저장되었습니다.")} className="btn btn-primary font-white fgb-btn-l ms-2">
                  저장
                </button>
              </div>
            </div>
          )}
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default SignContainer(Profile)
//테이블용 더미
const TITLE = [
  "",
  "학습자그룸",
  "소유자",
  "닉네임",
  "제목",
  "참여인원",
  "시작일",
  "완료일",
  "기간(일)",
  "(월)목표시간",
  "누적시간",
  "달성율(%)",
  "전시",
  "생성일",
  "변경일",
]
const DUMY = [
  {
    group: "a",
    owner: "copu",
    nickname: "cs",
    title: "공부 goddddddd?",
    join: 16,
    startDate: "2001-03-23",
    endDate: "2001-05-19",
    date: 15,
    plan: "22H",
    totalTime: "99h",
    planLate: "100%",
    isOpen: "Y",
    regDate: "2001-03-23",
    editDate: "2001-03-27",
  },
  {
    group: "a",
    owner: "copu",
    nickname: "cs",
    title: "공부 go?",
    join: 16,
    startDate: "2001-03-23",
    endDate: "2001-05-19",
    date: 15,
    plan: "22H",
    totalTime: "99h",
    planLate: "100%",
    isOpen: "Y",
    regDate: "2001-03-23",
    editDate: "2001-03-27",
  },
  {
    group: "a",
    owner: "copu",
    nickname: "cs",
    title: "공부 go?",
    join: 16,
    startDate: "2001-03-23",
    endDate: "2001-05-19",
    date: 15,
    plan: "22H",
    totalTime: "99h",
    planLate: "100%",
    isOpen: "Y",
    regDate: "2001-03-23",
    editDate: "2001-03-27",
  },
  {
    group: "a",
    owner: "copu",
    nickname: "cs",
    title: "공부 go?",
    join: 16,
    startDate: "2001-03-23",
    endDate: "2001-05-19",
    date: 15,
    plan: "22H",
    totalTime: "99h",
    planLate: "100%",
    isOpen: "Y",
    regDate: "2001-03-23",
    editDate: "2001-03-27",
  },
  {
    group: "c",
    owner: "copu",
    nickname: "cs",
    title: "공부 go?",
    join: 16,
    startDate: "2001-03-23",
    endDate: "2001-05-19",
    date: 15,
    plan: "22H",
    totalTime: "99h",
    planLate: "100%",
    isOpen: "Y",
    regDate: "2001-03-23",
    editDate: "2001-03-27",
  },
]
