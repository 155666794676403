import moment from "moment"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import LayerPopupLayout from "./LayerPopupLayout"
import UP from "../images/UP.svg"
import DOWN from "../images/DOWN.svg"
import KEEP from "../images/KEEP.svg"
import rank_flag from "../images/rank_flag.svg"
const RankingPopup = ({
  open = false,
  onClose = () => {},
  type = "",
  title = "{제목}",
  list = [
    {
      nickname: "Dumy",
      isMine: 0,
      totalTime: "07:15:47",
      ranking: 1,
    },
  ],
}) => {
  const dispatch = useDispatch()

  const closePopup = () => {
    onClose()
  }

  return (
    <LayerPopupLayout open={open} onClose={closePopup} isCloseBtn isMCloseBtn transparency zIndex={101}>
      <div className="popup-ranking-container">
        <p className="popup-ranking-title">{title}</p>
        <div className="popup-ranking-list-view">
          {list.map((i, idx) => (
            <div key={idx} className="popup-ranking-list-item">
              {i.isMine == 1 ? <img className="is-mine" src={rank_flag} alt="ismine img" /> : null}
              <p className="no">{idx + 1}</p>
              <p className="title">{i.title || i.nickname || i.tag || "nickname"}</p>
              {type == "" ? (
                <p className="time">{i.totalTime}</p>
              ) : (
                <div className="up-down time">
                  <p>{i.rankingFluctuation == 0 ? "-" : i.rankingFluctuation}</p>
                  <img src={i.rankingUpDown == "KEEP" ? KEEP : i.rankingUpDown == "UP" ? UP : DOWN}></img>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="popup-ranking-close-btn-view">
          <button className="btn btn-primary font-white popup-ranking-close-btn" onClick={closePopup}>
            확인
          </button>
        </div>
      </div>
    </LayerPopupLayout>
  )
}

export default RankingPopup
