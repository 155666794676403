/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect } from "react"

import "swiper/scss"

import BottomInfo from "../components/login/BottomInfo"

import SEO from "../components/seo"
import Layout from "../components/Layout"

import leftImg from "../images/leftImg.png"
import rightImg from "../images/RightImg.png"
import login_center_icon from "../images/login_center_icon.png"
import checked_on from "../images/checked_on.svg"
import checked_off from "../images/checked_off.svg"
import Validator from "validator"
import { debounce } from "lodash"
import ModalPopup from "../popups/ModalPopup"

import SignContainer from "../containers/SignContainer"
import { Link, navigate } from "gatsby"
import { dataCheckFn, localStorgeSet } from "../utils/util"
import { useDispatch } from "react-redux"
import { setIsLogin } from "../store/modules/sign"
//제휴사 로그인
const Affiliates = props => {
  const dispatch = useDispatch()
  const { postAuthNumber, authLog, postAuthNumberCheck, checkSignStatus } = props
  const [phone, setPhone] = useState("")
  const [authNo, setAuthNo] = useState("")
  const [isAuto, setIsAuto] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [tempPw, setTempPw] = useState("")
  const [minutes, setMinutes] = useState(3)
  const [seconds, setSeconds] = useState(0)
  const [errText, setErrText] = useState("")
  const [openModalText, setOpenModalText] = useState(false)
  const [authClick, setAuthClick] = useState(false)
  //타이머
  useEffect(() => {
    const countdown = setInterval(() => {
      if (parseInt(seconds) > 0) {
        setSeconds(parseInt(seconds) - 1)
      }
      if (parseInt(seconds) === 0) {
        if (parseInt(minutes) === 0) {
          clearInterval(countdown)
        } else {
          setMinutes(parseInt(minutes) - 1)
          setSeconds(59)
        }
      }
    }, 1000)
    return () => clearInterval(countdown)
  }, [minutes, seconds])
  useEffect(() => {
    localStorage.removeItem(process.env.TOKEN_ID)
  }, [])
  const postAuthNumberFn = debounce(async () => {
    if (!Validator.isMobilePhone(phone, "ko-KR")) {
      return false
    }

    let result = await postAuthNumber({ phone: phone })
    if (result != false) {
      //타이머초기화
      setMinutes(3)
      setSeconds(0)
      //타이머 on
      setAuthClick(true)
      //alerts off
    }
  }, 350)
  const postAuthNumberCheckFn = debounce(async () => {
    if (Validator.isEmpty(authNo, { ignore_whitespace: true })) {
      return false
    }
    if (!dataCheckFn(authLog)) {
      return false
    }
    let temp = {
      authLogSeq: authLog.authLogSeq,
      authNumber: authNo,
    }
    if (authLog.status == "WAITING") {
      setTempPw("승인 대기중입니다.")
      setOpenModal(true)
      return false
    } else if (authLog.status == "NONE") {
      setTempPw("회원가입을 진행해주세요.")
      setOpenModal(true)
      return false
    }
    let result = await postAuthNumberCheck(temp)
    if (result) {
      localStorgeSet(process.env.TOKEN_ID, result.accessToken)
      localStorgeSet("loginUserSeq", result.loginUserSeq)
      localStorgeSet("loginUserType", result.loginUserType)
      localStorgeSet("letMeUpAdminPhoneNum", phone)
      localStorgeSet("applicationType", result.applicationType)
      dispatch(setIsLogin(true))
      //main 이동
      navigate("/AffiliatesMain")
    }
  }, 350)

  return (
    <Layout isLeftBar={false} isContainer={false}>
      <SEO title="Login" />
      <ModalPopup
        open={openModal}
        onClose={() => {
          setTempPw("")
          setOpenModal(false)
        }}
        contents={tempPw}
        onClickBtn={() => setOpenModal(false)}
        btn={["확인"]}
      />
      <div className="admin-login-container">
        <img src={leftImg} />
        <div className="admin-login-div">
          <p className="affiliates-login-sub-title">LET ME UP 관리자 시스템</p>
          <p className="affiliates-login-sub-title-gray">학습집중도 · 주도적인 학습 습관을 키워주는</p>
          <p className="admin-login-title">AI 개인 맞춤형 학습 멘토 서비스</p>
          <p className="admin-login-text">로그인</p>
          <div className="d-flex justify-content-center">
            <img src={login_center_icon} />
          </div>
          <p className="admin-login-sm-p">휴대폰번호</p>
          <div className="d-flex">
            <input
              value={phone}
              onChange={e => setPhone(e.target.value)}
              type="text"
              placeholder="‘-’ 없이 휴대전화번호를 입력해주세요."
              className="fgb-input"
            />
            <button onClick={() => postAuthNumberFn()} className="btn btn-primary font-white fgb-btn-m ms-2 flex-shrink-0">
              {authClick == true ? "인증번호 재요청" : "인증번호 요청"}
            </button>
          </div>
          <p className="admin-login-sm-p">인증번호</p>
          <div className="d-flex">
            <input
              onKeyDown={e => {
                if (e.key === "Enter") {
                }
              }}
              value={authNo}
              onChange={e => setAuthNo(e.target.value)}
              type="text"
              placeholder={authClick == true ? `${minutes}:${seconds < 10 ? "0" + seconds : seconds}` : "문자로 전송된 인증번호를 입력해주세요."}
              className="fgb-input"
            />
            <button onClick={postAuthNumberCheckFn} className="btn btn-primary font-white fgb-btn-m ms-2 flex-shrink-0">
              인증하기
            </button>
          </div>

          <div className="d-flex justify-content-center admin-login-btn-top-margin">
            <div onClick={postAuthNumberCheckFn} className="btn btn-primary font-white fgb-btn-l login-btn">
              로그인
            </div>
          </div>
          <p style={{ color: "#313235" }} className="affiliates-login-bottom-p-1">
            아직 가입전이라면 회원가입을 먼저 진행해주세요.
          </p>
          <div className="d-flex justify-content-center">
            <button onClick={() => navigate("/AffiliatesSignup")} className="affiliates-login-bottom-p-2">
              회원가입
            </button>
          </div>
        </div>
        <img src={rightImg} />
      </div>
      <BottomInfo />
    </Layout>
  )
}

export default SignContainer(Affiliates)
