/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect } from "react"
import moment from "moment"

import "swiper/scss"

import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import TopInfo from "../../components/common/TopInfo"
import CalendarComponent from "../../components/Calendar"
import PrivateRoute from "../../components/privateRoute"
import MasterContainer from "../../containers/MasterContainer"

import PermissionTypePopup from "../../popups/master/PermissionTypePopup"
import PermissionInfoPopup from "../../popups/master/PermissionInfoPopup"
import PermissionEditPopup from "../../popups/master/PermissionEditPopup"

import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import icon_calendar from "../../images/ion_calendar-number-outline.svg"
import { dataCheckFn, popupFn } from "../../utils/util"

const Permission = props => {
  const {
    getAdminsInfo,
    adminTypeList,
    admin1DepthList,
    admin2DepthList,
    masterList,
    getAdminType,
    getAdminMeue1,
    getAdminMeue2,
    getAdminMasterList,
    delAdmins,
  } = props

  const [nameRadio, setNameRadio] = useState(true)
  const [dateRadio, setDateRadio] = useState(true)
  const [keyword, setKeyword] = useState("")

  //운영자 유형
  const [adminType, setAdminType] = useState("")
  //1차 메뉴
  const [admin1Depth, setAdmin1Depth] = useState("")
  //2차 메뉴
  const [admin2Depth, setAdmin2Depth] = useState("")

  const [checkList, setCheckList] = useState([])

  const [calendarPopup, setCalendarPopup] = useState(false)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [date, setDate] = useState(moment())
  const [popupType, setPopupType] = useState(false)

  const [openPermissionTypePopup, setOpenPermissionTypePopup] = useState(false)
  const [openPermissionInfoPopup, setOpenPermissionInfoPopup] = useState(false)
  const [openPermissionEditPopup, setOpenPermissionEditPopup] = useState(false)

  const getStartDate = async () => {
    //드롭다운불러오기
    await getAdminType()
    await getAdminMeue1()
  }
  const getDate = async () => {
    let temp = {
      wordType: nameRadio ? "NAME" : "ID",
      word: keyword,
      adminTypeSeq: adminType,
      menu1Seq: admin1Depth,
      menu2Seq: admin2Depth,
      dateType: dateRadio ? "REGISTRATION" : "UPDATE",
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
    }

    let result = await getAdminMasterList(temp)
    if (result) {
      setCheckList([])
    }
  }
  const delFn = async () => {
    if (checkList.length <= 0) {
      return false
    }
    let result = await delAdmins({ adminSeqs: checkList })
    if (result) {
      popupFn("삭제되었습니다.")
      getDate()
      setCheckList([])
    }
  }
  const get2DepthFn = async () => {
    //전체면 비우기
    if (admin1Depth == "") {
      await getAdminMeue2("")
    } else {
      await getAdminMeue2(admin1Depth)
    }
  }
  useEffect(() => {
    getStartDate()
  }, [])
  useEffect(() => {
    //1뎁스 변경하면 2뎁스 조회
    if (dataCheckFn(admin1DepthList)) {
      get2DepthFn()
    }
  }, [admin1Depth])
  return (
    <PrivateRoute>
      <PermissionTypePopup open={openPermissionTypePopup} onClose={() => setOpenPermissionTypePopup(false)} />
      <PermissionInfoPopup getDate={getDate} open={openPermissionInfoPopup} onClose={() => setOpenPermissionInfoPopup(false)} />
      <PermissionEditPopup open={openPermissionEditPopup} onClose={() => setOpenPermissionEditPopup(false)} />
      <Layout>
        <SEO title="운영자권한 관리" />

        <TopInfo title={["운영자 권한 관리", "마스터", "운영자 권한 관리"]} />
        <div className="permission-container">
          <div style={{ borderBottom: "none" }} className="row permission-table-row">
            <div className="col-5 flex-con">
              <div className="permission-table-div1">운영자</div>
              <div className="permission-table-div2">
                <img onClick={() => setNameRadio(!nameRadio)} src={nameRadio ? radio_on : radio_off} />
                <p>이름</p>
                <img onClick={() => setNameRadio(!nameRadio)} src={!nameRadio ? radio_on : radio_off} />
                <p>ID</p>
                <input value={keyword} onChange={e => setKeyword(e.target.value)} type="text" className="fgb-input" />
              </div>
            </div>
            <div className="col-7 flex-con">
              <div className="permission-table-div1">운영자 유형</div>
              <div className="permission-table-div2">
                <div style={{ width: "220px", margin: "10px 5px" }}>
                  <select
                    value={adminType}
                    className="form-select"
                    style={{ fontSize: "14px" }}
                    onChange={e => {
                      setAdminType(e.target.value)
                      console.log(e.target.value)
                    }}
                  >
                    {[{ seq: "", name: "전체" }, ...adminTypeList].map((i, idx) => (
                      <option key={idx} className="" value={i.seq}>
                        {i.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="row permission-table-row">
            <div className="col-5 flex-con">
              <div className="permission-table-div1">접근권한</div>
              <div className="permission-table-div2">
                <div style={{ width: "140px", margin: "10px 5px" }}>
                  <select
                    className="form-select"
                    style={{ fontSize: "14px" }}
                    value={admin1Depth}
                    onChange={e => {
                      setAdmin1Depth(e.target.value)
                    }}
                  >
                    {[{ menu1Seq: "", menu1Name: "메뉴 전체" }, ...admin1DepthList].map((i, idx) => (
                      <option key={idx} className="" value={i.menu1Seq}>
                        {i.menu1Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ width: "140px", margin: "10px 5px" }}>
                  <select
                    value={admin2Depth}
                    className="form-select"
                    style={{ fontSize: "14px" }}
                    onChange={e => {
                      setAdmin2Depth(e.target.value)
                    }}
                  >
                    {[{ menu2Seq: "", menu2Name: "권한 전체" }, ...admin2DepthList].map((i, idx) => (
                      <option key={idx} className="" value={i.menu2Seq}>
                        {i.menu2Name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-7 flex-con">
              <div className="permission-table-div1">기간</div>
              <div className="permission-table-div2">
                <img onClick={() => setDateRadio(!dateRadio)} src={dateRadio ? radio_on : radio_off} />
                <p>등록일</p>
                <img onClick={() => setDateRadio(!dateRadio)} src={!dateRadio ? radio_on : radio_off} />
                <p>변경일</p>
                <div className="fgb-date-form">
                  <img src={icon_calendar} />
                  <div
                    className="fgb-date-input"
                    onClick={() => {
                      setPopupType(false)
                      setCalendarPopup(true)
                    }}
                  >
                    <p>{startDate.isValid() ? startDate.format("YYYY.MM.DD") : "시작일"}</p>
                  </div>
                  {calendarPopup && (
                    <div className="calendar-popup-back">
                      <div className="calendar-popup-div">
                        <CalendarComponent
                          date={date}
                          setDate={setDate}
                          selectedDate={popupType == false ? startDate : endDate}
                          setSelectedDate={popupType == false ? setStartDate : setEndDate}
                          popup={setCalendarPopup}
                        />
                      </div>
                    </div>
                  )}
                  <p>~</p>
                  <img src={icon_calendar} />

                  <div
                    className="fgb-date-input "
                    onClick={() => {
                      setPopupType(true)
                      setCalendarPopup(true)
                    }}
                  >
                    <p>{endDate.isValid() ? endDate.format("YYYY.MM.DD") : "종료일"}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-container">
            <button onClick={getDate} className="btn btn-primary font-white fgb-btn-l">
              조회{" "}
            </button>
          </div>
          <div className="permission-table-container">
            <div className="permission-table-top">
              <button onClick={() => setOpenPermissionTypePopup(true)} className="btn btn-primary font-white fgb-btn-m">
                운영자 유형 관리
              </button>
              <div className="permission-table-top-right">
                <button onClick={() => delFn()} className="btn btn-primary fgb-btn-gray fgb-btn-m">
                  선택삭제
                </button>
                <button onClick={() => setOpenPermissionEditPopup(true)} className="btn btn-primary font-white fgb-btn-m">
                  권한등록
                </button>
              </div>
            </div>
            <div className="permission-table-div">
              <div className="fgb-table-div">
                <table className={`fgb-table`}>
                  <thead>
                    <tr className={`fgb-table-title-tr`}>
                      <td>
                        <img
                          onClick={() => {
                            if (checkList.length == masterList.length) {
                              setCheckList([])
                            } else {
                              let temp = []
                              for (let i of masterList) {
                                temp = [...temp, i.adminSeq]
                              }
                              setCheckList(temp)
                            }
                          }}
                          src={checkList.length == masterList.length && masterList.length > 0 ? checked_on : checked_off}
                        />
                      </td>
                      {TITLE.map((i, idx) => (
                        <td key={idx}>{i}</td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {masterList.map((i, idx) => (
                      <tr className={`fgb-table-tr ${checkList.indexOf(i.adminSeq) != -1 ? "active" : ""}`} key={idx}>
                        <td>
                          <img
                            onClick={() => {
                              let check = checkList.indexOf(i.adminSeq)
                              let temp = [...checkList]
                              if (check == -1) {
                                temp = [...temp, i.adminSeq]
                              } else {
                                temp.splice(check, 1)
                              }
                              setCheckList(temp)
                            }}
                            src={checkList.indexOf(i.adminSeq) != -1 ? checked_on : checked_off}
                          />
                        </td>
                        <td>{i.adminTypeName}</td>
                        <td>{i.id}</td>
                        <td>{i.password}</td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={async () => {
                            await getAdminsInfo(i.adminSeq)
                            setOpenPermissionInfoPopup(true)
                          }}
                        >
                          {i.name}
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={async () => {
                            await getAdminsInfo(i.adminSeq)
                            setOpenPermissionInfoPopup(true)
                          }}
                        >
                          {i.affiliation}
                        </td>
                        <td>{i.phone}</td>
                        <td>{i.regDatetime}</td>
                        <td>{i.updateDatetime}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default MasterContainer(Permission)
//테이블용 더미
const TITLE = ["운영자 유형", "id", "비밀번호", "운영자 이름", "소속", "휴대전화번호", "등록일", "변경일"]
