import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import {
  getStudyListAction,
  getStudyInfoAction,
  getStudyMemberAction,
  getStudyListExcelAction,
  getScheduleParticipantsListAction,
  postStudyScheduleCamStudyAction,
  putStudyScheduleCamStudyAction,
  getScheduleCamstudyListAction,
  getScheduleCamstudyInfoAction,
  delScheduleCamstudyAction,
  putScheduleCamstudyAction,
  getToDoListAction,
  getToDoInfoAction,
  postToDoAction,
  putToDoAction,
  postToDoSubscriberAction,
  delToDoSubscriberAction,
  getToDoAssignmentAction,
  putToDoAssignmentAction,
  postToDoAssignmentAction,
  delToDoAssignmentAction,
  postToDoKeywordAction,
  delToDoKeywordAction,
  getScheduleToDoListAction,
  getScheduleToDoInfoAction,
  putScheduleToDoAction,
  delScheduleToDoAction,
  getScheduleCamstudyListExcelAction,
  getScheduleToDoListExcelAction,
} from "../store/modules/schedule"

const mapStateToProps = state => ({
  studyList: state.schedule.studyList,
  studyInfo: state.schedule.studyInfo,
  scheduleParticipantsList: state.schedule.scheduleParticipantsList,
  scheduleCamstudyList: state.schedule.scheduleCamstudyList,
  scheduleCamstudyInfo: state.schedule.scheduleCamstudyInfo,
  todoList: state.schedule.todoList,
  todoInfo: state.schedule.todoInfo,
  scheduleTodoList: state.schedule.scheduleTodoList,
  scheduleTodoInfo: state.schedule.scheduleTodoInfo,
  todoAssignment: state.schedule.todoAssignment,
})

const mapDispatchToProps = dispatch => ({
  getStudyList: param => dispatch(getStudyListAction(param)),
  getStudyInfo: param => dispatch(getStudyInfoAction(param)),
  getStudyMember: param => dispatch(getStudyMemberAction(param)),
  getStudyListExcel: param => dispatch(getStudyListExcelAction(param)),
  getScheduleParticipantsList: param => dispatch(getScheduleParticipantsListAction(param)),
  postStudyScheduleCamStudy: param => dispatch(postStudyScheduleCamStudyAction(param)),
  putStudyScheduleCamStudy: param => dispatch(putStudyScheduleCamStudyAction(param)),
  getScheduleCamstudyList: param => dispatch(getScheduleCamstudyListAction(param)),
  getScheduleCamstudyInfo: param => dispatch(getScheduleCamstudyInfoAction(param)),
  delScheduleCamstudy: param => dispatch(delScheduleCamstudyAction(param)),
  putScheduleCamstudy: param => dispatch(putScheduleCamstudyAction(param)),
  getToDoList: param => dispatch(getToDoListAction(param)),
  getToDoInfo: param => dispatch(getToDoInfoAction(param)),
  postToDo: param => dispatch(postToDoAction(param)),
  putToDo: param => dispatch(putToDoAction(param)),
  postToDoSubscriber: param => dispatch(postToDoSubscriberAction(param)),
  delToDoSubscriber: param => dispatch(delToDoSubscriberAction(param)),
  getToDoAssignment: param => dispatch(getToDoAssignmentAction(param)),
  putToDoAssignment: param => dispatch(putToDoAssignmentAction(param)),
  postToDoAssignment: param => dispatch(postToDoAssignmentAction(param)),
  delToDoAssignment: param => dispatch(delToDoAssignmentAction(param)),
  postToDoKeyword: param => dispatch(postToDoKeywordAction(param)),
  delToDoKeyword: param => dispatch(delToDoKeywordAction(param)),
  getScheduleToDoList: param => dispatch(getScheduleToDoListAction(param)),
  getScheduleToDoInfo: param => dispatch(getScheduleToDoInfoAction(param)),
  putScheduleToDo: param => dispatch(putScheduleToDoAction(param)),
  delScheduleToDo: param => dispatch(delScheduleToDoAction(param)),
  getScheduleCamstudyListExcel: param => dispatch(getScheduleCamstudyListExcelAction(param)),
  getScheduleToDoListExcel: param => dispatch(getScheduleToDoListExcelAction(param)),
})

const WithHoc = ChildComponent => props => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
