/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect } from "react"

import "swiper/scss"

import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import moment from "moment"
import CalendarComponent from "../../components/Calendar"
import Pagination from "../../components/common/pagination"
import TopInfo from "../../components/common/TopInfo"

import CommonContainer from "../../containers/CommonContainer"
import ServiceContainer from "../../containers/ServiceContainer"
import SignContainer from "../../containers/SignContainer"

import icon_calendar from "../../images/ion_calendar-number-outline.svg"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"
import download from "../../images/download.svg"
import CloseButton from "../../images/img_popup_close_black.svg"
import PrivateRoute from "../../components/privateRoute"

import ModalPreviewPopup from "../../popups/ModalPreviewPopup"
import { useSelector } from "react-redux"
import { popupFn } from "../../utils/util"
import { navigate } from "gatsby"
const AdornmentReg = props => {
  const { postUploadFiles, postUploadFile, postDecorationItem } = props
  const userInfo = useSelector(state => state.sign.userInfo)
  const [type, setType] = useState(1) // 타이틀 Radio 버튼 (0: 필터,  1: 스티커)
  const [titleInput, setTitleInput] = useState() // 타이틀 Input 박스 기본값: null
  const [owner, setOwner] = useState() // 개설자 Input 박스 기본값: null
  const [keywordInput, setKeywordInput] = useState() // 키워드 Input 박스 기본값: null
  const [keywords, setKeywords] = useState([]) // 키워드 들어있는 배열

  const [allCheck, setAllCheck] = useState(false) // 전체 체크
  const [checkList, setCheckList] = useState([])
  const [files, setFiles] = useState([])
  const [filesInfo, setFilesInfo] = useState([])

  const [openPreview, setOpenPreview] = useState({ contents: {}, open: "false" })

  //base64
  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  const ChangeDisplay = (idx, type) => {
    setFilesInfo(
      filesInfo.map((i, index) =>
        idx === index
          ? {
              ...i,
              isDisplay: type,
            }
          : i
      )
    )
  }

  const ChangePoint = (idx, pointValue) => {
    setFilesInfo(
      filesInfo.map((i, index) =>
        idx === index
          ? {
              ...i,
              point: pointValue,
            }
          : i
      )
    )
  }

  const ChangeIsFree = (idx, value) => {
    setFilesInfo(
      filesInfo.map((i, index) =>
        idx === index
          ? {
              ...i,
              isFree: value,
            }
          : i
      )
    )
  }

  useEffect(() => {
    let list_ = []
    for (let i = 0; i < filesInfo.length; i++) {
      list_.push(false)
    }
    setCheckList(list_)
  }, [filesInfo])

  useEffect(() => {
    let list_ = []
    for (let i = 0; i < filesInfo.length; i++) {
      list_.push(allCheck)
    }
    setCheckList(list_)
  }, [allCheck])

  const RenderItems = () => {
    let result = []

    for (let i = 0; i < filesInfo.length; i++) {
      result.push(
        <div className="filter-table-filter-row">
          <div style={{ width: "40px" }}>
            <img
              src={checkList[i] ? checked_on : checked_off}
              onClick={() => {
                let list_ = []
                for (let j = 0; j < filesInfo.length; j++) {
                  if (j === i) {
                    list_.push(!checkList[j])
                  } else {
                    list_.push(checkList[j])
                  }
                }
                setCheckList(list_)
              }}
            />
          </div>
          <div
            onClick={() => {
              ChangeIsFree(i, filesInfo[i]?.isFree == "무료" ? "유료" : "무료")
            }}
            style={{ width: "80px" }}
          >
            <input disabled className="input-box" value={filesInfo[i]?.isFree} style={{ width: "64px", textAlign: "center", cursor: "pointer" }} />
          </div>
          <div style={{ padding: "0px 10px", width: "300px", maxWidth: "300px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            {filesInfo[i]?.fileName}
          </div>
          <div style={{ width: "80px" }}>
            <input
              disabled={filesInfo[i]?.isFree != "유료"}
              className="input-box"
              value={filesInfo[i]?.isFree == "유료" ? filesInfo[i]?.point : 0}
              onChange={e => ChangePoint(i, e.target.value)}
              style={{ width: "64px", textAlign: "center" }}
            />
          </div>
          <div style={{ width: "80px" }}>
            <div
              onClick={() => setOpenPreview({ open: true, contents: { base64: filesInfo[i].base64 } })}
              className="btn btn-primary font-white reverse fgb-btn-m"
              style={{ border: "1px solid", height: "38px", fontSize: "12px", width: "65px", padding: "0", margin: "11px auto" }}
            >
              미리보기
            </div>
          </div>
          <div style={{ display: "flex", width: "220px", justifyContent: "center" }}>
            <div className="radio-btn-wrapper" onClick={() => ChangeDisplay(i, 0)} style={{ border: "none" }}>
              <img src={filesInfo[i]?.isDisplay === 0 ? radio_on : radio_off} />
              <p>전시</p>
            </div>
            <div className="radio-btn-wrapper" onClick={() => ChangeDisplay(i, 1)}>
              <img src={filesInfo[i]?.isDisplay === 1 ? radio_on : radio_off} />
              <p>미전시</p>
            </div>
          </div>
        </div>
      )
    }

    return result
  }

  const RenderBlankBoxes = () => {
    const blankBoxesLength = 5 - files.length
    let result = []

    for (let i = 0; i < blankBoxesLength; i++) {
      result.push(
        <div className="filter-table-filter-row">
          <div style={{ width: "40px" }}>
            <img src={checked_off} />
          </div>
          <div style={{ width: "80px" }}></div>
          <div style={{ width: "300px" }}></div>
          <div style={{ width: "80px" }}></div>
          <div style={{ width: "80px" }}></div>
          <div style={{ width: "220px" }}></div>
        </div>
      )
    }

    return result
  }

  const deleteFiles = () => {
    setFilesInfo(filesInfo.filter((i, idx) => (checkList[idx] ? "" : i)))
    setFiles(files.filter((i, idx) => (checkList[idx] ? "" : i)))
    setAllCheck(false)
  }

  const fileChangeHandler = async event => {
    const fileArray = event.target.files
    let idx_ = []
    let idx_name = []
    for (let i = 0; i < fileArray.length; i++) {
      let base64 = await toBase64(fileArray[i])
      idx_.push(fileArray[i])

      idx_name.push({
        base64: base64,
        isFree: "무료",
        fileName: fileArray[i]?.name,
        point: 0,
        isDisplay: 0,
      })
    }
    setFiles([...files, ...idx_])
    setFilesInfo([...filesInfo, ...idx_name])
  }

  const handleSave = async () => {
    const type_ = type === 0 ? "FILTER" : "STICKER"
    const title_ = titleInput
    const tags_ = keywords
    let insert_items = []

    let isSuccess = true

    for (let i = 0; i < files.length; i++) {
      const formData = new FormData()
      await formData.append("file", files[i])
      const result = await postUploadFile(formData)
      if (!result) {
        isSuccess = false
      } else {
        insert_items.push({
          paymentType: filesInfo[i]?.isFree === "무료" ? "FREE" : "PAY",
          fileName: filesInfo[i]?.fileName,
          fileUrl: result,
          filterSource: "",
          point: filesInfo[i]?.isFree === "무료" ? 0 : filesInfo[i]?.point,
          isDisplay: filesInfo[i]?.isDisplay == 0 ? "Y" : "N",
        })
      }
    }

    if (isSuccess) {
      const result_ = await postDecorationItem({
        type: type_,
        title: title_,
        insertItems: insert_items,
        tags: tags_,
      })

      if (result_) {
        popupFn("저장되었습니다.")
        navigate("/service/ServiceInquiry")
        // window.location.reload()
      }
    }
  }

  return (
    <PrivateRoute>
      <Layout>
        {/* {console.log(filesInfo, files)} */}
        <SEO title="꾸미기 등록" />
        <TopInfo title={["꾸미기 등록", "서비스 상품 관리", "꾸미기 등록"]} />
        <ModalPreviewPopup open={openPreview.open} onClose={() => setOpenPreview({ contents: {}, open: "false" })} contents={openPreview.contents} />
        <div className="adornment-reg-container">
          <div className="adornment-reg-upper-table-wrapper">
            <div className="element-l right-border">타이틀</div>
            <div className="element-r right-border" style={{ width: "550px" }}>
              <div className="radio-btn-wrapper" onClick={() => setType(0)}>
                <img src={type == 0 ? radio_on : radio_off} />
                <p>필터</p>
              </div>
              <div className="radio-btn-wrapper" onClick={() => setType(1)}>
                <img src={type == 1 ? radio_on : radio_off} />
                <p>스티커</p>
              </div>
              <input
                className="input-box"
                placeholder="제목 공백 포함 최대 10자 입력"
                maxLength="10"
                value={titleInput}
                onChange={e => setTitleInput(e.target.value)}
                style={{ width: "350px", marginRight: "10px" }}
              />
            </div>
            <div className="element-l right-border">개설자</div>
            <div className="element-r" style={{ width: "230px" }}>
              <input
                className="input-box"
                placeholder=""
                value={userInfo?.profile?.name || ""}
                style={{ width: "190px", marginRight: "10px" }}
                readOnly
                disabled
              />
            </div>
          </div>

          <div className="adornment-reg-down-table-wrapper">
            <div className="table-row no-border">
              <div className="element-l right-border">키워드</div>
              <div className="element-r" style={{ width: "880px" }}>
                {keywords.length !== 0 &&
                  keywords.map(i => (
                    <div className="keyword-box">
                      {i}
                      <img className="x-button" src={CloseButton} onClick={e => setKeywords(keywords.filter(item => item !== i))} />
                    </div>
                  ))}
                {keywords.length < 5 && (
                  <input
                    className="input-box"
                    value={keywordInput}
                    placeholder="Enter or Tab"
                    onChange={e => setKeywordInput(e.target.value)}
                    style={{ width: "158px", textAlign: "center" }}
                    onKeyPress={e => {
                      if (e.key === "Enter" && e.target.value !== undefined) {
                        setKeywordInput()
                        setKeywords([...keywords, e.target.value])
                        e.target.value = ""
                      }
                    }}
                  />
                )}
              </div>
            </div>
            <div className="item-list-container">
              <div className="item-list-wrapper">
                <div className="item-list-left-part">아이템 목록</div>
                <div className="item-list-right-part">
                  <div className="buttons">
                    <p>{files.length}개</p>
                    <div>
                      <div className="btn btn-primary font-white reverse fgb-btn-m" onClick={deleteFiles}>
                        선택 삭제
                      </div>
                      <input
                        // ref={flie_add}
                        id="file-add"
                        type="file"
                        multiple
                        style={{ display: "none" }}
                        onChange={fileChangeHandler}
                        accept=".jpg, .png, .gif, .svg, .jpeg"
                      />
                      <label
                        htmlFor="file-add"
                        className="btn btn-primary font-white fgb-btn-m"
                        style={{ width: "85px", height: "35px", marginLeft: "5px" }}
                      >
                        추가
                      </label>
                    </div>
                  </div>
                  <div className="add-filter-table-wrapper" style={{ overflowY: "scroll" }}>
                    <div className="filter-table-detail-row">
                      <div style={{ width: "40px" }}>
                        <img onClick={e => setAllCheck(!allCheck)} src={allCheck ? checked_on : checked_off} />
                      </div>
                      <div style={{ width: "80px" }}>구분</div>
                      <div style={{ width: "300px" }}>파일명</div>
                      <div style={{ width: "80px" }}>포인트</div>
                      <div style={{ width: "80px" }}>미리 보기</div>
                      <div style={{ width: "220px" }}>화면 표시</div>
                    </div>

                    <div>
                      {RenderItems()}
                      {RenderBlankBoxes()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="two-buttons-wrapper">
            <div className="btn btn-primary font-white reverse fgb-btn-l cancle-button">취소</div>
            <div className="btn btn-primary font-white fgb-btn-l save-button" onClick={handleSave}>
              저장
            </div>
          </div>
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default SignContainer(ServiceContainer(AdornmentReg))
