import React, { useState } from "react"
import img_popup_close_gray from "../../images/img_popup_close_gray.svg"
import CloseBtn from "../../images/Close_Button.svg"
import LayerPopupLayout from "../LayerPopupLayout"
import OperationContainer from "../../containers/OperationContainer"
import CalendarComponent from "../../components/Calendar"
import ModalPopup from "../ModalPopup"

import Validator from "validator"
import { debounce } from "lodash"
import { dataCheckFn, popupFn } from "../../utils/util"

import upload from "../../images/upload.svg"

import moment from "moment"

import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"
import icon_calendar from "../../images/ion_calendar-number-outline.svg"

import ModalPreviewPopup from "../../popups/ModalPreviewPopup"

import { useEffect } from "react"
const TYPE = {
  전체: "",
  "(PR) News": "NEWS",
  EVENT안내: "EVENT",
  "정책변경 안내": "POLICY",
  "시스템 안내": "SYSTEM",
  "긴급공지 안내": "EMERGENCY",
  "자주하는 질의응답": "FAQ",
}
const TYPE2 = {
  NEWS: "(PR) News",
  EVENT: "EVENT안내",
  POLICY: "정책변경 안내",
  SYSTEM: "시스템 안내",
  EMERGENCY: "긴급공지 안내",
  FAQ: "자주하는 질의응답",
}
const NoticePopup = props => {
  const {
    open,
    onClose,
    getDate = () => {},
    setNoticeTargetEmpty,
    noticeTarget,
    postUploadFile,
    putTargetNotifications,
    postTargetNotifications,
  } = props
  const [calendarPopup, setCalendarPopup] = useState(false)
  const [date, setDate] = useState(moment())
  const [popupType, setPopupType] = useState(false)
  const [openPreview, setOpenPreview] = useState({ contents: {}, open: "false" })

  //data
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [type, setType] = useState("(PR) News")
  const [title, setTitle] = useState("")
  const [isDisplay, setIsDisplay] = useState(true)
  const [imageInfoPc, setImageInfoPc] = useState({ base64: null, file: null, imageUrl: null, name: null })
  const [imageInfoMo, setImageInfoMo] = useState({ base64: null, file: null, imageUrl: null, name: null })
  const [linkPc, setLinkPc] = useState("")
  const [linkMo, setLinkMo] = useState("")
  const [content, setContent] = useState("")
  const closePopup = e => {
    setNoticeTargetEmpty()
    setStartDate(moment())
    setEndDate(moment())
    setType("(PR) News")
    setTitle("")
    setIsDisplay(true)
    setIsDisplay(true)
    setImageInfoPc({ base64: null, file: null, imageUrl: null, name: null })
    setImageInfoMo({ base64: null, file: null, imageUrl: null, name: null })
    setLinkPc("")
    setLinkMo("")
    setContent("")
    onClose()
  }

  //base64
  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  const saveFn = async () => {
    if (Validator.isEmpty(title, { ignore_whitespace: true }) || Validator.isEmpty(content, { ignore_whitespace: true })) {
      return false
    }
    let imgPc = null
    let imgMo = null
    if (imageInfoPc.imageUrl == null && imageInfoPc.file != null) {
      const fomrData = new FormData()
      await fomrData.append("file", imageInfoPc.file)
      imgPc = await postUploadFile(fomrData)
    }
    if (imageInfoMo.imageUrl == null && imageInfoMo.file != null) {
      const fomrData = new FormData()
      await fomrData.append("file", imageInfoMo.file)
      imgMo = await postUploadFile(fomrData)
    }
    let temp = {
      type: TYPE[type],
      subject: title,
      isDisplay: isDisplay ? "Y" : "N",
      emergencyDatetimeStart: moment(startDate).format("YYYY-MM-DD") + " 00:00:00",
      emergencyDatetimeEnd: moment(endDate).format("YYYY-MM-DD") + " 23:59:59",
      imageUrlPc: imgPc ? imgPc : imageInfoPc.imageUrl || "",
      imageUrlMobile: imgMo ? imgMo : imageInfoMo.imageUrl || "",
      linkPc: linkPc,
      linkMobile: linkMo,
      content: content,
    }
    console.log(temp)
    if (dataCheckFn(noticeTarget)) {
      temp.noticeSeq = noticeTarget.noticeSeq
      let result = await putTargetNotifications(temp)
      if (result) {
        closePopup()
        getDate()
      }
    } else {
      let result = await postTargetNotifications(temp)
      if (result) {
        popupFn("저장되었습니다.")
        closePopup()
        getDate()
      }
    }
  }
  useEffect(() => {}, [])

  useEffect(() => {
    if (dataCheckFn(noticeTarget)) {
      setType(TYPE2[noticeTarget.type])
      setTitle(noticeTarget.subject)
      setIsDisplay(noticeTarget.isDisplay == "Y" ? true : false)
      setStartDate(moment(noticeTarget.emergencyDatetimeStart))
      setEndDate(moment(noticeTarget.emergencyDatetimeEnd))
      setImageInfoPc({ ...imageInfoPc, imageUrl: noticeTarget.imageUrlPc })
      setImageInfoMo({ ...imageInfoMo, imageUrl: noticeTarget.imageUrlMobile })
      setLinkPc(noticeTarget.linkPc)
      setLinkMo(noticeTarget.linkMobile)
      setContent(noticeTarget.content)
    }
  }, [open])

  return (
    <LayerPopupLayout open={open} onClose={() => closePopup()} isCloseBtn transparency zIndex={101}>
      <ModalPreviewPopup open={openPreview.open} onClose={() => setOpenPreview({ contents: {}, open: "false" })} contents={openPreview.contents} />

      <div className="permission-info-popup-container layer-popup-container">
        <p className="popup-title mb-3">공지사항 등록/수정</p>

        <div style={{ height: "80%", overflowY: "auto", overflowX: "hidden" }} className="partner-profile-container ">
          <div style={{ width: "100%" }} className="partner-profile-basic-info me-3">
            <div className="partner-profile-basic-info-box-row div-1">
              <div className="partner-profile-basic-info-title-p ">유형</div>
              <div className="partner-profile-basic-info-div">
                <div className="partner-profile-basic-info-div-2">
                  <div className="input-box">
                    <div className="" style={{ width: "180px" }}>
                      <select
                        className="form-select"
                        value={type || ""}
                        onChange={e => {
                          setType(e.target.value)
                        }}
                      >
                        <option className="" value="(PR) News">
                          (PR) News
                        </option>
                        <option className="" value="EVENT안내">
                          EVENT안내
                        </option>
                        <option className="" value="정책변경 안내">
                          정책변경 안내
                        </option>
                        <option className="" value="시스템 안내">
                          시스템 안내
                        </option>
                        <option className="" value="긴급공지 안내">
                          긴급공지 안내
                        </option>
                        <option className="" value="자주하는 질의응답">
                          자주하는 질의응답
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="partner-profile-basic-info-box-row div-1">
              <div className="partner-profile-basic-info-title-p ">제목</div>
              <div className="partner-profile-basic-info-div">
                <div className="partner-profile-basic-info-div-2">
                  <div className="input-box">
                    <input value={title || ""} onChange={e => setTitle(e.target.value)} type="text" className="fgb-input"></input>
                  </div>
                  <div onClick={() => setTitle("")} className="btn fgb-btn-gray fgb-btn-s ms-3">
                    초기화
                  </div>
                </div>
              </div>
            </div>

            <div className="partner-profile-basic-info-box-row div-1">
              <div className="partner-profile-basic-info-title-p ">전시여부</div>
              <div className="partner-profile-basic-info-div">
                <div className="partner-profile-basic-info-div-2">
                  <img onClick={() => setIsDisplay(true)} src={isDisplay ? radio_on : radio_off} />
                  <p>전시</p>
                  <img onClick={() => setIsDisplay(false)} src={!isDisplay ? radio_on : radio_off} />
                  <p>미전시</p>
                  {/* <div className="input-box"></div> */}
                </div>
              </div>
            </div>
            <div className="partner-profile-basic-info-box-row div-1">
              <div className="partner-profile-basic-info-title-p ">긴급공지기간</div>
              <div className="partner-profile-basic-info-div">
                <div className="partner-profile-basic-info-div-2">
                  <div className="fgb-date-form">
                    <img src={icon_calendar} />
                    <div
                      className="fgb-date-input"
                      onClick={() => {
                        setPopupType(false)
                        setCalendarPopup(true)
                      }}
                    >
                      <p>{startDate.isValid() ? startDate.format("YYYY.MM.DD") : "시작일"}</p>
                    </div>
                    {calendarPopup && (
                      <div className="calendar-popup-back">
                        <div className="calendar-popup-div">
                          <CalendarComponent
                            date={date}
                            setDate={setDate}
                            selectedDate={popupType == false ? startDate : endDate}
                            setSelectedDate={popupType == false ? setStartDate : setEndDate}
                            popup={setCalendarPopup}
                          />
                        </div>
                      </div>
                    )}
                    <p>~</p>
                    <img src={icon_calendar} />

                    <div
                      className="fgb-date-input"
                      onClick={() => {
                        setPopupType(true)
                        setCalendarPopup(true)
                      }}
                    >
                      <p>{endDate.isValid() ? endDate.format("YYYY.MM.DD") : "종료일"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="partner-profile-basic-info-box-row div-2">
              <div className="partner-profile-basic-info-title-p ">PC 이미지</div>
              <div className="partner-profile-basic-info-div">
                <div className="partner-profile-basic-info-div-2">
                  <div className="input-box">
                    <input readOnly disabled value={imageInfoPc.name || imageInfoPc.imageUrl || ""} type="text" className="fgb-input"></input>
                  </div>
                  <input
                    id={"gallery_image_input2"}
                    style={{ display: "none" }}
                    type={"file"}
                    onChange={async e => {
                      const file = e.target.files[0]
                      console.log("file!!!", file)
                      if (file !== undefined) {
                        const base64Image = await toBase64(file)

                        let tempList = {}
                        tempList.base64 = base64Image
                        tempList.file = file
                        tempList.name = file.name
                        tempList.imageUrl = null
                        console.log("tempList", tempList)
                        await setImageInfoPc(tempList)
                        e.target.value = ""
                      }
                    }}
                    accept=".gif, .jpg, .png"
                    // accept={"image/*"}
                  />

                  <div onClick={() => document.getElementById("gallery_image_input2").click()} className="btn fgb-btn-m fgb-btn-purple ms-2">
                    <img src={upload} />
                    <p>이미지 업로드</p>
                  </div>
                  <div
                    onClick={() => setOpenPreview({ open: true, contents: imageInfoPc })}
                    style={{ width: "90px" }}
                    className="btn btn-primary font-white fgb-btn-s ms-2"
                  >
                    미리보기
                  </div>
                  <div
                    onClick={() => setImageInfoPc({ base64: null, file: null, imageUrl: null, name: null })}
                    className="btn fgb-btn-gray fgb-btn-s ms-2"
                  >
                    삭제
                  </div>
                </div>
                <div className="partner-profile-basic-info-div-2">
                  <div className="input-box">
                    <input value={linkPc || ""} onChange={e => setLinkPc(e.target.value)} type="text" className="fgb-input"></input>
                  </div>

                  <div
                    onClick={() => window.open(linkPc.indexOf("https://") == -1 ? "https://" + linkPc : linkPc, "_blank")}
                    style={{ width: "90px" }}
                    className="btn btn-primary font-white fgb-btn-s ms-2"
                  >
                    링크 보기
                  </div>
                  <div className="btn fgb-btn-gray fgb-btn-s ms-2">삭제</div>
                </div>
              </div>
            </div>
            <div className="partner-profile-basic-info-box-row div-2">
              <div className="partner-profile-basic-info-title-p ">MO 이미지</div>
              <div className="partner-profile-basic-info-div">
                <div className="partner-profile-basic-info-div-2">
                  <div className="input-box">
                    <input readOnly disabled value={imageInfoMo.name || imageInfoMo.imageUrl || ""} type="text" className="fgb-input"></input>
                  </div>
                  <input
                    id={"gallery_image_input1"}
                    style={{ display: "none" }}
                    type={"file"}
                    onChange={async e => {
                      const file = e.target.files[0]
                      if (file !== undefined) {
                        const base64Image = await toBase64(file)

                        let tempList = {}
                        tempList.base64 = base64Image
                        tempList.file = file
                        tempList.name = file.name
                        tempList.imageUrl = null
                        console.log("tempList", tempList)
                        await setImageInfoMo(tempList)
                        e.target.value = ""
                      }
                    }}
                    accept=".gif, .jpg, .png"
                    // accept={"image/*"}
                  />
                  <div onClick={() => document.getElementById("gallery_image_input1").click()} className="btn fgb-btn-m fgb-btn-purple ms-2">
                    <img src={upload} />
                    <p>이미지 업로드</p>
                  </div>
                  <div
                    onClick={() => setOpenPreview({ open: true, contents: imageInfoMo })}
                    style={{ width: "90px" }}
                    style={{ width: "90px" }}
                    className="btn btn-primary font-white fgb-btn-s ms-2"
                  >
                    미리보기
                  </div>
                  <div
                    onClick={() => setImageInfoMo({ base64: null, file: null, imageUrl: null, name: null })}
                    className="btn fgb-btn-gray fgb-btn-s ms-2"
                  >
                    삭제
                  </div>
                </div>
                <div className="partner-profile-basic-info-div-2">
                  <div className="input-box">
                    <input value={linkMo || ""} onChange={e => setLinkMo(e.target.value)} type="text" className="fgb-input"></input>
                  </div>
                  <div
                    onClick={() => window.open(linkMo.indexOf("https://") == -1 ? "https://" + linkMo : linkMo, "_blank")}
                    style={{ width: "90px" }}
                    className="btn btn-primary font-white fgb-btn-s ms-2"
                  >
                    링크 보기
                  </div>
                  <div className="btn fgb-btn-gray fgb-btn-s ms-2">삭제</div>
                </div>
              </div>
            </div>

            <div className="partner-profile-basic-info-box-row div-3">
              <div className="partner-profile-basic-info-title-p ">내용상세</div>
              <div className="partner-profile-basic-info-div">
                <div className="info-div-textarea">
                  <textarea value={content || ""} onChange={e => setContent(e.target.value)}></textarea>
                  <button onClick={() => setContent("")} style={{ width: "85px" }} className="btn fgb-btn-gray fgb-btn-s margin-btn ms-2 mt-5">
                    초기화
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="permission-info-popup-btn-view">
          <button onClick={() => closePopup()} className="btn btn-primary fgb-btn-gray fgb-btn-l ">
            취소
          </button>
          <button onClick={saveFn} className="btn btn-primary font-white fgb-btn-l ">
            저장
          </button>
        </div>
      </div>
    </LayerPopupLayout>
  )
}

export default OperationContainer(NoticePopup)
