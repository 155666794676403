import React, { useState } from "react"
import img_popup_close_gray from "../images/img_popup_close_gray.svg"

import LayerPopupLayout from "./LayerPopupLayout"
import SignContainer from "../containers/SignContainer"
import Validator from "validator"
import { debounce } from "lodash"
import { dataCheckFn, popupFn } from "../utils/util"

const ProfileEmailChangePopup = props => {
  const { open, onClose, partnerProfile, postAuthEmail, setEmail, putAuthEmail } = props
  const [newEmail, setNewEmail] = useState("")
  const closePopup = e => {
    setNewEmail("")
    onClose(false)
  }
  const sandEmailFn = debounce(async () => {
    if (!Validator.isEmail(newEmail)) {
      return false
    }
    // if (newEmail.indexOf("samlab.co.kr") == -1) {
    //   return false
    // }
    let result = await postAuthEmail({ newEmail: newEmail })
    if (result) {
      popupFn("메일이 발송되었습니다.")
    }
  }, 500)
  const checkEmailFn = debounce(async () => {
    if (!Validator.isEmail(newEmail)) {
      return false
    }
    let result = await putAuthEmail({ newEmail: newEmail })
    if (result) {
      setEmail(newEmail)
      closePopup()
    }
  }, 500)
  return (
    <LayerPopupLayout open={open} onClose={() => closePopup()} isCloseBtn transparency zIndex={101}>
      <div className="pw-email-change-popup-container layer-popup-container">
        <p className="popup-title">이메일 변경</p>
        <div className="pw-email-change-popup-table">
          <div className="pw-email-change-popup-table-row">
            <div className="row-div1">현재 이메일 주소</div>
            <div className="row-div2">
              <div className="input-box">
                <input disabled readOnly value={dataCheckFn(partnerProfile) ? partnerProfile.email : ""} className="fgb-input" />
              </div>
            </div>
          </div>
          <div className="pw-email-change-popup-table-row">
            <div className="row-div1">새 이메일 주소</div>
            <div className="row-div2">
              <div className="input-box">
                <input value={newEmail} onChange={e => setNewEmail(e.target.value)} className="fgb-input" />
              </div>
              <button onClick={() => sandEmailFn()} className="btn btn-primary font-white fgb-btn-m">
                인증요청 메일발송
              </button>
            </div>
          </div>
        </div>
        <p className="pw-email-change-popup-info-text">새 이메일 주소로 발송된 메일을 확인 후 확인 버튼을 눌러주세요</p>
        <div className="pw-email-change-popup-btn-view">
          <button onClick={() => closePopup()} className="btn btn-primary fgb-btn-gray fgb-btn-l ">
            취소
          </button>
          <button onClick={() => checkEmailFn()} className="btn btn-primary font-white fgb-btn-l">
            확인
          </button>
        </div>
      </div>
    </LayerPopupLayout>
  )
}

export default SignContainer(ProfileEmailChangePopup)
