/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect } from "react"

import "swiper/scss"

import Pagination from "../../components/common/pagination"
import CalendarComponent from "../../components/Calendar"
import TopInfo from "../../components/common/TopInfo"

import moment from "moment"

import SEO from "../../components/seo"
import Layout from "../../components/Layout"

import AIMotionPopup from "../../popups/operation/AIMotionPopup"
import LearningRulesPopup from "../../popups/operation/LearningRulesPopup"

import icon_calendar from "../../images/ion_calendar-number-outline.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"

import OperationContainer from "../../containers/OperationContainer"

import PrivateRoute from "../../components/privateRoute"

const AI = props => {
  const {
    getRuleLearnAttitude,
    getRuleAISenstive,
    getRuleLearnAttitudes,
    getRuleAISenstives,
    aiSensitivesEmpty,
    learnAttitudesListEmpty,
    learnAttitudesList,
    aiList,
  } = props
  //date 세팅용 state
  const [calendarPopup, setCalendarPopup] = useState(false)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [date, setDate] = useState(moment())
  const [popupType, setPopupType] = useState(false)

  const [dateType, setDateType] = useState(true)
  const [isFcm, setIsFcm] = useState(true)
  const [isSMS, setIsSMS] = useState(true)
  const [isUesd, setIsUsed] = useState(true)
  const [isUnused, setIsUnused] = useState(true)

  const [step, setStep] = useState(0)

  const [openAIPopup, setOpenAIPopup] = useState(false)
  const [openRulesPopup, setOpenRulesPopup] = useState(false)

  const getData = async () => {
    let temp = {
      dateType: dateType ? "REG_DATE" : "UPDATE_DATE",
      dateStart: startDate.format("YYYY-MM-DD"),
      dateEnd: endDate.format("YYYY-MM-DD"),
      pushType: isFcm && isSMS ? "IN_APP,SMS" : isFcm ? "IN_APP" : isSMS ? "SMS" : "",
      isUse: isUesd && isUnused ? "Y,N" : isUesd ? "Y" : isUnused ? "N" : "",
    }
    if (step == 0) {
      await getRuleAISenstives(temp)
    } else {
      await getRuleLearnAttitudes(temp)
    }
  }
  useEffect(() => {
    return () => {
      aiSensitivesEmpty()
      learnAttitudesListEmpty()
    }
  }, [])
  return (
    <PrivateRoute>
      <Layout>
        <AIMotionPopup getData={getData} open={openAIPopup} onClose={() => setOpenAIPopup(false)} />
        <LearningRulesPopup getData={getData} open={openRulesPopup} onClose={() => setOpenRulesPopup(false)} />
        <SEO title="AI학습규칙 관리" />
        <TopInfo title={["AI·학습 규칙 관리", "운영관리", "AI·학습규칙 관리"]} />

        <div className="operation-learnerType-container">
          <div className="operation-learnerType-table ">
            <div className="operation-learnerType-table-name ">
              <div>조회 기간</div>
            </div>
            <div className="operation-learnerType-table-contents justify-content-between">
              <div className="d-flex align-items-center">
                <img onClick={() => setDateType(true)} className="ms-4 me-2" src={dateType ? radio_on : radio_off} />
                <p>등록일 </p>
                <img onClick={() => setDateType(false)} className="ms-4 me-2" src={!dateType ? radio_on : radio_off} />
                <p>변경일</p>
              </div>
              <div className="fgb-date-form ms-4">
                <img src={icon_calendar} />
                <div
                  className="fgb-date-input"
                  onClick={() => {
                    setPopupType(false)
                    setCalendarPopup(true)
                  }}
                >
                  <p>{startDate.isValid() ? startDate.format("YYYY.MM.DD") : "시작일"}</p>
                </div>
                {calendarPopup && (
                  <div className="calendar-popup-back">
                    <div className="calendar-popup-div">
                      <CalendarComponent
                        date={date}
                        setDate={setDate}
                        selectedDate={popupType == false ? startDate : endDate}
                        setSelectedDate={popupType == false ? setStartDate : setEndDate}
                        popup={setCalendarPopup}
                      />
                    </div>
                  </div>
                )}
                <p>~</p>
                <img src={icon_calendar} />

                <div
                  className="fgb-date-input"
                  onClick={() => {
                    setPopupType(true)
                    setCalendarPopup(true)
                  }}
                >
                  <p>{endDate.isValid() ? endDate.format("YYYY.MM.DD") : "종료일"}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="operation-learnerType-table">
            <div className="operation-learnerType-table-name">
              <div>push 유형</div>
            </div>
            <div className="d-flex align-items-center">
              <img
                onClick={() => {
                  if (isFcm && isSMS) {
                    setIsFcm(false)
                    setIsSMS(false)
                  } else {
                    setIsFcm(true)
                    setIsSMS(true)
                  }
                }}
                className="ms-4 me-2"
                src={isFcm && isSMS ? checked_on : checked_off}
              />
              <p>전체 </p>
              <img onClick={() => setIsFcm(!isFcm)} className="ms-4 me-2" src={isFcm ? checked_on : checked_off} />
              <p>in-app</p>
              <img onClick={() => setIsSMS(!isSMS)} className="ms-3 me-2" src={isSMS ? checked_on : checked_off} />
              <p>SMS</p>
            </div>
            <div className="operation-learnerType-table-name ms-5">
              <div>사용여부</div>
            </div>
            <div className="d-flex align-items-center">
              <img
                onClick={() => {
                  if (isUesd && isUnused) {
                    setIsUsed(false)
                    setIsUnused(false)
                  } else {
                    setIsUsed(true)
                    setIsUnused(true)
                  }
                }}
                className="ms-4 me-2"
                src={isUesd && isUnused ? checked_on : checked_off}
              />
              <p>전체 </p>
              <img onClick={() => setIsUsed(!isUesd)} className="ms-4 me-2" src={isUesd ? checked_on : checked_off} />
              <p>사용</p>
              <img onClick={() => setIsUnused(!isUnused)} className="ms-4 me-2" src={isUnused ? checked_on : checked_off} />
              <p>미사용</p>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <div onClick={getData} className="btn btn-primary font-white fgb-btn-l">
              조회
            </div>
          </div>
          <div className="ai-menu-tab mt-3 mb-3">
            <button onClick={() => setStep(0)} className={step == 0 ? "active" : ""}>
              AI 민감도
            </button>
            <button onClick={() => setStep(1)} className={step == 1 ? "active" : ""}>
              학습규칙
            </button>
          </div>
          {step == 0 && (
            <>
              <div className="d-flex justify-content-between mt-4">
                <p>{aiList.length.toLocaleString()}건</p>
                <div className="d-flex justify-content-end">
                  <div className="btn btn-primary font-white reverse fgb-btn-ms clear-button me-2">선택 삭제</div>
                  <div
                    onClick={() => {
                      // setOpenAIPopup(true)
                    }}
                    className="btn btn-primary font-white  fgb-btn-m me-2"
                  >
                    ai민감도 등록
                  </div>
                </div>
              </div>
              <div className="introduce-study-table mt-3">
                <div className="fgb-table-div">
                  <table className={`fgb-table mb-4`}>
                    <thead>
                      <tr className={`fgb-table-title-tr`}>
                        {[
                          "",
                          "AI 모션 타이틀",
                          "민감도",
                          "Push간격",
                          "Push유형",
                          "Terminate 사용여부",
                          "알림 개수",
                          "사용 여부",
                          "등록자",
                          "등록일",
                          "변경일",
                        ].map((i, idx) => (
                          <td key={idx}>{i}</td>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {aiList.map((i, idx) => (
                        <tr className={`fgb-table-tr `} key={idx}>
                          <td>
                            <img className="ms-4 me-2" src={false ? checked_on : checked_off} />
                          </td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={async () => {
                              await getRuleAISenstive(i.aiSensitiveRuleSeq)
                              setOpenAIPopup(true)
                            }}
                          >
                            {i.title}
                          </td>
                          <td>{i.sensitiveType}</td>
                          <td>{i.sensitiveTime}</td>
                          <td>{i.pushType}</td>
                          <td>{i.isTerminate}</td>
                          <td>{i.alarmCount}</td>
                          <td>{i.isUse}</td>
                          <td>{i.name}</td>
                          <td>{moment(i.regDatetime).format("YYYY-MM-DD")}</td>
                          <td>{i.updateDatetime != "" ? moment(i.updateDatetime).format("YYYY-MM-DD") : ""}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* <Pagination current={current} setCurrent={setCurrent} total={200} /> */}
                </div>
              </div>
            </>
          )}
          {step == 1 && (
            <>
              <div className="d-flex justify-content-between mt-4">
                <p>{learnAttitudesList.length.toLocaleString()}건</p>
                <div className="d-flex justify-content-end">
                  <div className="btn btn-primary font-white reverse fgb-btn-ms clear-button me-2">선택 삭제</div>
                  <div
                    onClick={() => {
                      // setOpenRulesPopup(true)
                    }}
                    className="btn btn-primary font-white fgb-btn-m me-2"
                  >
                    학습 규칙 등록
                  </div>
                </div>
              </div>
              <div className="introduce-study-table mt-3">
                <div className="fgb-table-div">
                  <table className={`fgb-table mb-4`}>
                    <thead>
                      <tr className={`fgb-table-title-tr`}>
                        {["", "학습 규칙 타이틀", "Push유형", "알림 개수", "사용여부", "등록자", "등록일", "변경일"].map((i, idx) => (
                          <td key={idx}>{i}</td>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {learnAttitudesList.map((i, idx) => (
                        <tr className={`fgb-table-tr `} key={idx}>
                          <td>
                            <img className="ms-4 me-2" src={false ? checked_on : checked_off} />
                          </td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={async () => {
                              await getRuleLearnAttitude(i.learnAttitudeRuleSeq)
                              setOpenRulesPopup(true)
                            }}
                          >
                            {i.title}
                          </td>
                          <td>{i.pushType}</td>
                          <td>{i.alarmCount}</td>
                          <td>{i.isUse}</td>
                          <td>{i.name}</td>
                          <td>{moment(i.regDatetime).format("YYYY-MM-DD")}</td>
                          <td>{i.updateDatetime != "" ? moment(i.updateDatetime).format("YYYY-MM-DD") : ""}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* <Pagination current={current2} setCurrent={setCurrent2} total={200} /> */}
                </div>
              </div>
            </>
          )}
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default OperationContainer(AI)
