import React, { useEffect, useState } from "react"
import img_popup_close_gray from "../images/img_popup_close_gray.svg"

import LayerPopupLayout from "./LayerPopupLayout"
import SignContainer from "../containers/SignContainer"
import Validator from "validator"
import { debounce } from "lodash"
import { dataCheckFn } from "../utils/util"

const MyAccountPhoneChangePopup = props => {
  const { open, onClose, setPhone, postAuthNumber, authLog, postAuthNumberCheck } = props
  const [newPhone, setNewPhone] = useState("")
  const [authNo, setAuthNo] = useState("")

  const [minutes, setMinutes] = useState(3)
  const [seconds, setSeconds] = useState(0)
  const [authClick, setAuthClick] = useState(false)
  const [phoneAlerts, setPhoneAlerts] = useState(false)

  const closePopup = e => {
    onClose()
  }
  //코드 요청
  const reqCode = debounce(async () => {
    if (Validator.isMobilePhone(newPhone, "ko-KR")) {
      let result = await postAuthNumber({ phone: newPhone })
      if (result != false) {
        //인증키
        // setuserAuthLogSeq(result.authLogSeq)
        // setIsUser(result.isUser)
        //타이머초기화
        setMinutes(3)
        setSeconds(0)
        //타이머 on
        setAuthClick(true)
        //alerts off
        setPhoneAlerts(false)
        // setUserPhoneNum(phoneNo)
      }
    } else {
      setErrText("휴대전화 번호를 정확히 입력해주세요.")
      setPhoneAlerts(true)
    }
  }, 500)

  const checkPhoneFn = debounce(async () => {
    if (!Validator.isMobilePhone(newPhone, "ko-KR")) {
      return false
    }
    let temp = {
      authLogSeq: authLog.authLogSeq,
      authNumber: authNo,
    }
    let result = await postAuthNumberCheck(temp)
    if (result) {
      setPhone(newPhone)
      closePopup()
    }
  }, 500)
  //타이머
  useEffect(() => {
    const countdown = setInterval(() => {
      if (parseInt(seconds) > 0) {
        setSeconds(parseInt(seconds) - 1)
      }
      if (parseInt(seconds) === 0) {
        if (parseInt(minutes) === 0) {
          clearInterval(countdown)
        } else {
          setMinutes(parseInt(minutes) - 1)
          setSeconds(59)
        }
      }
    }, 1000)
    return () => clearInterval(countdown)
  }, [minutes, seconds])
  return (
    <LayerPopupLayout open={open} onClose={() => closePopup()} isCloseBtn transparency zIndex={101}>
      <div className="pw-email-change-popup-container layer-popup-container">
        <p className="popup-title">휴대전화번호 변경</p>
        <p className="pw-email-change-popup-info-text">변경할 휴대전화번호로 인증을 진행주세요.</p>
        <div className="pw-email-change-popup-table">
          <div className="pw-email-change-popup-table-row">
            <div className="row-div1">새 핸드폰 번호</div>
            <div className="row-div2">
              <div className="input-box">
                <input
                  value={newPhone}
                  placeholder="‘-’ 없이 휴대전화번호를 입력해주세요."
                  onChange={e => setNewPhone(e.target.value)}
                  className="fgb-input"
                />
              </div>
              <button onClick={reqCode} className="btn btn-primary font-white fgb-btn-m">
                {authClick == true ? "인증번호 재요청" : "인증번호 요청"}
              </button>
            </div>
          </div>
          <div className="pw-email-change-popup-table-row">
            <div className="row-div1">인증번호</div>
            <div className="row-div2">
              <div className="input-box">
                <input
                  placeholder={authClick == true ? `${minutes}:${seconds < 10 ? "0" + seconds : seconds}` : "문자로 전송된 인증번호를 입력해주세요."}
                  value={authNo}
                  onChange={e => setAuthNo(e.target.value)}
                  className="fgb-input"
                />
              </div>
              <button onClick={checkPhoneFn} className="btn btn-primary font-white fgb-btn-m">
                인증하기
              </button>
            </div>
          </div>
          {phoneAlerts && (
            <div style={{ marginTop: "3px" }} className="alert alert-danger">
              {errText}
            </div>
          )}
        </div>
        <div className="pw-email-change-popup-btn-view">
          <button onClick={() => closePopup()} className="btn btn-primary fgb-btn-gray fgb-btn-l ">
            취소
          </button>
          <button onClick={checkPhoneFn} className="btn btn-primary font-white fgb-btn-l">
            확인
          </button>
        </div>
      </div>
    </LayerPopupLayout>
  )
}

export default SignContainer(MyAccountPhoneChangePopup)
