import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Validator from "validator"
import CloseBtn from "../images/Close_Button.svg"

const ModalPreviewPopup = props => {
  const { open = false, onClose = () => {}, contents = { base64: null, file: null, imageUrl: null, name: null, fileUrl: null } } = props
  const dispatch = useDispatch()

  return open == true ? (
    <div className="popup-modal-container" style={{ zIndex: 999 }}>
      <div className="popup-modal-div">
        <div className="popup-modal-close-div">
          <button className="btn" onClick={onClose}>
            <img src={CloseBtn}></img>
          </button>
        </div>
        <div className="popup-modal-contents-div">
          <img style={{ width: "200px", height: "150px", objectFit: "cover" }} src={contents.imageUrl || contents.base64 || contents.fileUrl || ""} />
        </div>

        <div className="popup-modal-btn-div">
          <button onClick={onClose} className="btn btn-primary font-white">
            {"확인"}
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  )
}

export default ModalPreviewPopup
