import api from "../utils/api"

// 학습자 소속 그룹 조회
const getStudentGroupList = async data => {
  const result = await api.get(`/student-groups`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 지역 목록 조회
const getRegionList = async data => {
  const result = await api.get(`/study-locations`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 렛미업 상벌점 목록 조회
const getAttitudeList = async data => {
  const result = await api.get(`/learn-attitude-rules`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// AI 카메라 민감도 목록 조회
const getAiList = async data => {
  const result = await api.get(`/ai-sensitive-rules`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 소속 목록 조회
const getBelongingsList = async data => {
  const result = await api.get(`/belongings`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 로그인 유저, 분류 및 이름
const getUserInformation = async data => {
  const result = await api.get(`/login-user/type`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 파일 업로드 +N
const postUploadFiles = async data => {
  const result = await api.post(`/upload/files`, {
    token: true,
    body: data,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

//컨텐츠-브랜드명 및 카테고리
const getContentCategory = async data => {
  const result = await api.get(`/brand-category`, { token: true })
  if (result) {
    return result.data
  } else return false
}

// 컨텐츠 강사 이름 조회
const getContentTeacher = async data => {
  const result = await api.get(`/teacher-names`, {
    token: true,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}
// 공지사항 목록 조회
const getAlarmNotifications = async data => {
  const result = await api.get(`/alarm/notifications`, {
    token: true,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}
// 공지사항 읽음 처리
const postAlarmNotificationsRead = async data => {
  const result = await api.post(`/alarm/notifications/${data}/read`, {
    token: true,
  })
  if (result) {
    return result.data
  } else {
    return false
  }
}

export default {
  getStudentGroupList,
  getRegionList,
  getAttitudeList,
  getAiList,
  getBelongingsList,
  getUserInformation,
  postUploadFiles,
  getContentCategory,
  getContentTeacher,
  getAlarmNotifications,
  postAlarmNotificationsRead,
}
