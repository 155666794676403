/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect, useRef } from "react"

import "swiper/scss"

import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import moment from "moment"
import CalendarComponent from "../../components/Calendar"
import Pagination from "../../components/common/pagination"
import TopInfo from "../../components/common/TopInfo"

import CommonContainer from "../../containers/CommonContainer"
import MemberContainer from "../../containers/MemberContainer"

import { downExcel, toStringByFormatting } from "../../utils/util"

import BasicInfoPopup from "../../popups/member/BasicInfoPopup"
import CreatedStudiesPopup from "../../popups/member/CreatedStudiesPopup"
import RolledInStudiesPopup from "../../popups/member/RolledInStudiesPopup"
import AccumulatedToDoPopup from "../../popups/member/AccumulatedToDoPopup"
import AccumulatedTimePopup from "../../popups/member/AccumulatedTimePopup"
import RankingPopup from "../../popups/member/RankingPopup"
import PointPopup from "../../popups/member/PointPopup"
import PaidPopup from "../../popups/member/PaidPopup"
import FriendPopup from "../../popups/member/FriendPopup"

import icon_calendar from "../../images/ion_calendar-number-outline.svg"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"
import download from "../../images/download.svg"

import PrivateRoute from "../../components/privateRoute"
const MemberInquiry = props => {
  const {
    getUserListExcel,
    userList,
    getUserList,
    studentGroupList,
    regionList,
    getStudentGroupList,
    getRegionList,
    getBelongingsList,
    belongingsList,
  } = props

  const isMounted = useRef(false)
  const [userType, setUserType] = useState()

  // popups
  const [basicInfoPopup, setBasicInfoPopup] = useState(false)
  const [createdStudiesPopup, setCreatedStudiesPopup] = useState(false)
  const [rolledInStudiesPopup, setRolledInStudiesPopup] = useState(false)
  const [accumulatedToDoPopup, setAccumulatedToDoPopup] = useState(false)
  const [accumulatedTimePopup, setAccumulatedTimePopup] = useState(false)
  const [rankingPopup, setRankingPopup] = useState(false)
  const [pointPopup, setPointPopup] = useState(false)
  const [paidPopup, setPaidPopup] = useState(false)
  const [friendPopup, setFriendPopup] = useState(false)

  // popup info
  const [popupInfo, setPopupInfo] = useState(undefined)

  // pagination
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(1)

  //date 세팅용 state
  const [calendarPopup, setCalendarPopup] = useState(false)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [date, setDate] = useState(moment())
  const [popupType, setPopupType] = useState(false)

  // Input 값들
  const [inquiryPeriod, setInquiryPeriod] = useState(0) // 0: 가입일,  1: 최근 접속일
  const [userName, setUserName] = useState(0) // 0: 이름,  1: 스크린 닉네임  2: 휴대전화번호
  const [userInput, setUserInput] = useState("") // 회원 이름칸 Input Box
  const [selectedGroup, setSelectedGroup] = useState("") // 학습자 그룹 드롭박스 기본값: 전체
  const [selectedGrade, setSelectedGrade] = useState("") // 학년 드롭박스 기본값: 전체
  const [selectedAffiliation, setSelectedAffiliation] = useState("") // 소속 드롭박스 기본값: 전체
  const [selectedAffiliationSeq, setSelectedAffiliationSeq] = useState("")
  const [selectedRegion, setSelectedRegion] = useState("") // 지역 드롭박스 기본값: 전체
  const [selectedAccumulatedTime, setSelectedAccumulatedTime] = useState("") // 누적시간 드롭박스 기본값: 전체
  const [accumulatedTime, setAccumulatedTime] = useState(0) // 누적 시간 Input Box
  const [achievementRate, setAchievementRate] = useState(0) // 달성률 Input Box
  const [points, setPoints] = useState(0) // 포인트 Input Box
  const [receiveMarketing, setReceiveMarketing] = useState({
    // 마케팅 수신 체크박스
    all: true,
    agree: true,
    disagree: true,
  })
  const [receiveMarketingValue, setReceiveMarketingValue] = useState("ALL")

  // useEffect(() => {
  //   if (receiveMarketing.all === true) {
  //     setReceiveMarketing({ all: true, agree: true, disagree: true })
  //     setReceiveMarketingValue("ALL")
  //   } else {
  //     setReceiveMarketing({ all: false, agree: false, disagree: false })
  //     setReceiveMarketingValue("")
  //   }
  // }, [receiveMarketing.all])
  useEffect(() => {
    if (receiveMarketing.agree) {
      if (receiveMarketing.disagree) {
        setReceiveMarketing({ all: true, agree: true, disagree: true })
        setReceiveMarketingValue("ALL")
      } else {
        setReceiveMarketing({ all: false, agree: true, disagree: false })
        setReceiveMarketingValue("Y")
      }
    } else {
      if (receiveMarketing.disagree) {
        setReceiveMarketing({ all: false, agree: false, disagree: true })
        setReceiveMarketingValue("N")
      } else {
        setReceiveMarketing({ all: false, agree: false, disagree: false })
        setReceiveMarketingValue("")
      }
    }
  }, [receiveMarketing.agree, receiveMarketing.disagree])

  // 결과 테이블
  const [allCheck, setAllCheck] = useState(false)

  // 학생 소속 드롭박스 값들
  const [studentGroupSeqLevel1, setStudentGroupSeqLevel1] = useState([])
  const [studentGroupSeqLevel2, setStudentGroupSeqLevel2] = useState([])

  useEffect(() => {
    async function getLists() {
      await getStudentGroupList()
      await getRegionList()
      await getBelongingsList()
    }

    async function ResetUserList() {
      await getUserList()
    }

    getLists()
    setUserType(localStorage.loginUserType)

    return () => {
      ResetUserList()
    }
  }, [])

  // student Group
  useEffect(() => {
    setStudentGroupSeqLevel1(studentGroupList)
  }, [studentGroupList])
  useEffect(() => {
    if (studentGroupSeqLevel1 && studentGroupSeqLevel1?.studentGroups) {
      setStudentGroupSeqLevel2((studentGroupSeqLevel1?.studentGroups.filter(i => i?.level1?.level1Seq === selectedGroup))[0]?.level1?.level2)
    }
  }, [selectedGroup])

  // pagination 페이지 바꿀 때
  useEffect(() => {
    if (isMounted.current) {
      handleSearch(current - 1)
    } else {
      isMounted.current = true
    }
  }, [current])

  useEffect(() => {
    setTotal(userList?.totalCount)
  }, [userList])

  const handleSearch = async num => {
    if (endDate < startDate) {
      alert("조회 기간을 제대로 설정해주세요.")
    } else {
      const date_type = inquiryPeriod === 0 ? "REGISTRATION" : "RECENTLY_ACCESS"
      const start_date = toStringByFormatting(startDate)
      const end_date = toStringByFormatting(endDate)
      const student_group_seq_level_1 = selectedGroup
      const student_group_seq_level_2 = selectedGrade
      const belonging_type = belongingsList?.belongings.filter(i => i.belongingSeq === selectedAffiliationSeq)[0]?.belongingType
      const belonging_seq = selectedAffiliationSeq
      const word_type = userName === 0 ? "NAME" : userName === 1 ? "NICKNAME" : "PHONE"
      const word_ = userInput
      const region_3 = selectedRegion
      const marketing_type = receiveMarketingValue
      const accumulated_study_time = accumulatedTime
      const achievement_rate = achievementRate
      const point_ = points

      console.log(`
        date_type: ${date_type}
        start_date: ${start_date}
        end_date: ${end_date}
        student_group_seq_level_1: ${student_group_seq_level_1}
        student_group_seq_level_2: ${student_group_seq_level_2}
        belonging_type: ${belonging_type}
        belonging_seq: ${belonging_seq}
        word_type: ${word_type}
        word_: ${word_}
        region_3: ${region_3}
        marketing_type: ${marketing_type}
        accumulated_study_time: ${accumulated_study_time}
        achievement_rate: ${achievement_rate}
        point_: ${point_}
      `)

      if (accumulated_study_time === undefined || achievement_rate === undefined || point_ === undefined) {
        if (accumulated_study_time === undefined) {
          alert("누적 시간을 입력해주세요.")
        } else if (achievement_rate === undefined) {
          alert("달성률을 입력해주세요.")
        } else if (point_ === undefined) {
          alert("포인트를 입력해주세요.")
        }
      } else {
        const result = await getUserList({
          dateType: date_type,
          startDate: start_date,
          endDate: end_date,
          studentGroupSeqLevel1: student_group_seq_level_1,
          studentGroupSeqLevel2: student_group_seq_level_2,
          belongingType: belonging_type,
          belongingSeq: belonging_seq,
          wordType: word_type,
          word: word_,
          region3: region_3,
          marketingType: marketing_type,
          accumulatedStudyTime: accumulated_study_time,
          achievementRate: achievement_rate,
          point: point_,
          page: num,
          count: 15,
        })
      }
    }
  }
  const getUserListExcelFn = async num => {
    if (endDate < startDate) {
      alert("조회 기간을 제대로 설정해주세요.")
    } else {
      const date_type = inquiryPeriod === 0 ? "REGISTRATION" : "RECENTLY_ACCESS"
      const start_date = toStringByFormatting(startDate)
      const end_date = toStringByFormatting(endDate)
      const student_group_seq_level_1 = selectedGroup
      const student_group_seq_level_2 = selectedGrade
      const belonging_type = belongingsList?.belongings.filter(i => i.belongingSeq === selectedAffiliationSeq)[0]?.belongingType
      const belonging_seq = selectedAffiliationSeq
      const word_type = userName === 0 ? "NAME" : userName === 1 ? "NICKNAME" : "PHONE"
      const word_ = userInput
      const region_3 = selectedRegion
      const marketing_type = receiveMarketingValue
      const accumulated_study_time = accumulatedTime
      const achievement_rate = achievementRate
      const point_ = points

      // console.log(`
      //   date_type: ${date_type}
      //   start_date: ${start_date}
      //   end_date: ${end_date}
      //   student_group_seq_level_1: ${student_group_seq_level_1}
      //   student_group_seq_level_2: ${student_group_seq_level_2}
      //   belonging_type: ${belonging_type}
      //   belonging_seq: ${belonging_seq}
      //   word_type: ${word_type}
      //   word_: ${word_}
      //   region_3: ${region_3}
      //   marketing_type: ${marketing_type}
      //   accumulated_study_time: ${accumulated_study_time}
      //   achievement_rate: ${achievement_rate}
      //   point_: ${point_}
      // `)

      if (accumulated_study_time === undefined || achievement_rate === undefined || point_ === undefined) {
        if (accumulated_study_time === undefined) {
          alert("누적 시간을 입력해주세요.")
        } else if (achievement_rate === undefined) {
          alert("달성률을 입력해주세요.")
        } else if (point_ === undefined) {
          alert("포인트를 입력해주세요.")
        }
      } else {
        const result = await getUserListExcel({
          dateType: date_type,
          startDate: start_date,
          endDate: end_date,
          studentGroupSeqLevel1: student_group_seq_level_1,
          studentGroupSeqLevel2: student_group_seq_level_2,
          belongingType: belonging_type,
          belongingSeq: belonging_seq,
          wordType: word_type,
          word: word_,
          region3: region_3,
          marketingType: marketing_type,
          accumulatedStudyTime: accumulated_study_time,
          achievementRate: achievement_rate,
          point: point_,
          page: current - 1,
          count: 15,
        })
        if (result) {
          await downExcel({ result: result, fileName: "회원 목록" })
        }
      }
    }
  }

  const InquiryPeriodOnClick = type => {
    setInquiryPeriod(type)
  }
  const UserNameOnClick = type => {
    setUserName(type)
  }

  return (
    <PrivateRoute>
      <>
        {basicInfoPopup && popupInfo != undefined && (
          <BasicInfoPopup
            userSeq={popupInfo}
            type={0}
            onClose={() => {
              setBasicInfoPopup(false)
              setPopupInfo(undefined)
            }}
          />
        )}
        {createdStudiesPopup && popupInfo != undefined && (
          <CreatedStudiesPopup
            userSeq={popupInfo}
            onClose={() => {
              setCreatedStudiesPopup(false)
              setPopupInfo(undefined)
            }}
          />
        )}
        {rolledInStudiesPopup && popupInfo != undefined && (
          <RolledInStudiesPopup
            userSeq={popupInfo}
            onClose={() => {
              setRolledInStudiesPopup(false)
              setPopupInfo(undefined)
            }}
          />
        )}
        {accumulatedToDoPopup && popupInfo != undefined && (
          <AccumulatedToDoPopup
            userSeq={popupInfo}
            onClose={() => {
              setAccumulatedToDoPopup(false)
              setPopupInfo(undefined)
            }}
          />
        )}
        {accumulatedTimePopup && popupInfo != undefined && (
          <AccumulatedTimePopup
            userSeq={popupInfo}
            onClose={() => {
              setAccumulatedTimePopup(false)
              setPopupInfo(undefined)
            }}
          />
        )}
        {rankingPopup && popupInfo != undefined && (
          <RankingPopup
            userSeq={popupInfo}
            open={rankingPopup}
            onClose={() => {
              setRankingPopup(false)
              setPopupInfo(undefined)
            }}
          />
        )}
        {pointPopup && popupInfo != undefined && (
          <PointPopup
            open={PointPopup}
            userSeq={popupInfo}
            onClose={() => {
              setPointPopup(false)
              setPopupInfo(undefined)
            }}
          />
        )}
        {paidPopup && popupInfo != undefined && (
          <PaidPopup
            open={paidPopup}
            userSeq={popupInfo}
            onClose={() => {
              setPaidPopup(false)
              setPopupInfo(undefined)
            }}
          />
        )}
        {friendPopup && popupInfo != undefined && (
          <FriendPopup
            open={friendPopup}
            userSeq={popupInfo}
            onClose={() => {
              setFriendPopup(false)
              setPopupInfo(undefined)
            }}
          />
        )}
        <Layout>
          <SEO title="회원조회" />
          <TopInfo title={["회원 조회", "회원 관리", "회원 조회"]} />
          <div className="member-inquiry-container">
            <div className="member-inquiry-table-wrapper">
              <div className="table-row bottom-border">
                <div className="element-l right-border">조회 기간</div>
                <div className="element-r right-border big-box">
                  <div className="radio-btn-wrapper" onClick={() => InquiryPeriodOnClick(0)}>
                    <img src={inquiryPeriod == 0 ? radio_on : radio_off} />
                    <p>가입일</p>
                  </div>
                  <div className="radio-btn-wrapper" onClick={() => InquiryPeriodOnClick(1)}>
                    <img src={inquiryPeriod == 1 ? radio_on : radio_off} />
                    <p>최근 접속일</p>
                  </div>
                  <div className="fgb-date-form">
                    <img src={icon_calendar} />
                    <div
                      className="fgb-date-input"
                      onClick={() => {
                        setPopupType(false)
                        setCalendarPopup(true)
                      }}
                    >
                      <p>{startDate.isValid() ? startDate.format("YYYY.MM.DD") : "시작일"}</p>
                    </div>
                    {calendarPopup && (
                      <div className="calendar-popup-back">
                        <div className="calendar-popup-div">
                          <CalendarComponent
                            date={date}
                            setDate={setDate}
                            selectedDate={popupType == false ? startDate : endDate}
                            setSelectedDate={popupType == false ? setStartDate : setEndDate}
                            popup={setCalendarPopup}
                          />
                        </div>
                      </div>
                    )}
                    <p>~</p>
                    <img src={icon_calendar} />
                    <div
                      className="fgb-date-input"
                      onClick={() => {
                        setPopupType(true)
                        setCalendarPopup(true)
                      }}
                    >
                      <p>{endDate.isValid() ? endDate.format("YYYY.MM.DD") : "종료일"}</p>
                    </div>
                  </div>
                </div>
                <div className="element-l right-border">학습자 그룹</div>
                <div className="element-r no-border small-box">
                  <div style={{ width: "188px" }}>
                    <select
                      className="form-select"
                      style={{ backgroundColor: "#F2F8FC", border: "none", cursor: "pointer" }}
                      onChange={e => setSelectedGroup(e.target.value)}
                    >
                      <option className="" value="">
                        전체
                      </option>
                      {studentGroupSeqLevel1 &&
                        studentGroupSeqLevel1?.studentGroups &&
                        studentGroupSeqLevel1?.studentGroups.map((i, idx) => (
                          <option value={i?.level1?.level1Seq} key={idx}>
                            {i?.level1?.level1Name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="table-row bottom-border">
                <div className="element-l right-border">회원 이름</div>
                <div className="element-r right-border big-box">
                  <div className="radio-btn-wrapper" onClick={() => UserNameOnClick(0)}>
                    <img src={userName == 0 ? radio_on : radio_off} />
                    <p>이름</p>
                  </div>
                  <div className="radio-btn-wrapper" onClick={() => UserNameOnClick(1)}>
                    <img src={userName == 1 ? radio_on : radio_off} />
                    <p>스크린 닉네임</p>
                  </div>
                  <div className="radio-btn-wrapper" onClick={() => UserNameOnClick(2)}>
                    <img src={userName == 2 ? radio_on : radio_off} />
                    <p>휴대전화번호</p>
                  </div>
                  <input className="user-info-input-box" value={userInput} onChange={e => setUserInput(e.target.value)} />
                </div>
                <div className="element-l right-border">학년</div>
                <div className="element-r no-border small-box">
                  <div style={{ width: "188px" }}>
                    <select
                      className="form-select"
                      style={{ backgroundColor: "#F2F8FC", border: "none", cursor: "pointer" }}
                      onChange={e => setSelectedGrade(e.target.value)}
                    >
                      <option className="" value="">
                        전체
                      </option>
                      {studentGroupSeqLevel2 &&
                        studentGroupSeqLevel2.map((i, idx) => (
                          <option value={i.level2Seq} key={idx}>
                            {i.level2Name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="table-row bottom-border">
                <div className="element-l right-border">소속</div>
                <div className="element-r right-border small-box">
                  <div style={{ width: "188px" }}>
                    <select
                      className="form-select"
                      style={{ backgroundColor: "#F2F8FC", border: "none", cursor: "pointer" }}
                      onChange={e => setSelectedAffiliationSeq(e.target.value)}
                    >
                      {belongingsList &&
                        belongingsList?.belongings &&
                        belongingsList?.belongings.map((i, idx) => (
                          <option value={i?.belongingSeq} key={idx}>
                            {i?.belongingType === "ALL" ? "전체" : i?.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="element-l right-border">지역</div>
                <div className="element-r right-border small-box">
                  <div style={{ width: "188px" }}>
                    <select
                      className="form-select"
                      style={{ backgroundColor: "#F2F8FC", border: "none", cursor: "pointer" }}
                      onChange={e => setSelectedRegion(e.target.value)}
                    >
                      <option className="" value="">
                        전체
                      </option>
                      {regionList &&
                        regionList?.studyLocations &&
                        regionList?.studyLocations?.region3.map((i, idx) => (
                          <option value={i} key={idx}>
                            {i}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="element-l right-border">마케팅 수신</div>
                <div className="element-r no-border small-box">
                  <div
                    className="check-box-wrapper"
                    onClick={() => {
                      if (receiveMarketing.agree && receiveMarketing.disagree) {
                        setReceiveMarketing({ agree: false, disagree: false })
                      } else {
                        setReceiveMarketing({ agree: true, disagree: true })
                      }

                      setReceiveMarketing({ ...receiveMarketing, all: !receiveMarketing.all })
                    }}
                  >
                    <img src={receiveMarketing.agree && receiveMarketing.disagree ? checked_on : checked_off} />
                    <p>전체</p>
                  </div>
                  <div className="check-box-wrapper" onClick={() => setReceiveMarketing({ ...receiveMarketing, agree: !receiveMarketing.agree })}>
                    <img src={receiveMarketing.agree ? checked_on : checked_off} />
                    <p>동의</p>
                  </div>
                  <div
                    className="check-box-wrapper"
                    onClick={() => setReceiveMarketing({ ...receiveMarketing, disagree: !receiveMarketing.disagree })}
                  >
                    <img src={receiveMarketing.disagree ? checked_on : checked_off} />
                    <p>미동의</p>
                  </div>
                </div>
              </div>
              <div className="table-row no-border">
                <div className="element-l right-border">누적 시간</div>
                <div className="element-r right-border small-box">
                  <input className="last-row-input-box" value={accumulatedTime} onChange={e => setAccumulatedTime(e.target.value)} />
                </div>
                <div className="element-l right-border">달성률&#40;%&#41;</div>
                <div className="element-r right-border small-box">
                  <input className="last-row-input-box" value={achievementRate} onChange={e => setAchievementRate(e.target.value)} />
                </div>
                <div className="element-l right-border">포인트</div>
                <div className="element-r no-border small-box">
                  <input className="last-row-input-box" value={points} onChange={e => setPoints(e.target.value)} />
                </div>
              </div>
            </div>

            <div className="search-btn-wrapper">
              <div className="btn btn-primary font-white fgb-btn-l" onClick={() => handleSearch(0)}>
                조회
              </div>
            </div>

            <div className="result-info-wrapper">
              <div className="people-num">{userList?.totalCount ? userList.totalCount : 0}명</div>
              <div onClick={getUserListExcelFn} className="excel-download-btn btn fgb-btn-m fgb-btn-grean" style={{ borderRadius: "6px " }}>
                <img src={download} />
                <p>엑셀 다운로드</p>
              </div>
            </div>

            {userList?.users !== undefined && userList?.users.length !== 0 ? (
              <div className="fgb-table-div">
                <table className={`fgb-table`}>
                  <thead>
                    <tr className={`fgb-table-title-tr`}>
                      <td>No.</td>
                      {TITLE.map((i, idx) => (
                        <td key={idx}>{i}</td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {userList?.users.map((i, idx) => (
                      <tr className={`fgb-table-tr`} key={idx}>
                        <td>{(current - 1) * 15 + idx + 1}</td>
                        <td>{i?.studentGroupName}</td>
                        <td>{i?.studentGrade}</td>
                        <td>{i?.belongingName}</td>
                        <td
                          onClick={() => {
                            setPopupInfo(i?.seq)
                            setBasicInfoPopup(true)
                          }}
                          className="td-with-onclick"
                        >
                          {i?.name}
                        </td>
                        <td
                          onClick={() => {
                            setPopupInfo(i?.seq)
                            setBasicInfoPopup(true)
                          }}
                        >
                          {i?.nickname}
                        </td>
                        <td>{i?.phone}</td>
                        <td>{i?.signupDatetime}</td>
                        <td>{i?.recentlyAccessDatetime}</td>
                        <td
                          onClick={() => {
                            setPopupInfo(i?.seq)
                            setCreatedStudiesPopup(true)
                          }}
                          className="td-with-onclick"
                        >
                          {i?.createStudyCount}
                        </td>
                        <td
                          onClick={() => {
                            setPopupInfo(i?.seq)
                            setRolledInStudiesPopup(true)
                          }}
                          className="td-with-onclick"
                        >
                          {i?.joinStudyCount}
                        </td>
                        <td
                          onClick={() => {
                            setPopupInfo(i?.seq)
                            setAccumulatedToDoPopup(true)
                          }}
                          className="td-with-onclick"
                        >
                          {i?.createTodoCount}
                        </td>
                        <td
                          onClick={() => {
                            setPopupInfo(i?.seq)
                            setAccumulatedTimePopup(true)
                          }}
                          className="td-with-onclick"
                        >
                          {i?.accumulatedStudyTime}
                        </td>
                        <td
                          onClick={() => {
                            setPopupInfo(i?.seq)
                            setRankingPopup(true)
                          }}
                          className="td-with-onclick"
                        >
                          {i?.ranking}
                        </td>
                        <td>{i?.achievementRate ? Math.round(i.achievementRate * 10) / 10 : ""}</td>
                        <td
                          onClick={() => {
                            setPopupInfo(i?.seq)
                            setPointPopup(true)
                          }}
                          className="td-with-onclick"
                        >
                          {i?.point}
                        </td>
                        <td
                          onClick={() => {
                            setPopupInfo(i?.seq)
                            setPaidPopup(true)
                          }}
                          className="td-with-onclick"
                        >
                          {i?.accumulatedPayAmount}
                        </td>
                        <td
                          onClick={() => {
                            setPopupInfo(i?.seq)
                            setFriendPopup(true)
                          }}
                          className="td-with-onclick"
                        >
                          {i?.friendCount}
                        </td>
                        <td>{i?.region3}</td>
                        <td>{i?.email}</td>
                        <td>{i?.marketingType}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <>
                {/* <div className="search-none-wrapper">
            <p>조회 결과가 없습니다.</p>
            <p>원하는 항목을 선택하고 '조회' 버튼을 눌러주십시오.</p>
          </div> */}
              </>
            )}
            <Pagination count={15} current={current} setCurrent={setCurrent} total={total} />
          </div>
        </Layout>
      </>
    </PrivateRoute>
  )
}

export default MemberContainer(CommonContainer(MemberInquiry))

const TITLE = [
  "학습자 그룹",
  "학년",
  "소속",
  "이름",
  "닉네임",
  "휴대전화번호",
  "가입일",
  "최근 접속일",
  "만든 캠스터디",
  "참여한 캠스터디",
  "누적 TO-DO",
  "누적 시간",
  "순위",
  "달성률(%)",
  "포인트(P)",
  "결제(₩)",
  "친구",
  "지역",
  "이메일",
  "마케팅 수신",
]
