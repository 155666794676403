/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect, useRef } from "react"

import "swiper/scss"

import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import moment from "moment"
import CalendarComponent from "../../components/Calendar"
import TopInfo from "../../components/common/TopInfo"
import Pagination from "../../components/common/pagination"
import ItemPopup from "../../popups/service/ItemPopup"
import ContentPopup from "../../popups/service/ContentPopup"

import CommonContainer from "../../containers/CommonContainer"
import ServiceContainer from "../../containers/ServiceContainer"

import { toStringByFormatting } from "../../utils/util"

import icon_calendar from "../../images/ion_calendar-number-outline.svg"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"
import download from "../../images/download.svg"
import PrivateRoute from "../../components/privateRoute"
import { navigate } from "gatsby"
import { downExcel } from "../../utils/util"
const ServiceInquiry = props => {
  const {
    getDecorationItemList,
    decorationItemList,
    getContentList,
    contentList,
    getContentCategory,
    contentCategory,
    getStudentGroupList,
    studentGroupList,
    getContentTeacher,
    contentTeacher,
    getDecorationItemListExcel,
    getContentListExcel,
  } = props

  const isMounted = useRef(false)

  // popups
  const [itemPopup, setItemPopup] = useState(false)
  const [contentPopup, setContentPopup] = useState(false)
  const [popupInfo, setPopupInfo] = useState(undefined)

  // 0이면 컨텐츠, 1이면 아이템
  const [currentType, setCurrentType] = useState(0)

  // pagination
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(1)

  const [currentContent, setCurrentContent] = useState(1)
  const [totalContent, setTotalContent] = useState(1)

  // date 세팅용 state
  // 컨텐츠, 꾸미기 아이템 공용
  const [calendarPopup, setCalendarPopup] = useState(false)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [date, setDate] = useState(moment())
  const [popupType, setPopupType] = useState(false)

  // 컨텐츠
  const [inquiryPeriod, setInquiryPeriod] = useState(0) // 0: 등록일,  1: 변경일
  const [brandName, setBrandName] = useState("") // 브랜드명 드롭다운 기본값: 전체
  const [category1, setCategory1] = useState("") // 카테고리 첫 번째 드롭다운 기본값: 전체
  const [category2, setCategory2] = useState("") // 카테고리 두 번째 드롭다운 기본값: 전체
  const [category3, setCategory3] = useState("") // 카테고리 세 번째 드롭다운 기본값: 전체
  const [learningGroup, setLearningGroup] = useState("") // 학습자 그룹 드롭다운 기본값: 전체
  const [teacherName, setTeacherName] = useState("") // 강사 이름 드롭다운 기본값: 전체
  const [priceFrom, setPriceFrom] = useState(0) // 가격 Input 박스 첫 번째 입력값 기본값: 0
  const [priceTo, setPriceTo] = useState(0) // 가격 Input 박스 두 번째 입력값 기본값: 0
  const [type, setType] = useState({ regular: true, plan: true }) // 유형 0: 전체,  1: 정규,  2: 기획
  const [display, setDisplay] = useState({ y: true, n: true }) // 화면 표시 0: 전체,  1: 전시,  2: 미전시
  const [price, setPrice] = useState({ partner: true, letmeup: true }) // 가격 0: 전체,  1: 제휴사,  2: 렛미업
  const [photo, setPhoto] = useState({ y: true, n: true }) // 강사 사진 0: 전체,  1: 등록,  2: 미등록
  const [url, setUrl] = useState({ y: true, n: true }) // 홍보 URL 0: 전체,  1: 있음  2: 없음

  const [teacherList, setTeacherList] = useState([])
  const [categoryLevel1, setCategoryLevel1] = useState([])
  const [categoryLevel2, setCategoryLevel2] = useState([])
  const [categoryLevel3, setCategoryLevel3] = useState([])

  // 꾸미기 아이템
  const [sortation, setSortation] = useState({ pay: true, free: true }) // 구분: (0: 전체,  1: 유료,  2: 무료)
  const [kind, setKind] = useState({ filter: true, sticker: true }) // 유형: (0: 전체,  1: 필터,  2: 스티커)
  const [pointFrom, setPointFrom] = useState(0) // 포인트 Input 박스 첫 번째 입력값 기본값: 0
  const [pointTo, setPointTo] = useState(0) // 포인트 Input 박스 두 번째 입력값 기본값: 0

  useEffect(() => {
    async function getLists() {
      await getStudentGroupList()
      await getContentCategory()
      await getContentTeacher()
    }

    async function resetLists() {
      await getDecorationItemList()
      // await getContentList()
    }

    getLists()

    return () => {
      resetLists()
    }
  }, [])

  useEffect(() => {
    setCategoryLevel1(contentCategory?.contentCategories)
  }, [contentCategory])

  useEffect(() => {
    if (contentCategory) {
      setCategoryLevel2(categoryLevel1.filter(i => i?.depth1Name === category1)[0]?.depth2)
    }
  }, [category1])

  useEffect(() => {
    if (contentCategory && categoryLevel2 && categoryLevel1) {
      setCategoryLevel3(categoryLevel2.filter(i => i?.depth2Name === category2)[0]?.depth3)
    }
  }, [category2])

  useEffect(() => {
    if (brandName !== "") {
      if (contentTeacher?.teacherNames) {
        let index_ = []
        for (let i = 0; i < contentTeacher?.teacherNames.length; i++) {
          if (contentTeacher?.teacherNames[i]?.brandName === brandName) {
            index_.push(contentTeacher?.teacherNames[i]?.teacherName)
          }
        }
        console.log(index_)
        setTeacherList(index_)
      }
    } else {
      setTeacherList([])
    }
  }, [brandName])

  const TypeFn = type => {
    setCurrentType(type)
  }
  const InquiryPeriodOnClick = type => {
    setInquiryPeriod(type)
  }
  useEffect(() => {
    setDate(moment())
    setStartDate(moment())
    setEndDate(moment())
    setDisplay({ y: true, n: true })
  }, [currentType])

  // pagination 페이지 바꿀 때
  useEffect(() => {
    if (isMounted.current) {
      handleSearch(current - 1)
    } else {
      isMounted.current = true
    }
  }, [current])

  useEffect(() => {
    if (isMounted.current) {
      handleSearch(currentContent - 1)
    } else {
      isMounted.current = true
    }
  }, [currentContent])

  useEffect(() => {
    setTotal(decorationItemList?.totalCount)
  }, [decorationItemList])

  useEffect(() => {
    setTotalContent(contentList?.totalCount)
  }, [contentList])

  const handleSearch = async page => {
    if (currentType === 0) {
      // 현재 선택된 값 컨텐츠일 때
      if (startDate > endDate) {
        alert("날짜를 확인해주세요.")
      } else {
        const date_start = toStringByFormatting(startDate)
        const date_end = toStringByFormatting(endDate)
        const type_ = type.regular && type.plan ? "" : type.regular ? "REGULAR" : type.plan ? "PLAN" : ""
        const is_display = display.y && display.n ? "" : display.y ? "Y" : display.n ? "N" : ""
        const brand_name = brandName
        const category_depth_1 = category1
        const category_depth_2 = category2
        const category_depth_3 = category3
        const student_group_seq = learningGroup
        const price_type = price.partner && price.letmeup ? "" : price.partner ? "PARTNER" : price.letmeup ? "LETMEUP" : ""
        const min_price = priceFrom
        const max_price = priceTo
        const teacher_name = teacherName
        const is_teacher_photo = photo.y && photo.n ? "" : photo.y ? "Y" : photo.n ? "N" : ""
        const is_video_url = url.y && url.n ? "" : url.y ? "Y" : url.n ? "N" : ""
        const page_ = page

        console.log(`
          dateStart: ${date_start}
          dateEnd: ${date_end}
          type: ${type_}
          isDisplay: ${is_display}
          brandName: ${brand_name}
          categoryDepth1: ${category_depth_1}
          categoryDepth2: ${category_depth_2}
          categoryDepth3: ${category_depth_3}
          studentGroupSeq: ${student_group_seq}
          priceType: ${price_type}
          minPrice: ${min_price}
          maxPrice: ${max_price}
          teacherName: ${teacher_name}
          isTeacherPhoto: ${is_teacher_photo}
          isVideoUrl: ${is_video_url}
          page: ${page_}
        `)

        const result = await getContentList({
          dateStart: date_start,
          dateEnd: date_end,
          type: type_,
          isDisplay: is_display,
          brandName: brand_name,
          categoryDepth1: category_depth_1,
          categoryDepth2: category_depth_2,
          categoryDepth3: category_depth_3,
          studentGroupSeq: student_group_seq,
          priceType: price_type,
          minPrice: min_price,
          maxPrice: max_price,
          teacherName: teacher_name,
          isTeacherPhoto: is_teacher_photo,
          isVideoUrl: is_video_url,
          page: page_,
        })
      }
    } else {
      // 꾸미기 아이템일 때
      if (startDate > endDate) {
        alert("날짜를 확인해주세요.")
      } else {
        const date_start = toStringByFormatting(startDate)
        const date_end = toStringByFormatting(endDate)
        const payment_type = sortation.pay && sortation.free ? "" : sortation.pay ? "PAY" : sortation.free ? "FREE" : ""
        const is_display = display.y && display.n ? "" : display.y ? "Y" : display.n ? "N" : ""
        const type_ = kind.filter && kind.sticker ? "" : kind.filter ? "FILTER" : kind.sticker ? "STICKER" : ""
        const min_point = pointFrom
        const max_point = pointTo
        const page_ = page

        console.log(`
          dateStart: ${date_start}
          dateEnd: ${date_end}
          paymentType: ${payment_type}
          isDisplay: ${is_display}
          type: ${type_}
          minPoint: ${min_point}
          maxPoint: ${max_point}
          page: ${page_}
        `)

        const result = await getDecorationItemList({
          dateStart: date_start,
          dateEnd: date_end,
          paymentType: payment_type,
          isDisplay: is_display,
          type: type_,
          minPoint: min_point,
          maxPoint: max_point,
          page: page_,
        })
      }
    }
  }

  const ExcelFn = async () => {
    let result = undefined
    if (currentType === 0) {
      // 현재 선택된 값 컨텐츠일 때
      if (startDate > endDate) {
        alert("날짜를 확인해주세요.")
      } else {
        const date_start = toStringByFormatting(startDate)
        const date_end = toStringByFormatting(endDate)
        const type_ = type.regular && type.plan ? "" : type.regular ? "REGULAR" : type.plan ? "PLAN" : ""
        const is_display = display.y && display.n ? "" : display.y ? "Y" : display.n ? "N" : ""
        const brand_name = brandName
        const category_depth_1 = category1
        const category_depth_2 = category2
        const category_depth_3 = category3
        const student_group_seq = learningGroup
        const price_type = price.partner && price.letmeup ? "" : price.partner ? "PARTNER" : price.letmeup ? "LETMEUP" : ""
        const min_price = priceFrom
        const max_price = priceTo
        const teacher_name = teacherName
        const is_teacher_photo = photo.y && photo.n ? "" : photo.y ? "Y" : photo.n ? "N" : ""
        const is_video_url = url.y && url.n ? "" : url.y ? "Y" : url.n ? "N" : ""
        const page_ = current - 1

        console.log(`
          dateStart: ${date_start}
          dateEnd: ${date_end}
          type: ${type_}
          isDisplay: ${is_display}
          brandName: ${brand_name}
          categoryDepth1: ${category_depth_1}
          categoryDepth2: ${category_depth_2}
          categoryDepth3: ${category_depth_3}
          studentGroupSeq: ${student_group_seq}
          priceType: ${price_type}
          minPrice: ${min_price}
          maxPrice: ${max_price}
          teacherName: ${teacher_name}
          isTeacherPhoto: ${is_teacher_photo}
          isVideoUrl: ${is_video_url}
          page: ${page_}
        `)

        result = await getContentListExcel({
          dateStart: date_start,
          dateEnd: date_end,
          type: type_,
          isDisplay: is_display,
          brandName: brand_name,
          categoryDepth1: category_depth_1,
          categoryDepth2: category_depth_2,
          categoryDepth3: category_depth_3,
          studentGroupSeq: student_group_seq,
          priceType: price_type,
          minPrice: min_price,
          maxPrice: max_price,
          teacherName: teacher_name,
          isTeacherPhoto: is_teacher_photo,
          isVideoUrl: is_video_url,
          page: page_,
        })
      }
    } else {
      // 꾸미기 아이템일 때
      if (startDate > endDate) {
        alert("날짜를 확인해주세요.")
      } else {
        const date_start = toStringByFormatting(startDate)
        const date_end = toStringByFormatting(endDate)
        const payment_type = sortation.pay && sortation.free ? "" : sortation.pay ? "PAY" : sortation.free ? "FREE" : ""
        const is_display = display.y && display.n ? "" : display.y ? "Y" : display.n ? "N" : ""
        const type_ = kind.filter && kind.sticker ? "" : kind.filter ? "FILTER" : kind.sticker ? "STICKER" : ""
        const min_point = pointFrom
        const max_point = pointTo
        const page_ = current - 1

        console.log(`
          dateStart: ${date_start}
          dateEnd: ${date_end}
          paymentType: ${payment_type}
          isDisplay: ${is_display}
          type: ${type_}
          minPoint: ${min_point}
          maxPoint: ${max_point}
          page: ${page_}
        `)

        result = await getDecorationItemListExcel({
          dateStart: date_start,
          dateEnd: date_end,
          paymentType: payment_type,
          isDisplay: is_display,
          type: type_,
          minPoint: min_point,
          maxPoint: max_point,
          page: page_,
        })
      }
    }

    if (result) {
      await downExcel({ result: result, fileName: currentType == 0 ? "컨텐츠 목록" : "꾸미기 목록" })
    }
  }

  return (
    <PrivateRoute>
      <>
        {itemPopup && popupInfo !== undefined && (
          <ItemPopup
            data={popupInfo}
            onClose={() => {
              setPopupInfo(undefined)
              setItemPopup(false)
            }}
          />
        )}
        {contentPopup && popupInfo !== undefined && (
          <ContentPopup
            data={popupInfo}
            onClose={() => {
              setPopupInfo(undefined)
              setContentPopup(false)
            }}
          />
        )}

        <Layout>
          <SEO title="서비스상품 조회" />
          <TopInfo title={["서비스 상품 조회", "서비스 상품 관리", "서비스 상품 조회"]} />
          <div className="service-inquiry-container">
            <div className="select-btn-wrapper">
              <div className={`select-item ${currentType === 0 ? "selected" : "non-selected"}`} onClick={() => TypeFn(0)}>
                컨텐츠
              </div>
              <div className={`select-item ${currentType === 1 ? "selected" : "non-selected"}`} onClick={() => TypeFn(1)}>
                아이템
              </div>
            </div>

            {currentType === 0 ? (
              <div className="service-inquiry-table-wrapper">
                <div className="table-row bottom-border">
                  <div className="element-l right-border">조회 기간</div>
                  <div className="element-r right-border small-box-l">
                    <div className="fgb-date-form">
                      <img src={icon_calendar} />
                      <div
                        className="fgb-date-input"
                        onClick={() => {
                          setPopupType(false)
                          setCalendarPopup(true)
                        }}
                      >
                        <p>{startDate.isValid() ? startDate.format("YYYY.MM.DD") : "시작일"}</p>
                      </div>
                      {calendarPopup && (
                        <div className="calendar-popup-back">
                          <div className="calendar-popup-div">
                            <CalendarComponent
                              date={date}
                              setDate={setDate}
                              selectedDate={popupType == false ? startDate : endDate}
                              setSelectedDate={popupType == false ? setStartDate : setEndDate}
                              popup={setCalendarPopup}
                            />
                          </div>
                        </div>
                      )}

                      <p>~</p>
                      <img src={icon_calendar} />
                      <div
                        className="fgb-date-input"
                        onClick={() => {
                          setPopupType(true)
                          setCalendarPopup(true)
                        }}
                      >
                        <p>{endDate.isValid() ? endDate.format("YYYY.MM.DD") : "종료일"}</p>
                      </div>
                    </div>
                  </div>
                  <div className="element-l right-border">유형</div>
                  <div className="element-r right-border small-box-r">
                    <div
                      className="check-box-wrapper"
                      onClick={() => {
                        if (type.regular && type.plan) {
                          setType({ regular: false, plan: false })
                        } else {
                          setType({ regular: true, plan: true })
                        }
                      }}
                    >
                      <img src={type.regular && type.plan ? checked_on : checked_off} />
                      <p>전체</p>
                    </div>
                    <div className="check-box-wrapper" onClick={() => setType({ ...type, regular: !type.regular })}>
                      <img src={type.regular ? checked_on : checked_off} />
                      <p>정규</p>
                    </div>
                    <div className="check-box-wrapper" onClick={() => setType({ ...type, plan: !type.plan })}>
                      <img src={type.plan ? checked_on : checked_off} />
                      <p>기획</p>
                    </div>
                  </div>
                  <div className="element-l right-border">화면 표시</div>
                  <div className="element-r no-border small-box-r">
                    <div
                      className="check-box-wrapper"
                      onClick={() => {
                        if (display.y && display.n) {
                          setDisplay({ y: false, n: false })
                        } else {
                          setDisplay({ y: true, n: true })
                        }
                      }}
                    >
                      <img src={display.y && display.n ? checked_on : checked_off} />
                      <p>전체</p>
                    </div>
                    <div className="check-box-wrapper" onClick={() => setDisplay({ ...display, y: !display.y })}>
                      <img src={display.y ? checked_on : checked_off} />
                      <p>전시</p>
                    </div>
                    <div className="check-box-wrapper" onClick={() => setDisplay({ ...display, n: !display.n })}>
                      <img src={display.n ? checked_on : checked_off} />
                      <p>미전시</p>
                    </div>
                  </div>
                </div>
                <div className="table-row bottom-border">
                  <div className="element-l right-border">브랜드명</div>
                  <div className="element-r right-border small-box-l">
                    <div style={{ width: "188px" }}>
                      <select
                        className="form-select"
                        style={{ backgroundColor: "#F2F8FC", border: "none", cursor: "pointer" }}
                        onChange={e => setBrandName(e.target.value)}
                      >
                        <option className="" value="">
                          전체
                        </option>
                        {contentCategory?.brandNames &&
                          contentCategory?.brandNames.map((i, idx) => (
                            <option key={idx} value={i?.brandName}>
                              {i?.brandName}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="element-l right-border">카테고리</div>
                  <div className="element-r no-border big-box">
                    <div style={{ width: "120px", marginRight: "5px" }}>
                      <select
                        className="form-select"
                        style={{ backgroundColor: "#F2F8FC", border: "none", cursor: "pointer" }}
                        onChange={e => setCategory1(e.target.value)}
                      >
                        <option className="" value="">
                          전체
                        </option>
                        {categoryLevel1 &&
                          categoryLevel1.map((i, idx) => (
                            <option value={i?.depth1Name} key={idx}>
                              {i?.depth1Name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div style={{ width: "120px", marginRight: "5px" }}>
                      <select
                        className="form-select"
                        style={{ backgroundColor: "#F2F8FC", border: "none", cursor: "pointer" }}
                        onChange={e => setCategory2(e.target.value)}
                      >
                        <option className="" value="">
                          전체
                        </option>
                        {categoryLevel2 &&
                          categoryLevel2.map((i, idx) => (
                            <option value={i?.depth2Name} key={idx}>
                              {i?.depth2Name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div style={{ width: "120px" }}>
                      <select
                        className="form-select"
                        style={{ backgroundColor: "#F2F8FC", border: "none", cursor: "pointer" }}
                        onChange={e => setCategory3(e.target.value)}
                      >
                        <option className="" value="">
                          전체
                        </option>
                        {categoryLevel3 &&
                          categoryLevel3.map((i, idx) => (
                            <option value={i?.depth3Name} key={idx}>
                              {i?.depth3Name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="table-row bottom-border">
                  <div className="element-l right-border">학습자 그룹</div>
                  <div className="element-r right-border small-box-l">
                    <div style={{ width: "188px" }}>
                      <select
                        className="form-select"
                        style={{ backgroundColor: "#F2F8FC", border: "none", cursor: "pointer" }}
                        onChange={e => setLearningGroup(e.target.value)}
                      >
                        <option className="" value="">
                          전체
                        </option>
                        {studentGroupList?.studentGroups &&
                          studentGroupList?.studentGroups.map((i, idx) => <option value={i?.level1?.level1Seq}>{i?.level1?.level1Name}</option>)}
                      </select>
                    </div>
                  </div>
                  <div className="element-l right-border">가격</div>
                  <div className="element-r no-border big-box">
                    <div
                      className="check-box-wrapper"
                      onClick={() => {
                        if (price.partner && price.letmeup) {
                          setPrice({ partner: false, letmeup: false })
                        } else {
                          setPrice({ partner: true, letmeup: true })
                        }
                      }}
                    >
                      <img src={price.partner && price.letmeup ? checked_on : checked_off} />
                      <p>전체</p>
                    </div>
                    <div className="check-box-wrapper" onClick={() => setPrice({ ...price, partner: !price.partner })}>
                      <img src={price.partner ? checked_on : checked_off} />
                      <p>제휴사</p>
                    </div>
                    <div className="check-box-wrapper" onClick={() => setPrice({ ...price, letmeup: !price.letmeup })}>
                      <img src={price.letmeup ? checked_on : checked_off} />
                      <p>렛미업</p>
                    </div>
                    <input className="price-input-box" placeholder="0" value={priceFrom} onChange={e => setPriceFrom(e.target.value)} />
                    <p>원</p>
                    <p className="wave-between-prices">~</p>
                    <input className="price-input-box" placeholder="0" value={priceTo} onChange={e => setPriceTo(e.target.value)} />
                    <p>원</p>
                  </div>
                </div>
                <div className="table-row no-border">
                  <div className="element-l right-border">강사 이름</div>
                  <div className="element-r right-border small-box-l">
                    <div style={{ width: "188px" }}>
                      <select
                        className="form-select"
                        style={{ backgroundColor: "#F2F8FC", border: "none", cursor: "pointer" }}
                        onChange={e => setTeacherName(e.target.value)}
                      >
                        <option className="" value="">
                          전체
                        </option>
                        {teacherList &&
                          teacherList.map((i, idx) => (
                            <option value={i} key={idx}>
                              {i}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="element-l right-border">강사 사진</div>
                  <div className="element-r right-border small-box-r">
                    <div
                      className="check-box-wrapper"
                      onClick={() => {
                        if (photo.y && photo.n) {
                          setPrice({ y: false, n: false })
                        } else {
                          setPrice({ y: true, n: true })
                        }
                      }}
                    >
                      <img src={photo.y && photo.n ? checked_on : checked_off} />
                      <p>전체</p>
                    </div>
                    <div className="check-box-wrapper" onClick={() => setPhoto({ ...photo, y: !photo.y })}>
                      <img src={photo.y ? checked_on : checked_off} />
                      <p>등록</p>
                    </div>
                    <div className="check-box-wrapper" onClick={() => setPhoto({ ...photo, n: !photo.n })}>
                      <img src={photo.n ? checked_on : checked_off} />
                      <p>미등록</p>
                    </div>
                  </div>
                  <div className="element-l right-border">홍보 URL</div>
                  <div className="element-r no-border small-box-r">
                    <div
                      className="check-box-wrapper"
                      onClick={() => {
                        if (url.y && url.n) {
                          setUrl({ y: false, n: false })
                        } else {
                          setUrl({ y: true, n: true })
                        }
                      }}
                    >
                      <img src={url.y && url.n ? checked_on : checked_off} />
                      <p>전체</p>
                    </div>
                    <div className="check-box-wrapper" onClick={() => setUrl({ ...url, y: !url.y })}>
                      <img src={url.y ? checked_on : checked_off} />
                      <p>있음</p>
                    </div>
                    <div className="check-box-wrapper" onClick={() => setUrl({ ...url, n: !url.n })}>
                      <img src={url.n ? checked_on : checked_off} />
                      <p>없음</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="service-inquiry-table-wrapper">
                <div className="table-row bottom-border">
                  <div className="element-l right-border">조회 기간</div>
                  <div className="element-r right-border small-box-l">
                    <div className="fgb-date-form">
                      <img src={icon_calendar} />
                      <div
                        className="fgb-date-input"
                        onClick={() => {
                          setPopupType(false)
                          setCalendarPopup(true)
                        }}
                      >
                        <p>{startDate.isValid() ? startDate.format("YYYY.MM.DD") : "시작일"}</p>
                      </div>
                      {calendarPopup && (
                        <div className="calendar-popup-back">
                          <div className="calendar-popup-div">
                            <CalendarComponent
                              date={date}
                              setDate={setDate}
                              selectedDate={popupType == false ? startDate : endDate}
                              setSelectedDate={popupType == false ? setStartDate : setEndDate}
                              popup={setCalendarPopup}
                            />
                          </div>
                        </div>
                      )}

                      <p>~</p>
                      <img src={icon_calendar} />
                      <div
                        className="fgb-date-input"
                        onClick={() => {
                          setPopupType(true)
                          setCalendarPopup(true)
                        }}
                      >
                        <p>{endDate.isValid() ? endDate.format("YYYY.MM.DD") : "종료일"}</p>
                      </div>
                    </div>
                  </div>
                  <div className="element-l right-border">구분</div>
                  <div className="element-r right-border small-box-r">
                    <div
                      className="check-box-wrapper"
                      onClick={() => {
                        if (sortation.pay && sortation.free) {
                          setSortation({ pay: false, free: false })
                        } else {
                          setSortation({ pay: true, free: true })
                        }
                      }}
                    >
                      <img src={sortation.pay && sortation.free ? checked_on : checked_off} />
                      <p>전체</p>
                    </div>
                    <div className="check-box-wrapper" onClick={() => setSortation({ ...sortation, pay: !sortation.pay })}>
                      <img src={sortation.pay ? checked_on : checked_off} />
                      <p>유료</p>
                    </div>
                    <div className="check-box-wrapper" onClick={() => setSortation({ ...sortation, free: !sortation.free })}>
                      <img src={sortation.free ? checked_on : checked_off} />
                      <p>무료</p>
                    </div>
                  </div>
                  <div className="element-l right-border">화면 표시</div>
                  <div className="element-r no-border small-box-r">
                    <div
                      className="check-box-wrapper"
                      onClick={() => {
                        if (display.y && display.n) {
                          setDisplay({ y: false, n: false })
                        } else {
                          setDisplay({ y: true, n: true })
                        }
                      }}
                    >
                      <img src={display.y && display.n ? checked_on : checked_off} />
                      <p>전체</p>
                    </div>
                    <div className="check-box-wrapper" onClick={() => setDisplay({ ...display, y: !display.y })}>
                      <img src={display.y ? checked_on : checked_off} />
                      <p>전시</p>
                    </div>
                    <div className="check-box-wrapper" onClick={() => setDisplay({ ...display, n: !display.n })}>
                      <img src={display.n ? checked_on : checked_off} />
                      <p>미전시</p>
                    </div>
                  </div>
                </div>
                <div className="table-row no-border">
                  <div className="element-l right-border">유형</div>
                  <div className="element-r right-border small-box-l">
                    <div
                      className="check-box-wrapper"
                      onClick={() => {
                        if (kind.filter && kind.sticker) {
                          setKind({ filter: false, sticker: false })
                        } else {
                          setKind({ filter: true, sticker: true })
                        }
                      }}
                    >
                      <img src={kind.filter && kind.sticker ? checked_on : checked_off} />
                      <p>전체</p>
                    </div>
                    <div className="check-box-wrapper" onClick={() => setKind({ ...kind, filter: !kind.filter })}>
                      <img src={kind.filter ? checked_on : checked_off} />
                      <p>필터</p>
                    </div>
                    <div className="check-box-wrapper" onClick={() => setKind({ ...kind, sticker: !kind.sticker })}>
                      <img src={kind.sticker ? checked_on : checked_off} />
                      <p>스티커</p>
                    </div>
                  </div>
                  <div className="element-l right-border">포인트</div>
                  <div className="element-r no-border big-box">
                    <input className="point-input-box" placeholder="0" value={pointFrom} onChange={e => setPointFrom(e.target.value)} />
                    <p>P</p>
                    <p className="wave-between-prices">~</p>
                    <input className="point-input-box" placeholder="0" value={pointTo} onChange={e => setPointTo(e.target.value)} />
                    <p>P</p>
                  </div>
                </div>
              </div>
            )}

            <div className="search-btn-wrapper">
              <div
                className="btn btn-primary font-white fgb-btn-l"
                onClick={() => {
                  handleSearch(0)
                  setCurrent(1)
                  setCurrentContent(1)
                }}
              >
                조회
              </div>
            </div>

            {currentType === 0 ? (
              <>
                <div className="result-info-wrapper">
                  <div className="num">{contentList?.totalCount ? contentList?.totalCount : 0}건</div>
                  <div className="two-btn-wrapper">
                    <div onClick={ExcelFn} className="excel-download-btn btn fgb-btn-m fgb-btn-grean " style={{ borderRadius: "6px" }}>
                      <img src={download} />
                      <p>엑셀 다운로드</p>
                    </div>
                    <div onClick={() => navigate("/service/ContentsReg")} className="btn btn-primary font-white  fgb-btn-m ms-2">
                      컨텐츠 등록
                    </div>
                  </div>
                </div>

                {contentList?.contents !== undefined && contentList?.contents.length !== 0 ? (
                  <>
                    <div className="fgb-table-div">
                      <table className="fgb-table">
                        <thead>
                          <tr className="fgb-table-title-tr">
                            <td>No.</td>
                            {TITLE_CONTENT.map((i, idx) => (
                              <td key={idx}>{i}</td>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {contentList?.contents.map((i, idx) => (
                            <tr
                              className="fgb-table-tr"
                              key={idx}
                              onClick={() => {
                                setPopupInfo(i)
                                setContentPopup(true)
                              }}
                            >
                              <td>{(currentContent - 1) * 15 + idx + 1}</td>
                              <td>{i?.isDisplay}</td>
                              <td>{i?.brandName}</td>
                              <td>{i?.studentGroupName}</td>
                              <td>{i?.categoryDepth1}</td>
                              <td>{i?.categoryDepth2}</td>
                              <td>{i?.categoryDepth3}</td>
                              <td>{i?.type}</td>
                              <td>{i?.title}</td>
                              <td>{i?.teacherName}</td>
                              <td>{i?.isHomepageUrl}</td>
                              <td>{i?.isVideoUrl}</td>
                              <td>{i?.partnerPrice}</td>
                              <td>{i?.letmeupPrice}</td>
                              <td>{i?.discountRate}</td>
                              <td>{i?.bannerClickCount}</td>
                              <td>{i?.name}</td>
                              <td>{i?.regDatetime}</td>
                              <td>{i?.updateDatetime}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      current={currentContent}
                      setCurrent={setCurrentContent}
                      total={totalContent}
                      count={15}
                      style={{ margin: "15px 0" }}
                    />
                  </>
                ) : (
                  <>
                    {/* <div className="search-none-wrapper">
                     <p>조회 결과가 없습니다.</p>
                     <p>원하는 항목을 선택하고 '조회' 버튼을 눌러주십시오.</p>
                  </div> */}
                  </>
                )}
              </>
            ) : (
              <>
                <div className="result-info-wrapper">
                  <div className="num">{decorationItemList?.totalCount ? decorationItemList?.totalCount : 0}건</div>
                  <div className="two-btn-wrapper">
                    <div onClick={ExcelFn} className="excel-download-btn btn fgb-btn-m fgb-btn-grean" style={{ borderRadius: "6px" }}>
                      <img src={download} />
                      <p>엑셀 다운로드</p>
                    </div>
                    <div onClick={() => navigate("/service/AdornmentReg")} className="btn btn-primary font-white  fgb-btn-m ms-2">
                      꾸미기 등록
                    </div>
                  </div>
                </div>

                {decorationItemList?.items !== undefined && decorationItemList?.items.length !== 0 ? (
                  <>
                    <div className="fgb-table-div">
                      <table className="fgb-table">
                        <thead>
                          <tr className="fgb-table-title-tr">
                            <td>No.</td>
                            {TITLE_ITEM.map((i, idx) => (
                              <td key={idx}>{i}</td>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {decorationItemList?.items.map((i, idx) => (
                            <tr
                              className="fgb-table-tr"
                              key={idx}
                              onClick={() => {
                                setPopupInfo(i)
                                setItemPopup(true)
                              }}
                            >
                              <td>{(current - 1) * 15 + idx + 1}</td>
                              <td>{i?.isDisplay}</td>
                              <td>{i?.paymentType === "PAY" ? "유료" : i?.paymentType === "FREE" ? "무료" : ""}</td>
                              <td>{i?.type === "FILTER" ? "필터" : i?.type === "STICKER" ? "스티커" : ""}</td>
                              <td>{i?.title}</td>
                              <td>{i?.tags}</td>
                              <td>{i?.fileName}</td>
                              <td>{i?.point}</td>
                              <td>{i?.downloadCount}</td>
                              <td>{i?.name}</td>
                              <td>{i?.regDatetime}</td>
                              <td>{i?.updateDatetime}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <Pagination current={current} setCurrent={setCurrent} total={total} count={15} style={{ margin: "15px 0" }} />
                  </>
                ) : (
                  <>
                    {/* <div className="search-none-wrapper">
                    <p>조회 결과가 없습니다.</p>
                    <p>원하는 항목을 선택하고 '조회' 버튼을 눌러주십시오.</p>
                  </div> */}
                  </>
                )}
              </>
            )}
          </div>
        </Layout>
      </>
    </PrivateRoute>
  )
}

export default CommonContainer(ServiceContainer(ServiceInquiry))

const TITLE_CONTENT = [
  "전시",
  "브랜드명",
  "학습자 그룹",
  "1Depth 카테고리",
  "2Depth 카테고리",
  "3Depth 카테고리",
  "유형",
  "타이틀",
  "강사 이름",
  "페이지 URL",
  "홍보 URL",
  "제휴사 가격",
  "렛미업 가격",
  "할인율",
  "배너 클릭수",
  "등록자",
  "등록일시",
  "변경일",
]
const TITLE_ITEM = ["전시", "구분", "유형", "타이틀", "키워드", "파일명", "렛미업 포인트", "다운로드 횟수", "등록자", "등록일시", "변경일"]
