import React from "react"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"

import samlab from "../../images/samlab.svg"
import Facebook_white from "../../images/Facebook_white.svg"
import Youtube_white from "../../images/Youtube_white.svg"
import Instagram_white from "../../images/Instagram_white.svg"
//인덱스순 [page이름, 1뎁스, 현재페이지]
const TopInfo = ({ title = ["", "", ""], rightView = true }) => {
  //rightView 우측내용 날리기
  const dispatch = useDispatch()

  return (
    <div className="page-top-info-container">
      <p className="page-top-info-title">{title[0]}</p>
      {rightView && (
        <p className="page-navi-p">
          {`홈 > ${title[1]} > `}
          <span className="page-navi-p-bold">{title[2]}</span>
        </p>
      )}
    </div>
  )
}

export default TopInfo
