import api from "../utils/api"

// 전시 관리

// 결제 ID 생성
// const postPaymentsOrder = async data => {
//   const result = await api.post(`/payments/order`, { token: true, body: data })
//   if (result) {
//     return result.data
//   } else return false
// }

//전시 목록 get
const getDisplayContents = async data => {
  const result = await api.get(
    `/display-contents?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&type=${data.type}&deviceType=${data.deviceType}&fileType=${data.fileType}&isDisplay=${data.isDisplay}&isUsingPageLink=${data.isUsingPageLink}&page=${data.page}&count=${data.count}`,
    { token: true }
  )
  if (result) {
    return result.data
  } else return false
}
//전시 상세 get
const getDisplayContent = async data => {
  const result = await api.get(`/display-contents/${data}`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//전시 게이트웨이 슬롯 get
const getDisplayOrderNumbers = async data => {
  const result = await api.get(`/display-order-numbers`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//전시 아이템 수정
const putDisplayContent = async data => {
  const result = await api.put(`/display-contents`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//전시 아이템 add
const postDisplayContent = async data => {
  const result = await api.post(`/display-contents`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//전시 아이템 del
const delDisplayContent = async data => {
  const result = await api.delete(`/display-contents`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//전시 프로모션 목록 get
const getPromotionAds = async data => {
  const result = await api.get(
    `/promotion-ads?dateStart=${data.dateStart}&dateEnd=${data.dateEnd}&type=${data.type}&isDisplay=${data.isDisplay}&bannerType=${data.bannerType}&bannerPosition=${data.bannerPosition}&deviceType=${data.deviceType}&page=${data.page}&count=${data.count}`,
    { token: true }
  )
  if (result) {
    return result.data
  } else return false
}
//전시 프로모션 상세 get
const getPromotionAd = async data => {
  const result = await api.get(`/promotion-ads/${data}`, { token: true })
  if (result) {
    return result.data
  } else return false
}

//전시 프로모션 등록
const postPromotionAds = async data => {
  const result = await api.post(`/promotion-ads`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//전시 프로모션 수정
const putPromotionAds = async data => {
  const result = await api.put(`/promotion-ads`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//전시 프로모션 Del
const delPromotionAds = async data => {
  const result = await api.delete(`/promotion-ads`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}

export default {
  getDisplayContents,
  getDisplayContent,
  getDisplayOrderNumbers,
  putDisplayContent,
  postDisplayContent,
  delDisplayContent,
  postPromotionAds,
  getPromotionAds,
  getPromotionAd,
  putPromotionAds,
  delPromotionAds,
}
