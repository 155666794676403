/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect } from "react"
import { loadTossPayments } from "@tosspayments/payment-sdk"

import "swiper/scss"

import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import TopInfo from "../../components/common/TopInfo"

import Pagination from "../../components/common/pagination"
import CalendarComponent from "../../components/Calendar"

import icon_calendar from "../../images/ion_calendar-number-outline.svg"
import download from "../../images/download.svg"
import moment from "moment"
import Validator from "validator"

import ChargePointModalPopup from "../../popups/account/ChargePointModalPopup"
import ChargeEtcPointModalPopup from "../../popups/account/ChargeEtcPointModalPopup"

import SignContainer from "../../containers/SignContainer"
import PaymentsContainer from "../../containers/PaymentsContainer"
import { downExcel, localStorgeSet, popupFn } from "../../utils/util"

import PrivateRoute from "../../components/privateRoute"
import { getScheduleCamstudyInfoAction } from "../../store/modules/schedule"
import { navigate } from "gatsby"
const PAY_TYPE = { POINT_CHARGING: "포인트충전", MONTHLY_FEE: "월정액", ANNUAL_FEE: "연정액" }

const MyOrder = props => {
  const {
    setPartnerProfilePaymentsEmpty,
    setPartnerProfilePaymentsTotalEmpty,
    getProfilePayments,
    profilePayments,
    profilePaymentsTotal,
    getProfilePaymentsXlDown,
    postPaymentsOrder,
  } = props
  //date 세팅용 state
  const [calendarPopup, setCalendarPopup] = useState(false)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [date, setDate] = useState(moment())
  const [popupType, setPopupType] = useState(false)

  const [current, setCurrent] = useState(1)

  const [openPointPopup, setOpenPointPopup] = useState(false)
  const [openPointEtcPopup, setOpenPointEtcPopup] = useState(false)
  const url = process.env.CALLBACK_URL

  const orderFn = async (amt, type) => {
    if (amt == 0 || Validator.isEmpty(type, { ignore_whitespace: true })) return false
    let result = await postPaymentsOrder({
      amount: amt,
      payType: type,
    })
    if (result) {
      setOpenPointPopup(false)
      setOpenPointEtcPopup(false)
      localStorgeSet("paySeq", result.paySeq)
      // console.log(result)
      let temp = {
        amount: result.amount,
        orderId: result.orderId,
        orderName: type,
        customerName: result.name,
        successUrl: `${url}/authOrder/`,
        failUrl: `${url}/authOrder/`,
      }
      const tossPayments = await loadTossPayments(process.env.TOSS_CLIENT_KEY)
      if (tossPayments) {
        tossPayments.requestPayment("카드", temp).catch(function (error) {
          if (error.code === "USER_CANCEL") {
            // 취소 이벤트 처리
            popupFn("결제가 취소되었습니다.")
            localStorage.removeItem("paySeq")
          }
        })
      }
    }
  }
  const getSearchData = async () => {
    if (startDate > endDate) return false
    let temp = {
      dateStart: startDate.format("YYYY-MM-DD"),
      dateEnd: endDate.format("YYYY-MM-DD"),
      page: 0,
      count: 15,
    }
    await getProfilePayments(temp)
    setCurrent(1)
  }
  useEffect(() => {
    return () => {
      setPartnerProfilePaymentsEmpty()
      setPartnerProfilePaymentsTotalEmpty()
    }
  }, [])
  const getPageData = async num => {
    if (startDate > endDate) return false
    let temp = {
      dateStart: startDate.format("YYYY-MM-DD"),
      dateEnd: endDate.format("YYYY-MM-DD"),
      page: num - 1,
      count: 15,
    }
    await getProfilePayments(temp)
  }

  //제휴사 결제 내역 목록 Xl
  const xlDownFn = async () => {
    let temp = {
      dateStart: startDate.format("YYYY-MM-DD"),
      dateEnd: endDate.format("YYYY-MM-DD"),
    }
    let result = await getProfilePaymentsXlDown(temp)
    if (result) {
      await downExcel({ result: result, fileName: "결제내역" })
    }
  }
  return (
    <PrivateRoute>
      <Layout>
        <SEO title="결제 내역" />
        <TopInfo title={["결제내역", "내 계정 관리", "결제내역"]} />
        <ChargePointModalPopup
          orderFn={orderFn}
          setEtc={() => setOpenPointEtcPopup(true)}
          open={openPointPopup}
          onClose={() => setOpenPointPopup(false)}
        />
        <ChargeEtcPointModalPopup orderFn={orderFn} open={openPointEtcPopup} onClose={() => setOpenPointEtcPopup(false)} />

        <div className="partner-profile-point-list">
          <div className="point-top-table my-order">
            <div className="point-top-table-name my-order">
              <div>조회 기간</div>
            </div>
            <div className="point-top-table-radio my-order">
              <div className="point-top-table-radio-row ">
                <div className="fgb-date-form ms-4">
                  <img src={icon_calendar} />
                  <div
                    className="fgb-date-input"
                    onClick={() => {
                      setPopupType(false)
                      setCalendarPopup(true)
                    }}
                  >
                    <p>{startDate.isValid() ? startDate.format("YYYY.MM.DD") : "시작일"}</p>
                  </div>
                  {calendarPopup && (
                    <div className="calendar-popup-back">
                      <div className="calendar-popup-div">
                        <CalendarComponent
                          date={date}
                          setDate={setDate}
                          selectedDate={popupType == false ? startDate : endDate}
                          setSelectedDate={popupType == false ? setStartDate : setEndDate}
                          popup={setCalendarPopup}
                        />
                      </div>
                    </div>
                  )}
                  <p>~</p>
                  <img src={icon_calendar} />

                  <div
                    className="fgb-date-input"
                    onClick={() => {
                      setPopupType(true)
                      setCalendarPopup(true)
                    }}
                  >
                    <p>{endDate.isValid() ? endDate.format("YYYY.MM.DD") : "종료일"}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <div onClick={() => getSearchData()} className="btn btn-primary font-white fgb-btn-l">
              조회
            </div>
          </div>
          <div className="d-flex justify-content-between mt-4">
            <p>{profilePaymentsTotal.toLocaleString()}건</p>
            <div className="d-flex justify-content-end">
              <div onClick={() => setOpenPointPopup(true)} className="btn btn-primary font-white reverse fgb-btn-m me-2">
                포인트 충전하기
              </div>
              <div onClick={() => xlDownFn()} className="btn fgb-btn-m fgb-btn-grean">
                <img src={download} />
                <p>엑셀 다운로드</p>
              </div>
            </div>
          </div>
          <div className="introduce-study-table mt-3">
            <div className="fgb-table-div">
              <table className={`fgb-table mb-4`}>
                <thead>
                  <tr className={`fgb-table-title-tr`}>
                    {["No.", "결제일시", "구분", "결제 내용 상세", " 결제 금액", "적립 포인트 (P)", "결제수단"].map((i, idx) => (
                      <td key={idx}>{i}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {profilePayments.map((i, idx) => (
                    <tr className={`fgb-table-tr `} key={idx}>
                      <td>{(current - 1) * 15 + idx + 1}</td>
                      <td>{i.paySuccessDatetime}</td>
                      <td>{PAY_TYPE[i.payType]}</td>
                      <td>{i.content}</td>
                      <td>{(i.amount || 0).toLocaleString()}</td>
                      <td>{(i.savePoint || 0).toLocaleString()}</td>

                      <td>{i.payMethod}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination fn={getPageData} count={15} current={current} setCurrent={setCurrent} total={profilePaymentsTotal} />
            </div>
          </div>
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default PaymentsContainer(SignContainer(MyOrder))
