import axios from "axios"
import { navigate } from "gatsby"
import { configureStore } from "../store"
import { setLoadingPopup, setServerErrorPopup } from "../store/modules/config"
import { setIsLogin } from "../store/modules/sign"
import { debug, isApp, localStorgeGet } from "../utils/util"
import { useDispatch } from "react-redux"

const apiFunction = method => {
  return async (url, { body = {}, header = {}, token = false, options = {} } = {}) => {
    const headers = {
      Accept: "application/json",
      ...header,
    }

    if (token) {
      headers.Authorization = `Bearer ${localStorgeGet(process.env.TOKEN_ID)}`
    }

    try {
      if (method == "GET") {
        // configureStore.dispatch(setLoadingPopup(true))
      }
      const result = await axios({
        method: method,
        url: process.env.API_URL + url,
        data: body,
        headers: headers,
        responseType: options.responseType,
      })
      // debug(result)
      console.log(result)
      return result
    } catch (err) {
      if (err.response) {
        // debug(err.response)
        console.log(err.response)
        if (err.response.status === 400) {
          configureStore.dispatch(setServerErrorPopup({ open: true, text: err.response.data.message }))
          return err.response
        } else if (err.response.status === 401) {
          // configureStore.dispatch(setServerErrorPopup({ open: true, text: err.response.data.message }))
          // navigate(-1)
          localStorage.removeItem(process.env.TOKEN_ID)
          localStorage.removeItem("loginUserSeq")
          // localStorage.removeItem("loginUserType")
          localStorage.removeItem("applicationType")
          configureStore.dispatch(setIsLogin(false))
          if (localStorgeGet("loginUserType") == "PARTNER") {
            navigate("/Affiliates")
          } else if (localStorgeGet("loginUserType") == "ADMIN") {
            navigate("/Admin")
          } else {
            navigate("/Affiliates")
          }
          return err.response
        } else if (err.response.status === 403) {
          configureStore.dispatch(setIsLogin(false))
          localStorage.removeItem(process.env.TOKEN_ID)
          localStorage.removeItem("loginUserSeq")
          // localStorage.removeItem("loginUserType")
          localStorage.removeItem("applicationType")
          if (localStorgeGet("loginUserType") == "PARTNER") {
            navigate("/Affiliates")
          } else if (localStorgeGet("loginUserType") == "ADMIN") {
            navigate("/Admin")
          } else {
            navigate("/Affiliates")
          }
          return err.response
        } else if (err.response.status === 409) {
          // 로그인 실패
          return err.response
        } else if (err.response.status === 412) {
          // if (isApp) window.callNative(JSON.stringify({ Type: "CookieRemove", FunctionName: "" }))
          configureStore.dispatch(setIsLogin(false))
          localStorage.removeItem(process.env.TOKEN_ID)
          localStorage.removeItem("loginUserSeq")
          localStorage.removeItem("applicationType")
          if (localStorgeGet("loginUserType") == "PARTNER") {
            navigate("/Affiliates")
          } else if (localStorgeGet("loginUserType") == "ADMIN") {
            navigate("/Admin")
          } else {
            navigate("/Affiliates")
          }
          return err.response
        } else if (err.response.status === 500) {
          configureStore.dispatch(setServerErrorPopup({ open: true, text: err.response.data.message }))
          return err.response
        }
      }
    } finally {
      // configureStore.dispatch(setLoadingPopup(false))
    }
  }
}

const api = {
  post: apiFunction("POST"),
  get: apiFunction("GET"),
  delete: apiFunction("DELETE"),
  patch: apiFunction("PATCH"),
  put: apiFunction("PUT"),
}

export default api
