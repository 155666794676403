import { createAction, handleActions } from "redux-actions"
import User_Profile from "../../images/User_Profile.svg"
import master_icon from "../../images/master_icon.svg"
import operation_icon from "../../images/operation_icon.svg"
import junsi_icon from "../../images/junsi_icon.svg"
import setting_icon from "../../images/setting_icon.svg"
import service_icon from "../../images/service_icon.svg"
import study_icon from "../../images/study_icon.svg"
const SET_NAVI = "popup/set-Navi"

export const setNavi = createAction(SET_NAVI)

const initState = {
  navi: {
    MASTAR: [
      {
        path: "/member",
        title: "회원관리",
        open: false,
        icon: User_Profile,
        sub: [
          { title: "회원조회", pathname: "/MemberInquiry" },
          { title: "탈퇴회원 관리", pathname: "/WithdrawalMember" },
        ],
      },
      {
        path: "/studySchedule",
        title: "스터디스케줄 관리",
        open: false,
        icon: study_icon,
        sub: [
          { title: "스케줄 신청목록 조회", pathname: "/ScheduleInquiry" }, // 관리자 페이지에는 없는 페이지임, 나중에 삭제해야함
          { title: "스터디스케줄 조회", pathname: "/StudyScheduleInquiry" },
          { title: "스터디스케줄 등록", pathname: "/StudyScheduleReg" },
          { title: "추천 프로그램 등록", pathname: "/RegisterRecommendationProgram" },
        ],
      },
      {
        path: "/service",
        title: "서비스상품 관리",
        open: false,
        icon: service_icon,
        sub: [
          { title: "서비스상품 조회", pathname: "/ServiceInquiry" },
          { title: "컨텐츠 등록", pathname: "/ContentsReg" },
          { title: "꾸미기 등록", pathname: "/AdornmentReg" },
        ],
      },
      {
        path: "/exhibition",
        title: "전시 관리",
        open: false,
        icon: junsi_icon,
        sub: [
          { title: "전시목록조회", pathname: "/ExhibitionInquiry" },
          { title: "프로모션광고 배너관리", pathname: "/Promotion" },
          { title: "Intro 이미지 등록", pathname: "/Intro" },
          { title: "Gateway 이미지 등록", pathname: "/Gateway" },
        ],
      },
      {
        path: "/operation",
        title: "운영관리",
        open: false,
        icon: operation_icon,
        sub: [
          { title: "학습자 유형 관리", pathname: "/LearnerType" },
          { title: "AI학습규칙 관리", pathname: "/AI" },
          { title: "컨텐츠 카테고리 관리", pathname: "/ContantsCategory" },
          { title: "렛미업 포인트 관리", pathname: "/Point" },
          { title: "키워드 관리", pathname: "/Keyword" },
          { title: "제휴사 관리", pathname: "/Affiliates" },
          { title: "공지사항 관리", pathname: "/Notice" },
        ],
      },
      {
        path: "/master",
        title: "마스터",
        open: false,
        icon: master_icon,
        sub: [{ title: "운영자권한 관리", pathname: "/Permission" }],
      },
      {
        path: "/account",
        title: "내 계정 관리",
        open: false,
        icon: setting_icon,
        sub: [{ title: "내 계정 정보", pathname: "/MyAccount" }],
      },
    ],
    AFFILIATES: [
      {
        path: "/member",
        title: "회원관리",
        open: false,
        icon: User_Profile,
        sub: [{ title: "회원조회", pathname: "/MemberInquiry" }],
      },
      {
        path: "/studySchedule",
        title: "스터디스케줄 관리",
        open: false,
        icon: study_icon,
        sub: [
          { title: "스케줄 신청목록 조회", pathname: "/ScheduleInquiry" },
          { title: "스터디스케줄 조회", pathname: "/StudyScheduleInquiry" },
          { title: "스터디스케줄 등록", pathname: "/StudyScheduleReg" },
        ],
      },
      {
        path: "/service",
        title: "서비스상품 관리",
        open: false,
        icon: service_icon,
        sub: [
          { title: "서비스상품 조회", pathname: "/ServiceInquiry" },
          { title: "컨텐츠 등록", pathname: "/ContentsReg" },
          { title: "꾸미기 등록", pathname: "/AdornmentReg" },
        ],
      },

      {
        path: "/account",
        title: "내 계정 관리",
        open: false,
        icon: setting_icon,
        sub: [
          { title: "프로필", pathname: "/Profile" },
          { title: "포인트 사용내역", pathname: "/PointList" },
          { title: "결제 내역", pathname: "/MyOrder" },
          { title: "내 메세지", pathname: "/Message" },
        ],
      },
    ],
  },
}

export default handleActions(
  {
    [SET_NAVI]: (state, action) => ({
      ...state,
      navi: action.payload,
    }),
  },
  initState
)
