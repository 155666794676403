import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import {
  getDisplayContentsAction,
  getDisplayContentAction,
  setDisplayContent,
  setDisplayContents,
  setDisplayContentsTotal,
  getDisplayOrderNumbersAction,
  putDisplayContentAction,
  postDisplayContentAction,
  delDisplayContentAction,
  postPromotionAdsAction,
  getPromotionAdsAction,
  getPromotionAdAction,
  setPromotionAd,
  putPromotionAdsAction,
  delPromotionAdsAction,
} from "../store/modules/exhibition"
import { postUploadFileAction } from "../store/modules/sign"
import { postUploadFilesAction } from "../store/modules/common"

// redux state > component mapping
const mapStateToProps = state => ({
  displayContents: state.exhibition.displayContents,
  displayContentsTotal: state.exhibition.displayContentsTotal,
  displayContent: state.exhibition.displayContent,
  displayOrderNumber: state.exhibition.displayOrderNumber,
  promotionAds: state.exhibition.promotionAds,
  promotionAdsTotal: state.exhibition.promotionAdsTotal,
  promotionAd: state.exhibition.promotionAd,
})

// redux action > component mapping
const mapDispatchToProps = dispatch => ({
  //인트로 게이트웨이
  getDisplayContents: param => dispatch(getDisplayContentsAction(param)),
  getDisplayContent: param => dispatch(getDisplayContentAction(param)),
  displayContentEmpty: param => dispatch(setDisplayContent({})),
  displayContentsEmpty: param => dispatch(setDisplayContents([])),
  displayContentsTotalEmpty: param => dispatch(setDisplayContentsTotal(0)),
  getDisplayOrderNumbers: param => dispatch(getDisplayOrderNumbersAction(param)),
  putDisplayContent: param => dispatch(putDisplayContentAction(param)),
  postDisplayContent: param => dispatch(postDisplayContentAction(param)),
  delDisplayContent: param => dispatch(delDisplayContentAction(param)),
  //업로드 file
  postUploadFile: param => dispatch(postUploadFileAction(param)),
  postUploadFiles: param => dispatch(postUploadFilesAction(param)),
  //프로모션
  getPromotionAds: param => dispatch(getPromotionAdsAction(param)),
  postPromotionAds: param => dispatch(postPromotionAdsAction(param)),
  putPromotionAds: param => dispatch(putPromotionAdsAction(param)),
  getPromotionAd: param => dispatch(getPromotionAdAction(param)),
  delPromotionAds: param => dispatch(delPromotionAdsAction(param)),
  promotionAdEmpty: param => dispatch(setPromotionAd({})),
})

const WithHoc = ChildComponent => props => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
