import { createAction, handleActions } from "redux-actions"

const SET_LOGIN_POPUP = "popup/set-login-popup"
const SET_SIGN_UP_POPUP = "popup/set-sign-up-popup"
const SET_MYACCOUNT_POPUP = "popup/set-my-account-popup"

export const setLoginPopup = createAction(SET_LOGIN_POPUP)
export const setSignUpPopup = createAction(SET_SIGN_UP_POPUP)
export const setMyAccountPopup = createAction(SET_MYACCOUNT_POPUP)

const initState = {
  loginPopup: false,
  signUpPopup: false,
  myAccountPopup: false,
}

export default handleActions(
  {
    [SET_LOGIN_POPUP]: (state, action) => ({
      ...state,
      loginPopup: action.payload,
    }),
    [SET_SIGN_UP_POPUP]: (state, action) => ({
      ...state,
      signUpPopup: action.payload,
    }),
    [SET_MYACCOUNT_POPUP]: (state, action) => ({
      ...state,
      myAccountPopup: action.payload,
    }),
  },
  initState
)
