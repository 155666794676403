import React from "react"
import { debounce } from "lodash"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import LayerPopupLayout from "./LayerPopupLayout"
import girl_1 from "../images/girl_1.svg"
import boy_1 from "../images/boy_1.svg"
import stikerSmile from "../images/Stiker-Smile.svg"
import clockwatch from "../images/clock-watch.svg"
import calendarSchedule7 from "../images/calendar-schedule-7.svg"
import img_exploring_card_profile_temp from "../images/temp_profile.svg"
import AffiliatesMainContainer from "../containers/AffiliatesMainContainer"

import img_popup_close_gray from "../images/img_popup_close_gray.svg"

import api from "../utils/api"
import { dataCheckFn } from "../utils/util"
moment.lang("ko", {
  weekdays: ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"],
  weekdaysShort: ["일", "월", "화", "수", "목", "금", "토"],
})

const StudyInfoPopup = props => {
  const {
    data = {
      isParticipation: "신규 참여중",
      studySeq: "1638927932106_rejuqdcq",
      studentGroupName: "기타",
      dateStart: "2021-12-08",
      dateEnd: "2021-12-08",
      title: "1111",
      isFree: "Y",
      isLoop: "N",
      viewCount: 0,
      regDatetime: "2021-12-08 10:45:32",
      studyMemberCount: 1,
      studyMemberTotalCount: 4,
      managerUserSeq: "1638409001996_qw135x1d",
      managerUserType: "USER",
      managerUserNickname: "",
      managerUserProfileUrl: "",
      managerUserRegion1: "인천",
      managerUserRegion2: "중구",
      managerUserRegion3: "운서동",
      isWithdraw: "N",
      tags: ["11"],
    },
    partnerMainMyStudiesSchedules = {
      summaryStudySchedules: [
        {
          weekStudyDay: "월, 화, 수, 목, 금",
          weekStudyHour: "07:00:00",
        },
        {
          weekStudyDay: "토, 일",
          weekStudyHour: "03:00:00",
        },
      ],
      studySchedules: [
        {
          dayType: "월",
          startTime: "12:00:00",
          endTime: "19:00:00",
        },
        {
          dayType: "화",
          startTime: "12:00:00",
          endTime: "19:00:00",
        },
        {
          dayType: "수",
          startTime: "12:00:00",
          endTime: "19:00:00",
        },
      ],
    },
    open = false,
    onClose = () => {},
  } = props
  const dispatch = useDispatch()

  const closePopup = () => {
    onClose()
  }

  // 참여 가능 여부
  // let ableToParticipate = false

  // if (Object.keys(data).length != 0) {
  //   const filteredList = []
  //   data.detailStudySchedules.studySchedules.map(j => (j.dayType === moment().format("ddd") ? filteredList.push(j) : false))
  //   if (filteredList.length > 0 && moment().isBetween(moment(filteredList[0].startTime, "hh:mm:ss"), moment(filteredList[0].endTime, "hh:mm:ss"))) {
  //     ableToParticipate = true
  //   } else {
  //     ableToParticipate = false
  //   }
  // }

  return (
    <LayerPopupLayout style={{ alignItems: "flex-start" }} open={open} onClose={closePopup} zIndex={103}>
      <div className="popup-affiliates-main-study-info-container">
        <div className="d-flex justify-content-end mt-5">
          <img onClick={closePopup} className="btn" src={img_popup_close_gray} />
        </div>
        <div className="title-box"></div>
        <div className="popup-affiliates-main-study-info-info-container">
          {Object.keys(data).length != 0 && (
            <div className="popup-affiliates-main-study-info-info-box">
              <img className="boy-img" src={boy_1}></img>

              <img className="girl-img" src={girl_1}></img>

              <div className={`popup-affiliates-main-study-info-info-label ${"popup-affiliates-main-study-info-card-2"}`}></div>
              <div className={`popup-affiliates-main-study-info-info-in-box`}>
                <p className="inTitle">{data.title}</p>
                <div className="result-key-box">
                  {(data.tags || []).map((item, idx) => (
                    <div className="popup-affiliates-main-study-info-info-in-box-key" key={idx}>
                      # {item}
                    </div>
                  ))}
                </div>
                <div className="popup-affiliates-main-study-info-info-contents purple">
                  <img style={{ marginLeft: "8px" }} src={calendarSchedule7}></img>
                  <div>
                    스터디 기간:{" "}
                    {data.dateStart === data.dateEnd
                      ? `${moment(data.dateStart).format("MM-DD")}`
                      : `${moment(data.dateStart).format("MM-DD")} ~ ${moment(data.dateEnd).format("MM-DD")}`}
                  </div>
                </div>
                <div className="popup-affiliates-main-study-info-info-contents mint">
                  <img src={clockwatch}></img>
                  <div style={{ display: "flex" }}>
                    스터디 시간:
                    <div style={{ flex: 1, marginLeft: 5 }}>
                      {data.isFree == "Y" ? (
                        <>
                          <p>주 1일 1회</p>
                          <p>
                            {dataCheckFn(partnerMainMyStudiesSchedules) &&
                              partnerMainMyStudiesSchedules.studySchedules.map(
                                (i, idx) =>
                                  `${i.dayType} ${moment(i.startTime, "hh:mm:ss").format("hh:mm")} ~ ${moment(i.endTime, "hh:mm:ss").format(
                                    "hh:mm"
                                  )}\n`
                              )}
                          </p>
                        </>
                      ) : (
                        <>
                          <pre>
                            {dataCheckFn(partnerMainMyStudiesSchedules) &&
                              partnerMainMyStudiesSchedules.summaryStudySchedules.map(
                                i => `${data.isLoop == "Y" ? "매주 " : ""} ${i.weekStudyDay} ${i.weekStudyHour}시간 \n`
                              )}
                            {dataCheckFn(partnerMainMyStudiesSchedules) &&
                              partnerMainMyStudiesSchedules.studySchedules.map(
                                (i, idx) =>
                                  `${i.dayType} ${moment(i.startTime, "hh:mm:ss").format("hh:mm")} ~ ${moment(i.endTime, "hh:mm:ss").format(
                                    "hh:mm"
                                  )}\n`
                              )}
                          </pre>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="popup-affiliates-main-study-info-info-contents blue">
                  <img src={stikerSmile}></img>
                  <div>스터디 참여인원: 최대 {data.isFree == "Y" ? "4" : data.studyMemberTotalCount}명</div>
                </div>
                <div className="result-card-bottom">
                  <div className="result-card-bottom-avatar">
                    <img
                      src={
                        data.profileImage
                          ? data.profileImage != null && data.profileImage != ""
                            ? data.profileImage
                            : img_exploring_card_profile_temp
                          : img_exploring_card_profile_temp
                      }
                      alt="profile img"
                    />
                    <img />
                  </div>
                  <div className="result-card-bottom-user">
                    {data.nickname || data.managerNickname || "nickname"}
                    <br />
                    {`${data.region1 || data.managerRegion1 || "OO시"} 
                    ${data.region2 || data.managerRegion2 || "OO구"} 
                    ${data.region3 || data.managerRegion3 || "OO동"}`}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </LayerPopupLayout>
  )
}

export default AffiliatesMainContainer(StudyInfoPopup)
