/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect } from "react"

import "swiper/scss"

import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import CalendarComponent from "../../components/Calendar"
import TopInfo from "../../components/common/TopInfo"
import Pagination from "../../components/common/pagination"

import moment from "moment"

import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import icon_calendar from "../../images/ion_calendar-number-outline.svg"

import AddIntroPopup from "../../popups/exhibition/AddIntroPopup"
import AddGatewayPopup from "../../popups/exhibition/AddGatewayPopup"
import { navigate } from "gatsby"

import ExhibitionContainer from "../../containers/ExhibitionContainer"

import PrivateRoute from "../../components/privateRoute"

const ExhibitionInquiry = props => {
  const {
    delDisplayContent,
    getDisplayContents,
    displayContents,
    displayContentsTotal,
    getDisplayContent,
    displayContentsEmpty,
    displayContentsTotalEmpty,
  } = props
  //date 세팅용 state
  const [calendarPopup, setCalendarPopup] = useState(false)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [date, setDate] = useState(moment())
  const [popupType, setPopupType] = useState(false)

  const [current, setCurrent] = useState(1)
  const [checkList, setCheckList] = useState([])

  const [openIntroPopup, setOpenIntroPopup] = useState(false)
  const [openGatewayPopup, setOpenGatewayPopup] = useState(false)

  const [isIntro, setIsIntro] = useState(true)
  const [isGateway, setIsGateway] = useState(true)

  const [isPc, setIsPc] = useState(true)
  const [isMo, setIsMo] = useState(true)

  const [isVideo, setIsVideo] = useState(true)
  const [isImg, setIsImg] = useState(true)

  const [isLinkY, setinLinkY] = useState(true)
  const [isLinkN, setIsLinkN] = useState(true)

  const [isDisplayY, setIsDisplayY] = useState(true)
  const [isDisplayN, setIsDisplayN] = useState(true)

  const searchFn = async () => {
    if (startDate > endDate) return false

    let temp = {
      dateStart: startDate.format("YYYY-MM-DD"),
      dateEnd: endDate.format("YYYY-MM-DD"),
      type: isIntro && isGateway ? "" : isIntro ? "INTRO" : isGateway ? "GATEWAY" : "",
      deviceType: isPc && isMo ? "" : isPc ? "PC" : isMo ? "MOBILE" : "",
      fileType: isVideo && isImg ? "" : isVideo ? "VIDEO" : isImg ? "IMAGE" : "",
      isDisplay: isDisplayY && isDisplayN ? "" : isDisplayY ? "Y" : isDisplayN ? "N" : "",
      isUsingPageLink: isLinkY && isLinkN ? "" : isLinkY ? "Y" : isLinkN ? "N" : "",
      page: 0,
      count: 15,
    }
    console.log(temp)
    let result = await getDisplayContents(temp)
    if (result) {
      setCurrent(1)
      setCheckList([])
    }
  }
  const delFn = async () => {
    if (checkList.length < 1) return false
    let result = await delDisplayContent({ exhibitionContentSeqs: checkList })
    if (result) [searchFn()]
  }
  const pageFn = async num => {
    if (startDate > endDate) return false
    let temp = {
      dateStart: startDate.format("YYYY-MM-DD"),
      dateEnd: endDate.format("YYYY-MM-DD"),
      type: isIntro && isGateway ? "" : isIntro ? "INTRO" : isGateway ? "GATEWAY" : "",
      deviceType: isPc && isMo ? "" : isPc ? "PC" : isMo ? "MOBILE" : "",
      fileType: isVideo && isImg ? "" : isVideo ? "VIDEO" : isImg ? "IMAGE" : "",
      isDisplay: isDisplayY && isDisplayN ? "" : isDisplayY ? "Y" : isDisplayN ? "N" : "",
      isUsingPageLink: isLinkY && isLinkN ? "" : isLinkY ? "Y" : isLinkN ? "N" : "",
      page: num - 1 || 0,
      count: 15,
    }
    let result = await getDisplayContents(temp)
    if (result) {
      setCheckList([])
    }
  }
  useEffect(() => {
    return () => {
      displayContentsEmpty()
      displayContentsTotalEmpty()
    }
  }, [])

  return (
    <PrivateRoute>
      <Layout>
        <SEO title="전시목록조회" />
        <TopInfo title={["전시목록조회", "전시관리", "전시목록조회"]} />
        <AddIntroPopup getFn={searchFn} open={openIntroPopup} onClose={() => setOpenIntroPopup(false)} />
        <AddGatewayPopup getFn={searchFn} open={openGatewayPopup} onClose={() => setOpenGatewayPopup(false)} />

        <div className="operation-learnerType-container">
          <div className="operation-learnerType-table ">
            <div className="operation-learnerType-table-name ">
              <div>조회 기간</div>
            </div>
            <div style={{ width: "296px" }} className="operation-learnerType-table-contents justify-content-between">
              <div className="fgb-date-form ms-2">
                <img src={icon_calendar} />
                <div
                  className="fgb-date-input"
                  onClick={() => {
                    setPopupType(false)
                    setCalendarPopup(true)
                  }}
                >
                  <p>{startDate.isValid() ? startDate.format("YYYY.MM.DD") : "시작일"}</p>
                </div>
                {calendarPopup && (
                  <div className="calendar-popup-back">
                    <div className="calendar-popup-div">
                      <CalendarComponent
                        date={date}
                        setDate={setDate}
                        selectedDate={popupType == false ? startDate : endDate}
                        setSelectedDate={popupType == false ? setStartDate : setEndDate}
                        popup={setCalendarPopup}
                      />
                    </div>
                  </div>
                )}
                <p>~</p>
                <img src={icon_calendar} />

                <div
                  className="fgb-date-input"
                  onClick={() => {
                    setPopupType(true)
                    setCalendarPopup(true)
                  }}
                >
                  <p>{endDate.isValid() ? endDate.format("YYYY.MM.DD") : "종료일"}</p>
                </div>
              </div>
            </div>
            <div className="operation-learnerType-table-name">
              <div>구분</div>
            </div>
            <div className="d-flex align-items-center">
              <img
                onClick={() => {
                  if (isIntro && isGateway) {
                    setIsIntro(false)
                    setIsGateway(false)
                  } else {
                    setIsIntro(true)
                    setIsGateway(true)
                  }
                }}
                className="ms-3 me-2"
                src={isIntro && isGateway ? checked_on : checked_off}
              />
              <p>전체 </p>
              <img onClick={() => setIsIntro(!isIntro)} className="ms-3 me-2" src={isIntro ? checked_on : checked_off} />
              <p>Intro페이지</p>
              <img onClick={() => setIsGateway(!isGateway)} className="ms-3 me-2" src={isGateway ? checked_on : checked_off} />
              <p>Gateway 페이지</p>
            </div>
          </div>
          <div style={{ borderBottom: "none" }} className="operation-learnerType-table">
            <div className="operation-learnerType-table-name">
              <div>플랫폼</div>
            </div>
            <div style={{ width: "296px" }} className="d-flex align-items-center">
              <img
                onClick={() => {
                  if (isPc && isMo) {
                    setIsPc(false)
                    setIsMo(false)
                  } else {
                    setIsPc(true)
                    setIsMo(true)
                  }
                }}
                className="ms-2 me-2"
                src={isPc && isMo ? checked_on : checked_off}
              />
              <p>전체 </p>
              <img onClick={() => setIsPc(!isPc)} className="ms-3 me-2" src={isPc ? checked_on : checked_off} />
              <p>PC</p>
              <img onClick={() => setIsMo(!isMo)} className="ms-3 me-2" src={isMo ? checked_on : checked_off} />
              <p className="">Mobile</p>
            </div>
            <div className="operation-learnerType-table-name">
              <div>유형</div>
            </div>
            <div className="d-flex align-items-center">
              <img
                onClick={() => {
                  if (isVideo && isImg) {
                    setIsVideo(false)
                    setIsImg(false)
                  } else {
                    setIsVideo(true)
                    setIsImg(true)
                  }
                }}
                className="ms-3 me-2"
                src={isVideo && isImg ? checked_on : checked_off}
              />
              <p>전체 </p>
              <img onClick={() => setIsVideo(!isVideo)} className="ms-3 me-2" src={isVideo ? checked_on : checked_off} />
              <p>동영상</p>
              <img onClick={() => setIsImg(!isImg)} className="ms-3 me-2" src={isImg ? checked_on : checked_off} />
              <p>이미지</p>
            </div>
          </div>
          <div className="operation-learnerType-table">
            <div className="operation-learnerType-table-name">
              <div>전시 여부</div>
            </div>
            <div style={{ width: "296px" }} className="d-flex align-items-center">
              <img
                onClick={() => {
                  if (isDisplayY && isDisplayN) {
                    setIsDisplayY(false)
                    setIsDisplayN(false)
                  } else {
                    setIsDisplayY(true)
                    setIsDisplayN(true)
                  }
                }}
                className="ms-2 me-2"
                src={isDisplayY && isDisplayN ? checked_on : checked_off}
              />
              <p>전체 </p>
              <img onClick={() => setIsDisplayY(!isDisplayY)} className="ms-3 me-2" src={isDisplayY ? checked_on : checked_off} />
              <p>전시</p>
              <img onClick={() => setIsDisplayN(!isDisplayN)} className="ms-3 me-2" src={isDisplayN ? checked_on : checked_off} />
              <p>비전시</p>
            </div>
            <div className="operation-learnerType-table-name ">
              <div>링크여부</div>
            </div>
            <div className="d-flex align-items-center">
              <img
                onClick={() => {
                  if (isLinkY && isLinkN) {
                    setinLinkY(false)
                    setIsLinkN(false)
                  } else {
                    setinLinkY(true)
                    setIsLinkN(true)
                  }
                }}
                className="ms-3 me-2"
                src={isLinkY && isLinkN ? checked_on : checked_off}
              />
              <p>전체 </p>
              <img onClick={() => setinLinkY(!isLinkY)} className="ms-3 me-2" src={isLinkY ? checked_on : checked_off} />
              <p>있음</p>
              <img onClick={() => setIsLinkN(!isLinkN)} className="ms-3 me-2" src={isLinkN ? checked_on : checked_off} />
              <p>없음</p>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <div onClick={searchFn} className="btn btn-primary font-white fgb-btn-l">
              조회
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mt-4">
            <p>{displayContentsTotal.toLocaleString()}건</p>
            <div className="d-flex justify-content-end">
              <div onClick={delFn} className="btn btn-primary font-white reverse fgb-btn-ms clear-button me-2">
                선택 삭제
              </div>
              <div onClick={() => navigate("/exhibition/Gateway")} className="btn btn-primary font-white fgb-btn-m me-2">
                Gateway 등록
              </div>
              <div onClick={() => navigate("/exhibition/Intro")} className="btn btn-primary font-white fgb-btn-m me-2">
                Intro 등록
              </div>
            </div>
          </div>
          <div className="introduce-study-table mt-3">
            <div className="fgb-table-div">
              <table style={{ overFlowX: "auto" }} className={`fgb-table mb-4`}>
                <thead>
                  <tr className={`fgb-table-title-tr`}>
                    <td>
                      <img
                        onClick={() => {
                          if (checkList.length == displayContents.length) {
                            setCheckList([])
                          } else {
                            let temp = []
                            for (let i of displayContents) {
                              temp = [...temp, i.exhibitionContentSeq]
                            }
                            setCheckList(temp)
                          }
                        }}
                        className="ms-4 me-2"
                        src={checkList.length == displayContents.length && displayContents.length > 0 ? checked_on : checked_off}
                      />
                    </td>
                    {["구분", "유형", "플랫폼", "제목", "동영상/페이지 링크", "전시순서", "전시여부", "등록자", "등록일시"].map((i, idx) => (
                      <td key={idx}>{i}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {displayContents.map((i, idx) => (
                    <tr className={`fgb-table-tr ${checkList.indexOf(i.exhibitionContentSeq) != -1 ? "active" : ""}`} key={idx}>
                      <td>
                        <img
                          onClick={() => {
                            let check = checkList.indexOf(i.exhibitionContentSeq)
                            if (check == -1) {
                              setCheckList([...checkList, i.exhibitionContentSeq])
                            } else {
                              let temp = [...checkList]
                              temp.splice(check, 1)
                              setCheckList(temp)
                            }
                          }}
                          className="ms-4 me-2"
                          src={checkList.indexOf(i.exhibitionContentSeq) != -1 ? checked_on : checked_off}
                        />
                      </td>
                      <td
                        onClick={async () => {
                          let result = await getDisplayContent(i.exhibitionContentSeq)
                          if (result) {
                            if (i.type == "GATEWAY") {
                              setOpenGatewayPopup(true)
                            } else if (i.type == "INTRO") {
                              setOpenIntroPopup(true)
                            }
                          }
                        }}
                      >
                        {i.type}
                      </td>
                      <td>{i.fileType}</td>
                      <td>{i.deviceType}</td>
                      <td
                        onClick={async () => {
                          let result = await getDisplayContent(i.exhibitionContentSeq)
                          if (result) {
                            if (i.type == "GATEWAY") {
                              setOpenGatewayPopup(true)
                            } else if (i.type == "INTRO") {
                              setOpenIntroPopup(true)
                            }
                          }
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {i.title}
                      </td>
                      <td>{i.pageUrl || i.videoUrl}</td>
                      <td>{i.displayOrderNumber}</td>
                      <td>{i.isDisplay}</td>
                      <td>{i.adminName}</td>
                      <td>{moment(i.regDatetime).format("YYYY/MM/DD HH:mm")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <Pagination fn={pageFn} count={15} current={current} setCurrent={setCurrent} total={displayContentsTotal} />
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default ExhibitionContainer(ExhibitionInquiry)
