/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect, useRef } from "react"

import "swiper/scss"

import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import moment from "moment"
import CalendarComponent from "../../components/Calendar"
import Pagination from "../../components/common/pagination"
import TopInfo from "../../components/common/TopInfo"

import CommonContainer from "../../containers/CommonContainer"
import MemberContainer from "../../containers/MemberContainer"

import { downExcel, popupFn, toStringByFormatting } from "../../utils/util"

import BasicInfoPopup from "../../popups/member/BasicInfoPopup"
import CreatedStudiesPopup from "../../popups/member/CreatedStudiesPopup"
import RolledInStudiesPopup from "../../popups/member/RolledInStudiesPopup"
import AccumulatedToDoPopup from "../../popups/member/AccumulatedToDoPopup"
import AccumulatedTimePopup from "../../popups/member/AccumulatedTimePopup"
import RankingPopup from "../../popups/member/RankingPopup"
import PointPopup from "../../popups/member/PointPopup"
import PaidPopup from "../../popups/member/PaidPopup"
import FriendPopup from "../../popups/member/FriendPopup"

import icon_calendar from "../../images/ion_calendar-number-outline.svg"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"
import download from "../../images/download.svg"

import PrivateRoute from "../../components/privateRoute"

const WithdrawalMember = props => {
  const { getWithdrawalMemberListExcel, withdrawalMemberList, getWithdrawalMemberList, studentGroupList, getStudentGroupList, delWithdrawalMember } =
    props

  const isMounted = useRef(false)
  const [userType, setUserType] = useState()

  // pagination
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(1)

  // popup info
  const [popupInfo, setPopupInfo] = useState(undefined)
  const [basicInfoPopup, setBasicInfoPopup] = useState(false)
  const [createdStudiesPopup, setCreatedStudiesPopup] = useState(false)
  const [rolledInStudiesPopup, setRolledInStudiesPopup] = useState(false)
  const [accumulatedToDoPopup, setAccumulatedToDoPopup] = useState(false)
  const [accumulatedTimePopup, setAccumulatedTimePopup] = useState(false)
  const [rankingPopup, setRankingPopup] = useState(false)
  const [pointPopup, setPointPopup] = useState(false)
  const [paidPopup, setPaidPopup] = useState(false)
  const [friendPopup, setFriendPopup] = useState(false)

  //date 세팅용 state
  const [calendarPopup, setCalendarPopup] = useState(false)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [date, setDate] = useState(moment())
  const [popupType, setPopupType] = useState(false)

  const [userName, setUserName] = useState(0) // -1: 아무것도 선택 안함,  0: 이름,  1: 스크린 닉네임  2: 휴대전화번호
  const [userInput, setUserInput] = useState("") // 회원 이름칸 Input Box
  const [selectedGroup, setSelectedGroup] = useState("") // 학습자 그룹 드롭박스 기본값: 전체
  const [selectedGrade, setSelectedGrade] = useState("") // 학년 드롭박스 기본값: 전체
  const [affiliation, setAffiliation] = useState(0) // 소속 체크박스 (0:전체, 1:학원, 2:컨설턴트, 3:개인)
  const [affiliationType, setAffiliationType] = useState({ carmSchool: true, consultant: true, alone: true })

  // 결과 테이블
  const [allCheck, setAllCheck] = useState(false)
  const [checkList, setCheckList] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ])

  // 학생 소속 드롭박스 값들
  const [studentGroupSeqLevel1, setStudentGroupSeqLevel1] = useState([])
  const [studentGroupSeqLevel2, setStudentGroupSeqLevel2] = useState([])

  const UserNameOnClick = type => {
    if (userName === type) {
      setUserName(-1)
    } else {
      setUserName(type)
    }
  }

  useEffect(() => {
    async function getLists() {
      await getStudentGroupList()
    }

    async function resetLists() {
      await getWithdrawalMemberList()
    }

    getLists()
    setUserType(localStorage.loginUserType)

    return () => {
      resetLists()
    }
  }, [])

  useEffect(() => {
    setStudentGroupSeqLevel1(studentGroupList)
  }, [studentGroupList])

  useEffect(() => {
    if (studentGroupSeqLevel1 && studentGroupSeqLevel1?.studentGroups) {
      setStudentGroupSeqLevel2((studentGroupSeqLevel1?.studentGroups.filter(i => i?.level1?.level1Seq === selectedGroup))[0]?.level1?.level2)
    }
    setSelectedGrade("")
  }, [selectedGroup])

  // pagination 페이지 바꿀 때
  useEffect(() => {
    if (isMounted.current) {
      handleSearch(current - 1)
      setCheckList([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false])
    } else {
      isMounted.current = true
    }
  }, [current])

  useEffect(() => {
    if (allCheck) {
      setCheckList([true, true, true, true, true, true, true, true, true, true, true, true, true, true, true])
    } else {
      setCheckList([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false])
    }
  }, [allCheck])

  useEffect(() => {
    setTotal(withdrawalMemberList?.totalCount)
  }, [withdrawalMemberList])

  const ChangeCheckList = item => {
    let list_ = []
    for (let i = 0; i < checkList.length; i++) {
      if (i === item) {
        list_.push(!checkList[i])
      } else {
        list_.push(checkList[i])
      }
    }
    setCheckList(list_)
  }

  const handleDelete = async () => {
    const seq = []
    if (withdrawalMemberList?.withdrawUsers) {
      if (withdrawalMemberList?.withdrawUsers.length > 0) {
        for (let i = 0; i < withdrawalMemberList?.withdrawUsers.length; i++) {
          if (checkList[i] === true) {
            seq.push(withdrawalMemberList?.withdrawUsers[i].userSeq)
          }
        }
        if (seq.length === 0) {
          alert("삭제할 사용자를 선택해주세요.")
        } else {
          const payload = { userSeqs: seq }
          console.log(payload)
          const result = await delWithdrawalMember(payload)
          if (result) {
            handleSearch(current - 1, true)
            setCheckList([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false])
            popupFn("삭제되었습니다.")
          }
        }
      } else {
        alert("삭제할 사용자를 선택해주세요.")
      }
    }
  }

  const handleSearch = async (num, isSearch = false) => {
    if (endDate < startDate) {
      alert("조회 기간을 제대로 설정해주세요.")
    } else {
      const start_date = toStringByFormatting(startDate)
      const end_date = toStringByFormatting(endDate)
      const word_type = userName === 0 ? "NAME" : userName === 1 ? "NICKNAME" : userName === 2 ? "PHONE" : ""
      const word_ = userName === -1 ? "" : userInput
      const student_group_seq_level_1 = selectedGroup
      const student_group_seq_level_2 = selectedGrade
      let belonging_type_string = ""
      if (affiliationType.carmSchool && affiliationType.consultant && affiliationType.alone) {
        belonging_type_string = "ALL"
      } else {
        if (affiliationType.carmSchool) belonging_type_string += "CRAM_SCHOOL,"
        if (affiliationType.consultant) belonging_type_string += "CONSULTANT,"
        if (affiliationType.alone) belonging_type_string += "ALONE"
      }

      if (belonging_type_string.substr(-1) == ",") belonging_type_string = belonging_type_string.slice(0, -1)
      const belonging_type = belonging_type_string

      console.log(`
        start_date: ${start_date}
        end_date: ${end_date}
        word_type: ${word_type}
        word_: ${word_}
        student_group_seq_level_1: ${student_group_seq_level_1}
        student_group_seq_level_2: ${student_group_seq_level_2}
        belonging_type: ${belonging_type}
      `)

      const result = await getWithdrawalMemberList({
        startDate: start_date,
        endDate: end_date,
        wordType: word_type,
        word: word_,
        studentGroupSeqLevel1: student_group_seq_level_1,
        studentGroupSeqLevel2: student_group_seq_level_2,
        belongingType: belonging_type,
        page: num,
      })
    }
  }

  const ChangePopupInfo = seq => {
    setPopupInfo(seq)
    setBasicInfoPopup(true)
  }
  const exDownFn = async () => {
    const start_date = toStringByFormatting(startDate)
    const end_date = toStringByFormatting(endDate)
    const word_type = userName === 0 ? "NAME" : userName === 1 ? "NICKNAME" : userName === 2 ? "PHONE" : ""
    const word_ = userName === -1 ? "" : userInput
    const student_group_seq_level_1 = selectedGroup
    const student_group_seq_level_2 = selectedGrade
    let belonging_type_string = ""
    if (affiliationType.carmSchool && affiliationType.consultant && affiliationType.alone) {
      belonging_type_string = "ALL"
    } else {
      if (affiliationType.carmSchool) belonging_type_string += "CRAM_SCHOOL,"
      if (affiliationType.consultant) belonging_type_string += "CONSULTANT,"
      if (affiliationType.alone) belonging_type_string += "ALONE"
    }

    if (belonging_type_string.substr(-1) == ",") belonging_type_string = belonging_type_string.slice(0, -1)
    const belonging_type = belonging_type_string
    const result = await getWithdrawalMemberListExcel({
      startDate: start_date,
      endDate: end_date,
      wordType: word_type,
      word: word_,
      studentGroupSeqLevel1: student_group_seq_level_1,
      studentGroupSeqLevel2: student_group_seq_level_2,
      belongingType: belonging_type,
      page: current - 1,
    })
    if (result) {
      await downExcel({ result: result, fileName: "탈퇴 회원" })
    }
  }

  return (
    <PrivateRoute>
      <>
        {basicInfoPopup && popupInfo != undefined && (
          <BasicInfoPopup
            userSeq={popupInfo}
            type={0}
            onClose={() => {
              setBasicInfoPopup(false)
              setPopupInfo(undefined)
            }}
          />
        )}
        {createdStudiesPopup && popupInfo != undefined && (
          <CreatedStudiesPopup
            userSeq={popupInfo}
            onClose={() => {
              setCreatedStudiesPopup(false)
              setPopupInfo(undefined)
            }}
          />
        )}
        {rolledInStudiesPopup && popupInfo != undefined && (
          <RolledInStudiesPopup
            userSeq={popupInfo}
            onClose={() => {
              setRolledInStudiesPopup(false)
              setPopupInfo(undefined)
            }}
          />
        )}
        {accumulatedToDoPopup && popupInfo != undefined && (
          <AccumulatedToDoPopup
            userSeq={popupInfo}
            onClose={() => {
              setAccumulatedToDoPopup(false)
              setPopupInfo(undefined)
            }}
          />
        )}
        {accumulatedTimePopup && popupInfo != undefined && (
          <AccumulatedTimePopup
            userSeq={popupInfo}
            onClose={() => {
              setAccumulatedTimePopup(false)
              setPopupInfo(undefined)
            }}
          />
        )}
        {rankingPopup && popupInfo != undefined && (
          <RankingPopup
            userSeq={popupInfo}
            open={rankingPopup}
            onClose={() => {
              setRankingPopup(false)
              setPopupInfo(undefined)
            }}
          />
        )}
        {pointPopup && popupInfo != undefined && (
          <PointPopup
            open={PointPopup}
            userSeq={popupInfo}
            onClose={() => {
              setPointPopup(false)
              setPopupInfo(undefined)
            }}
          />
        )}
        {paidPopup && popupInfo != undefined && (
          <PaidPopup
            open={paidPopup}
            userSeq={popupInfo}
            onClose={() => {
              setPaidPopup(false)
              setPopupInfo(undefined)
            }}
          />
        )}
        {friendPopup && popupInfo != undefined && (
          <FriendPopup
            open={friendPopup}
            userSeq={popupInfo}
            onClose={() => {
              setFriendPopup(false)
              setPopupInfo(undefined)
            }}
          />
        )}
        {/* {basicInfoPopup && popupInfo != undefined && (
          <BasicInfoPopup
            userSeq={popupInfo}
            type={1}
            onClose={() => {
              setBasicInfoPopup(false)
              setPopupInfo(undefined)
            }}
          />
        )} */}
        <Layout>
          <SEO title="탈퇴회원 관리" />
          <TopInfo title={["탈퇴 회원 관리", "회원 관리", "탈퇴 회원 관리"]} />
          <div className="withdrawal-member-container">
            <div className="withdrawal-member-table-wrapper">
              <div className="table-row bottom-border">
                <div className="element-l right-border">조회 기간</div>
                <div className="element-r right-border middle-box">
                  <div className="fgb-date-form">
                    <img src={icon_calendar} />
                    <div
                      className="fgb-date-input"
                      onClick={() => {
                        setPopupType(false)
                        setCalendarPopup(true)
                      }}
                    >
                      <p>{startDate.isValid() ? startDate.format("YYYY.MM.DD") : "시작일"}</p>
                    </div>
                    {calendarPopup && (
                      <div className="calendar-popup-back">
                        <div className="calendar-popup-div">
                          <CalendarComponent
                            date={date}
                            setDate={setDate}
                            selectedDate={popupType == false ? startDate : endDate}
                            setSelectedDate={popupType == false ? setStartDate : setEndDate}
                            popup={setCalendarPopup}
                          />
                        </div>
                      </div>
                    )}
                    <p>~</p>
                    <img src={icon_calendar} />
                    <div
                      className="fgb-date-input"
                      onClick={() => {
                        setPopupType(true)
                        setCalendarPopup(true)
                      }}
                    >
                      <p>{endDate.isValid() ? endDate.format("YYYY.MM.DD") : "종료일"}</p>
                    </div>
                  </div>
                </div>
                <div className="element-l right-border">이름</div>
                <div className="element-r no-border big-box">
                  <div className="radio-btn-wrapper" onClick={() => UserNameOnClick(0)}>
                    <img src={userName == 0 ? radio_on : radio_off} />
                    <p>이름</p>
                  </div>
                  <div className="radio-btn-wrapper" onClick={() => UserNameOnClick(1)}>
                    <img src={userName == 1 ? radio_on : radio_off} />
                    <p>스크린 닉네임</p>
                  </div>
                  <div className="radio-btn-wrapper" onClick={() => UserNameOnClick(2)}>
                    <img src={userName == 2 ? radio_on : radio_off} />
                    <p>휴대전화번호</p>
                  </div>
                  <input className="user-info-input-box" value={userInput} onChange={e => setUserInput(e.target.value)} />
                </div>
              </div>
              <div className="table-row no-border">
                <div className="element-l right-border">학습자 그룹</div>
                <div className="element-r right-border middle-box">
                  <div style={{ width: "160px", marginRight: "8px" }}>
                    <select
                      className="form-select"
                      style={{ backgroundColor: "#F2F8FC", border: "none", cursor: "pointer" }}
                      onChange={e => setSelectedGroup(e.target.value)}
                    >
                      <option className="" value="">
                        그룹 전체
                      </option>
                      {studentGroupSeqLevel1 &&
                        studentGroupSeqLevel1?.studentGroups &&
                        studentGroupSeqLevel1?.studentGroups.map((i, idx) => (
                          <option value={i?.level1?.level1Seq} key={idx}>
                            {i?.level1?.level1Name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div style={{ width: "111px" }}>
                    <select
                      className="form-select"
                      style={{ backgroundColor: "#F2F8FC", border: "none", cursor: "pointer" }}
                      onChange={e => setSelectedGrade(e.target.value)}
                    >
                      <option className="" value="">
                        학년 전체
                      </option>
                      {studentGroupSeqLevel2 &&
                        studentGroupSeqLevel2.map((i, idx) => (
                          <option value={i.level2Seq} key={idx}>
                            {i.level2Name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="element-l right-border">소속</div>
                <div className="element-r no-border big-box">
                  <div className="check-box-wrapper" onClick={() => setAffiliationType(0)}>
                    <img src={affiliationType.carmSchool && affiliationType.consultant && affiliationType.alone ? checked_on : checked_off} />
                    <p>전체</p>
                  </div>
                  <div
                    className="check-box-wrapper"
                    onClick={() => setAffiliationType({ ...affiliationType, carmSchool: !affiliationType.carmSchool })}
                  >
                    <img src={affiliationType.carmSchool ? checked_on : checked_off} />
                    <p>학원</p>
                  </div>
                  <div
                    className="check-box-wrapper"
                    onClick={() => setAffiliationType({ ...affiliationType, consultant: !affiliationType.consultant })}
                  >
                    <img src={affiliationType.consultant ? checked_on : checked_off} />
                    <p>컨설턴트</p>
                  </div>
                  <div className="check-box-wrapper" onClick={() => setAffiliationType({ ...affiliationType, alone: !affiliationType.alone })}>
                    <img src={affiliationType.alone ? checked_on : checked_off} />
                    <p>개인</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="search-btn-wrapper">
              <div className="btn btn-primary font-white fgb-btn-l" onClick={() => handleSearch(0)}>
                조회
              </div>
            </div>

            <div className="result-info-wrapper">
              <div className="people-num">{withdrawalMemberList?.totalCount ? withdrawalMemberList.totalCount : 0}명</div>
              <div className="two-btn-wrapper">
                {/* <div className="delete-btn" onClick={handleDelete}>
                  선택 삭제
                </div> */}
                <div className="btn btn-primary font-white reverse fgb-btn-ms clear-button me-2" onClick={handleDelete}>
                  선택 삭제
                </div>
                <div onClick={exDownFn} className="excel-download-btn btn fgb-btn-m fgb-btn-grean" style={{ borderRadius: "6px " }}>
                  <img src={download} />
                  <p>엑셀 다운로드</p>
                </div>
              </div>
            </div>

            {withdrawalMemberList?.withdrawUsers !== undefined && withdrawalMemberList?.withdrawUsers.length !== 0 ? (
              <>
                <div className="fgb-table-div">
                  <table className={`fgb-table`}>
                    <thead>
                      <tr className={`fgb-table-title-tr`}>
                        <td className="check-box-img" onClick={() => setAllCheck(!allCheck)}>
                          <img src={allCheck ? checked_on : checked_off} style={{ cursor: "pointer" }} />
                        </td>
                        {TITLE.map((i, idx) => (
                          <td key={idx}>{i}</td>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {withdrawalMemberList?.withdrawUsers.map((i, idx) => (
                        <tr className={`fgb-table-tr`} key={idx}>
                          <td onClick={() => ChangeCheckList(idx)}>
                            <img className="check-box-img" src={checkList[idx] ? checked_on : checked_off} />
                          </td>
                          <td>{i?.studentGroupName}</td>
                          <td>{i?.studentGroupGrade}</td>
                          <td>{i?.affiliation}</td>
                          <td
                            onClick={() => {
                              setPopupInfo(i?.userSeq)
                              setBasicInfoPopup(true)
                            }}
                            className="td-with-onclick"
                          >
                            {i?.name}
                          </td>
                          <td
                            onClick={() => {
                              setPopupInfo(i?.userSeq)
                              setBasicInfoPopup(true)
                            }}
                          >
                            {i?.nickname}
                          </td>
                          <td>{i?.phone}</td>
                          <td>{i?.regDatetime}</td>
                          <td>{i?.withdrawDatetime}</td>
                          <td
                            onClick={() => {
                              setPopupInfo(i?.userSeq)
                              setCreatedStudiesPopup(true)
                            }}
                          >
                            {i?.createCount}
                          </td>
                          <td
                            onClick={() => {
                              setPopupInfo(i?.userSeq)
                              setRolledInStudiesPopup(true)
                            }}
                          >
                            {i?.joinCount}
                          </td>
                          <td
                            onClick={() => {
                              setPopupInfo(i?.userSeq)
                              setAccumulatedToDoPopup(true)
                            }}
                          >
                            {i?.todoCount}
                          </td>
                          <td
                            onClick={() => {
                              setPopupInfo(i?.userSeq)
                              setAccumulatedTimePopup(true)
                            }}
                          >
                            {i?.accumulatedStudyTime}
                          </td>
                          <td
                            onClick={() => {
                              setPopupInfo(i?.userSeq)
                              setRankingPopup(true)
                            }}
                          >
                            {i?.ranking}
                          </td>
                          <td>{i?.achievementRate}</td>
                          <td
                            onClick={() => {
                              setPopupInfo(i?.userSeq)
                              setPointPopup(true)
                            }}
                          >
                            {i?.point}
                          </td>
                          <td
                            onClick={() => {
                              setPopupInfo(i?.userSeq)
                              setPaidPopup(true)
                            }}
                          >
                            {i?.accumulatedPayAmount}
                          </td>
                          <td
                            onClick={() => {
                              setPopupInfo(i?.userSeq)
                              setFriendPopup(true)
                            }}
                          >
                            {i?.friendCount}
                          </td>
                          <td>{i?.region3}</td>
                          <td>{i?.email}</td>
                          <td>{i?.marketingType}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Pagination current={current} setCurrent={setCurrent} total={total} count={15} />
              </>
            ) : (
              <>
                {/* <div className="search-none-wrapper">
                <p>조회 결과가 없습니다.</p>
                <p>원하는 항목을 선택하고 '조회' 버튼을 눌러주십시오.</p>
              </div> */}
              </>
            )}
          </div>
        </Layout>
      </>
    </PrivateRoute>
  )
}

export default CommonContainer(MemberContainer(WithdrawalMember))

//테이블용 더미
const TITLE = [
  "학습자 그룹",
  "학년",
  "소속",
  "이름",
  "닉네임",
  "휴대전화번호",
  "가입일",
  "탈퇴 일시",
  "만든 캠스터디",
  "참여한 캠스터디",
  "누적 TO-DO",
  "누적 시간",
  "순위",
  "달성률(%)",
  "포인트(P)",
  "결제(₩)",
  "친구",
  "지역",
  "이메일",
  "마케팅 수신",
]
