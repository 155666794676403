import React from "react"
import { Bar } from "react-chartjs-2"
import { useSelector } from "react-redux"
//차트데이터

const DESKTOP_OPTION = {
  responsive: true,

  plugins: {
    legend: {
      display: false,
    },
  },

  scales: {
    x: {
      grid: {
        display: false, // 차트 내 세로줄 제거
      },
      stacked: false,
      ticks: {
        color: "#000000",
        padding: 10,
      },
    },
    y: {
      // min: 0,
      // max: 300,
      beginAtZero: true,
      ticks: {
        stepSize: 4,
        color: "#000000",
        padding: 10,
      },
      grid: {
        drawBorder: false, // y축 세로줄 제거
      },
    },
  },
}

const BarChart = ({ data = {}, width = 430, height = 350 }) => {
  return (
    <div style={{ height: "100%" }} className="common-bar-chart-view">
      <Bar width={width} height={width} data={data} options={DESKTOP_OPTION} />
    </div>
  )
}

export default BarChart
