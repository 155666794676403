/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect } from "react"

import "swiper/scss"

import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import CalendarComponent from "../../components/Calendar"
import TopInfo from "../../components/common/TopInfo"
import Pagination from "../../components/common/pagination"

import moment from "moment"

import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import icon_calendar from "../../images/ion_calendar-number-outline.svg"

import AddPromotionPopup from "../../popups/exhibition/AddPromotionPopup"

import ExhibitionContainer from "../../containers/ExhibitionContainer"

import PrivateRoute from "../../components/privateRoute"
import { popupFn } from "../../utils/util"

const TYPE = { LETMEUP: "렛미업 프로모션", PARTNER: "학원/컨설턴트 광고", CONTENT: "컨텐츠 광고", ETC: "기타" }
const BANNER_TYPE = { HORIZONTAL: "가로형", ROLLING: "롤링형", CARD: "카드형" }
const BANNER_POS = { TOP: "상단", HOLD: "고정", BOTTOM: "하단" }

const Promotion = props => {
  const { delPromotionAds, promotionAds, promotionAdsTotal, getPromotionAds, getPromotionAd } = props
  //date 세팅용 state
  const [calendarPopup, setCalendarPopup] = useState(false)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [date, setDate] = useState(moment())
  const [popupType, setPopupType] = useState(false)
  const [type, setType] = useState("")
  const [adType, setAdType] = useState("")

  const [isDisplayY, setIsDisplayY] = useState(true)
  const [isDisplayN, setIsDisplayN] = useState(true)

  const [isPc, setIsPc] = useState(true)
  const [isMo, setIsMo] = useState(true)

  const [isTop, setIsTop] = useState(true)
  const [isBottom, setIsBottom] = useState(true)
  const [isHold, setIsHold] = useState(true)

  const [current, setCurrent] = useState(1)
  const [checkList, setCheckList] = useState([])

  const [openPromotionPopup, setOpenPromotionPopup] = useState(false)

  const searchFn = async () => {
    if (startDate > endDate) return false
    let pos = ""
    if ((isTop && isBottom && isHold) || (!isTop && !isBottom && !isHold)) {
      pos = ""
    } else {
      if (isTop) pos += "TOP,"
      if (isBottom) pos += "BOTTOM,"
      if (isHold) pos += "HOLD"
    }
    let temp = {
      dateStart: startDate.format("YYYY-MM-DD"),
      dateEnd: endDate.format("YYYY-MM-DD"),
      type: type,
      deviceType: isPc && isMo ? "" : isPc ? "PC" : isMo ? "MOBILE" : "",
      isDisplay: isDisplayY && isDisplayN ? "" : isDisplayY ? "Y" : isDisplayN ? "N" : "",
      bannerType: adType,
      bannerPosition: pos,

      page: 0,
      count: 15,
    }
    console.log(temp)
    let result = await getPromotionAds(temp)
    if (result) {
      setCurrent(1)
      setCheckList([])
    }
  }
  const pageFn = async num => {
    if (startDate > endDate) return false
    let pos = ""
    if ((isTop && isBottom && isHold) || (!isTop && !isBottom && !isHold)) {
      pos = ""
    } else {
      if (isTop) pos += "TOP,"
      if (isBottom) pos += "BOTTOM,"
      if (isHold) pos += "HOLD"
    }
    let temp = {
      dateStart: startDate.format("YYYY-MM-DD"),
      dateEnd: endDate.format("YYYY-MM-DD"),
      type: type,
      deviceType: isPc && isMo ? "" : isPc ? "PC" : isMo ? "MOBILE" : "",
      isDisplay: isDisplayY && isDisplayN ? "" : isDisplayY ? "Y" : isDisplayN ? "N" : "",
      bannerType: adType,
      bannerPosition: pos,
      page: num - 1,
      count: 15,
    }
    let result = await getPromotionAds(temp)
    if (result) {
      setCheckList([])
    }
  }

  const openFn = async seq => {
    let result = await getPromotionAd(seq)
    if (result) {
      setOpenPromotionPopup(true)
    }
  }
  const delFn = async () => {
    if (checkList.length < 1) return false
    let temp = {
      promotionAdSeqs: checkList,
    }
    console.log(temp)
    let result = await delPromotionAds(temp)
    if (result) {
      popupFn("삭제되었습니다.")
      searchFn()
    }
  }

  return (
    <PrivateRoute>
      <Layout>
        <SEO title="프로모션 광고 배너 관리" />
        <TopInfo title={["프로모션 광고 배너 관리", "전시관리", "프로모션 광고 배너 관리"]} />
        <AddPromotionPopup searchFn={searchFn} open={openPromotionPopup} onClose={() => setOpenPromotionPopup(false)} />

        <div className="operation-learnerType-container">
          <div className="operation-learnerType-table ">
            <div className="operation-learnerType-table-name ">
              <div>조회 기간</div>
            </div>
            <div className="operation-learnerType-table-contents justify-content-between">
              <div className="fgb-date-form ms-2">
                <img src={icon_calendar} />
                <div
                  className="fgb-date-input"
                  onClick={() => {
                    setPopupType(false)
                    setCalendarPopup(true)
                  }}
                >
                  <p>{startDate.isValid() ? startDate.format("YYYY.MM.DD") : "시작일"}</p>
                </div>
                {calendarPopup && (
                  <div className="calendar-popup-back">
                    <div className="calendar-popup-div">
                      <CalendarComponent
                        date={date}
                        setDate={setDate}
                        selectedDate={popupType == false ? startDate : endDate}
                        setSelectedDate={popupType == false ? setStartDate : setEndDate}
                        popup={setCalendarPopup}
                      />
                    </div>
                  </div>
                )}
                <p>~</p>
                <img src={icon_calendar} />

                <div
                  className="fgb-date-input"
                  onClick={() => {
                    setPopupType(true)
                    setCalendarPopup(true)
                  }}
                >
                  <p>{endDate.isValid() ? endDate.format("YYYY.MM.DD") : "종료일"}</p>
                </div>
              </div>
            </div>
            <div style={{ marginLeft: "22px" }} className="operation-learnerType-table-name">
              <div>구분</div>
            </div>
            <div className="d-flex align-items-center">
              <div className="ms-4" style={{ width: "180px" }}>
                <select
                  className="form-select"
                  value={type}
                  onChange={e => {
                    setType(e.target.value)
                  }}
                >
                  <option className="" value="">
                    전체
                  </option>
                  <option className="" value="LETMEUP">
                    렛미업 프로모션
                  </option>
                  <option className="" value="PARTNER">
                    학원/컨설턴트 광고
                  </option>
                  <option className="" value="CONTENT">
                    컨텐츠 광고
                  </option>
                  <option className="" value="ETC">
                    기타
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div style={{ borderBottom: "none" }} className="operation-learnerType-table">
            <div className="operation-learnerType-table-name">
              <div>전시여부</div>
            </div>
            <div className="d-flex align-items-center">
              <img
                onClick={() => {
                  if (isDisplayY && isDisplayN) {
                    setIsDisplayY(false)
                    setIsDisplayN(false)
                  } else {
                    setIsDisplayY(true)
                    setIsDisplayN(true)
                  }
                }}
                className="ms-2 me-2"
                src={isDisplayY && isDisplayN ? checked_on : checked_off}
              />
              <p>전체 </p>
              <img onClick={() => setIsDisplayY(!isDisplayY)} className="ms-3 me-2" src={isDisplayY ? checked_on : checked_off} />
              <p>전시</p>
              <img onClick={() => setIsDisplayN(!isDisplayN)} className="ms-3 me-2" src={isDisplayN ? checked_on : checked_off} />
              <p className="">비전시</p>
            </div>
            <div style={{ marginLeft: "105px" }} className="operation-learnerType-table-name">
              <div>유형</div>
            </div>
            <div className="d-flex align-items-center">
              <div className="ms-4" style={{ width: "180px" }}>
                <select
                  className="form-select"
                  value={adType}
                  onChange={e => {
                    setAdType(e.target.value)
                  }}
                >
                  <option className="" value="">
                    전체
                  </option>
                  <option className="" value="HORIZONTAL">
                    가로형
                  </option>
                  <option className="" value="CARD">
                    카드형
                  </option>
                  <option className="" value="ROLLING">
                    롤링형
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="operation-learnerType-table">
            <div className="operation-learnerType-table-name">
              <div>표시여부</div>
            </div>
            <div className="d-flex align-items-center">
              <img
                onClick={() => {
                  if (isTop && isBottom && isHold) {
                    setIsTop(false)
                    setIsBottom(false)
                    setIsHold(false)
                  } else {
                    setIsTop(true)
                    setIsBottom(true)
                    setIsHold(true)
                  }
                }}
                className="ms-2 me-2"
                src={isTop && isBottom && isHold ? checked_on : checked_off}
              />
              <p>전체 </p>
              <img onClick={() => setIsTop(!isTop)} className="ms-3 me-2" src={isTop ? checked_on : checked_off} />
              <p>상단</p>
              <img onClick={() => setIsBottom(!isBottom)} className="ms-3 me-2" src={isBottom ? checked_on : checked_off} />
              <p>하단</p>
              <img onClick={() => setIsHold(!isHold)} className="ms-3 me-2" src={isHold ? checked_on : checked_off} />
              <p>고정</p>
            </div>
            <div className="operation-learnerType-table-name ms-5">
              <div>플랫폼</div>
            </div>
            <div className="d-flex align-items-center">
              <img
                onClick={() => {
                  if (isPc && isMo) {
                    setIsPc(false)
                    setIsMo(false)
                  } else {
                    setIsPc(true)
                    setIsMo(true)
                  }
                }}
                className="ms-2 me-2"
                src={isPc && isMo ? checked_on : checked_off}
              />
              <p>전체 </p>
              <img onClick={() => setIsPc(!isPc)} className="ms-3 me-2" src={isPc ? checked_on : checked_off} />
              <p>PC</p>
              <img onClick={() => setIsMo(!isMo)} className="ms-3 me-2" src={isMo ? checked_on : checked_off} />
              <p>Mobile</p>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <div onClick={searchFn} className="btn btn-primary font-white fgb-btn-l">
              조회
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mt-4">
            <p>{promotionAdsTotal.toLocaleString()}건</p>
            <div className="d-flex justify-content-end">
              <div onClick={delFn} className="btn btn-primary font-white reverse fgb-btn-ms clear-button me-2">
                선택 삭제
              </div>
              <div onClick={() => setOpenPromotionPopup(true)} className="btn btn-primary font-white fgb-btn-m me-2">
                프로모션 광고
                <br /> 배너 등록
              </div>
            </div>
          </div>
          <div className="introduce-study-table mt-3">
            <div className="fgb-table-div">
              <table className={`fgb-table mb-4`}>
                <thead>
                  <tr className={`fgb-table-title-tr`}>
                    <td>
                      <img
                        onClick={() => {
                          if (checkList.length == promotionAds.length) {
                            setCheckList([])
                          } else {
                            let temp = []
                            for (let i of promotionAds) {
                              temp = [...temp, i.promotionAdSeq]
                            }
                            setCheckList(temp)
                          }
                        }}
                        className="ms-4 me-2"
                        src={checkList.length == promotionAds.length && promotionAds.length > 0 ? checked_on : checked_off}
                      />
                    </td>
                    {[
                      "구분",
                      "유형",
                      "표시위치",
                      "플랫폼",
                      "프로모션 제목",
                      "이미지파일명",
                      " 이동 경로 링크",
                      "전시여부",
                      "조회수",
                      "등록자",
                      "등록일시",
                    ].map((i, idx) => (
                      <td key={idx}>{i}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {promotionAds.map((i, idx) => (
                    <tr className={`fgb-table-tr ${checkList.indexOf(i.promotionAdSeq) != -1 ? "active" : ""}`} key={idx}>
                      <td>
                        <img
                          onClick={() => {
                            let check = checkList.indexOf(i.promotionAdSeq)
                            if (check == -1) {
                              setCheckList([...checkList, i.promotionAdSeq])
                            } else {
                              let temp = [...checkList]
                              temp.splice(check, 1)
                              setCheckList(temp)
                            }
                          }}
                          className="ms-4 me-2"
                          src={checkList.indexOf(i.promotionAdSeq) != -1 ? checked_on : checked_off}
                        />
                      </td>
                      <td>{TYPE[i.type]}</td>
                      <td>{BANNER_TYPE[i.bannerType]}</td>
                      <td>{BANNER_POS[i.bannerPosition]}</td>
                      <td>{i.deviceType}</td>
                      <td style={{ cursor: "pointer" }} onClick={() => openFn(i.promotionAdSeq)}>
                        {i.title}
                      </td>
                      <td>{i.fileName}</td>
                      <td>{i.pageUrl}</td>
                      <td>{i.isDisplay}</td>
                      <td>{i.viewCount}</td>
                      <td>{i.adminName}</td>
                      <td>{moment(i.regDatetime).format("YYYY/MM/DD HH:MM")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination fn={pageFn} const={15} current={current} setCurrent={setCurrent} total={promotionAdsTotal} />
          </div>
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default ExhibitionContainer(Promotion)
