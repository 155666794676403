import React, { useState } from "react"
import LayerPopupLayout from "../LayerPopupLayout"
import MasterContainer from "../../containers/MasterContainer"
import Validator from "validator"
import { debounce } from "lodash"
import { dataCheckFn, popupFn } from "../../utils/util"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import moment from "moment"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"
import { useEffect } from "react"

import ModalPopup from "../ModalPopup"

const PermissionEditPopup = props => {
  const {
    emptyAdminMasterPopList,
    getAdminMasterPopList,
    getAdminsInfo,
    open,
    onClose,
    adminTypeList,
    masterPopList,
    adminInfo,
    putAdminsPermission,
    emptyAdminInfo,
  } = props
  //권한 state
  const adminProfile = dataCheckFn(adminInfo) ? adminInfo.profile : {}

  const [member, setMember] = useState(0)
  const [studySchedule, setStudySchedule] = useState(0)
  const [service, setService] = useState(0)
  const [exhibition, setExhibition] = useState(0)
  const [operation, setOperation] = useState(0)
  const [master, setMaster] = useState(0)

  const [adminType, setAdminType] = useState("")

  const [checkAdmin, setCheckAdmin] = useState("")
  const [keyword, setKeyword] = useState("")

  const [openModal, setOpenModal] = useState(false)

  const getDate = async () => {
    if (Validator.isEmpty(keyword, { ignore_whitespace: true })) return false
    let temp = {
      wordType: "NAME",
      word: keyword,
      adminTypeSeq: "",
      menu1Seq: "",
      menu2Seq: "",
      dateType: "REGISTRATION",
      startDate: moment().format("YYYY-MM-DD"),
      endDate: "2021-10-01",
    }
    console.log(temp)

    let result = await getAdminMasterPopList(temp)
    if (result) {
      setCheckAdmin("")
    }
  }
  const putAdminsPermissionFn = async () => {
    if (Object.keys(adminInfo).length == 0) {
      popupFn("변경할 관리자를 선택해주세요.")
      return false
    }
    let temp = [...adminInfo.authorities]
    for (let i of temp) {
      if (i.menu1Name == "회원관리") {
        i.authorityNumber = member
      } else if (i.menu1Name == "스터디스케줄관리") {
        i.authorityNumber = studySchedule
      } else if (i.menu1Name == "서비스상품관리") {
        i.authorityNumber = service
      } else if (i.menu1Name == "전시관리") {
        i.authorityNumber = exhibition
      } else if (i.menu1Name == "운영관리") {
        i.authorityNumber = operation
      } else if (i.menu1Name == "마스터") {
        i.authorityNumber = master
      }
    }
    let data = { adminSeq: adminProfile.adminSeq, adminTypeSeq: adminType, authorities: temp }

    let result = await putAdminsPermission(data)
    if (result) {
      popupFn("저장되었습니다.")
      closePopup()
      // setOpenModal(true)
    }
  }
  const closePopup = e => {
    emptyAdminInfo()
    onClose()
    emptyAdminMasterPopList()
  }
  const permissionFn = seq => {
    for (let i of adminTypeList) {
      if (seq == i.seq) {
        if (i.authorityNumber == 0) {
          setMember(0)
          setStudySchedule(0)
          setService(0)
          setExhibition(0)
          setOperation(0)
          setMaster(0)
        } else if (i.authorityNumber == 1) {
          setMember(1)
          setStudySchedule(1)
          setService(1)
          setExhibition(1)
          setOperation(1)
          setMaster(1)
        } else if (i.authorityNumber == 3) {
          setMember(3)
          setStudySchedule(3)
          setService(3)
          setExhibition(3)
          setOperation(3)
          setMaster(3)
        } else if (i.authorityNumber == 7) {
          setMember(3)
          setStudySchedule(3)
          setService(3)
          setExhibition(3)
          setOperation(7)
          setMaster(7)
        }
      }
    }
  }
  useEffect(() => {
    if (dataCheckFn(adminProfile)) {
      for (let i of adminTypeList) {
        if (i.name == adminProfile.adminTypeName) {
          setAdminType(i.seq)
        }
      }
      for (let i of adminInfo.authorities) {
        if (i.menu1Name == "회원관리") {
          setMember(i.authorityNumber)
        } else if (i.menu1Name == "스터디스케줄관리") {
          setStudySchedule(i.authorityNumber)
        } else if (i.menu1Name == "서비스상품관리") {
          setService(i.authorityNumber)
        } else if (i.menu1Name == "전시관리") {
          setExhibition(i.authorityNumber)
        } else if (i.menu1Name == "운영관리") {
          setOperation(i.authorityNumber)
        } else if (i.menu1Name == "마스터") {
          setMaster(i.authorityNumber)
        }
      }
    }
  }, [adminInfo])
  useEffect(() => {
    setMember(0)
    setStudySchedule(0)
    setService(0)
    setExhibition(0)
    setOperation(0)
    setMaster(0)
  }, [open])
  return (
    <LayerPopupLayout open={open} onClose={() => closePopup()} isCloseBtn transparency zIndex={101}>
      <ModalPopup open={openModal} onClose={() => setOpenModal(false)} contents="저장되었습니다." />
      <div className="permission-info-popup-container layer-popup-container">
        <p className="popup-title mb-3">권한 등록/변경</p>
        <div className="admin-account-info-container ">
          <div className="admin-account-info-box">
            <div className="admin-account-info-box-row div-3">
              <div className="admin-account-info-title-p ">운영자 정보</div>
              <div className="admin-account-info-div">
                <div className="admin-account-info-div-2 justify-content-end">
                  <input
                    value={keyword}
                    onChange={e => setKeyword(e.target.value)}
                    type="text"
                    placeholder="이름 검색"
                    className="fgb-input input-box"
                  />
                  <button onClick={getDate} className="btn btn-primary font-white fgb-btn-s ms-2">
                    조회
                  </button>
                </div>
                <div style={{ alignItems: "flex-start", overflowY: "auto" }} className="admin-account-info-div-3">
                  <table className={`fgb-table mb-3`}>
                    <thead>
                      <tr className={`fgb-table-title-tr`}>
                        {["", "ID", "이름", "소속", "휴대전화번호"].map((i, idx) => (
                          <td key={idx}>{i}</td>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {masterPopList.map((i, idx) => (
                        <tr key={idx} className={`fgb-table-tr`}>
                          <td>
                            <img
                              onClick={async () => {
                                setCheckAdmin(i.adminSeq)
                                await getAdminsInfo(i.adminSeq)
                              }}
                              src={i.adminSeq == checkAdmin ? radio_on : radio_off}
                            />
                          </td>
                          <td>{i.id}</td>
                          <td>{i.name}</td>
                          <td>{i.affiliation}</td>
                          <td>{i.phone}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="admin-account-info-box-row div-1">
              <div className="admin-account-info-title-p ">운영자 유형</div>
              <div className="admin-account-info-div">
                <div style={{ width: "190px", margin: "10px 5px" }}>
                  <select
                    value={adminType}
                    className="form-select"
                    style={{ fontSize: "14px" }}
                    onChange={e => {
                      setAdminType(e.target.value)
                      console.log(e.target.value)
                      permissionFn(e.target.value)
                    }}
                  >
                    {adminTypeList.map((i, idx) => (
                      <option key={idx} className="" value={i.seq}>
                        {i.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="admin-account-info-box-row">
              <div className="admin-account-info-title-p">접근권한</div>
              <div className="admin-account-info-div">
                <div className="authority-table-top-div justify-content-end mt-2 mb-2">
                  <button
                    onClick={() => {
                      setMember(3)
                      setStudySchedule(3)
                      setService(3)
                      setExhibition(3)
                      setOperation(7)
                      setMaster(7)
                    }}
                    className="btn btn-primary font-white fgb-btn-s ms-2"
                  >
                    전체
                  </button>
                  <button
                    onClick={() => {
                      setMember(1)
                      setStudySchedule(1)
                      setService(1)
                      setExhibition(1)
                      setOperation(1)
                      setMaster(1)
                    }}
                    className="btn btn-primary font-white fgb-btn-s ms-2"
                  >
                    조회
                  </button>
                  <button
                    onClick={() => {
                      setMember(3)
                      setStudySchedule(3)
                      setService(3)
                      setExhibition(3)
                      setOperation(3)
                      setMaster(3)
                    }}
                    className="btn btn-primary font-white fgb-btn-m ms-2"
                  >
                    등록/변경
                  </button>
                </div>
                <table className={`fgb-table mb-3`}>
                  <thead>
                    <tr className={`fgb-table-title-tr`}>
                      {["", "1DEPTH 메뉴", "상태"].map((i, idx) => (
                        <td key={idx}>{i}</td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={`fgb-table-tr`}>
                      <td>{1}</td>
                      <td>회원관리</td>
                      <td className="authority-table-tr-edit">
                        <img
                          onClick={() => {
                            if (member >= 3) {
                              setMember(0)
                            } else {
                              setMember(3)
                            }
                          }}
                          src={member >= 3 ? checked_on : checked_off}
                        />
                        전체
                        <img
                          onClick={() => {
                            if (member >= 1) {
                              setMember(0)
                            } else {
                              setMember(1)
                            }
                          }}
                          src={member >= 1 ? checked_on : checked_off}
                        />
                        조회
                        <img
                          onClick={() => {
                            if (member >= 3) {
                              setMember(0)
                            } else {
                              setMember(3)
                            }
                          }}
                          src={member >= 3 ? checked_on : checked_off}
                        />
                        등록/변경
                      </td>
                    </tr>
                    <tr className={`fgb-table-tr`}>
                      <td>{2}</td>
                      <td>스터디 스케줄 관리</td>
                      <td className="authority-table-tr-edit">
                        <img
                          onClick={() => {
                            if (studySchedule >= 3) {
                              setStudySchedule(0)
                            } else {
                              setStudySchedule(3)
                            }
                          }}
                          src={studySchedule >= 3 ? checked_on : checked_off}
                        />
                        전체
                        <img
                          onClick={() => {
                            if (studySchedule >= 1) {
                              setStudySchedule(0)
                            } else {
                              setStudySchedule(1)
                            }
                          }}
                          src={studySchedule >= 1 ? checked_on : checked_off}
                        />
                        조회
                        <img
                          onClick={() => {
                            if (studySchedule >= 3) {
                              setStudySchedule(0)
                            } else {
                              setStudySchedule(3)
                            }
                          }}
                          src={studySchedule >= 3 ? checked_on : checked_off}
                        />
                        등록/변경
                      </td>
                    </tr>
                    <tr className={`fgb-table-tr`}>
                      <td>{3}</td>
                      <td>서비스 상품관리</td>
                      <td className="authority-table-tr-edit">
                        <img
                          onClick={() => {
                            if (service >= 3) {
                              setService(0)
                            } else {
                              setService(3)
                            }
                          }}
                          src={service >= 3 ? checked_on : checked_off}
                        />
                        전체
                        <img
                          onClick={() => {
                            if (service >= 1) {
                              setService(0)
                            } else {
                              setService(1)
                            }
                          }}
                          src={service >= 1 ? checked_on : checked_off}
                        />
                        조회
                        <img
                          onClick={() => {
                            if (service >= 3) {
                              setService(0)
                            } else {
                              setService(3)
                            }
                          }}
                          src={service >= 3 ? checked_on : checked_off}
                        />
                        등록/변경
                      </td>
                    </tr>

                    <tr className={`fgb-table-tr`}>
                      <td>{4}</td>
                      <td>전시관리</td>
                      <td className="authority-table-tr-edit">
                        <img
                          onClick={() => {
                            if (exhibition >= 3) {
                              setExhibition(0)
                            } else {
                              setExhibition(3)
                            }
                          }}
                          src={exhibition >= 3 ? checked_on : checked_off}
                        />
                        전체
                        <img
                          onClick={() => {
                            if (exhibition >= 1) {
                              setExhibition(0)
                            } else {
                              setExhibition(1)
                            }
                          }}
                          src={exhibition >= 1 ? checked_on : checked_off}
                        />
                        조회
                        <img
                          onClick={() => {
                            if (exhibition >= 3) {
                              setExhibition(0)
                            } else {
                              setExhibition(3)
                            }
                          }}
                          src={exhibition >= 3 ? checked_on : checked_off}
                        />
                        등록/변경
                      </td>
                    </tr>
                    <tr className={`fgb-table-tr`}>
                      <td>{5}</td>
                      <td>운영관리</td>
                      <td className="authority-table-tr-edit">
                        <img
                          onClick={() => {
                            if (operation >= 7) {
                              setOperation(0)
                            } else {
                              setOperation(7)
                            }
                          }}
                          src={operation >= 7 ? checked_on : checked_off}
                        />
                        전체
                        <img
                          onClick={() => {
                            if (operation >= 1) {
                              setOperation(0)
                            } else {
                              setOperation(1)
                            }
                          }}
                          src={operation >= 1 ? checked_on : checked_off}
                        />
                        조회
                        <img
                          onClick={() => {
                            if (operation >= 3) {
                              setOperation(0)
                            } else {
                              setOperation(3)
                            }
                          }}
                          src={operation >= 3 ? checked_on : checked_off}
                        />
                        등록/변경
                        <img
                          onClick={() => {
                            if (operation >= 7) {
                              setOperation(0)
                            } else {
                              setOperation(7)
                            }
                          }}
                          src={operation >= 7 ? checked_on : checked_off}
                        />
                        승인/반려
                      </td>
                    </tr>
                    <tr className={`fgb-table-tr`}>
                      <td>{6}</td>
                      <td>마스터</td>
                      <td className="authority-table-tr-edit">
                        <img
                          onClick={() => {
                            if (master >= 7) {
                              setMaster(0)
                            } else {
                              setMaster(7)
                            }
                          }}
                          src={master >= 7 ? checked_on : checked_off}
                        />
                        전체
                        <img
                          onClick={() => {
                            if (master >= 1) {
                              setMaster(0)
                            } else {
                              setMaster(1)
                            }
                          }}
                          src={master >= 1 ? checked_on : checked_off}
                        />
                        조회
                        <img
                          onClick={() => {
                            if (master >= 3) {
                              setMaster(0)
                            } else {
                              setMaster(3)
                            }
                          }}
                          src={master >= 3 ? checked_on : checked_off}
                        />
                        등록/변경
                        <img
                          onClick={() => {
                            if (master >= 7) {
                              setMaster(0)
                            } else {
                              setMaster(7)
                            }
                          }}
                          src={master >= 7 ? checked_on : checked_off}
                        />
                        승인/반려
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="permission-info-popup-btn-view">
          <button onClick={() => closePopup()} className="btn btn-primary fgb-btn-gray fgb-btn-l ">
            취소
          </button>
          <button onClick={putAdminsPermissionFn} className="btn btn-primary font-white fgb-btn-l ">
            저장
          </button>
        </div>
      </div>
    </LayerPopupLayout>
  )
}

export default MasterContainer(PermissionEditPopup)
const DUMY = [
  {
    group: "a",
    class: 2,
    name: "asdfew",
    num: 6,
  },
  {
    group: "a",
    class: 2,
    name: "asdfew",
    num: 6,
  },
  {
    group: "a",
    class: 2,
    name: "asdfew",
    num: 6,
  },
  {
    group: "a",
    class: 2,
    name: "asdfew",
    num: 6,
  },
  {
    group: "a",
    class: 2,
    name: "asdfew",
    num: 6,
  },
  {
    group: "a",
    class: 2,
    name: "asdfew",
    num: 6,
  },
]
