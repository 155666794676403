import React, { useEffect, useState } from "react"
import { debounce } from "lodash"

import { setConsultantProfilePopup } from "../../store/modules/popup"
import { useDispatch } from "react-redux"
import bookmark_favorite from "../../images/bookmark_favorite.svg"
import thumbs_up_like from "../../images/thumbs_up_like.svg"

const ToDoItem = props => {
  const {
    data = {
      todoTaskSeq: "1636598898494_ewa675mf",
      subject: "영어",
      book: "EBS 수능특강 영어영역",
      rangeType: "PAGE",
      rangeStart: 30,
      rangeEnd: 60,
      estimatedTimeRequired: "03:00:00",
      mediaContentUrl: null,
      mediaInfomation: null,
      comment: "차분히 풀어봅시다.",
    },
  } = props

  const cardColor = {
    중학생: 1,
    고등학생: 2,
    대학생: 3,
    "취업/자격증": 5,
    대입수험생: 4,
    공무원: 6,
    직장인: 7,
    기타: 8,
  }
  const dispatch = useDispatch()
  // studentGroupName
  return (
    <div className={`common-todo-item best-todo-item-${data.studentGroupName != null ? cardColor[data.studentGroupName] : 8} `}>
      <div className="todo-top-info-div">
        <img src={thumbs_up_like}></img>
        <p>{(data.favoriteCount || 0).toLocaleString()}</p>
        <img src={bookmark_favorite}></img>
        <p>{(data.bookmarkCount || 0).toLocaleString()}</p>
      </div>
      <p className="title">{data.title || "Study with me"}</p>
      {/* todo list */}
      <div className="todo-item-list-info">
        <p className="keywords">
          {Object.keys(data).length > 0 &&
            data.keywords.map(i => {
              let str = "#" + i + " "
              return str
            })}
        </p>
        <div className="todo-notice-item-list">
          {Object.keys(data).length > 0 &&
            data.tasks.map((i, idx) => {
              if (idx > 2) return false
              return (
                <div key={idx} className="todo-notice-item">
                  <div className="todo-notice-item-inner-top">
                    <p className="todo-notice-item-inner-title">{i.title || i.keyword}</p>
                    <div className="row">
                      {i.subject && <p className="todo-info1">{i.subject}</p>}
                      {i.book && <p className="todo-info1">{i.book}</p>}
                      {i.rangeType && (
                        <p className="todo-info2">
                          {i.rangeType == "PAGE" ? "페이지" : "챕터"} {i.rangeStart} - {i.rangeEnd}
                        </p>
                      )}
                      {i.estimatedTimeRequired && (
                        <p className="todo-info2">{i.estimatedTimeRequired != null ? `(예상소요시간 ${i.estimatedTimeRequired})` : ""}</p>
                      )}
                      {i.mediaContentUrl && <p className="todo-info3">{i.mediaContentUrl != null ? i.mediaContentUrl : ""}</p>}
                      <p className="todo-info4">{i.comment || i.content}</p>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
      <div className="bottom-text mt-3">
        <p>구독자 {data.subscriberCount || 0}명</p>
      </div>
    </div>
  )
}

export default ToDoItem
