import React, { useState } from "react"
import LayerPopupLayout from "../LayerPopupLayout"
import SignContainer from "../../containers/SignContainer"
import Validator from "validator"
import { debounce } from "lodash"
import { dataCheckFn, popupFn } from "../../utils/util"
import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"
import CloseBtn from "../../images/Close_Button.svg"
import img_popup_close_gray from "../../images/img_popup_close_gray.svg"
import { useEffect } from "react"
import OperationContainer from "../../containers/OperationContainer"

const LearnerTypePopup = props => {
  const { postCategoryStudentGroup, searchFn, open, onClose, setCategoryStudentGroupEmpty, categoryStudentGroup, putCategoryStudentGroup } = props

  const [checkList, setCheckList] = useState([])
  const [checkIdx, setCheckIdx] = useState([])
  const [depth1Name, setDepth1Name] = useState("")

  const closePopup = e => {
    onClose()
  }
  const saveFn = async () => {
    let check = false
    for (let i of checkList) {
      if (Validator.isEmpty(i.name, { ignore_whitespace: true })) check = true
    }
    if (Validator.isEmpty(depth1Name, { ignore_whitespace: true })) check = true

    if (check) {
      popupFn("비어있는 타이틀이 존재합니다.")
      return false
    }
    let temp = {
      studentGroupsDepth1: {
        studentGroupSeq: dataCheckFn(categoryStudentGroup) ? categoryStudentGroup.studentGroupSeq : "",
        name: depth1Name,
        studentGroupsDepth2: checkList,
      },
    }
    let result = false
    if (dataCheckFn(categoryStudentGroup)) {
      //수정
      result = await putCategoryStudentGroup(temp)
    } else {
      //add
      result = await postCategoryStudentGroup(temp)
    }
    if (result) {
      popupFn("저장되었습니다.")
      searchFn()
      onClose()
    }
  }
  const delFn = async () => {
    let temp = []
    for (let i = checkList.length - 1; i >= 0; i--) {
      let check = false
      for (let k of checkIdx) {
        if (k == i) {
          check = true
        }
      }
      if (!check) {
        temp = [...temp, checkList[i]]
      }
    }
    let tempReverse = []
    for (let i = temp.length - 1; i >= 0; i--) {
      tempReverse = [...tempReverse, temp[i]]
    }
    setCheckList(tempReverse)
    setCheckIdx([])
  }
  useEffect(() => {
    // if (open) setCheckList(DUMY)
    if (!open) {
      setCategoryStudentGroupEmpty()
      setCheckList([])
      setCheckIdx([])
      setDepth1Name("")
    }
  }, [open])
  useEffect(() => {
    if (dataCheckFn(categoryStudentGroup)) {
      setCheckList([...categoryStudentGroup.studentGroupsDepth2])
      setDepth1Name(categoryStudentGroup.name)
    }
  }, [categoryStudentGroup])
  return (
    <LayerPopupLayout open={open} onClose={() => closePopup()} isCloseBtn transparency zIndex={101}>
      <div className="learner-type-popup-container layer-popup-container">
        <p className="popup-title mb-3">학습자 유형 등록/수정</p>
        <div className="admin-account-info-container ">
          <div className="admin-account-info-box">
            <div className="admin-account-info-box-row div-13">
              <div className="admin-account-info-title-p ">
                학습자그룹
                <br />
                1DEPTH 타이틀
              </div>
              <div className="admin-account-info-div">
                <div style={{ width: "330px", border: "none" }} className="admin-account-info-div-2">
                  <input value={depth1Name} onChange={e => setDepth1Name(e.target.value)} type="text" className="fgb-input" />
                </div>
              </div>
            </div>

            <div className="admin-account-info-box-row">
              <div className="admin-account-info-title-p">
                학습자그룹
                <br />
                2DEPTH 타이틀
              </div>
              <div className="admin-account-info-div">
                <div className="authority-table-top-div"></div>
                <div className="d-flex align-items-center justify-content-end mt-3 mb-3">
                  <button onClick={delFn} style={{ width: "80px" }} className="btn btn-primary font-white reverse fgb-btn-ms clear-button">
                    선택 삭제
                  </button>
                  <button
                    onClick={() => {
                      setCheckList([
                        ...checkList,
                        {
                          name: "",
                          studentGroupSeq: "",
                        },
                      ])
                    }}
                    className="btn btn-primary font-white fgb-btn-s ms-2"
                  >
                    행추가
                  </button>
                </div>
                <table className={`fgb-table mb-3`}>
                  <thead>
                    <tr className={`fgb-table-title-tr`}>
                      {["", "학습자그룹 2DEPTH 상세"].map((i, idx) => (
                        <td key={idx}>{i}</td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {checkList.length > 0 &&
                      checkList.map((i, idx) => (
                        <tr key={idx} className={`fgb-table-tr`}>
                          <td>
                            <img
                              onClick={() => setCheckIdx([...checkIdx, idx])}
                              className=""
                              src={checkIdx.indexOf(idx) != -1 ? checked_on : checked_off}
                            />
                          </td>
                          <td>
                            <input
                              placeholder="필수 입력"
                              value={i.name}
                              onChange={e => {
                                let temp = [...checkList]
                                temp[idx].name = e.target.value
                                setCheckList(temp)
                              }}
                              type="text"
                              className="fgb-input"
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="admin-account-info-box-row div-1">
              <div className="admin-account-info-title-p">등록자</div>
              <div className="admin-account-info-div">
                <p className="admin-account-info-title-p">{categoryStudentGroup.regAdminName || ""}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="permission-info-popup-btn-view">
          <button onClick={() => closePopup()} className="btn btn-primary fgb-btn-gray fgb-btn-l ">
            취소
          </button>
          <button onClick={() => saveFn()} className="btn btn-primary font-white fgb-btn-l ">
            저장
          </button>
        </div>
      </div>
    </LayerPopupLayout>
  )
}

export default OperationContainer(LearnerTypePopup)
const DUMY = [
  {
    depth2name: "1학년",
    seq: "q1",
  },
  {
    depth2name: "2학년",
    seq: "q2",
  },
  {
    depth2name: "3학년",
    seq: "q3",
  },
]
