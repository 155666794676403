/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect } from "react"
import moment from "moment"

import "swiper/scss"

import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import TopInfo from "../../components/common/TopInfo"

import CalendarComponent from "../../components/Calendar"

import Pagination from "../../components/common/pagination"

import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"
import download from "../../images/download.svg"
import icon_calendar from "../../images/ion_calendar-number-outline.svg"
import SignContainer from "../../containers/SignContainer"
import { downExcel } from "../../utils/util"
import PrivateRoute from "../../components/privateRoute"
const POINT_TYPE = { MY_POINT: "내 포인트", STUDENT_POINT: "학습자 포인트" }
const PointList = props => {
  const {
    setPartnerProfilePointTotalEmpty,
    setPartnerProfilePointEmpty,
    getProfilePointsXlDown,
    getStudentGroups,
    studentGroups,
    profilePoint,
    profilePointTotal,
    getProfilePointss,
  } = props
  //date 세팅용 state
  const [calendarPopup, setCalendarPopup] = useState(false)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [date, setDate] = useState(moment())
  const [popupType, setPopupType] = useState(false)

  const [pointMine, setPointMine] = useState(false)
  const [pointLearner, setPointLearner] = useState(false)

  const [groupSeq, setGroupSeq] = useState("")

  const [current, setCurrent] = useState(1)
  const getData = async () => {
    await getStudentGroups()
  }
  const searchDataFn = async () => {
    if (startDate > endDate) return false
    let temp = {
      dateStart: startDate.format("YYYY-MM-DD"),
      dateEnd: endDate.format("YYYY-MM-DD"),
      userType: pointMine && pointLearner ? "" : pointMine ? "MY_POINT" : pointLearner ? "STUDENT_POINT" : "",
      studentGroupSeq: groupSeq,
      page: 0,
      count: 15,
    }
    let result = await getProfilePointss(temp)
    if (result) {
      setCurrent(1)
    }
  }
  const pageFn = async num => {
    if (startDate > endDate) return false
    let temp = {
      dateStart: startDate.format("YYYY-MM-DD"),
      dateEnd: endDate.format("YYYY-MM-DD"),
      userType: pointMine && pointLearner ? "" : pointMine ? "MY_POINT" : pointLearner ? "STUDENT_POINT" : "",
      studentGroupSeq: groupSeq,
      page: num - 1,
      count: 15,
    }
    let result = await getProfilePointss(temp)
    if (result) {
    }
  }
  //제휴사 포인트 목록 Xl
  const xlDownFn = async () => {
    let temp = {
      dateStart: startDate.format("YYYY-MM-DD"),
      dateEnd: endDate.format("YYYY-MM-DD"),
    }
    let result = await getProfilePointsXlDown(temp)
    if (result) {
      await downExcel({ result: result, fileName: "포인트내역" })
    }
  }
  useEffect(() => {
    getData()
    return () => {
      setPartnerProfilePointEmpty()
      setPartnerProfilePointTotalEmpty()
    }
  }, [])

  return (
    <PrivateRoute>
      <Layout>
        <SEO title="포인트 사용내역" />
        <TopInfo title={["포인트 사용내역", "내 계정 관리", "포인트 사용내역"]} />

        <div className="partner-profile-point-list">
          <div style={{ height: "120px" }} className="point-top-table">
            <div style={{ height: "120px", borderBottom: "1px solid #E1E1E1" }} className="point-top-table-name">
              <div>조회 기간</div>
              <div>학습자 그룹</div>
            </div>
            <div style={{ height: "120px", borderRight: "none" }} className="point-top-table-radio">
              <div className="point-top-table-radio-row ">
                <div className="fgb-date-form ms-4">
                  <img src={icon_calendar} />
                  <div
                    className="fgb-date-input"
                    onClick={() => {
                      setPopupType(false)
                      setCalendarPopup(true)
                    }}
                  >
                    <p>{startDate.isValid() ? startDate.format("YYYY.MM.DD") : "시작일"}</p>
                  </div>
                  {calendarPopup && (
                    <div className="calendar-popup-back">
                      <div className="calendar-popup-div">
                        <CalendarComponent
                          date={date}
                          setDate={setDate}
                          selectedDate={popupType == false ? startDate : endDate}
                          setSelectedDate={popupType == false ? setStartDate : setEndDate}
                          popup={setCalendarPopup}
                        />
                      </div>
                    </div>
                  )}
                  <p>~</p>
                  <img src={icon_calendar} />

                  <div
                    className="fgb-date-input"
                    onClick={() => {
                      setPopupType(true)
                      setCalendarPopup(true)
                    }}
                  >
                    <p>{endDate.isValid() ? endDate.format("YYYY.MM.DD") : "종료일"}</p>
                  </div>
                </div>
              </div>
              <div style={{ borderRight: "none" }} className="point-top-table-radio-row ">
                <div style={{ width: "190px", margin: "10px 5px" }}>
                  <select
                    className="form-select ms-3"
                    onChange={e => {
                      console.log(e.target.value)
                      setGroupSeq(e.target.value)
                    }}
                  >
                    {[
                      {
                        level1: {
                          level1Seq: "",
                          level1Name: "전체",
                          level2: [],
                        },
                      },
                      ...studentGroups.map(i => {
                        return i
                      }),
                    ].map((i, idx) => (
                      <option key={idx} className="" value={i.level1.level1Seq}>
                        {i.level1.level1Name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div>
              <div style={{ height: "60px" }} className="point-top-table-name">
                <div style={{ borderBottom: "1px solid #E1E1E1", borderLeft: "1px solid #E1E1E1" }}>구분</div>
              </div>
              <div style={{ height: "59px", border: "none" }} className="point-top-table-name">
                <div style={{ borderLeft: "none", borderBottom: "1px solid #E1E1E1", background: "#fff" }}></div>
              </div>
            </div>
            <div style={{ height: "120px" }} className="point-top-table-radio">
              <div style={{ borderBottom: "1px solid #E1E1E1" }} className="point-top-table-radio-row ">
                <img
                  onClick={() => {
                    if (pointMine && pointLearner) {
                      setPointMine(false)
                      setPointLearner(false)
                    } else {
                      setPointMine(true)
                      setPointLearner(true)
                    }
                  }}
                  className="ms-4 me-2"
                  src={pointMine && pointLearner ? checked_on : checked_off}
                />
                <p>전체 </p>
                <img onClick={() => setPointMine(!pointMine)} className="ms-4 me-2" src={pointMine ? checked_on : checked_off} />
                <p>내포인트</p>
                <img onClick={() => setPointLearner(!pointLearner)} className="ms-4 me-2" src={pointLearner ? checked_on : checked_off} />
                <p>학습자포인트</p>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <div onClick={searchDataFn} className="btn btn-primary font-white fgb-btn-l">
              조회
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <div onClick={xlDownFn} className="btn fgb-btn-m fgb-btn-grean">
              <img src={download} />
              <p>엑셀 다운로드</p>
            </div>
          </div>
          <div className="introduce-study-table mt-2">
            <div className="fgb-table-div">
              <table className={`fgb-table mb-4`}>
                <thead>
                  <tr className={`fgb-table-title-tr`}>
                    {TITLE.map((i, idx) => (
                      <td key={idx}>{i}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {profilePoint.map((i, idx) => (
                    <tr className={`fgb-table-tr `} key={idx}>
                      <td>{(current - 1) * 15 + idx + 1}</td>
                      <td>{POINT_TYPE[i.userType]}</td>
                      <td>{i.studentGroupName}</td>
                      <td>{i.phone}</td>
                      <td>{i.name}</td>
                      <td>{i.accumulatedPoint}</td>
                      <td>{i.rewardPoint}</td>
                      <td>{i.savePoint}</td>
                      <td>{i.beforeExpirePoint}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination fn={pageFn} count={15} current={current} setCurrent={setCurrent} total={profilePointTotal} />
            </div>
          </div>
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default SignContainer(PointList)
//테이블용 더미
const TITLE = ["", "구분", "학습자그룸", "휴대전화번호", "누적 포인트", "보상", "적립", "잔여", "소멸예정"]
const DUMY = [
  {
    type: "내포인트",
    group: "직장인",
    phone: "01090459594",
    cumulativePoint: 1234,
    rewordPoint: 16,
    savePoint: 1234,
    point: 1234,
    del: 15,
  },
  {
    type: "내포인트",
    group: "직장인",
    phone: "01090459594",
    cumulativePoint: 1234,
    rewordPoint: 16,
    savePoint: 1234,
    point: 1234,
    del: 15,
  },
  {
    type: "내포인트",
    group: "직장인",
    phone: "01090459594",
    cumulativePoint: 1234,
    rewordPoint: 16,
    savePoint: 1234,
    point: 1234,
    del: 15,
  },
]
