import React, { useState, useEffect } from "react"
import XBtn from "../../components/common/XBtn"
import MemberContainer from "../../containers/MemberContainer"

import CloseButton from "../../images/img_popup_close_black.svg"

const BasicInfoPopup = props => {
  const { userSeq, onClose, getUserInfo, userInfo, type, style = {} } = props

  useEffect(() => {
    async function UserInfo() {
      const result = await getUserInfo({ userSeq: userSeq })
    }
    async function ResetUserInfo() {
      await getUserInfo()
    }

    UserInfo()

    return () => {
      ResetUserInfo()
    }
  }, [])

  return (
    <div onClick={onClose} style={style} className="member-popup-container">
      <div onClick={e => e.stopPropagation()} className="member-popup-wrapper">
        <img src={CloseButton} alt="close-popup-button" className="close-popup-button" onClick={onClose} />
        <XBtn onClose={onClose} />
        <div className="member-popup-title">회원 기본 정보</div>

        <div className="basic-info-popup-table-wrapper">
          <div className="display-with-flex" style={{ height: "120px" }}>
            <div className="element-l-small right-border bottom-border" style={{ height: "120px", lineHeight: "120px", background: "#F8F8F8" }}>
              필수
            </div>
            <div>
              <div className="display-with-flex">
                <div className="element-l-medium right-border bottom-border">이름</div>
                <div className="element-r bottom-border">{userInfo?.user?.name}</div>
              </div>
              <div className="display-with-flex">
                <div className="element-l-medium right-border bottom-border">휴대전화번호</div>
                <div className="element-r bottom-border">{userInfo?.user?.phone}</div>
              </div>
            </div>
          </div>
          <div className="display-with-flex">
            <div className="element-l-small right-border bottom-border" style={{ background: "#F8F8F8", height: "360px", lineHeight: "360px" }}>
              선택
            </div>
            <div>
              <div className="display-with-flex">
                <div className="element-l-medium right-border bottom-border">스크린 닉네임</div>
                <div className="element-r bottom-border">{userInfo?.user?.nickname}</div>
              </div>
              <div className="display-with-flex">
                <div className="element-l-medium right-border bottom-border">공부 장소</div>
                <div className="element-r bottom-border">{userInfo?.user?.addressNew}</div>
              </div>
              <div className="display-with-flex">
                <div className="element-l-medium right-border bottom-border">학습자 그룹</div>
                <div className="element-r bottom-border">{userInfo?.user?.studentGroupName}</div>
              </div>
              <div className="display-with-flex">
                <div className="element-l-medium right-border bottom-border">학년</div>
                <div className="element-r bottom-border">{userInfo?.user?.studentGrade}</div>
              </div>
              <div className="display-with-flex">
                <div className="element-l-medium right-border bottom-border">이메일</div>
                <div className="element-r bottom-border">{userInfo?.user?.email}</div>
              </div>
              <div className="display-with-flex">
                <div className="element-l-medium right-border bottom-border">마케팅 수신</div>
                <div className="element-r bottom-border">{userInfo?.user?.marketingType}</div>
              </div>
            </div>
          </div>
          <div className="display-with-flex">
            <div className="element-l-big right-border bottom-border">소속</div>
            <div className="element-r bottom-border">{userInfo?.user?.belongingName}</div>
          </div>
          <div className="display-with-flex">
            <div className="element-l-big right-border bottom-border">가입일</div>
            <div className="element-r bottom-border">{userInfo?.user?.regDatetime}</div>
          </div>
          <div className="display-with-flex">
            <div className="element-l-big right-border" style={{ height: "58px" }}>
              {type === 0 ? "최근 접속일" : "탈퇴 일시"}
            </div>
            <div className="element-r no-border">{userInfo?.user?.recentlyAccessDatetime}</div>
          </div>
        </div>

        <div className="member-popup-button-wrapper">
          <div className="confirm-button btn btn-primary font-white fgb-btn-l" onClick={onClose}>
            확인
          </div>
        </div>
      </div>
    </div>
  )
}

export default MemberContainer(BasicInfoPopup)
