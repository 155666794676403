/*
  스터디 탐색 페이지
*/
import React, { useState, useEffect } from "react"

import "swiper/scss"

import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import TopInfo from "../../components/common/TopInfo"

import checked_on from "../../images/checked_on.svg"
import checked_off from "../../images/checked_off.svg"
import radio_on from "../../images/radio_on.svg"
import radio_off from "../../images/radio_off.svg"
import upload from "../../images/upload.svg"
import Validator from "validator"

import ExhibitionContainer from "../../containers/ExhibitionContainer"
import ModalPreviewPopup from "../../popups/ModalPreviewPopup"
import { navigate } from "gatsby"
import PrivateRoute from "../../components/privateRoute"
import { popupFn } from "../../utils/util"

const Gateway = props => {
  const { postUploadFile, postDisplayContent } = props
  const [fileType, setFileType] = useState(1)
  const [title, setTitle] = useState("")

  const [isPc, setIsPc] = useState(true)
  const [isMo, setIsMo] = useState(true)

  const [isAuto, setIsAuto] = useState(false)
  const [isAutoSc, setIsAutoSc] = useState(1)

  const [isSkip, setIsSkip] = useState(false)
  const [isSkipText, setIsSkipText] = useState("")

  const [imageInfoPc, setImageInfoPc] = useState({ base64: null, file: null, imageUrl: null, name: null })
  const [imageInfoMo, setImageInfoMo] = useState({ base64: null, file: null, imageUrl: null, name: null })
  const [movLink, setMovLink] = useState("")

  const [isPageLink, setIsPageLink] = useState(false)
  const [pageLink, setPageLink] = useState("")

  const [isDisplayNum, setIsDisplayNum] = useState(true)
  const [displayNum, setdisplayNum] = useState(1)

  const [isDisplay, setIsDisplay] = useState(false)

  const [openPreview, setOpenPreview] = useState({ contents: {}, open: "false" })

  const saveFn = async () => {
    let imgPc = null
    let imgMo = null
    if (imageInfoPc.imageUrl == null && imageInfoPc.file == null) {
      popupFn("이미지를 등록해주세요")
      return false
    }
    if (imageInfoMo.imageUrl == null && imageInfoMo.file == null) {
      popupFn("이미지를 등록해주세요")
      return false
    }
    if (Validator.isEmpty(title, { ignore_whitespace: true })) {
      popupFn("제목을 입력해주세요.")
      return false
    }
    if (!isPc && !isMo) {
      popupFn("플랫폼을 선택해주세요.")
      return false
    }
    if (imageInfoPc.imageUrl == null && imageInfoPc.file != null) {
      const fomrData = new FormData()
      await fomrData.append("file", imageInfoPc.file)
      imgPc = await postUploadFile(fomrData)
    }
    if (imageInfoMo.imageUrl == null && imageInfoMo.file != null) {
      const fomrData = new FormData()
      await fomrData.append("file", imageInfoMo.file)
      imgMo = await postUploadFile(fomrData)
    }
    let temp = {
      exhibitionContentSeq: "",
      type: "GATEWAY",
      fileType: "IMAGE",
      title: title,
      deviceType: isPc && isMo ? "ALL" : isPc ? "PC" : isMo ? "MOBILE" : "",
      isAutoSlide: isAuto ? "Y" : "N",
      autoSlideInterval: isAuto ? isAutoSc : "",
      isPossibleSkip: isSkip ? "Y" : "N",
      skipComment: isSkip ? isSkipText : "",
      pcImageUrl: imgPc ? imgPc : imageInfoPc.imageUrl,
      mobileImageUrl: imgMo ? imgMo : imageInfoMo.imageUrl,
      videoUrl: "",
      isUsingPageLink: isPageLink ? "Y" : "N",
      pageUrl: isPageLink ? pageLink : "",
      displayOrderType: isDisplayNum ? "ORDER" : "PICK",
      displayOrderNumber: displayNum,
      isDisplayText: "",
      textPositionVerticalType: "",
      textPositionHorizonType: "",
      text: "",
      isDisplay: isDisplay ? "Y" : "N",
    }
    console.log(temp)
    let result = await postDisplayContent(temp)
    if (result) {
      popupFn("등록되었습니다.")
      navigate("/exhibition/ExhibitionInquiry")
    }
  }

  //base64
  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  return (
    <PrivateRoute>
      <Layout>
        <SEO title="Gateway 이미지 등록" />
        <TopInfo title={["Gateway 이미지 등록", "전시관리", "Gateway 이미지 등록"]} />
        <ModalPreviewPopup open={openPreview.open} onClose={() => setOpenPreview({ contents: {}, open: "false" })} contents={openPreview.contents} />
        <div className=" layer-popup-container">
          <div className="admin-account-info-container ">
            <div className="admin-account-info-box">
              <div style={{ border: "none", borderBottom: "1px solid #E1E1E1" }} className="admin-account-info-box-row div-1">
                <div className="admin-account-info-title-p ">유형</div>
                <div className="admin-account-info-div">
                  <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                    <img className=" me-2" src={fileType == 0 ? radio_on : radio_off} />
                    <p>동영상 </p>
                    <img className="ms-4 me-2" src={fileType == 1 ? radio_on : radio_off} />
                    <p>이미지</p>
                  </div>
                </div>
              </div>
              <div style={{ border: "none", borderBottom: "1px solid #E1E1E1" }} className="admin-account-info-box-row div-1">
                <div className="admin-account-info-title-p ">Gateway 제목</div>
                <div className="admin-account-info-div">
                  <div style={{ width: "330px", border: "none" }} className="admin-account-info-div-2">
                    <input value={title || ""} onChange={e => setTitle(e.target.value)} type="text" className="fgb-input" />
                  </div>
                </div>
              </div>
              <div style={{ border: "none", borderBottom: "1px solid #E1E1E1" }} className="admin-account-info-box-row div-1">
                <div className="admin-account-info-title-p ">플렛폼</div>
                <div className="admin-account-info-div">
                  <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                    <img
                      onClick={() => {
                        if (isPc && isMo) {
                          setIsPc(false)
                          setIsMo(false)
                        } else {
                          setIsPc(true)
                          setIsMo(true)
                        }
                      }}
                      className=" me-2"
                      src={isPc && isMo ? checked_on : checked_off}
                    />
                    <p>전체 </p>
                    <img onClick={() => setIsPc(!isPc)} className="ms-3 me-2" src={isPc ? checked_on : checked_off} />
                    <p>PC</p>
                    <img onClick={() => setIsMo(!isMo)} className="ms-3 me-2" src={isMo ? checked_on : checked_off} />
                    <p className="">Mobile</p>
                  </div>
                </div>
              </div>
              <div style={{ border: "none", borderBottom: "1px solid #E1E1E1" }} className="admin-account-info-box-row div-1">
                <div className="admin-account-info-title-p ">이미지 슬라이딩</div>
                <div className="admin-account-info-div">
                  <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                    <img onClick={() => setIsAuto(!isAuto)} className=" me-2" src={isAuto ? checked_on : checked_off} />
                    <p>자동 슬라이딩 전환</p>
                    <img onClick={() => setIsAutoSc(1)} className="ms-4 me-2" src={isAutoSc == 1 ? radio_on : radio_off} />
                    <p>1초</p>
                    <img onClick={() => setIsAutoSc(2)} className="ms-4 me-2" src={isAutoSc == 2 ? radio_on : radio_off} />
                    <p>2초</p>
                    <img onClick={() => setIsAutoSc(3)} className="ms-4 me-2" src={isAutoSc == 3 ? radio_on : radio_off} />
                    <p>3초</p>
                  </div>
                </div>
              </div>
              <div style={{ border: "none", borderBottom: "1px solid #E1E1E1" }} className="admin-account-info-box-row div-1">
                <div className="admin-account-info-title-p ">SKIP 체크버튼</div>
                <div className="admin-account-info-div">
                  <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                    <img onClick={() => setIsSkip(true)} className="me-2" src={isSkip ? radio_on : radio_off} />
                    <p>사용</p>
                    <img onClick={() => setIsSkip(false)} className="ms-4 me-2" src={!isSkip ? radio_on : radio_off} />
                    <p className="me-3">사용하지 않음</p>
                    <div style={{ width: "330px", border: "none" }} className="admin-account-info-div-2">
                      <input value={isSkipText || ""} onChange={e => setIsSkipText(e.target.value)} type="text" className="fgb-input" />
                    </div>
                    <div onClick={() => setIsSkipText("")} className="btn fgb-btn-gray fgb-btn-s ms-2">
                      삭제
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ padding: "20px 15px 0px" }} className="">
                <div className="admin-account-info-box-row div-1">
                  <div className="admin-account-info-title-p ">PC BG 이미지</div>
                  <div className="admin-account-info-div">
                    <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                      <div style={{ width: "330px", border: "none" }} className="admin-account-info-div-2 ms-4">
                        <input value={imageInfoPc.imageUrl || imageInfoPc.name || ""} readOnly type="text" className="fgb-input" />
                      </div>
                      <input
                        id={"gallery_image_input1"}
                        style={{ display: "none" }}
                        type={"file"}
                        onChange={async e => {
                          const file = e.target.files[0]
                          if (file !== undefined) {
                            const base64Image = await toBase64(file)

                            let tempList = {}
                            tempList.base64 = base64Image
                            tempList.file = file
                            tempList.name = file.name
                            tempList.imageUrl = null
                            console.log("tempList", tempList)
                            await setImageInfoPc(tempList)
                            e.target.value = ""
                          }
                        }}
                        accept=".gif, .jpg, .png"
                        // accept={"image/*"}
                      />
                      <div
                        onClick={() => setImageInfoPc({ base64: null, file: null, imageUrl: null, name: null })}
                        className="btn fgb-btn-gray fgb-btn-s ms-2"
                      >
                        삭제
                      </div>
                      <div
                        onClick={() => setOpenPreview({ open: true, contents: imageInfoPc })}
                        className="btn btn-primary font-white fgb-btn-m ms-2"
                      >
                        미리보기
                      </div>
                      <div onClick={() => document.getElementById("gallery_image_input1").click()} className="btn fgb-btn-m fgb-btn-purple ms-2">
                        <img src={upload} />
                        <p>파일 업로드</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="admin-account-info-box-row div-1">
                  <div className="admin-account-info-title-p ">MO BG 이미지</div>
                  <div className="admin-account-info-div">
                    <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                      <div style={{ width: "330px", border: "none" }} className="admin-account-info-div-2 ms-4">
                        <input value={imageInfoMo.imageUrl || imageInfoMo.name || ""} readOnly type="text" className="fgb-input" />
                      </div>
                      <div
                        onClick={() => setImageInfoMo({ base64: null, file: null, imageUrl: null, name: null })}
                        className="btn fgb-btn-gray fgb-btn-s ms-2"
                      >
                        삭제
                      </div>
                      <div
                        onClick={() => setOpenPreview({ open: true, contents: imageInfoMo })}
                        className="btn btn-primary font-white fgb-btn-m ms-2"
                      >
                        미리보기
                      </div>
                      <div onClick={() => document.getElementById("gallery_image_input2").click()} className="btn fgb-btn-m fgb-btn-purple ms-2">
                        <img src={upload} />
                        <p>파일 업로드</p>
                      </div>
                      <input
                        id={"gallery_image_input2"}
                        style={{ display: "none" }}
                        type={"file"}
                        onChange={async e => {
                          const file = e.target.files[0]
                          if (file !== undefined) {
                            const base64Image = await toBase64(file)

                            let tempList = {}
                            tempList.base64 = base64Image
                            tempList.file = file
                            tempList.name = file.name
                            tempList.imageUrl = null
                            console.log("tempList", tempList)
                            await setImageInfoMo(tempList)
                            e.target.value = ""
                          }
                        }}
                        accept=".gif, .jpg, .png"
                        // accept={"image/*"}
                      />
                    </div>
                  </div>
                </div>
                <div className="admin-account-info-box-row div-1">
                  <div className="admin-account-info-title-p ">영상링크</div>
                  <div className="admin-account-info-div">
                    <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                      <div style={{ width: "330px", border: "none" }} className="admin-account-info-div-2 ms-4">
                        <input value={""} disabled readOnly type="text" className="fgb-input" />
                      </div>
                      <div className="btn fgb-btn-gray fgb-btn-s ms-2">삭제</div>
                      <div className="btn btn-primary font-white fgb-btn-m ms-2">링크보기</div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ padding: "20px 15px 0px" }} className="">
                <div className="admin-account-info-box-row div-1">
                  <div className="admin-account-info-title-p ">페이지 링크</div>
                  <div className="admin-account-info-div">
                    <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                      <img onClick={() => setIsPageLink(true)} className="ms-3 me-2" src={isPageLink ? radio_on : radio_off} />
                      <p>사용 </p>
                      <img onClick={() => setIsPageLink(false)} className="ms-3 me-2" src={!isPageLink ? radio_on : radio_off} />
                      <p>사용하지 않음 </p>
                      <div style={{ width: "260px", border: "none" }} className="admin-account-info-div-2 ms-2">
                        <input value={pageLink || ""} onChange={e => setPageLink(e.target.value)} type="text" className="fgb-input" />
                      </div>
                      <div onClick={() => setPageLink("")} className="btn fgb-btn-gray fgb-btn-s ms-2">
                        삭제
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ padding: "20px 15px 20px" }} className="">
                <div className="admin-account-info-box-row div-1">
                  <div className="admin-account-info-title-p ">화면 표시 순서</div>
                  <div className="admin-account-info-div">
                    <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                      <img onClick={() => setIsDisplayNum(true)} className="ms-3 me-2" src={isDisplayNum ? radio_on : radio_off} />
                      <p>순차 </p>
                      <img onClick={() => setIsDisplayNum(false)} className="ms-3 me-2" src={!isDisplayNum ? radio_on : radio_off} />
                      <p>지정 </p>
                      <div className="ms-4" style={{ width: "190px" }}>
                        <select
                          className="form-select"
                          value={displayNum}
                          disabled={isDisplayNum}
                          onChange={e => {
                            setdisplayNum(e.target.value)
                          }}
                        >
                          <option className="" value="1">
                            1
                          </option>
                          <option className="" value="2">
                            2
                          </option>
                          <option className="" value="3">
                            3
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="admin-account-info-box-row div-1">
                  <div className="admin-account-info-title-p ">전시 여부</div>
                  <div className="admin-account-info-div">
                    <div style={{ border: "none", height: "100%" }} className="d-flex align-items-center justify-content-start">
                      <img onClick={() => setIsDisplay(true)} className="ms-3 me-2" src={isDisplay ? radio_on : radio_off} />
                      <p>전시 </p>
                      <img onClick={() => setIsDisplay(false)} className="ms-3 me-2" src={!isDisplay ? radio_on : radio_off} />
                      <p>비전시 </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <button className="btn btn-primary fgb-btn-gray fgb-btn-l ">취소</button>
            <button onClick={saveFn} className="btn btn-primary font-white fgb-btn-l ms-2">
              저장
            </button>
          </div>
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default ExhibitionContainer(Gateway)
