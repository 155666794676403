import { navigate } from "gatsby-link"
import { createAction, handleActions } from "redux-actions"
import MasterApis from "../../apis/MasterApis"

const SET_ADMIN_MASTER_LIST = "master/SET_ADMIN_MASTER_LIST"
const SET_ADMIN_MASTER_POP_LIST = "master/SET_ADMIN_MASTER_POP_LIST"
const SET_ADMIN_TYPE_LIST = "master/SET_ADMIN_TYPE_LIST"
const SET_ADMIN_1DEPTH_LIST = "master/SET_ADMIN_1DEPTH_LIST"
const SET_ADMIN_2DEPTH_LIST = "master/SET_ADMIN_2DEPTH_LIST"
const SET_ADMIN_INFO = "master/SET_ADMIN_INFO"

export const setAdminMasterList = createAction(SET_ADMIN_MASTER_LIST)
export const setAdminMasterPopList = createAction(SET_ADMIN_MASTER_POP_LIST)
export const setAdminTypeList = createAction(SET_ADMIN_TYPE_LIST)
export const setAdminDepth1List = createAction(SET_ADMIN_1DEPTH_LIST)
export const setAdminDepth2List = createAction(SET_ADMIN_2DEPTH_LIST)
export const setAdminInfo = createAction(SET_ADMIN_INFO)

const initState = {
  masterList: [],
  masterPopList: [],
  adminTypeList: [],
  admin1Depth: [],
  admin2Depth: [],
  adminInfo: {},
}

export default handleActions(
  {
    [SET_ADMIN_MASTER_LIST]: (state, action) => ({
      ...state,
      masterList: action.payload,
    }),
    [SET_ADMIN_TYPE_LIST]: (state, action) => ({
      ...state,
      adminTypeList: action.payload,
    }),
    [SET_ADMIN_1DEPTH_LIST]: (state, action) => ({
      ...state,
      admin1Depth: action.payload,
    }),
    [SET_ADMIN_2DEPTH_LIST]: (state, action) => ({
      ...state,
      admin2Depth: action.payload,
    }),
    [SET_ADMIN_INFO]: (state, action) => ({
      ...state,
      adminInfo: action.payload,
    }),
    [SET_ADMIN_MASTER_POP_LIST]: (state, action) => ({
      ...state,
      masterPopList: action.payload,
    }),
  },
  initState
)
/* action api function */

//관리자 리스트 조회
export const getAdminMasterAction = param => async dispatch => {
  const result = await MasterApis.getAdminMaster(param)
  if (result.code == 0) {
    dispatch(setAdminMasterList(result.data.admins))
    return true
  } else {
    dispatch(setAdminMasterList([]))
    return false
  }
}
//관리자 리스트 조회 popup
export const getAdminMasterPopAction = param => async dispatch => {
  const result = await MasterApis.getAdminMaster(param)
  if (result.code == 0) {
    dispatch(setAdminMasterPopList(result.data.admins))
    return true
  } else {
    dispatch(setAdminMasterPopList([]))
    return false
  }
}

//관리자 유형 목록
export const getAdminTypeAction = param => async dispatch => {
  const result = await MasterApis.getAdminType(param)
  if (result.code == 0) {
    dispatch(setAdminTypeList(result.data.adminTypes))
    return true
  } else {
    dispatch(setAdminTypeList([]))
    return false
  }
}

//관리자 권한 1뎁스 메뉴
export const getAdminMeue1Action = param => async dispatch => {
  const result = await MasterApis.getAdminMeue1(param)
  if (result.code == 0) {
    dispatch(setAdminDepth1List(result.data.menus1))
    return true
  } else {
    dispatch(setAdminDepth1List([]))
    return false
  }
}

//관리자 권한 2뎁스 메뉴
export const getAdminMeue2Action = param => async dispatch => {
  const result = await MasterApis.getAdminMeue2(param)
  if (result.code == 0) {
    dispatch(setAdminDepth2List(result.data.menus2))
    return true
  } else {
    dispatch(setAdminDepth2List([]))
    return false
  }
}
//관리자 개인 정보
export const getAdminsInfoAction = param => async dispatch => {
  const result = await MasterApis.getAdminsInfo(param)
  if (result.code == 0) {
    dispatch(setAdminInfo(result.data))
    return true
  } else {
    dispatch(setAdminInfo([]))
    return false
  }
}
// 관리자 유형관리 수정
export const putAdminsTypeAction = param => async dispatch => {
  const result = await MasterApis.putAdminsType(param)
  if (result.code == 0) {
    return true
  } else return false
}
//어드민 권한 수정
export const putAdminsPermissionAction = param => async dispatch => {
  const result = await MasterApis.putAdminsPermission(param)
  if (result.code == 0) {
    return true
  } else return false
}
//어드민 삭제
export const delAdminsAction = param => async dispatch => {
  const result = await MasterApis.delAdmins(param)
  if (result.code == 0) {
    return true
  } else return false
}
