/*
  관리자 메인 페이지
*/
import React, { useState, useEffect, useRef } from "react"

import "swiper/scss"

import SEO from "../components/seo"
import Layout from "../components/Layout"

import download from "../images/download.svg"
import main_good from "../images/main_good.svg"
import main_clock from "../images/main_clock.svg"
import main_cal from "../images/main_cal.svg"
import main_cal_check from "../images/main_cal_check.svg"
import main_good_reverse from "../images/main_good_reverse.svg"
import main_clock_reverse from "../images/main_clock_reverse.svg"
import main_cal_reverse from "../images/main_cal_reverse.svg"
import main_cal_check_reverse from "../images/main_cal_check_reverse.svg"

import img_schedule_left from "../images/img_schedule_left.svg"
import img_schedule_right from "../images/img_schedule_right.svg"
import pic from "../images/pic.png"

import moment from "moment"
import SwipeableViews from "react-swipeable-views"

import TopInfo from "../components/common/TopInfo"
import PrivateRoute from "../components/privateRoute"

import BarChart from "../components/common/BarChart"
import StudyItem from "../components/common/StudyItem"
import RankItem from "../components/common/RankItem"
import ToDoItem from "../components/common/ToDoItem"

import StudyInfoPopup from "../popups/StudyInfoPopup"
import RankingPopup from "../popups/RankingPopup"
import BasicInfoPopup from "../popups/member/BasicInfoPopup"

import AffiliatesMainContainer from "../containers/AffiliatesMainContainer"

import { useDownloadExcel, DownloadTableExcel } from "react-export-table-to-excel/src"
import { navigate } from "gatsby"
import { dataCheckFn } from "../utils/util"

const AffiliatesMain = props => {
  const {
    getStatisticsStudyTime,
    getStatisticsMembers,
    getStatisticsStudyCount,
    getStatisticsTodoCount,
    partnerMainStudyTime,
    partnerMainMember,
    partnerMainStudy,
    partnerMainTodo,
    getStatisticsStudies,
    partnerMainStudyList,
    getMyStudies,
    partnerMainMyStudies,
    getMyStudiesSchedules,
    getMyTodos,
    partnerMainMyTodos,
    getRankingStudyKeyword,
    partnerMainRankKeyword,
    getRankingStudyBest,
    partnerMainRankStudy,
    getRankingStudyTime,
    partnerMainRankTime,
    partnerProfile,
    partnerMainMemberRecently,
    getMemberRecently,
  } = props

  const [dateMM, setDateMM] = useState(moment().format("MM"))
  const [swiperIndex, setSwiperIndex] = useState(0)
  const [swiperIndex2, setSwiperIndex2] = useState(0)
  const [openStudyInfo, setOpenStudyInfo] = useState({ open: false, data: {} })
  const [openRank, setOpenRank] = useState({ open: false, type: "", title: "", list: [] })

  const [basicInfoPopup, setBasicInfoPopup] = useState({ open: false, data: "" })
  // SwipeableViews styles
  const studyTable = useRef(null)

  const styles = {
    root: {
      padding: "0 280px",
    },
    slideContainer: {
      padding: "0 10px",
    },
  }
  const barDataFn = (arr, key) => {
    let temp = []
    if (arr.length < 1) return temp
    for (let i = 0; i < arr.length; i++) {
      if (i < 10) {
        temp = [...temp, arr[i][key]]
      }
    }
    return temp
  }
  // bar chart data
  const BAR_DATA = {
    labels: barDataFn(partnerMainStudyList, "title"),

    datasets: [
      {
        label: "총 누적시간",
        data: barDataFn(partnerMainStudyList, "accumulatedStudyTime"),
        borderColor: "#28D7D2",
        backgroundColor: "#28D7D2",
        borderRadius: 4,
        barThickness: 16,
      },
      {
        label: "평균 공부시간",
        data: barDataFn(partnerMainStudyList, "averageStudyTime"),
        borderColor: " #70A9FF",
        backgroundColor: " #70A9FF",
        borderRadius: 4,
        barThickness: 16,
      },
    ],
  }
  //get data
  const getData = async () => {
    await getStatisticsStudyTime()
    await getStatisticsMembers()
    await getStatisticsStudyCount()
    await getStatisticsTodoCount()
    await getMyStudies()
    await getMyTodos()
    await getRankingStudyKeyword()
    await getRankingStudyBest()
    await getRankingStudyTime()
    await getMemberRecently()
  }
  //공부 누적 시간 현황 get
  const getStatisticsStudiesFn = async () => {
    await getStatisticsStudies(dateMM)
  }

  useEffect(() => {
    getStatisticsStudiesFn()
  }, [dateMM])
  useEffect(() => {
    getData()
  }, [])
  useEffect(() => {
    if (partnerMainMyStudies.length > 2) {
      setSwiperIndex(1)
    }
  }, [partnerMainMyStudies])

  return (
    <PrivateRoute>
      {basicInfoPopup.open && (
        <BasicInfoPopup
          // style={{ background: "rgba(0,0,0,0)", position: "absolute", width: "fit-content", height: "fit-content", zIndex: 999 }}
          userSeq={basicInfoPopup.data}
          type={0}
          onClose={() => {
            setBasicInfoPopup({ open: false, data: "" })
          }}
        />
      )}
      <Layout>
        <StudyInfoPopup data={openStudyInfo.data} open={openStudyInfo.open} onClose={() => setOpenStudyInfo({ open: false, data: {} })} />
        <RankingPopup
          list={openRank.list}
          title={openRank.title}
          type={openRank.type}
          open={openRank.open}
          onClose={() => setOpenRank({ open: false, type: "", title: "", list: [] })}
        />
        <SEO title="Main" />
        <div className="affiliates-main-container">
          <TopInfo rightView={false} title={[dataCheckFn(partnerProfile.brandName) ? partnerProfile.brandName : "", "", ""]} />
          <div className="affiliates-main-top-bar">
            <div className="affiliates-main-top-bar-item">
              <img className="img-1" src={main_good} />
              <img className="img-2" src={main_good_reverse} />
              <div className="affiliates-main-top-bar-item-info">
                <p className="p-1">총 누적 회원수 (명)</p>
                <p className="p-2">{(partnerMainMember.accumulatedMemberCount || 0).toLocaleString()}</p>
                <p className="p-3">
                  이번주 <span>{(partnerMainMember.thisWeekMemberCount || 0).toLocaleString()}</span>
                </p>
              </div>
            </div>
            <div className="affiliates-main-top-bar-item">
              <img className="img-1" src={main_clock} />
              <img className="img-2" src={main_clock_reverse} />
              <div className="affiliates-main-top-bar-item-info">
                <p className="p-1">총 누적 공부시간(시간)</p>
                <p className="p-2">{(partnerMainStudyTime.accumulatedStudyTime || 0).toLocaleString()}</p>
                <p className="p-3">
                  이번주 <span>{(partnerMainStudyTime.thisWeekStudyTime || 0).toLocaleString()}</span>
                </p>
              </div>
            </div>
            <div className="affiliates-main-top-bar-item">
              <img className="img-1" src={main_cal} />
              <img className="img-2" src={main_cal_reverse} />
              <div className="affiliates-main-top-bar-item-info">
                <p className="p-1">스터디 스케줄 (개)</p>
                <p className="p-2">{(partnerMainStudy.accumulatedStudyCount || 0).toLocaleString()}</p>
                <p className="p-3">
                  이번주 <span>{(partnerMainStudy.thisWeekStudyCount || 0).toLocaleString()}</span>
                </p>
              </div>
            </div>
            <div className="affiliates-main-top-bar-item">
              <img className="img-1" src={main_cal_check} />
              <img className="img-2" src={main_cal_check_reverse} />
              <div className="affiliates-main-top-bar-item-info">
                <p className="p-1">총 누적 TO-DO 스케줄 (개)</p>
                <p className="p-2">{(partnerMainTodo.accumulatedTodoCount || 0).toLocaleString()}</p>
                <p className="p-3">
                  이번주 <span>{(partnerMainTodo.thisWeekTodoCount || 0).toLocaleString()}</span>
                </p>
              </div>
            </div>
          </div>

          <div className="affiliates-main-con-1 mt-4">
            <p className="sub-title">공부 누적 시간 현황</p>
            <div className="affiliates-main-con-1-info-div">
              <div className="bar-chart">
                <div className="bar-labels">
                  <div className="box-1"></div>
                  <p>총 누적시간</p>
                  <div className="box-2"></div>
                  <p>평균 공부시간</p>
                </div>
                <BarChart data={BAR_DATA} width={430} height={350} />
              </div>
              <div className="table-div">
                <div className="table-top">
                  <div style={{ width: "120px", margin: "10px 5px" }}>
                    <select
                      value={dateMM}
                      className="form-select"
                      onChange={e => {
                        setDateMM(e.target.value)
                      }}
                    >
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i, idx) => (
                        <option key={idx} className="" value={i < 10 ? "0" + i : i}>
                          {i}월
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* <div style={{ display: "none" }}>
                    <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className=""
                      table="studyTable"
                      filename={`공부 누적 시간 현황 ${dateMM}월`}
                      sheet={`공부 누적 시간 현황 ${dateMM}월`}
                      buttonText={``}
                    ></ReactHTMLTableToExcel>
                  </div> */}
                  <DownloadTableExcel
                    filename={`공부 누적 시간 현황 ${dateMM}월`}
                    sheet={`공부 누적 시간 현황 ${dateMM}월`}
                    currentTableRef={studyTable.current}
                  >
                    {/* <Button className="btn fgb-btn-m fgb-btn-grean"> Export excel </Button> */}
                    <div
                      // onClick={() => document.getElementById("test-table-xls-button").click()}
                      className="btn fgb-btn-m fgb-btn-grean"
                    >
                      <img src={download} />
                      <p>엑셀 다운로드</p>
                    </div>
                  </DownloadTableExcel>
                </div>
                <div className="fgb-table-div">
                  <table id="studyTable" className={`fgb-table`}>
                    <thead>
                      <tr className={`fgb-table-title-tr`}>
                        {["", "스터디 그룹", "총 누적시간", "평균 공부시간", "목표달성%", "공부기간"].map((i, idx) => (
                          <td key={idx}>{i}</td>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {partnerMainStudyList.map((i, idx) => {
                        if (partnerMainStudyList.length - 1 > idx) {
                          return (
                            <tr className={`fgb-table-tr`} key={idx}>
                              <td>{idx + 1}</td>
                              <td>{i.title}</td>
                              <td>{i.accumulatedStudyTime}</td>
                              <td>{i.averageStudyTime}</td>
                              <td>{i.achievementRate}</td>
                              <td>{i.studyPeriod}</td>
                            </tr>
                          )
                        }
                      })}
                      {partnerMainStudyList.length > 0 && (
                        <tr className={`fgb-table-title-tr`}>
                          <td>{""}</td>
                          <td>{partnerMainStudyList[partnerMainStudyList.length - 1].title}</td>
                          <td>{partnerMainStudyList[partnerMainStudyList.length - 1].accumulatedStudyTime}</td>
                          <td>{partnerMainStudyList[partnerMainStudyList.length - 1].averageStudyTime}</td>
                          <td>{partnerMainStudyList[partnerMainStudyList.length - 1].achievementRate}</td>
                          <td>{partnerMainStudyList[partnerMainStudyList.length - 1].studyPeriod}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <table style={{ display: "none" }} ref={studyTable} id="studyTable" className={`fgb-table`}>
                    <tbody>
                      <tr>
                        <td></td>
                      </tr>
                      <tr className={`fgb-table-title-tr`}>
                        {["", "스터디 그룹", "총 누적시간", "평균 공부시간", "목표달성%", "공부기간"].map((i, idx) => (
                          <td key={idx}>{i}</td>
                        ))}
                      </tr>
                      {partnerMainStudyList.map((i, idx) => {
                        if (partnerMainStudyList.length - 1 > idx) {
                          return (
                            <tr className={`fgb-table-tr `} key={idx}>
                              <td>{idx + 1}</td>
                              <td>{i.title}</td>
                              <td>{i.accumulatedStudyTime}</td>
                              <td>{i.averageStudyTime}</td>
                              <td>{i.achievementRate}</td>
                              <td>{i.studyPeriod}</td>
                            </tr>
                          )
                        }
                      })}
                      {partnerMainStudyList.length > 0 && (
                        <tr className={`fgb-table-title-tr`}>
                          <td>{""}</td>
                          <td>{partnerMainStudyList[partnerMainStudyList.length - 1].title}</td>
                          <td>{partnerMainStudyList[partnerMainStudyList.length - 1].accumulatedStudyTime}</td>
                          <td>{partnerMainStudyList[partnerMainStudyList.length - 1].averageStudyTime}</td>
                          <td>{partnerMainStudyList[partnerMainStudyList.length - 1].achievementRate}</td>
                          <td>{partnerMainStudyList[partnerMainStudyList.length - 1].studyPeriod}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="affiliates-main-con-2 mt-4">
            <div className="d-flex justify-content-between align-tiems-center">
              <p className="sub-title">스터디 스케줄 목록</p>
              <button
                style={{ width: "135px" }}
                onClick={() => navigate("/studySchedule/StudyScheduleReg")}
                className="btn btn-primary font-white fgb-btn-m"
              >
                스터디 스케줄 등록
              </button>
            </div>

            <div className="affiliates-main-con-card-view">
              <button
                className="btn card-left-button"
                onClick={() => {
                  if (swiperIndex === 0) return
                  setSwiperIndex(prev => prev - 1)
                }}
              >
                <img src={img_schedule_left} alt="left arrow img" />
              </button>
              <button
                className="btn card-right-button"
                onClick={() => {
                  if (swiperIndex >= partnerMainMyStudies.length - 1) return
                  setSwiperIndex(prev => prev + 1)
                }}
              >
                <img src={img_schedule_right} alt="right arrow img" />
              </button>

              <div className="card-swiper-view">
                <SwipeableViews style={styles.root} slideStyle={styles.slideContainer} index={swiperIndex} onChangeIndex={idx => setSwiperIndex(idx)}>
                  {partnerMainMyStudies.map((i, idx) => (
                    <StudyItem
                      setOpenStudyInfo={async () => {
                        await getMyStudiesSchedules(i.studySeq || i.study_seq)
                        setOpenStudyInfo({ open: true, data: i })
                      }}
                      key={idx}
                      data={i}
                    />
                  ))}
                </SwipeableViews>
              </div>
            </div>
            <div className="affiliates-main-con-swiper-indicator-view">
              {partnerMainMyStudies.length <= 10 &&
                partnerMainMyStudies.map((i, idx) => (
                  <button
                    className={`btn affiliates-main-con-swiper-indicator-item ${swiperIndex === idx ? "active" : ""}`}
                    key={idx}
                    onClick={() => setSwiperIndex(idx)}
                  ></button>
                ))}
              {partnerMainMyStudies.length > 10 && (
                <p>
                  {swiperIndex + 1} / {partnerMainMyStudies.length}
                </p>
              )}
            </div>
          </div>
          <div className="affiliates-main-con-3 mt-4">
            <RankItem
              list={partnerMainRankKeyword}
              title="Study Keyword 순위"
              onClick={
                () => {
                  setOpenRank({ open: true, type: "keyword", title: "Study Keyword 순위", list: partnerMainRankKeyword })
                }
                // setTop100({ list: exploringData.keywordStatistics || [], title: "Study Keyword 순위", type: "keyword" })
                // dispatch(setRankingPopup(true))
              }
              type="keyword"
            />
            <RankItem
              list={partnerMainRankStudy}
              onClick={() => setOpenRank({ open: true, type: "", title: "베스트 스터디 그룹 순위", list: partnerMainRankStudy })}
              title="베스트 스터디 그룹 순위"
            />
            <RankItem
              list={partnerMainRankTime}
              title="누적 공부시간 순위"
              onClick={() => {
                setOpenRank({ open: true, type: "", title: "누적 공부시간 순위", list: partnerMainRankTime })
              }}
            />
          </div>
          <div style={{ height: "656px" }} className="affiliates-main-con-2 mt-4">
            <div className="d-flex justify-content-between align-tiems-center">
              <p className="sub-title">TO-DO 스케줄 목록</p>
              <button
                style={{ width: "135px" }}
                onClick={() => navigate("/studySchedule/StudyScheduleReg")}
                className="btn btn-primary font-white fgb-btn-m"
              >
                TO-DO 스케줄 등록
              </button>
            </div>

            <div className="affiliates-main-con-card-view">
              <button
                className="btn card-left-button"
                onClick={() => {
                  if (swiperIndex2 === 0) return
                  setSwiperIndex2(prev => prev - 1)
                }}
              >
                <img src={img_schedule_left} alt="left arrow img" />
              </button>
              <button
                className="btn card-right-button"
                onClick={() => {
                  if (swiperIndex2 >= partnerMainMyTodos.length - 1) return
                  setSwiperIndex2(prev => prev + 1)
                }}
              >
                <img src={img_schedule_right} alt="right arrow img" />
              </button>

              <div className="card-swiper-view">
                <SwipeableViews
                  style={styles.root}
                  slideStyle={styles.slideContainer}
                  index={swiperIndex2}
                  onChangeIndex={idx => setSwiperIndex2(idx)}
                >
                  {partnerMainMyTodos.map((i, idx) => (
                    <ToDoItem key={idx} data={i} />
                  ))}
                </SwipeableViews>
              </div>
            </div>
            <div className="affiliates-main-con-swiper-indicator-view">
              {partnerMainMyStudies.length <= 10 &&
                partnerMainMyTodos.map((i, idx) => (
                  <button
                    className={`btn affiliates-main-con-swiper-indicator-item ${swiperIndex2 === idx ? "active" : ""}`}
                    key={idx}
                    onClick={() => setSwiperIndex2(idx)}
                  ></button>
                ))}
              {partnerMainMyTodos.length > 10 && (
                <p>
                  {swiperIndex2 + 1} / {partnerMainMyTodos.length}
                </p>
              )}
            </div>
          </div>
          <div className="affiliates-main-con-4 mt-4">
            <div className="flexdiv">
              <p className="sub-title">회원 현황</p>
              <button onClick={() => navigate("/member/MemberInquiry")} className="btn btn-primary font-white fgb-btn-m">
                회원목록 조회
              </button>
            </div>
            {/* partnerMainMemberRecently */}
            {partnerMainMemberRecently.map((i, idx) => {
              if (idx < 6)
                return (
                  <div onClick={() => setBasicInfoPopup({ open: true, data: i.seq })} className="affiliates-main-user-item" key={idx}>
                    <p className="affiliates-main-user-item-state">가입</p>
                    <img src={i.profileImage == "" ? pic : i.profileImage} />
                    <div>
                      <p className="affiliates-main-user-item-name">
                        {i.name || "User_NAME"} / {i.nickname || "Screen Name"}
                      </p>
                      <p className="affiliates-main-user-item-group">{i.studentGroupName || "학습자 그룹"}</p>
                    </div>
                    <div className="affiliates-main-user-item-loc">
                      <p>{i.region3 || "부림동"}</p>
                    </div>
                    <div className="d-flex justify-content-end">
                      <p className="affiliates-main-user-item-date">{moment(i.regDatetime).format("YYYY/MM/DD")}</p>
                      <p className="affiliates-main-user-item-date">{moment(i.regDatetime).format("HH:MM")}</p>
                    </div>
                  </div>
                )
            })}
          </div>
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default AffiliatesMainContainer(AffiliatesMain)
