import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import LayerPopupLayout from "./LayerPopupLayout"
import img_popup_close_black from "../images/img_popup_close_black.svg"

import SignContainer from "../containers/SignContainer"
import ModalPopup from "./ModalPopup"
import { dataCheckFn } from "../utils/util"

const TYPE = ["USE", "PRIVACY", "MARKETING"]

const SignTermsPopup = props => {
  const { open, onClose, idx = null, term } = props

  const closePopup = () => {
    onClose()
  }

  return (
    <LayerPopupLayout open={open} onClose={closePopup} transparency zIndex={101}>
      <div className="popup-terms-container">
        {/* desktop close btn */}
        <div className="d-flex justify-content-end">
          <button className="btn" onClick={closePopup}>
            <img src={img_popup_close_black} alt="popup close img" />
          </button>
        </div>
        <div className="popup-terms-div">
          <div className="d-flex justify-content-start align-items-center">
            <p>{idx != null && term[TYPE[idx]].title}</p>
            <div className="form-check form-switch form-switch-md ms-3"></div>
          </div>
          {/* scroll */}
          <div className="popup-terms-scroll-view mt-2">
            <pre>{idx != null && term[TYPE[idx]].content}</pre>
          </div>
          {/* button */}
          <div className="d-flex justify-content-center mt-3">
            <button className="btn fgb-btn-gray fgb-btn-m" onClick={closePopup}>
              취소
            </button>
            <button className="btn btn-primary font-white fgb-btn-m ms-2" onClick={closePopup}>
              확인
            </button>
          </div>
        </div>
      </div>
    </LayerPopupLayout>
  )
}

export default SignContainer(SignTermsPopup)
